<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
    <div class="p-col-12  p-lg-12">
        <div style="position: relative;" class="card  p-0 p-pt-4">
            <div style="position: absolute; top: 4.2%;
            left: 1.3%;" class="p-grid">
                <button (click)="showaddevent()" style="margin-right: 5px;" pButton type="button" label=' {{"NEW_EVENT"| translate}}'
                    icon="fas fa-plus" class="p-button-sm p-button-raised"></button>
                <button *ngIf="view =='listWeek'" (click)="gridView()" icon="fas fa-th" style="margin-right: 5px;"
                    pButton type="button" label=' {{"GRID_VIEW"| translate}}' class="p-button-sm p-button-raised"></button>
                <button *ngIf="view =='dayGridMonth'" (click)="listView()" icon="fas fa-list" style="margin-right: 5px;"
                    pButton class="p-button-sm p-button-raised" type="button" label=' {{"LIST_VIEW"| translate}}'></button>
            </div>
            <full-calendar #calendar (eventClick)="eventClick($event)" [options]="calendarOptions">

            </full-calendar>

        </div>
    </div>
</div>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="eventDialogHeader" [(visible)]="eventDialog" [style]="{width: '30rem'}">
    <div class="p-grid">
        <div class="p-col-12 p-pt-3">
            <span class="p-float-label ">
                <input [(ngModel)]="event.title" (ngModelChange)="notempty($event)" id="title-input" type="text"
                    pInputText>
                <label for="title-input">{{"TITLE"| translate}}</label>
            </span>

        </div>
        <div class="p-col-12 p-pt-4">

            <span class="p-float-label">
                <textarea [(ngModel)]="event.description" (ngModelChange)="notempty($event)" id="description-input"
                    rows="4" pInputTextarea></textarea>
                <label for="description-input">{{"DISCRIPTION"| translate}}</label>
            </span>
        </div>
        <div class="p-grid p-col-12 p-pt-4">
            <div class="p-col-6">
                <span class="p-float-label ">
                    <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" appendTo="body" showIcon="true"
                        [(ngModel)]="event.start" [required]="true" ngDefaultControl></p-calendar>
                    <label for="title-input">{{"START_DATE"| translate}}</label>
                </span>
            </div>
            <div class="p-col-6">
                <span class="p-float-label ">
                    <p-calendar appendTo="body" [(ngModel)]="event.startTime" (ngModelChange)="notempty($event)" [timeOnly]="true"
                        ngDefaultControl showTime="true" hourFormat="12"></p-calendar>

                    <label for="title-input">{{"TIME"| translate}}</label>
                </span>
            </div>

        </div>

        <div class="p-grid p-col-12 p-pt-4">
            <div class="p-col-6">
                <span class="p-float-label ">
                    <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" appendTo="body" showIcon="true"
                        [(ngModel)]="event.end" [required]="true" ngDefaultControl></p-calendar>
                    <label for="title-input">{{"END_DATE"| translate}}</label>
                </span>
            </div>
            <div class="p-col-6">
                <span class="p-float-label ">
                    <p-calendar appendTo="body" [(ngModel)]="event.endTime" (ngModelChange)="notempty($event)" [timeOnly]="true"
                        ngDefaultControl showTime="true" hourFormat="12"></p-calendar>

                    <label for="title-input">{{"TIME"| translate}}</label>
                </span>
            </div>

        </div>


    </div>


    <p-footer>
        <button *ngIf="eventDialogHeader== translate.instant('NEW_EVENT')" type="button" pButton icon="pi pi-plus" (click)="addEvent()"
            label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
        <button *ngIf="eventDialogHeader==translate.instant('VIEW_EVENT')" type="button" pButton icon="fas fa-pen" (click)="updateEvent()"
            label='{{"CUSTOMER_LIST_UPDATE"| translate}}'></button>
        <button *ngIf="eventDialogHeader==translate.instant('VIEW_EVENT')" type="button" pButton icon="fas fa-trash"
            class="p-button-danger" (click)="deleteEvent()" label='{{"CUSTOMER_LIST_DELETE_BUTTON"| translate}}'></button>
        <button type="button" pButton icon="pi pi-times" (click)="eventDialog=false" label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}} '
            class="p-button-outlined p-button-secondary"></button>



    </p-footer>
</p-dialog>