import { isSet } from '../modules/core/util';
import { Unit } from './unit';
import { Category } from './category';
import { Brand } from './brand';

export class ProductAdvance {
  stock: boolean;
  barcode: string;
  unit: Unit;

  sub_item_barcode:string
  

  public constructor(params?: ProductAdvance) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: ProductAdvance) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new ProductAdvance(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: ProductAdvance[]) {
    const objects: ProductAdvance[] = [];
    for (const obj of objectsToClone) {
      objects.push(ProductAdvance.cloneObject(obj));
    }

    return objects;
  }
}
