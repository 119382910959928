
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseListComponent } from './purchase-list/purchase-list.component';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CheckboxModule } from 'primeng/checkbox';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { ChipsModule } from 'primeng/chips';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import {TieredMenuModule} from 'primeng/tieredmenu';
import { SharedModule } from '../modules/shared/shared.module';
export const routes: Routes = [
  { path: '', component: PurchaseListComponent }
];

@NgModule({
  declarations: [
    PurchaseListComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    OverlayPanelModule,
    MenuModule,
    ToolbarModule,
    ButtonModule,
    FormsModule,
    PdfViewerModule,
    DropdownModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    SidebarModule,
    ProgressSpinnerModule,
    RippleModule,
    AutoCompleteModule, FileUploadModule, InputNumberModule,
    ToastModule,TieredMenuModule,
    TranslateModule, CheckboxModule, InplaceModule, CalendarModule, CardModule, TableModule, SplitButtonModule, TagModule
    , InputTextareaModule, EditorModule, ChipsModule, TooltipModule, InputMaskModule, InputSwitchModule, ImageToDataUrlModule
    , ChipModule, SharedModule, TabViewModule
  ]
})
export class PurchaseJournalsModule { }
