import { Component, OnInit, Renderer2 } from '@angular/core';
import { MenuService } from '../menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { AfterViewChecked } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('mask-anim', [
      state('void', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 0.8
      })),
      transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
    ])
  ]
})
export class MainComponent implements OnInit ,  AfterViewChecked{

  horizontalMenu: boolean;

  darkMode = false;

  menuColorMode = 'light';

  menuColor = 'layout-menu-light';

  themeColor = 'blue';

  layoutColor = 'blue';

  rightPanelClick: boolean;

  rightPanelActive: boolean;

  menuClick: boolean;

  staticMenuActive: boolean;

  menuMobileActive: boolean;

  megaSellMenuClick: boolean;
  megaBuyMenuClick: boolean;

  megaSellMenuActive: boolean;
  megaBuyMenuActive: boolean;

  megaSellMenuMobileClick: boolean;
  megaBuyMenuMobileClick: boolean;

  megaSellMenuMobileActive: boolean;
  megaBuyMenuMobileActive: boolean;

  topbarItemClick: boolean;

  topbarMobileMenuClick: boolean;

  topbarMobileMenuActive: boolean;

  sidebarActive: boolean;

  activeTopbarItem: any;

  topbarMenuActive: boolean;

  menuHoverActive: boolean;

  configActive: boolean;

  configClick: boolean;

  ripple = true;

  inputStyle = 'outlined';
  constructor(public renderer: Renderer2, private menuService: MenuService,private cdr: ChangeDetectorRef, private primengConfig: PrimeNGConfig,private SharedService : SharedService,private router:Router) {}

  ngOnInit() {
    this.primengConfig.ripple = true;


    
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();

  }
  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaSellMenuClick) {
      this.megaSellMenuActive = false;
    }

    if (!this.megaBuyMenuClick) {
      this.megaBuyMenuActive = false;
    }

    if (!this.megaSellMenuMobileClick) {
      this.megaSellMenuMobileActive = false;
    }

    if (!this.megaBuyMenuMobileClick) {
      this.megaBuyMenuMobileActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.menuService.reset();
      }

      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }

      this.menuHoverActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.configClick = false;
    this.menuClick = false;
    this.topbarItemClick = false;
    this.megaSellMenuClick = false;
    this.megaSellMenuMobileClick = false;
    this.megaBuyMenuClick = false;
    this.megaBuyMenuMobileClick = false;
    this.rightPanelClick = false;
  }

  onMegaSellMenuButtonClick(event) {
    this.megaSellMenuClick = true;
    this.megaSellMenuActive = !this.megaSellMenuActive;
    event.preventDefault();
  }

  onMegaSellMenuClick(event) {
    this.megaSellMenuClick = true;
    event.preventDefault();
  }

  onMegaBuyMenuButtonClick(event) {
    this.megaBuyMenuClick = true;
    this.megaBuyMenuActive = !this.megaBuyMenuActive;
    event.preventDefault();
  }

  onMegaBuyMenuClick(event) {
    this.megaBuyMenuClick = true;
    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null; } else {
      this.activeTopbarItem = item; }

    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;

    event.preventDefault();
  }

  onRightPanelClose(event) {
    this.rightPanelActive = false;
    this.rightPanelClick = false;

    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMobileMenuClick = true;
    this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

    event.preventDefault();
  }

  onMegaSellMenuMobileButtonClick(event) {
    this.megaSellMenuMobileClick = true;
    this.megaSellMenuMobileActive = !this.megaSellMenuMobileActive;

    event.preventDefault();
  }

  onMegaBuyMenuMobileButtonClick(event) {
    this.megaBuyMenuMobileClick = true;
    this.megaBuyMenuMobileActive = !this.megaBuyMenuMobileActive;

    event.preventDefault();
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isMobile()) {
      this.menuMobileActive = !this.menuMobileActive;
    }

    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.staticMenuActive = !this.staticMenuActive;
    event.preventDefault();
  }

  onConfigClick(event) {
    this.configClick = true;
  }

  onRippleChange(event) {
    this.ripple = event.checked;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isHorizontal() {
    return this.horizontalMenu === true;
  }

}
