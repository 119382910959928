import { isSet } from '../modules/core/util';

export class PricingCategory {
  id: string;
  name: string;

  public constructor(params?: PricingCategory) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: PricingCategory) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new PricingCategory(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: PricingCategory[]) {
    const objects: PricingCategory[] = [];
    for (const obj of objectsToClone) {
      objects.push(PricingCategory.cloneObject(obj));
    }

    return objects;
  }
  toDB(){
    return{
      name:this.name
    }
  }
}
