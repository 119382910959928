import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { TaxService } from './Tax.service';
import { TaxListComponent } from './Tax-list/Tax-list.component';
import { TaxFormComponent } from './Tax-form/Tax-form.component';
import { TranslateModule } from '@ngx-translate/core';


export const routes: Routes = [
  {path: '', component: TaxListComponent}
];

@NgModule({
  declarations: [TaxFormComponent, TaxListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    TableModule,
    SharedModule,
    SidebarModule,
    ToolbarModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    DialogModule,
    PaginatorModule,
    FileUploadModule,
    OverlayPanelModule,
    ChartModule,
    ToastModule,
    TranslateModule
  ],
  providers: [TaxService, MessageService]
})
export class TaxModule {
}
