<p-toast key="form" position="top-right"></p-toast>

<app-sidebar [(visible)]="visible" [title]="modalTitle" [size]="size">
    <div style="padding-top: 15px;
    padding-left: 41px;" header>
        <button style="margin-right: 5px;" *ngIf="modalTitle==translate.instant('APPER_SETTINGS')" (click)="companySettingsShow()"
            pButton type="button" label='{{"COM_SETT" | translate}}' class="ui-button-raised ui-button-info "></button>
        <button style="margin-right: 5px;" *ngIf="modalTitle== translate.instant('COM_SETT')" (click)="appearanceShow()"
            pButton type="button" label=' {{"SITE_APPER" | translate}} ' icon="pi pi-palette    "
            class="ui-button-raised ui-button-info "></button>

        <button pButton type="button" (click)="updateCompanySettings()"[disabled]='loadingbtn'
            *ngIf="modalTitle==translate.instant('COM_SETT')"  [icon]="loadingbtn ? 'pi pi-spin pi-spinner': 'pi pi-plus'" label='{{"CUSTOMER_LIST_SAVE" | translate}}'
            class="ui-button-raised ui-button-info"></button>
    </div>
    <div body style=" height: 193%;">
        <div class="sidebar-body" >
            <div *ngIf="modalTitle==translate.instant('COM_SETT')" class="p-grid pt-2">
                <div class="p-col-12 pt-0">
                    <!-- <p-card id="config">

                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-id-badge tags"></i>
  List Companies                          </h4>
                        </ng-template>
                        <p-dropdown appendTo="body" (ngModelChange)="notempty($event)"  [(ngModel)]="selectedcompany.name" [options]="companies" optionLabel="name" placeholder="Select Company"  (onChange)="onChangeCompany($event)" [showClear]="true">
                            <ng-template let-item pTemplate="item">
                                <span [innerHTML]="item.name"></span>
                              </ng-template>
                        </p-dropdown>


                    
                    </p-card>

                    <p-card id="config">

                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-id-badge tags"></i>
                                Main info
                            </h4>
                        </ng-template>
                        <div class="form-group">
                            <div class="p-inputgroup">
                                <span class="p-float-label p-inputgroup">
                                    <input [(ngModel)]="company.vat" (ngModelChange)="notempty($event)"
                                        (input)="getDataFromVat()" id="vat-input" type="text" pInputText>
                                    <label for="vat-input">VAT number</label>
                                </span>
                                <button pButton (click)="getDataFromVat()" type="button" [disabled]="vatBtnDisabled"
                                    [icon]="vatBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                                    class="ui-button-warn"></button>
                            </div>
                        </div>

                        <div style="padding-top: 1.1rem; " [ngClass]="{'invalid': company_name.invalid && (company_name.dirty || company_name.touched) }" class="form-group ">
                            <span class="p-float-label">
                                <input required minlength="3"  #company_name="ngModel" (ngModelChange)="notempty($event)" [(ngModel)]="company.name" id="company-input" type="text" pInputText  >
                                <label for="company-input">Company Name&nbsp;
                
                                  <label *ngIf="company.name == '' || company.name== null &&  company_name.touched ; else company_name_err" class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
                
                                  <ng-template #company_name_err>
                                    <label *ngIf="company_name.invalid && company_name.dirty " class="alert-invalid">Not Valid</label>
                          
                          
                                  </ng-template>
                
                                </label>
                     
                            </span>
                      </div>
                    </p-card>
                    <p-card id="config">

                        <ng-template pTemplate="header">
                            <div class="p-grid">
                                <div class="p-col-7">

                            <h4 class="pt-3 px-3">
                           
                                    <i class="fas fa-id-card tags"></i> 
                          
                                    Contact</h4>
                                </div>
                                    <div class="p-col-5" style="text-align: right;padding-top:1.2rem !important">
                                        <p-button class=" px-3 " (onClick)="showContactDialog = true" icon="pi pi-plus" label="Add Contact"></p-button>
                                      </div>
                                    </div>
                        </ng-template>
                     
                     

                    
                    </p-card>
                    <p-card id="config">

                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-map-marker-alt tags"></i>
                                 Address</h4>
                        </ng-template>
                        <div  (ngModelChange)="notempty($event)" [ngClass]="{'invalid': address.invalid && (address.dirty || address.touched) }" class="form-group ">
                            <span class="p-float-label">
                                        <input required minlength="2" (ngModelChange)="notempty($event)" [(ngModel)]="company.address.address"  #address="ngModel" (keydown.space)="$event.preventDefault();" id="address-input" type="text" pInputText>
                                        <label for="address-input">Address&nbsp;
                    
                                                            
                                        </label>
                    
                    
                                    </span>
                          </div>
                          <div class="p-grid  pt-2">
                            <div class="p-col-6 pt-3">
                              <div (ngModelChange)="notempty($event)" [ngClass]="{'invalid': zip.invalid && (zip.dirty || zip.touched) }" class="form-group">
                                    <span class="p-float-label">
                                        <input  required minlength="4" [(ngModel)]="company.address.zip"  #zip="ngModel" id="zip-input" type="text" pInputText>
                                        <label for="zip-input">Zip code&nbsp;
                                  
                    
                    
                                        </label>
                    
                                   
                    
                                    </span> 
                              </div>
                            </div>
                            <div class="p-col-6 pt-3">
                              <div (ngModelChange)="notempty($event)" [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched) }" class="form-group">
                                    <span class="p-float-label">
                                        <input required minlength="2" (keydown.space)="$event.preventDefault();" [(ngModel)]="company.address.city" #city="ngModel" id="city-input" type="text" pInputText>
                                        <label for="city-input">City&nbsp;
                          
                                
                    
                                        </label>
                                 
                    
                                    </span>
                              </div>
                            </div>
                            <div  class="p-col-6 ">
                              <div (ngModelChange)="notempty($event)" [ngClass]="{'invalid': country_code.invalid && (country_code.dirty || country_code.touched) }" class="form-group">
                                    <span class="p-float-label">
                                        <input required minlength="3" (keydown.space)="$event.preventDefault();" (ngModelChange)="notempty($event)" [(ngModel)]="company.address.country_code"  #country_code="ngModel" id="country-input" type="text" pInputText>
                                        <label for="country-input">Country&nbsp;
                    
                    
                                        </label>
                    
                                       
                    
                                    </span>
                              </div>
                            </div>
                          </div>

                    
                    </p-card> -->
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-stamp tags" style="font-size: 1.5rem;"></i>
                                {{"CUSTOMER_FORM_VAT_NO" | translate}}
                            </h4>
                        </ng-template>
                        <input type="text" [(ngModel)]="companySettings.vat"
                            placeholder='{{"CUSTOMER_FORM_VAT_NO" | translate}}' pInputText />
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-stream tags" style="font-size: 1.5rem;"></i>
                                {{"CUSTOMER_FORM_COMPANT_NAME" | translate}}
                            </h4>
                        </ng-template>
                        <input type="text" [(ngModel)]="companySettings.companyName"
                            placeholder='{{"CUSTOMER_FORM_COMPANT_NAME" | translate}}' pInputText />
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-map-marker-alt tags" style="font-size: 1.5rem;"></i>
                                {{"REGISTER_ADDRESS_CO" | translate}}
                            </h4>
                        </ng-template>
                        <input #addressInput placeholder='  {{"REGISTER_ADDRESS_CO" | translate}}'
                        (ngModelChange)="addreesChange()"   [(ngModel)]="companySettings.companyAddress" type="text" pInputText>
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <div class="p-d-flex">
                                <h4 class="pt-3 px-3">
                                    <i class="fas fa-file-image tags" style="font-size: 1.5rem;"></i>
                                    {{"CO_LOGO" | translate}}
                                </h4>
                                <div class="pt-3">
                                    <button *ngIf="!companySettings?.viewlink" pButton type="button"
                                        [disabled]="disablebtnupload"
                                        [icon]="disablebtnupload? 'pi pi-spin pi-spinner': ' pi pi-upload'"
                                        (click)="showUpload()" label='{{"UPLOAD" | translate}}' class="p-button-sm"
                                        for="upload-photo"></button>
                                    <input #upload accept="image/png, image/jpeg" type="file" id="upload-photo"
                                        [imageToDataUrl]="options" (imageSelected)="uploadlogo($event)">
                                    <button *ngIf="companySettings?.viewlink" pButton type="button"
                                        [disabled]="disablebtnupload"
                                        [icon]="disablebtnupload? 'pi pi-spin pi-spinner': ' fas fa-trash'"
                                        (click)="deleteLogo()" label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'
                                        class=" p-button-danger p-button-sm p-ml-2"></button>
                                </div>
                            </div>
                        </ng-template>
                        <div style="display: block; text-align: center;">
                            <img width="300px" width="300px" [src]="companySettings?.viewlink">
                        </div>
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-file-signature tags" style="font-size: 1.5rem;"></i>
                                {{"INVO_FOOTER" | translate}}
                            </h4>
                        </ng-template>
                        <p-editor id="Message" [(ngModel)]="companySettings.invoiceFooter" [style]="{'height':'120px'}">
                        </p-editor>
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-money-check-alt tags" style="font-size: 1.5rem;"></i>
                                IBAN
                            </h4>
                        </ng-template>
                        <input type="text" [(ngModel)]="companySettings.iBan" placeholder="IBAN" pInputText />
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-money-bill-alt tags" style="font-size: 1.5rem;"></i>
                                {{"DEFL_CURR" | translate}}
                            </h4>
                        </ng-template>
                        <input [(ngModel)]="defultCurrency" [disabled]='true' id="specialPrice-input" type="text"
                            pInputText>
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-money-bill-alt tags" style="font-size: 1.5rem;"></i>
                                {{"SUPP_CURR" | translate}}
                                <button pButton type="button" icon="fas fa-plus" label='{{"ADD_NEW" | translate}}' class="p-button-sm"
                                    (click)="showAdd_Currency()"></button>
                            </h4>
                        </ng-template>
                        <span *ngFor="let item of currencies">
                            <p-chip styleClass="p-ml-2 " [label]="item?.name">
                                <i *ngIf="item?.updatable" (click)="remove_Currency(item)"
                                    class="pi pi-times-circle chip-delete"></i>
                            </p-chip>
                        </span>
                    </p-card>
                    <p-card id="config">
                        <ng-template pTemplate="header">
                            <h4 class="pt-3 px-3">
                                <i class="fas fa-percent tags" style="font-size: 1.5rem;"></i>

                                {{"VATS" | translate}}
                                <button pButton type="button" icon="fas fa-plus" label='{{"ADD_NEW" | translate}}'
                                    class="p-button-sm" (click)="showAdd_vat()"></button>
                            </h4>
                        </ng-template>
                        <span *ngFor="let item of all_vats">
                            <p-chip styleClass="p-ml-2 " label=" {{item?.value |number:'1.2-2'}} ">
                                <i *ngIf="item?.updatable" (click)="remove_Vat(item)"
                                    class="pi pi-times-circle chip-delete"></i>
                            </p-chip>
                        </span>
                    </p-card>
                </div>
            </div>
            <div *ngIf="modalTitle==translate.instant('APPER_SETTINGS')" class="p-grid pt-2">
                <div class="p-col-12 pt-0">
                    <p-card>
                        <div class="layout-config-content ">

                            <!-- <h5>Input Style</h5>
                            <div class="p-formgroup-inline">
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="input_outlined" name="inputstyle"
                                        [(ngModel)]="app.inputStyle" value="outlined">
                                    </p-radioButton>
                                    <label for="input_outlined">Outlined</label>
                                </div>
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="input_filled" name="inputstyle" [(ngModel)]="app.inputStyle"
                                        value="filled">
                                    </p-radioButton>
                                    <label for="input_filled">Filled</label>
                                </div>
                            </div> -->
                            <!-- <h5>Ripple Effect</h5>
                            <p-inputSwitch [ngModel]="app.ripple" (onChange)="app.onRippleChange($event)">
                            </p-inputSwitch> -->
                            <h5>{{"MENU_ORIE" | translate}}</h5>
                            <div class="p-formgroup-inline">

                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="overlay" name="layoutMode" [(ngModel)]="app.horizontalMenu">
                                    </p-radioButton>
                                    <label for="static">{{"OVERLAY" | translate}}</label>
                                </div>
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="horizontal" name="layoutMode" [value]="true"
                                        [(ngModel)]="app.horizontalMenu"></p-radioButton>
                                    <label for="horizontal">{{"HORIZANT" | translate}}</label>
                                </div>
                            </div>

                            <h5>{{"COLOR_SHCE" | translate}}</h5>
                            <div class="p-formgroup-inline">
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="light" name="colorScheme" [value]="false"
                                        [(ngModel)]="app.darkMode" (onClick)="changeLayout($event, false)">
                                    </p-radioButton>
                                    <label for="light">{{"LIGHT" | translate}}</label>
                                </div>
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="dark" name="colorScheme" [value]="true"
                                        [(ngModel)]="app.darkMode" (onClick)="changeLayout($event, true)">
                                    </p-radioButton>
                                    <label for="dark">{{"DARK" | translate}}</label>
                                </div>
                            </div>

                            <h5>{{"MENU_COL" | translate}}</h5>
                            <div class="p-formgroup-inline">
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="lightMenu" name="menuColor" value="light"
                                        [ngModel]="app.menuColorMode" (onClick)="changeMenuColorMode($event, 'light')">
                                    </p-radioButton>
                                    <label for="lightMenu">{{"LIGHT" | translate}}</label>
                                </div>
                                <div class="p-field-radiobutton">
                                    <p-radioButton inputId="darkMenu" name="menuColor" value="dark"
                                        [ngModel]="app.menuColorMode" (onClick)="changeMenuColorMode($event, 'dark')">
                                    </p-radioButton>
                                    <label for="darkMenu">{{"DARK" | translate}}</label>
                                </div>
                                <!-- <div class="p-field-radiobutton">
                                    <p-radioButton inputId="customMenu" name="menuColor" value="custom"
                                        [ngModel]="app.menuColorMode" (onClick)="changeMenuColorMode($event, 'custom')">
                                    </p-radioButton>
                                    <label for="customMenu">Custom</label>
                                </div> -->
                            </div>


                            <!-- <h5>Themes</h5>
                            <div class="layout-themes">
                                <div style="display:inline-block" *ngFor="let t of componentThemes">
                                    <a style="cursor: pointer; margin-right: 10px  !important; border-radius: 20%;
                            font-size: large;" (click)="changeComponentTheme($event, t.file)"
                                        [ngStyle]="{'background-color': t.color}">
                                        <i style="font-size: x-large;" class="pi pi-check"></i>
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </p-card>
                </div>
            </div>
        </div>

    </div>


</app-sidebar>


<p-dialog (onShow)="outside_Status(true)" modal="true" (onHide)="cancelAdd_Currency()" [resizable]="false"
    [draggable]="false" class="p-shadow-1" header='{{"ADD_CUR" | translate}}' [(visible)]="showAddCurrency"
    [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

        <div class="p-col-12 ">
            <span class="p-float-label">
                
                <p-dropdown appendTo="body" id="currency-input" placeholder='{{"SELECT_CUR" | translate}}' [options]="all_currencies"
                    [filter]="true" optionValue='currency' [(ngModel)]="addCurrency" optionLabel="currency">
                </p-dropdown>

            </span>
        </div>

    </div>


    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="save_newCurrency()" label=' {{"CUSTOMER_LIST_SAVE" | translate}}'></button>

        <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelAdd_Currency()"
            label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
</p-dialog>


<p-dialog (onShow)="outside_Status(true)" modal="true" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" class="p-shadow-1" header='{{"ADD_NEW_VAT" | translate}}' [(visible)]="showAddvat" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

        <div class="p-col-12 ">
            <p-inputNumber [min]="0" [inputStyle]="{'width': '19.3vw' }" prefix="%" mode="decimal"
                [minFractionDigits]="2" [(ngModel)]="addVat" max="100"></p-inputNumber>

        </div>

    </div>


    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="save_newVat()" label=' {{"CUSTOMER_LIST_SAVE" | translate}}'></button>

        <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelAdd_vat()" label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}'
            class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
</p-dialog>