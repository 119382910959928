import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { userRoles } from 'src/app/models/roles';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public roles:userRoles[]=[{id:1,name:'testing'},{id:2,name:'test'}]
  public roleSelected: BehaviorSubject<userRoles> = new BehaviorSubject<userRoles>(null);
  public roleSelectedEmitter: Observable<userRoles> = this.roleSelected.asObservable();
  public createClicke: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public createClickeEmitter: Observable<boolean> = this.createClicke.asObservable();

  constructor() { }
  selectRole(role: userRoles) {
    this.roleSelected.next(role);
  }
}
