import { isSet } from '../modules/core/util';


export class Attachment {
  path: string;
  type: string;
  name: string;

  public constructor(params?: Attachment) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Attachment) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new Attachment(objectToClone);


    return obj;
  }

  public static cloneManyObjects(objectsToClone: Attachment[]) {
    const objects: Attachment[] = [];
    for (const obj of objectsToClone) {
      objects.push(Attachment.cloneObject(obj));
    }

    return objects;
  }
}
