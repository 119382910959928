import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { Vat_Product } from 'src/app/models/vat-product';
import { ApiService } from '../core/api.service';
import { isSet } from '../core/util';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  //show form
  public diplayDash: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public diplayitemEmitter: Observable<any> = this.diplayDash.asObservable();


  //show dialog (only in create New)
  public Createform: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public Createform_Emitter: Observable<boolean> = this.Createform.asObservable();

  //show dialog (only in create New)
  public removeCandCopformCusotmer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public removeCandCopformCusotmerEmitter: Observable<boolean> = this.removeCandCopformCusotmer.asObservable();

  //check if the fildes of object not empty
  public chekcempey: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public chekcempey_Emitter: Observable<boolean> = this.chekcempey.asObservable();

  // save object form form
  public saved_object: BehaviorSubject<objectDetail> = new BehaviorSubject<objectDetail>(null);
  public Saved_object_Emitter: Observable<objectDetail> = this.saved_object.asObservable();

  public obj_Collection: BehaviorSubject<objectDetail[]> = new BehaviorSubject<objectDetail[]>(null);
  public obj_Collection_Emitter: Observable<objectDetail[]> = this.obj_Collection.asObservable();

  // Submit buuton to save object
  private Saveform__state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public Saveform_Observable_state: Observable<boolean> = this.Saveform__state.asObservable();

  //check  the objcet isSet
  public objectstauts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public objectstauts_Emitter: Observable<boolean> = this.objectstauts.asObservable();


  // clearbtn
  public clearAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public clearAll_Emitter: Observable<boolean> = this.clearAll.asObservable();

  // clear afte save

  public clearSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public clearSave_Emitter: Observable<boolean> = this.clearSave.asObservable();


  public update_form: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public update_form_Emitter: Observable<boolean> = this.update_form.asObservable();



  public dialog_status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dialog_status_Emitter: Observable<boolean> = this.dialog_status.asObservable();



  public currnceyChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currnceyChangeEmitter: Observable<any> = this.currnceyChange.asObservable();

  public openDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public openDropdownEmitter: Observable<boolean> = this.openDropdown.asObservable();


  ojbects_deatil: objectDetail[] = []
  constructor(private router: Router, private api: ApiService) {

  }


  Set_Saveform__state(state: boolean) {
    this.Saveform__state.next(state)
  }
  Save_object(object: any) {
    let ojbect_deatil = {
      object: object,
      url: this.router.url
    }

    this.ojbects_deatil = this.ojbects_deatil.filter(item => item.url !== ojbect_deatil.url)
    this.ojbects_deatil.push(ojbect_deatil)
    this.obj_Collection.next(this.ojbects_deatil)

  }


  Create_form(state: boolean) {
    this.Createform.next(state)
  }
  delete_obj() {
    this.ojbects_deatil = this.ojbects_deatil.filter(item => item.url !== this.router.url)
    this.obj_Collection.next(this.ojbects_deatil)
    this.saved_object.next(null)

  }

  add_Vat(vat: Vat_Product) {
    return this.api.post<Vat_Product>(`/vats`, vat.toDB());

  }
  get_Vats(): Observable<PaginatedResponse<Vat_Product[]>> {
    return this.api.get<PaginatedResponse<Vat_Product[]>>(`/vats`);
  }
  hide_Vat(vat: Vat_Product) {
    vat.show = false

    return this.api.patch<Vat_Product>(`/vats`, vat);

  }

  update_profile(user: any) {
    const body_form = new FormData()

    if (typeof (user?.image) == 'object') {

      body_form.append("image", user?.image)

    }
    body_form.append("first_name", user?.first_name)
    body_form.append("last_name", user?.last_name)
    body_form.append("mobile", user?.mobile)

    const body = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      mobile: user?.mobile,
      image: user?.image
    }

    return this.api.patch<any>(`/users/rud/${user?.id}`, user.deletePic == true ? body : body_form);

  }

  change_password(data: any) {

    return this.api.post<any>(`/users/change_password`, data);

  }

  reset_email(data: any) {

    return this.api.post<any>(`/users/reset_email`, data);

  }
  urlShortener(downloadUrl: any, fileName:string) {
    let body={
      url:downloadUrl,
      file_path:fileName,
    }
    return this.api.post<any>(`/urls_shortner`,body);
  }
  urlDownload(id: any) {
    return this.api.get<any>(`/download/${id}`);
  }
}

export class objectDetail {
  object: any;
  url: string
}
