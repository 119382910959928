import { isSet } from '../modules/core/util';

export class DimensionUnits {



  id: string;
  name: string;

  public constructor(params?: DimensionUnits) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: DimensionUnits) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new DimensionUnits(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: DimensionUnits[]) {
    const objects: DimensionUnits[] = [];
    for (const obj of objectsToClone) {
      objects.push(DimensionUnits.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
    return{
      name:this.name
    
    }
      }
}
