
import { isSet } from '../modules/core/util';

export class Tax {
  id?: string;
  name?:string
  value ?: number;


  public constructor(params?: Tax) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Tax) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new Tax(objectToClone);
    
    
    return obj;
  }

  public static cloneManyObjects(TaxesToClone: Tax[]) {
    const Taxes: Tax[] = [];
    for (const tax of TaxesToClone) {
      Taxes.push(Tax.cloneObject(tax));
    }

    return Taxes;
  }

  public toDB() {
    return {
      name: this.name,
      value: this.value,
      
 
    };
  }


}
