import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { verifyemailComponent } from './verifyemail/verifyemail.component';
import { TranslateModule } from '@ngx-translate/core';
import {StepsModule} from 'primeng/steps';
import { ProgressStepComponent } from './register/progress/progress-step/progress-step.component';
import { ProgressComponent } from './register/progress/progress.component';
import {DividerModule} from 'primeng/divider';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CardModule } from 'primeng/card';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import {DropdownModule} from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';


export const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'verify', component: verifyemailComponent},
  {path: 'resetpassword', component: ResetPasswordComponent},
  {path: 'register_confirmation', component: ConfirmEmailComponent},

];

@NgModule({
  providers: [MessageService],
  declarations: [LoginComponent, RegisterComponent,verifyemailComponent,
    ProgressComponent,ProgressStepComponent, ResetPasswordComponent, ConfirmEmailComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    InputTextModule,
    CheckboxModule,
    RouterModule,
    ButtonModule,DividerModule,
    SharedModule,
    DropdownModule,
    ToastModule,
    MessageModule,
    StepsModule,
    TranslateModule
    ,CardModule,DialogModule,
    NgxCaptchaModule
    
  ]
})
export class AuthModule {
}
