<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (Reload)='refreshList()' (remove)="removedialogueeQ = true" [disableRemove]="!selected.length"
        (add)="showAddExpenseNote()" addLabel='{{"NEW_EXPENSE_NOTE" | translate}} '>
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredExpenseNotes" [field_name]="keyword"
          (filter)="serach($event)"></app-Search-select>
      </app-table-header>
      <p-table styleClass="p-datatable-striped " [paginator]="true" [rows]="10" [value]="expenseNote"  (onPage)="getNextExpenseNotesPage($event)"
        [(selection)]="selected" [rowsPerPageOptions]="[10,30]"[showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries"
        [globalFilterFields]="['format','ex_status','payer.name','date','total']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="format"> 
              <span
              [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">
              {{"QUOTES_LIST_QUOTE_NO" | translate}}</span>
            <p-sortIcon (click)="customSort('format')" field="format"></p-sortIcon>


                  <p-columnFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('format',filterModeFormat_query?.type,filterModeFormat_query?.value)"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>

            </th>
            <th>
              <span [style.color]="filterModeApply_Payer?'var(--primary-color)':null">{{"PAYER" | translate}}</span>
              <p-columnFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name">
                <ng-template pTemplate="filter">
                  <app-customers-select *ngIf="filterModeApply_Payer==false" [(customer)]="selected_Payerrquery"
                    [filterMode]="true"></app-customers-select>
                  <input *ngIf="filterModeApply_Payer==true" [(ngModel)]="selected_Payerrquery.name"
                    class="p-mt-3" type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries();filterModeApply_Payer=false"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Payerrquery?.id" label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('customer',null,selected_Payerrquery?.id);filterModeApply_Payer=true"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th>{{"QUOTES_LIST_STATUS" | translate}}

              <p-columnFilter type="text" matchMode="equals" field="ex_status" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false"> 
                           
                <ng-template id="statusFilter" pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [options]="ex_statues"  [showClear]="true"
                    (onChange)="filter($event.value)" placeholder='{{"SELECT_TYPE" | translate}}' optionLabel="ex_status"
                    optionValue="ex_status">
                    <ng-template let-item pTemplate="item">
                      <span >{{item.ex_status}}</span>
                
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>


            </th>

            <th>{{"TITLE" | translate}} </th>


            <th pSortableColumn="date">{{"DASHBORD_DATE" | translate}}
              <p-sortIcon field="date"></p-sortIcon>

              <p-columnFilter class="p-ml-3" [showAddButton]="false" [showOperator]="false" type="date" field="date"
                display="menu">
              </p-columnFilter>

            </th>

            <th pSortableColumn="total">{{"QUOTES_LIST_TOTAL" | translate}}
              <p-sortIcon field="total"></p-sortIcon>

              <p-columnFilter class="p-ml-3" [showAddButton]="false" [showOperator]="false" type="numeric" field="total"
                display="menu"></p-columnFilter>
            </th>





          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showExpenseNote(rowData,i)" href="javascript:;">{{ rowData?.format }}</a></td>
            <td>
              {{ rowData?.payer?.name }}
            </td>
            <td>
              {{ rowData?.ex_status }}
            </td>
            <td>
              {{ rowData?.title }}
            </td>

            <td> {{ rowData?.date | date:'dd-MM-yyyy' }}</td>
            <td>{{ rowData?.total |  number:'1.2-2'}}€</td>


          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [size]="'p-sidebar-xlg'">
  <div header>
    <button *ngIf="selectedExpenseNote" style="margin-right: 5px;" pButton
      pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" type="button"
      icon="far fa-envelope" class="p-button-secondary"></button>
    <button *ngIf="selectedExpenseNote" pButton type="button" icon="fas fa-print"
      pTooltip='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' tooltipPosition="bottom" class="p-button-secondary"></button>
    <button (click)="deleteseletedexpense(selectedExpenseNote)" *ngIf="selectedExpenseNote" pButton pRipple
      type="button" icon="fas fa-trash" [pTooltip]="'Delete ' + selectedExpenseNote?.format" tooltipPosition="bottom"
      class="p-button-secondary" style="margin-left: 5px;"></button>

    <button *ngIf="!selectedExpenseNote" pButton type="button" icon="pi pi-plus" label='{{"CUSTOMER_LIST_SAVE" | translate}}' (click)="saveForm()"
      class="ui-button-raised ui-button-info"></button>
    <!-- <p-splitButton *ngIf="!selectedExpenseNote" label=' {{"CUSTOMER_LIST_SAVE" | translate}}' icon="pi pi-plus" (onClick)="saveForm()" [model]="itemsSave" class="ui-button-raised ui-button-info"></p-splitButton> -->

    <button style="margin-left: 22px;" *ngIf="selectedExpenseNote" (click)="Update()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-expense-note-form *ngIf="showSidebar"></app-expense-note-form>
  </div>
</app-sidebar>

<p-dialog appendTo="body" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" header="Remove Expenses Note" [(visible)]="removedialogueeQ"
  [style]="{width: '30vw'}">
  <div class="p-text-justify ">{{"CUSTOMER_LIST_DELETE_CONFIRM" | translate}}</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="removeExpenseNotes()"
      label=' {{"QUOTES_LIST_YES" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialogueeQ=false"
      label='{{"QUOTES_LIST_NO" | translate}}' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>