<p-toast key="formM" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid">
  <div class="p-col-8 ">

    <p-card>
      <div [style.height.px]="payment_cardHeight" class="p-grid">
        <div class="p-col-7 p-pr-3">
          <div class="p-grid">
            <div class="p-col-3 text-align-left">
              <label style="pointer-events: none; " class="formattext ">{{"TITLE" | translate}} : </label>
            </div>
            <div class="p-col-8 ">
              <input #title="ngModel" maxlength="40" (ngModelChange)="notempty($event)" [(ngModel)]="expenseNote.title"
                type="text" pInputText>

            </div>

          </div>
          <div class="p-grid">
            <div class="p-col-3 text-align-left">
              <label style="pointer-events: none; " class="formattext ">{{"DISCRIPTION" | translate}} :</label>
            </div>
            <div class="p-col-8 ">
              <textarea style="max-height: 119px;" (ngModelChange)="notempty($event)" (ngModelChange)="notempty($event)"
                [(ngModel)]="expenseNote.description" id="message-input" rows="5" autoResize="false"
                pInputTextarea></textarea>
            </div>

          </div>

        </div>

        <div class="p-col-5 p-pr-3">
          <div class="form-group order-header-numbers">

            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label style="pointer-events: none; " class="formattext ">{{"EXPENSE" | translate}} Num : </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-6">
                <p-inplace [disabled]="selected_expenseNote" #expenseNoteformat>
                  <span pInplaceDisplay>
                    <button *ngIf=" expenseNote.format "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton
                      [label]="expenseNote.format" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>

                    <button *ngIf=" expenseNote.format == '' && formatt.touched  " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>

                  </span>
                  <input #formatt="ngModel" [(ngModel)]="expenseNote.format" type="text" pInputText pInplaceContent
                    (blur)="expenseNoteformat.deactivate()">

                </p-inplace>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label style="pointer-events: none; " class="formattext "> Expense Date : </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-6">
                <p-inplace #qouteDate>
                  <span pInplaceDisplay>

                    <button *ngIf=" expenseNote.date  "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton (click)="focusThenShow('date')"
                      [label]="expenseNote.date | date:'dd-MM-yyyy' " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                    <button *ngIf=" expenseNote.date == null && date.touched " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
                  </span>
                  <p-calendar #openCalender dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true"
                    [(ngModel)]="expenseNote.date" pInplaceContent #date="ngModel" [required]="true"
                    (onClose)="qouteDate.deactivate()" (onClickOutside)="qouteDate.deactivate()"
                    (onSelect)="qouteDate.deactivate()"></p-calendar>
                </p-inplace>
              </div>


            </div>
            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label style="pointer-events: none; " class="formattext "> Expense Status : </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-6">
                <p-inplace #ex_status>
                  <span pInplaceDisplay>

                    <button *ngIf=" expenseNote.ex_status  "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton (click)="focusThenShow('status')"
                      [label]="expenseNote.ex_status " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                    <button *ngIf=" expenseNote.ex_status == null && ex_statuse.touched " style="  font-weight: 600;  "
                      pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('status')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
                  </span>
                  <p-dropdown #DropDownStatus pInplaceContent appendTo="body" [options]="expenseNoteStatus"
                    (ngModelChange)="notempty($event)" [(ngModel)]="expenseNote.ex_status" #ex_statuse="ngModel"
                    name="ex_status" optionValue="ex_status" optionLabel="ex_status" (onSelect)="ex_status.deactivate()"
                    (onClickOutside)="ex_status.deactivate()" (onHide)="ex_status.deactivate()">

                  </p-dropdown>
                </p-inplace>
              </div>


            </div>

            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label style="pointer-events: none; " class="formattext "> {{"PAYER" | translate}} : </label>
              </div>
              <div class="p-col-6">
                <p-inplace #ex_payer>
                  <span pInplaceDisplay>

                    <button *ngIf=" expenseNote.payer  "
                      style=" color: var(--primary-color) !important; font-weight: 600; " pButton
                      [label]="expenseNote.payer.name " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>

                    <button *ngIf="!expenseNote.payer "
                      style=" color: var(--primary-color) !important; font-weight: 600; " icon="fas fa-plus" pButton
                      id="selectCustomer" pButton label='{{"ADD_PAYER" | translate}}' type="button"
                      class="  p-button-outlined"></button>


                  </span>

                  <app-payer-select pInplaceContent [(payer)]="expenseNote.payer" (payerChange)="payerChange($event)"
                    (onHide)="ex_payer.deactivate()"></app-payer-select>

                </p-inplace>
              </div>


            </div>

          </div>
        </div>

      </div>
    </p-card>
  </div>
  <div class="p-col-4 col-4-r">
    <p-card>
      <div #paymentcard class="p-grid">
        <div class="p-col-12 ">

          <h3 aria-required="true" class="table-inplace">
            <i class="fa-menu fa fa-money-bill-alt tags"></i>
            {{"ORDERS_FORM_PAYMENTS" | translate}}
          </h3>
        </div>

        <div class="p-col-12">

          <p-table id="payment-table" [resizableColumns]="true" [rowHover]="true" [scrollable]="true"
            scrollHeight="153px" [value]="expenseNote.payments">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 50%; "> {{"QUOTES_LIST_TOTAL" | translate}}:
                  <span class="p-text-bold" style="color: #51749e;">{{ expenseNote.totalwithVat | number:'1.2-2'}}
                  </span>

                </th>
                <th class="text-align-right" style="width: 50%;">
                  <div> {{"ORDERS_FORM_DUE" | translate}}:
                    <span class="p-text-bold" style="color: #51749e;">{{paymentDue | number:'1.2-2'}} </span>

                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
              <tr [ngStyle]="{cursor: rowData.status2=='new'?'pointer':'auto'}" (click)="paymentReview(rowData,i)">
                <!-- <td style="width: 50px;">

                </td> -->
                <td>
                  <div [ngClass]="{'p-ml-5': rowData.status2=='new'}" class="p-text-bold ">
                    {{rowData.notes}}</div>
                  <div style="position: relative;" [ngClass]="{'p-ml-5': rowData.status2=='new'}" class="p-text-light ">
                    <button style="position: absolute; left: -36px;
                    top: -12px;" *ngIf="rowData.status2=='new'" (click)="deletePayment(i)" pButton pRipple
                      type="button" icon="fas fa-trash" class="p-button-lg p-button-text p-button-danger "></button>
                    {{ rowData.dateP | date:'d-M-y' }}
                    <p-tag styleClass="ml-3" severity="primary">{{ rowData.type | translate}}</p-tag>
                  </div>
                </td>
                <td style="text-align:end;">
                  <span class="p-text-bold " style="color: #51749e;">
                    <label *ngIf="rowData.status == 'refund'">-</label>
                    {{rowData.amount| number:'1.2-2'}} </span>
                </td>

              </tr>


            </ng-template>

            <ng-template pTemplate="summary">
              <div class="p-grid ">
                <div class="p-col-6">

                  <p-splitButton #ua appendTo="body" [disabled]="paymentDuestatus" label='{{"PAYMENT" | translate}}'
                    icon="fas fa-plus" (onClick)="showAddPaymnet()" [model]="payment_Options"
                    class="ui-button-raised ui-button-info">
                  </p-splitButton>

                </div>
                <div style="text-align: end !important;" class="p-col-6 ">
                  <button pButton class="p-button-danger " (click)="showrefundPayment()"
                    [disabled]="expenseNote.payments?.length==0 || expenseNote.totalwithVat ==  paymentDue"
                    icon="fas fa-minus" type="button" label='{{"ORDERS_FORM_REFUND" | translate}}'></button>

                </div>

              </div>

            </ng-template>
          </p-table>



        </div>

      </div>

    </p-card>
  </div>

  <div class="p-col-12 col-12-r">

    <p-card>
      <div style="box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
        *ngFor="let item of expenseNote.items; let i = index" class="p-grid p-pl-3 p-mb-6 border ">
        <div *ngIf="item.ex_type == 'Ticket'" class="p-col-12">
          <div class="p-grid">
            <div style="border-right: 1px solid #dee2e6;" class="p-col-4 custom-col-4  ">
              <div class="p-grid  p-pr-4">
                <div class="p-col-12 p-mt-4">
                  <span class="p-float-label">
                    <p-calendar [inputStyle]="{'width':item_vat.offsetWidth-30+'px'}" id="date-input"
                      dateFormat="dd-mm-yy" appendTo="body" [(ngModel)]="item.datePipe" showIcon="true"
                      (ngModelChange)="notempty($event)" name="item.datePipe"></p-calendar>
                    <label for="date-input">{{"DASHBORD_DATE" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label">
                    <p-dropdown id="type-input" appendTo="body" [placeholder]="item.ex_type? null:'Select Type'"
                      (onChange)="ex_typeonChanged($event,i)" [options]="expenseNoteType"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.ex_type" optionValue="ex_type"
                      optionLabel="ex_type">
                    </p-dropdown>
                    <label *ngIf="item.ex_type" for="type-input">{{"TYPE" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12  p-mt-3">
                  <span class="p-float-label">
                    <p-dropdown id="category-input" appendTo="body"
                      [placeholder]="item.ex_type_2? null:'Select Category'" [options]="expenseNoteType2"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.ex_type_2" optionValue="ex_type_2"
                      optionLabel="ex_type_2">
                    </p-dropdown>
                    <label *ngIf="item.ex_type_2" for="category-input">{{"CUSTOMER_FORM_CAT" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 ">

                  <span *ngIf="!isSet(item?.vendor?.id) && isSet(item?.vendor?.name) ; else inlineVendor"
                    class="p-float-label">
                    <input id="supplier-input" [(ngModel)]="item.vendor.name" type="text" pInputText>
                    <label for="supplier-input">{{"SUP_VEN" | translate}}</label>
                  </span>
                  <ng-template #inlineVendor>
                    <app-supplier-select id="supplier-input" [floatSupplier]='true'
                      (supplierChange)="selectvendor($event,i)" [(supplier)]="item.vendor">
                    </app-supplier-select>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <input #item_vat id="vat-input" name="item.vat" [(ngModel)]="item.vat"
                      (ngModelChange)="notempty($event)" type="text" pInputText>
                    <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}</label>
                  </span>
                </div>

              </div>
            </div>
                  <div style="border-right: 1px solid #dee2e6;" class="p-col-4  custom-col-4 ">
              <div class="p-grid p-px-4  text-align-center">
                <div class="p-col-12 p-mt-4">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat21-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_21
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_21"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_21)&& item.vat_21 !== 0  && item.vat_21 !== '' "
                        (click)="erace_value(i,'vat_21')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat21-input">Vat
                        21%</label>
                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat12-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_12
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_12"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_12)&& item.vat_12 !== 0  && item.vat_12 !== '' "
                        (click)="erace_value(i,'vat_12')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat12-input">Vat
                        12%</label>

                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat6-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_6
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_6"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_6)&& item.vat_6 !== 0  && item.vat_6 !== '' "
                        (click)="erace_value(i,'vat_6')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat6-input">Vat
                        6%</label>

                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"[inputStyle]="{'width':currencyinput.offsetWidth+'px'}" id="amount-input" #amount
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.amount"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber> <i
                        *ngIf="isSet(item.amount)&& item.amount !== 0  && item.amount !== '' "
                        (click)="erace_value(i,'amount')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary"
                        for="amount-input">{{"ORDERS_FORM_AMOUNT" | translate}}</label>

                    </div>

                  </span>

                </div>

                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">

                    <div class="clearable-input">
                      <input [style]="{'width':currencyinput.offsetWidth+'px'}" id="exchange_rate-input" #exchangeRate
                        name="item.exchange_rate" (keypress)="numberOnly($event)"
                        (change)="onChange_Item(i,'exchange_rate')" (ngModelChange)="notempty($event)"
                        (focus)="$event.target.select()" [(ngModel)]="item.exchange_rate" type="text" pInputText>
                      <i *ngIf="isSet(item.exchange_rate)&& item.exchange_rate !== 1 && item.exchange_rate !== ''"
                        (click)="erace_value(i,'exchange_rate')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary"
                        for="exchange_rate-input">{{"EXCHANGE_RATE" | translate}}</label>

                    </div>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label ">



                    <span #currencyinput class="p-float-label text-align-left">

                      <p-dropdown id="currency-input" [options]="currencies" [filter]="true" optionValue='currency'
                        [(ngModel)]="item.currency" optionLabel="currency"></p-dropdown>
                      <label *ngIf="item?.currency" for="currency-input">{{"CURRANECY" | translate}}</label>

                    </span>

                  </span>
                </div>
                <div class="p-col-12   p-mt-3">
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_VAT" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.vatTotal | number:'1.2-2'}}</div>
                  </div>
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"TOTAL_EXCL" | translate}} </div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.item_total | number:'1.2-2'}}
                    </div>
                  </div>
                  <hr class="divider">
                  <div style="background-color: #f8f9fa;" class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_LIST_TOTAL" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.totalwithVat | number:'1.2-2'}}
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div class="p-col-4  custom-col-4  ">
              <div class="p-grid  p-px-4 ">
                <div class="p-col-12 p-mt-4 ">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" [placeholder]="item.payment_type? null:'Payment Type'"
                      [options]="paymenttypes" (ngModelChange)="notempty($event)" [(ngModel)]="item.payment_type"
                      optionValue="type" optionLabel="label">

                    </p-dropdown>

                    <label *ngIf="item.payment_type" for="category-input">{{"PAYMENT_TYPE" | translate}}</label>
                  </span>
                </div>

                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <textarea #tablee rows="5" (ngModelChange)="notempty($event)" (ngModelChange)="notempty($event)"
                      [(ngModel)]="item.description" id="description-input" autoResize="false"
                      pInputTextarea></textarea>
                    <label for="description-input">{{"DISCRIPTION" | translate}}</label>
                  </span>
                </div>

                <div class="p-col-12 p-mt-3 text-align-center">
                  <div *ngIf="!isSet(item?.attachment?.path) || item.loadingbtnupload!=false; else viewRec">
                    <button pButton type="button" [disabled]="disablebtnupload"
                      [icon]="item.loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'"
                      (click)="showUploadReceipt(i)" class="p-button-lg" label='{{"UPLOAD_RECIEPT" | translate}}'
                      for="upload-photo"></button>
                    <input #upload type="file" accept=".jpg,.jpeg,.png,.pdf" id="upload-photo"
                      [imageToDataUrl]="options" (imageSelected)="uploadReceipt($event)">
                  </div>
                  <ng-template #viewRec>
                    <button pButton type="button" [disabled]="disablebtnupload" icon="fas fa-eye"
                      (click)="showViewReceipt(i,'item')" label='{{"VIEW_RECEIPT" | translate}}' class="p-button-lg"
                      for="upload-photo"></button>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 text-align-center">
                  <button *ngIf="expenseNote.items.length > 1" pButton type="button" (click)="deleteExpense(i)"
                    icon="fas fa-trash " label='Delete Expense' class="p-button-lg  p-button-danger"></button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.ex_type == 'Invoice'" class="p-col-12">
          <div class="p-grid">
            <div style="border-right: 1px solid #dee2e6;" class="p-col-4 custom-col-4  ">
              <div class="p-grid  p-pr-4">
                <div #ex_type class="p-col-12 p-mt-4">
                  <span class="p-float-label">
                    <p-calendar [inputStyle]="{'width':ex_type.offsetWidth-30+'px'}" id="date-input"
                      dateFormat="dd-mm-yy" appendTo="body" [(ngModel)]="item.datePipe" showIcon="true"
                      (ngModelChange)="notempty($event)" name="item.datePipe"></p-calendar>
                    <label for="date-input">{{"DASHBORD_DATE" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label">
                    <p-dropdown id="type-input" appendTo="body" [placeholder]="item.ex_type? null:'Select Type'"
                      (onChange)="ex_typeonChanged($event,i)" [options]="expenseNoteType"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.ex_type" optionValue="ex_type"
                      optionLabel="ex_type">
                    </p-dropdown>
                    <label *ngIf="item.ex_type" for="type-input">{{"PAYMENT_TYPE" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12  p-mt-3">
                  <span class="p-float-label">
                    <p-dropdown id="category-input" appendTo="body"
                      [placeholder]="item.ex_type_2? null:'Select Category'" [options]="expenseNoteType2"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.ex_type_2" optionValue="ex_type_2"
                      optionLabel="ex_type_2">
                    </p-dropdown>
                    <label *ngIf="item.ex_type_2" for="category-input">{{"CUSTOMER_FORM_CAT" | translate}}</label>
                  </span>
                </div>

                <div class="p-col-12 p-mt-3 ">

                  <span *ngIf="!isSet(item?.vendor?.id) && isSet(item?.vendor?.name) ; else inlineVendor"
                    class="p-float-label">
                    <input id="supplier-input" [(ngModel)]="item.vendor.name" type="text" pInputText>
                    <label for="supplier-input">{{"SUP_VEN" | translate}}</label>
                  </span>
                  <ng-template #inlineVendor>
                    <app-supplier-select id="supplier-input" [floatSupplier]='true'
                      (supplierChange)="selectvendor($event,i)" [(supplier)]="item.vendor">
                    </app-supplier-select>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <input id="vat-input" name="item.vat" [(ngModel)]="item.vat" (ngModelChange)="notempty($event)"
                      type="text" pInputText>
                    <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}</label>
                  </span>
                </div>



              </div>
            </div>
            <div style="border-right: 1px solid #dee2e6;" class="p-col-4  custom-col-4 ">
              <div class="p-grid p-px-4  text-align-center">
                <div class="p-col-12 p-mt-4">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat21-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_21
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_21"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_21)&& item.vat_21 !== 0  && item.vat_21 !== '' "
                        (click)="erace_value(i,'vat_21')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat21-input">Vat
                        21%</label>
                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat12-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_12
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_12"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_12)&& item.vat_12 !== 0  && item.vat_12 !== '' "
                        (click)="erace_value(i,'vat_12')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat12-input">Vat
                        12%</label>

                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"id="vat6-input" [inputStyle]="{'width':currencyinput.offsetWidth+'px'}" #vat_6
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.vat_6"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                      <i *ngIf="isSet(item.vat_6)&& item.vat_6 !== 0  && item.vat_6 !== '' "
                        (click)="erace_value(i,'vat_6')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary" for="vat6-input">Vat
                        6%</label>

                    </div>

                  </span>

                </div>
                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">
                      <p-inputNumber [min]="0"[inputStyle]="{'width':currencyinput.offsetWidth+'px'}" id="amount-input" #amount
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.amount"
                        mode="decimal" [minFractionDigits]="2"></p-inputNumber> <i
                        *ngIf="isSet(item.amount)&& item.amount !== 0  && item.amount !== '' "
                        (click)="erace_value(i,'amount')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary"
                        for="amount-input">{{"ORDERS_FORM_AMOUNT" | translate}}</label>

                    </div>

                  </span>

                </div>

                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">

                    <div class="clearable-input">
                      <input [style]="{'width':currencyinput.offsetWidth+'px'}" id="exchange_rate-input" #exchangeRate
                        name="item.exchange_rate" (keypress)="numberOnly($event)"
                        (change)="onChange_Item(i,'exchange_rate')" (ngModelChange)="notempty($event)"
                        (focus)="$event.target.select()" [(ngModel)]="item.exchange_rate" type="text" pInputText>
                      <i *ngIf="isSet(item.exchange_rate)&& item.exchange_rate !== 1 && item.exchange_rate !== ''"
                        (click)="erace_value(i,'exchange_rate')" class="pi pi-times"></i>
                      <label style="position: absolute;left: 2%;" class="p-text-secondary"
                        for="exchange_rate-input">{{"EXCHANGE_RATE" | translate}}</label>

                    </div>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label ">



                    <span #currencyinput class="p-float-label text-align-left">

                      <p-dropdown id="currency-input" [options]="currencies" [filter]="true" optionValue='currency'
                        [(ngModel)]="item.currency" optionLabel="currency"></p-dropdown>
                      <label *ngIf="item?.currency" for="currency-input">{{"CURRANECY" | translate}}</label>

                    </span>

                  </span>
                </div>
                <div class="p-col-12   p-mt-3">
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_VAT" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.vatTotal | number:'1.2-2'}}</div>
                  </div>
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"TOTAL_EXCL" | translate}} </div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.item_total | number:'1.2-2'}}
                    </div>
                  </div>
                  <hr class="divider">
                  <div style="background-color: #f8f9fa;" class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_LIST_TOTAL" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ item.totalwithVat | number:'1.2-2'}}
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div class="p-col-4  custom-col-4  ">
              <div class="p-grid  p-px-4 ">
                <div class="p-col-12 p-mt-4 ">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" [placeholder]="item.payment_type? null:'Payment Type'"
                      [options]="paymenttypes" (ngModelChange)="notempty($event)" [(ngModel)]="item.payment_type"
                      optionValue="type" optionLabel="label">

                    </p-dropdown>

                    <label *ngIf="item.payment_type" for="category-input">{{"ORDERS_FORM_PAYMENT_TYPES" |
                      translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <input id="iban-input" name="item.iban" [(ngModel)]="item.iban" (ngModelChange)="notempty($event)"
                      type="text" pInputText>
                    <label for="iban-input">IBAN</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <input id="vcs-input" name="item.vcs" [(ngModel)]="item.vcs" (ngModelChange)="notempty($event)"
                      type="text" pInputText>
                    <label for="vcs-input">VCS</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <textarea #tablee (ngModelChange)="notempty($event)" (ngModelChange)="notempty($event)"
                      [(ngModel)]="item.description" id="description-input" rows="8" autoResize="false"
                      pInputTextarea></textarea>
                    <label for="description-input">{{"DISCRIPTION" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 text-align-center">
                  <div *ngIf="!isSet(item?.attachment?.path) || item.loadingbtnupload!=false; else viewRec">
                    <button pButton type="button" [disabled]="disablebtnupload"
                      [icon]="item.loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'"
                      (click)="showUploadReceipt(i)" class="p-button-lg" label='{{"UPLOAD_RECIEPT" | translate}}'
                      for="upload-photo"></button>
                    <input #upload type="file" accept=".jpg,.jpeg,.png,.pdf" id="upload-photo"
                      [imageToDataUrl]="options" (imageSelected)="uploadReceipt($event)">
                  </div>
                  <ng-template #viewRec>
                    <button pButton type="button" [disabled]="disablebtnupload" icon="fas fa-eye"
                      (click)="showViewReceipt(i,'item')" label='{{"VIEW_RECEIPT" | translate}}' class="p-button-lg"
                      for="upload-photo"></button>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 text-align-center">
                  <button *ngIf="expenseNote.items.length > 1" pButton type="button" (click)=" deleteExpense(i)"
                    icon="fas fa-trash " label='{{"DELETE_EXPENSE" | translate}}'
                    class="p-button-lg  p-button-danger"></button>
                </div>

              </div>
            </div>
          </div>
        </div>



      </div>

      <button (click)="addItem()" pButton type="button" label='{{"NEW_EXPENSE" | translate}}' icon="fas fa-plus"
        class="ui-button-raised  p-mr-3"></button>
      <button (click)="showImportExpenss=true" [disabled]="!expenses.length" pButton type="button"
        label='{{"IMPORT_EXPENSE" | translate}}' icon="fas fa-file-import"
        class="ui-button-raised p-button-secondary"></button>
      <!-- <label class="p-ml-3">OR press <code>ctrl+enter</code></label> -->
    </p-card>
  </div>
  <div class="p-col-12 col-12-r">

    <div class="p-grid">
      <div class="p-col-8">
        <p-card>
          <div [style.height.px]="cardHeight" class="p-grid ">

            <div class="p-col-12">
              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 95px;" (ngModelChange)="notempty($event)" autoResize="false"
                    [(ngModel)]="expenseNote.notes" id="message-input" rows="5" pInputTextarea></textarea>
                  <label for="message-input">{{"QUOTES_Form_MESSAGE" | translate}}</label>
                </span>
              </div>
            </div>

          </div>

        </p-card>
      </div>
      <div class="p-col-4">
        <p-card>
          <div #total_Height class="p-grid">
            <div class=" p-col-12 order-summary text-muted">

              <div *ngIf="expenseNote.vatTotal > 0" class=" p-grid p-mt-3">
                <div class="p-col-6"> {{"TOTAL_EXCL" | translate}} :</div>
                <div class="p-col-6 text-align-right number-total">{{ expenseNote?.totalwithout_vat | number:'1.2-2'}}
                </div>
              </div>
              <div *ngIf="expenseNote.vatTotal > 0" style="background-color: #f8f9fa;" class=" p-grid ">
                <div class="p-col-6"> {{"QUOTES_Form_TOTAL_VAT" | translate}} :</div>
                <div class="p-col-6 text-align-right number-total">{{ expenseNote.vatTotal | number:'1.2-2'}}</div>
              </div>
              <div
                *ngIf="expenseNote.vatAmount_6 != 0 && (expenseNote.vatAmount_12 != 0 || expenseNote.vatAmount_21 != 0 )"
                style="background-color: #f8f9fa;" class=" p-grid">
                <div class="p-col-6">{{"CUSTOMER_LIST_VAT" | translate}} 6%:</div>
                <div class="p-col-6 text-align-right number-total">{{ expenseNote.vatAmount_6 |
                  number:'1.2-2'}} </div>
              </div>
              <div
                *ngIf="expenseNote.vatAmount_12 != 0 && (expenseNote.vatAmount_6 != 0 || expenseNote.vatAmount_21 != 0 )"
                style="background-color: #f8f9fa;" class=" p-grid">

                <div *ngIf="expenseNote.vatAmount_12 != 0" class="p-col-6">{{"CUSTOMER_LIST_VAT" | translate}} 12%:
                </div>
                <div *ngIf="expenseNote.vatAmount_12 != 0" class="p-col-6 text-align-right number-total">{{
                  expenseNote.vatAmount_12 |
                  number:'1.2-2'}} </div>
              </div>
              <div
                *ngIf="expenseNote.vatAmount_21 != 0 && (expenseNote.vatAmount_6 != 0 || expenseNote.vatAmount_12 != 0 )"
                style="background-color: #f8f9fa;" class="p-grid">

                <div *ngIf="expenseNote.vatAmount_21 != 0" class="p-col-6">{{"CUSTOMER_LIST_VAT" | translate}} 21%:
                </div>
                <div *ngIf="expenseNote.vatAmount_21 != 0" class="p-col-6 text-align-right number-total">{{
                  expenseNote.vatAmount_21 |
                  number:'1.2-2'}} </div>
              </div>
              <div class=" p-grid">
                <div class="p-col-6"> Total :</div>
                <div class="p-col-6 text-align-right number-total">{{ expenseNote.totalwithVat | number:'1.2-2'}}</div>
              </div>
              <hr class="divider">

              <div style="background-color: #f8f9fa;" class="p-grid p-mt-3 ">
                <div class="p-col-7">{{"PAYER_BALANCE" | translate}}:</div>
                <div class="p-col-5 text-align-right number-total">
                  <label *ngIf="expenseNote.payer?.due ; else balance">{{expenseNote.payer?.due |
                    number:'1.2-2'}}</label>
                  <ng-template #balance>
                    <label>0</label>
                  </ng-template>
                </div>
              </div>

            </div>
          </div>
        </p-card>

      </div>

    </div>
  </div>

</div>




<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1 " [header]="'View Receipt'" [(visible)]="viewAttachment_diloage"
  [style]="{width: '60vw'}">

  <div *ngIf="selectedexpense?.attachment?.type == 'application/pdf' ;else img_viewer" style="position: relative;"
    [style.height.px]="pdfheight">
    <pdf-viewer [src]="selectedexpense?.view_url" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
      (after-load-complete)="afterLoadComplete($event)" [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"
      (on-progress)="pdfLoader($event)"></pdf-viewer>
  </div>
  <ng-template #img_viewer>
    <div *ngIf="selectedexpense?.attachment?.type != 'application/pdf'" class="p-grid text-align-center">
      <div class="p-col-12"><img style="max-width: 800px !important;" [src]="selectedexpense?.view_url"></div>
    </div>


  </ng-template>

  <p-footer class="p-pt-4">
    <button *ngIf="selectedexpense?.attachment?.type == 'application/pdf'" type="button" pButton icon="fas fa-print"
      (click)="printPdf()" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
    <button type="button" pButton icon="pi pi-download" (click)="downloadlink()"
      label='{{"DOWNLOAD" | translate}}'></button>
    <button *ngIf="isSet(expenseNote?.items[itemIndex]?.attachment?.path ) " pButton type="button"
      (click)="deletefile()" icon="fas fa-trash " label='Delete Receipt' class=" p-button-danger "
      for="upload-photo"></button>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="viewAttachment_diloage=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>

  </p-footer>
</p-dialog>




<p-dialog modal="true" appendTo="body" [header]="paymentheader" (onShow)="outside_Status(true)"
  (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false" [(visible)]="showPaymentDialog"
  [style]="{width: '40vw'}">
  <div style="margin-top: 2px; " class="p-grid">

    <div class="p-col-6">
      <div class="form-text text-muted">Date</div>
      <p-calendar dateFormat="dd-mm-yy" appendTo="body" [inputStyle]="{'width':'18.5vw'}" class="calendar"
        [(ngModel)]="payment.dateP" #payment_date="ngModel" name="payment.dateP"
        placeholder='{{"DASHBORD_DATE" | translate}}'></p-calendar>
    </div>
    <div class="p-col-6">
      <div class="form-text text-muted">Payment Types</div>
      <p-dropdown appendTo="body" [(ngModel)]="payment.type" [options]="paymenttypes" optionValue="type"
        optionLabel="label"></p-dropdown>

    </div>


    <div class="p-col-12">
      <div class="form-text text-muted ">Amount
        <label *ngIf=" (payment.amount == null || amountt.invalid) &&  amountt.touched " class="alert-invalida">
          {{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

      </div>

      <p-inputNumber [min]="0"[inputStyle]="{'width': '38.2vw' }" id="Amount-input" #amountt="ngModel"
        (ngModelChange)="notempty($event)" [(ngModel)]="payment.amount" mode="decimal" [minFractionDigits]="2">
      </p-inputNumber>
    </div>
    <div class="p-col-12">
      <div class="form-text text-muted">Reference

      </div>
      <input [(ngModel)]="payment.notes" id="Reference -input" type="text" pInputText>

    </div>
  </div>

  <p-footer>
    <button type="button" pButton (click)="showPaymentDialog=false" icon="pi pi-times"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
    <button *ngIf="paymentheader=='Add Payment'" type="button" pButton (click)="addPayment()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="paymentheader=='Update Payment'" type="button" pButton (click)="updatePayment()"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}'></button>
    <button *ngIf="paymentheader=='Refund Payment'" type="button" pButton (click)="refundPayment()"
      label='{{"ORDERS_FORM_REFUND" | translate}}'></button>

  </p-footer>
</p-dialog>


<p-dialog modal="true" header="Import Expense" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)"
  [resizable]="false" [draggable]="false" [(visible)]="showImportExpenss" [style]="{width: '74vw'}">
  <div class="p-grid">


    <div class="p-col-12">

      <p-table styleClass="p-datatable-striped " [paginator]="true" [rows]="10" [value]="expenses"
        [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true" [(selection)]="selectedexpenses"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['format','ex_status','payer.name','date','total']">

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
              <span
                [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_QUOTE_NO" | translate}}</span>


              <p-columnFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('format',filterModeFormat_query?.type,filterModeFormat_query?.value)"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>

            </th>
            <th>Payer

            </th>
            <th>Status

            </th>
            <th>Date


            </th>

            <th>Total

            </th>

            <th>Receipt</th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a>{{ rowData?.format }}</a></td>
            <td>
              {{ rowData?.payer?.name }}
            </td>
            <td>
              {{ rowData?.ex_status }}
            </td>

            <td> {{ rowData?.date | date:'dd-MM-yyyy' }}</td>
            <td>{{ rowData?.total | number:'1.2-2'}}€</td>
            <td>
              <button *ngIf="isSet(rowData?.attachment?.path ) " style="margin-right: 5px;" pButton type="button"
                icon="fas fa-eye" (click)="showViewReceipt(i,'ImportExpense')" label='{{"VIEW_RECEIPT" | translate}}'
                class="p-button-raised p-button-secondary"></button>



            </td>


          </tr>
        </ng-template>
      </p-table>

    </div>

  </div>





  <p-footer>
    <button [disabled]="!selectedexpenses.length" type="button" pButton (click)="importExpense()"
      label='{{"IMPORT" | translate}}'></button>

    <button *ngIf="viewAttachment_diloage!=true" type="button" pButton (click)="showImportExpenss=false"
      icon="pi pi-times" label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false);canceleviewRecpie()" [resizable]="false"
  [draggable]="false" class="p-shadow-1 " [header]="'Confirm Receipt'" [(visible)]="viewRecpie"
  [style]="{width: '65vw'}">
  <div style="position: relative;" class="p-grid p-p-2">
    <div class="p-col-9">

      <div *ngIf="selectedexpense?.attachment?.type == 'application/pdf'" style="position: relative;"
        [style.height.px]="pdfheight">
        <p-tabView>
          <p-tabPanel header="PDF">

            <pdf-viewer [src]="selectedexpense?.view_url" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
              (after-load-complete)="afterLoadComplete($event)" [zoom-scale]="'page-width'" [zoom]="0.9"
              style="display: block" (on-progress)="pdfLoader($event)"></pdf-viewer>
          </p-tabPanel>

        </p-tabView>

      </div>
      <div *ngIf="selectedexpense?.attachment?.type != 'application/pdf'" class="p-grid">
        <p-tabView (onChange)="tabSelectedRecpit=!tabSelectedRecpit">

          <p-tabPanel header="AI Cropped ">

            <div *ngFor="let item of selectedexpense?.aiCropped" style="text-align: left !important; " class="p-col-12">
              <img style="width:38vw  !important; " [src]="displayimg_ai">
            </div>
          </p-tabPanel>
          <p-tabPanel header="Original">
            <div style="text-align: left !important; " class="p-col-12"><img style="width:38vw  !important; "
                [src]="displayimg_localy "></div>
          </p-tabPanel>
        </p-tabView>

      </div>



    </div>
    <div style="z-index: 1000;" class="p-col-3 ">


      <div *ngIf="selected_Dates?.length" style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
        class="p-col-12" class="p-grid p-mb-4">
        <div #vendor_name class="p-col-12">
          <label #vendor_namelabel class="formattext">There are {{selected_Dates?.length}} dates in receipt
            <br>
            Select one to approve : </label>


          <p-listbox [options]="selected_Dates" [(ngModel)]="selectedexpense.datePipe" optionLabel="date"
            optionValue="date">

            <ng-template let-item pTemplate="item">
              <label>
                {{item?.date|date:'dd-MM-yyyy'}}</label>
            </ng-template>
          </p-listbox>


        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid; " *ngIf="!selected_Dates?.length"
        class="p-grid p-mb-4">




        <div *ngIf="selectedexpense?.datePipe!=null || selectedexpense?.datePipe!=undefined" #selectedexpensedate
          class="p-col-12">
          <label #datelabel class="formattext">Date : </label>&nbsp;

          <p-inplace [style]="{display: 'inline-block'}" #selectedexpense_date>

            <span pInplaceDisplay>

              <label style=" color: var(--primary-color) !important; font-weight: 600;  ">
                {{selectedexpense?.datePipe|date:'dd-MM-yyyy'}}</label>
            </span>
            <p-calendar appendTo="body"
              [inputStyle]="{'width':selectedexpensedate.offsetWidth-datelabel.offsetWidth-65+'px'}"
              dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true"
              [(ngModel)]="selectedexpense.datePipe" pInplaceContent #date="ngModel" [required]="true"
              (onClose)="selectedexpense_date.deactivate()" (onClickOutside)="selectedexpense_date.deactivate()"
              (onSelect)="selectedexpense_date.deactivate()"></p-calendar>


          </p-inplace>
        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
        *ngIf="selectedexpense?.payment_type" class="p-grid p-mb-4">
        <div #selectedexppayment_type class="p-col-12 ">
          <label #payment_typeabel style="pointer-events: none; " class="formattext"> Payment Type :
          </label>&nbsp;
          <p-inplace [style]="{display: 'inline-block'}" #expenseNotepayment_type>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                {{selectedexpense?.payment_type}}</label>
            </span>

            <p-dropdown [style]="{width:selectedexppayment_type.offsetWidth-payment_typeabel.offsetWidth-35+'px'}"
              appendTo="body" [placeholder]="selectedexpense?.payment_type? null:'Payment Type'"
              [options]="paymenttypes" (ngModelChange)="notempty($event)" [(ngModel)]="selectedexpense.payment_type"
              optionValue="type" (onSelect)="expenseNotepayment_type.deactivate()"
              (onHide)="expenseNotepayment_type.deactivate()" optionLabel="label" pInplaceContent>

            </p-dropdown>


          </p-inplace>

        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " class="p-grid p-mb-4">
        <div #selectedexpex_type_2 class="p-col-12 ">
          <label #typelabel style="pointer-events: none; " class="formattext"> Category :
          </label>&nbsp;
          <p-inplace [style]="{display: 'inline-block'}" #expenseNoteex_type_2>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                <label *ngIf="selectedexpense.ex_type_2 == null;else selected">Not selected</label>
              </label>
              <ng-template #selected>
                {{selectedexpense?.ex_type_2}}

              </ng-template>
            </span>



            <p-dropdown id="category-input" appendTo="body"
              [placeholder]="selectedexpense.ex_type_2? null:'Select Category'" [options]="expenseNoteType2"
              (ngModelChange)="notempty($event)" [(ngModel)]="selectedexpense.ex_type_2" optionValue="ex_type_2"
              optionLabel="ex_type_2" (onSelect)="expenseNoteex_type_2.deactivate()"
              (onHide)="expenseNoteex_type_2.deactivate() " pInplaceContent>
            </p-dropdown>

          </p-inplace>

        </div>

      </div>
      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.ex_type"
        class="p-grid p-mb-4">
        <div #selectedexpex_type class="p-col-12 ">
          <label #typelabel style="pointer-events: none; " class="formattext"> Type :
          </label>&nbsp;
          <p-inplace [style]="{display: 'inline-block'}" #expenseNoteex_type>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                {{selectedexpense?.ex_type}}</label>
            </span>

            <p-dropdown [style]="{width:selectedexpex_type.offsetWidth-typelabel.offsetWidth-35+'px'}" appendTo="body"
              [options]="expenseNoteType" (ngModelChange)="notempty($event)" [(ngModel)]="selectedexpense.ex_type"
              optionValue="ex_type" optionLabel="ex_type" (onClickOutside)="expenseNoteex_type.deactivate()"
              (onHide)="expenseNoteex_type.deactivate()" (onSelect)="expenseNoteex_type.deactivate()" pInplaceContent>
            </p-dropdown>
          </p-inplace>

        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.amount"
        class="p-grid p-mb-4">
        <div #selectedexpenseamount class="p-col-12">
          <label #amountlabel class="formattext">Amount : </label>&nbsp;


          <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.amount}}</label>

            </span>
            <p-inputNumber [min]="0"[inputStyle]="{'width':selectedexpenseamount.offsetWidth-amountlabel.offsetWidth-30+'px'}"
              id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
              [(ngModel)]="selectedexpense.amount" mode="decimal" [minFractionDigits]="2"
              (onBlur)="expenseamount.deactivate()" pInplaceContent></p-inputNumber>




          </p-inplace>
        </div>

      </div>
      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.vat_21"
        class="p-grid p-mb-4">
        <div #selectedexpenseamount class="p-col-12">
          <label #vat_21label class="formattext">Vat 21% : </label>&nbsp;


          <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vat_21}}</label>

            </span>
            <p-inputNumber [min]="0"[inputStyle]="{'width':selectedexpenseamount.offsetWidth-vat_21label.offsetWidth-30+'px'}"
              id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
              [(ngModel)]="selectedexpense.vat_21" mode="decimal" [minFractionDigits]="2"
              (onBlur)="expenseamount.deactivate()" pInplaceContent></p-inputNumber>




          </p-inplace>
        </div>

      </div>
      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.vat_12"
        class="p-grid p-mb-4">
        <div #selectedexpenseamount class="p-col-12">
          <label #vat_12label class="formattext">Vat 12% : </label>&nbsp;


          <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vat_12}}</label>

            </span>
            <p-inputNumber [min]="0"[inputStyle]="{'width':selectedexpenseamount.offsetWidth-vat_12label.offsetWidth-30+'px'}"
              id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
              [(ngModel)]="selectedexpense.vat_12" mode="decimal" [minFractionDigits]="2"
              (onBlur)="expenseamount.deactivate()" pInplaceContent></p-inputNumber>




          </p-inplace>
        </div>

      </div>
      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.vat_6"
        class="p-grid p-mb-4">
        <div #selectedexpenseamount class="p-col-12">
          <label #vat_6label class="formattext">Vat 6% : </label>&nbsp;


          <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vat_6}}</label>

            </span>
            <p-inputNumber [min]="0"[inputStyle]="{'width':selectedexpenseamount.offsetWidth-vat_6label.offsetWidth-30+'px'}"
              id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
              [(ngModel)]="selectedexpense.vat_6" mode="decimal" [minFractionDigits]="2"
              (onBlur)="expenseamount.deactivate()" pInplaceContent></p-inputNumber>




          </p-inplace>
        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.vendor_name"
        class="p-grid p-mb-4">
        <div #vendor_name class="p-col-12">
          <label #vendor_namelabel class="formattext">Vendor : </label>&nbsp;


          <p-inplace [style]="{display: 'inline-block'}" #vendor_nameselected>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vendor?.name}}</label>

            </span>

            <app-supplier-select (supplierChange)="selectvendor_2($event)" [customstyle]='true'
              [width]='vendor_name.offsetWidth-vendor_namelabel.offsetWidth-30' [(supplier)]="selectedexpense.vendor"
              (onHide)="vendor_nameselected.deactivate()" pInplaceContent>
            </app-supplier-select>






          </p-inplace>
        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_VATs?.length > 1"
        class="p-grid p-mb-4">
        <div #vendor_name class="p-col-12">
          <label #vendor_namelabel class="formattext">There are {{selected_VATs?.length}} vats in receipt
            <br>
            Select one :</label>


          <p-listbox [options]="selected_VATs" [(ngModel)]="selectedexpense.vat" optionLabel="vat" optionValue="vat">
          </p-listbox>


        </div>

      </div>


      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_VATs?.length==1"
        class="p-grid p-mb-4">
        <div #vat_name class="p-col-12">
          <label #vat_label2 class="formattext">Vat : </label>

          <p-inplace [style]="{display: 'inline-block'}" #vat_formatelected>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vat}}</label>
              &nbsp;

            </span>

            <input [style]="{'width':vat_name?.offsetWidth-vat_label2?.offsetWidth-30+'px'}" name="selectedexpense.vat"
              [(ngModel)]="selectedexpense.vat" (ngModelChange)="notempty($event)"
              (blur)="vat_formatelected.deactivate()" type="text" pInplaceContent pInputText>


          </p-inplace>



        </div>

      </div>

      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_IBans?.length > 1"
        class="p-grid p-mb-4">
        <div #vendor_name class="p-col-12">
          <label #vendor_namelabel class="formattext">There are {{selected_IBans?.length}} IBAN in receipt
            <br>
            Select one :</label>


          <p-listbox [options]="selected_IBans" [(ngModel)]="selectedexpense.iban" optionLabel="iban"
            optionValue="iban">
          </p-listbox>


        </div>

      </div>


      <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_IBans?.length==1"
        class="p-grid p-mb-4">
        <div #iban class="p-col-12">
          <label #vat_label2 class="formattext">IBAN : </label>&nbsp;

          <p-inplace [style]="{display: 'inline-block'}" #iban_formatelected>

            <span pInplaceDisplay>

              <label
                style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.iban}}</label>
              &nbsp;

            </span>

            <input [style]="{'width':iban.offsetWidth-vat_label2.offsetWidth-30+'px'}" name="selectedexpense.iban"
              [(ngModel)]="selectedexpense.iban" (ngModelChange)="notempty($event)"
              (blur)="iban_formatelected.deactivate()" type="text" pInplaceContent pInputText>


          </p-inplace>



        </div>

      </div>



      <div *ngIf="selectedexpense?.vcs" style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
        class="p-grid p-mb-4">
        <div #vcs_name class="p-col-12">
          <label #vcs_label2 class="formattext">VCS : </label>&nbsp;

          <p-inplace [style]="{display: 'inline-block'}" #vcs_formatelected>

            <span pInplaceDisplay>

              <label style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vcs}}
              </label>
              &nbsp;

            </span>

            <input [style]="{'width':vcs_name.offsetWidth-vcs_label2.offsetWidth-30+'px'}" name="selectedexpense.vat"
              [(ngModel)]="selectedexpense.vcs" (ngModelChange)="notempty($event)"
              (blur)="vcs_formatelected.deactivate()" type="text" pInplaceContent pInputText>


          </p-inplace>



        </div>

      </div>
    </div>

  </div>


  <p-footer class="p-pt-4">


    <button *ngIf="selectedexpense?.attachment?.type == 'application/pdf' " (click)="confirmRreceipt_withpdf()"
      type="button" pButton icon="pi pi-check" label='{{"CUSTOMER_LIST_SAVE" | translate}}'></button>

    <button *ngIf="selectedexpense?.attachment?.type != 'application/pdf' && tabSelectedRecpit == true" type="button"
      pButton icon="pi pi-check" (click)="confirmRreceipt_withOriginal()" label="Save with Original"></button>
    <button *ngIf="selectedexpense?.attachment?.type != 'application/pdf' && tabSelectedRecpit == false" type="button"
      pButton icon="pi pi-check" (click)="confirmRreceipt_withCropped()" label="Save with AI Cropped "></button>


    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="canceleviewRecpie()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>

  </p-footer>
</p-dialog>
