<p-toast key="form" position="top-right"></p-toast>

<app-loading *ngIf="loading"></app-loading>
<div class="p-grid purchase-form">
  <div [ngClass]="{ 'p-col-12': qouteChecks == true }" class="p-col-8">
    <p-card>
      <div class="p-grid">
        <div [class]=" qouteChecks == true? 'p-col-8': 'p-col-7'">
          <div class="form-group" (click)="openDropDown()">
            <p-inplace #vendors>
              <span id="vendor" aria-required="true" pInplaceDisplay>
                <button *ngIf="purchaseOrder?.vendor" pButton [icon]="editvendoricon" iconPos="right"
                  [label]="purchaseOrder.vendor.name" type="button"
                  class="p-button-text p-button-plain p-ripple p-button p-component customerinplace customerinplace-icon"></button>

                <button *ngIf="!purchaseOrder.vendor" pButton label='{{"NOTES_SUPPLIER_FORM_VENDOR" | translate}}'
                  icon="fas fa-id-card-alt tags" type="button"
                  class="p-button-text p-button-plain p-ripple p-button p-component customerinplace"></button>

                <div *ngIf="purchaseOrder?.vendor == null && vendorOrder == true" class="alert-vendor">
                  {{"CUSTOMER_FORM_REQUIRED" | translate}}
                </div>
              </span>
              <app-supplier-select pInplaceContent (supplierChange)="supplierChange($event)"
                [(supplier)]="purchaseOrder.vendor" (onHide)="vendors.deactivate()"></app-supplier-select>
            </p-inplace>
          </div>

          <div style="font-weight: 600" class="p-grid text-muted">
            <div *ngIf="purchaseOrder?.vendor?.address" class="p-col-6">
              <span>{{ purchaseOrder.vendor?.address[0]?.address }}</span><br />
              <span>{{ purchaseOrder.vendor?.address[0]?.zip }}</span><br />
              <span>{{ purchaseOrder.vendor?.address[0]?.city }}</span><br />
              <span>{{ purchaseOrder.vendor?.address[0]?.country_code }}</span><br />
              <span *ngIf="purchaseOrder?.vendor?.vat">{{"CUSTOMER_LIST_VAT" | translate}}: </span>
              <span>{{ purchaseOrder?.vendor?.vat }}</span>
            </div>
            <div *ngIf="purchaseOrder?.vendor?.contacts?.length" style="border-left: 2px solid var(--surface-c)"
              class="p-col-6">
              <span class="bold">{{"CONTACTS" | translate}} </span><br />
              <span *ngIf="purchaseOrder?.vendor?.contacts[0]?.email">{{"CUSTOMER_FORM_EMAIL" | translate}}:
                <span>{{purchaseOrder?.vendor?.contacts[0]?.email}}</span></span><br />
              <span *ngIf="purchaseOrder?.vendor?.contacts[0]?.phone">{{"CUSTOMER_FORM_PHONE" | translate}}:
                <span>{{purchaseOrder?.vendor?.contacts[0]?.phone}}</span></span><br />
            </div>
          </div>
        </div>

        <div [class]=" qouteChecks == true? 'p-col-4': 'p-col-5'">
          <div class="form-group order-header-numbers  ">
            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">
                  <span *ngIf="purchaseOrder?.type  == 'Quote'; else p">RQ</span>
                  <ng-template #p>{{"PO" | translate}}</ng-template>
                  {{"INTERNAL_REFRENACE" | translate}} :
                </label>
              </div>
              <div class="p-col-6  ">
                <p-inplace *ngIf="qouteChecks == null" [disabled]="selected_PurchaseOrders" #orderNumber>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.internal_reference" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.internal_reference" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component"></button>
                    <button *ngIf="
                        purchaseOrder.internal_reference == '' &&
                        internal_reference.touched
                      " style="font-weight: 600" pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid"></button>
                  </span>
                  <input #internal_reference="ngModel" [(ngModel)]="purchaseOrder.internal_reference" type="text"
                    pInputText pInplaceContent (blur)="orderNumber.deactivate()" />
                </p-inplace>

                <p-inplace *ngIf="qouteChecks == true" [disabled]="selected_PurchaseOrders" #QuoteNumber>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.internal_reference" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.internal_reference" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component"></button>

                    <button *ngIf="
                        purchaseOrder.internal_reference == '' &&
                        internal_reference.touched
                      " style="font-weight: 600" pButton type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid"></button>
                  </span>
                  <input #internal_reference="ngModel" [(ngModel)]="purchaseOrder.internal_reference" type="text"
                    pInputText pInplaceContent (blur)="QuoteNumber.deactivate()" />
                </p-inplace>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">
                  <span *ngIf="purchaseOrder?.type  == 'Quote'; else p">RQ</span>
                  <ng-template #p>{{"PO" | translate}}</ng-template>
                  Date :
                </label>
              </div>
              <div class="p-col-6">
                <p-inplace #orderDate>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.date" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton (click)="focusThenShow('date')"
                            [label]="purchaseOrder.date | date: 'dd-MM-yyyy'" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component"></button>
                    <button *ngIf="purchaseOrder?.date == null " style="font-weight: 600" pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                      class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid"></button>
                  </span>
                  <p-calendar dateFormat="dd-mm-yy" appendTo="body" showIcon="true" [(ngModel)]="purchaseOrder.date"
                    pInplaceContent #date="ngModel" [required]="true" (onClose)="orderDate.deactivate()" #rqDateToggle
                    (onClickOutside)="orderDate.deactivate()" (onSelect)="orderDate.deactivate()"></p-calendar>
                </p-inplace>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">
                  <span *ngIf="purchaseOrder?.type  == 'Quote'; else p">RQ</span>
                  <ng-template #p>{{"PO" | translate}}</ng-template>
                  {{"INRENAL_INVOICE" | translate}} :
                </label>
              </div>
              <div class="p-col-6  ">
                <p-inplace *ngIf="qouteChecks == null" [disabled]="selected_PurchaseOrders" #orderNumber>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.internal_invoice" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.internal_invoice" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component"></button>
                    <button *ngIf="
                        purchaseOrder.internal_invoice == ''
                      " style="font-weight: 600" pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid"></button>
                  </span>
                  <input #internal_reference="ngModel" [(ngModel)]="purchaseOrder.internal_invoice" type="text"
                    pInputText pInplaceContent (blur)="orderNumber.deactivate()" />
                </p-inplace>

                <p-inplace *ngIf="qouteChecks == true" [disabled]="selected_PurchaseOrders" #QuoteNumber>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.internal_reference" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.internal_reference" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component"></button>

                    <button *ngIf="
                        purchaseOrder.internal_reference == '' &&
                        internal_reference.touched
                      " style="font-weight: 600" pButton type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid"></button>
                  </span>
                  <input #internal_reference="ngModel" [(ngModel)]="purchaseOrder.internal_reference" type="text"
                    pInputText pInplaceContent (blur)="QuoteNumber.deactivate()" />
                </p-inplace>
              </div>
            </div>


            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">{{"INVO_REFRENCE" | translate}} :</label>
              </div>
              <div class="p-col-6 p-mt-1">
                <p-inplace #Vendorinvoive>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.vendor_invoice" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.vendor_invoice" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component "></button>

                    <button *ngIf="!purchaseOrder.vendor_invoice" style="font-weight: 600" pButton type="button"
                      label='{{"ADD_REFRENCE" | translate}}' icon="fas fa-plus" class="  p-button-outlined"></button>
                  </span>
                  <input #vendor_invoice="ngModel" [(ngModel)]="purchaseOrder.vendor_invoice"
                    (ngModelChange)="notempty($event);disable_VatsRatesChecks=true" type="text" pInputText
                    pInplaceContent (blur)="Vendorinvoive.deactivate()" />
                </p-inplace>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">{{"INVO_DATE" | translate}} :</label>
              </div>
              <div class="p-col-6 p-mt-1">


                <p-inplace #invoice_date>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.invoice_date" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton (click)="focusThenShow('invoice')"
                            [label]="purchaseOrder.invoice_date | date: 'dd-MM-yyyy'" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component "></button>

                    <button *ngIf="!purchaseOrder.invoice_date" style="font-weight: 600" pButton type="button" (click)="focusThenShow('invoice')"
                      label='{{"ADD_INVO_DATE" | translate}}' icon="fas fa-plus" class="  p-button-outlined"></button>
                  </span>

                  <p-calendar dateFormat="dd-mm-yy" appendTo="body" showIcon="true"
                    [(ngModel)]="purchaseOrder.invoice_date" (ngModelChange)="notempty($event)" pInplaceContent
                    #date="ngModel" [required]="true" (onClose)="invoice_date.deactivate()" #rqInvoiceDateToggle
                    (onClickOutside)="invoice_date.deactivate()" (onSelect)="invoice_date.deactivate()"></p-calendar>
                </p-inplace>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-6 text-align-right">
                <label class="formattext">{{"VENDOR_ORDER" | translate}} Ref :</label>
              </div>
              <div class="p-col-6 p-mt-1">
                <p-inplace #VendorNumber>
                  <span pInplaceDisplay>
                    <button *ngIf="purchaseOrder?.vendor_order" style="
                        color: var(--primary-color) !important;
                        font-weight: 600;
                      " pButton [label]="purchaseOrder.vendor_order" type="button"
                      class="p-button-text p-button-plain p-ripple p-button p-component "></button>
                    <button *ngIf="!purchaseOrder.vendor_order" style="font-weight: 600" pButton type="button"
                      label='{{"ADD_REFRENCE" | translate}}' icon="fas fa-plus" class="  p-button-outlined"></button>
                  </span>
                  <input #vendor_order="ngModel" (ngModelChange)="notempty($event);disable_VatsRatesChecks=true"
                    [(ngModel)]="purchaseOrder.vendor_order" type="text" pInputText pInplaceContent
                    (blur)="VendorNumber.deactivate()" />
                </p-inplace>
              </div>
            </div>

            <div *ngIf="purchaseOrder.exchange_rateStauts == true" class="p-grid">
              <div class="p-col-6  text-align-right">
                <label class="formattext p-pr-2">{{"EXCHANGE_RATE" | translate}} :</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-6 ">
                <p-inplace [ngStyle]="{cursor: 'pointer'}" #exchange_rate>
                  <span pInplaceDisplay>
                    <button *ngIf=" purchaseOrder?.exchange_rate "
                      style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                      [label]=" purchaseOrder?.currency?.name " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                      1 {{default_currency.name}} <i style="margin-left: 1px; margin-right: 1px;"
                        class="fas fa-arrow-right"></i> <label style="margin-right: 2px;"> {{
                        purchaseOrder?.exchange_rate |
                        number:'1.2-2'}} </label>
                    </button>


                  </span>



                  <p-inputNumber [min]="0" [disabled]="purchaseOrder?.status?.invoiced"
                    (onFocus)="$event.target.select()" (keyup.enter)="onKeyUp($event)"
                    (ngModelChange)="notempty($event);exchangeRateChange()" [(ngModel)]=" purchaseOrder.exchange_rate"
                    mode="decimal" [minFractionDigits]="2" (onBlur)="exchange_rate.deactivate();exchangeRateChange()"
                    pInplaceContent></p-inputNumber>
                </p-inplace>

              </div>
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{default_currency?.name}} Summary :</label>
                  </div>
                  <div class="p-col-6 p-mt-2  text-center">
                    <div class="p-field-checkbox">
                      <p-checkbox [(ngModel)]="defaultcurrency_status" binary="true"></p-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-4" *ngIf="qouteChecks == null">
    <p-card>
      <div #paymentcard class="p-grid">
        <div class="p-col-12 ">

          <h3 aria-required="true" class="table-inplace">
            <i class="fa-menu fa fa-money-bill-alt tags"></i>
            {{"ORDERS_FORM_PAYMENTS" | translate}}
          </h3>
        </div>

        <div class="p-col-12">

          <p-table id="payment-table" [resizableColumns]="true" [rowHover]="true" [scrollable]="true"
            scrollHeight="153px" [value]="purchaseOrder?.payments">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 50%; "> {{"QUOTES_LIST_TOTAL" | translate}}:
                  <span class="p-text-bold" style="color: #51749e;">{{ purchaseOrder?.orderTotalIncVAT |
                    number:'1.2-2'}}</span>

                </th>
                <th class="text-align-right" style="width: 50%;">
                  <div> {{"ORDERS_FORM_DUE" | translate}}:
                    <span class="p-text-bold" style="color: #51749e;">{{paymentDue | number:'1.2-2'}}</span>

                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
              <tr
                [ngStyle]="{cursor: rowData.status2=='new' && i==purchaseOrder?.payments.length-1 && rowData.notes!='Total Changed'?'pointer':'auto'}"
                (click)="paymentReview(rowData,i)">
                <!-- <td style="width: 50px;">

            </td> -->
                <td>
                  <div
                    [ngClass]="{'p-ml-5': rowData.status2=='new'  && i==purchaseOrder?.payments.length-1 && rowData.notes!='Total Changed'}"
                    class="p-text-bold ">
                    {{rowData.notes}}</div>
                  <div style="position: relative;"
                    [ngClass]="{'p-ml-5': rowData.status2=='new' && i==purchaseOrder?.payments.length-1 && rowData.notes!='Total Changed'}"
                    class="p-text-light ">
                    <button style="position: absolute; left: -36px;
                top: -12px;"
                      *ngIf="rowData.status2=='new' && i==purchaseOrder?.payments.length-1 && rowData.notes!='Total Changed'"
                      (click)="deletePayment(i)" pButton pRipple type="button" icon="fas fa-trash"
                      class="p-button-lg p-button-text p-button-danger "></button>
                    {{ rowData.dateP | date:'d-M-y' }}
                    <p-tag styleClass="ml-3" severity="primary">{{ rowData.type |translate}}</p-tag>
                  </div>
                </td>
                <td style="text-align:end;">
                  <span class="p-text-bold " style="color: #51749e;">
                    <label *ngIf="rowData.status == 'refund'">-</label>
                    {{rowData.amount | number:'1.2-2'}}</span>
                </td>

              </tr>


            </ng-template>

            <ng-template pTemplate="summary">
              <div class="p-grid ">
                <div class="p-col-6">

                  <p-splitButton #ua appendTo="body" [disabled]="paymentDuestatus "
                    label='{{"PAYMENT" | translate}}' icon="fas fa-plus" (onClick)="showAddPaymnet()"
                    [model]="itemsSave" class="ui-button-raised ui-button-info"></p-splitButton>

                </div>
                <div style="text-align: end !important;" class="p-col-6 ">
                  <button pButton class="p-button-danger " (click)="showrefundPayment()"
                    [disabled]="purchaseOrder?.payments?.length==0 || purchaseOrder?.orderTotalIncVAT ==  paymentDue"
                    icon="fas fa-minus" type="button" label='{{"ORDERS_FORM_REFUND" | translate}}'></button>

                </div>

              </div>

            </ng-template>
          </p-table>



        </div>

      </div>

    </p-card>
  </div>
  <div class="p-col-12">
    <p-card>
      <p-table [value]="purchaseOrder.items" styleClass="p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 20%">{{"QUOTES_Form_ITEM" |translate}}</th>
            <th style="width: 16%">{{"DASHBORD_DATE" | translate}}</th>
            <th style="width: 10%">{{"QUOTES_Form_QTY" | translate}}</th>
            <th style="width: 11%" *ngIf="qouteChecks == null">{{"QUOTES_Form_PRICE" | translate}} </th>
            <th style="width: 10%" *ngIf="qouteChecks == null">{{"CUSTOMER_LIST_VAT" | translate}} <i
                class="fas fa-percent"></i></th>
            <th style="width: 15%" *ngIf="qouteChecks == null">
              {{"WAREHOUSE" |translate}}
            </th>
            <th style="width: 11%" *ngIf="qouteChecks == null">{{"DELIVERD" | translate}}</th>
            <th style="width: 9%" *ngIf="qouteChecks == null">{{"REMIANED" | translate}}</th>
            <th style="width: 12%" *ngIf="qouteChecks == null">{{"QUOTES_LIST_TOTAL" | translate}} </th>
            <th *ngIf="purchaseOrder?.items?.length > 1   && checkdiscountPy==false"
              [style.width]="qouteChecks? 1.5+'%': 4+'%'"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td #productelemt id="item">
              <app-products-select (keyup.enter)="onKeyUp($event)" [width]="100+'%'"
                *ngIf="((isSet(item.product?.id) && item.product?.id !='') || isSet(item?.name)) && item.checkinlne==false ; else checkinlineProduct"
                (service)="serviceItem($event,i)" (inlineProduct)="inlineprodcut($event,i)" [(product)]="item.product"
                (onChange)="productChanged($event, i)">
              </app-products-select>
              <div *ngIf=" item.name == null && productTouched == true " class="alert-Item">{{"CUSTOMER_FORM_REQUIRED" |
                translate}} </div>

              <ng-template #checkinlineProduct>
                <input *ngIf="isSet(item?.name)  ; else inline" #service="ngModel" (ngModelChange)="notempty($event)"
                  [(ngModel)]="item.name" type="text" pInputText>
                <ng-template #inline>
                  <div #inlineProductt class="inlineProduct">
                    <p-editor id="inlineProduct" name="inline" readonly=true [(ngModel)]="item.service" ngDefaultControl
                      [ngClass]="{'showless': item.inline_Epand==false || item.inline_Epand==null}"> </p-editor>
                    <div class="inlineProduct-btnSetings">
                      <button *ngIf="(inlineProductt?.offsetHeight > 138) || (inlineProductt?.offsetHeight > 100)"
                        type="button" pButton class="p-button-text  p-button-secondary"
                        (click)="expand_inLineProduct(i)" [pTooltip]="item.inline_Epand==true  ?'Compress':'Expand'"
                        tooltipPosition="bottom"
                        [icon]="item.inline_Epand==true  ? 'fas fa-compress-alt':'fas fa-expand-alt'"></button>

                      <button type="button" pButton class="p-button-text p-ml-1" (click)="showEidtinlineProduct(i)"
                        pTooltip='{{"CUSTOMER_LIST_EDIT" | translate}}' tooltipPosition="bottom"
                        icon="fas fa-edit"></button>

                    </div>
                  </div>
                </ng-template>

              </ng-template>
              <!-- [width]="productelemt.offsetWidth-30+'px'"  -->
            </td>
            <td style="width: 15%" #date>
              <p-calendar [inputStyle]="{'width':qouteChecks?date.offsetWidth-68+'px':null}" showIcon="true"
                dateFormat="dd-mm-yy" appendTo="body" [(ngModel)]="item.datePipe" name="item.datePipe"
                (change)="onChange_Item(i, null)" (ngModelChange)="notempty($event);disable_VatsRatesChecks=true">
              </p-calendar>
            </td>
            <td #qty style="width: 15%">
              <div class="clearable-input">
                <input #item.qty [style.width]="qouteChecks?qty.offsetWidth-30+'px':null"
                  (keypress)="numberOnly($event)" (change)="onChange_Item(i, 'qty')" (focus)="$event.target.select()"
                  name="item.qty" (ngModelChange)="notempty($event)" [(ngModel)]="item.qty" type="text" pInputText />
                <i *ngIf="isSet(item.qty) && item.qty !== 1 && item.qty !== ''" (click)="erace_value(i, 'qty')"
                  class="pi pi-times"></i>
              </div>
            </td>
            <td style="width: 15%" *ngIf="qouteChecks == null">
              <div class="clearable-input">
                <!-- <input #item.price name="item.price" (focus)="$event.target.select()" (click)="erace_Default(i)"
                  (change)="onChange_Item(i, 'price')" (ngModelChange)="notempty($event)" [(ngModel)]="item.price"
                  type="text" pInputText /> -->
                <p-inputNumber *ngIf="default_currency?.id == purchaseOrder?.currency?.id; else qprice"
                  [disabled]="purchaseOrder?.status?.invoiced" [min]="0" #item.price (onFocus)="$event.target.select()"
                  (ngModelChange)="notempty($event)" [(ngModel)]="item.price" mode="decimal" [minFractionDigits]="2">
                </p-inputNumber>

                <ng-template #qprice>
                  <!-- *ngIf="item?.product?.id?.length > 4 else qqprice" -->
                  <p-inputNumber #item.price [min]="0" mode="currency" [disabled]="purchaseOrder?.status?.invoiced"
                    currency="{{default_currency?.name}}" locale="de-DE" currencyDisplay="code"
                    (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.price"
                    [minFractionDigits]="2"></p-inputNumber>
                  <!-- <ng-template #qqprice>
                    <p-inputNumber #item.price [min]="0" mode="currency" currency="{{ quote?.currency?.name}}"
                      locale="de-DE" currencyDisplay="code" (onFocus)="$event.target.select()"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.price" [minFractionDigits]="2">
                    </p-inputNumber>

                  </ng-template> -->
                </ng-template>

                <i *ngIf="isSet(item.price) && item.price !== 0" (click)="erace_value(i, 'price')"
                  class="pi pi-times"></i>
              </div>
            </td>
            <td style="width: 10%" *ngIf="qouteChecks == null">
              <p-dropdown (ngModelChange)="notempty($event)" (keyup.enter)="onKeyUp($event)"
                (onChange)="onChange_Item(i,'vat')" appendTo="body" [options]="vat_Percentage" [(ngModel)]="item.vat"
                optionLabel="value">
              </p-dropdown>
            </td>


            <td style="width: 20%" *ngIf="qouteChecks == null">
              <p-dropdown #dd filter="true" [(ngModel)]="item.warehouse" (onChange)="warehouseChanged($event, i)"
                [options]="warehousess" appendTo="body" (ngModelChange)="notempty($event)" placeholder="Warehouse"
                optionLabel="name">
                <ng-template let-warehouse pTemplate="warehouse">
                  <div class="p-grid">
                    <div class="p-col-8">
                      <span [innerHTML]="warehouse.name"></span>
                    </div>
                    <div class="p-col-4">
                      <button *ngIf="warehouse.id != 0 && warehouse.id != -1" [ngClass]="{
                          lighteidtbtn: item.warehouse?.id == warehouse?.id
                        }" style="z-index: 10000" pButton (click)="showeditWarehouse(warehouse)"
                        pTooltip="Edit {{ warehouse.name }}" tooltipPosition="bottom" class="p-button-text p-ml-sm-1"
                        type="button" icon="fas fa-edit"></button>
                      <button *ngIf="warehouse.id != 0 && warehouse.id != -1" style="z-index: 10000" pButton
                        (click)="showdeleteWarehouse(warehouse)" pTooltip="Delete {{ warehouse?.name }}"
                        tooltipPosition="bottom" class="p-button-text p-button-danger" type="button"
                        icon="fas fa-trash"></button>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </td>
            <td style="width: 15%" *ngIf="qouteChecks == null">


              <p-inputNumber [min]="0" (ngModelChange)="notempty($event)" [max]="item.qty"
                [(ngModel)]="item.received_qty" mode="decimal" inputId="withoutgrouping" [useGrouping]="false">
              </p-inputNumber>

            </td>
            <td *ngIf="qouteChecks == null">
              <div>{{ item.remained | number }}</div>
            </td>


            <td  *ngIf="qouteChecks == null">

              <div style="font-weight: 800;" class="number-total"
                *ngIf="purchaseOrder.exchange_rate >0 && defaultcurrency_status ;else currency2">
                {{item?.default_currncey_item_total | number:'1.2-2'}} <span
                  style="font-size: small;font-weight: 600 !important;">{{ default_currency?.name }} </span> <label
                  class="divide">/</label>
                {{item?.item_total | number:'1.2-2'}} <span style="font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span>

              </div>
              <ng-template #currency2>
                <span style=" color: var(--primary-color) !important; font-weight: 800;">

                  {{ item?.item_total | number:'1.2-2'}}
                </span>

              </ng-template>


            </td>
            <td *ngIf="purchaseOrder?.items.length > 1 && checkdiscountPy==false">
              <button id='rm' (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null"
                (keyup.enter)="onKeyUp($event)" (click)="remomveItem(i)" pButton pRipple type="button"
                icon="fas fa-trash" class="p-button-text  p-button-delete p-button-danger"></button>

            </td>
          </tr>
        </ng-template>
      </p-table>
      <button *ngIf=" checkdiscountPy==false" (click)="addItem()" pButton type="button"
        label='{{"CUSTOMER_FORM_ADD" | translate}}' class="ui-button-raised p-mt-2"></button>
      <label class="p-ml-3">{{"QUOTES_Form_OR_PREES" | translate}} <code>ctrl+enter</code></label>
    </p-card>
  </div>
  <div style="padding-bottom: 0vh !important" [class]="qouteChecks?'p-col-12':'p-col-8'">
    <p-card>
      <div [style.height.px]="qouteChecks?200:total_Height?.offsetHeight" class="p-grid">
        <div class="p-col-6">
          <div class="form-group">
            <span class="p-float-label">
              <textarea [(ngModel)]="purchaseOrder.notes"
                (ngModelChange)="notempty($event);disable_VatsRatesChecks=true" id="message-input" rows="6"
                autoResize="false" pInputTextarea></textarea>
              <label for="message-input">{{"QUOTES_Form_MESSAGE" | translate}}</label>
            </span>
          </div>
        </div>
        <div class="p-col-6">
          <div class="form-group">
            <span class="p-float-label">
              <textarea id="payment-terms-input" [(ngModel)]="purchaseOrder.payment_terms"
                (ngModelChange)="notempty($event);disable_VatsRatesChecks=true" rows="6" autoResize="false"
                pInputTextarea></textarea>
              <label for="payment-terms-input">{{"CUSTOMER_FORM_PAY_TERMS" | translate}}</label>
            </span>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div [style.display]="qouteChecks==true?'none':null" style="display: block;" class="p-col-4">

    <p-card>
      <div #total_Height class=" order-summary text-muted">

        <div style="background-color: #f8f9fa; position: relative;" class="p-grid">
          <div style=" padding-top: 15px;" class="p-col-6">
            {{"DISCOUNT_EXCL_VAT" | translate}}:
          </div>
          <div class="p-col-6 text-align-right">
            <p-inplace [disabled]="paymentDue==0" #discount>

              <span pInplaceDisplay>
                <span *ngIf="purchaseOrder?.discount>0 && selected_discount_type?.type == 'Amount'"
                  style="font-weight: 800;" class="  number-total">{{purchaseOrder?.discount |number:'1.2-2'}}
                  <span style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    default_currency?.name }}
                  </span>
                </span>

                <span *ngIf="purchaseOrder?.discount>0 && selected_discount_type?.type == 'Percent'"
                  style="font-weight: 800;" class="  number-total">{{purchaseOrder?.discount |number:'1.2-2'}}%</span>

                <p-selectButton [disabled]="paymentDue==0" *ngIf="purchaseOrder?.discount==0" [options]="discount_type"
                  [(ngModel)]="selected_discount_type" optionLabel="label">
                </p-selectButton>

              </span>
              <div class="p-inputgroup" pInplaceContent>

                <p-inputNumber [min]="0" *ngIf="selected_discount_type?.type == 'Amount'"
                  [(ngModel)]="purchaseOrder.discount" (onFocus)="$event.target.select()"
                  (ngModelChange)="total_discountchange()" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                <p-inputNumber [min]="0" *ngIf="selected_discount_type?.type == 'Percent'" prefix="%"
                  (ngModelChange)="total_discountchange()" [(ngModel)]="purchaseOrder.discount" max="100">
                </p-inputNumber>

                <button type="button"
                  [disabled]="(purchaseOrder?.discount > purchaseOrder.beforeDiscont_orderTotalIncVAT && purchaseOrder.discount_type == 'Amount') || disable_payments == true"
                  (click)="discount.deactivate()" pButton pRipple icon="pi pi-check"></button>

                <button type="button" (click)="discount.deactivate();cleardiscont()" pButton pRipple icon="pi pi-times"
                  class="p-button-danger"></button>

              </div>
            </p-inplace>
          </div>
          <div *ngIf="purchaseOrder?.discount>0" class="p-col-12">

            <div class="p-field-checkbox">
              <p-checkbox [(ngModel)]="show_afterdiscont" binary="true" inputId="binary"></p-checkbox>
              <label for="binary"> {{"SHOW_BF_DS" | translate}}</label>
            </div>
          </div>

        </div>

        <div class="p-grid p-mt-3">
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}" class="p-col-5">{{"QUOTES_Form_TOTAL_NET" |
            translate}}
            <!-- <label
              *ngIf="default_currency?.id != order?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}}/
              {{order?.currency?.name}})</label> -->
            :
          </div>
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}"
            class="p-col-7 text-align-right number-total">
            <span *ngIf="purchaseOrder?.discount==0">
              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">
                {{purchaseOrder?.default_currncey_orderTotal | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{purchaseOrder?.orderTotal | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span></label>
              <ng-template #currency> {{purchaseOrder?.orderTotal | number:'1.2-2'}}
              </ng-template>
            </span>

            <span *ngIf="purchaseOrder?.discount>0">


              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">
                <del *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotal != purchaseOrder?.orderTotal"
                  style="font-weight: 600;" class="p-text-secondary">
                  {{purchaseOrder?.default_currncey_beforeDiscont_orderTotal | number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    default_currency?.name }} </span></del>
                {{purchaseOrder?.default_currncey_orderTotal | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{purchaseOrder?.orderTotal | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span>
                <del *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotal != purchaseOrder?.orderTotal"
                  style="font-weight: 600;" class="p-text-secondary"> {{purchaseOrder?.beforeDiscont_orderTotal |
                  number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    purchaseOrder?.currency?.name }} </span></del>




              </label>
              <ng-template #currency>

                <del *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotal != purchaseOrder?.orderTotal"
                  style="font-weight: 600;" class="p-text-secondary"> {{purchaseOrder?.beforeDiscont_orderTotal |
                  number:'1.2-2'}} </del>
                {{purchaseOrder?.orderTotal | number:'1.2-2'}}
              </ng-template>


            </span>

          </div>

        </div>

        <div class="p-grid ">
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}" class="p-col-5">{{"QUOTES_Form_TOTAL_VAT" |
            translate}}
            <!-- <label
              *ngIf="default_currency?.id != order?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}} /
              {{order?.currency?.name}})</label> -->

            :
          </div>
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}"
            class="p-col-7 text-align-right number-total">
            <span *ngIf="purchaseOrder?.discount==0">
              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">
                {{purchaseOrder?.default_currncey_orderTotalVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{purchaseOrder?.orderTotalVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span></label>
              <ng-template #currency> {{purchaseOrder?.orderTotalVAT | number:'1.2-2'}}
              </ng-template>
            </span>

            <span *ngIf="purchaseOrder?.discount>0">


              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">

                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.default_currncey_beforediscontorderTotalVAT != purchaseOrder?.default_currncey_orderTotalVAT"
                  style="font-weight: 600;" class="p-text-secondary">
                  {{purchaseOrder?.default_currncey_beforediscontorderTotalVAT | number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    default_currency?.name }} </span></del>
                {{purchaseOrder?.default_currncey_orderTotalVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name}} </span><label class="divide">/</label>

                {{purchaseOrder?.orderTotalVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span>
                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.beforediscontorderTotalVAT != purchaseOrder?.orderTotalVAT"
                  style="font-weight: 600;" class="p-text-secondary"> {{purchaseOrder?.beforediscontorderTotalVAT |
                  number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    purchaseOrder?.currency?.name }} </span></del>


              </label>
              <ng-template #currency>

                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.beforediscontorderTotalVAT != purchaseOrder?.orderTotalVAT"
                  style="font-weight: 600;" class="p-text-secondary"> {{purchaseOrder?.beforediscontorderTotalVAT |
                  number:'1.2-2'}} </del>
                {{purchaseOrder?.orderTotalVAT | number:'1.2-2'}}
              </ng-template>


            </span>

          </div>

        </div>


        <ng-container *ngIf="purchaseOrder?.paid_vats?.length>1">
          <div *ngFor="let item of purchaseOrder.paid_vats">
            <div *ngIf="item.afterdiscont >0" style="background-color: #f8f9fa;" class=" p-grid">
              <div class="p-col-5">VAT {{item.name}} %
                <!-- <label
                  *ngIf="default_currency?.id != order?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}} /
                  {{order?.currency?.name}})</label>  -->
                :

              </div>
              <div class="p-col-7 text-align-right number-total">
                <span *ngIf="purchaseOrder?.discount==0">

                  <label
                    *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">{{item?.beforediscont
                    |
                    number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      default_currency?.name }} </span> <label class="divide">/</label> {{item?.beforediscont *
                    purchaseOrder?.exchange_rate |
                    number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      purchaseOrder?.currency?.name }} </span></label>
                  <ng-template #currency>{{item?.beforediscont | number:'1.2-2'}}
                  </ng-template>
                </span>

                <span *ngIf="purchaseOrder?.discount>0">

                  <label
                    *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">

                    <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                      class="p-text-secondary">{{item?.beforediscont / purchaseOrder?.exchange_rate | number:'1.2-2'}}
                      <span style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                        default_currency?.name }} </span>
                    </del>
                    {{item?.afterdiscont / purchaseOrder?.exchange_rate|number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      default_currency?.name }} </span> <label class="divide">/</label>

                    {{item?.afterdiscont |number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      purchaseOrder?.currency?.name }} </span>
                    <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                      class="p-text-secondary">{{item?.beforediscont | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                        purchaseOrder?.currency?.name }} </span>
                    </del>
                  </label>
                  <ng-template #currency>
                    <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                      class="p-text-secondary">{{item?.beforediscont| number:'1.2-2'}}
                    </del>
                    {{item?.afterdiscont |number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      purchaseOrder?.currency?.name }} </span>
                  </ng-template>




                </span>


              </div>
            </div>
          </div>
        </ng-container>




        <div [ngClass]="{'p-p-0': purchaseOrder?.status?.cc == false}" class="p-grid">
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}" class="p-col-5">{{"QUOTES_Form_TOTAL_INC_VAT"
            |
            translate}}
            <!-- <label
              *ngIf="default_currency?.id != order?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}}/
              {{order?.currency?.name}})</label> -->
            :
          </div>
          <div [ngClass]="{'text-muted': purchaseOrder?.status?.invoiced}"
            class="p-col-7 text-align-right number-total">


            <span *ngIf="purchaseOrder?.discount==0">
              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">
                {{purchaseOrder?.default_currncey_orderTotalIncVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{purchaseOrder?.orderTotalIncVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name }} </span></label>
              <ng-template #currency> {{purchaseOrder?.orderTotalIncVAT | number:'1.2-2'}}
              </ng-template>
            </span>





            <span *ngIf="purchaseOrder?.discount>0">


              <label
                *ngIf="default_currency?.id != purchaseOrder?.currency?.id &&  defaultcurrency_status;else currency">
                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotalIncVAT != purchaseOrder?.orderTotalIncVAT"
                  style="font-weight: 600;" class="p-text-secondary">
                  {{purchaseOrder?.default_currncey_beforeDiscont_TotalInc | number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    default_currency?.name }}</span></del>
                {{purchaseOrder?.default_currncey_orderTotalIncVAT | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{purchaseOrder?.orderTotalIncVAT |number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                  purchaseOrder?.currency?.name}}</span>
                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotalIncVAT != purchaseOrder?.orderTotalIncVAT"
                  style="font-weight: 600;" class="p-text-secondary">{{
                  purchaseOrder?.beforeDiscont_orderTotalIncVAT |
                  number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                    purchaseOrder?.currency?.name}}</span>
                </del>



              </label>
              <ng-template #currency>

                <del
                  *ngIf="show_afterdiscont && purchaseOrder?.beforeDiscont_orderTotalIncVAT != purchaseOrder?.orderTotalIncVAT"
                  style="font-weight: 600;" class="p-text-secondary">{{
                  purchaseOrder?.beforeDiscont_orderTotalIncVAT |
                  number:'1.2-2'}}
                </del>
                {{purchaseOrder?.orderTotalIncVAT |number:'1.2-2'}}
              </ng-template>

            </span>


          </div>

        </div>
        <hr *ngIf="purchaseOrder?.customer?.company_name != 'Cash & Carry'" class="divider">
        <div *ngIf="purchaseOrder?.customer?.company_name != 'Cash & Carry'" style="background-color: #f8f9fa; "
          class="p-grid p-mt-3 ">
          <div class="p-col-8">{{"QUOTES_Form_CUSTOMER_BALANCE" | translate}}:</div>
          <div class="p-col-4 text-align-right number-total">
            <label class="" *ngIf="purchaseOrder?.customer?.due ; else balance">{{purchaseOrder?.customer?.due
              |number:'1.2-2'}}</label>


            <ng-template #balance>
              <label>0</label>
            </ng-template>
          </div>
        </div>

      </div>
    </p-card>
  </div>
</div>
<p-dialog modal="true" appendTo="body" [header]="Paymentlabel" (onShow)="outside_Status(true)"
  (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false" [(visible)]="showPaymentDialog"
  [style]="{ width: '40vw' }">
  <div style="margin-top: 2px" class="p-grid">
    <div class="p-col-6">
      <div class="form-text text-muted">{{"DASHBORD_DATE" | translate}}</div>
      <p-calendar dateFormat="dd-mm-yy" appendTo="body" [inputStyle]="{ width: '18.5vw' }" class="calendar"
        [(ngModel)]="payment.dateP" #payment_date="ngModel" name="payment.dateP"
        placeholder='{{"DASHBORD_DATE" | translate}}'></p-calendar>
    </div>
    <div class="p-col-6">
      <div class="form-text text-muted">{{"ORDERS_FORM_PAYMENT_TYPES" | translate}}</div>
      <p-dropdown appendTo="body" [(ngModel)]="payment.type" [options]="paymenttypes" optionValue="type"
        optionLabel="label"></p-dropdown>
    </div>

    <div class="p-col-12">
      <div class="form-text text-muted">
        {{"ORDERS_FORM_AMOUNT" | translate}}

        <label *ngIf=" (payment?.amount == null || amountt?.invalid) &&  amountt?.touched " class="alert-invalida">
          {{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

      </div>

      <p-inputNumber [min]="0" [inputStyle]="{'width': '38.2vw' }" id="Amount-input" #amountt="ngModel"
        [(ngModel)]="payment.amount" mode="decimal" [disabled]="disable_payments" [minFractionDigits]="2">
      </p-inputNumber>
    </div>
    <div class="p-col-12">
      <div class="form-text text-muted"> {{"ORDERS_FORM_REFERENCE" | translate}}</div>
      <input [(ngModel)]="payment.notes" id="Reference -input" type="text" pInputText />
    </div>
  </div>

  <p-footer>

    <button *ngIf="Paymentlabel == translate.instant('ADD_PAYMENT')" type="button" pButton (click)="addPayment()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="Paymentlabel == translate.instant('UPDATED_PAYMENT')" type="button" pButton (click)="UpdatePayment()"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}'></button>
    <button *ngIf="Paymentlabel == translate.instant('REFUND_PAYMENT')" type="button" pButton (click)="refundPayment()"
      label='{{"ORDERS_FORM_REFUND" | translate}}'></button>
    <button type="button" pButton (click)="showPaymentDialog = false" icon="pi pi-times"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="cancelshowAddWarehouse()" [resizable]="false"
  [draggable]="false" class="p-shadow-1" [header]="addWarehousesheader" [(visible)]="showWarehouse"
  [style]="{ width: '21vw' }">
  <div class="p-grid p-mt-2 form-group">
    <div class="p-col-12">
      <span class="p-float-label">
        <input required #warehouses="ngModel" [(ngModel)]="warehouse.name" id="warehouses-input" type="text"
          pInputText />
        <label for="warehouses-input">{{"CUSTOMER_FORM_NAME" | translate}}&nbsp;
          <label *ngIf="warehouse.name == null && warehouses.touched" class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" |
            translate}}</label></label>
      </span>
    </div>
  </div>

  <p-footer>
    <button *ngIf="addWarehousesheader == translate.instant('ADD_WARHOUSE')" type="button" pButton icon="pi pi-check"
      (click)="save_Warehouse()" label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="addWarehousesheader == translate.instant('EDIT_WARHOUSE')" type="button" pButton icon="pi pi-check"
      (click)="update_Warehouse()" label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelshowAddWarehouse()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="canceldeleteWarehouse()" class="p-shadow-1"
  header='{{"DELETE_WARHOUSE" | translate}}' [(visible)]="showdeleteWarehouses" [style]="{ width: '30vw' }">
  <div class="p-text-justify">
    {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{ warehouse.name }}</span> ?
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="delete_Warehouse()"
      label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="canceldeleteWarehouse()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" closable="false" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body"
  [resizable]="false" [draggable]="false" class="p-shadow-1" header='{{"CONVERT_PUR" | translate}}'
  [(visible)]="convertPurchasedialogueeQ" [style]="notSavedupdate_Convert?{ width: '36rem' } : { width: '22rem' }">

  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col-5 p-mt-1 ">
          <label class="formattext ">{{"INTERNAL_REFRENACE" | translate}} : </label>
        </div>

        <div class="p-col-7 text-align-left">
          <p-inplace id="internal_reference" #internal_reference>
            <span pInplaceDisplay>
              <button *ngIf="internalrReference_ToPurchace" style="
                  pointer-events: none;
                  color: var(--primary-color) !important;
                  font-weight: 600;
                " pButton [label]="internalrReference_ToPurchace" type="button"
                class="p-button-text p-button-plain p-ripple p-button p-component "></button>
              <button *ngIf="internalrReference_ToPurchace == ''" style="font-weight: 600" pButton
                label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid "></button>
            </span>
            <input name="purchaseOrder.internal_reference" [(ngModel)]="internalrReference_ToPurchace"
              #internal_reference="ngModel" id="internal_reference" type="text" pInputText pInplaceContent
              (blur)="internal_reference.deactivate()" />
          </p-inplace>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col-5  p-mt-1 ">
          <label class="formattext">{{"ORDERS_LIST_ORDER_DATE" | translate}} : </label>
        </div>
        <div class="p-col-7  p-mt-1 text-align-left">
          <p-inplace id="invoice_date" [ngStyle]="{ cursor: 'pointer' }" #invoice_date>
            <span pInplaceDisplay>
              <button *ngIf="purchaseOrder?.date" style="color: var(--primary-color) !important; font-weight: 600"
                pButton [label]="purchaseOrder.date | date: 'dd-MM-yyyy'" type="button"
                class="p-button-text p-button-plain p-ripple p-button p-component  "></button>
              <button *ngIf="purchaseOrder?.date == null" style="font-weight: 600" pButton
                label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                class="p-button-text p-button-plain p-ripple p-button p-component alert-invalid  "></button>
            </span>
            <p-calendar appendTo="body" showIcon="true" [(ngModel)]="purchaseOrder.date" #Date="ngModel"
              name="purchaseOrder.date" [required]="true" pInplaceContent (onClose)="invoice_date.deactivate()"
              (onClickOutside)="invoice_date.deactivate()" (onSelect)="invoice_date.deactivate()" ngDefaultControl>
            </p-calendar>
          </p-inplace>
        </div>
      </div>
    </div>

    <div *ngIf="notSavedupdate_Convert" class="p-col-12">
      <span style="font-weight: 800;" class="text-danger h6">* There are changes that have not yet been saved</span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="notSavedupdate_Convert" type="button" pButton icon="pi pi-check" (click)="updatePurchaseQuote()"
      label='Save and Convert'></button>
    <button *ngIf="notSavedupdate_Convert" type="button" pButton icon="pi pi-check" (click)="convertToPurchaseOrder()"
      label='Discard and Convert'></button>
    <button *ngIf="!notSavedupdate_Convert" type="button" pButton icon="pi pi-check" (click)="convertToPurchaseOrder()"
      label='{{"ORDERS_FORM_CONVERT" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="convertPurchasedialogueeQ = false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" header='{{"ORDERS_FORM_SEND_BY_EMAIL" | translate}}'
  [(visible)]="sendEmaildialog" [style]="{width: '65vw'}">
  <div class="p-field p-grid">
    <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 ">{{"QUOTES_Form_RECIPIENTS" |
      translate}}</label>

    <div class="p-col">

      <p-chips (onRemove)="onRemovemail()" addOnBlur='true' (keydown)="addEmail_onSpace($event)" id="Recipients"
        name="send_to_mails" [inputStyle]="{width: '49vw'}" [(ngModel)]="sendEmail.send_to_mails">
        <ng-template let-item pTemplate="item">
          <span>{{item}}</span>
        </ng-template>

      </p-chips>

      <div class="p-mt-2">
        <p-checkbox [binary]="user_email" [(ngModel)]="user_email" (onChange)="send_copyto_myself()"
          label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>
      </div>
    </div>

  </div>

  <div class="p-field p-grid">
    <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"QUOTES_Form_SUBJECT" | translate}}</label>
    <div class="p-col">
      <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>

    </div>
  </div>

  <div class="p-field p-grid">
    <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"MESSAGE" | translate}} </label>
    <div #col class="p-col">
      <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
        [style]="{'height':'180px'}"></p-editor>
      <div class="mt-2">
        <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="Save_Message()"></p-checkbox>
      </div>

    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-send" (click)="send_Email()" label='{{"QUOTES_Form_SEND" | translate}}'
      icon="far fa-paper-plane"></button>

    <button type="button" pButton icon="pi pi-times" (click)="sendEmaildialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>



  </p-footer>
</p-dialog>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" class="p-shadow-1" header="Edit Service" [(visible)]="showaddinlineProduct"
  [style]="{width: '60vw'}">

  <p-editor id="Message" name="inline" [(ngModel)]="inlineProductValue" ngDefaultControl [style]="{'height':'200px'}">
  </p-editor>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="edit_inlineProduct()"
      label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" (click)="showaddinlineProduct=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>


  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1"
  appendTo="body" [resizable]="false" [draggable]="false" header='{{"EXCHANGE_RATE" | translate}}'
  [(visible)]="showAdd_exchange_rate" [style]="{width: '15vw'}">


  <p-inputNumber [min]="0" [inputStyle]="{'width':13+'vw'}" (onFocus)="$event.target.select()"
    [(ngModel)]="add_exchange_rate" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="confrimAdd_exchangeRate()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="cancelAdd_exchangeRate()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" closable="false" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body"
  [resizable]="false" [draggable]="false" class="p-shadow-1" header='Not Saved Changes'
  [(visible)]="notSavedupdate_Dupplict" [style]=" { width: '37rem' }">

  <div class="p-grid">

    <div *ngIf="notSavedupdate_Dupplict" class="p-col-12">
      <span class=" h6"> Before doing duplicate there are changes that have not yet been saved</span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="purchaseOrder?.type  == 'Quote' " type="button" pButton icon="pi pi-check"
      (click)="updatePurchaseQuote()" label='Save'></button>
    <button *ngIf="purchaseOrder?.type  == 'Order' " type="button" pButton icon="pi pi-check" (click)="updatePurchase()"
      label='Save'></button>
    <button *ngIf="notSavedupdate_Dupplict" type="button" pButton icon="pi pi-times"
      (click)="discard_ChangeOnDuplicate()" class="p-button-outlined " label='Discard'></button>


  </p-footer>
</p-dialog>
