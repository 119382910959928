import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InplaceModule } from 'primeng/inplace';
import { SharedModule } from '../shared/shared.module';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {OrderListModule} from 'primeng/orderlist';
import { DialogModule } from 'primeng/dialog';
import { creditnoteslistCustomerComponent } from './creditnotesCustomers-list/creditnotescustomers-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileUploadModule } from 'primeng/fileupload';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import {ButtonModule} from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TieredMenuModule } from 'primeng/tieredmenu';
import {SelectButtonModule} from 'primeng/selectbutton';

export const routes: Routes = [
  {path: '', component: creditnoteslistCustomerComponent}
];

@NgModule({
  declarations: [ creditnoteslistCustomerComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CardModule,
    DropdownModule,
    FormsModule,
    InplaceModule,
    InputTextModule,
    CalendarModule,
    InputTextareaModule,
    SharedModule,
    SplitButtonModule,
    TableModule,
    TranslateModule,
    ToastModule,
    OrderListModule,DialogModule,
    FileUploadModule,
    ChipsModule,
    CheckboxModule,
    EditorModule,
    TooltipModule,
    TagModule,SelectButtonModule,
    ButtonModule,TieredMenuModule,
    PdfViewerModule,InputNumberModule
  ]
})
export class creditnotesCustomerModule {
}
