
import { isSet } from '../modules/core/util';
import { Currency } from './currency';

export class CompanySettings {



  id: string;
  iBan: string;
  logo_file: any;
  logo: any;
  logoName: any;
  logoType: any;
  company_name: string;
  company_id: string;
  invoiceFooter: string;
  company: number;
  created_by: Date;
  created_at: Date;
  updated_at: Date;
  viewlink: string;
  vat:string
  companyName:string
  companyAddress:string
  currency: Currency;
  currencies: Currency[]

  public constructor(params?: CompanySettings) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: CompanySettings) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new CompanySettings(objectToClone);
    obj.currency = Currency.cloneObject(obj.currency);
    obj.currencies = Currency.cloneManyObjects(obj.currencies);

  }
  public static cloneManyObjects(companiesToClone: CompanySettings[]) {
    const companies: CompanySettings[] = [];
    for (const company of companiesToClone) {
      companies.push(CompanySettings.cloneObject(company));
    }

    return companies;
  }
  public toDB() {

    return {
      logo: this.logo,
      iBan: this.iBan,
      company_name: this.company_name,
      company_id: this.company_id,
      invoiceFooter: this.invoiceFooter,
      company: this.company

    }
  }
}
