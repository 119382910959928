
import { isSet } from '../../modules/core/util';
import { Payment } from '../orders/Payment';
import { Supplier } from '../supplier';
import { Payer } from './payer';
import * as _ from 'lodash';
import { Currency } from '../currency';


export class Expenseitems {
  id: string;
  format: string;
  number: string
  datePipe: Date
  title: string
  date: any
  description: string
  ex_type: string
  ex_type_2: string
  amount: any;
  currency: Currency;
  exchange_rate: number
  total: number;
  balance: number;
  payment_type: string
  vatRate: number;
  payments: Payment[]
  payments_amount: number
  template: string;

  vat_6: number
  vat_12: number
  vat_21: number

  items_vats: any
  base: any
  vendor: Supplier
  payer: Payer
  attachment: any
  invoice_format: string
  vat: string
  download_url: string;
  view_url: any
  payer_name: string
  ex_status: string
  inoiceIndex: number
  notes: string
  invoice_number: string
  loadingbtnupload: boolean
  noted: boolean
  vendor_name: string;
  vcs: string;
  iban:any
  aiCropped: any[];
  items: any
  ticketAmount: boolean
  get item_total() {
    // deleted
    if (isSet(this.amount) && isSet(this.exchange_rate)) {
      return (parseFloat(`${Number(this.amount)}`) * parseFloat(`${Number(this.exchange_rate)}`))
    }
    return 0;
  }

  get vatTotal() {
    const vats = []
    for (const prop in this.items_vats) {
      vats.push(this.items_vats[prop]
      );
    }
    return _.sumBy(vats)
  }
  get subTotal() {
    const baes = []

    for (const prop in this.base) {
      baes.push(this.base[prop]
      );
    }

    return _.sumBy(baes)

  }
  get totalwithVat() {
if (this.ex_type== 'Ticket') {
  this.items=[]
  this.base = {
    [0]: this.amount
  }
  this.items_vats = {
    [0]: 0
  }
  this.items.push({
    vat_rate: 0,
    base: this.amount,
    vat: 0,
  })
  return this.amount
}else{
  if (this.subTotal > 0 && !this.ticketAmount) {
    this.amount = (this.subTotal + this.vatTotal)
  }
  if (this.subTotal == 0) {
    return this.amount * this.exchange_rate
  }
  return (this.subTotal + this.vatTotal) * this.exchange_rate
}
  }

  public constructor(params?: Expenseitems,) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Expenseitems) {
    if (!isSet(objectToClone)) {
      return new Expenseitems();
    }
    const obj = new Expenseitems(objectToClone);

    if (isSet(objectToClone.payments)) {
      obj.payments = Payment.cloneManyObjects(objectToClone.payments);
    }
    if (isSet(objectToClone.payer)) {
      obj.payer = Payer.cloneObject(objectToClone.payer);
    }
    if (isSet(objectToClone.vendor)) {
      obj.vendor = Supplier.cloneObject(objectToClone.vendor);
    }
    return obj
  }

  public static cloneManyObjects(objectsToClone: Expenseitems[]) {
    const objects: Expenseitems[] = [];
    for (const obj of objectsToClone) {
      objects.push(Expenseitems.cloneObject(obj));
    }

    return objects;
  }

  public toDB(date) {
    if (this.ex_type == 'Ticket') {
      this.base = {}
      this.items_vats = {}
    }

    return {
      format: this.format,
      payer: this.payer?.id,
      date: date,
      exchange_rate: this.exchange_rate,
      sub_total: this.amount,
      number: this.number,
      payment_type: this.payment_type,
      payments: this.payments,
      currency: this.currency,
      title: this.title,
      attachment: this.attachment,
      description: this.description,
      ex_status: this.ex_status,
      ex_type: this.ex_type,
      ex_type_2: this.ex_type_2,
      items_vats: this.items_vats,
      base: this.base,
      vendor: this.vendor?.id,
      vendor_name: this.vendor_name,
      vat: this.vat,
      vcs: this.vcs,
      iban: this.iban


    }

  }



}
