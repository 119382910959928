import { Address } from './address';
import { state } from '@angular/animations';
import { isSet } from '../modules/core/util';
import { Contact } from './contact';
export class Company {
  id: string;
  company: number;
  company_name: string;
  company_settings:string
  name:string
  vat:string;
  address:Address
  contact:Contact[]
  description:string;
  house_number:string;
  phone:number;
  phone2:number;
  email1:string;
  email2:string
  website: string;
  fax: string;
  owner:string;
  company_users:any[]
  public constructor(params?: Company) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Company) {
    if (!isSet(objectToClone)) {
      return objectToClone;
          }
          const obj = new Company(objectToClone);

          if (isSet(obj.contact)) {
            obj.contact = Contact.cloneManyObjects(objectToClone.contact);
          }
          if (isSet(obj.address)) {
            obj.address = Address.cloneObject(objectToClone.address);
          }
        }
    public static cloneManyObjects(companiesToClone: Company[]) {
      const companies: Company[] = [];
      for (const company of companiesToClone) {
        companies.push(Company.cloneObject(company));
      }
  
      return companies;
    }
    public toDB() {
    
      return {
        vat: this.vat,
        name: this.name,
        phone:this.phone,
        phone2:this.phone,
        email1:this.email1,
        email2:this.email2,
        website:this.website

    }
    }
}
