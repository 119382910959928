import { Customer } from './../../../models/customer';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { Order } from '../../../models/orders/order';
import { OrdersService } from '../orders.service';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { MenuItem, MessageService } from 'primeng/api';
import * as printJS from 'print-js';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { SalesServiceService } from '../../sales-journals/sales-service.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { DatePipe } from '@angular/common';
import { Query } from 'src/app/models/query';
import { Currency } from 'src/app/models/currency';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderItem } from 'src/app/models/orders/orderitem';
import { OrderStatus } from 'src/app/models/orders/orderstatus';
import { MenuService } from 'src/app/shared/menu.service';
import { SendEmail } from 'src/app/models/SendEmail';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent extends BaseComponent implements OnInit {
  @ViewChild('statusfilter') statusfilter: any;
  public selectedOrder = new Order()
  public LangStats = new OrderStatus()
  public showSidebar = false;
  public typeSidebar :string
  public size = SidebarSize.X_LARGE;
  public currentModal;
  public modalTitle = 'New order'

  public selected: Order[] = [];
  public orders: Order[] = [];
  public filteredProducts: Order[] = [];
  public fillterCustomers: Customer[] = [];
  header_body: string
  itemSearch
  keyword = "format"
  checkempty: boolean
  cols: any[];
  disableUnlockInvoice: boolean
  itemsSave = []
  langOpctions = [];
  deliveryNotelangOpctions = [];

  loadingbtnPrint = false
  loadingbtnPrint_d = false
  loadingbtnemail = false
  removedialoguee = false
  pdfViewer = false
  userEmail: string;
  sendEmailDialog = false;
	sendEmail = new SendEmail();
  pdflistmode = false
  orderstatusTitle: string
  lastInvoiced_order: string
  orderSelectedIndex = 0
  qouteToOrder_id: string
  pdfheight = 0
  orderNextPage: any = 0
  orderPrevPage: any = 0
  currancyOptions: MenuItem[] = [];
  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  refresh_selectedOrder: boolean = false
  currentChangedCurrancy: string;
  selectedCurrancy = new Currency()
  defultCurrancy = new Currency()

  currencies: Currency[] = []

  selected_Cutstomerquery = new Customer()
  filterModeApply_Cutstomer: boolean = false
  filterModeFormat_query = new Query()
  filterModeinvoice_format_query = new Query()

  filterModeStatus_query = new Query()
  filterModeDate_query = new Query()
  filterModeTotal_query = new Query()
  filterModePaid_status = new Query()

  sortMode: number = 1
  save_Print: boolean = false
  save_Email: boolean = false
  save_Invoice: boolean = false
  saved_order: Boolean = false


  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: this.translate.instant('NOT_EQUAL'),
      value: 'ne'
    },
    {
      type: this.translate.instant('EQUAL'),
      value: 'exact'
    },
    {
      type: this.translate.instant('LESS_THAN'), value: 'lt'

    },
    {
      type: this.translate.instant('GREATER_THAN'), value: 'gt'

    },


  ]
  statuses = [
    { status: { value: 'Invoiced', label: 'Invoiced', color: 'Invoiced' } },
    { status: { value: 'Draft', label: 'Draft', color: 'Draft' } },
    { status: { value: 'C&C', label: 'C&C', color: 'CC' } },
  ]
  paid_status = [
    { status: { value: 'paid', label: 'Paid', color: 'Paid' } },
    { status: { value: 'unPaid', label: 'UnPaid', color: 'UnPaid' } }

  ]
  seletedquery_type: string
  constructor(private ordersService: OrdersService,
    private sharedService: SharedService,
    public messageService: MessageService,
    private dashServices: DashboardService,
    private menuService: MenuService,
    public translate: TranslateService,
    private salesServiceService: SalesServiceService,
    private datePipe: DatePipe,


  ) {
    super();
  }

  ngOnInit(): void {
    this.filterFromDashboard()
    this.subscribeOnorderRefresh()
    this.subscribeOnduplicatOrder()
    this.emailbtnLoading()
    this.disableunlockInoviceEmitter()
    this.showOrder_salesJournal()
    this.refreshSelectedOrder()
    this.getCurrencies()
    this.currencyChanged()
    this.Convert_toOrder()
    this.printOrderViewer()
    setTimeout(()=>{
      console.log(this.statusfilter)
    },2000)
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.defultCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

    this.cols = [
      { field: 'ordernumber', header: this.translate.instant('ORDERS_LIST_ORDER_NO') },
      { field: 'name', header: this.translate.instant('CUSTOMER_FORM_NAME') },
      { field: 'category', header: this.translate.instant('CUSTOMER_FORM_CAT') },
      { field: 'quantity', header: this.translate.instant('QUANTITY') }
    ];
    this.itemsSave = [
      {
        label: this.translate.instant('SAVE_PRINT'), icon: 'fas fa-print', command: () => {
          this.saveOrder()
          this.save_Print = true
        }
      },
      {
        label: this.translate.instant('SAVE_EAMIL'), icon: "far fa-envelope", command: () => {

          this.saveOrder()
          this.save_Email = true

        }
      },
      {
        label: this.translate.instant('SAVE_INVOICE'), icon: "fas fa-file-invoice", command: () => {

          this.saveOrder()
          this.save_Invoice = true
        }
      },

    ]
    this.langOpctions = [
      {
        label: 'EN', command: () => {

          this.ordersService.printLang = 'en'
          this.printOrder_list(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
      {
        label: 'FR', command: () => {

          this.ordersService.printLang = 'fr'

          this.printOrder_list(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
      {
        label: 'NL', command: () => {

          this.ordersService.printLang = 'nl'

          this.printOrder_list(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
    ]
    this.deliveryNotelangOpctions = [
      {
        label: 'EN', command: () => {

          this.ordersService.printLang = 'en'
          this.printDeliverNote(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
      {
        label: 'FR', command: () => {

          this.ordersService.printLang = 'fr'

          this.printDeliverNote(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
      {
        label: 'NL', command: () => {

          this.ordersService.printLang = 'nl'

          this.printDeliverNote(this.selectedOrder.id, this.orderSelectedIndex)
        }
      },
    ]


  }


  getCurrencies() {
    this.currencies = []
    this.currancyOptions = []
    const subscription = this.sharedService.currnceyChangeEmitter.subscribe((currencies: Currency[]) => {

      if (!isSet(currencies)) {
        this.currencies = JSON.parse(localStorage.getItem('supported_currencies'))
      } else {
        localStorage.setItem('supported_currencies', JSON.stringify(this.currencies))

        this.currencies = Currency.cloneManyObjects(currencies)

      }
      this.currencies = this.currencies.reverse();
      this.currencies?.map(c => {
        this.currancyOptions.push(
          {
            label: c.name, command: () => {
              if (this.selectedCurrancy?.id == c?.id) {
                return
              }
              this.selectedCurrancy = c

              this.ordersService.currencyChanged.next(c)
            }
          }
        )
      })


    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);
  }

  showAddOrder() {
    this.save_Print = false
    this.save_Email = false
    this.save_Invoice = false
    this.ordersService.selectOrder(null);
    this.ordersService.updateFormClicked.next(null)
    this.sharedService.Create_form(true)
    this.selectedOrder = new Order();
    this.orderstatusTitle = null
    this.header_body = null
    this.orderSelectedIndex = 0
    this.ordersService.currencyChanged.next(JSON.parse(localStorage.getItem('companySettings'))?.currency)
    this.modalTitle = this.translate.instant('ORDER')// New order
    this.getCurrencies()
    this.showSidebar = true;
    this.typeSidebar='order'

  }

  showOrder(order: Order, index: number) {
    this.ordersService.selectOrder(order);
    this.selectedCurrancy = order.currency
    this.showSidebar = true;
    this.disableUnlockInvoice = false
    this.selectedOrder = order;
    this.orderSelectedIndex = index
    if (this.selectedOrder.status?.invoiced == true) {
      this.getlast_invoiced_order()

    }
    this.typeSidebar='order'
    this.modalTitle = this.selectedOrder.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
    this.header_body = this.selectedOrder?.format
    this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedOrder?.status?.status?.color}">${this.translate.instant(this.selectedOrder?.status.status?.label)}</span> /
    <span  style="margin-right: 5px;" Class="${this.selectedOrder?.status.status?.paid_color}">${this.translate.instant(this.selectedOrder?.status.status?.paid_status)}</span>`;
  }

  public convertInvoice() {
    this.ordersService.ConvertInvoiceClicked.next(true)
    const subscription = this.ordersService.ConvertInvoiceStatusEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.lastInvoiced_order = this.selectedOrder?.id
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  public converttoDraft() {
    this.ordersService.convertDraft.next(true)


  }
  unlockinvoiced() {
    this.ordersService.unlockInovice.next(true)

  }
  disableunlockInoviceEmitter() {
    const subscription = this.ordersService.disableUnlockInvoiceEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        this.disableUnlockInvoice = false

      }
      if (status == true) {
        this.disableUnlockInvoice = true

      }

    }, error => {

      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  refreshSelectedOrder() {
    const subscription = this.ordersService.refreshSelectedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }

      this.refresh_selectedOrder = true


      this.ordersService.refreshSelected.next(null)



    }, error => {
      this.loading = false

      this.handleError(error);
    });
    this.subscriptions.push(subscription);



  }

  saveOrder() {
    this.loading = false
    if (this.modalTitle == this.translate.instant('QUOTES_LIST_CONVERT_ORDER')) {

      try {
        const subscription = this.ordersService.quotetoOrder(this.qouteToOrder_id).subscribe(() => {
          this.ordersService.saveForm();

          this.subscriptions.push(subscription);


        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

          this.loading = false;
          this.subscriptions.push(subscription);

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    } else {
      this.ordersService.saveForm();

    }
    const subscription = this.ordersService.retrieve_lastOrderEmitter.subscribe((order: Order) => {
      if (!isSet(order)) {
        return
      }

      this.selectedOrder = Order.cloneObject(order)
      this.saved_order = false

      this.modalTitle = this.selectedOrder.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
      this.header_body = this.selectedOrder?.format
      this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedOrder?.status?.status?.color}">${this.translate.instant(this.selectedOrder?.status.status?.label)}</span> /
    <span  style="margin-right: 5px;" Class="${this.selectedOrder?.status.status?.paid_color}">${this.translate.instant(this.selectedOrder?.status.status?.paid_status)}</span>`;
      this.ordersService.retrieve_lastOrder.next(null)


      if (this.save_Print == true) {
        return this.printOrder_list(this.selectedOrder?.id, this.orderSelectedIndex)
      }
      if (this.save_Email == true) {
        return this.sendByEmail()
      }
      if (this.save_Invoice == true) {
        return this.convertInvoice()
      }

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  emailbtnLoading() {
    const subscription = this.ordersService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false

      }
      if (status == true) {
        return this.loadingbtnemail = true

      }
      this.ordersService.btnLoading.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  printOrder_form(id: string) {
    this.outside_Status(true)

    this.loadingbtnPrint = true;
    if (this.save_Print == false) {
      this.orders[this.orderSelectedIndex].loadPrint = true

    }
    const subscription = this.ordersService.PrintOrder(id).subscribe((order: any) => {
      if (!isSet(order)) {
        return;
      }
      if (this.pdflistmode == false) {
        this.ordersService.printOrderLink.next(order.url)
      } else {
        this.pdfViewer = true
        this.ordersService.printLang = null

        this.selectedOrder.downloadUrl = order.download_url
        this.selectedOrder.viewUrl = order.view_url
        this.getShortUrl(order.download_url,order.file_name)
        this.pdflistmode = false
        this.outside_Status(false)

      }

      subscription.unsubscribe();
      this.loadingbtnPrint = false;
      this.orders[this.orderSelectedIndex].loadPrint = false

    }, error => {
      subscription.unsubscribe();
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      this.loadingbtnPrint = false;
      this.orders[this.orderSelectedIndex].loadPrint = false


    });

  }
  printDeliverNote_form(id: string) {
    this.outside_Status(true)
    this.loadingbtnPrint_d = true;
    if (!this.save_Print) {
      this.orders[this.orderSelectedIndex].loadPrint = true

    }
    const subscription = this.ordersService.printDeliverNote(id).subscribe((order: any) => {
      if (!isSet(order)) {
        return;
      }
      if (!this.pdflistmode) {
        this.ordersService.printOrderLink.next(order.url)
      } else {
        this.pdfViewer = true
        this.ordersService.printLang = null

        this.selectedOrder.downloadUrl = order.download_url
        this.selectedOrder.viewUrl = order.view_url
        this.getShortUrl(order.download_url,order.file_name)
        this.pdflistmode = false

      }

      subscription.unsubscribe();
      this.loadingbtnPrint_d = false;
      this.outside_Status(false)

      this.orders[this.orderSelectedIndex].loadPrint = false

    }, error => {
      subscription.unsubscribe();
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      this.loadingbtnPrint_d = false;
      this.orders[this.orderSelectedIndex].loadPrint = false


    });

  }
  printOrder_list(id: string, i: number) {
    this.orderSelectedIndex = i
    this.pdflistmode = true
    this.selectedOrder.id=id
    this.printOrder_form(id)

  }
  printDeliverNote(id: string, i: number) {
    this.orderSelectedIndex = i
    this.pdflistmode = true
    this.printDeliverNote_form(id)
  }

  downloadPdf() {
    window.location.href = this.selectedOrder.downloadUrl;

  }
  printPdf() {

    printJS({ printable: this.selectedOrder.downloadUrl, type: 'pdf', showModal: true })

  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  upadteFormClicked() {

    this.ordersService.updateFormClicked.next(true)
  }

  public sendByEmail() {

    this.ordersService.sendEmailClicked.next(true)

  }
  deleteseletedorder(order: Order) {
    this.selected = []
    this.selected.push(order)
    this.removedialoguee = true

  }

  outsideStatus(status: boolean) {
		this.sharedService.dialog_status.next(status);
	}

  deleteOrders() {
    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.ordersService.orderdelete(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.format + this.translate.instant(' DELETED ') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant(' SELECTED_ORDERS_DELTED') });
          }
          this.ordersService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

          subscription.unsubscribe();

        }, error => {

          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', life: 3500, summary: this.translate.instant('DELETED') + " (" + item?.format + ")", detail: error.error.detail });
          }
          this.loading = false;
          subscription.unsubscribe();
          this.ordersService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

        })

      })


      this.loading = false;
      this.selected = []




    }
    catch (error) {
      this.log(error);
    }

    this.removedialoguee = false
    this.showSidebar = false
  }


  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.orders = e


  }

  completeMethod(serach_item) {
    this.ordersService.queris = []
    this.getOrders_queries('format', 'startswith', serach_item)
  }

  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.ordersService.queris.map((query,) => {
      if (query.query_name == query_name && query_name != 'status') {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.ordersService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
        this.statusfilter.overlayVisible=false
     // this.filter.showMenu=false

    }



    this.ordersCount = 0
    const subscription = this.ordersService.getOrders().subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }
      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = orders.count
      this.total_pages = orders.total_pages
      this.orders = Array(this.ordersCount).fill(0)


      const clone = Order.cloneManyObjects(orders.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        this.filteredProducts.push(e)
      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.orders[index] = clone[index];

      }

      if (this.refresh_selectedOrder == true) {
        this.selectedOrder = this.orders[this.orderSelectedIndex]

        this.modalTitle = this.selectedOrder?.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
        this.header_body = this.selectedOrder?.format
        this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedOrder?.status?.status?.color}">${this.translate.instant(this.selectedOrder?.status.status?.label)}</span> /
    <span  style="margin-right: 5px;" Class="${this.selectedOrder?.status.status?.paid_color}">${this.translate.instant(this.selectedOrder?.status.status?.paid_status)}</span>`;
      }

      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.ordersService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeinvoice_format_query = new Query()

    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeStatus_query = new Query()
    this.filterModePaid_status = new Query()

    this.selected_Cutstomerquery = new Customer()
    this.getOrders()
  }
  paid_Status_queries() {

    this.ordersService.queris.push(
      {
        query_name: 'status',
        query_type: 'paid',
        query_value: this.filterModePaid_status?.value == 'paid' ? true : false
      }


    )
  }
  private subscribeOnorderRefresh() {
    const subscription = this.ordersService.refreshListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {


        if (this.orderNextPage != 0) {
          this.refresh_NextPage()
        }
        if (this.orderNextPage == 0) {
          this.getOrders();


        }


      }
    });
    this.subscriptions.push(subscription);
  }
  private getOrders() {
    this.orders = []
    this.loading = true;
    const subscription = this.ordersService.getOrders().subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = orders.count
      this.total_pages = orders.total_pages
      this.orders = Array(this.ordersCount).fill(0)


      const clone = Order.cloneManyObjects(orders.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.orders[index] = clone[index];

      }
      if (this.saved_order == true) {
        this.orderSelectedIndex = 0
        this.saved_order = false
      }


      if (this.refresh_selectedOrder == true) {
        this.selectedOrder = this.orders[this.orderSelectedIndex]

        this.modalTitle = this.selectedOrder?.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
        this.header_body = this.selectedOrder?.format
        this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedOrder?.status?.status?.color}">${this.translate.instant(this.selectedOrder?.status.status?.label)}</span> /
    <span  style="margin-right: 5px;" Class="${this.selectedOrder?.status.status?.paid_color}">${this.translate.instant(this.selectedOrder?.status.status?.paid_status)}</span>`;
      }


      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.ordersService.sort = filed
      return this.getOrders()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.ordersService.sort = '-' + filed
      return this.getOrders()
    }







  }
  getNextorderPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.ordersService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.ordersService.ordesrNextPage(this.orderNextPage).subscribe((orders: PaginatedResponse<Order[]>) => {
          if (!isSet(orders)) {
            return;
          }



          const clone = Order.cloneManyObjects(orders.results);
          clone.map(e => {
            e.customer = new Customer()
            e.customer.company_name = e.company_name
            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
            this.fillterCustomers.push(e.customer)

            this.filteredProducts.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.orders[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  refresh_NextPage() {
    this.loading = true
    const subscription = this.ordersService.ordesrNextPage(this.orderNextPage).subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }



      const clone = Order.cloneManyObjects(orders.results);
      clone.map(e => {
        e.customer = new Customer()
        e.customer.company_name = e.company_name
        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
        this.fillterCustomers.push(e.customer)

        this.filteredProducts.push(e)
      })
      const shown = this.orders_inPage

      this.orders_inPage = (this.orderNextPage - 1) * 30

      if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
        this.orders_inPage = this.orders_inPage
      }


      for (let index = 0; index < clone.length; index++) {

        this.orders[this.orders_inPage + index] = clone[index];

      }

      this.pageViwed.push(this.orderNextPage)
      this.loading = false;
      if (this.saved_order == true) {
        this.orderSelectedIndex = 0
        this.saved_order = false
      }
      if (this.refresh_selectedOrder == true) {
        this.selectedOrder = this.orders[this.orderSelectedIndex]
        this.modalTitle = this.selectedOrder?.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
        this.header_body = this.selectedOrder?.format
        this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedOrder?.status?.status?.color}">${this.translate.instant(this.selectedOrder?.status.status?.label)}</span> /
    <span  style="margin-right: 5px;" Class="${this.selectedOrder?.status.status?.paid_color}">${this.translate.instant(this.selectedOrder?.status.status?.paid_status)}</span>`;
      }
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  private getlast_invoiced_order() {
    const subscription = this.ordersService.getlast_invoiced_order().subscribe((order: Order) => {

      this.lastInvoiced_order = order.id

      this.loading = false

      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();
      this.loading = false

    });
  }
  Reload() {
    this.ordersService.sort = null
    this.ordersService.selectOrder(null)
    this.dashServices.displayFilter.next(null)
    this.ordersService.Reload()
    this.getOrders()
  }
  Convert_toOrder() {

    this.ordersService.QuoteToOrderListEmitter.subscribe((order: Order) => {
      if (!isSet(order)) {
        return;
      }
      setTimeout(() => {
        this.selectedCurrancy = order?.currency

      }, 1000);

      this.sharedService.Create_form(true)
      this.sharedService.chekcempey.next(true)
      this.qouteToOrder_id = order.idQuoteConvert
      this.modalTitle = this.translate.instant('QUOTES_LIST_CONVERT_ORDER')
      this.showSidebar = true;
    this.typeSidebar='order'

    }, error => {
      this.handleError(error);
    });


  }
  duplicateOrder() {
    this.ordersService.duplicateClicked.next(true)
    this.orderSelectedIndex = 0
    this.orderNextPage = 0
  }
  private subscribeOnduplicatOrder() {
    this.loading = true
    const subscription = this.ordersService.duplicateClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.showduplicat()
      this.ordersService.duplicateClicked.next(null)

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
    this.loading = false



  }
  showduplicat() {
    this.ordersService.selectOrder(null);
    this.sharedService.Create_form(true)
    this.selectedOrder = null;
    this.modalTitle = this.translate.instant('ORDERS_LIST_DUPLICATE_ORDER') + " : "
    this.header_body = this.orders[this.orderSelectedIndex]?.format

    this.orderstatusTitle = null
    this.showSidebar = true;
    this.typeSidebar='order'


  }
  onDropdownClick_language(rowData: Order, i: number) {
    this.selectedOrder = rowData
    this.orderSelectedIndex = i
  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  create_creditnote() {
    this.ordersService.orderToCredit.next(true)
  }
  showOrder_salesJournal() {
    const subscription = this.salesServiceService.showOrderEmitter.subscribe((order: any) => {
      if (!isSet(order)) {
        return
      }
      setTimeout(() => {
        this.showOrder(order, null)

      }, 500);
      this.salesServiceService.showOrder.next(null)
    }, error => {
      this.loading = false

      this.handleError(error);
    });
    this.subscriptions.push(subscription);



  }
  filterFromDashboard() {
    const subscription = this.dashServices.displayFilterEmitter.subscribe(type => {
      if (!isSet(type)) {
        this.getOrders()
        return
      }
      this.orders = type

    }, error => {
      console.log(error);
    })
    this.subscriptions.push(subscription)
  }

  currencyChanged() {
    const subscription = this.ordersService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }

      this.selectedCurrancy = currancy


      this.ordersService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }



  gotheringOrders(){
    console.log(this.selected);
    this.showSidebar = true;
    this.typeSidebar='invoice'
    this.modalTitle='Invoice Multi Orders'
  }


  sendToWhatsapp(id?){
    if (!this.selectedOrder.whatsappUrl){
      const subscription = this.ordersService.PrintOrder(id).subscribe((order: any) => {
        if (!isSet(order)) {
          return;
        }
          this.selectedOrder.downloadUrl = order.download_url
          this.getShortUrl(order.download_url,order.file_name).then(()=>{
            window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedOrder.whatsappUrl}`, "_blank")
          })
          this.outside_Status(false)
        subscription.unsubscribe();
      }, error => {
        subscription.unsubscribe();
        this.loading=false
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      });
    }else{
      window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedOrder.whatsappUrl}`, "_blank")
    }
  }
  getShortUrl(downloadUrl,fileName){
    return new Promise((resolve) => {
      const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
        if (!isSet(url)) {
          return
        }
        resolve(this.selectedOrder.whatsappUrl=url?.id)
        subscription.unsubscribe()
      },error=>{
        console.log(error)
        subscription.unsubscribe()
      })
    });
  }
  sendByEmail_order() {
    this.ordersService.sendEmailClicked.next(true)


  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
   sendByEmailS() {
			this.sendEmailDialog = true;
	}
  printOrderViewer() {
		const subscription = this.ordersService.printOrderLinkEmitter.subscribe((link: any) => {
			if (!isSet(link)) {
				return;
			}
      this.pdfViewer = true
			this.selectedOrder.downloadUrl = link.download_url;
			this.selectedOrder.viewUrl = link.view_url;
			this.getShortUrl(link.download_url, link.file_name)
			this.ordersService.printOrderLink.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}
	SaveMessage() {
		localStorage.setItem('defaultMessage', this.sendEmail.message);
	}

	sendEmails(): void {    
		try {
			this.ordersService.btnLoading.next(true);
			const subscription = this.ordersService.sendEmail(this.sendEmail, this.selectedOrder.id).subscribe((sendEmail: SendEmail) => {
				if (!isSet(sendEmail)) {
					return;
				}
				this.messageService.add({
					key: 'form',
					severity: 'success',
					summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
					detail: this.translate.instant('SENT_EMAILS')
				});
				this.ordersService.btnLoading.next(false);
				subscription.unsubscribe();
			}, error => {
				this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error });
				this.ordersService.btnLoading.next(false);
				subscription.unsubscribe();
			});
		} catch (error) {
			this.loading = false;
			this.log(error);
		}
		this.sendEmailDialog = false;
	}

	sendCopyToMyself() {
		const user: User = JSON.parse(localStorage.getItem('user'));
		const email = this.sendEmail.send_to_mails;
		if (!isSet(this.sendEmail.send_to_mails)) {
			this.sendEmail.send_to_mails = [];
			this.sendEmail.send_to_mails.push(user.email);
		}
		if (this.sendEmail.send_to_mails?.includes(user.email) === false && this.userEmail != null) {
			email.push(user.email);
			this.sendEmail.send_to_mails = [];
			this.userEmail = user.email;
			email.map(em => {
				this.sendEmail.send_to_mails.push(em);
			});
		} else {
			this.sendEmail.send_to_mails = this.sendEmail.send_to_mails?.filter(item => item !== user.email);
			this.userEmail = null;
		}
	}

	onRemovEmail() {
		this.sendEmail.send_to_mails.find(email => {
			if (email !== this.userEmail) {
				this.userEmail = null;
			}
		});
	}

	addEmailOnSpace(event: KeyboardEvent) {
		if (event.key === ' ') {
			event.preventDefault();
			const element = event.target as HTMLElement;
			element.blur();
			element.focus();
		}
	}
}
