
export const environment = {
  production: true,

  // test sever 1
  // apiUrl: 'https://api-test.squirx.com/api',

    // test sever 2
    apiUrl: 'https://api-demo.squirx.com/api',
};


