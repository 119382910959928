import { CompanySettings } from './../models/company-settings';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Company } from '../models/company';
import { PaginatedResponse } from '../models/paginatedresponse';
import { User } from '../models/user';
import { ApiService } from '../modules/core/api.service';
import { isSet } from '../modules/core/util';
import { Calendar_event } from '../models/calendar/event';
import { Currency } from '../models/currency';
import { CountryCode_currency } from '../models/country_code&currency';
import { Vat_Product } from '../models/vat-product';

@Injectable()
export class MenuService {

  private menuSource = new Subject<string>();
  private resetSource = new Subject();

  uploadlogo: boolean = false

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();
  constructor(private api: ApiService) { }

  public configClicke: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public conifgClickeEmitter: Observable<boolean> = this.configClicke.asObservable();

  public refreshShapes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshShapesEmitter: Observable<boolean> = this.refreshShapes.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public langStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public langStatusEmitter: Observable<any> = this.langStatus.asObservable();

  public refreshcalendar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshcalendarEmitter: Observable<boolean> = this.refreshcalendar.asObservable();




  getCompanies(): Observable<PaginatedResponse<Company[]>> {
    return this.api.get<PaginatedResponse<Company[]>>('/companies');
  }

  getCompany(): Observable<Company> {
    const userStorage = JSON.parse(localStorage.getItem('user')) as User

    return this.api.get<Company>(`/companies/rud/${userStorage.user_companies[0]?.company}`);
  }

  getComponyProfile(): Observable<any> {

    return this.api.get<any>(`/company_profile`);
  }
  
  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next();
  }

  getCompany_settings(id: string): Observable<CompanySettings> {
    return this.api.get<CompanySettings>(`/company_settings/rud/${id}`);
  }

  updateCompany_settings(companySettings: CompanySettings): Observable<CompanySettings> {

  const  uploadIogo={
     company_address_data: {
        address:companySettings?.companyAddress
      },

      company_data: {
        name:companySettings?.companyName,
        vat:companySettings?.vat
      },
    company_settings_data: {
      "logo-file":[{
        name:companySettings?.logoName,
        content_type:companySettings?.logoType,
        file:companySettings?.logo_file
      }]
    }
    }

    const body = {
      company_address_data: {
        address:companySettings?.companyAddress
      },

      company_data: {
        name:companySettings?.companyName,
        vat:companySettings?.vat
      },
      company_settings_data: {
        iBan:companySettings?.iBan,
        logo:companySettings?.logo,
        invoiceFooter:companySettings.invoiceFooter,
        currency: companySettings?.currency?.id,
        
      },
    }            
    return this.api.patch<any>(`/company_profile`, this.uploadlogo == true ? uploadIogo : body );
  }
  generate_download(name: any) {
    let d = {
      file_type: 'CompanySettings',
      file_name: name
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  delete_File(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.deleteFile(`/delete_s3_file`, d)
  }
  getTodayEvents(): Observable<Calendar_event[]> {
    return this.api.get<Calendar_event[]>(`/events?filter=day`);
  }
  getCurrencies(): Observable<PaginatedResponse<Currency[]>> {
    return this.api.get<PaginatedResponse<Currency[]>>(`/check_currencies`)

  }
  countryCode_currency(): Observable<CountryCode_currency[]> {
    return this.api.getGuest('/countries/list')
  }
  addCurrency(currency: any): Observable<any> {
    return this.api.post('/currencies', { name: currency });
  }

  remove_Currency(id: any): Observable<any> {
    return this.api.delete<any>(`/currencies/rud/${id}`);
  }
  getVats(): Observable<PaginatedResponse<Vat_Product[]>> {
    return this.api.get<PaginatedResponse<Vat_Product[]>>('/check_vats')
  }
  addVat(vat: any): Observable<any> {
    return this.api.post('/vats', { value: vat });
  }
  remove_Vat(id: any): Observable<any> {
    return this.api.delete<any>(`/vats/${id}`);
  }
}
