<div  class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}" (click)="app.onSidebarClick($event)"
     (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
  <div class="sidebar-logo">
    <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="app.onToggleMenuClick($event)">
      <i class="pi pi-lock" *ngIf="app.staticMenuActive"></i>
      <i class="pi pi-lock-open" *ngIf="!app.staticMenuActive"></i>
    </a>
    <a routerLink="/">
      <img    alt="logo" src="assets/img/logo72.svg" />
      <!-- style="    image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      -ms-interpolation-mode: nearest-neighbor;"  -->
    </a>
  </div>

  <div  style="overflow: hidden  !important;" class="layout-menu-container" (click)="onMenuClick()">
    <ul  class="layout-menu">
      <li  translate    app-menuitem  *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>
  </div>
</div>
