import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Expenseitems } from 'src/app/models/expense/expenseItems';
import { Payment } from 'src/app/models/orders/Payment';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { ApiService } from '../core/api.service';
import { isSet } from '../core/util';
import { CountryCode_currency } from 'src/app/models/country_code&currency';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  public newPayments: Payment[] = []
  public attachmentlink: string;
  public queris = []
  sort: any

  public refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public expenseSelected: BehaviorSubject<Expenseitems> = new BehaviorSubject<Expenseitems>(null);
  public expenseSelectedListEmitter: Observable<Expenseitems> = this.expenseSelected.asObservable();

  public retrieve_lastExpense: BehaviorSubject<Expenseitems> = new BehaviorSubject<Expenseitems>(null);
  public retrieve_lastExpenseEmitter: Observable<Expenseitems> = this.retrieve_lastExpense.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();

  public filterVat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public filterVatEmitter: Observable<any> = this.filterVat.asObservable();



  constructor(private api: ApiService, private datePipe: DatePipe) {
  }
  getExpenses() {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<Expenseitems[]>>(`/expenses?${sum_querirs}&order=${this.sort}`);

    }

    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Expenseitems[]>>(`/expenses?order=${this.sort}`);

    }
  }
  getExpenses_NextPage(num): Observable<PaginatedResponse<Expenseitems[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<Expenseitems[]>>(`/expenses?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Expenseitems[]>>(`/expenses?page=${num}&order=${this.sort}`);

    }
  }
  getfree_expenses() {
    return this.api.get<PaginatedResponse<Expenseitems[]>>('/free_note_expenses');
  }

  getExpense(id: string): Observable<Expenseitems> {
    return this.api.get<Expenseitems>(`/expenses/rud/${id}`);
  }
  deleteExpense(id: string): Observable<Expenseitems> {
    return this.api.delete<Expenseitems>(`/expenses/rud/${id}`);
  }
  getNexExpenseNumber(): Observable<{ format: string }> {
    return this.api.get<{ format: string }>('expenses/next_format');
  }
  addExpense(expense: Expenseitems): Observable<Expenseitems> {
    let date = this.datePipe.transform(expense.date, 'yyyy-MM-dd')
    return this.api.post('/expenses', expense.toDB(date));
  }

  upadeExpense(expense: Expenseitems) {
    let date = this.datePipe.transform(expense.date, 'yyyy-MM-dd')
    console.log(expense.toDB(date))
    return this.api.patch<any>(`/expenses/rud/${expense.id}`, expense.toDB(date))
  }


  uploadReceipt(receipt: FormData): Observable<any> {
    return this.api.post<any>(`/TextractorV3ViewSet`, receipt);
  }

  upload(filee: any) {
    const subscription = this.uploadDetilsEmitter.subscribe(uploadDetils => {
      if (!isSet(uploadDetils)) {
        return;
      }

      const credential = uploadDetils.upload_data.fields as credentialfilee


      const body = new FormData()
      this.uploadkey.next(credential.key)
      body.append("key", credential.key)
      body.append("x-amz-algorithm", credential['x-amz-algorithm'])
      body.append("x-amz-credential", credential['x-amz-credential'])
      body.append("x-amz-date", credential['x-amz-date'])
      body.append("policy", credential.policy)
      body.append("x-amz-signature", credential['x-amz-signature'])
      body.append("file", filee, filee.name)

      const sub = this.api.Uploadfile<any>(uploadDetils.upload_data.url, body).subscribe(() => {
        this.attachmentlink = credential.key

        this.uploadstuats.next(true)
        sub.unsubscribe()
      }, error => {
        this.uploadstuats.next(false)
        sub.unsubscribe()

      }
      )
      this.uploadDetils.next(null)
      subscription.unsubscribe()

    }, error => {
      subscription.unsubscribe()

    })
  }


  generate_Upload(file: any) {
    const subscription = this.api.generate_upload_url<any>(`/generate_s3_upload_url`, file).subscribe(uploadDetils => {

      if (!isSet(uploadDetils)) {
        return
      }
      subscription.unsubscribe()
      return this.uploadDetils.next(uploadDetils)


    })
  }
  generate_download(filename: any) {
    let d = {
      file_type: 'ExpenseItem',
      file_name: filename
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  delete_File(filename: any) {
    let d = {
      file_type: 'ExpenseItem',
      file_name: filename
    }
    return this.api.deleteFile(`/delete_s3_file`, d)
  }
  CountryCode_currency(): Observable<CountryCode_currency[]> {
    return this.api.getGuest('/countries/list')
  }
}
export class credentialfilee {
  "key": string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "policy": string
  "x-amz-signature": string
}

