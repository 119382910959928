import { Component, OnInit, ViewChild } from '@angular/core';
import { Contact } from '../../../models/contact';
import { Address } from '../../../models/address';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { ApiService } from '../../core/api.service';
import { MessageService } from 'primeng/api';
import { VatInfo } from '../../auth/models/vatinfo';
import { Tax } from 'src/app/models/Tax';
import { TaxService } from '../Tax.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-Tax-form',
  templateUrl: './Tax-form.component.html',
  styleUrls: ['./Tax-form.component.scss']
})
export class TaxFormComponent extends BaseComponent implements OnInit {

  Tax= new Tax;
  public showContactDialog = false;
  public showAddressDialog = false;


  public showAddCustomFieldDialog = false;
  public addCustomFieldSection;
  public customFieldName;


  @ViewChild('Tax_name') name:any;
  @ViewChild('Tax_value') value:any;
 

  constructor(private TaxService: TaxService, public translate:TranslateService, private api: ApiService, private messageService: MessageService) {
    super();
  }
  vatBtnDisabled = false;

  ngOnInit(): void {




    this.subscribeOnSaveFormClicked();
   this.getTax()
  }


 

  // addCustomField(): void {
  //   if(!isSet(this.Tax.ExtraFields))    this.Tax.ExtraFields=[]

  //   if (this.addCustomFieldSection === 'mainInfo') {
  //     this.Tax.ExtraFields.push({name: this.customFieldName, value: null});
  //   } 
  //   this.addCustomFieldSection = null;
  //   this.customFieldName = null;
  //   this.showAddCustomFieldDialog = false;
  // }
  getTax(){
    this.TaxService.TaxSelectedEmitter.subscribe((tax:Tax)=>{
      if(!isSet(tax)){
        return
      }
      this.Tax = tax
    })
  }
  private subscribeOnSaveFormClicked(): void {
    try {
      const subscription = this.TaxService.saveFormClickedEmitter.subscribe((data: boolean) => {
        if (!isSet(data) || !data) {
          return;
        }
        if (isSet(this.Tax.id)) {
          this.updateTax();
        } else {
          this.saveNewTax();
        }
      }, error => {
        this.log(error);
      });
      this.subscriptions.push(subscription);
    } catch (error) {
      this.log(error);
    }
  }




  private saveNewTax(): void {
    let state : boolean
    if( this.name.control.invalid ||   this.value.control.invalid  )
    {
      this.name.control.markAsTouched();  
      this.value.control.markAsTouched();
    this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS')});
      
  } else {    try {
      this.loading = true;
      this.messageService.add({key: 'myKey1' , severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'New Tax added successfully'});
      const subscription = this.TaxService.addTax(this.Tax).subscribe((tax: Tax) => {

        this.Tax = tax;
        this.TaxService.currentTax = Tax.cloneObject(tax);
        this.loading = false;
        state= true
        this.TaxService.refreshTaxes();
        subscription.unsubscribe();

      }, error => {
        this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail});

   
         this.loading = false;
        subscription.unsubscribe();
      });
    } catch (error) {
      this.loading = false;
      this.log(error);
      
    }}
  }

  private updateTax(): void {
    try {
      this.loading = true;
      const subscription = this.TaxService.updateTax(this.Tax).subscribe((tax: Tax) => {
        if (!isSet(tax)) {
          return;
        }
        this.Tax = Tax.cloneObject(tax);
        this.TaxService.currentTax = Tax.cloneObject(tax);
        this.loading = false;
        this.TaxService.refreshTaxes();
        subscription.unsubscribe();
      }, error => {
        this.loading = false;
        subscription.unsubscribe();
      });
    } catch (error) {
      this.loading = false;
      this.log(error);
    }
  }



}
