import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    public user:User[]=[{
      id:1,
      username:"test",
      first_name:"test",
      last_name:"test",
      email:"test",
      mobile:"test",
      image:"https://squirx-app.s3.amazonaws.com/public_media/Tue_Jul_05_2022img_ixjHdUP.jpg",
      viewimage:"test",
      showSettings:true,
      deletePic:true
    },
    {
      id:2,
      username:"test2",
      first_name:"test2",
      last_name:"test2",
      email:"test2",
      mobile:"test2",
      image:"https://squirx-app.s3.amazonaws.com/public_media/Tue_Jul_05_2022img_ixjHdUP.jpg",
      viewimage:"test2",
      showSettings:true,
      deletePic:true
    },
    {
      id:1,
      username:"test3",
      first_name:"test3",
      last_name:"test3",
      email:"test3",
      mobile:"test3",
      image:"https://squirx-app.s3.amazonaws.com/public_media/Tue_Jul_05_2022img_ixjHdUP.jpg",
      viewimage:"test3",
      showSettings:true,
      deletePic:true
    }]
    selectedUser:User
    public userSelected: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public userSelectedEmitter: Observable<User> = this.userSelected.asObservable();
    public createClicke: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public createClickeEmitter: Observable<boolean> = this.createClicke.asObservable();

    constructor() { }
    selectUser(user: User) {
      
      this.selectedUser=user
      //this.userSelected.next(user);
  }
  saveForm() {

   // this.saveFormClicked.next(true);
  }
}
