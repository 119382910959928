<p-toast position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid">
  <div class="p-col-10">

    <p-card>
      <div class="p-grid">
        <div class="p-col-8">
          <div  class="form-group ">
            <p-inplace #order>
              <span aria-required="true" pInplaceDisplay>
                <!-- <span *ngIf="import_orderstate" class="customer-inplace"> Imported form <label style="color: #0d6efd;">{{ CreditNote.invoice_format}}</label></span> -->
                <button id="selectorder"   styleClass="p-button-label"
       *ngIf="import_orderstate" [label]="orderformat " pButton type="button" class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace">
                              Imported form order:&nbsp;
              </button>
                  <!-- <span *ngIf="!import_orderstate" class="customer-inplace">
                    Import form order
                  </span> -->

                  <button  *ngIf="!import_orderstate"  pButton label="Import form purchase order" type="button" class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace"></button>
              </span>
<app-purchase-select pInplaceContent (purchaseOrderChange)="serach($event)"  (onHide)="order.deactivate()" ngDefaultControl> </app-purchase-select>
            </p-inplace>
          </div>
        </div>



      </div>
    </p-card>


    <p-card>
      <div class="p-grid">
        <div class="p-col-8">
          <div class="form-group">
            <p-inplace #vendor>
              <span pInplaceDisplay>
                  <!-- <span *ngIf="CreditNote.vendor" class="customer-inplace">{{ CreditNote.vendor.name }}</span> -->

                  <button *ngIf="CreditNote.vendor" pButton [label]="CreditNote.vendor.name" type="button" class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace"></button>

                  <!-- <span *ngIf="!PurchaseOrders.vendor" class="customer-inplace">Select Vendor
                    <i style="color: red;">*</i>
                  </span> -->

                  <button *ngIf="!CreditNote.vendor" pButton label='{{"NOTES_SUPPLIER_FORM_VENDOR" | translate}}' type="button" class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace"></button>


                  <div *ngIf="CreditNote.vendor==null && VendorOrder==true"  class="alert-invalid">{{"NOTES_CUSTOMERS_FORM_FIELD_REQ" | translate}} </div>

              </span>
              <app-supplier-select   pInplaceContent (SupplierChange)="selectvendor($event)"    (onHide)="vendor.deactivate()" ngDefaultControl></app-supplier-select>



            </p-inplace>
          </div>

          <div *ngIf="CreditNote.vendor" class="p-grid">
            <div  class="p-col-6">
              <span>{{ CreditNote.vendor.address[0].address }}</span><br>
              <span>{{ CreditNote.vendor.address[0].city }}</span><br>
              <span>{{ CreditNote.vendor.address[0].country_code }}</span><br>
              <span *ngIf="CreditNote.vendor?.contacts[0]?.phone">{{"CUSTOMER_FORM_PHONE" | translate}}: </span>
              <span>{{ CreditNote.vendor?.contacts[0]?.phone }}</span><br>
              <span>{{"CUSTOMER_LIST_VAT" | translate}}: </span> <span>{{ CreditNote.vendor.vat }}</span>
            </div>
            <div style="border-left: 2px solid var(--surface-c);" class="p-col-6">
              <span class="bold">{{"NOTES_SUPPLIER_FORM_BANK" | translate}}   </span><br><br>
              <span>BankName:{{ CreditNote.vendor.bank_name }}</span><br>
              <span>CreditLimit:{{ CreditNote.vendor.credit_limit }}</span><br>
            </div>
          </div>
        </div>

        <div class="p-col-4">
          <div class="form-group order-header-numbers">

            <div class="p-grid ">
              <div class="p-col-6 text-align-right ">
                <label  class="formattext " >{{"NOTES_CUSTOMERS_FORM_INVOICE_FORMAT" | translate}}: #</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-6 ">
                <p-inplace #invoice_format>
                  <span pInplaceDisplay>
                    <button  *ngIf=" CreditNote.order_format " style="  color: var(--primary-color) !important; font-weight: 600;     " pButton [label]="CreditNote.order_format" type="button" class=" p-button-text p-button-plain p-ripple p-button p-component " >
                    </button>
                    <button  *ngIf=" CreditNote.order_format == '' && Invoice_format.touched  " style="  font-weight: 600;  " pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid" >
                    </button>

                  </span>
                  <input  name="CreditNote.order_format"      #Invoice_format="ngModel"        [(ngModel)]="CreditNote.order_format" type="text" pInputText pInplaceContent (blur)="invoice_format.deactivate()">
                </p-inplace>
              </div>
            </div>



          </div>
        </div>

      </div>
    </p-card>

    <p-card>
      <p-table [value]="CreditNote.items" styleClass="p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 25%">{{"QUOTES_Form_ITEM" | translate}}</th>
            <th style="width: 20%">{{"DASHBORD_DATE" | translate}}</th>
            <th style="width: 10%">{{"QUOTES_Form_QTY" | translate}}</th>
            <th style="width: 15%">{{"NOTES_SUPPLIER_FORM_RECEVIED" | translate}}</th>
            <th style="width: 15%">{{"QUOTES_Form_PRICE" | translate}}</th>
            <th style="width: 10%" class="text-align-right">{{"QUOTES_LIST_TOTAL" | translate}}</th>
            <th style="width: 5%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td style="width: 25%" #productelemt>
              <app-products-select
                [(product)]="item.product" [width]="productelemt.offsetWidth - 30 + 'px'"
                (onChange)="productChanged($event, i)">
              </app-products-select>
              <div  *ngIf=" item.product == null && productTouched == true " class="alert-invalid">{{"NOTES_CUSTOMERS_FORM_ITEM_REQ" | translate}} </div>
            </td>
            <td style="width: 20%">
              <p-calendar dateFormat="dd-mm-yy"
                [(ngModel)]="item.date"
                name="item.date"
              ></p-calendar>
            </td>
            <td style="width: 10%">
              <input name="item.qty" [(ngModel)]="item.qty" type="text" pInputText>
            </td>
            <td style="width: 10%">
              <input name="item.received_qty " [(ngModel)]="item.received_qty" type="text" pInputText>
            </td>
            <td style="width: 15%">
              <input name="item.price" [(ngModel)]="item.price" type="text" pInputText>
            </td>

            <td style="width: 10%" class="text-align-right">
              {{ item.total }}
            </td>
            <td style="width: 5%" class="text-align-right">
              <i *ngIf="CreditNote.items.length > 1" (click)="CreditNote.items.splice(i, 1)"  class="fas fa-trash" style="color: #c82333;"></i>
              <button    *ngIf="CreditNote.items.length > 1" (click)="CreditNote.items.splice(i, 1)" pButton pRipple type="button" icon="fas fa-trash" class="p-button-text p-button-danger p-button-delete"></button>

            </td>
          </tr>
        </ng-template>
      </p-table>
      <button (click)="addItem()" pButton type="button" label='{{"CUSTOMER_FORM_ADD" | translate}}' class="ui-button-raised p-mt-2"></button>
      <label class="p-ml-3">{{"QUOTES_Form_OR_PREES" | translate}} <code>ctrl+enter</code></label>
    </p-card>

    <!-- <p-card>
      <div class="p-grid">
        <div class="p-col-8">
          <div class="form-group">
            <span class="p-float-label">
                <textarea [(ngModel)]=" CreditNote.note  " id="message-input" rows="3" placeholder="Message" pInputTextarea></textarea>
            </span>

          </div>
        </div>

        <div class="p-col-4 order-summary">

          <div class="p-grid">
            <div class="p-col-6">
              Discount (Excl.VAT):
            </div>
            <div class="p-col-6 text-align-right">
              <p-inplace #discount>
                  <span pInplaceDisplay>
                      <span>0</span>
                  </span>
                <input type="text" pInputText pInplaceContent (blur)="discount.deactivate()">
              </p-inplace>
            </div>
          </div>

          <div class="p-grid p-mt-3">
            <div class="p-col-6">Total (Net.):</div>
            <div class="p-col-6 text-align-right">{{ order.orderTotal }}</div>
          </div>

          <div class="p-grid">
            <div class="p-col-6">Total VAT:</div>
            <div class="p-col-6 text-align-right">{{ order.orderTotalVAT }}</div>
          </div>

          <div class="p-grid">
            <div class="p-col-6">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}}:</div>
            <div class="p-col-6 text-align-right">{{ order.orderTotalIncVAT }}</div>
          </div>

          <div class="p-grid">
            <div class="p-col-6">Currency:</div>
            <div class="p-col-6 text-align-right">EUR</div>
          </div>

          <div class="p-grid p-mt-3">
            <div class="p-col-6">Customer Balance:</div>
            <div class="p-col-6 text-align-right">700.00</div>
          </div>

        </div>
      </div>
    </p-card> -->
  </div>

</div>
