import { isSet } from '../../modules/core/util';
import * as _ from 'lodash';
import { Payment } from '../orders/Payment';
import { Expenseitems } from './expenseItems';
import { Payer } from './payer';
import { stringify } from '@angular/compiler/src/util';


export class ExpenseNote {
  id: string;
  title:string
  company:string;
  format:string;
  number:string
  payer_name:string
  date: Date
  description: string
  items:Expenseitems[]
  ex_status: string
  ex_type_2:string
  total: number;
  balance: number;
  payment_type:string
  payments:Payment[]
  payments_amount:number
  template:string;
  payer:Payer
  notes:string
  linktoview:string
  download_url:string
  get totalwithout_vat() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'item_total');
  }

  get vatTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatTotal');
  }

  get totalwithVat() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'totalwithVat');
  }
  get vatAmount_6(){
 
    return _.sumBy(this.items,(item)=>{
            return  item.vat_21 

      
    })|| 0

  }
  get vatAmount_12(){
 
    return _.sumBy(this.items,(item)=>{
            return  item.vat_12 

      
    })|| 0

  }
  get vatAmount_21(){
 
    return _.sumBy(this.items,(item)=>{
      
            return  item.vat_6 

      
    })|| 0

  }
  public constructor(params?: ExpenseNote) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: ExpenseNote) {
    if (!isSet(objectToClone)) {
      return new ExpenseNote();
    }
    const obj = new ExpenseNote(objectToClone);

    if (isSet(objectToClone?.payer)) {
      obj.payer = Payer.cloneObject(objectToClone?.payer);
    }
    if (isSet(objectToClone?.items)) {
      obj.items = Expenseitems.cloneManyObjects(objectToClone?.items);
    }
    if (isSet(objectToClone?.payments)) {
      obj.payments = Payment.cloneManyObjects(objectToClone?.payments);
    }
    return obj;

  }
  public static cloneManyObjects(objectsToClone: ExpenseNote[]) {
    const objects: ExpenseNote[] = [];
    for (const obj of objectsToClone) {
      objects.push(ExpenseNote.cloneObject(obj));
    }

    return objects;
  }

  public toDB(expense_date) {

    return {

      format: this.format,
      payer: this.payer?.id,
      date:expense_date,
      payments:this.payments,
      payments_amount:this.payments_amount,
      notes:this.notes,
      ex_status:this.ex_status,
      description:this.description,
        items: _.map(this.items, item => {
        return {
          id:item.id,
          format: item.format,
          date:item.date,
          exchange_rate:item.exchange_rate,
          amount:item.amount,
          number: item.number,
          payment_type:item.payment_type,
          payments:item.payments,
          currency: item?.currency?.id,
          attachment:item.attachment,
          description:item.description,
          ex_type:item.ex_type,
          ex_type_2:item.ex_type_2,
          vat_6:item.vat_6,
          vat_12:item.vat_12,
          vat_21:item.vat_21,
          vendor:item.vendor?.id,
          vendor_name:item.vendor_name,
          vat:item.vat,
          invoice_format:item.invoice_format,
          invoice_number:item.invoice_number,
          vcs:item.vcs,
          iban:item.iban
        }
      }),
      title:this.title
    }

  }



}
