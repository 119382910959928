import { isSet } from '../modules/core/util';
import { Address } from './address';
import { Bank } from './Bank';
import { Contact } from './contact';
import { ExtraFields } from './extrafields';

export class Supplier {
  id: string;
  name: string;
  vat: string;
  vendor_type : string
  bank_name: string;
  bank_account_number: string;
  BIC : any
  IBAN : any
  credit_limit:number
  payment_terms : string;
  company:any;
   language:string
   vendor_name:string
  currency:string
  created_at: Date;
  updated_at: Date;
  contacts: Contact[];
  address: Address[] ;
  extra_fields: ExtraFields[];
  discount:number;
  firstcontact :Contact

  public constructor(params?: Supplier) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Supplier) {

    const obj = new Supplier(objectToClone);
    if (isSet(obj.contacts)) {
      obj.contacts = Contact.cloneManyObjects(objectToClone.contacts);
    }
    if (isSet(obj.extra_fields)) {
      obj.extra_fields = ExtraFields.cloneManyObjects(objectToClone.extra_fields);
    }

    if (isSet(obj.address)) {
      obj.address = Address.cloneManyObjects(objectToClone.address);
    }
    if (isSet(obj.firstcontact)) {
      obj.firstcontact = Contact.cloneObject(objectToClone.firstcontact);
        }
        return obj;

  }

  public static cloneManyObjects(objectsToClone: Supplier[]) {
    const objects: Supplier[] = [];
    for (const obj of objectsToClone) {
      objects.push(Supplier.cloneObject(obj));
    }

    return objects;
  }

  public toDB() {
    return {

      name: this.name,
      vat:this.vat,
      discount:this.discount,
      credit_limit:this.credit_limit,
      payment_terms:this.payment_terms,
      bank_name:this.bank_name,
      bank_account_number:this.bank_account_number,
      BIC:this.BIC,
      iban:this.IBAN,
      language:this.language,
      currency:this.currency,
      address: this.address,
      contacts: this.contacts,
      extra_fields: this.extra_fields,


        }

  }
}
