
import { isSet } from '../../modules/core/util';


export class Payer {
    id: string;
    name: string;
    company: any;
    due: number;
    payments: number;
    created_by: any;
    created_at: Date;
    updated_at: Date


    public constructor(params?: Payer) {
        Object.assign(this, params);
    }

    public static cloneObject(objectToClone: Payer) {
        if (!isSet(objectToClone)) {
            return new Payer();
        }
        const obj = new Payer(objectToClone);


        return obj;
    }

    public static cloneManyObjects(objectsToClone: Payer[]) {
        const objects: Payer[] = [];
        for (const obj of objectsToClone) {
            objects.push(Payer.cloneObject(obj));
        }

        return objects;
    }
    public toDB(){
        return { 
            name:this.name
        }
    }
}


