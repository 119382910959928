import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Contact } from '../../../models/contact';
import { Customer } from '../../../models/customer';
import { CustomerDetails } from '../../../models/customerdetails';
import { AccountManager } from '../../../models/accountmanager';
import { Address } from '../../../models/address';
import { BaseComponent } from '../../core/base/base.component';
import { CustomerService } from '../customer.service';
import { isSet } from '../../core/util';
import { VatInfo, VatInfoCountry } from '../../auth/models/vatinfo';
import { ApiService } from '../../core/api.service';
import { MessageService } from 'primeng/api';
import { CustomerPricing } from '../../../models/customerpricing';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { SpecialPrice } from '../../../models/specialprice';
import { PricingCategory } from '../../../models/pricingcategory';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Dropdown } from 'primeng/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { Region } from 'src/app/models/region';
import places from 'places.js'
import { AuthService } from '../../auth/auth.service';
import { CountryCode_currency } from 'src/app/models/country_code&currency';


@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})

export class CustomerFormComponent extends BaseComponent implements OnInit, AfterViewInit {

  vatBtnDisabled = false;

  public accountManagers: AccountManager[] = [];
  public specialPrices: SpecialPrice[] = [];
  public pricingCategories: PricingCategory[] = [];
  public contacts: Contact[] = [];
  public contact = new Contact()
  public customer: Customer;
  public shipping_address = new Address()
  public showContactDialog = false;
  public ContactDialogHeader = 'Add Contact'
  public shipingaddressDialogHeader = 'Add Shipping addresses'
  public showAccountmanager = false
  public index_Toupdate = 0
  public showAccountmanager_flabel = false
  public showSpecialPrice = false
  public showspecialPrices_flabel = false
  specialPricesheader = 'Add Special Price'
  public addAccountManagerheader = 'Add Account Manager'
  public showdeleteAccountmanager = false
  public showdeleteSpecialPrice = false
  public showAddressDialog = false;
  public showpricingcategory_flabel = false
  public showpricingCategoryDialog = false
  pricingCategoryheader = 'Add Pricing Category'
  public showAddCustomFieldDialog = false;
  public showdeletePricingCategory = false
  public addCustomFieldSection;
  public customFieldName;
  public mainInfoCustomField = [];
  public updateStatus = false
  public regions: Region[] = []
  public regionHeader: string
  public region = new Region()
  public showAddcRegion = false
  public showRegion = false
  public showRegion_flabel = true
  public showDeletedRegion = false
  countries: CountryCode_currency[] = []
  fileViewer = false
  attachment_name: string
  account_Manager = new AccountManager()
  special_Price = new SpecialPrice()
  pricing_Category = new PricingCategory()
  attachmentdilioge = false
  pdfheight = 0
  recpieImage: string
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  }
  @ViewChild('dd') dd: Dropdown;
  @ViewChild('pp') pp: Dropdown;
  @ViewChild('cc') cc: Dropdown;
  @ViewChild('regionD') regionD: Dropdown;
  @ViewChild('upload') upload: ElementRef;

  @ViewChild('company_name') company_name: any
  @ViewChild('email') email: any;
  @ViewChild('shipping_addresscountry_code') shipping_addresscountry_code: any;
  @ViewChild('shipping_addresscity') shipping_addresscity: any;
  @ViewChild('shipping_addresszip') shipping_addresszip: any;
  @ViewChild('shippingaddress') shippingaddress: any;
  @ViewChild('specialPrice') specialPrice_validation: any;
  @ViewChild('accountManager') accmanger_validation: any;
  @ViewChild('pricingCategory') pricingCategory_validation: any;
  @ViewChild('attachmentname') attachmentnamevaild: any;
  @ViewChild('addressInput') addressInput: any;
  @ViewChild('shipInput') shipInput: any;



  constructor(
    private customerService: CustomerService,
    private sharedService: SharedService,
    private api: ApiService,
    private messageService: MessageService,
    private router: Router,
    private auth: AuthService,
    public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {

    this.getAccountManagers();
    this.getSpecialPrices();
    this.getPricingCategories();
    this.getRegions()
    this.Save_form()
    this.subscribeOnCusomterSelected()
    this.subscribeOnSaveFormClicked();
    this.getSavedCustomer()
    this.clearAll()
    this.updateCustomerEmiiter()
    this.listCounties()
    this.showFromDash()
  }
  ngAfterViewInit() {
    var placesAutocomplete = places({
      container: this.addressInput.nativeElement
    });
    placesAutocomplete.on('change', (e: any) => {
      this.customer.invoicing_address.address = e?.suggestion.value
    });
    var shipAutocomplete = places({
      container: this.shipInput.nativeElement
    });
    shipAutocomplete.on('change', (e: any) => {
      this.shipping_address.address = e?.suggestion.value

    });
  }

  private subscribeOnCusomterSelected() {

    const subscription = this.customerService.customerSelectedEmitter.subscribe((customer: Customer) => {
      if (!isSet(customer)) {
        return this.initcustomer()


      }
      this.getCustomer(customer.id)
      this.customerService.customerSelected.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  private getCustomer(id: string) {
    this.loading = true
    const subscription = this.customerService.getCustomer(id).subscribe((customer: Customer) => {
      if (!isSet(customer)) {
        return;
      }

      const cloneObject = Customer.cloneObject(customer);

      this.customer = cloneObject;


      if (!isSet(this.customer?.payment_details)) {
        this.customer.payment_details = new CustomerDetails
      }
      if (!isSet(this.customer.pricing)) {
        this.customer.pricing = new CustomerPricing()
      }
      if (!isSet(this.customer.invoicing_address)) {
        this.customer.invoicing_address = new Address()
      }
      if (!isSet(this.customer.contact)) {
        this.customer.contact = []
      }
      if (!isSet(this.customer.shipping_address)) {
        this.customer.shipping_address = []
      }
      if (!isSet(this.customer.attachments)) {
        this.customer.attachments = []
      }
      if (!isSet(this.customer.payment_details.extra_fields)) {
        this.customer.payment_details.extra_fields = []
      }
      if (!isSet(this.customer.main_info_extra_fields)) {
        this.customer.main_info_extra_fields = []
      }
      subscription.unsubscribe();
      this.loading = false


    }, error => {
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false

    });
  }
  refreshCustomer() {

    this.company_name?.control.markAsUntouched()
    this.company_name?.control.clearValidators()

    this.shipping_addresscountry_code?.control.markAsUntouched()
    this.shipping_addresscountry_code?.control.clearValidators()

    this.shipping_addresscity?.control.markAsUntouched()
    this.shipping_addresscity?.control.clearValidators()

    this.shipping_addresszip?.control.markAsUntouched()
    this.shipping_addresszip?.control.clearValidators()

    this.shippingaddress?.control.markAsUntouched()
    this.shippingaddress?.control.clearValidators()
    //
    this.email?.control.markAsUntouched()
    this.email?.control.clearValidators()

  }
  showUploader() {
    if (!isSet(this.attachment_name)) {
      this.attachmentnamevaild.control.markAsTouched();
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    }
    this.upload.nativeElement.click()

  }
  myUploader(imageResult: ImageResult) {

    this.recpieImage = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;

    const filee = new FormData();
    this.srcToFile(this.recpieImage, imageResult.file.name, imageResult.file.type).then((file) => {
      filee.append('file', file)
      if (file.type == 'application/pdf') {
        this.messageService.add({ key: 'formM', severity: 'info', life: 2000, summary: this.translate.instant('INFO'), detail: this.translate.instant('FILE_UPLOAD') });

      }

      const generatUpload = {
        file_type: 'user_report',
        file_path: file.name
      }

      this.customerService.generate_Upload(generatUpload)

      this.customerService.upload(file)
      try {
        this.loading = true
        const subscription = this.customerService.uploadstuatsEmitter.subscribe(status => {
          if (!isSet(status) || !status) {
            return;
          }
          if (status === true) {

            const at = {
              path: this.customerService.attachmentlink,
              type: file.type,
              name: this.attachment_name
            }
            this.updateStatus = true
            this.customer.attachments.push(at)
            this.messageService.add({
              key: 'form',
              severity: 'success',
              summary: this.translate.instant('SUCCESSFUL'),
              detail: this.translate.instant('UPLOADED_SUCCESS')
            });
            this.attachment_name = null
            this.attachmentnamevaild.control.markAsUntouched()
            this.attachmentnamevaild.control.clearValidators()
            this.loading = false
            this.attachmentdilioge = false

          } else if (status === false) {
            this.messageService.add({
              key: 'form',
              severity: 'error',
              summary: this.translate.instant('ERR_ESSAGE'),
              detail: this.translate.instant('NETWORK_ERROR')
            });
            this.loading = false

          }
          this.customerService.uploadstuats.next(null)
          subscription.unsubscribe()
        }, error => {
          this.loading = false
          this.handleError(error);
          subscription.unsubscribe()

        });
      } catch (error) {
        console.log(error);
      }
    })
  }
  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  generate_download(seletectAttachment) {
    this.customer.seletectAttachment = seletectAttachment
    try {
      this.loading = true
      const subscription = this.customerService.generate_download(this.customer.seletectAttachment.path).subscribe(link => {
        this.customer.download_url = link?.download_url
        this.customer.view_url = link?.view_url
        this.fileViewer = true
        this.loading = false
        subscription.unsubscribe()
      }, error => {
        this.loading = false
        this.handleError(error);
        subscription.unsubscribe()
      })
    } catch (error) {
      this.log(error);
    }
  }

  downloadlink() {
    window.location.href = this.customer.download_url;
  }

  removerlink(item_) {
    this.customer.attachments = this.customer.attachments.filter(item => {
      return item !== item_
    })
    this.updateStatus = true
  }

  initcustomer() {
    this.refreshCustomer()
    this.customer = new Customer();
    this.customer.main_info_extra_fields = [];
    this.customer.contact = [];
    this.customer.payment_details = new CustomerDetails();
    this.customer.payment_details.extra_fields = [];
    this.customer.invoicing_address = new Address();
    this.customer.shipping_address = []
    this.shipping_address = new Address();
    this.contact = new Contact();
    this.customer.pricing = new CustomerPricing();
    this.showAccountmanager_flabel = false
    this.customer.attachments = []
  }

  public adShipping_address(): void {
    if ((!isSet(this.shipping_address.country_code) || (this.shipping_addresscountry_code.control.invalid))
      || (!isSet(this.shipping_address.city) || (this.shipping_addresscity.control.invalid))
      || (!isSet(this.shipping_address.zip) || (this.shipping_addresszip.control.invalid))
      || (!isSet(this.shipping_address.address) || (this.shippingaddress.control.invalid))) {
      this.shipping_addresscountry_code.control.markAsTouched();
      this.shipping_addresscity.control.markAsTouched();
      this.shipping_addresszip.control.markAsTouched();
      this.shippingaddress.control.markAsTouched();
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      if (!isSet(this.customer.shipping_address)) {
        this.customer.shipping_address = []
      }
      this.customer.shipping_address.push(this.shipping_address);
      this.shipping_address = new Address()
      this.showAddressDialog = false;
      this.shipping_addresscountry_code.control.markAsUntouched()
      this.shipping_addresscountry_code.control.clearValidators()
      this.shipping_addresscity.control.markAsUntouched()
      this.shipping_addresscity.control.clearValidators()
      this.shipping_addresszip.control.markAsUntouched()
      this.shipping_addresszip.control.clearValidators()
      this.shippingaddress.control.markAsUntouched()
      this.shippingaddress.control.clearValidators()
    }
  }

  showAddAdrees() {
    this.shipping_address = new Address()
    this.shipingaddressDialogHeader = null
    this.shipingaddressDialogHeader = this.translate.instant('SUPPLIERS_FORM_ADD_SHIPP_NAME')
    this.showAddressDialog = true;
  }
  fillShippingAdrress() {
    if (isSet(this.customer.invoicing_address)) {
      this.shipping_address = Address.cloneObject(this.customer.invoicing_address)

    }

  }
  preventDefault(event, element) {
    const el = element.model
    if (!el.replace(/\s/g, '').length) {
      event.preventDefault();
    }
  }

  showEidtAdrees(index: number) {
    this.shipingaddressDialogHeader = this.translate.instant('EDIT_SHIP_ADDRES')
    this.shipping_address = Address.cloneObject(this.customer.shipping_address[index])
    this.index_Toupdate = index
    this.showAddressDialog = true;
  }

  eidtAdrees() {
    if ((!isSet(this.shipping_address.country_code) || (this.shipping_addresscountry_code.control.invalid))
      || (!isSet(this.shipping_address.city) || (this.shipping_addresscity.control.invalid))
      || (!isSet(this.shipping_address.zip) || (this.shipping_addresszip.control.invalid))
      || (!isSet(this.shipping_address.address) || (this.shippingaddress.control.invalid))) {
      this.shipping_addresscountry_code.control.markAsTouched();
      this.shipping_addresscity.control.markAsTouched();
      this.shipping_addresszip.control.markAsTouched();
      this.shippingaddress.control.markAsTouched();
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.customer.shipping_address[this.index_Toupdate] = this.shipping_address
      this.shipping_address = new Address()
      this.showAddressDialog = false;
      this.shipping_addresscountry_code.control.markAsUntouched()
      this.shipping_addresscountry_code.control.clearValidators()
      this.shipping_addresscity.control.markAsUntouched()
      this.shipping_addresscity.control.clearValidators()
      this.shipping_addresszip.control.markAsUntouched()
      this.shipping_addresszip.control.clearValidators()
      this.shippingaddress.control.markAsUntouched()
      this.shippingaddress.control.clearValidators()
    }
  }

  rmAdrees(index: number) {
    this.customer.shipping_address.splice(index, 1)
    this.updateStatus = true
  }

  normalize_VAT(event) {
    this.customer.vat = event?.replace(/\s/g, '')
    this.customer.vat = this.customer?.vat?.replace(/\./g, '').toUpperCase()
    this.sharedService.chekcempey.next(true)
    this.updateStatus = true
  }

  public addContact(): void {
    if (!isSet(this.contact.email) || this.email.control.invalid) {
      this.email.control.markAsTouched();

      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.customer.contact.push(this.contact);
      this.contact = new Contact()
      this.email.control.markAsUntouched()
      this.email.control.clearValidators()
      this.showContactDialog = false;
      this.updateStatus = true
    }
  }

  showEidtContact(index: number) {
    this.ContactDialogHeader = this.translate.instant('EDIT_CONTACT')
    this.contact = Contact.cloneObject(this.customer.contact[index])


    this.index_Toupdate = index
    this.showContactDialog = true;

  }

  eidtContact() {
    if (!isSet(this.contact.email) && this.email.control.invalid) {
      this.email.control.markAsTouched();

      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.customer.contact[this.index_Toupdate] = this.contact
      this.contact = new Contact()
      this.email.control.markAsUntouched()
      this.email.control.clearValidators()
      this.showContactDialog = false;
      this.updateStatus = true
    }
  }

  rmContact(index: number) {
    this.customer.contact.splice(index, 1)
    this.updateStatus = true
  }

  showAddContact() {
    this.contact = new Contact()
    this.ContactDialogHeader = this.translate.instant('CUSTOMER_FORM_ADD_CONTACT')
    this.showContactDialog = true;
  }

  addCustomField(): void {
    if (this.addCustomFieldSection === 'mainInfo') {
      this.customer.main_info_extra_fields.push({ name: this.customFieldName, value: null });
    } else if (this.addCustomFieldSection === 'otherDetails') {
      this.customer.payment_details.extra_fields.push({ name: this.customFieldName, value: null });
    }
    this.addCustomFieldSection = null;
    this.customFieldName = null;
    this.showAddCustomFieldDialog = false;
    
  }

  getDataFromVat(): void {
    if (this.customer.vat.length < 9) {
      return
    }

    this.vatBtnDisabled = true;
    const subscription = this.api.postGuest('/extract_vat_info', {
      vat_number: this.customer.vat
    }).subscribe((vatInfo: VatInfo) => {
      if (!isSet(vatInfo)) {
        return;
      }
      this.customer.company_name = vatInfo.name;
      const address = vatInfo.address.split('\n');
      if (address.length >= 2) {
        this.customer.invoicing_address.address = address[0]
        const zip = address[1].split(' ')
        if (zip.length >= 2) {
          this.customer.invoicing_address.zip = zip[0]
          this.customer.invoicing_address.city = zip[1]
        }
        if (isSet(VatInfoCountry[vatInfo.countryCode])) {
          this.customer.invoicing_address.country_code = VatInfoCountry[vatInfo.countryCode];
        }
      }
    }, error => {

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: "Can't find vat information" });
    
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    }, () => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    });
  }

  private subscribeOnSaveFormClicked(): void {
    try {
      const subscription = this.customerService.saveFormClickedEmitter.subscribe((data: boolean) => {
        if (!isSet(data) || !data) {
          return;
        }
        this.saveNewCustomer();
      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);
    } catch (error) {
      this.log(error);
    }
  }

  private saveNewCustomer(): void {
    let noerror
    if (!isSet(this.customer?.company_name) || this.company_name?.control.invalid) {
      this.company_name?.control.markAsTouched();
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
      noerror = false
    } else {
      noerror = true
    }
    if (noerror === true) {
      try {
        this.loading = true;
        const subscription = this.customerService.addCustomer(this.customer).subscribe((customer: Customer) => {
          this.customer = customer;
          this.loading = false;
          this.customerService.refreshCustomers();
          this.customerService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)
          this.customerService.showaftersaveForm.next(true)
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_CUSTOMER_SUCCESS')
          });
          subscription.unsubscribe();
        }, error => {

          if (error.error?.detail) {
            this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          } else if (error?.error?.company_name[0]) {
            this.messageService.add({
              key: 'form',
              severity: 'error',
              summary: this.translate.instant('ERR_ESSAGE'),
              detail: 'Customer name cannot be used twice'
            });
          } else {
            this.messageService.add({
              key: 'form',
              severity: 'error',
              summary: this.translate.instant('ERR_ESSAGE'),
              detail: this.translate.instant('NETWORK_ERROR')
            });
          }
          this.loading = false;
          subscription.unsubscribe();
        });
      } catch (error) {
        this.loading = false;
        this.log(error);
      }
    }
  }

  private updateCustomerEmiiter(): void {
    try {
      const subscription = this.customerService.upadteFormClickedEmitter.subscribe(status => {
        if (!isSet(status) || !status) {
          return;
        }
        this.updateCustomer()
      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);
    } catch (error) {
      this.log(error);
    }
  }

  updateCustomer() {
    if (this.updateStatus === false) {
      this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });
    } else {
      try {
        this.loading = true;
        const subscription = this.customerService.updateCustomer(this.customer).subscribe((customer: Customer) => {
          if (!isSet(customer)) {
            return;
          }
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('UPDATED_SUCC')
          });
          this.loading = false;
          this.customerService.refreshCustomers()
          this.customerService.upadteFormClicked.next(null)
          this.sharedService.update_form.next(false);
          
          subscription.unsubscribe();

          this.updateStatus = false
        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          this.loading = false;
          this.customerService.upadteFormClicked.next(null)

          this.updateStatus = false
          subscription.unsubscribe();

          console.log(error);
          
        })
      } catch (error) {
        this.loading = false;
        this.log(error);
      }
    }
  }

  private getAccountManagers() {
    try {
      const subscription = this.customerService.getAccountManagers().subscribe((data: PaginatedResponse<AccountManager[]>) => {
        if (!isSet(data)) {
          return;
        }
        this.accountManagers = AccountManager.cloneManyObjects(data.results);
        const addAccountManager = new AccountManager();
        addAccountManager.id = '0';
        addAccountManager.name = '<span class="add-quick-option">' + this.translate.instant('NEW_ACCO_MAG') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.accountManagers = insert(this.accountManagers, 0, addAccountManager)
        setTimeout(() => {
          this.initaccountmanager();
        }, 100);
        subscription.unsubscribe();
      }, error => {
        this.log(error);
        subscription.unsubscribe();
      });
    } catch (error) {
      this.log(error);
    }
  }

  showAddAccontmanger() {
    this.accmanger_validation?.control.clearValidators()
    this.accmanger_validation?.control.markAsUntouched()
    this.addAccountManagerheader = this.translate.instant('NEW_ACCO_MAG')
    this.showAccountmanager = true
  }

  showeditAccontmanger(item: AccountManager) {
    this.account_Manager = AccountManager.cloneObject(item)
    this.addAccountManagerheader = this.translate.instant('CUSTOMER_FORM_EDIT_ACC_MANG')
    this.showAccountmanager = true
    this.index_Toupdate = this.accountManagers.indexOf(item)
  }

  save_AccountManagers() {
    if (!isSet(this.account_Manager.name)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
      this.accmanger_validation.control.markAsTouched();

    } else {
      this.loading = true;

      const subscription = this.customerService.addAccountManagers(this.account_Manager).subscribe((accountManager: AccountManager) => {
        this.loading = false;
        if (!isSet(accountManager)) {
          return;
        }
        subscription.unsubscribe();
        this.loading = false;
        this.showAccountmanager = false
        this.getAccountManagers()
        const x = setInterval(() => {
          this.customer.payment_details.account_manager = this.accountManagers[1]
          if (this.customer.payment_details.account_manager?.name === this.account_Manager?.name) {
            this.showAccountmanager_flabel = false
            clearInterval(x);
          }
        }, 300)
        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('NEW_ACC_MANG_SUCCESS')
        });
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
        this.showAccountmanager = false
        subscription.unsubscribe();
        this.loading = false;
      })
    }
  }

  cancelshowAddAccontmanger() {
    this.showAccountmanager = false
    this.showAccountmanager_flabel = true
    this.customer.payment_details.account_manager = null
    this.sharedService.dialog_status.next(false)
  }

  accountmanagerChanged($event) {
    const accountManager = $event.value as AccountManager;
    this.showAccountmanager_flabel = false
    if (accountManager?.id === '0') {
      this.account_Manager = new AccountManager()
      this.customer.payment_details.account_manager = new AccountManager()
      this.showAccountmanager_flabel = true
      return this.showAddAccontmanger()
    }
    if (accountManager?.id === '-1') {
      this.account_Manager = new AccountManager()
      this.account_Manager.name = accountManager.name.replace(
        '<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_ACC_MANG_SUCCESS') + ')</span>', '')
      this.showAccountmanager_flabel = true
      this.customer.payment_details.account_manager = new AccountManager()
      return this.showAddAccontmanger()
    }
    if (!isSet(accountManager)) {
      this.showAccountmanager_flabel = true
      this.updateStatus = true
    }
  }

  showdeleteAccontmanger(item: AccountManager) {
    this.account_Manager = item
    this.showdeleteAccountmanager = true
    this.customer.payment_details.account_manager = null
  }

  canceldeleteAccontmanger() {
    this.showAccountmanager_flabel = true
    this.showdeleteAccountmanager = false
    this.customer.payment_details.account_manager = null
    this.sharedService.dialog_status.next(false)
  }

  deleteAccontmanger() {
    this.loading = true;
    const subscription = this.customerService.deleteAccountManager(this.account_Manager.id).subscribe(() => {
      if (!isSet(this.account_Manager)) {
        return;
      }
      this.messageService.add({
        key: 'list',
        severity: 'success',
        life: 2000,
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('SELECT_ACC_MANG_DEL')
      });
      this.account_Manager = new AccountManager()
      this.getAccountManagers()
      this.showdeleteAccountmanager = false
      this.showAccountmanager_flabel = true
      this.loading = false;
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({
        key: 'list',
        severity: 'error',
        life: 3000,
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: error.error.detail
      });
      this.showdeleteAccountmanager = false
      this.loading = false;
      subscription.unsubscribe();
      this.getAccountManagers()
    })
  }

  updateAccountManger() {
    try {
      this.loading = true;
      const subscription = this.customerService.updateAccountManger(this.account_Manager).subscribe((accountManager: AccountManager) => {
        if (!isSet(accountManager)) {
          return;
        }
        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('UPDATED_SUCC')
        });
        this.loading = false;
        this.showAccountmanager = false
        this.getAccountManagers()
        const x = setInterval(() => {
          this.customer.payment_details.account_manager = this.accountManagers[this.index_Toupdate]
          if (this.customer.payment_details.account_manager?.name === this.accountManagers[this.index_Toupdate].name) {
            clearInterval(x);
          }
        }, 300)
        subscription.unsubscribe();
        this.customerService.upadteFormClicked.next(null)
        this.updateStatus = false
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.customerService.upadteFormClicked.next(null)
        this.updateStatus = false
      })
    } catch (error) {
      this.loading = false;
      this.log(error);
    }
  }

  private initaccountmanager(): void {
    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;
      const options = this.accountManagers.filter(category => {
        return category.name.toLowerCase().includes(value.toLowerCase());
      });
      const addcAccountManager = new AccountManager();
      addcAccountManager.id = '-1';
      addcAccountManager.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_ACC_MANG_SUCCESS') + ')</span>';
      options.push(addcAccountManager);
      this.dd.optionsToDisplay = options;
    };
  }

  private initspecialPrices(): void {
    this.pp.activateFilter = () => {
      const value = this.pp.filterValue;
      const options = this.specialPrices.filter(specialPrice => {
        return specialPrice.name.toLowerCase().includes(value.toLowerCase());
      });

      const addcAccountManager = new AccountManager();
      addcAccountManager.id = '-1';
      addcAccountManager.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_SPICAL_PRICE') + ')</span>';

      options.push(addcAccountManager);

      this.pp.optionsToDisplay = options;
    };
  }

  private getSpecialPrices() {
    try {
      const subscription = this.customerService.getSpecialPrices().subscribe((data: PaginatedResponse<SpecialPrice[]>) => {
        if (!isSet(data)) {
          return;
        }

        this.specialPrices = SpecialPrice.cloneManyObjects(data.results)
        const addspecialPrices = new SpecialPrice();
        addspecialPrices.id = '0';
        addspecialPrices.name = '<span class="add-quick-option">' + this.translate.instant('NEW_SPICAL_PRICE') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.specialPrices = insert(this.specialPrices, 0, addspecialPrices)
        setTimeout(() => {
          this.initspecialPrices();
        }, 100);
        subscription.unsubscribe();
      }, error => {

        this.log(error);
        subscription.unsubscribe();
      });
    } catch (error) {
      this.log(error);
    }
  }

  showAddSpecialPrice() {
    this.specialPrice_validation?.control.clearValidators()
    this.specialPrice_validation?.control.markAsUntouched()
    this.specialPricesheader = this.translate.instant('ADD_SPECAL_PRICE')
    this.showSpecialPrice = true
  }

  showeditSpecialPrice(item: SpecialPrice) {
    this.special_Price = SpecialPrice.cloneObject(item)
    this.specialPricesheader = this.translate.instant('EDIT_SPECAL_PRICE')
    this.showSpecialPrice = true
    this.index_Toupdate = this.specialPrices.indexOf(item)
  }

  updateSpecialPrice() {
    try {
      this.loading = true;
      const subscription = this.customerService.updateSpecialPrices(this.special_Price).subscribe((special_Price: SpecialPrice) => {
        if (!isSet(special_Price)) {
          return;
        }
        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('UPDATED_SUCC')
        });
        this.loading = false;
        this.showSpecialPrice = false
        this.getSpecialPrices()
        const x = setInterval(() => {
          this.customer.pricing.special_price = this.specialPrices[this.index_Toupdate]
          if (this.customer.pricing.special_price?.name === this.specialPrices[this.index_Toupdate]?.name) {
            clearInterval(x);
          }
        }, 300)
        subscription.unsubscribe();
        this.customerService.upadteFormClicked.next(null)
        this.updateStatus = false
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.customerService.upadteFormClicked.next(null)
        this.updateStatus = false

      })
    } catch (error) {
      this.loading = false;
      this.log(error);
    }
  }

  specialPricesChanged($event) {
    const specialPrice = $event.value as SpecialPrice;
    this.showspecialPrices_flabel = false
    if (specialPrice?.id === '0') {
      this.special_Price = new SpecialPrice()
      this.customer.pricing.special_price = new SpecialPrice()
      this.showspecialPrices_flabel = true
      return this.showAddSpecialPrice()
    }
    if (specialPrice?.id === '-1') {
      this.special_Price = new SpecialPrice()
      this.special_Price.name = specialPrice.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_SPICAL_PRICE') + ')</span>', '')
      this.showspecialPrices_flabel = true
      this.customer.pricing.special_price = new SpecialPrice()
      return this.showAddSpecialPrice()
    }
    if (!isSet(specialPrice)) {
      this.showspecialPrices_flabel = true
      this.updateStatus = true
    }
  }

  save_specialPrices() {
    if (!isSet(this.special_Price.name)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
      this.specialPrice_validation.control.markAsTouched();
    } else {
      this.loading = true;
      const subscription = this.customerService.addSpecialPrices(this.special_Price).subscribe((specialPrices: SpecialPrice) => {
        this.loading = false;
        if (!isSet(specialPrices)) {
          return;
        }
        subscription.unsubscribe();
        this.loading = false;

        this.showSpecialPrice = false
        this.getSpecialPrices()
        const x = setInterval(() => {
          this.customer.pricing.special_price = this.specialPrices[1]
          if (this.customer.pricing?.special_price.name === this.special_Price?.name) {
            this.showspecialPrices_flabel = false

            clearInterval(x);
          }
        }, 300)
        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('NEW_SPICAL_CUCCESS')
        });
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
        this.showAccountmanager = false
        subscription.unsubscribe();
        this.loading = false;
      })
    }
  }

  cancelspecialPrices() {
    this.showSpecialPrice = false
    this.showspecialPrices_flabel = true
    this.customer.pricing.special_price = null
    this.sharedService.dialog_status.next(false)
  }

  showdeletespecialPrices(item: SpecialPrice) {
    this.special_Price = item
    this.showdeleteSpecialPrice = true
    this.customer.pricing.special_price = null
  }

  deletespecialPrice() {
    this.loading = true;
    const subscription = this.customerService.deleteSpecialPrices(this.special_Price.id).subscribe(() => {
      this.messageService.add({
        key: 'list',
        severity: 'success',
        life: 2000,
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('SELECTE_SPECAL_DELETE')
      });
      this.special_Price = new SpecialPrice()
      this.getSpecialPrices()
      this.showdeleteSpecialPrice = false
      this.showspecialPrices_flabel = true
      this.loading = false;
      subscription.unsubscribe();

    }, error => {
      this.messageService.add({
        key: 'list',
        severity: 'error',
        life: 3000,
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: error.error.detail
      });
      this.showdeleteAccountmanager = false
      this.loading = false;
      subscription.unsubscribe();
      this.getAccountManagers()
    })
  }

  canceldeletespecialPrices() {
    this.showspecialPrices_flabel = true
    this.showdeleteSpecialPrice = false
    this.customer.pricing.special_price = null
    this.sharedService.dialog_status.next(false)
  }

  private getPricingCategories() {
    try {
      const subscription = this.customerService.getPricingCategories().subscribe((data: PaginatedResponse<PricingCategory[]>) => {
        if (!isSet(data)) {
          return;
        }
        this.pricingCategories = PricingCategory.cloneManyObjects(data.results)
        const addPricingCategory = new PricingCategory();
        addPricingCategory.id = '0';
        addPricingCategory.name = '<span class="add-quick-option">' + this.translate.instant('NEW_PRICE_CAT') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.pricingCategories = insert(this.pricingCategories, 0, addPricingCategory)
        setTimeout(() => {
          this.initpricingCategory();
        }, 100);
        subscription.unsubscribe();
      }, error => {
        this.log(error);
        subscription.unsubscribe();
      });
    } catch (error) {
      this.log(error);
    }
  }
  private getRegions() {
    try {
      const subscription = this.customerService.getRegions().subscribe((data: PaginatedResponse<Region[]>) => {
        if (!isSet(data)) {
          return;
        }
        this.regions = Region.cloneManyObjects(data.results)
        const addRegion = new Region();
        addRegion.id = '0';
        addRegion.name = '<span class="add-quick-option">' + this.translate.instant('NEW_REGION') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.regions = insert(this.regions, 0, addRegion)
        setTimeout(() => {
          this.initRegion();
        }, 100);
        subscription.unsubscribe();
      }, error => {
        console.log(error);
        this.log(error);
        subscription.unsubscribe();
      });
    } catch (error) {
      this.log(error);
    }
  }

  private initpricingCategory(): void {
    this.cc.activateFilter = () => {
      const value = this.cc.filterValue;
      const options = this.pricingCategories.filter(specialPrice => {
        return specialPrice.name.toLowerCase().includes(value.toLowerCase());
      });
      const addPricingCategory = new PricingCategory();
      addPricingCategory.id = '-1';
      addPricingCategory.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_PRICE_CAT') + ')</span>';
      options.push(addPricingCategory);
      this.cc.optionsToDisplay = options;
    };
  }
  private initRegion(): void {
    this.regionD.activateFilter = () => {
      const value = this.regionD.filterValue;
      const options = this.regions.filter(region => {
        return region.name.toLowerCase().includes(value.toLowerCase());
      });
      const addRegion = new Region();
      addRegion.id = '-1';
      addRegion.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_REGION') + ')</span>';
      options.push(addRegion);
      this.regionD.optionsToDisplay = options;
    };
  }

  pricingCategoryChanged($event) {

    const pricingCategory = $event.value as PricingCategory;
    this.showpricingcategory_flabel = false
    if (pricingCategory?.id === '0') {
      this.pricing_Category = new PricingCategory()
      this.customer.pricing.category = new PricingCategory()
      this.showpricingcategory_flabel = true

      return this.showAddpricingCategory()

    }
    if (pricingCategory?.id === '-1') {
      this.pricing_Category = new SpecialPrice()
      this.pricing_Category.name = pricingCategory.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_PRICE_CAT') + ')</span>', '')

      this.showpricingcategory_flabel = true
      this.customer.pricing.category = new PricingCategory()
      return this.showAddpricingCategory()

    }
    if (!isSet(pricingCategory)) {
      this.showpricingcategory_flabel = true

      this.updateStatus = true
    }
  }

  regionChanged($event) {
    const region = $event.value as Region;
    if (region?.id === '0') {
      this.region = new Region()
      this.customer.region = new Region()
      this.customer.region.name = null
      this.regionHeader = this.translate.instant('ADD_REGION')
      this.showRegion_flabel = true
      return this.showAddcRegion = true
    }
    if (region?.id === '-1') {
      this.region = new Region()
      this.region.name = region.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_REGION') + ')</span>', '')
      this.showRegion_flabel = true
      this.regionHeader = this.translate.instant('ADD_REGION')
      this.customer.region = new Region()
      return this.showAddcRegion = true
    }
    if (region == null) {
      this.updateStatus = true
    }
  }
  showAddpricingCategory() {
    this.pricingCategory_validation?.control.clearValidators()
    this.pricingCategory_validation?.control.markAsUntouched()
    this.pricingCategoryheader = this.translate.instant('ADD_PRIC_CAT')
    this.showpricingCategoryDialog = true
  }

  save_pricingCategory() {
    if (!isSet(this.pricing_Category.name)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
      this.pricingCategory_validation.control.markAsTouched();

    } else {
      this.loading = true;

      const subscription = this.customerService.addPricingCategories(this.pricing_Category)
        .subscribe((pricingCategory: PricingCategory) => {
          this.loading = false;
          if (!isSet(pricingCategory)) {
            return;
          }
          subscription.unsubscribe();
          this.loading = false;
          this.showpricingCategoryDialog = false
          this.getPricingCategories()
          const x = setInterval(() => {
            this.customer.pricing.category = this.pricingCategories[1]

            if (this.customer.pricing.category.name === this.pricing_Category?.name) {
              this.showpricingcategory_flabel = false

              clearInterval(x);
            }
          }, 300)
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_PRIC_ADD_SUCCES')
          });
        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          this.showAccountmanager = false
          subscription.unsubscribe();
          this.loading = false;
        })
    }
  }
  saveRegion() {
    if (!isSet(this.region.name)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
      this.pricingCategory_validation.control.markAsTouched();
    } else {
      this.loading = true;
      const subscription = this.customerService.addPRegion(this.region)
        .subscribe((region: Region) => {
          this.loading = false;
          if (!isSet(region)) {
            return;
          }
          this.loading = false;
          this.showRegion_flabel = false
          this.showAddcRegion = false
          this.getRegions()
          const x = setInterval(() => {
            this.customer.region = this.regions[1]
            if (this.customer.region.name === this.region?.name) {
              clearInterval(x);
            }
          }, 300)
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_REGION_ADDED_SUCC')
          });
          subscription.unsubscribe();
        }, error => {
          console.log(error);
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          subscription.unsubscribe();
          this.loading = false;
        })
    }
  }

  updatepricingCategory() {
    try {
      this.loading = true;
      const subscription = this.customerService.updatePricingCategories(this.pricing_Category)
        .subscribe((pricing_Category: PricingCategory) => {
          if (!isSet(pricing_Category)) {
            return;
          }
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('UPDATED_SUCC')
          });
          this.loading = false;
          this.showpricingCategoryDialog = false
          this.getPricingCategories()
          const x = setInterval(() => {
            this.customer.pricing.category = this.pricingCategories[this.index_Toupdate]
            if (this.customer.pricing.category?.name === this.pricingCategories[this.index_Toupdate]?.name) {
              clearInterval(x);
            }
          }, 300)
          subscription.unsubscribe();
          this.customerService.upadteFormClicked.next(null)
          this.updateStatus = false
        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          this.loading = false;
          subscription.unsubscribe();
          this.customerService.upadteFormClicked.next(null)
          this.updateStatus = false
        })
    } catch (error) {
      this.loading = false;
      this.log(error);
    }
  }
  updateRegion() {
    try {
      this.loading = true;
      const subscription = this.customerService.updateRegion(this.region)
        .subscribe((region: Region) => {
          if (!isSet(region)) {
            return;
          }
          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('UPDATED_SUCC')
          });
          this.loading = false;
          this.showAddcRegion = false
          this.getRegions()
          const x = setInterval(() => {
            this.customer.region = this.regions[this.index_Toupdate]

            if (this.customer.region?.name === this.regions[this.index_Toupdate]?.name) {
              clearInterval(x);
            }
          }, 300)
          subscription.unsubscribe();
          this.customerService.upadteFormClicked.next(null)
          this.updateStatus = false
        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          console.log(error);
          this.loading = false;
          subscription.unsubscribe();
          this.customerService.upadteFormClicked.next(null)
          this.updateStatus = false
        })
    } catch (error) {
      this.loading = false;
      this.log(error);
    }

  }

  cancelshowAddpricingCategory() {
    this.showpricingCategoryDialog = false
    this.showpricingcategory_flabel = true
    this.customer.pricing.category = null
    this.sharedService.dialog_status.next(false)
  }
  cancelshowAddREgion() {
    this.showAddcRegion = false
    this.showRegion_flabel = true
    this.customer.region = null
    this.sharedService.dialog_status.next(false)
  }

  showeditpricingCategory(item: PricingCategory) {
    this.pricing_Category = PricingCategory.cloneObject(item)
    this.pricingCategoryheader = this.translate.instant('EDIT_PRICE_CAT')
    this.showpricingCategoryDialog = true
    this.index_Toupdate = this.pricingCategories.indexOf(item)
  }
  showeditRegion(item: Region) {
    this.region = Region.cloneObject(item)
    this.regionHeader = this.translate.instant('EDIT_REGION')
    this.showAddcRegion = true
    this.index_Toupdate = this.regions.indexOf(item)
  }

  showdeletepricingCategory(item: PricingCategory) {
    this.pricing_Category = item
    this.showdeletePricingCategory = true
    this.customer.pricing.category = null
  }
  showdeleteRegion(item: Region) {
    this.region = item
    this.showDeletedRegion = true
    this.customer.region = null
  }

  canceldeletepricingCategory() {
    this.showRegion_flabel = true
    this.showdeletePricingCategory = false
    this.customer.region = null
    this.sharedService.dialog_status.next(false)
  }
  canceldeleteRegion() {
    this.showpricingcategory_flabel = true
    this.showDeletedRegion = false
    this.customer.pricing.category = null
    this.sharedService.dialog_status.next(false)
  }

  deletepricingCategory() {
    this.loading = true;

    const subscription = this.customerService.deletePricingCategories(this.pricing_Category.id).subscribe(() => {

      this.messageService.add({
        key: 'list',
        severity: 'success',
        life: 2000,
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('SLEC_PRIC_CAT_DEL')
      });
      this.pricing_Category = new PricingCategory()
      this.getPricingCategories()
      this.showdeletePricingCategory = false
      this.showpricingcategory_flabel = true
      this.loading = false;
      subscription.unsubscribe();

    }, error => {
      this.messageService.add({
        key: 'list',
        severity: 'error',
        life: 3000,
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: error.error.detail
      });
      this.showdeleteAccountmanager = false
      this.loading = false;
      subscription.unsubscribe();
      this.getAccountManagers()
    })

  }
  deleteRegion() {
    this.loading = true;
    const subscription = this.customerService.deleteRegion(this.region.id).subscribe(() => {
      this.messageService.add({
        key: 'list',
        severity: 'success',
        life: 2000,
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('SELC_REIOGN_DELE')
      });
      this.region = new Region()
      this.getRegions()
      this.showDeletedRegion = false
      this.showRegion_flabel = true
      this.loading = false;
      subscription.unsubscribe();
    }, error => {
      console.log(error);
      this.messageService.add({
        key: 'list',
        severity: 'error',
        life: 3000,
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: error.error.detail
      });
      this.loading = false;
      subscription.unsubscribe();
    })
  }

  notempty(event) {
    this.sharedService.chekcempey.next(true)
    this.updateStatus = true
    this.sharedService.update_form.next(true)

  }

  Save_form() {
    this.sharedService.Saveform_Observable_state.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }
      
      this.sharedService.Save_object(this.customer)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }

  getSavedCustomer() {
    const subscription = this.sharedService.Saved_object_Emitter.subscribe((customer: any) => {

      if (!isSet(customer)) {
        return;
      }
      
      if (customer.url === this.router.url) {
        this.customer = customer.object
        this.sharedService.objectstauts.next(true)
      } else {
        this.sharedService.objectstauts.next(null)
      }
      if (isSet(this.sharedService.Createform.value)) {
        this.sharedService.objectstauts.next(true)
      } else {
        this.sharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }

  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {
      if (!isSet(status)) {
        return
      }
      this.sharedService.clearAll.next(null)
      this.initcustomer()
    }
      , error => {
        this.handleError(error);
      });
  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  listCounties() {
    const subscription = this.auth.countries().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.countries = CountryCode_currency.cloneManyObjects(countryCode_currency)
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }

  showFromDash() {
    const subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }
      if (item.itemName === 'Custmoer') {
        this.getCustomer(item.item?.id)

      }
      this.sharedService.diplayDash.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }
}
