import { isSet } from '../modules/core/util';

export class AccountManager {
  id: string;
  name: string;

  public constructor(params?: AccountManager) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: AccountManager) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new AccountManager(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: AccountManager[]) {
    const objects: AccountManager[] = [];
    for (const obj of objectsToClone) {
      objects.push(AccountManager.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
    return{
      name:this.name
    }
  }
}
