import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../../../models/product';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-dashboard',
  templateUrl: './product-dashboard.component.html',
  styleUrls: ['./product-dashboard.component.scss']
})
export class ProductDashboardComponent extends BaseComponent implements OnInit {

  public products = [
    {title: 'Product 1', date: '04/05/2020'},
    {title: 'Test Product 2', date: '04/05/2020'},
    {title: 'consectetur adipisicing elit', date: '04/05/2020'},
    {title: 'aperiam atque culpa', date: '04/05/2020'},
    {title: 'molestias odit', date: '04/05/2020'},
    {title: 'Lorem ipsum dolor', date: '04/05/2020'},
    {title: 'Product 1', date: '04/05/2020'},
    {title: 'Test Product 2', date: '04/05/2020'},
    {title: 'consectetur adipisicing elit', date: '04/05/2020'},
    {title: 'aperiam atque culpa', date: '04/05/2020'},
    {title: 'molestias odit', date: '04/05/2020'},
    {title: 'Lorem ipsum dolor', date: '04/05/2020'},
  ];
  public orders = [
    {number: '000001', date: '04/05/2020'},
    {number: '000002', date: '04/05/2020'},
    {number: '000004', date: '05/05/2020'},
    {number: '000005', date: '06/05/2020'},
    {number: '000006', date: '08/05/2020'},
    {number: '000007', date: '04/05/2020'},
    {number: '000008', date: '04/05/2020'},
    {number: '000009', date: '04/05/2020'},
    {number: '000010', date: '04/05/2020'},
  ];

  public buyers = [
    { name: 'Lea Pacocha', qty: 300 },
    { name: 'Keely Conroy', qty: 200 },
    { name: 'Lucio Powlowski', qty: 170 },
    { name: 'Yvonne Frami', qty: 170 },
    { name: 'Katelyn Mohr', qty: 130 },
    { name: 'Darion Heidenreich', qty: 123 },
    { name: 'Cleve Batz', qty: 98 },
    { name: 'Annie Gusikowski', qty: 90 },
    { name: 'Miss Mara Mraz', qty: 53 },
    { name: 'Lexi Adams', qty: 44 },
  ];

  public stockEvolution = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Depot 1',
        backgroundColor: '#81A1C1',
        borderColor: '#81A1C1',
        data: [65, 59, 80, 81, 56, 55, 40]
      },
      {
        label: 'Depot 2',
        backgroundColor: '#D08770',
        borderColor: '#D08770',
        data: [28, 48, 40, 19, 86, 27, 90]
      }
    ]
  };

  dropdownYears: any[];
  selectedYear: any;
  lineChartData: any;
  productsTimeline: any;

  lineChartOptions: any;

  pieData = {
    labels: ['A', 'B', 'C', 'D'],
    datasets: [
      {
        data: [540, 325, 702, 421],
        backgroundColor: [
          '#81A1C1',
          '#EBCB8B',
          '#88C0D0',
          '#D08770'
        ]
      }]
  };

  constructor(private productService: ProductService,public translate:TranslateService) {
    super();
  }

  ngOnInit(): void {

    this.subscribeOnProductSelected();

    this.lineChartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Sales',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#45b0d5'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Profits',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.productsTimeline = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Product 1',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#45b0d5'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Product 2',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
      hover: {
        mode: 'index'
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }]
      },
      legend: {
        display: true,
        labels: {
          fontColor: '#9199a9'
        }
      }
    };
    this.dropdownYears = [
      {label: '2020', value: 2020},
      {label: '2019', value: 2019},
      {label: '2018', value: 2018},
    ];
  }

  private subscribeOnProductSelected() {
    const subscription = this.productService.productSelectedEmitter.subscribe((product: Product) => {
      if (!isSet(product)) {
        return;
      }

      this.getProduct(product.id);
    });
    this.subscriptions.push(subscription);
  }

  private getProduct(id: string) {
    this.loading = true;
    const subscription = this.productService.getProduct(id).subscribe((product: Product) => {
      if (!isSet(product)) {
        return;
      }

      // this.productService.currentProduct = Product.cloneObject(product);
      this.loading = false;
      subscription.unsubscribe();
    }, error => {
      this.loading = false;
      this.log(error);
      subscription.unsubscribe();
    });
  }
}
