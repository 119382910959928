import { Component, HostListener, OnInit } from '@angular/core';
import {  userRolesMainContent, userRolesSubContent } from 'src/app/models/roles';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent extends BaseComponent implements OnInit {
  rolesSubScreeens:userRolesSubContent[]
  userRolesMainContent:userRolesMainContent[]
  selectedReadValues
  selectedEditValues
  selectedDeleteValues
  selectedConvertToOrderValues
  constructor(private translate:TranslateService) {
    super();
    this.userRolesMainContent=[
      {
        label: translate.instant('SELL'),
        items: [
          {id:1, label: translate.instant('CUSTOMER'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false},
          {id:2, label: translate.instant('QUOTATION'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          {id:3, label: translate.instant('ORDERS') ,roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          {id:4, label: translate.instant('DASHBORD_CREDIT_NOTES') ,roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          // {label: 'Invoices', icon: 'fa-menu fa fa-file-invoice', routerLink: ['/components/forms']},
          // {label: 'Payments', icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms']},
        ]
      },
      {
        label: translate.instant('BUY'),
        items: [
          {id:5, label: translate.instant('SUPPLIERS_LIST_SUPPLIERS'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          {id:6, label: translate.instant('PUR_ORDERS'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false},
          // {label: translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/buy/creditnotes']},
        ]
      },
      {
        label: translate.instant('DASHBORD_EXPENSES'),
        items: [
          {id:7, label: translate.instant('NEW_EXPENSE') ,roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          {id:8, label: translate.instant('EXPENSE_NOTE'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
        ]
      },
      {
        label: translate.instant('ACCOUNTING'),
        items: [
          {id:9, label:  translate.instant('SALES_JOURNAL'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false },
          {id:10, label: translate.instant('PUR_JOURNAL'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false }
        ]
      },{
        label: translate.instant('USERS'),
        items: [
          {id:11, label:  translate.instant('USERS'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false},
          {id:12, label:  translate.instant('ROLES') ,roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false}
      ]
      },
      {
        label: translate.instant('INVOEN'),
        items: [
          {id:13, label: translate.instant('PRODUCTS'),roleReadSelected:false,roleEditSelected:false,roleDeleteSelected:false,roleConvertToOrderSelected:false}
        ]
      }
      

    ];


  }

  ngOnInit(): void {
    
    
  }
  logkt(rawdata){
    this.userRolesMainContent.map((userRoles,i)=>{
      userRoles.items.map((items)=>{
        if(this.selectedReadValues){
          this.selectedReadValues.map((selected, j) => {
        
            if(selected==items){   
              items.roleReadSelected=true
      
             
              
              
            }else if(selected!=items){
              items.roleReadSelected=false
          
  
            }
         
           })
        }
   
        if(this.selectedEditValues){
          this.selectedEditValues.map((selected)=>{
            if(selected==items){   
              items.roleEditSelected=true
        
            }else if(selected!=items){
              items.roleEditSelected=false
              
            }
          })
        }
        if(this.selectedDeleteValues){
          this.selectedDeleteValues.map((selected)=>{
            if(selected==items){   
              items.roleDeleteSelected=true
              
            }else if(selected!=items){
              items.roleDeleteSelected=false
              
            }
          })
        }


        if(this.selectedConvertToOrderValues){
          this.selectedConvertToOrderValues.map((selected)=>{
            if(selected==items){   
              items.roleConvertToOrderSelected=true
              
            }else  if(selected!=items){
              items.roleConvertToOrderSelected=false
              
            }
          })
            
        }
  
       
        
      })
    })
    
 
   

  }



}
