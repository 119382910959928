import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { MainComponent } from '../main/main.component';
import { MenuService } from '../menu.service';
import { isSet } from 'src/app/modules/core/util';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { Company } from 'src/app/models/company';
import { VatInfo } from 'src/app/modules/auth/models/vatinfo';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/modules/core/api.service';
import { CompanySettings } from 'src/app/models/company-settings';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { Currency } from 'src/app/models/currency';
import { CountryCode_currency } from 'src/app/models/country_code&currency';
import { Vat_Product } from 'src/app/models/vat-product';
import { TranslateService } from '@ngx-translate/core';
import places from 'places.js'

@Component({
  selector: 'app-config-system',
  templateUrl: './config-system.component.html',
  styleUrls: ['./config-system.component.scss']
})
export class ConfigSystemComponent extends BaseComponent implements OnInit {


  vatBtnDisabled = false;
  company: Company
  selectedcompany: Company = new Company()
  deleteAttachment_diloage: boolean
  companies: Company[] = []
  updateStatus: boolean = false;
  companySettings = new CompanySettings()
  darkColors: any;
  lightColors: any;
  customColors: any;
  menuColors: any;
  visible: boolean = false
  componentThemes: any;
  placesAutocomplete: any
  loadingbtn= false
  accc:boolean=true
  menustauts: boolean = false
  showContactDialog: boolean = false
  size = "p-sidebar-md"
  modalTitle = this.translate.instant('COM_SETT')
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  };
  @ViewChild('upload') upload: ElementRef;
  @ViewChild('addressInput') addressInput: ElementRef;
  disablebtnupload: boolean


  addCurrency: string
  showAddCurrency: boolean = false
  defultCurrency: string

  currencies: Currency[] = []
  all_currencies: any[] = []

  showAddvat: boolean = false
  addVat: Number
  all_vats: any[] = []

  constructor(public app: MainComponent, private menuService: MenuService, public translate: TranslateService,
    private sharedService: SharedService, private messageService: MessageService, private api: ApiService,

  ) {
    super();

  }

  ngOnInit() {
    this.openConfigMenu()
    this.getCompany()
    this.getCurrencies()
    this.get_AllCurrencies()
    this.get_AllVats()
    this.lightColors = [
      { name: 'Blue', file: 'blue', color: '#5e81ac' },
      { name: 'Green', file: 'green', color: '#A3BE8C' },
      { name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];

    this.darkColors = [
      { name: 'Blue', file: 'blue', color: '#5e81ac' },
      { name: 'Green', file: 'green', color: '#A3BE8C' },
      { name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];

    this.customColors = [
      { name: 'Chile', file: 'chile', image: 'chile.png' },
      { name: 'Naples', file: 'naples', image: 'naples.png' },
      { name: 'Georgia', file: 'georgia', image: 'georgia.png' },
      { name: 'Infinity', file: 'infinity', image: 'infinity.png' },
      { name: 'Chicago', file: 'chicago', image: 'chicago.png' },
      { name: 'Majesty', file: 'majesty', image: 'majesty.png' },
      { name: 'Fish', file: 'fish', image: 'fish.png' },
      { name: 'Dawn', file: 'dawn', image: 'dawn.png' },
      { name: 'Record', file: 'record', image: 'record.png' },
      { name: 'Pool', file: 'pool', image: 'pool.png' },
      { name: 'Metal', file: 'metal', image: 'metal.png' },
      { name: 'China', file: 'china', image: 'china.png' },
      { name: 'Table', file: 'table', image: 'table.png' },
      { name: 'Panorama', file: 'panorama', image: 'panorama.png' },
      { name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
      { name: 'Underwater', file: 'underwater', image: 'underwater.png' },
      { name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
      { name: 'Rain', file: 'rain', image: 'rain.png' },
      { name: 'Utah', file: 'utah', image: 'utah.png' },
      { name: 'Wave', file: 'wave', image: 'wave.png' },
      { name: 'Flora', file: 'flora', image: 'flora.png' },
      { name: 'Speed', file: 'speed', image: 'speed.png' },
      { name: 'Canopy', file: 'canopy', image: 'canopy.png' },
      { name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
      { name: 'Basketball', file: 'basketball', image: 'basketball.png' },
      { name: 'Misty', file: 'misty', image: 'misty.png' },
      { name: 'Summit', file: 'summit', image: 'summit.png' },
      { name: 'Wall', file: 'wall', image: 'wall.png' },
      { name: 'Ferris', file: 'ferris', image: 'ferris.png' },
      { name: 'Ship', file: 'ship', image: 'ship.png' },
      { name: 'NY', file: 'ny', image: 'ny.png' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.png' },
      { name: 'Violet', file: 'violet', image: 'violet.png' },
      { name: 'Red', file: 'red', image: 'red.png' },
      { name: 'Blue', file: 'blue', image: 'blue.png' },
      { name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
      { name: 'Pink', file: 'pink', image: 'pink.png' },
      { name: 'Purple', file: 'purple', image: 'purple.png' },
      { name: 'Orange', file: 'orange', image: 'orange.png' },
    ];

    this.menuColors = this.lightColors;

    this.componentThemes = [
      { name: 'Blue', file: 'blue', color: '#5E81AC' },
      { name: 'Green', file: 'green', color: '#99CE6B' },
      { name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];
  }

  ngAfterViewInit () {
    this.placesAutocomplete = places({
      container: this.addressInput.nativeElement
    });
    this.placesAutocomplete.on('change', (e: any) => { this.companySettings.companyAddress = e.suggestion?.value });
  }
  addreesChange(){
    if (this.accc == true) {
      setTimeout(() => {
        this.companySettings.companyAddress=''
      }, 300);
      this.ngAfterViewInit()
    }
   return this.accc=false
  }
  ngOnDestroy() {
    this.placesAutocomplete.destroy();
  }
  changeLayout(event, mode) {
    this.app.darkMode = mode;
    if (mode === true) {
      this.app.menuColorMode = 'dark';
      this.app.menuColor = 'layout-menu-dark';
      this.app.layoutColor = this.darkColors[0].file;
      this.menuColors = this.darkColors;
      this.changeLightDarkLayout('layout-css', this.darkColors[0].file, 'layout-dark');
      this.changeLightDarkTheme('theme-css', 'theme-dark');
    } else {
      this.app.menuColorMode = 'light';
      this.app.menuColor = 'layout-menu-light';
      this.app.layoutColor = this.lightColors[0].file;
      this.menuColors = this.lightColors;
      this.changeLightDarkLayout('layout-css', this.lightColors[0].file, 'layout-light');
      this.changeLightDarkTheme('theme-css', 'theme-light');
    }

    event.preventDefault();
  }

  changeLightDarkTheme(id, value) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = value + '.css';
    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  changeLightDarkLayout(id, color, mode) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = color;
    urlTokens[urlTokens.length - 1] = mode + '.css';
    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  changeMenuColorMode(event, mode) {
    this.app.menuColorMode = mode;
    if (mode !== 'custom') {
      if (mode === 'light') {
        this.menuColors = this.lightColors;
        this.changeMenuColor(event, this.lightColors[0].file);
      } else {
        this.menuColors = this.darkColors;
        this.changeMenuColor(event, this.darkColors[0].file);
      }
    } else {
      this.menuColors = this.customColors;
      this.changeMenuColor(event, this.customColors[0].file);
    }
  }

  changeMenuColor(event, color) {
    if (this.app.menuColorMode !== 'custom') {
      this.app.menuColor = 'layout-menu-' + this.app.menuColorMode;
      if (this.app.menuColorMode === 'dark') {
        this.app.layoutColor = color;
        this.changeStyleSheetsColor('layout-css', color);
      } else {
        this.app.layoutColor = color;
        this.changeStyleSheetsColor('layout-css', color);
      }
    } else {
      this.app.layoutColor = color;
      this.app.menuColor = 'layout-menu-' + color;
    }

    event.preventDefault();
  }

  changeComponentTheme(event, color) {
    this.app.themeColor = color;
    this.changeStyleSheetsColor('theme-css', color);

    event.preventDefault();
  }

  changeStyleSheetsColor(id, value) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = value;
    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  onConfigButtonClick(event) {
    this.app.configActive = !this.app.configActive;
    event.preventDefault();
  }

  onConfigCloseClick(event) {
    this.app.configActive = false;
    event.preventDefault();
  }


  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }
  appearanceShow() {
    this.modalTitle = this.translate.instant('APPER_SETTINGS')

  }
  companySettingsShow() {
    this.modalTitle = this.translate.instant('COM_SETT')

  }
  openConfigMenu() {
    try {
      this.menuService.conifgClickeEmitter.subscribe(status => {
        if (!isSet(status) || !status) {
          return
        }
        this.visible = true
        this.modalTitle = this.translate.instant('COM_SETT')
        this.getCurrencies()
        this.get_AllVats()
        this.menuService.configClicke.next(null)

      }, error => {
      });

    } catch (error) {
    }

  }

  getCompany() {
    this.loading = true;
    const subscription = this.menuService.getComponyProfile().subscribe((company) => {
      if (!isSet(company)) {
        return
      }
      console.log(company);
      this.companySettings = company.company_settings_data
      localStorage.setItem('companySettings', JSON.stringify(company.company_settings_data))
      this.defultCurrency = company.company_settings_data.currency.name
      this.companySettings.companyAddress = company?.company_address_data?.address
      this.companySettings.companyName = company?.company_data?.name
      this.companySettings.vat = company?.company_data?.vat
      if (this.companySettings?.logo?.path) {
        this.viewLogo()
      }
      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();

    })
  }
  getCurrencies() {

    this.currencies = JSON.parse(localStorage.getItem('supported_currencies'))
    if (this.currencies?.length && this.visible == false) {
      return
    }
    const subscription = this.menuService.getCurrencies().subscribe((currencies: PaginatedResponse<Currency[]>) => {

      if (!isSet(currencies)) {
        return
      }

      this.currencies = Currency.cloneManyObjects(currencies.results)
      localStorage.setItem('supported_currencies', JSON.stringify(this.currencies))
      this.sharedService.currnceyChange.next(this.currencies)


    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  initCompanySettings() {
    this.companySettings = new CompanySettings()
  }

  updateCompanySettings() {
    this.loading = true;
    this.loadingbtn = true
    try {
      const subscription = this.menuService.updateCompany_settings(this.companySettings).subscribe((companySettings: CompanySettings) => {

        if (!isSet(companySettings)) {
          return;
        }
        this.loading = false;
        this.loadingbtn = false
        this.menuService.uploadlogo = false

        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });

        subscription.unsubscribe();


      }, error => {
        console.log(error);
        this.loadingbtn = false
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

        subscription.unsubscribe();

      })
    } catch (error) {
      this.loadingbtn = false
    }

  }


  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }

  showUpload() {
    this.upload.nativeElement.click()

  }
  uploadlogo(imageResult: ImageResult) {
    const filee = imageResult.dataURL;
    this.companySettings.viewlink = filee
    this.menuService.uploadlogo = true
    this.companySettings.logo_file = imageResult.dataURL.split(',')[1];
    this.companySettings.logoName = imageResult.file.name
    this.companySettings.logoType = imageResult.file.type
    this.updateCompanySettings()
  }


  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  notempty(event) {
    this.sharedService.chekcempey.next(true)
    this.updateStatus = true
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }
  viewLogo() {
    try {
      const subscription = this.menuService.generate_download(this.companySettings?.logo?.path).subscribe(link => {
        this.companySettings.viewlink = link.view_url
        subscription.unsubscribe()
      }, error => {
        subscription.unsubscribe()
      })
    } catch (error) {
      console.log(error);

    }
  }
  deleteLogo() {
    this.deleteAttachment_diloage = false
    this.companySettings.logo = {}
    this.companySettings.viewlink = null
    this.updateCompanySettings()
  }
  get_AllCurrencies() {
    this.all_currencies = JSON.parse(localStorage.getItem('currencies'))

    if (this.all_currencies?.length) {
      return
    }
    const subscription = this.menuService.countryCode_currency().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.all_currencies = []
      countryCode_currency.map(c => {
        this.all_currencies.push({ currency: c.currency })
      })
      let rmAduplicate = []
      this.all_currencies.forEach((item) => {
        if (rmAduplicate.findIndex(i => i.currency == item.currency) === -1) {
          rmAduplicate.push(item)
        }
      });

      this.all_currencies = rmAduplicate
      this.all_currencies.sort((a, b) => {
        return a.currency.localeCompare(b.currency)
      })
      localStorage.setItem("currencies", JSON.stringify(this.all_currencies))

      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  showAdd_Currency() {
    this.showAddCurrency = true
    this.addCurrency = null
  }
  cancelAdd_Currency() {
    this.showAddCurrency = false
    this.sharedService.dialog_status.next(false)

  }
  save_newCurrency() {

    if (!isSet(this.addCurrency)) {
      return this.messageService.add({ key: 'list', severity: 'error', life: 3500, summary: this.translate.instant('ERROR'), detail: this.translate.instant('OLZ_SEL_CUURR') });
    }

    const subscription = this.menuService.addCurrency(this.addCurrency).subscribe((currency: any) => {
      localStorage.removeItem('supported_currencies')
      this.getCurrencies()
      this.showAddCurrency = false

      this.messageService.add({ key: 'form', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('NEW_CURR_ADDED') });

      subscription.unsubscribe();

    }, error => {


      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });


      this.loading = false;
      subscription.unsubscribe();
    })
  }
  remove_Currency(currency: Currency) {

    const subscription = this.menuService.remove_Currency(currency?.id).subscribe(() => {


      this.messageService.add({ key: 'form', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_CURR_REM') });
      localStorage.removeItem('supported_currencies')
      this.getCurrencies()

      subscription.unsubscribe();

    }, error => {

      if (isSet(error)) {
        this.messageService.add({ key: 'form', severity: 'error', life: 3500, summary: this.translate.instant('ERROR'), detail: this.translate.instant('CANT_DELTE') + currency?.name + this.translate.instant('IT_USED') });
      }
      this.loading = false;
      subscription.unsubscribe();


    })
  }
  get_AllVats() {
    this.all_vats = JSON.parse(localStorage.getItem('vats_Product'))

    if (this.all_vats?.length && this.visible == false) {
      return
    }

    const subscription = this.menuService.getVats().subscribe((vats_Product: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(Vat_Product)) {
        return;
      }


      this.all_vats = Vat_Product.cloneManyObjects(vats_Product.results)


      localStorage.setItem("vats_Product", JSON.stringify(this.all_vats))

      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  showAdd_vat() {
    this.showAddvat = true
    this.addVat = null
  }
  save_newVat() {

    if (!isSet(this.addVat)) {
      return this.messageService.add({ key: 'form', severity: 'error', life: 3500, summary: this.translate.instant('ERROR'), detail: this.translate.instant('PLZ_FILL_INPUT') });
    }

    const subscription = this.menuService.addVat(this.addVat).subscribe((vat: any) => {
      localStorage.removeItem('vats_Product')
      this.get_AllVats()
      this.showAddvat = false
      this.messageService.add({ key: 'form', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('NEW_VAT_ADDED') });


      subscription.unsubscribe();

    }, error => {


      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });


      this.loading = false;
      subscription.unsubscribe();
    })
  }
  cancelAdd_vat() {
    this.showAddvat = false
    this.sharedService.dialog_status.next(false)

  }

  remove_Vat(vat: Vat_Product) {

    const subscription = this.menuService.remove_Vat(vat?.id).subscribe(() => {


      this.messageService.add({ key: 'form', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_VAT_REM') });
      localStorage.removeItem('vats_Product')
      this.get_AllVats()

      subscription.unsubscribe();

    }, error => {

      if (isSet(error)) {
        this.messageService.add({ key: 'form', severity: 'error', life: 3500, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NO_DEL_VAT') });
      }
      this.loading = false;
      subscription.unsubscribe();


    })
  }

}
