import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { Tax } from 'src/app/models/Tax';


@Injectable({
  providedIn: 'root'
})
export class TaxService {

  public currentTax: Tax;

  private TaxSelected: BehaviorSubject<Tax> = new BehaviorSubject<Tax>(null);
  public TaxSelectedEmitter: Observable<Tax> = this.TaxSelected.asObservable();
  private saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();
  private refreshTaxList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshTaxListEmitter: Observable<boolean> = this.refreshTaxList.asObservable();


  constructor(private api: ApiService) { }

  getTaxes(): Observable<PaginatedResponse<Tax[]>> {
    return this.api.get<PaginatedResponse<Tax[]>>('/tax');
  }

  getTax(id: string): Observable<Tax> {
    return this.api.get<Tax>(`/tax/${id}`);
  }

  
  


  deleteTax(id: string): Observable<Tax> {
    return this.api.delete<Tax>(`/tax/${id}`);
  }

  addTax(Tax: Tax): Observable<Tax> {
    return this.api.post<Tax>(`/tax`, Tax.toDB());
  }

   updateTax(Tax: Tax): Observable<Tax> {
    return this.api.put<Tax>(`/tax/${Tax.id}`, Tax.toDB());
  }

  selectTax(Tax: Tax) {
    this.TaxSelected.next(Tax);
  }

  saveForm() {
    this.saveFormClicked.next(true);
  }

  refreshTaxes() {
    this.refreshTaxList.next(true);
  }
}
