import { isSet } from '../modules/core/util';

export class SendEmail {
  send_to_mails?: string[];
  subject?: string;
  message?: string;
  file_upload_id?: string;

  public constructor(params?: SendEmail) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: SendEmail) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new SendEmail(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: SendEmail[]) {
    const objects: SendEmail[] = [];
    for (const obj of objectsToClone) {
      objects.push(SendEmail.cloneObject(obj));
    }

    return objects;
  }
  public toDB() {
    
  return{
    send_to_mails:this.send_to_mails,
    subject:this.subject,
    message:this.message,
    file_upload_id:this.file_upload_id
  }
  }
}
