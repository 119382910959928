<p-toast key="form" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid order-form">
  <div class="p-col-12 col-12-r">

    <p-card>
      <div class="p-grid">
        <div class="p-col-8">
          <div class="form-group p-pr-6">
            <p-inplace #customer>
              <span id="customer" pInplaceDisplay>


                <button *ngIf="quote.customer" pButton icon="far fa-edit" iconPos="right"
                  [label]="quote.customer.company_name " type="button"
                  class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace customerinplace-icon"></button>


                <button *ngIf="!quote.customer"
                  [ngClass]="{'alertBtn-Customer': quote.customer==null && customerOrder==true}" pButton
                  id="selectCustomer" pButton label='{{"QUOTES_LIST_SELECT_CUSTOMER" | translate}}'
                  icon="fas fa-id-card-alt tags" type="button" class=" p-button-text  customerinplace"></button>

                <div *ngIf="quote.customer==null && customerOrder==true" class="alert-Customer">
                  {{"CUSTOMER_FORM_REQUIRED" | translate}}</div>

              </span>
              <app-customers-select #customerSelect pInplaceContent (customerChange)="customerChange($event)"
                [(customer)]="quote.customer" (onHide)="customer.deactivate()"></app-customers-select>
            </p-inplace>
          </div>




          <div style="font-weight: 600;" *ngIf="isSet(quote.customer)" class="p-grid  text-muted">
            <div class="p-col-6 ">
              <span *ngIf="quote.customer?.vat || quote.customer?.contact[0]?.email " class="bold">
                Main
                <br></span>
              <span *ngIf="quote.customer?.vat ">{{"CUSTOMER_LIST_VAT" | translate}}: </span>
              <span> {{ quote.customer?.vat }}</span><br>
              <span *ngIf="quote.customer?.contact && quote.customer?.contact[0]?.email">{{"CUSTOMER_FORM_EMAIL" | translate}}: </span>
              <span *ngIf="quote.customer?.contact && quote.customer?.contact[0]?.email"> {{
                quote.customer?.contact[0]?.email }} <br> </span>
              <span *ngIf="quote.customer?.contact && quote.customer?.contact[0]?.phone">{{"CUSTOMER_FORM_PHONE" | translate}}: </span>
              <span *ngIf="quote.customer?.contact && quote.customer?.contact[0]?.phone">
                {{quote.customer?.contact[0]?.phone}}
                <br></span>
              <span *ngIf="quote.customer?.invoicing_address?.address" class="bold">
                {{"CUSTOMER_FORM_INV_ADDRESS" | translate}}
                <br></span>
              <span *ngIf="quote.customer?.invoicing_address?.address">
                {{quote.customer?.invoicing_address?.address}}<br></span>
              <span *ngIf="quote.customer?.invoicing_address?.city"> {{quote.customer?.invoicing_address?.city }}
                <br></span>
              <span *ngIf="quote.customer?.invoicing_address?.country_code  ">
                {{quote.customer?.invoicing_address?.country_code}}<br></span>


            </div>
            <div *ngIf="quote.customer?.shipping_address?.length" class="p-col-6 border-left">
              <span class="bold">{{"ORDERS_FORM_SHIPPING_ADR" | translate}} <br> </span>
              <span> {{quote.customer?.shipping_address[0]?.address}}<br> </span>
              <span> {{ quote.customer?.shipping_address[0]?.city}} <br></span>
              <span> {{quote.customer?.shipping_address[0]?.country_code}}</span>
            </div>
          </div>

        </div>

        <div class="p-col-4">
          <div class="form-group order-header-numbers">

            <div class="p-grid">
              <div class="p-col-5 text-align-right">
                <label class="formattext p-pr-2"> {{"QUOTE_NO" | translate}}: </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7 p-mt-1">
                <p-inplace [disabled]="selected_Quote" #quoteformat>
                  <span pInplaceDisplay>
                    <button *ngIf=" quote.format "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton
                      [label]="quote.format" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                    <button *ngIf=" quote.format == '' && format.touched  " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>

                  </span>
                  <input #format="ngModel" [(ngModel)]="quote.format" type="text" pInputText pInplaceContent
                    (blur)="quoteformat.deactivate()">

                </p-inplace>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col-5 text-align-right">
                <label class="formattext p-pr-2"> {{"QUOTES_LIST_QUOTE_DATE" | translate}} :</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7 p-mt-1">
                <p-inplace #qouteDate>
                  <span pInplaceDisplay>

                    <button *ngIf=" quote.date  "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton (click)="focusThenShow('date')"
                      [label]="quote.date | date:'dd-MM-yyyy' " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                    <button *ngIf=" quote.date == null && date.touched " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
                  </span>
                  <p-calendar dateFormat="dd-mm-yy" yearNavigator=true yearRange='2018:2024' #quoteDateToggle
                    (ngModelChange)="notempty($event)" showIcon="true" [(ngModel)]="quote.date" pInplaceContent
                    #date="ngModel" [required]="true" (onClose)="qouteDate.deactivate()"
                    (onClickOutside)="qouteDate.deactivate()" (onSelect)="qouteDate.deactivate()"></p-calendar>
                </p-inplace>
              </div>


            </div>

            <div class="p-grid">
              <div class="p-col-5 text-align-right">
                <label class="formattext p-pr-2"> {{"QUOTES_LIST_EXPIRY_DATE" | translate}} :</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7 p-mt-1">
                <p-inplace #expiry_Date>
                  <span pInplaceDisplay>

                    <button *ngIf=" quote.expiry_date"
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton (click)="focusThenShow('dueDate')"
                      [label]="quote.expiry_date | date:'dd-MM-yyyy'" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                    <button *ngIf=" quote.expiry_date == null && expiry_date.touched " style="  font-weight: 600;  "
                      pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('dueDate')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>



                  </span>
                  <p-calendar dateFormat="dd-mm-yy" yearNavigator=true yearRange='2018:2024' #quoteDueDateToggle
                    (ngModelChange)="notempty($event)" showIcon="true" [(ngModel)]="quote.expiry_date" pInplaceContent
                    #expiry_date="ngModel" [required]="true" (onClose)="expiry_Date.deactivate()"
                    (onClickOutside)="expiry_Date.deactivate()" (onSelect)="expiry_Date.deactivate()"></p-calendar>
                </p-inplace>
              </div>


            </div>
            <div *ngIf="quote.exchange_rateStauts == true" class="p-grid ">
              <div class="p-col-5  text-align-right">
                <label class="formattext p-pr-2"> {{"EXCHANGE_RATE" | translate}} :</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7 p-mt-1">
                <p-inplace [ngStyle]="{cursor: 'pointer'}" #exchange_rate>
                  <span pInplaceDisplay>
                    <button *ngIf=" quote?.exchange_rate "
                      style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                      [label]="quote?.currency?.name  " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                      1 {{default_currency.name}}<i style="margin-left: 1px; margin-right: 1px;"
                        class="fas fa-arrow-right"></i> <label style="margin-right: 2px;"> {{ quote?.exchange_rate |
                        number:'1.2-2'}} </label>

                    </button>


                  </span>



                  <p-inputNumber [min]="0" [min]="0" (onFocus)="$event.target.select()"
                    (keyup.enter)="onKeyUp($event);exchangeRateChange()" (ngModelChange)="notempty($event)"
                    [(ngModel)]=" quote.exchange_rate" mode="decimal" [minFractionDigits]="2"
                    (onBlur)="exchange_rate.deactivate()" pInplaceContent></p-inputNumber>
                </p-inplace>

              </div>

              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-5  text-align-right">
                    <label class="formattext p-pr-2"> {{default_currency?.name}} Summary :</label>
                  </div>
                  <div class="p-col-6 p-mt-2  text-center">
                    <div class="p-field-checkbox">
                      <p-checkbox [(ngModel)]="defaultcurrency_status" binary="true"></p-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </p-card>

    <p-card>
      <p-table [value]="quote.items" styleClass="p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th [ngClass]="{'expand-item': quote?.items?.length > 1}" style="width: 23%"> {{"QUOTES_Form_ITEM" |
              translate}}</th>
            <th style="width: 12%"> {{"DASHBORD_DATE" | translate}}</th>
            <th style="width: 10%"> {{"QUOTES_Form_QTY" | translate}}</th>
            <th style="width: 13%"> {{"QUOTES_Form_PRICE" | translate}} </th>
            <th style="width: 9%"> {{"CUSTOMER_LIST_VAT" | translate}}%</th>
            <th style="width: 13%"> {{"VAT_AMOUNT" | translate}} </th>
            <th style="width: 16%"> {{"QUOTES_LIST_TOTAL" | translate}} </th>
            <th *ngIf="quote?.items?.length > 1 " style="width: 3.5%"></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>

            <td #productelemt id="item">
              <app-products-select (keyup.enter)="onKeyUp($event)" [width]="productSize+'px'"
                *ngIf="((isSet(item.product?.id) && item.product?.id !='') || isSet(item?.name)) && item.checkinlne==false ; else checkinlineProduct"
                (service)="serviceItem($event,i)" (inlineProduct)="inlineprodcut($event,i)" [(product)]="item.product"
                (onChange)="productChanged($event, i)">
              </app-products-select>
              <div *ngIf=" item.name == null && productTouched == true " class="alert-Item"> {{"CUSTOMER_FORM_REQUIRED"
                |
                translate}} </div>

              <ng-template #checkinlineProduct>
                <input *ngIf="isSet(item?.name)  ; else inline" #service="ngModel" (ngModelChange)="notempty($event)"
                  [(ngModel)]="item.name" type="text" pInputText>
                <ng-template #inline>
                  <div #inlineProductt class="inlineProduct">
                    <p-editor id="inlineProduct" name="inline" readonly=true [(ngModel)]="item.service" ngDefaultControl
                      [ngClass]="{'showless': item.inline_Epand==false || item.inline_Epand==null}"> </p-editor>
                    <div class="inlineProduct-btnSetings">
                      <button *ngIf="(inlineProductt?.offsetHeight > 138) || ( inlineProductt?.offsetHeight > 100)"
                        type="button" pButton class="p-button-text  p-button-secondary"
                        (click)="expand_inLineProduct(i)" [pTooltip]="item.inline_Epand==true  ?'Compress':'Expand'"
                        tooltipPosition="bottom"
                        [icon]="item.inline_Epand==true  ? 'fas fa-compress-alt':'fas fa-expand-alt'"></button>

                      <button type="button" pButton class="p-button-text p-ml-1" (click)="showEidtinlineProduct(i)"
                        pTooltip='{{"CUSTOMER_LIST_EDIT" | translate}}' tooltipPosition="bottom"
                        icon="fas fa-edit"></button>

                    </div>
                  </div>
                </ng-template>

              </ng-template>
              <!-- [width]="productelemt.offsetWidth-30+'px'"  -->
            </td>
            <td>
              <p-calendar yearNavigator=true yearRange='2018:2024' dateFormat="dd-mm-yy" appendTo="body"
                [(ngModel)]="item.datePipe" showIcon="true" name="item.datePipe">
              </p-calendar>
            </td>
            <td>
              <div class="clearable-input">
                <input #item.qty name="item.qty" [(ngModel)]="item.qty" (ngModelChange)="notempty($event)"
                  (change)="onChange_Item(i,'qty')" (keypress)="numberOnly($event)" (focus)="$event.target.select()"
                  type="text" pInputText>

                <i *ngIf="isSet(item.qty)&& item.qty !== 1  && item.qty !== '' " (click)="erace_value(i,'qty')"
                  class="pi pi-times"></i>
              </div>
              <!-- (focus)="$event.target.select()" -->
            </td>
            <td>
              <!-- <div class="clearable-input">
                <input #item.price name="item.price" (ngModelChange)="notempty($event)"
                  (change)="onChange_Item(i,'price')" (focus)="$event.target.select()" [(ngModel)]="item.price"
                  (click)="erace_Default(i)" type="text" pInputText>
                <i *ngIf="isSet(item.price)&& item.price !== 0 && item.price !== ''" (click)="erace_value(i,'price')"
                  class="pi pi-times"></i>
              </div> -->
              <div class="clearable-input">
                <p-inputNumber *ngIf="default_currency?.id == quote?.currency?.id; else qprice" [min]="0" #item.price
                  (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.price"
                  mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                <ng-template #qprice>
                  <!-- *ngIf="item?.product?.id?.length > 4 else qqprice" -->
                  <p-inputNumber  #item.price [min]="0"
                    mode="currency" currency="{{default_currency?.name}}" locale="de-DE" currencyDisplay="code"
                    (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)" [(ngModel)]="item.price"
                    [minFractionDigits]="2"></p-inputNumber>
                  <!-- <ng-template #qqprice>
                    <p-inputNumber #item.price [min]="0" mode="currency" currency="{{ quote?.currency?.name}}"
                      locale="de-DE" currencyDisplay="code" (onFocus)="$event.target.select()"
                      (ngModelChange)="notempty($event)" [(ngModel)]="item.price" [minFractionDigits]="2">
                    </p-inputNumber>

                  </ng-template> -->
                </ng-template>

                <i *ngIf="(isSet(item.price)&& item.price !== 0 && item.price !== '') " (click)="erace_value(i,'price')"
                  class="pi pi-times"></i>
              </div>
            </td>
            <td>
              <!-- <input  name="item.vat" [(ngModel)]="item.vat" type="text" pInputText> -->

              <p-dropdown (ngModelChange)="notempty($event)" (keyup.enter)="onKeyUp($event)"
                (onChange)="onChange_Item(i,'vat')" appendTo="body" [options]="vat_Percentage" [(ngModel)]="item.vat"
                optionLabel="value">
              </p-dropdown>
            </td>
            <td>

              <label style="color: var(--primary-color);font-weight: 800; "
                *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                {{item?.default_currncey_vatAmount | number:'1.2-2'}} <span
                  style="font-size: small;font-weight: 600 !important;"> {{
                  default_currency?.name }} </span> <label class="divide">/</label>
                {{item?.vatAmount | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                  quote?.currency?.name }} </span></label>
              <ng-template #currency>
                <span style=" color: var(--primary-color) !important; font-weight: 800;">
                  {{ item.vatAmount | number:'1.2-2'}} </span>

              </ng-template>

            </td>
            <td>
              <div style="font-weight: 800;" class="number-total"
                *ngIf="quote.exchange_rate >1 && defaultcurrency_status ;else currency2">
                {{item?.default_currncey_item_total | number:'1.2-2'}} <span
                  style="font-size: small;font-weight: 600 !important;"> {{ default_currency?.name }} </span> <label
                  class="divide">/</label>
                {{item?.item_total | number:'1.2-2'}} <span style="font-size: small;font-weight: 600 !important;"> {{
                  quote?.currency?.name }} </span>

              </div>
              <ng-template #currency2>
                <span style=" color: var(--primary-color) !important; font-weight: 800;">

                  {{ item?.item_total | number:'1.2-2'}}
                </span>

              </ng-template>

            </td>
            <td *ngIf="quote.items.length > 1" style="width: 3.5%" class="text-align-right">
              <button (click)="remomveItem(i)" pButton pRipple type="button" icon="fas fa-trash"
                class="p-button-text p-button-danger p-button-delete"></button>
              <!-- <i (click)="quote.items.splice(i, 1)" class="fas fa-times pointer-cursor "></i> -->

            </td>
          </tr>
        </ng-template>
      </p-table>
      <button (click)="addItem()" pButton type="button" label='{{"CUSTOMER_FORM_ADD" | translate}} '
        class="ui-button-raised p-mt-2"></button>
      <label class="p-ml-3"> {{"QUOTES_Form_OR_PREES" | translate}} <code>ctrl+enter</code></label>
    </p-card>

    <div class="p-grid">
      <div
        [ngClass]="quote?.discount>0 && default_currency?.id != quote?.currency?.id &&  defaultcurrency_status && show_afterdiscont?'p-col-7' : 'p-col-8'">
        <p-card>
          <div [style.height.px]="cardHeight" class="p-grid ">

            <div class="p-col-6">
              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 180px;" (ngModelChange)="notempty($event)" autoResize="false"
                    [(ngModel)]="quote.notes" id="message-input" rows="8" pInputTextarea></textarea>
                  <label for="message-input"> {{"QUOTES_Form_MESSAGE" | translate}}</label>
                </span>
              </div>
            </div>
            <div class="p-col-6">
              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 180px;" autoResize="false" (ngModelChange)="notempty($event)"
                    [(ngModel)]="quote.payment_terms" id="payment-terms-input" rows="8" pInputTextarea></textarea>
                  <label for="payment-terms-input"> {{"CUSTOMER_FORM_PAY_TERMS" | translate}}</label>
                </span>
              </div>
            </div>
          </div>

        </p-card>
      </div>
      <div
        [ngClass]="quote?.discount>0 && default_currency?.id != quote?.currency?.id &&  defaultcurrency_status && show_afterdiscont?'p-col-5' : 'p-col-4'">

        <p-card>
          <div #total_Height class=" order-summary text-muted">

            <div style="background-color: #f8f9fa; position: relative;" class="p-grid">
              <div [style.padding-top]="quote?.discount>0 ?null: 15+'px'" class="p-col-6">
                {{"DISCOUNT_EXCL_VAT" | translate}} :
              </div>
              <div class="p-col-6 text-align-right">
                <p-inplace [disabled]="quote.beforeDiscont_quoteTotalIncVAT==0 " #discount>

                  <span pInplaceDisplay>
                    <span *ngIf="quote?.discount>0 && selected_discount_type?.type == 'Amount'"
                      style="font-weight: 800;" class="  number-total"> {{quote?.discount |number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        default_currency?.name }} </span> <i class="far fa-edit"></i>
                      <!-- <i [class]="quote.currency"></i> -->
                    </span>

                    <span *ngIf="quote?.discount>0 && selected_discount_type?.type == 'Percent'"
                      style="font-weight: 800; " class="  number-total "> {{quote?.discount |number:'1.2-2'}} % <i
                        class="far fa-edit"></i></span>

                    <p-selectButton [disabled]="quote.beforeDiscont_quoteTotalIncVAT==0" *ngIf="quote?.discount==0"
                      [options]="discount_type" [(ngModel)]="selected_discount_type" optionLabel="type">
                    </p-selectButton>

                  </span>
                  <div class="p-inputgroup" pInplaceContent>
                    <!-- prefix=" {{quote.currencySign}}" -->
                    <p-inputNumber [min]="0" [min]="0" *ngIf="selected_discount_type?.type == 'Amount'"
                      [(ngModel)]="quote.discount" (onFocus)="$event.target.select()"
                      (ngModelChange)="total_discountchange()" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                    <p-inputNumber [min]="0" [min]="0" *ngIf="selected_discount_type?.type == 'Percent'" prefix="%"
                      (ngModelChange)="total_discountchange()" [(ngModel)]="quote.discount" max="100"></p-inputNumber>

                    <button type="button"
                      [disabled]="(this.quote?.discount > this.quote.beforeDiscont_quoteTotalIncVAT && quote.discount_type == 'Amount') "
                      (click)="discount.deactivate()" pButton pRipple icon="pi pi-check"></button>

                    <button type="button" (click)="discount.deactivate();cleardiscont()" pButton pRipple
                      icon="pi pi-times" class="p-button-danger"></button>

                  </div>
                </p-inplace>
              </div>
              <div *ngIf="quote?.discount>0" class="p-col-12">

                <div class="p-field-checkbox">
                  <p-checkbox [(ngModel)]="show_afterdiscont" binary="true" inputId="binary"></p-checkbox>
                  <label for="binary"> {{"SHOW_BF_DS" | translate}}</label>
                </div>
              </div>

            </div>

            <div class="p-grid p-mt-3">
              <div class="p-col-4"> {{"QUOTES_Form_TOTAL_NET" |
                translate}}
                <!-- <label
                  *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}}/
                  {{quote?.currency?.name}})</label> -->
                :
              </div>
              <div class="p-col-8 text-align-right number-total">
                <span *ngIf="quote?.discount==0">
                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                    {{quote?.default_currncey_quoteTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name }} </span> <label class="divide">/</label>
                    {{quote?.quoteTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name }} </span></label>
                  <ng-template #currency> {{quote?.quoteTotal | number:'1.2-2'}}
                  </ng-template>
                </span>

                <span *ngIf="quote?.discount>0">


                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotal != quote?.quoteTotal"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{quote?.default_currncey_beforeDiscont_quoteTotal | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        default_currency?.name }} </span></del>
                    {{quote?.default_currncey_quoteTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name }} </span> <label class="divide">/</label>
                    {{quote?.quoteTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name }} </span>
                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotal != quote?.quoteTotal"
                      style="font-weight: 600;" class="p-text-secondary"> {{quote?.beforeDiscont_quoteTotal |
                      number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        quote?.currency?.name }} </span></del>




                  </label>
                  <ng-template #currency>

                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotal != quote?.quoteTotal"
                      style="font-weight: 600;" class="p-text-secondary"> {{quote?.beforeDiscont_quoteTotal |
                      number:'1.2-2'}} </del>
                    {{quote?.quoteTotal | number:'1.2-2'}}
                  </ng-template>


                </span>

              </div>

            </div>
            <div class="p-grid ">
              <div class="p-col-4"> {{"QUOTES_Form_TOTAL_VAT" |
                translate}}
                <!-- <label
                  *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}} /
                  {{quote?.currency?.name}})</label> -->

                :
              </div>
              <div class="p-col-8 text-align-right number-total">
                <span *ngIf="quote?.discount==0">
                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                    {{quote?.default_currncey_quoteTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name }} </span> <label class="divide">/</label>
                    {{quote?.quoteTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name }} </span></label>
                  <ng-template #currency> {{quote?.quoteTotalVAT | number:'1.2-2'}}
                  </ng-template>
                </span>

                <span *ngIf="quote?.discount>0">


                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">

                    <del
                      *ngIf="show_afterdiscont && quote?.default_currncey_beforediscontquoteTotalVAT != quote?.default_currncey_quoteTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{quote?.default_currncey_beforediscontquoteTotalVAT | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        default_currency?.name }} </span></del>
                    {{quote?.default_currncey_quoteTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name}} </span><label class="divide">/</label>

                    {{quote?.quoteTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name }} </span>
                    <del *ngIf="show_afterdiscont && quote?.beforediscontquoteTotalVAT != quote?.quoteTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{quote?.beforediscontquoteTotalVAT |
                      number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        quote?.currency?.name }} </span></del>


                  </label>
                  <ng-template #currency>

                    <del *ngIf="show_afterdiscont && quote?.beforediscontquoteTotalVAT != quote?.quoteTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{quote?.beforediscontquoteTotalVAT |
                      number:'1.2-2'}} </del>
                    {{quote?.quoteTotalVAT | number:'1.2-2'}}
                  </ng-template>


                </span>

              </div>

            </div>

            <ng-container *ngIf="quote?.paid_vats?.length>1">
              <div *ngFor="let item of quote.paid_vats">
                <div *ngIf="item.afterdiscont >0" style="background-color: #f8f9fa;" class=" p-grid">
                  <div class="p-col-4">VAT {{item.name}} %
                    <!-- <label
                      *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}} /
                      {{quote?.currency?.name}})</label>  -->
                    :

                  </div>
                  <div class="p-col-8 text-align-right number-total">
                    <span *ngIf="quote?.discount==0">

                      <label
                        *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                        {{item?.beforediscont
                        |
                        number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                          default_currency?.name }} </span> <label class="divide">/</label> {{item?.beforediscont *
                        quote?.exchange_rate |
                        number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                          quote?.currency?.name }} </span></label>
                      <ng-template #currency> {{item?.beforediscont | number:'1.2-2'}}
                      </ng-template>
                    </span>

                    <span *ngIf="quote?.discount>0">

                      <label
                        *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">

                        <del *ngIf="show_afterdiscont " style="font-weight: 600;" class="p-text-secondary">
                          {{item?.beforediscont / quote?.exchange_rate | number:'1.2-2'}} <span
                            style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                            default_currency?.name }} </span>
                        </del>
                        {{item?.afterdiscont / quote?.exchange_rate|number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                          default_currency?.name }} </span> <label class="divide">/</label>

                        {{item?.afterdiscont |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                          quote?.currency?.name }} </span>
                        <del *ngIf="show_afterdiscont " style="font-weight: 600;" class="p-text-secondary">
                          {{item?.beforediscont | number:'1.2-2'}} <span
                            style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                            quote?.currency?.name }} </span>
                        </del>
                      </label>
                      <ng-template #currency>
                        <del *ngIf="show_afterdiscont " style="font-weight: 600;" class="p-text-secondary">
                          {{item?.beforediscont| number:'1.2-2'}}
                        </del>
                        {{item?.afterdiscont |number:'1.2-2'}}
                      </ng-template>




                    </span>


                  </div>
                </div>
              </div>
            </ng-container>

            <div [ngClass]="{'p-p-0': quote?.status?.cc == false}" class="p-grid">
              <div class="p-col-4"> {{"QUOTES_Form_TOTAL_INC_VAT" |
                translate}}
                <!-- <label
                  *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}}/
                  {{quote?.currency?.name}})</label> -->
                :
              </div>
              <div class="p-col-8 text-align-right number-total">


                <span *ngIf="quote?.discount==0">
                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                    {{quote?.default_currncey_quoteTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name }} </span> <label class="divide">/</label>
                    {{quote?.quoteTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name }} </span></label>
                  <ng-template #currency> {{quote?.quoteTotalIncVAT | number:'1.2-2'}}
                  </ng-template>
                </span>





                <span *ngIf="quote?.discount>0">


                  <label *ngIf="default_currency?.id != quote?.currency?.id &&  defaultcurrency_status;else currency">
                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotalIncVAT != quote?.quoteTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{quote?.default_currncey_beforeDiscont_TotalInc | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        default_currency?.name }}</span></del>
                    {{quote?.default_currncey_quoteTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      default_currency?.name }} </span> <label class="divide">/</label>
                    {{quote?.quoteTotalIncVAT |number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                      quote?.currency?.name}}</span>
                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotalIncVAT != quote?.quoteTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{
                      quote?.beforeDiscont_quoteTotalIncVAT |
                      number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                        quote?.currency?.name}}</span>
                    </del>



                  </label>
                  <ng-template #currency>

                    <del *ngIf="show_afterdiscont && quote?.beforeDiscont_quoteTotalIncVAT != quote?.quoteTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{
                      quote?.beforeDiscont_quoteTotalIncVAT |
                      number:'1.2-2'}}
                    </del>
                    {{quote?.quoteTotalIncVAT |number:'1.2-2'}}
                  </ng-template>

                </span>


              </div>

            </div>



            <hr  class="divider">
            <div  style="background-color: #f8f9fa; "
              class="p-grid p-mt-3 ">
              <div class="p-col-8"> {{"QUOTES_Form_CUSTOMER_BALANCE"
                | translate}} :</div>
              <div class="p-col-4 text-align-right number-total">
                <label class="" *ngIf="quote?.customer?.id != null ; else balance"> {{quote?.customer?.due - quote?.default_currncey_quoteTotalIncVAT |number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{
                    default_currency?.name }} </span></label>


                <ng-template #balance>
                  <label>0</label>
                </ng-template>
              </div>
            </div>

          </div>
        </p-card>

      </div>




    </div>

  </div>
</div>
<!-- position="top" -->


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" class="p-shadow-1" header="Send by Email" [(visible)]="sendEmaildialog"
  [style]="{width: '65vw'}">
  <div class="p-field p-grid">
    <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 "> {{"QUOTES_Form_RECIPIENTS" |
      translate}}</label>

    <div class="p-col">


      <p-chips (onRemove)="onRemovemail()" addOnBlur='true' (keydown)="addEmail_onSpace($event)" id="Recipients"
        name="send_to_mails" [(ngModel)]="sendEmail.send_to_mails">
        <ng-template let-item pTemplate="item">
          <span> {{item}}</span>
        </ng-template>

      </p-chips>

      <div class="p-mt-2">
        <p-checkbox [binary]="user_email" [(ngModel)]="user_email" (onChange)="send_copyto_myself()"
          label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>

      </div>
    </div>

  </div>

  <div class="p-field p-grid">
    <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0"> {{"QUOTES_Form_SUBJECT" | translate}}</label>
    <div class="p-col">
      <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>

    </div>
  </div>

  <div class="p-field p-grid">
    <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0"> {{"MESSAGE" | translate}} </label>
    <div class="p-col">
      <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
        [style]="{'height':'180px'}"></p-editor>
      <div class="mt-2">
        <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="Save_Message()"></p-checkbox>
      </div>

    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-send" (click)="send_Email()" label='{{"QUOTES_Form_SEND" | translate}}'
      icon="far fa-paper-plane"></button>

    <button type="button" pButton icon="pi pi-times" (click)="sendEmaildialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>



  </p-footer>
</p-dialog>

<p-dialog modal="true" [resizable]="false" [draggable]="false" appendTo="body" class="p-shadow-1"
  header="Convert to order" [(visible)]="ConvertialogueeQ" [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"QUOTES_LIST_CONFIRM" | translate}} <label style="color: #51749e;"> {{
      this.quote.format
      }}</label> {{"QUOTES_LIST_TO_ORDER" | translate}}  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="ConvertToOrder()" label=' Convert'></button>
    <button type="button" pButton icon="pi pi-times" (click)="ConvertialogueeQ=false" label='Cancel'
      class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body"
  [resizable]="false" [draggable]="false" class="p-shadow-1" [header]="quote.format" [(visible)]="pdfViewer"
  [style]="{width: '60vw'}">
  <div style=" position: relative;" [style.height.px]="pdfheight">
    <pdf-viewer (after-load-complete)="afterLoadComplete($event)" (on-progress)="pdfLoader($event)"
      [src]="quote?.view_url" [render-text]="true" [show-all]="true" [stick-to-page]=" true" [zoom-scale]="'page-width'"
      [zoom]="0.9" style="display: block"></pdf-viewer>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="fas fa-print" (click)="printPdf()"
      label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>

    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()"
      label='{{"DOWNLOAD" | translate}}'></button>
       <button type="button" pButton icon="pi pi-envelope" (click)="sendEmaildialog=true"  label=' {{"Send To Email" | translate}} '></button> 

    <button type="button" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"
             (click)="sendToWhatsapp()"
            label=' {{"Send to Whatsapp" | translate}}' ></button>
    <button type="button" pButton icon="pi pi-times" (click)="pdfViewer=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" class="p-shadow-1" header='{{"EDIT_SERVICE" | translate}}'
  [(visible)]="showaddinlineProduct" [style]="{width: '60vw'}">

  <p-editor id="Message" name="inline" [(ngModel)]="inlineProductValue" ngDefaultControl [style]="{'height':'200px'}">
  </p-editor>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="edit_inlineProduct()"
      label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" (click)="showaddinlineProduct=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>


  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false);cancelAdd_exchangeRate()"
  class="p-shadow-1" appendTo="body" [resizable]="false" [draggable]="false" header='{{"EXCHANGE_RATE" | translate}}'
  [(visible)]="showAdd_exchange_rate" [style]="{width: '15vw'}">


  <p-inputNumber [min]="0" [min]="0" [inputStyle]="{'width':13+'vw'}" (onFocus)="$event.target.select()"
    [(ngModel)]="add_exchange_rate" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="confrimAdd_exchangeRate();exchangeRateChange()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="cancelAdd_exchangeRate()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>
