import { isSet } from '../modules/core/util';

export class Address {
  address?: string;
  zip?: string;
  city?: string;
  country_code?: string;

  public constructor(params?: Address) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Address) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Address(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Address[]) {
    const objects: Address[] = [];
    for (const obj of objectsToClone) {
      objects.push(Address.cloneObject(obj));
    }

    return objects;
  }
}
