import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotesListComponent } from './quotes-list/quotes-list.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import {ChipsModule} from 'primeng/chips';
import {CheckboxModule} from 'primeng/checkbox';
import {FileUploadModule} from 'primeng/fileupload';
import {EditorModule} from 'primeng/editor';
import {TooltipModule} from 'primeng/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { TieredMenuModule } from 'primeng/tieredmenu';

export const routes: Routes = [
  {path: '', component: QuotesListComponent}
];

@NgModule({
  declarations: [QuotesListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    PdfViewerModule,
    SharedModule,
    TableModule,TieredMenuModule,
    CardModule,
    InplaceModule,
    CalendarModule,
    FormsModule,
    ToastModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,SplitButtonModule,
    DialogModule,ChipsModule,CheckboxModule,
    FileUploadModule,EditorModule,TooltipModule,
    InputTextModule,
    TranslateModule,InputNumberModule
  ]
})
export class QuotesModule { }
