import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SendEmail } from 'src/app/models/SendEmail';
import { isSet } from '../core/util';
import { DatePipe } from '@angular/common';
import { ExpenseNote } from 'src/app/models/expense/ExpenseNote';
import { Payment } from 'src/app/models/orders/Payment';
import { CountryCode_currency } from 'src/app/models/country_code&currency';

@Injectable({
  providedIn: 'root'
})
export class ExpenseNoteService {

  public newPayments: Payment[] = []
  public attachmentlink: string;
  public queris = []
  sort :any
  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public submitStuats: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public submitStuatsEmitter: Observable<boolean> = this.submitStuats.asObservable();


  public printqoute_link: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public printqoute_linkEmitter: Observable<string> = this.printqoute_link.asObservable();

  public btnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public btnLoadingEmitter: Observable<boolean> = this.btnLoading.asObservable();

  private refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();

  public duplicateClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public duplicateClickedEmitter: Observable<boolean> = this.duplicateClicked.asObservable();


  public expenseNoteSelected: BehaviorSubject<ExpenseNote> = new BehaviorSubject<ExpenseNote>(null);
  public expenseNoteSelectedListEmitter: Observable<ExpenseNote> = this.expenseNoteSelected.asObservable();



  private sendEmailClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sendEmailClickedEmitter: Observable<boolean> = this.sendEmailClicked.asObservable();

  public retrieve_lastExpenseNote: BehaviorSubject<ExpenseNote> = new BehaviorSubject<ExpenseNote>(null);
  public retrieve_lastExpenseNoteEmitter: Observable<ExpenseNote> = this.retrieve_lastExpenseNote.asObservable();

  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();


  constructor(private api: ApiService, private datePipe: DatePipe) {
  }

  getExpenseNotes() {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<ExpenseNote[]>>(`/expenses_notes?${sum_querirs}&order=${this.sort}`);

    }

    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<ExpenseNote[]>>(`/expenses_notes?order=${this.sort}`);

    }
  }
  getExpenseNotes_NextPage(num): Observable<PaginatedResponse<ExpenseNote[]>>{
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<ExpenseNote[]>>(`/expenses_notes?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<ExpenseNote[]>>(`/expenses_notes?page=${num}&order=${this.sort}`);

    }
  }
  getExpenseNote(id: string): Observable<ExpenseNote> {
    return this.api.get<ExpenseNote>(`/expenses_notes/rud/${id}`);
  }
  getNexExpenseNumber(): Observable<{ format: string }> {
    return this.api.get<{ format: string }>('expenses_notes/next_format');
  }

  addExpenseNote(expenseNote: ExpenseNote): Observable<ExpenseNote> {
    let date = this.datePipe.transform(expenseNote.date, 'yyyy-MM-dd')
console.log(expenseNote.toDB(date));

    return this.api.post('/expenses_notes', expenseNote.toDB(date));
  }
  getNextdExpenseNoteNumber(): Observable<{ format: string }> {
    return this.api.get<{ format: string }>('/expenses_notes/next_format');
  }
  getNextdExpenseeNumber(): Observable<{ format: string }> {
    return this.api.get<{ format: string }>('/expenses/next_format');
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }

  sendEmail_buuton(state) {
    this.sendEmailClicked.next(state);
  }
  refreshDataTable() {
    this.refreshList.next(true);
  }

  deleteExpenseNote(id: string): Observable<ExpenseNote> {
    return this.api.delete<ExpenseNote>(`/expenses_notes/rud/${id}`);
  }



  // SendEmail(SendEmail: SendEmail, id: string): Observable<SendEmail> {
  //   this.PrintQoute(id).subscribe((Print: any) => {
  //     SendEmail.file_upload_id = Print.url
  //   })
  //   return this.api.post(`/quotes/send_mail/${id}`, SendEmail.toDB());
  // }


  // PrintQoute(id: string): Observable<any> {

  //   return this.api.get<any>(`/quotes/print_quote/${id}`);
  // }

  upadeExpenseNote(expenseNote: ExpenseNote) {
    let date = this.datePipe.transform(expenseNote.date, 'yyyy-MM-dd')

    let itemstoUpdate = []

    for (let x = 0; x < expenseNote.items.length; x++) {
      expenseNote.items[x].amount = Number(expenseNote.items[x].amount)
      expenseNote.items[x].exchange_rate = Number(expenseNote.items[x].exchange_rate)
      expenseNote.items[x].date = this.datePipe.transform(expenseNote.items[x].datePipe, 'yyyy-MM-dd')
      itemstoUpdate[x] = {
        id: expenseNote.items[x].id,
        date: expenseNote.items[x].date,
        amount: expenseNote.items[x].amount,
        price: expenseNote.items[x].amount,
        currency: expenseNote.items[x]?.currency?.id,
        exchange_rate: expenseNote.items[x].exchange_rate,
        attachment: expenseNote.items[x].attachment,
        payment_type: expenseNote.items[x].payment_type,
        ex_type: expenseNote.items[x].ex_type,
        ex_type_2: expenseNote.items[x]?.ex_type_2,
        description: expenseNote.items[x]?.description,
        vat_6: expenseNote.items[x]?.vat_6,
        vat_12: expenseNote.items[x]?.vat_12,
        vat_21: expenseNote.items[x]?.vat_21,
        vendor: expenseNote.items[x]?.vendor?.id,
        vendor_name: expenseNote.items[x]?.vendor_name,
        vat: expenseNote.items[x]?.vat,
        invoice_format: expenseNote.items[x]?.invoice_format,
        vcs:expenseNote.items[x]?.vcs,
        iban:expenseNote.items[x]?.iban
      }

    }
    let update = {
      date: date,
      payer: expenseNote.payer?.id,
      notes: expenseNote?.notes,
      title: expenseNote?.title,
      payments: this.newPayments,
      description: expenseNote?.description,
      ex_status: expenseNote?.ex_status,
      items: itemstoUpdate
    }

    for (const prop in update) {
      if (!isSet(update[prop])) {
        delete update[prop];

      }
    }


    return this.api.patch<any>(`/update_compile_expenses_notes/rud/${expenseNote.id}`, update)


  }
  uploadReceipt(receipt: FormData): Observable<any> {
    return this.api.post<any>(`/TextractorV3ViewSet`, receipt);
  }

  upload(filee: any) {
    const subscription = this.uploadDetilsEmitter.subscribe(uploadDetils => {
      if (!isSet(uploadDetils)) {
        return;
      }

      const credential = uploadDetils.upload_data.fields as credentialfilee


      const body = new FormData()
      this.uploadkey.next(credential.key)
      body.append("key", credential.key)
      body.append("x-amz-algorithm", credential['x-amz-algorithm'])
      body.append("x-amz-credential", credential['x-amz-credential'])
      body.append("x-amz-date", credential['x-amz-date'])
      body.append("policy", credential.policy)
      body.append("x-amz-signature", credential['x-amz-signature'])
      body.append("file", filee, filee.name)

      const sub = this.api.Uploadfile<any>(uploadDetils.upload_data.url, body).subscribe(() => {
        this.attachmentlink = credential.key

        this.uploadstuats.next(true)
        sub.unsubscribe()
      }, error => {
        this.uploadstuats.next(false)
        sub.unsubscribe()

      }
      )
      this.uploadDetils.next(null)
      subscription.unsubscribe()

    }, error => {
      subscription.unsubscribe()

    })



  }
  generate_Upload(file: any) {
    const subscription = this.api.generate_upload_url<any>(`/generate_s3_upload_url`, file).subscribe(uploadDetils => {

      if (!isSet(uploadDetils)) {
        return
      }
      subscription.unsubscribe()
      return this.uploadDetils.next(uploadDetils)


    })
  }
  generate_download(filename: any) {
    let d = {
      file_type: 'ExpenseItem',
      file_name: filename
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  delete_File(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.deleteFile(`/delete_s3_file`, d)
  }

 countryCode_currency(): Observable<CountryCode_currency[]> {
    return this.api.getGuest('/countries/list')
  }
}
export class credentialfilee {
  "key": string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "policy": string
  "x-amz-signature": string
}