import { Component, HostListener, OnInit } from '@angular/core';
import { userRoles } from 'src/app/models/roles';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { isSet } from '../../core/util';
import { Query } from 'src/app/models/query';
import { RolesService } from '../roles.service';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared/shared.service';


@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent extends BaseComponent implements OnInit {

  roles:userRoles[]=[]
  selected:userRoles[]=[]
  selectedRoles:userRoles[]=[]
  selectedSingleRole:userRoles
  filteredRoles:userRoles[]=[]
  itemSearch: string
  keyword: string = 'test'
  rolesCount: any
  showSidebar = false;
  public size: SidebarSize;
  public modalTitle = 'Add new role';
  header_body: string
  public currentModal ;

  selected_Rolesnamequery = new Query()
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  constructor(private rolesService:RolesService,private translate:TranslateService,private sharedService:SharedService ) {
    super();
    
  }

  ngOnInit(): void {
    this.roles= this.rolesService.roles

  }
  Reload(){

  }
  remove(){

  }
  showAddNewRole(){
    this.rolesService.selectRole(null)
    this.sharedService.Create_form(true)
    this.selectedSingleRole = null;
    this.rolesService.createClicke.next(true)
    this.switchModal('Form');
    this.showSidebar = true;
  }
  completeMethod(serach_item) {
  //  this.getUser_queries('username', 'startswith', serach_item)

  }
  clearRoles_queries(){

  }
  serach(e){
    if (!isSet(e)) {
      return
    }
    this.roles = e
  }
  getNextRolePage(event){

  }
  customSort(filed: string){
  //   if (this.sortMode == 1) {
  //     this.sortMode = -1
  //    // this.customersService.sort = filed
  //     return this.getUsers()
  //   } else if (this.sortMode == -1) {
  //     this.sortMode = 1
  //  //   this.customersService.sort = '-' + filed
  //     return this.getUsers()
  //   }

  }

  getRoles_queries(query_name, query_type, query_value) {

  }
  selectedRole(role){
    if (this.selectedRoles.includes(role.id) == false) {
      this.selectedRoles.push(role)
    } else {
      this.selectedRoles = this.selectedRoles.filter(item => item.id !== role)
    }
  }
  public showRole(role) {

    this.selectedSingleRole=role
     this.switchModal('Form');
     this.showSidebar = true;
  }
  switchModal(modal){
    this.size = SidebarSize.X_LARGE;
    this.currentModal = 'Form';

     this.modalTitle = this.selectedRole ? this.translate.instant('ROLE_PROF') : this.translate.instant('ROLE_FORM_ADD_ROLE');
     this.header_body = this.selectedSingleRole ? ` ${this.selectedSingleRole.name}` : null;
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    }
  }
  public saveForm() { }
  upadteFormClicked() { 
   // this.customersService.upadteFormClicked.next(true) 
  }
}
