import { isSet } from '../modules/core/util';
import { Unit } from './unit';
import { PackageType } from './packagetype';
import { Supplier } from './supplier';

export class ProductShelf {
  shelf: string;
  shelf_life: number;
  weight: number;
  height: number;
  width: number;
  length: number;
  dimension_unit: Unit;
  package_type: PackageType;
  minimum_stock: number;

  public constructor(params?: ProductShelf) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: ProductShelf) {
    if (!isSet(objectToClone)) {
      return new ProductShelf();
    }
    const obj = new ProductShelf(objectToClone);
    obj.dimension_unit = Unit.cloneObject(objectToClone.dimension_unit);
    obj.package_type = PackageType.cloneObject(objectToClone.package_type);
    return obj;
  }

  public static cloneManyObjects(objectsToClone: ProductShelf[]) {
    const objects: ProductShelf[] = [];
    for (const obj of objectsToClone) {
      objects.push(ProductShelf.cloneObject(obj));
    }

    return objects;
  }
}
