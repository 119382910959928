<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (Reload)='refreshList()' (remove)="removedialogueeQ = true"  [disableRemove]="!selected.length"
        (add)="showAddexpense()" addLabel='{{"NEW_EXPENSE" | translate}} '>
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredexpenses" [field_name]="keyword"
          (filter)="serach($event)"></app-Search-select>

      </app-table-header>
      <p-table styleClass="p-datatable-striped " [paginator]="true" [rows]="10" [value]="expense"
        [(selection)]="selected" (onPage)="getNextExpensesPage($event)" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['format','ex_status','payer.name','date','total']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="format"> {{"QUOTES_LIST_QUOTE_NO" | translate}}

                <p-columnFilter #formatFilter  class="p-ml-3" matchMode="equals" [showMatchModes]="false"
                                [showAddButton]="false" [showOperator]="false"
                                type="text" field="format" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">

                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                                placeholder='{{"SELECT_FLTER_TYPE" | translate}}' optionValue="type" optionLabel="label">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type |translate}}</span>
                      </ng-template>
                    </p-dropdown>

                    <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                                type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                                label='{{"APPLY" | translate}}'
                                (click)="getOrders_queries('format',filterModeFormat_query?.type,filterModeFormat_query?.value);formatFilter.overlayVisible=false"
                                type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </p-columnFilter>

            </th>
            <th>{{"DASHBORD_VENDOR" | translate}}
              <p-columnFilter #supNameFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="supplier.name">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                    <app-supplier-select *ngIf="filterModeApplySupplier==false" [(supplier)]="selectedSupplier"
                                          [filterMode]="true"></app-supplier-select>
                    <input *ngIf="filterModeApplySupplier==true" [disabled]="true" [(ngModel)]="selectedSupplier.name"
                           class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries();filterModeApplySupplier=false"
                              class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selectedSupplier?.id" label='{{"APPLY" | translate}}'
                              (click)="getOrders_queries('vendor',null,selectedSupplier?.id);filterModeApplySupplier=true;supNameFilter.overlayVisible=false"
                              type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th  pSortableColumn="ex_status">{{"QUOTES_LIST_STATUS" | translate}}
              <p-columnFilter type="text" matchMode="equals" field="ex_status" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false">

              <ng-template id="statusFilter" pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [options]="ex_statues"  [showClear]="true"
                  (onChange)="filter($event.value)" placeholder='{{"SELECT_STATUS" | translate}}'
                            optionLabel="label" optionValue="ex_status">
                  <ng-template let-item pTemplate="item">
                    <span >{{item.label | translate}}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            </th>
            <th  pSortableColumn="date">{{"DASHBORD_DATE" | translate}}
              <p-columnFilter #statusfilter class="p-ml-2" type="date" field="date" matchMode="equals" display="menu"
                              [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDate_query.type"
                              placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar dateFormat="dd-mm-yy" [showIcon]="true" yearNavigator=true yearRange='2018:2024'
                                [(ngModel)]="filterModeDate_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                              type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDate_query?.type||!filterModeDate_query?.value"
                              label='{{"APPLY" | translate}}'
                              (click)="getOrders_queries('date',filterModeDate_query.type,filterModeDate_query.value)"
                              type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>

            </th>

            <th pSortableColumn="total">{{"QUOTES_LIST_TOTAL" | translate}}
              <p-columnFilter #totalFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                              [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeTotal_query.type"
                              placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0"[(ngModel)]="filterModeTotal_query.value" [minFractionDigits]="2"  mode="decimal" ></p-inputNumber>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                              type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeTotal_query?.value || !filterModeTotal_query?.type"
                              label='{{"APPLY" | translate}}'
                              (click)="getOrders_queries('total',filterModeTotal_query?.type,filterModeTotal_query?.value);totalFilter.overlayVisible=false"
                              type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th>Exported

            </th>

            <th>{{"RECIPET" | translate}}</th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showexpense(rowData,i)" href="javascript:;">{{ rowData?.format }}</a></td>
            <td>
              {{ rowData?.vendor_name }}
            </td>
            <td>
              {{ rowData?.ex_status  | translate }}
            </td>
            <td> {{ rowData?.date | date:'dd-MM-yyyy' }}</td>
            <td>{{ rowData?.total |  number:'1.2-2'}}

              <span style="color: var(--primary-color);font-size: small;">{{ currnecy?.name }}</span>
            </td>
            <td>
              <div *ngIf="rowData?.exported=='invoice'">{{"DASHBORD_INVOICE" | translate}}</div>
              <div *ngIf="rowData?.exported=='note'">{{"CUSTOMER_FORM_NOTE" | translate}}</div>

            </td>

            <td> <button *ngIf="isSet(rowData?.attachment?.path ) " style="margin-right: 5px;" pButton type="button"
                icon="fas fa-eye" (click)="showViewReceipt(rowData,i)" label='{{"VIEW_RECEIPT" | translate}}'
                class="p-button-raised p-button-secondary"></button>



            </td>


          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [size]="'p-sidebar-xlg'">
  <div header>

    <button (click)="deleteseletedexpense(selectedexpense)" *ngIf="selectedexpense" pButton pRipple type="button"
      icon="fas fa-trash" [pTooltip]="'Delete ' + selectedexpense?.format" tooltipPosition="bottom"
      class="p-button-secondary" style="margin-left: 5px;"></button>


    <button *ngIf="!selectedexpense" pButton type="button" label=' {{"CUSTOMER_LIST_SAVE" | translate}} ' icon="pi pi-plus" (click)="saveForm()"
      class="ui-button-raised ui-button-info"></button>
    <!-- <button pButton  (click)="saveForm()" *ngIf="!selectedexpense" icon="pi pi-plus"  type="button"  label='Save' class="ui-button-raised ui-button-info"></button> -->

    <button style="margin-left: 22px;" *ngIf="selectedexpense" (click)="Update()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-expense-form *ngIf="showSidebar"></app-expense-form>
  </div>
</app-sidebar>

<p-dialog modal="true"  appendTo="body" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" [header]="this.selected.length == 1 ? 'Delete '+ selected[0]?.format : 'Delete Expenses'" [(visible)]="removedialogueeQ"
  [style]="{width: '30vw'}">

  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> {{"CON_DELETE_ORDERS" | translate}} ?
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.format}} ?</span>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="removeexpenses()"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialogueeQ=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true"  (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  class="p-shadow-1 " [header]="'View Receipt '+ '('+selectedexpense?.format+')'" [(visible)]="viewAttachment_diloage"
  [style]="{width: '60vw'}">

  <div *ngIf="selectedexpense?.attachment?.type=='application/pdf' ;else img_viewer" style=" position: relative;"
    [style.height.px]="pdfheight">
    <pdf-viewer (after-load-complete)="afterLoadComplete($event)" [src]="selectedexpense?.view_url" [render-text]="true"
      [show-all]="true" [stick-to-page]=" true" [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"
      (on-progress)="pdfLoader($event)"></pdf-viewer>
  </div>
  <ng-template #img_viewer>
    <div class="p-grid text-align-center">
      <div class="p-col-12"><img [src]="selectedexpense?.view_url "></div>
    </div>


  </ng-template>

  <p-footer class="p-pt-4">
    <button *ngIf="selectedexpense?.attachment?.type == 'application/pdf'" type="button" pButton icon="fas fa-print"
    (click)="printPdf()" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
    <button type="button" pButton icon="pi pi-download" (click)="downloadlink()" label=' {{"DOWNLOAD" | translate}} '></button>
    <button pButton type="button" (click)="deletefile()" icon="fas fa-trash " label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} ' class=" p-button-danger"
      for="upload-photo"></button>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="viewAttachment_diloage=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
