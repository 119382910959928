<p-toast appendTo="body" key="formMs" position="top-right"></p-toast>
<div id="selectSupplier">
  <span class="p-float-label">

    <p-dropdown #dd id="supplier-input" [style]="{'width':customstyle?width+'px':null}" [(ngModel)]="supplier"
      [appendTo]="append" name="supplier" [options]="filteredVendors" placeholder='{{"SEL_SUP"| translate}}'
      optionLabel="name" (onFocus)="onFocus()" [required]="true" #nameS="ngModel" (onChange)="supplierChanged($event)"
       (onHide)="hide($event)" filter="true">
      <!-- <ng-template >
        <div class="flex p-3">
            <div class="p-inputgroup" >
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                <input type="text" pInputText placeholder="Filter">
            </div>
        </div>
    </ng-template> -->
      <ng-template let-item pTemplate="item">
        <span [innerHTML]="item.name"></span>
      </ng-template>
    </p-dropdown>
    <label *ngIf="floatSupplier && supplierselected " for="supplier-input">
      {{"SUPPLIERS_LIST_SUPPLIERS"|translate}}</label>
  </span>
  <p-dialog modal="true" (onShow)="outside_Status(true)" [resizable]="false" [draggable]="false"
    *ngIf="displayAddsupplier" header="Add Vendor" [(visible)]="displayAddsupplier" [style]="{width: '25vw'}"
    closeIcon="false" (onHide)="closeminiSupplierModal()">

    <app-loading *ngIf="addSupplierLoading"></app-loading>

    <div class="p-grid form-group p-mt-2">
      <div class="p-col-12">
        <div class="p-inputgroup">
          <span class="p-float-label p-inputgroup">
            <input [(ngModel)]="miniSupplier.vat" (ngModelChange)="normalize_VAT($event)" (input)="getDataFromVat()"
              id="vat-input" type="text" pInputText>
            <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO"| translate}}
              <!-- <label *ngIf="miniSupplier.vat == '' || miniSupplier.vat ==null &&  miniSupplier_vat.touched  " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label> -->

            </label>

          </span>
          <button pButton (click)="getDataFromVat()" type="button" [disabled]="vatBtnDisabled"
            [icon]="vatBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
            class="ui-button-warn"></button>
        </div>
      </div>

      <div class="p-col-12 mt-4">
        <span class="p-float-label">
          <input required #supplier_name="ngModel" (ngModelChange)="notempty($event)" [(ngModel)]="miniSupplier.name"
            id="company-name-input" type="text" pInputText>
          <label for="company-name-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;

            <label
              *ngIf="((miniSupplier.name == '' || miniSupplier.name ==null) && supplier_name.touched ) || supplier_nametouched==true  "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
          </label>
        </span>
      </div>
      <div class="p-col-12 mt-4">
        <!-- <span class="p-float-label">
        <input [(ngModel)]="miniSupplierContact.email" id="email-input" type="text" pInputText>
        <label for="email-input">Email</label>
      </span> -->
        <p-button (onClick)="showContactDialog = true" icon="pi pi-plus"
          label='{{"CUSTOMER_FORM_ADD_CONTACT"| translate}}'></p-button>
        <div *ngFor="let contact of miniSupplier.contacts" class="p-grid p-mt-2 border-bottom">
          <div class="p-col-4">
            <strong>{{ contact.name }}</strong>
          </div>
          <div class="p-col-8">
            <ul>
              <li *ngIf="contact.position"><i class="tags">{{"CUSTOMER_FORM_POSITION"| translate}}:</i> {{
                contact.position }}</li>
              <li *ngIf="contact.email"><i class="tags">{{"LOGIN_EMAIL"| translate}}:</i> {{ contact.email }}</li>
              <li *ngIf="contact.phone"><i class="tags">{{"CUSTOMER_FORM_PHONE"| translate}}:</i> {{ contact.phone }}
              </li>
              <li *ngIf="contact.note"><i class="tags">{{"CUSTOMER_FORM_NOTES"| translate}}:</i> {{ contact.note }}</li>
              <li *ngIf="contact.GSM"><i class="tags">{{"CUSTOMER_FORM_GSM"| translate}}:</i> {{ contact.GSM }}</li>
              <li *ngIf="contact.fax"> <i class="tags">{{"CUSTOMER_FORM_FAX"| translate}}:</i> {{ contact.fax }}</li>

            </ul>
          </div>
        </div>
      </div>
    </div>

    <p-footer *ngIf="showContactDialog==false">

      <button type="button" pButton icon="pi pi-check" (click)="addminiSupplier()"
        label='{{"CUSTOMER_LIST_SAVE"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" (click)="closeminiSupplierModal()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined"></button>


    </p-footer>
  </p-dialog>


  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" appendTo="body" header='{{"CUSTOMER_FORM_ADD_CONTACT"| translate}}'
    [(visible)]="showContactDialog" [style]="{width: '25vw'}">
    <div class="p-grid ">
      <div class="p-col-12 p-mt-3">
        <span [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched) }" class="p-float-label">
          <input required email (ngModelChange)="notempty($event)" [(ngModel)]="contact.email" #email="ngModel"
            name="email" id="email-input" type="text" pInputText>
          <label for="email-input">{{"LOGIN_EMAIL"| translate}}&nbsp;
            <label *ngIf="contact.email == '' || contact.email == null &&  email.touched ; else anotheral"
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

            <ng-template #anotheral>
              <label *ngIf="email.invalid && email.dirty " class="alert-invalid">{{"CUSTOMER_FORM_NOT_VALID"|
                translate}}</label>
            </ng-template>
          </label>
        </span>
      </div>
      <div class="p-col-6 p-mt-3">
        <span class="p-float-label">
          <input required minlength="2" (keydown.space)="preventDefault($event,contactname)"
            (ngModelChange)="notempty($event)" [(ngModel)]="contact.name" #contactname="ngModel" name="name"
            id="contact-name-input" type="text" pInputText>
          <label for="contact-name-input">{{"CUSTOMER_LIST_CONTACT_NAME"| translate}}
          </label>
        </span>
      </div>
      <div class="p-col-6 p-mt-3">
        <span class="p-float-label">
          <input (keydown.space)="preventDefault($event,position)" (ngModelChange)="notempty($event)"
            [(ngModel)]="contact.position" #position="ngModel" name="position" id="position-input" type="text"
            pInputText>
          <label for="position-input">{{"CUSTOMER_FORM_POSITION"| translate}}
          </label>
        </span>
      </div>
      <div class="p-col-6 p-mt-3">
        <span class="p-float-label">
          <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.phone" name="phone" id="phone-input"
            type="text" pInputText>
          <label for="phone-input">{{"CUSTOMER_FORM_PHONE"| translate}}
          </label>
        </span>
      </div>
      <div class="p-col-6 p-mt-3">
        <span class="p-float-label">
          <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.mobile_no" name="phone" id="phone-input"
            type="text" pInputText>
          <label for="mobile-input">Mobile Number</label>
        </span>
      </div>
      <div class="p-col-12 p-mt-3">
        <span class="p-float-label">
          <textarea (ngModelChange)="notempty($event)" [(ngModel)]="contact.note" id="notes-input" pInputTextarea
            rows="4"></textarea>
          <label for="notes-input">{{"CUSTOMER_FORM_NOTES"| translate}}</label>
        </span>
      </div>
    </div>

    <div *ngIf="DropdownonFocus_Stuats==false" class="p-grid form-group ">
      <div class="p-col-6 mt-2">
        <span [ngClass]="{'invalid': contactname.invalid && (contactname.dirty || contactname.touched) }"
          class="p-float-label">
          <input required minlength="2" (keydown.space)="$event.preventDefault();" [(ngModel)]="contact.name"
            #contactname="ngModel" name="name" id="contact-name-input" type="text" pInputText>
          <label for="contact-name-input">{{"CUSTOMER_LIST_CONTACT_NAME"| translate}}
          </label>

        </span>
      </div>
      <div class="p-col-6  mt-2">
        <span [ngClass]="{'invalid': position.invalid && (position.dirty || position.touched) }" class="p-float-label">
          <input required minlength="2" (keydown.space)="$event.preventDefault();" (ngModelChange)="notempty($event)"
            [(ngModel)]="contact.position" #position="ngModel" name="position" id="position-input" type="text"
            pInputText>
          <label for="position-input">{{"CUSTOMER_FORM_POSITION"| translate}}


          </label>

        </span>
      </div>

      <div class="p-col-6  mt-2">
        <span [ngClass]="{'invalid': phone.invalid && (phone.dirty || phone.touched) }" class="p-float-label">
          <input required
            pattern='(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})'
            (ngModelChange)="notempty($event)" [(ngModel)]="contact.phone" #phone="ngModel" name="phone"
            id="phone-input" type="text" pInputText>
          <label for="phone-input">{{"CUSTOMER_LIST_CONTACT_PHONE"| translate}}</label>
        </span>
      </div>
      <div class="p-col-6  mt-2">
        <span class="p-float-label">
          <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.GSM" name="gsm" id="gsm-input" type="text"
            pInputText>
          <label for="gsm-input">{{"CUSTOMER_FORM_GSM"| translate}}</label>
        </span>
      </div>

      <div class="p-col-6  mt-2">
        <span class="p-float-label">
          <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.note" name="note" id="note-input" type="text"
            pInputText>
          <label for="note-input">{{"CUSTOMER_FORM_NOTES"| translate}}</label>
        </span>
      </div>

      <div class="p-col-6  mt-2">
        <span class="p-float-label">
          <input [(ngModel)]="contact.fax" id="fax-input" type="text" pInputText>
          <label for="fax-input">{{"CUSTOMER_FORM_FAX"| translate}}</label>
        </span>
      </div>
    </div>

    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="addContact()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="showContactDialog=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined"></button>


    </p-footer>
  </p-dialog>
