import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { Quote } from '../../../models/quotes/quote';
import { QuotesService } from '../quotes.service';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Product } from '../../../models/product';
import { Customer } from 'src/app/models/customer';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../../dashboard/dashboard.service';
import { Query } from 'src/app/models/query';
import { DatePipe } from '@angular/common';
import { Currency } from 'src/app/models/currency';
import { SharedService } from '../../shared/shared.service';
import { OrderStatus } from 'src/app/models/orders/orderstatus';
import { MenuService } from 'src/app/shared/menu.service';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss']

})
export class QuotesListComponent extends BaseComponent implements OnInit {

  public showSidebar = false;
  public modalTitle = 'Add new quote';
  public removedialogueeQ = false;
  public LangStats = new OrderStatus()
  public quotes: Quote[] = [];
  loadingbtnPrint = false
  loadingbtnemail = false
  public fillterCustomers: Customer[] = [];
  public filteredQuotes: Quote[] = [];
  itemSearch
  public currentModal;
  public selectedQuote: Quote
  public selected: Quote[] = [];
  qouteSelectedIndex = 0
  convertialogueeQ = false
  ToOrder = new Quote()
  keyword = "format"
  products: Product[] = [];
  statuses: any[] = [
    { status: { value: 'Completed', label: this.translate.instant('COMPLETED'), color: 'Completed' } },
    { status: { value: 'Draft', label: this.translate.instant('DRAFT'), color: 'Draft' } },


  ]
  valuestatus
  itemsSave = []
  modaltatusTitle: string
  langOpctions = [];
  header_body: string

  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith', label: this.translate.instant('START_WITH')
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL')
    },
    {
      type: 'Contains', label: this.translate.instant('CONTINES')
    },
    {
      type: 'EndsWith', label: this.translate.instant('END_WITH')
    },
  ];
  query_types2 = [
    {
      type: 'Not Equal', label: this.translate.instant('NOT_EQUAL'), value: 'ne'
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL'), value: 'exact'
    },
    {
      type: 'Less than', label: this.translate.instant('LESS_THAN'), value: 'lt'
    },
    {
      type: 'Grater than', label: this.translate.instant('GREATER_THAN'), value: 'gt'
    },
  ];
  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  orderNextPage: any = 0
  orderPrevPage: any = 0

  selected_Cutstomerquery = new Customer()
  filterModeApply_Cutstomer: boolean = false
  filterModeFormat_query = new Query()
  filterModeStatus_query = new Query()
  filterModeDate_query = new Query()
  filterModeexpiry_date_query = new Query()
  filterModeTotal_query = new Query()
  seletedquery_type: string

  selectedCurrancy = new Currency()
  currencies: Currency[] = []
  currancyOptions: MenuItem[] = [];

  save_Print: boolean = false
  save_Email: boolean = false

  constructor(private quotesService: QuotesService, public translate: TranslateService, private dashServices: DashboardService,
    private sharedService: SharedService, private menuService: MenuService,
    private messageService: MessageService, private router: Router,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.filterFromDashboard()
    this.subscribeOnQuotesRefresh();
    this.subscribeOnduplicatOrder()
    this.getCurrencies()
    this.currencyChanged()
    this.emailbtnLoading()
    this.langChanged()
    const lang=JSON.parse(localStorage.getItem('currentLang'))?.lang
    this.LangStats.langStatus=lang

    this.itemsSave = [
      {
        label: 'Save & Print', icon: 'fas fa-print', command: () => {
          this.saveForm()
          this.save_Print = true
        }
      },
      {
        label: 'Save & Email', icon: "far fa-envelope", command: () => {
          this.saveForm()
          this.save_Email = true

        }
      },

    ]
    // this.subscribeOnConvert()
    this.langOpctions = [
      {
        label: 'EN', command: () => {

          this.quotesService.printLang = 'en'
          this.print()
        }
      },
      {
        label: 'FR', command: () => {

          this.quotesService.printLang = 'fr'

          this.print()
        }
      },
      {
        label: 'NL', command: () => {

          this.quotesService.printLang = 'nl'

          this.print()
        }
      },
    ]
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

  }


  getCurrencies() {
    this.currencies = []
    this.currancyOptions = []
    const subscription = this.sharedService.currnceyChangeEmitter.subscribe((currencies: Currency[]) => {

      if (!isSet(currencies)) {
        this.currencies = JSON.parse(localStorage.getItem('supported_currencies'))
      } else {
        localStorage.setItem('supported_currencies', JSON.stringify(this.currencies))

        this.currencies = Currency.cloneManyObjects(currencies)

      }
      this.currencies= this.currencies.reverse();

      this.currencies?.map(c => {
        this.currancyOptions.push(
          {
            label: c.name, command: () => {
              if (this.selectedCurrancy?.id == c?.id) {
                return
              }
              this.selectedCurrancy = c

              this.quotesService.currencyChanged.next(c)
            }
          }
        )
      })


    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);
  }
  filter(evenet) {
  }
  private subscribeOnQuotesRefresh() {
    const subscription = this.quotesService.refreshListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getQuotes();
      }
    });
    this.subscriptions.push(subscription);
  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.currentModal = 'Form';
        this.modalTitle = this.selectedQuote ? this.translate.instant('QUOTE') + ` : ` : this.translate.instant('ADD_NEW_QUOTE');
        this.header_body = this.selectedQuote ? this.selectedQuote.format : null
        break;
    }
  }
  showAddQuote() {
    this.save_Print = false
    this.save_Email = false
    this.getCurrencies()
    this.showSidebar = true;
    this.sharedService.Create_form(true)
    this.quotesService.currentOrder = null;
    this.quotesService.selectQuote(null);
    this.selectedQuote = null;
    this.switchModal('Form');
    this.modaltatusTitle = null
    this.header_body = null
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

  }


  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.quotes = e


  }

  print() {
    this.loadingbtnPrint = true;

    const subscription = this.quotesService.PrintQoute(this.selectedQuote.id).subscribe((url: any) => {
      if (!isSet(Quote)) {
        return;
      }

      this.quotesService.printqoute_link.next(url)
      subscription.unsubscribe();
      this.loadingbtnPrint = false;
    }, error => {
      subscription.unsubscribe();
      this.loadingbtnPrint = false;

    })
  }

  emailbtnLoading() {
    const subscription = this.quotesService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false

      }
      if (status == true) {
        return this.loadingbtnemail = true

      }
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  convertt(e) {
    if (!isSet(e)) {
      return
    }
    this.showSidebar = false
    this.quotesService.selectQuote(null);
    this.selectedQuote = null;
    setTimeout(() => {
      this.quotesService.convertToOrder(this.quotesService.currentOrder)
    }, 1000)


  }
  removeQuotes() {

    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.quotesService.deleteQuotes(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.format + this.translate.instant('DELETED') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: 'Selected qoutes deleted' });
          }
          this.loading = false;
          this.quotesService.refreshDataTable();
          subscription.unsubscribe();
          this.sharedService.dialog_status.next(false)



        }, error => {
          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();
          this.sharedService.dialog_status.next(false)

        })

        this.selected = []
        this.refreshList()
      })




    }
    catch (error) {
      console.log(error)
      this.log(error);
    }

    this.removedialogueeQ = false
  }
  getNextorderPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.quotesService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.quotesService.quotesNextPage(this.orderNextPage).subscribe((orders: PaginatedResponse<Quote[]>) => {
          if (!isSet(orders)) {
            return;
          }



          const clone = Quote.cloneManyObjects(orders.results);
          clone.map(e => {
            e.customer = new Customer()
            e.customer.company_name = e.company_name
            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
            let expriydatefillter = new Date(e.expiry_date)

            e.expiry_date = new Date(expriydatefillter.getFullYear(), expriydatefillter.getMonth(), expriydatefillter.getUTCDate())
            this.fillterCustomers.push(e.customer)

            this.filteredQuotes.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.quotes[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date' || query_name == 'expiry_date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.quotesService.queris.map((query, index) => {
      if (query.query_name == query_name) {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.quotesService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.ordersCount = 0
    const subscription = this.quotesService.getQuotes().subscribe((quotes: PaginatedResponse<Quote[]>) => {
      if (!isSet(quotes)) {
        return;
      }
      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = quotes.count
      this.total_pages = quotes.total_pages
      this.quotes = Array(this.ordersCount).fill(0)


      const clone = Quote.cloneManyObjects(quotes.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        this.filteredQuotes.push(e)
      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.quotes[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.quotesService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeexpiry_date_query = new Query()
    this.filterModeStatus_query = new Query()
    this.selected_Cutstomerquery = new Customer()
    this.getQuotes()
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.quotesService.sort = filed
      return this.getQuotes()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.quotesService.sort = '-' + filed
      return this.getQuotes()
    }







  }
  completeMethod(serach_item) {
    this.quotesService.queris = []
    this.getOrders_queries('format', 'startswith', serach_item)
  }

  showQuote(quote: Quote, index: number) {
    this.qouteSelectedIndex = index

    this.showSidebar = true;

    this.quotesService.selectQuote(quote);
    this.selectedQuote = quote;
    this.selectedCurrancy = quote.currency
    this.switchModal('Form');
    this.modaltatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedQuote.status.status?.color}">${this.translate.instant(this.selectedQuote.status.status?.label)}</span> `
  }
  public saveForm() {
    this.quotesService.saveForm();
    const subscription = this.quotesService.retrieve_lastOrderEmitter.subscribe((quote: Quote) => {
      if (!isSet(quote)) {
        return
      }
      this.selectedQuote = Quote.cloneObject(quote)


      this.modalTitle = this.selectedQuote ? this.translate.instant('QUOTE') + ` : ` : this.translate.instant('ADD_NEW_QUOTE');
      this.header_body = this.selectedQuote ? this.selectedQuote.format : null
      this.modaltatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedQuote.status.status?.color}">${this.translate.instant(this.selectedQuote.status.status?.label)}</span> `

      if (this.save_Print == true) {
        return this.print()
      }
      if (this.save_Email == true) {
        return this.sendemail()
      }

      this.quotesService.retrieve_lastOrder.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  saveQoute() {
    this.saveForm()
  }
  public convert() {
    this.quotesService.Converttoroeder_buuton(true)
  }
  public sendemail() {
    this.quotesService.sendEmail_buuton(true)
  }

  private getQuotes() {
    this.loading = true

    const subscription = this.quotesService.getQuotes().subscribe((quotes: PaginatedResponse<Quote[]>) => {

      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = quotes.count
      this.total_pages = quotes.total_pages
      this.quotes = Array(this.ordersCount).fill(0)

      const clone = Quote.cloneManyObjects(quotes.results);


      clone.map(e => {
        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        let expriydatefillter = new Date(e.expiry_date)

        e.expiry_date = new Date(expriydatefillter.getFullYear(), expriydatefillter.getMonth(), expriydatefillter.getUTCDate())

        this.filteredQuotes.push(e)


      })
      // remove duplicate form customers fillter
      // let rmAduplicate = []
      // this.fillterCustomers.forEach((item) => {
      //   if (rmAduplicate.findIndex(i => i == item) === -1) {
      //     rmAduplicate.push(item)
      //   }
      // });
      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.quotes[index] = clone[index];

      }

      this.loading = false

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  Update() {
    this.quotesService.upadteFormClicked.next(true)
  }

  refreshList() {
    this.quotesService.sort = null
    this.quotesService.queris = []
    this.dashServices.displayFilter.next(null)
    this.quotesService.selectQuote(null)
    this.dashServices.displayFilter.next(null)
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);

    });



  }
  Toorder(order: Quote) {

    this.getQuote(order.id)
    this.convertialogueeQ = true
    this.quotesService.selectQuote(this.ToOrder)
  }
  convertToOrder() {

    this.loading = true;
    if (!isSet(this.ToOrder)) {
      return;
    }
    this.quotesService.convertToOrder(this.ToOrder)
    this.outside_Status(false)

  }

  private getQuote(id: string) {
    this.loading = true;
    const subscription = this.quotesService.getQuote(id).subscribe((quote: Quote) => {
      if (!isSet(quote)) {
        return
      }
      const cloneObject = Quote.cloneObject(quote);
      this.ToOrder = cloneObject
      if (this.ToOrder.discount[this.ToOrder.discount.length - 1] == "%") {
        this.ToOrder.discount = this.ToOrder.discount.substring(0, this.ToOrder.discount.length - 1)

        this.ToOrder.discount_type = 'Percent'
      } else {
        this.ToOrder.discount_type = 'Amount'

      }




      this.ToOrder.date = new Date(quote?.date)
      this.ToOrder.expiry_date = new Date(quote.expiry_date)


      const disconnt = this.ToOrder.discont_Percentage
      if (this.ToOrder?.items?.length) {

        this.ToOrder?.items?.map(item => {
          item.discont_Percentage = disconnt
          item.exchange_rate = this.ToOrder?.exchange_rate

          item.itemVaild = true
          item.datePipe = new Date(item?.date);
          if (isSet(item.product?.id)) {
            item.checkinlne = false
          }
          if (isSet(item?.name) && !isSet(item.product?.id)) {
            item.checkinlne = true
            item.product = new Product()
            item.product.name = item?.name
          }
          if (isSet(item.service)) {
            item.checkinlne = true
          }
        })

      this.loading = false;
      subscription.unsubscribe();
      }
    }, error => {
      subscription.unsubscribe();
      this.loading = false;

    });
  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  private subscribeOnOrderSelected() {
    const subscription = this.quotesService.QuoteSelectedListEmitter.subscribe((quote: Quote) => {
      if (!isSet(quote)) {
        return;
      }
      this.showSidebar = true
      this.selectedQuote = quote;
      this.switchModal('Form');

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  duplicateQoute() {
    this.quotesService.duplicateClicked.next(true)
  }
  private subscribeOnduplicatOrder() {
    this.loading = true
    setTimeout(() => {
      const subscription = this.quotesService.duplicateClickedEmitter.subscribe((status: boolean) => {
        if (!isSet(status) || !status) {
          return
        }
        this.quotesService.duplicateClicked.next(null)
        this.showDuplicate()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);
      this.loading = false

    }, 500);


  }
  showDuplicate() {

    this.sharedService.Create_form(true)
    const format = this.selectedQuote?.format
    this.selectedQuote = null;
    this.modalTitle = this.translate.instant('DUPLI_QUOTE')
    this.modaltatusTitle = null
    this.selectedQuote = null
    this.showSidebar = true;


  }
  deleteseletedQoute(qoute: Quote) {
    this.selected = []
    this.selected.push(qoute)
    this.removedialogueeQ = true
  }
  filterFromDashboard() {
    const subscription = this.dashServices.displayFilterEmitter.subscribe(type => {

      if (!isSet(type)) {
        this.getQuotes()
        return
      }
      this.quotes = type

    }, error => {
      console.log(error);
    })
    this.subscriptions.push(subscription)
  }
  currencyChanged() {
    const subscription = this.quotesService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }

      this.selectedCurrancy = currancy


      this.quotesService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }

  langChanged() {
    const subscription = this.menuService.langStatusEmitter.subscribe(status => {
      if (!isSet(status)) {
        return
      }
      this.LangStats.langStatus=status
      this.menuService.langStatus.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }


  sendToWhatsapp(id?){
    if (!this.selectedQuote.whatsappUrl){
      const subscription = this.quotesService.PrintQoute(id).subscribe((url: any) => {
        if (!isSet(Quote)) {
          return;
        }
        this.getShortUrl(url.download_url,url.file_name).then(()=>{
          window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedQuote.whatsappUrl}`, "_blank")

        })
        subscription.unsubscribe();
      }, error => {
        subscription.unsubscribe();
      })
    }else{
      window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedQuote.whatsappUrl}`, "_blank")
    }
  }
  getShortUrl(downloadUrl,fileName){
    return new Promise((resolve) => {
      const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
        if (!isSet(url)) {
          return
        }
        resolve(this.selectedQuote.whatsappUrl=url?.id)
        subscription.unsubscribe()
      },error=>{
        console.log(error)
        subscription.unsubscribe()
      })
    });
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
