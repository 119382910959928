import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditNote } from 'src/app/models/CreditNotesCustomer';
import { Product } from 'src/app/models/product';
import { SendEmail } from 'src/app/models/SendEmail';
import { Payment } from 'src/app/models/orders/Payment';
import { DatePipe } from '@angular/common';
import { isSet } from '../core/util';
import { Order } from 'src/app/models/orders/order';

@Injectable({
  providedIn: 'root'
})
export class CreditnotesCustomerService {
  public newPayments: Payment[] = []
  public printLang: string;
  public  queris  = []
  public  sort :any

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();


  public duplicateClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public duplicateClickedEmitter: Observable<boolean> = this.duplicateClicked.asObservable();
  
  public refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();

  public creditNote_Selected: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
  public creditNote_SelectedListEmitter: Observable<CreditNote> = this.creditNote_Selected.asObservable();

  public ImportCreditNoteSelected: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
  public ImportCreditNoteSelectedEmitter: Observable<CreditNote> = this.ImportCreditNoteSelected.asObservable();

  public sendEmailClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sendEmailClickedEmitter: Observable<boolean> = this.sendEmailClicked.asObservable();

  public refreshselected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshselectedEmitter: Observable<boolean> = this.refreshselected.asObservable();


  public btnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public btnLoadingEmitter: Observable<boolean> = this.btnLoading.asObservable();

  

  public printCreditnote_link: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public printCreditnote_linkEmitter: Observable<any> = this.printCreditnote_link.asObservable();

  public retrieve_lastCreditnote: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
  public retrieve_lastCreditnoteEmitter: Observable<CreditNote> = this.retrieve_lastCreditnote.asObservable();

  public confirmCreditnote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public confirmCreditnoteEmitter: Observable<boolean> = this.confirmCreditnote.asObservable();

  public importform_OrdersModule: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
 public importform_OrdersModuleEmitter: Observable<CreditNote> = this.importform_OrdersModule.asObservable();


 public currencyChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public currencyChangedEmitter: Observable<any> = this.currencyChanged.asObservable();
 
  constructor(private api: ApiService, private datePipe: DatePipe) {
  }

  getCredit_notes(): Observable<PaginatedResponse<CreditNote[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<CreditNote[]>>(`/credits_notes?${sum_querirs}&order=${this.sort}`);

    }

    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<CreditNote[]>>(`/credits_notes?order=${this.sort}`);

    }
  }

  getCredit_note(id: string): Observable<CreditNote> {
    return this.api.get<CreditNote>(`/credits_notes/rud/${id}`);
  }
  credit_notesNextPage(num): Observable<PaginatedResponse<CreditNote[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<CreditNote[]>>(`/credits_notes?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<CreditNote[]>>(`/credits_notes?page=${num}&order=${this.sort}`);

    }
  }

  getNextNumber(): Observable<{ reference: string }> {
    return this.api.get<{ reference: string }>('/credits_notes/next_reference');
  }
  getnext_issued_reference(): Observable<{ cn_format: string }> {
    return this.api.get<{ cn_format: string }>('/credits_notes/next_issued_cn_format');
  }

  addCredit_notes(credit: CreditNote): Observable<CreditNote> {
    let date = this.datePipe.transform(credit.date, 'yyyy-MM-dd')
    let dueDate = this.datePipe.transform(credit.due_date, 'yyyy-MM-dd')

    let discont_ = credit.discount
    if (credit.discount_type == 'Percent') {
      discont_ = credit.discount + '%'
    }
    return this.api.post('/credits_notes', credit.toDB(date, dueDate,discont_));
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }
  printCreditNote(id: string): Observable<any> {
    let printLang
    if (!isSet(this.printLang)) {
      printLang = JSON.parse(localStorage.getItem("currentLang"))?.lang.toLocaleLowerCase() || 'en'
    } else {
      printLang = this.printLang
    }
    let body = {
      lang: printLang.toLocaleLowerCase()
    }


    return this.api.post<any>(`/credits_notes/print_credit_note/${id}`, body);
  }
  refreshDataTable() {
    this.refreshList.next(true);
  }

  selectCredit_notes(CreditNote: CreditNote) {
    this.creditNote_Selected.next(CreditNote);
  }

  deleteCredit_note(id: string): Observable<CreditNote> {
    return this.api.delete<CreditNote>(`/credits_notes/rud/${id}`);
  }

  // getproducts(id: string): Observable<Product> {
  //   return this.api.get<Product>(`/products/${id}`);
  // }


  updateCredit_note(creditNote: CreditNote) {
    let discont_ = creditNote.discount
    if (creditNote.discount_type == 'Percent') {
      discont_ = creditNote.discount + '%'
    }
    let date = this.datePipe.transform(creditNote.date, 'yyyy-MM-dd')
    let due_date = this.datePipe.transform(creditNote.due_date, 'yyyy-MM-dd')

    let itemstoUpdate = []
let orders = []
    for (let x = 0; x < creditNote.items.length; x++) {
      if (isSet(creditNote.items[x].service)) {
        creditNote.items[x].name = null
      }
      creditNote.items[x].price = Number(creditNote.items[x]?.price)
      creditNote.items[x].qty = Number(creditNote.items[x]?.qty)
      creditNote.items[x].vat.value = Number(creditNote.items[x]?.vat.value)
      creditNote.items[x].date = this.datePipe.transform(creditNote.items[x]?.datePipe, 'yyyy-MM-dd')
      itemstoUpdate[x] = {
        id: creditNote.items[x]?.id,
        product: creditNote.items[x]?.product?.id,
        name: creditNote.items[x]?.name,
        qty: creditNote.items[x]?.qty,
        price: creditNote.items[x]?.price,
        vat: creditNote.items[x]?.vat?.id,
        date: creditNote.items[x]?.date,
        service: creditNote.items[x]?.service,
        order:creditNote.items[x]?.order
      }

    }
creditNote?.orders?.map((order,index)=>{
  orders[index]={
    _id:order.id
  }
})
    let update = {
      date: date,
      due_date: due_date,
      customer: creditNote?.customer?.id,
      notes: creditNote.notes,
      payments: this.newPayments,
      items: itemstoUpdate,
      total_discount: creditNote?.total_discount,
      orders:orders,
      exchange_rate:creditNote?.exchange_rate,
      discount : discont_
    }
    update?.payments.map(payment => {
      payment.amount = Number(payment?.amount)
      if (payment?.type == 'Wire Transfer') {
        payment.type = 'wt'
      }
      payment.status= "refund"

  

    })

    return this.api.patch<any>(`/credits_notes/rud/${creditNote.id}`, update)
  }
  convertToIssue(creditNote: CreditNote) {

    let issue = {
      cn_format: creditNote.cn_format,
      cn_date: creditNote.cn_date
    }


    return this.api.post<any>(`/credits_notes/issue/${creditNote.id}`, issue)
  }
  get_cutsomer_orders(customer_id: string): Observable<PaginatedResponse<Order[]>> {

    return this.api.get<PaginatedResponse<Order[]>>(`/orders?query=customer,${customer_id}&query=status,invoiced,true&query=status,paid,true`)
  }

  sendEmail(SendEmail: SendEmail, id: string): Observable<SendEmail> {

    return this.api.post(`/credits_notes/send_mail/${id}`, SendEmail.toDB());
  }
}
