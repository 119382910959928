<div  class="layout-wrapper " (click)="onLayoutClick()"
     [ngClass]="{'layout-horizontal': horizontalMenu,
                'layout-wrapper-static': staticMenuActive,
                'layout-rightpanel-active': rightPanelActive,
                'layout-topbar-mobile-active': topbarMobileMenuActive,
                'layout-sidebar-mobile-active': menuMobileActive,
                'p-input-filled': inputStyle === 'filled',
                'p-ripple-disabled': !ripple}"  [class]="menuColor">

  <app-menu></app-menu>

  <div   class="layout-main">
    <app-topbar></app-topbar>

    <!-- <app-rightpanel></app-rightpanel> -->

    <app-breadcrumb ></app-breadcrumb>

    <div  class="layout-content p-4">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <app-config-system></app-config-system>

    <div class="layout-main-mask"></div>
  </div>
</div>
