<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (Reload)='Reload()' (remove)="remoe()" (add)="showAddCustomer()"
        addLabel=' {{"CUSTOMER_LIST_CREATE"| translate}}' [disableRemove]="!selected.length">
        <app-Search-select [selecteditem]="itemSearch" (completeMethod)="completeMethod($event)"
          (onClear)="clearCustomers_queries()" [Searchitems]="filteredCustomers" [field_name]="keyword"
          (filter)="serach($event)"></app-Search-select>
      </app-table-header>

      <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped"
        (onPage)="getNextCustomerPage($event)" [value]="customers" [paginator]="true" [rows]="10"
        [(selection)]="selected" [scrollable]="true" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {{customersCount}} entries"
        [globalFilterFields]="['vat','company_name','firstcontact.name','firstcontact.email','firstcontact.phone']">

        <ng-template pTemplate="header" let-columns>

          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox (click)="deleteRow(selected)"></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="company_name">
              <span
                [style.color]="selected_Customerrquery?.type && selected_Customerrquery?.value?'var(--primary-color)':null">
                {{"CUSTOMER_LIST_COMAPNY"| translate}} </span>
              <p-sortIcon (click)="customSort('company_name')" field="company_name"></p-sortIcon>

              <p-columnFilter #companyNameFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="reference" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="selected_Customerrquery.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="selected_Customerrquery.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Customerrquery?.type || !selected_Customerrquery?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('company_name',selected_Customerrquery?.type,selected_Customerrquery?.value);companyNameFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>


            </th>
            <th>

              <span [style.color]="filterModeVat_query?.type && filterModeVat_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"CUSTOMER_LIST_VAT"| translate}} </span>

              <p-columnFilter #customerListVatFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeVat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeVat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeVat_query?.type || !filterModeVat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('vat',filterModeVat_query?.type,filterModeVat_query?.value);customerListVatFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>

            <th>
              <span
                [style.color]="filterModeRegion_query?.type && filterModeRegion_query?.value?'var(--primary-color)':null">
                {{"DASHBORD_REGION"| translate}} </span>

              <p-columnFilter #regionFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="reference" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeRegion_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeRegion_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeRegion_query?.type || !filterModeRegion_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('region',filterModeRegion_query?.type,filterModeRegion_query?.value);regionFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>


            </th>
            <th>

              <span
                [style.color]="filterModeContactName_query?.type && filterModeContactName_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"CUSTOMER_LIST_CONTACT_NAME"| translate}} </span>

              <p-columnFilter #contactNameFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeContactName_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeContactName_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="!filterModeContactName_query?.type || !filterModeContactName_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('contact,name',filterModeContactName_query?.type,filterModeContactName_query?.value);contactNameFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th>

              <span
                [style.color]="filterModeContactEmail_query?.type && filterModeContactEmail_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"CUSTOMER_LIST_CONTACT_EMAIL"| translate}} </span>

              <p-columnFilter #contactEmailFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeContactEmail_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeContactEmail_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="!filterModeContactEmail_query?.type || !filterModeContactEmail_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('contact,email',filterModeContactEmail_query?.type,filterModeContactEmail_query?.value);contactEmailFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th>

              <span
                [style.color]="filterModeContactPhone_query?.type && filterModeContactPhone_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"CUSTOMER_LIST_CONTACT_PHONE"| translate}} </span>

              <p-columnFilter #contactPhoneFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeContactPhone_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeContactPhone_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="!filterModeContactPhone_query?.type || !filterModeContactPhone_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('contact,phone',filterModeContactPhone_query?.type,filterModeContactPhone_query?.value);contactPhoneFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="due">
              <span [style.color]="filterModeDue_query?.type && filterModeDue_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"DASHBORD_BLANCE" | translate}}</span>
              <p-sortIcon (click)="customSort('due')" field="due"></p-sortIcon>

              <p-columnFilter #balanceFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDue_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">

                    <p-inputNumber [(ngModel)]="filterModeDue_query.value" mode="decimal"></p-inputNumber>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDue_query?.type || !filterModeDue_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('due',filterModeDue_query?.type,filterModeDue_query?.value);balanceFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="credit_notes">
              <span
                [style.color]="filterModeCredit_notes_query?.type && filterModeCredit_notes_query?.value ?'var(--primary-color)':null"
                class="p-mr-1"> {{"DASHBORD_CREDIT_NOTES" | translate}}</span>
              <p-sortIcon (click)="customSort('credit_notes')" field="credit_notes"></p-sortIcon>

              <p-columnFilter #criditNoteFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                [showAddButton]="false" type="text" field="vat" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true"
                    [(ngModel)]="filterModeCredit_notes_query.type" placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value"
                    optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">

                    <p-inputNumber [min]="0" [(ngModel)]="filterModeCredit_notes_query.value" mode="decimal">
                    </p-inputNumber>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearCustomers_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="!filterModeCredit_notes_query?.type || !filterModeCredit_notes_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getCustomer_queries('credit_notes',filterModeCredit_notes_query?.type,filterModeCredit_notes_query?.value);criditNoteFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox (click)="seletedCustomer(rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showCustomer(rowData)" href="javascript:;">{{ rowData.company_name }}</a></td>
            <td>
              <span *ngIf="rowData.vat  ;else nul"> {{ rowData.vat }}</span>
              <ng-template #nul>ــ</ng-template>
            </td>

            <td>
              <span *ngIf="rowData.region  ;else nul"> {{ rowData?.region?.name }}</span>
              <ng-template #nul>ــ</ng-template>

            </td>

            <td>

              <span *ngIf="rowData.firstcontact?.name  ;else nul"> {{ rowData.firstcontact?.name }}</span>
              <ng-template #nul>ــ</ng-template>
            </td>
            <td>

              <span *ngIf="rowData.firstcontact?.email  ;else nul"> {{ rowData.firstcontact?.email }}</span>
              <ng-template #nul>ــ</ng-template>
            </td>
            <td>

              <span *ngIf="rowData.firstcontact?.phone  ;else nul"> {{ rowData.firstcontact?.phone }}</span>
              <ng-template #nul>ــ</ng-template>
            </td>
            <td>{{ rowData.due | number:'1.2-2' }} <span style="color: var(--primary-color);font-size: small;">{{
                currency?.name }}</span></td>
            <td>{{ rowData.credit_notes | number:'1.2-2' }} <span
                style="color: var(--primary-color);font-size: small;">{{ currency?.name }}</span></td>


          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">No Customers found</td>
          </tr>
        </ng-template>


      </p-table>

    </div>
  </div>
</div>

<p-dialog modal="true"  class="p-shadow-1"
  [header]="this.selected.length == 1 ? 'Delete '+ selected[0]?.company_name : 'Delete Customers'"
  [(visible)]="Removedialoguee" [style]="{width: '30vw'}">

  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> {{"DASHBORD_DELETE_CUSTOMER_LIST" | translate}}
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.company_name}} ?</span>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="RemoveCustomer()"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON"| translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="Removedialoguee=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}} ' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [size]="size">
  <div header>
    <button pButton *ngIf="selectedCustomer != null && selectedCustomer != undefined && currentModal == 'Form'"
      (click)="switchModal('Dashboard')" type="button" label='{{"CUSTOMER_LIST_DASHBOARD"| translate}}'
      icon="pi pi-chart-bar" class="ui-button-raised ui-button-secondary p-mr-2"></button>
    <button pButton *ngIf="selectedCustomer != null && selectedCustomer != undefined && currentModal == 'Dashboard'"
      (click)="switchModal('Form')" type="button" label='Profile'
      class="ui-button-raised ui-button-info p-mr-2"></button>
    <button pButton (click)="saveForm()" *ngIf="!selectedCustomer" type="button"
      label='{{"CUSTOMER_LIST_SAVE"| translate}}' class="ui-button-raised ui-button-info"></button>
    <button *ngIf=" selectedCustomer  && currentModal == 'Form'" (click)="upadteFormClicked()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE"| translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-customer-form *ngIf="currentModal == 'Form' && showSidebar"></app-customer-form>
    <app-customer-dashboard *ngIf="currentModal == 'Dashboard' && showSidebar"></app-customer-dashboard>
  </div>
</app-sidebar>