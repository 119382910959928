import { isSet } from '../modules/core/util';

export class Category {
  id: string;
  name: string;
  company:number;
  
  public constructor(params?: Category) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Category) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Category(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Category[]) {
    const objects: Category[] = [];
    for (const obj of objectsToClone) {
      objects.push(Category.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
return{
  name:this.name

}
  }
}
