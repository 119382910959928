import { isSet } from '../modules/core/util';

export class ProductPricing {
  buy_price: number;
  cost: number;
  delivery_cost: number;
  sub_items_count: number;
  sub_items_sell_price: number;
  sub_item_barcode: string;

  public constructor(params?: ProductPricing) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: ProductPricing) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new ProductPricing(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: ProductPricing[]) {
    const objects: ProductPricing[] = [];
    for (const obj of objectsToClone) {
      objects.push(ProductPricing.cloneObject(obj));
    }

    return objects;
  }
}
