<div class="table-header-parent">
  <div *ngIf="hasFilter" class="item">
    <p-overlayPanel #filter>
      <p>{{"SIDE_BAR_FILTER_VIEW"| translate}}</p>
    </p-overlayPanel>

    <button (click)="filter.toggle($event)" pButton pRipple type="button" icon="fas fa-filter"
      label='{{"CUSTOMER_LIST_CREATE_NEW"| translate}}' class="p-button-outlined p-button-help"></button>
  </div>

  <div class="item">
    <button id="create" (click)="ad()" pButton pRipple type="button" icon="fas fa-plus" [label]="addLabel"
      class="p-button-raised p-button-primary"></button>
  </div>
  <div *ngIf="url == '/buy/purchaseorders'" class="item">
    <button id="create2" #lol (click)="ad2($event)" pButton pRipple type="button" icon="fas fa-plus" [label]="addLabel2"
      class="p-button-raised p-button-primary"></button>
  </div>
  <div *ngIf="url == '/??'" class="item">
    <button (click)="ad2($event)" pButton pRipple type="button" icon="fas fa-plus" [label]="addLabel2"
      class="p-button-raised p-button-primary"></button>
  </div>

  <div class="item">
    <button (click)="rm()" [disabled]="disableRemove" pButton pRipple type="button" icon="fas fa-trash"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON"| translate}}' class="p-button-raised p-button-danger"></button>
  </div>

  <div class="item">
    <button pButton pRipple (click)="Reload.emit()" type="button" icon="fas fa-redo"
      label=' {{"SIDE_BAR_REFRESH"| translate}}' class="p-button-raised p-button-secondary"></button>
  </div>


  <div class="search">
    <span class="md-inputfield search-input-wrapper">
      <ng-content></ng-content>
    </span>
  </div>



</div>
<div>
  <!-- <div class="search-responive">
  <span class="md-inputfield search-input-wrapper">
<ng-content ></ng-content>
    <i class="pi pi-search"></i>
  </span>
</div> -->
