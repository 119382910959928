export class PrintOrder {
  type: string;
  lang?: Lang
}

export enum Lang {
  EN = 'en',
  FR = 'fr',
  NL = 'nl',
}
