import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { MessageService } from 'primeng/api';
import { count } from 'rxjs/operators';
import { Calendar_event } from 'src/app/models/calendar/event';
import { Customer } from 'src/app/models/customer';
import { Expenseitems } from 'src/app/models/expense/expenseItems';
import { Order } from 'src/app/models/orders/order';
import { OrderStatus } from 'src/app/models/orders/orderstatus';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { Product } from 'src/app/models/product';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { Quote } from 'src/app/models/quotes/quote';
import { Supplier } from 'src/app/models/supplier';
import { User } from 'src/app/models/user';
import { MenuService } from 'src/app/shared/menu.service';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { CustomerService } from '../../customers/customer.service';
import { ExpenseService } from '../../expense/expense.service';
import { OrdersService } from '../../orders/orders.service';
import { ProductService } from '../../products/product.service';
import { PurchaseOrdersService } from '../../PurchaseOrders/PurchaseOrders.service';
import { QuotesService } from '../../quotes/quotes.service';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { SharedService } from '../../shared/shared.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent extends BaseComponent implements OnInit {
  order: Order
  selected: any
  selectedIndex: number
  user: User
  LangStats = new OrderStatus()
  loadingbtnPrint: boolean
  loadingbtnemail: boolean
  loadingbtnPrint_d: boolean
  currentModal;
  Invoice: Order[]
  quotes: Quote[]
  orders: Order[]
  events: Calendar_event[]
  expense: Expenseitems[]
  topCustmer: Customer[]
  showSidebar: boolean
  sidebarName: string
  size: SidebarSize
  sidebar_header: string
  topProduct: Product[]
  topProductCount: number
  purchasesCount: number
  invoiced_order_count: number
  all_quote_counts: number
  all_order_counts: number
  purchases: any[]
  modalTitle = 'New '
  statusTitle: string
  customerPaymentsLsit: Customer[]
  SuppliersPaymentsList: Supplier[]
  cashPostion: number
  removedialoguee: boolean
  lastInvoiced_order: string
  disableUnlockInvoice: boolean
  loadingbtnupload = false;
  public removeDialogueeQ = false;
  langOrderOpctions = [];
  expenseCounts: number
  outCustomerPayCount: number
  outCustomerPay: any[]
  outSuppliersPayCount: number
  outSuppliersPay: any[]
  salesRegionLabels: any[] = []
  salesRegionTotals: any[] = []
  salesBrandsLabels: any[] = []
  salesBrandsTotals: any[] = []
  CashInMonths: any[] = []
  CashInTotals: any[] = []
  CashOutMonths: any[] = []
  CashOutTotals: any[] = []
  langOpctions = [];
  langOpctions_O = []
  whatsappUrl:string

  public products = [
    { title: 'Product 1', date: '04/05/2020' },
    { title: 'Test Product 2', date: '04/05/2020' },
    { title: 'consectetur adipisicing elit', date: '04/05/2020' },
    { title: 'aperiam atque culpa', date: '04/05/2020' },
    { title: 'molestias odit', date: '04/05/2020' },
    { title: 'Lorem ipsum dolor', date: '04/05/2020' },
    { title: 'Product 1', date: '04/05/2020' },
    { title: 'Test Product 2', date: '04/05/2020' },
    { title: 'consectetur adipisicing elit', date: '04/05/2020' },
    { title: 'aperiam atque culpa', date: '04/05/2020' },
    { title: 'molestias odit', date: '04/05/2020' },
    { title: 'Lorem ipsum dolor', date: '04/05/2020' },
  ];

  dropdownYears: any[];
  deliveryNotelangOpctions = [];

  selectedYear: any;
  lineChartData: any;
  saleByRegionBar: any;
  saleByBrandsBar: any;
  cashInLine: any;
  productsTimeline: any;
  itemsSave = []
  lineChartOptions: any;
  barChartOptions: any;
  showDialogView: string
  inoivceViewer = false;

  dueCustomerPaymentsCount: number
  dueVendorsPaymentsCount: number
  getEventsCounts: number
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750,
    },
    allowedExtensions: ["JPG", "JPEG ", "PnG", "Pdf"],
  };
  @ViewChild("upload") upload: ElementRef;
  @ViewChild('chart') chart: any;
  @ViewChild('BrandChart') BrandChart: any;
  @ViewChild('RegionChart') RegionChart: any;



  constructor(private dashboardService: DashboardService
    , public messageService: MessageService, private customersService: CustomerService
    , private sharedService: SharedService, private quotesService: QuotesService,
    private ordersService: OrdersService, private productsService: ProductService,
    private purchaseOrdersService: PurchaseOrdersService,
    public translate: TranslateService, private datePipe: DatePipe,
    private menuService: MenuService,
    private router: Router, public expenseService: ExpenseService
  ) {
    super();
  }

  ngOnInit() {

    this.lineChartOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };

    this.productsTimeline = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Product 1',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#17a2b8'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Product 2',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.barChartOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#ebedef'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#ebedef'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        },
        y: {
          ticks: {
            color: '#ebedef'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        }
      }
    };
    this.dropdownYears = [
      { label: '2021', value: 2021 },
      { label: '2022', value: 2022 },
      { label: '2023', value: 2023 },
    ];

    this.itemsSave = [
      {
        label: 'Save & Print', icon: 'fas fa-print', command: () => {
          this.saveOrder()
          // this.print_order()
        }
      },
      {
        label: 'Save & Email', icon: 'far fa-envelope', command: () => {
          this.saveOrder()
          this.sendByEmail_order()
        }
      },
      {
        label: 'Save & Invoice', icon: 'fas fa-file-invoice', command: () => {

          this.saveOrder()
          this.convertInvoice()

        }
      },

    ]

    this.selectCurrentYear()
    this.topProducts()
    this.getOrdersInvoice()
    this.getQuotes()
    this.outstandingCustomerPayments()
    this.outstandingSuppliersPayments()
    this.getOrders()
    this.getOutstandingPurchases()
    this.topCustomer()
    this.subscribeOnduplicatOrder()
    this.expenseCount()
    this.dueCustomerPayments()
    this.getCashPostion()
    this.dueVendorsPayments()
    this.getEventsCount()
    this.getSalesByRegion()
    this.getSalesByBrands()
    this.refreshShapes()
    this.emailbtnLoading_q()
    this.emailbtnLoading()
    this.subscribeOnduplicatQuote()
    setTimeout(() => {
      this.skipTutorial()
      this.CashInLine()
      this.CashOutLine()
    }, 1000);

    this.langOpctions = [
      {
        label: 'EN', command: () => {

          this.quotesService.printLang = 'en'
          this.print_Qoute()
        }
      },
      {
        label: 'FR', command: () => {

          this.quotesService.printLang = 'fr'

          this.print_Qoute()
        }
      },
      {
        label: 'NL', command: () => {

          this.quotesService.printLang = 'nl'

          this.print_Qoute()
        }
      },
    ]
    this.langOpctions_O = [
      {
        label: 'EN', command: () => {

          this.ordersService.printLang = 'en'
          this.printOrder_form(this.selected.id)
        }
      },
      {
        label: 'FR', command: () => {

          this.ordersService.printLang = 'fr'

          this.printOrder_form(this.selected.id)
        }
      },
      {
        label: 'NL', command: () => {

          this.ordersService.printLang = 'nl'

          this.printOrder_form(this.selected.id)
        }
      },
    ]
    this.deliveryNotelangOpctions = [
      {
        label: 'EN', command: () => {

          this.ordersService.printLang = 'en'
          this.printDeliverNote_form(this.selected.id)
        }
      },
      {
        label: 'FR', command: () => {

          this.ordersService.printLang = 'fr'

          this.printDeliverNote_form(this.selected.id)
        }
      },
      {
        label: 'NL', command: () => {

          this.ordersService.printLang = 'nl'

          this.printDeliverNote_form(this.selected.id)
        }
      },
    ]
    this.langOrderOpctions = [
      {
        label: "EN",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "en";
          this.printPurchaseOrders_form(this.selected.id)
        },
      },
      {
        label: "FR",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "fr";
          this.printPurchaseOrders_form(this.selected.id)

        },
      },
      {
        label: "NL",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "nl";
          this.printPurchaseOrders_form(this.selected.id)

        },
      },
    ];
  }



  getOrdersInvoice() {
    const subscription = this.dashboardService.getOuststandingInvoice().subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }
      this.invoiced_order_count = orders.count
      this.Invoice = Order.cloneManyObjects(orders.results);
      subscription.unsubscribe()


    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()

    })
  }
  getQuotes() {
    const subscription = this.dashboardService.getOuststandingQoute().subscribe((qoutes: PaginatedResponse<Quote[]>) => {
      if (!isSet(qoutes)) {
        return;
      }
      this.all_quote_counts = qoutes.count
      this.quotes = Quote.cloneManyObjects(qoutes.results);
      subscription.unsubscribe()



    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  outstandingCustomerPayments() {
    const subscription = this.dashboardService.getOuststandingCustomerPayments().subscribe((data: PaginatedResponse<any[]>) => {
      if (!isSet(data)) {
        return;
      }
      this.outCustomerPayCount = data.count
      this.outCustomerPay = data.results
      subscription.unsubscribe()
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  outstandingSuppliersPayments() {
    const subscription = this.dashboardService.getOuststandingVendorsPayments().subscribe((data: PaginatedResponse<any[]>) => {
      if (!isSet(data)) {
        return;
      }
      this.outSuppliersPayCount = data.count
      this.outSuppliersPay = data.results


      subscription.unsubscribe()



    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  getOrders() {
    const subscription = this.dashboardService.getOrders().subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }

      this.all_order_counts = orders.count
      this.orders = Order.cloneManyObjects(orders.results);
      subscription.unsubscribe()
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  topCustomer() {
    const subscription = this.dashboardService.getTopCustomers().subscribe((customer: PaginatedResponse<Customer[]>) => {
      if (!isSet(customer)) {
        return;
      }
      let slicedArray = Customer.cloneManyObjects(customer.results);
      this.topCustmer = slicedArray.slice(0, 10);
      subscription.unsubscribe()
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  topProducts() {
    const subscription = this.dashboardService.getTopPro().subscribe((pro: PaginatedResponse<Product[]>) => {
      if (!isSet(pro)) {
        return;
      }

      this.topProduct = Product.cloneManyObjects(pro.results);



      subscription.unsubscribe()
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }
  downloadPdf() {
    window.location.href = this.selected.download_url;
  }
  getOutstandingPurchases() {
    const subscription = this.dashboardService.getPurchases().subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }
      this.purchasesCount = orders.count
      this.purchases = Order.cloneManyObjects(orders.results);
      subscription.unsubscribe()
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      subscription.unsubscribe()
    })
  }

  displayitem(item: any, itemName: string) {
    const _item = {
      item,
      itemName
    }
    this.sharedService.diplayDash.next(_item)
    this.selected = item
    this.sidebarName = itemName
    if (this.sidebarName == 'Order') {
      this.modalTitle = item.format
      this.selected = Order.cloneObject(item)
      if (this.selected?.status?.invoiced) {
        this.getlast_invoiced_order()
      }
      this.sidebar_header = this.selected.status?.invoiced ? this.translate.instant('DASHBORD_INVOICE') + ': ' : this.translate.instant('DASHBORD_ORDER')+`: `
          this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
      <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`


      this.size = SidebarSize.X_LARGE

    } else if (this.sidebarName == 'Quotes') {
      this.modalTitle = item.format
      this.sidebar_header = this.translate.instant('DASHBORD_QUOTE') + `: `
      this.selected = Quote.cloneObject(item)
          this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> `
      this.size = SidebarSize.LARGE

    } else if (this.sidebarName == 'Product') {
      this.modalTitle = item.name
      this.sidebar_header = this.translate.instant('DASHBORD_PRODUCT') + `: `
      this.selected = Product.cloneObject(item)
      this.statusTitle = null
      this.size = SidebarSize.X_LARGE
    } else if (this.sidebarName == 'Custmoer') {
      this.modalTitle = item.company_name
      this.sidebar_header = this.translate.instant('CUSTOMER_PROF')
      this.selected = Customer.cloneObject(item)
      this.size = SidebarSize.X_LARGE
      this.statusTitle = null
    }else if (this.sidebarName == 'Expense') {
      this.modalTitle = item.format
      this.sidebar_header = this.translate.instant('EXPENSE')+ `: `
      this.selected = Expenseitems.cloneObject(item)
      this.size = SidebarSize.X_LARGE
      this.statusTitle = null
    } else if (this.sidebarName == 'Purchases') {
      this.selected = PurchaseOrders.cloneObject(item)
      this.modalTitle = item.internal_reference
      this.sidebar_header = this.translate.instant('PUR_ORDER') + ` : `
          this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`
      this.size = SidebarSize.X_LARGE
    }
    setTimeout(() => {
      this.showSidebar = true
    }, 100);
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  /// order functions
  create_creditnote() {
    this.ordersService.orderToCredit.next(true)
  }
  sendByEmail_order() {
     this.ordersService.sendEmailClicked.next(true)
     this.ordersService.printOrderLink.unsubscribe()
     
  }
 


  emailbtnLoading() {
    const subscription = this.ordersService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false
      }
      if (status == true) {
        return this.loadingbtnemail = true
      }
      this.ordersService.btnLoading.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  onDropdownClick_language(rowData: Order, i: number) {

  }
  printDeliverNote_form(id: string) {
    this.outside_Status(true)
    this.loadingbtnPrint_d = true;

    const subscription = this.ordersService.printDeliverNote(id).subscribe((order: any) => {
      if (!isSet(order)) {
        return;
      }
      this.ordersService.printOrderLink.next(order)
      subscription.unsubscribe();
      this.loadingbtnPrint_d = false;
      this.outside_Status(false)
    }, error => {
      subscription.unsubscribe();
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      this.loadingbtnPrint_d = false;
    });

  }
  printOrder_form(id: string) {
    this.outside_Status(true)

    this.loadingbtnPrint = true;

    const subscription = this.ordersService.PrintOrder(id).subscribe((order: any) => {
      if (!isSet(order)) {
        return;
      }

     
      
      this.ordersService.printOrderLink.next(order)


      subscription.unsubscribe();
      this.loadingbtnPrint = false;

    }, error => {
      subscription.unsubscribe();
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      this.loadingbtnPrint = false;
    });

  }

  duplicateOrder() {
    this.ordersService.duplicateClicked.next(true)
  }
  subscribeOnduplicatOrder() {
    this.loading = true
    setTimeout(() => {
      const subscription = this.ordersService.duplicateClickedEmitter.subscribe((status: boolean) => {
        if (!isSet(status) || !status) {
          return
        }
        this.ordersService.duplicateClicked.next(null)
        this.showduplicat()
      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);
      this.loading = false
    }, 500);
  }
  showduplicat() {
    this.ordersService.selectOrder(null);
    this.sharedService.Create_form(true)
    const format = this.selected?.format
    this.selected = null;
    this.sidebar_header = this.translate.instant('ORDERS_LIST_DUPLICATE_ORDER') + " : "
    this.modalTitle = format

    this.statusTitle = null
    this.showSidebar = true;
  }
  upadteFormClicked_order() {
    this.ordersService.updateFormClicked.next(true)
    this.refreshSelectedOrder()
  }
  saveOrder() {
    this.ordersService.saveForm();
    const subscription = this.ordersService.retrieve_lastOrderEmitter.subscribe((order: Order) => {
      if (!isSet(order)) {
        return
      }
      this.sidebar_header = this.translate.instant('Order') + ` : `
      this.modalTitle = order.format
      order.status.draft = true
      this.selected = Order.cloneObject(order)
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status?.status?.label)}</span> /
      <span  style="margin-right: 5px;" Class="${this.selected?.status?.status?.paid_color}">${this.translate.instant(this.selected?.status?.status?.paid_status)}</span>`
      this.ordersService.retrieve_lastOrder.next(null)
      this.getOrdersInvoice()

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }
  updatePurchase() {
    this.purchaseOrdersService.upadteFormClicked.next(true)
  }
  UpdatePro() {
    this.productsService.upadteFormClicked.next(true)
  }
  getlast_invoiced_order() {
    const subscription = this.ordersService.getlast_invoiced_order().subscribe((order: Order) => {
      this.lastInvoiced_order = order.id
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
      this.loading = false
    });
  }
  convertInvoice() {
    this.ordersService.ConvertInvoiceClicked.next(true)
    this.refreshSelectedOrder()
  }

  unlockinvoiced() { this.ordersService.unlockInovice.next(true) }

  disableunlockInoviceEmitter() {
    const subscription = this.ordersService.disableUnlockInvoiceEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        this.disableUnlockInvoice = false
      }
      if (status == true) {
        this.disableUnlockInvoice = true
      }
      this.subscriptions.push(subscription);
    }, error => {
      this.subscriptions.push(subscription);
      this.handleError(error);
    });
  }
  converttoDraft() {
    this.ordersService.convertDraft.next(true)
    this.refreshSelectedOrder()
  }
  deleteOrders() {

    try {
      this.loading = true;
      const subscription = this.ordersService.orderdelete(this.selected.id).subscribe(() => {
        this.loading = false;
        setTimeout(() => {
          this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: this.translate.instant('DASHBORD_OUSTANDING_ORDERS') + this.selected?.format + this.translate.instant('DELETED') });
        }, 1000);
        this.getOrders();
        subscription.unsubscribe();
      }, error => {
        this.loading = false;
        if (isSet(this.selected)) {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        }
        subscription.unsubscribe();
        this.sharedService.dialog_status.next(false)
      })
    }
    catch (error) {
      this.log(error);
    }

    this.removedialoguee = false
    this.showSidebar = false
  }
  getOrder_refresh() {
    const subscription = this.ordersService.getOrder(this.selected?.id).subscribe((order: Order) => {
      if (!isSet(order)) {
        return;
      }
      const cloneObject = Order.cloneObject(order);
      this.selected = cloneObject
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status?.status?.label)}</span> /
      <span  style="margin-right: 5px;" Class="${this.selected?.status?.status?.paid_color}">${this.translate.instant(this.selected?.status?.status?.paid_status)}</span>`
      this.getOrdersInvoice()
      subscription.unsubscribe();
    }, error => {
      console.log(error);
      subscription.unsubscribe();
    });
  }
  refreshSelectedOrder() {
    const subscription = this.ordersService.refreshSelectedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.getOrder_refresh()
      this.ordersService.refreshSelected.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }
  convert() { this.quotesService.Converttoroeder_buuton(true) }
  convertt(e) {
    if (!isSet(e)) {
      return
    }
    this.showSidebar = false
    this.quotesService.selectQuote(null);
    setTimeout(() => {
      this.quotesService.convertToOrder(this.quotesService.currentOrder)
    }, 1000)


  }

  deleteQuotes() {

    try {
      this.loading = true;
      const subscription = this.quotesService.deleteQuotes(this.selected.id).subscribe(() => {
        this.loading = false;
        setTimeout(() => {
          this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: ' Quote ' + this.selected?.format + this.translate.instant('DELETED') });
        }, 1000);
        this.getQuotes();
        subscription.unsubscribe();
      }, error => {
        this.loading = false;
        if (isSet(this.selected)) {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        }
        subscription.unsubscribe();
        this.sharedService.dialog_status.next(false)
      })
    }
    catch (error) {
      this.log(error);
    }

    this.removedialoguee = false
    this.showSidebar = false
  }
  Sendemail() { this.quotesService.sendEmail_buuton(true) }
  emailbtnLoading_q() {
    const subscription = this.quotesService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false

      }
      if (status == true) {
        return this.loadingbtnemail = true

      }
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
    
  }

  print_Qoute() {
    this.loadingbtnPrint = true;
    const subscription = this.quotesService.PrintQoute(this.selected.id).subscribe((Quote: any) => {
      if (!isSet(Quote)) {
        return;
      }

      this.quotesService.printqoute_link.next(Quote)
      subscription.unsubscribe();
      this.loadingbtnPrint = false;
    }, error => {
      subscription.unsubscribe();
      this.loadingbtnPrint = false;
    })
  }
  duplicateQoute() {
    this.quotesService.duplicateClicked.next(true)
  }
  private subscribeOnduplicatQuote() {
    this.loading = true
    setTimeout(() => {
      const subscription = this.quotesService.duplicateClickedEmitter.subscribe((status: boolean) => {
        if (!isSet(status) || !status) {
          return
        }
        const format = this.selected?.format
        this.selected = null;
        this.quotesService.duplicateClicked.next(null)
        this.quotesService.selectQuote(null);
        this.sharedService.Create_form(true)
        this.sidebar_header = this.translate.instant('DUPLI_QUOTE')
        this.modalTitle = format
        this.statusTitle = null
        this.showSidebar = true;
        this.getQuotes()
      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);
      this.loading = false

    }, 500);


  }
  UpdateQoute() { this.quotesService.upadteFormClicked.next(true) }
  saveQoute() {
    this.quotesService.saveForm();
    const subscription = this.quotesService.retrieve_lastOrderEmitter.subscribe((quote: Quote) => {
      if (!isSet(quote)) {
        return
      }
      this.selected = Quote.cloneObject(quote)
      this.sidebar_header = this.translate.instant('QUOTE') + ` : `
      this.modalTitle = quote.format
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected.status.status?.color}">${this.translate.instant(this.selected.status.status?.label)}</span> `
      this.quotesService.retrieve_lastOrder.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }

  UpadteCustmoer() { this.customersService.upadteFormClicked.next(true) }

  showConfirm() {
    this.messageService.clear();
    this.messageService.add({ key: 'first', sticky: true, severity: 'info', closable: false, life: 3000 });
  }

  onConfirm() {
    const id = JSON.parse(localStorage.getItem('user'))
    const skipToturial = {
      user_id: id.id,
      skip: false
    }
    this.messageService.clear('first');
    this.messageService.add({ key: 'confirm', closable: false, severity: 'info', life: 5000 });
    this.dashboardService.openSettings.next(true)
    const subscription = this.dashboardService.skipTutorial(skipToturial).subscribe(check => {
      if (!isSet(check)) {
        return
      }
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
      this.loadingbtnPrint = false;
    })
  }

  onReject() {
    this.messageService.clear();
    this.messageService.add({ key: 'reject', severity: 'info', closable: false });
  }

  skipTutorial() {
    this.dashboardService.toturialEmitter.subscribe(skip => {
      if (skip == true) {
        return this.showConfirm()
      }
    })
  }
  filterDashboard(type: any) {
    if (type == 'invoices') {
      let body = {
        status: 'invoice',
        data: this.Invoice
      }
      this.dashboardService.displayFilter.next(this.Invoice)
      this.router.navigateByUrl('/sell/orders')
    }
    if (type == 'orders') {
      let body = {
        status: 'order',
        data: this.orders
      }
      this.dashboardService.displayFilter.next(this.orders)
      this.router.navigateByUrl('/sell/orders')
    }
    if (type == 'quotes') {
      this.dashboardService.displayFilter.next(this.quotes)
      this.router.navigateByUrl('/sell/quotes')
    }
    if (type == 'purchase') {
      this.dashboardService.displayFilter.next(this.purchases)
      this.router.navigateByUrl('buy/purchaseorders')
    }
  }
  showUploadInovice() { this.upload.nativeElement.click(); }
  srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  generate_download() {
    try {
      this.loading = true;
      this.expenseService
        .generate_download(
          this.selected?.invoice_attachment?.path
        )
        .subscribe((link) => {
          (this.selected.download_url = link?.download_url),
            (this.selected.viewImg = link?.view_url),
            (this.loading = false);
        },
          (error) => {
            this.loading = false;
            this.handleError(error);
          }
        );
    } catch (error) {
      this.log(error);
    }
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true;
    } else {
      return false;
    }
  }
  myUploader(imageResult: ImageResult) {
    this.loadingbtnupload = true;
    let _file =
      (imageResult.resized && imageResult.resized.dataURL) ||
      imageResult.dataURL;
    this.srcToFile(_file, imageResult.file.name, imageResult.file.type).then(
      (file) => {
        const generatUpload = {
          file_type: "user_report",
          file_path: file.name,
        };

        this.purchaseOrdersService.generate_Upload(generatUpload);

        this.purchaseOrdersService.upload(file);

        try {
          this.purchaseOrdersService.uploadstuatsEmitter.subscribe(
            (status) => {
              if (!isSet(status) || !status) {
                return;
              }
              if (status === true) {
                this.selected.invoice_attachment = {
                  name: file.name,
                  path: this.purchaseOrdersService.attachmentlink,
                  type: file.type,
                };
                this.loadingbtnupload = false;

                this.purchaseOrdersService.uploadInovice.next(
                  this.selected?.invoice_attachment
                );
                return
              } else if (status === false) {
                this.messageService.add({
                  key: "form",
                  severity: "error",
                  summary: this.translate.instant('ERROR'),
                  detail: this.translate.instant('NETWORK_ERROR'),
                });
              }
            },
            (error) => {
              this.loading = false;
              this.handleError(error);
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    );
  }
  showViewInoivce() {
    this.showDialogView = 'invoice'
    this.inoivceViewer = true;

    this.generate_download();
  }
  removeInvoice_attachmet() {
    this.purchaseOrdersService.uploadInovice.next('delete');
    this.selected.invoice_attachment = null
    this.inoivceViewer = false
    this.loadingbtnupload = false
    this.purchases[this.selectedIndex].invoice_attachment = null

  }
  duplicatePurchase() {
    this.sidebar_header = this.translate.instant('DUP_OUR_ORDER') + ' : ';
    const format = this.selected?.internal_reference
    this.modalTitle = format
    this.selected = null;
    this.statusTitle = null
    this.purchaseOrdersService.duplicateClicked.next("Order");

  }
  savePurchase() {
    this.purchaseOrdersService.saveForm();
    const subscription = this.purchaseOrdersService.retrieve_lastOrderEmitter.subscribe(
      (purchaseOrders: PurchaseOrders) => {
        if (!isSet(purchaseOrders)) {
          return;
        }
        this.sidebar_header = this.translate.instant('PUR_ORDER') + ` : `

        this.modalTitle = purchaseOrders.internal_reference
        this.selected = PurchaseOrders.cloneObject(purchaseOrders)
        this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected.status.status?.color}">${this.translate.instant(this.selected.status.status?.label)}</span> /
        <span  style="margin-right: 5px;" Class="${this.selected.status.status?.paid_color}">${this.translate.instant(this.selected.status.status?.paid_status)}</span>`;

        this.purchaseOrdersService.retrieve_lastOrder.next(null);

      },
      (error) => {
        this.handleError(error);
      }
    );

    this.subscriptions.push(subscription);
  }
  public sendByEmail() {
    this.purchaseOrdersService.sendEmailClicked.next(
      "Order"
    );
   
  }

  printPurchaseOrders_form(id: any) {
    this.loadingbtnPrint = true;
    try {
      const sub = this.purchaseOrdersService.PrintOrder(id).subscribe(
        (Order: any) => {
          if (!isSet(Order)) {
            return;
          }

          this.showDialogView = 'pdf'
          this.selected.download_url = Order?.download_url;
          this.selected.view_url = Order?.view_url;
          this.getShortUrll(Order?.download_url,Order?.file_name)
          this.loadingbtnPrint = false;

          this.inoivceViewer = true;

          sub.unsubscribe();
          this.loadingbtnPrint = false;

        },
        (error) => {
          this.messageService.add({
            key: "form",
            severity: "error",
            summary: "Error",
            detail: error.error.detail,
          });
          this.loadingbtnPrint = false;
        }
      );
    } catch (error) {
      console.log(error);
      this.loadingbtnPrint = false;
    }
  }
  deleteseletedPO() {
    this.removeDialogueeQ = true;
  }
  removePurchaseOrders() {
    try {
      this.loading = true;
      const subscription = this.purchaseOrdersService
        .deletePurchaseOrders(this.selected.id)
        .subscribe(
          () => {
            this.loading = false;
            setTimeout(() => {
              this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: this.translate.instant('DASHBORD_OUSTANDING_ORDERS') + this.selected?.format + this.translate.instant('DELETED') });
            }, 1000);
            this.getOutstandingPurchases();
            subscription.unsubscribe();
          },
          (error) => {
            console.log(error);
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('ERROR'),
              detail:
                this.translate.instant('ERR_OCCEURRED_DELETE') + this.selected?.internal_reference,
            });
            this.loading = false;
            subscription.unsubscribe();
          }
        );
    } catch (error) {
      console.log(error);
      this.log(error);
    }
    this.removeDialogueeQ = false;
    this.showSidebar = false
  }
  expenseCount() {
    const subscription = this.expenseService.getExpenses().subscribe((expense: PaginatedResponse<Expenseitems[]>) => {
      if (!isSet(expense)) {
        return;
      }
      this.expense = Expenseitems.cloneManyObjects(expense.results)
      this.expenseCounts = expense.count
      subscription.unsubscribe()
    }, error => {
      subscription.unsubscribe()
      console.log(error);
    });
    this.subscriptions.push(subscription);
  }
  dueCustomerPayments() {
    const subscription = this.dashboardService.dueCustomerPayments().subscribe((customer: PaginatedResponse<Customer[]>) => {
      if (!isSet(customer)) {
        return;
      }
      console.log(customer);

      this.dueCustomerPaymentsCount = customer.count
      this.customerPaymentsLsit = Customer.cloneManyObjects(customer.results)
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  getCashPostion() {
    const subscription = this.dashboardService.cashPostion().subscribe(cash => {
      if (!isSet(cash)) {
        return;
      }
      this.cashPostion = cash
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  dueVendorsPayments() {
    const subscription = this.dashboardService.dueVendorsPayments().subscribe((vendor: PaginatedResponse<Supplier[]>) => {
      if (!isSet(vendor)) {
        return;
      }
      this.dueVendorsPaymentsCount = vendor.count
      this.SuppliersPaymentsList = Supplier.cloneManyObjects(vendor.results)
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  getEventsCount() {
    const subscription = this.dashboardService.getEventsCount().subscribe((erp: PaginatedResponse<Calendar_event[]>) => {
      if (!isSet(count)) {
        return;
      }
      this.events = Calendar_event.cloneManyObjects(erp.results)
      this.events.map((event => {
        event.startP = this.datePipe.transform(event.start, 'yyyy-MM-dd');
        event.endP = this.datePipe.transform(event.end, 'yyyy-MM-dd');
      }))
      this.getEventsCounts = erp.count
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  routing(type) {
    if (type == 'erp') {
      this.router.navigateByUrl('/erp/calendar')
    } else if (type == 'expense') {
      this.router.navigateByUrl('/expense')
    }
  }
  getSalesByRegion() {
    const subscription = this.dashboardService.getSalesByRegion().subscribe(data => {
      if (!isSet(data)) {
        return;
      }
      data.results.map(filter => {
        this.salesRegionLabels.push(filter.region);
        this.salesRegionTotals.push(Number(filter.total));
      })
      this.saleByRegionBar = {
        labels: this.salesRegionLabels,
        datasets: [
          {
            label: this.translate.instant('SALES_REGION'),
            backgroundColor: '#42A5F5',
            data: this.salesRegionTotals
          },
        ]
      };
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  getSalesByBrands() {
    const subscription = this.dashboardService.getSalesByBrand().subscribe(data => {
      if (!isSet(data)) {
        return;
      }
      data.results.map(filter => {
        this.salesBrandsLabels.push(filter.brand);
        this.salesBrandsTotals.push(Number(filter.count));
      })
      this.saleByBrandsBar = {
        labels: this.salesBrandsLabels,
        datasets: [
          {
            label: this.translate.instant('SALES_BRAND'),
            backgroundColor: '#e9506c',
            data: this.salesBrandsTotals
          },
        ]
      };
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  CashInLine() {
    const subscription = this.dashboardService.CashIn().subscribe(data => {
      if (!isSet(data)) {
        return;
      }
      data.results.map(filter => {
        this.CashInMonths.push(filter.month);
        this.CashInTotals.push(Number(filter.total));
      })
      this.cashInLine = {
        labels: this.CashInMonths,
        datasets: [
          {
            label: this.translate.instant('CASHIN'),
            data: this.CashInTotals,
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
          },
          {
            label: this.translate.instant('CASHOUT'),
            data: this.CashOutTotals,
            fill: false,
            borderColor: '#e9506c',
            tension: .4
          }
        ]
      };
      this.chart.refresh();
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  CashOutLine() {
    const subscription = this.dashboardService.CashOut().subscribe(data => {
      if (!isSet(data)) {
        return;
      }
      data.results.map(filter => {
        this.CashOutMonths.push(filter.month);
        this.CashOutTotals.push(Number(filter.total));
      })
      this.cashInLine = {
        labels: this.CashInMonths,
        datasets: [
          {
            label: this.translate.instant('CASHIN'),
            data: this.CashInTotals,
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
          },
          {
            label: this.translate.instant('CASHOUT'),
            data: this.CashOutTotals,
            fill: false,
            borderColor: '#e9506c',
            tension: .4
          }
        ]
      };
      this.chart.refresh();
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  selectCurrentYear() {
    let currentYear = new Date().getFullYear()
    this.selectedYear = currentYear
    this.dashboardService.filterSelectedYear = this.selectedYear
  }
  chooseYearFilter() {
    this.dashboardService.filterSelectedYear = this.selectedYear
    this.CashOutMonths = []
    this.CashOutTotals = []
    this.CashInMonths = []
    this.CashInTotals = []
    this.CashInLine()
    this.CashOutLine()
  }

  refreshShapes() {
    const subscription = this.menuService.refreshShapesEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.salesRegionLabels = []
      this.salesRegionTotals = []
      this.salesBrandsLabels = []
      this.salesBrandsTotals = []
      this.chooseYearFilter()
      this.getSalesByRegion()
      this.getSalesByBrands()
      this.menuService.refreshShapes.next(null)

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }
  public switchModalCustomer(modal: string, item?: any): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.sidebar_header = this.translate.instant('CUSTOMER_PROF')
        this.modalTitle = item.company_name;
        break;
      case 'Dashboard':
        setTimeout(() => {
          this.customersService.selectCustomer(this.selected);

        }, 300);
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Dashboard';
        this.sidebar_header = this.translate.instant('CUSTOMER_DASH');
        this.modalTitle = item.company_name;
        break;
    }
  }

  // sendToWhatsapp(){
  //   window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.whatsappUrl}`, "_blank")
  // }

  // getShortUrl(downloadUrl,fileName){
  //   const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
  //     if (!isSet(url)) {
  //       return
  //     }
  //     this.whatsappUrl=url?.id
  //     subscription.unsubscribe()
  //   },error=>{
  //     console.log(error)
  //     subscription.unsubscribe()
  //   })
  // }

  updateFormClickedExpense() {
    this.expenseService.upadteFormClicked.next(true)
    setTimeout(()=>{
      this.expenseCount()
    },300)
  }

  deleteExpense() {
    try {
      this.loading = true;
        const subscription = this.expenseService.deleteExpense(this.selected.id).subscribe(() => {

            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.selected.format + this.translate.instant('DELETED') });
          this.loading = false;
          this.expenseCount()
          subscription.unsubscribe();
        }, error => {
            this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
          this.loading = false;
          subscription.unsubscribe();

        })
    }
    catch (error) {
      console.log(error)
      this.log(error);
    }
    this.removedialoguee = false
    this.showSidebar = false
  }
  sendToWhatsapp(id?){
    if (!this.selected.whatsappUrl){
      const subscription = this.ordersService.PrintOrder(id).subscribe((order: any) => {
        if (!isSet(order)) {
          return;
        }
          this.selected.downloadUrl = order.download_url
          this.getShortUrll(order.download_url,order.file_name).then(()=>{
            window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selected.whatsappUrl}`, "_blank")
          })
          this.outside_Status(false)
        subscription.unsubscribe();
      }, error => {
        subscription.unsubscribe();
        this.loading=false
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      });
    }else{
      window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selected.whatsappUrl}`, "_blank")
    }
  }
  getShortUrll(downloadUrl,fileName){
    return new Promise((resolve) => {
      const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
        if (!isSet(url)) {
          return
        }
        resolve(this.selected.whatsappUrl=url?.id)
        subscription.unsubscribe()
      },error=>{
        console.log(error)
        subscription.unsubscribe()
      })
    });
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}

