<app-loading *ngIf="loading"></app-loading>`

<div *ngIf="showSidebar==false">
  <div class="p-grid">

    <div class="p-col-12 p-xl-3  p-md-6 pionter">
      <div pRipple (click)="op4.toggle($event)" class="p-grid ripple  p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Quote/qoutes.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary card-text">{{"DASHBORD_OUSTANDING_QUOTES" |translate}}</h5>
          <span class="overview-box-status">{{all_quote_counts}}</span>
        </div>
      </div>

    </div>


    <div class="p-col-12 p-xl-3  p-md-6 pionter">
      <div pRipple (click)="op1.toggle($event)" class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Sell/Invoice.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary card-text">{{"DASHBORD_OUSTANDING_INVOICE" |translate}}</h5>
          <span class="overview-box-status">{{invoiced_order_count}}</span>
        </div>
      </div>



    </div>


    <div class="p-col-12  p-xl-3 p-md-6 pionter">



      <div pRipple (click)="op3.toggle($event)" class="p-grid ripplep  p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Sell/order.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary card-text">{{"DASHBORD_OUSTANDING_ORDERS" |translate}}</h5>
          <span class="overview-box-status">{{all_order_counts}}</span>
        </div>
      </div>


    </div>

    <div class="p-col-12 p-xl-3 p-md-6 pionter">
      <div pRipple (click)="op2.toggle($event)" class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Purchase Order/Outstanding Purchase.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary card-text">{{"DASHBORD_OUSTANDING_CREDITES" |translate}}</h5>
          <span class="overview-box-status">{{purchasesCount}}</span>
        </div>
      </div>


    </div>



  </div>

  <div class="p-grid layout-dashboard">
    <div class="p-col-6">
      <!-- <div class="p-grid p-dir-col p-nogutter">
        <div class="p-col-12 ">
          <div class="card statistics">
            <div class="p-grid">
              <div class="p-col-6 p-md-8">
                <h5>{{"CUSTOMER_DASHBORD_ORDER_TIMELINE" | translate}}</h5>
              </div>
              <div class="p-col-6 p-md-4 dropdown">
                <p-dropdown [options]="dropdownYears" [(ngModel)]="selectedYear"></p-dropdown>
              </div>
            </div>
            <p-chart id="dashboard-chart" type="line" [data]="lineChartData" [options]="lineChartOptions"></p-chart>
          </div>
        </div>
      </div> -->

      <div class="p-grid ">
        <div class="p-col-12 ">
          <div class="card statistics">
            <div class="p-grid">
              <div class="p-col-6 p-md-8">
                <h5 class="p-text-secondary">{{"DASHBORD_MOST_SOLD_PRO" | translate}}</h5>
              </div>
            </div>
            <p-table [value]="mostSolid_Product">
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-col-4">No.</th>
                  <th class="p-col-4">{{"CUSTOMER_FORM_NAME"| translate}}</th>
                  <th class="p-col-4">{{"QUANTITY" | translate}}</th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product let-i="rowIndex">
                <tr>
                  <td class="p-col-4"><a>{{i +1}} </a></td>
                  <td class="p-col-4">{{ product.name }}</td>
                  <td class="p-col-4">{{ product.count }}</td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="1">{{"DASHBORD_NO_PRODUCT"| translate}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>



    </div>
    <div class="p-col-6">
      <div class="p-grid">

        <div class="p-col-12">


          <div class="card statistics">
            <div class="p-grid">
              <div class="p-col-6 p-md-8">
                <h5 class="p-text-secondary">{{"CUSTOMER_DASHBORD_RECENT_ORDERS" | translate}}</h5>
              </div>
            </div>
            <p-table [value]="last_customer_order">
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-col-4">{{"DASHBORD_INVOICE" | translate}}</th>
                  <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
                  <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-order>
                <tr>
                  <td class="p-col-4"><a href="javascript:;" (click)="displayitem(order,'Order')">{{ order.format}}</a>
                  </td>
                  <td class="p-col-4">{{ order.date }}</td>
                  <td class="p-col-4">{{ order.total | number:'1.2-2' }}</td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_ORDERS" | translate}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>


      </div>
    </div>
  </div>

  <p-overlayPanel #op1 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table #outstand_order [value]="invoices" [rows]="30" [paginator]="true" [scrollable]="true"
        [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px" (onPage)="load_outstandinginvoiced($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"DASHBORD_INVOICE" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoice>
          <tr>
            <td class="p-col-4"><a href="javascript:;" (click)="displayitem(invoice,'Order')">{{ invoice.format}}</a>
            </td>
            <td class="p-col-4">{{ invoice.date }}</td>
            <td class="p-col-4">{{ invoice.total | number:'1.2-2'}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_INVOICE" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel id="op2_customerDashbored" #op2 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table [value]="orders" [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"DASHBORD_ORDER" | translate}}</th>
            <th>{{"DASHBORD_DATE" | translate}}</th>
            <th></th>
          </tr>
        </ng-template>


      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel id="op3_customerDashbored" #op3 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table #outstand_order [value]="orders" [rows]="30" [paginator]="true" [scrollable]="true"
        [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px" (onPage)="load_outstandingOrder($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">Order</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td class="p-col-4"><a href="javascript:;" (click)="displayitem(order,'Order')">{{ order.format}}</a></td>
            <td class="p-col-4">{{ order.date }}</td>
            <td class="p-col-4">{{ order.total | number:'1.2-2'}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_ORDERS" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op4 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table #outstand_order [value]="quotes" [rows]="30" [paginator]="true" [scrollable]="true"
        [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px" (onPage)="load_outstandingQoutes($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">Quote</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-quote>
          <tr>
            <td class="p-col-4"><a href="javascript:;" (click)="displayitem(quote,'Quotes')">{{ quote.format}}</a></td>
            <td class="p-col-4">{{ quote.date }}</td>
            <td class="p-col-4">{{ quote.total | number:'1.2-2' }}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_QUOTES" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op7 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table [value]="orders" [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"DASHBORD_INVOICE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td class="p-col-4"><a>{{ order.format}}</a></td>
            <td class="p-col-4">{{ order.company_name }}</td>
            <td class="p-col-4">{{ order.date }}</td>
            <td class="p-col-4">{{ order.sub_total | number:'1.2-2'}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_ORDERS" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op10 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table [value]="products" [style]="{width: '500px'}" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"DASHBORD_INVOICE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td class="p-col-4"><a>{{ order.format}}</a></td>
            <td class="p-col-4">{{ order.company_name }}</td>
            <td class="p-col-4">{{ order.date }}</td>
            <td class="p-col-4">{{ order.sub_total | number:'1.2-2'}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_ORDERS" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>
</div>
<div *ngIf="showSidebar==true">
  <div class="customerdasbored-secnodyheader">
    <div *ngIf="sidebarName=='Order'" class="p-grid">
      <div class="p-col-6 text-align-left">
        <button id="back-button" style="margin-left: -13px; ; margin-right: 3px;  transform: scale(0.8);"
          (click)="showSidebar=false" pButton type="button" icon="pi pi-arrow-left
        " class="p-button-rounded close-Sidebar p-button-sm"></button>

        <span class="my-sidebar-toolbar-title" [innerHTML]='modalTitle'></span>&nbsp;
        <span class="my-sidebar-toolbar-title3" [innerHTML]="header_body"></span>
        <span class="my-sidebar-toolbar-title2" [innerHTML]='statusTitle'></span>

      </div>
      <div class="p-col-6 text-align-right">
        <button *ngIf="selected?.id && !selected?.status?.invoiced && !selected?.status?.cc" (click)="convertInvoice()"
          style="margin-right: 5px;" pButton type="button" label='{{"ORDERS_LIST_CONVERT_INVOICE" | translate}}'
          class="p-button-secondary"></button>

        <button *ngIf="(selected?.status?.invoiced  && selected?.id == lastInvoiced_order ) ||selected?.status?.cc "
          (click)="converttoDraft()" style="margin-right: 5px;" pButton type="button" label='{{"TO_DRAFT" | translate}}' 
          class="p-button-secondary"></button>

        <button *ngIf="selected?.status?.invoiced  && selected?.id != lastInvoiced_order " (click)="unlockinvoiced()"
          style="margin-right: 5px;" pButton type="button" [disabled]="disableUnlockInvoice" label='{{"UNLOCK_INVO" | translate}}'
          class="p-button-secondary"></button>

        <button (click)="removedialoguee = true" *ngIf="selected?.id &&  !selected?.status?.invoiced" pButton pRipple
          type="button" icon="fas fa-trash" [pTooltip]="'Delete ' + selected?.format" tooltipPosition="bottom"
          class="p-button-secondary" style="margin-right: 5px;"></button>


        <button *ngIf="selected?.id" (click)="sendByEmail_order()" pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom"
          style="margin-right: 5px;" pButton type="button" [disabled]="loadingbtnemail"
          [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>

        <button *ngIf="selected?.id" (click)="print_order()" pTooltip='{{"ORDERS_LIST_PRINT_ORDER" | translate}}' tooltipPosition="bottom"
          style="margin-right: 5px;" pButton type="button" [disabled]="loadingbtnPrint"
          [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" class="p-button-secondary"></button>



        <button *ngIf="selected?.id" (click)="upadteFormClicked_order()" style="margin-left: 22px;" pButton
          type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>
        <p-splitButton *ngIf="!selected?.id" label='{{"CUSTOMER_LIST_UPDATE" | translate}}' icon="pi pi-plus" (onClick)="updateOrder()" [model]="itemsSave"
          class="ui-button-raised ui-button-info"></p-splitButton>
      </div>

    </div>
    <!-- <div *ngIf="sidebarName=='Purchases'">
      <button *ngIf="selected?.id" (click)="updatePurchase()" pButton type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
        class="ui-button-raised ui-button-info"></button>

    </div> -->
    <!-- <div *ngIf="sidebarName=='Product'">
      <button *ngIf="selected?.id" pButton type="button" style="margin-right: 5px;" label="Stock"
        class="ui-button-raised ui-button-info p-mr-2"></button>
      <button *ngIf="selected?.id" (click)="UpdatePro()" style="margin-right: 5px;" pButton type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
        class="ui-button-raised ui-button-info"></button>
    </div> -->

    <div *ngIf="sidebarName=='Quotes'" class="p-grid">
      <div class="p-col-6 text-align-left">
        <button id="back-button" style="margin-left: -13px; ; margin-right: 3px;  transform: scale(0.8);"
          (click)="showSidebar=false" pButton type="button" icon="pi pi-arrow-left
        " class="p-button-rounded close-Sidebar p-button-sm"></button>

        <span class="my-sidebar-toolbar-title" [innerHTML]='modalTitle'></span>&nbsp;
        <span class="my-sidebar-toolbar-title3" [innerHTML]="header_body"></span>
        <span class="my-sidebar-toolbar-title2" [innerHTML]='statusTitle'></span>

      </div>
      <div class="p-col-6 text-align-right">
        <button *ngIf="selected?.id" (click)="convert()" style="margin-right: 5px;" icon="pi pi-copy" pButton
          type="button" label='{{"QUOTES_LIST_CONVERT_ORDER" | translate}}' class="p-button-secondary"></button>
        <button *ngIf="selected?.id" (click)="Sendemail()" style="margin-right: 5px;" pButton
          pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" type="button"
          icon="far fa-envelope" class="p-button-secondary"></button>
        <button *ngIf="selected?.id" (click)="print()" [disabled]="loadingbtnPrint"
          [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" pButton type="button" icon="fas fa-print"
          pTooltip='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' tooltipPosition="bottom"
          class="p-button-secondary"></button>
        <button style="margin-left: 22px;" *ngIf="selected?.id" (click)="UpdateQoute()" pButton type="button"
          label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>
      </div>
    </div>

  </div>

  <div style="padding-top: 65px;">
    <app-orders *ngIf="showSidebar && sidebarName=='Order'"></app-orders>
    <app-quote-form *ngIf="showSidebar && sidebarName=='Quotes'"></app-quote-form>
    <app-PurchaseOrders-form *ngIf="showSidebar && sidebarName=='Purchases'"></app-PurchaseOrders-form>
    <app-products-form *ngIf="showSidebar && sidebarName=='Product'"></app-products-form>
    <app-customer-form *ngIf="showSidebar && sidebarName=='Custmoer'"></app-customer-form>
  </div>
</div>


<p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1" appendTo="body"
  [resizable]="false" [draggable]="false" [header]=" 'Delete '+ selected?.format " [(visible)]="removedialoguee"
  [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CON_DELETE_ORDERS" | translate}} ?
  </div>

  <div *ngIf="this.selected?.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span>{{selected?.format}} ?</span>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deleteOrders()" label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialoguee=false" label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}'
      class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>