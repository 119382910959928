import { isSet } from '../../modules/core/util';


export class Summary {
  total: number;
  total_vat: number; 
  sub_total : number
  count:number
  vat: any[]

  public constructor(params?: Summary ) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Summary) {
    if (!isSet(objectToClone)) {
      return new Summary();
    }
    const obj = new Summary(objectToClone);
    return obj
  }




 





}


