import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { Dropdown } from 'primeng/dropdown';
import { Product } from '../../../models/product';
import { ProductService } from '../../products/product.service';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import * as _ from 'lodash';
import { SharedService } from '../shared.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Vat_Product } from 'src/app/models/vat-product';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-select',
  templateUrl: './products-select.component.html',
  styleUrls: ['./products-select.component.scss']
})
export class ProductsSelectComponent extends BaseComponent implements OnInit {

  @ViewChild('dd') dd: Dropdown;
  @ViewChild('selectItem') selectItem: ElementRef;

  @Input() product: Product = new Product()
  @Input() disabled: boolean;
  @Input() width: any;
  inline: string
  showaddinlineProduct: boolean
  @Output() productChange = new EventEmitter<Product>();

  @Output() onHide = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<Product>();
  @Output() inlineProduct = new EventEmitter<String>();
  @Output() service = new EventEmitter<String>();

  public vat_Percentage :Vat_Product[] = [];

  addProductLoading: boolean;

  displayAddProduct: boolean;
  vatBtnDisabled: boolean;
  miniProduct: Product = new Product();
  products: Product[] = [];
  filteredProducts: Product[] = [];
showreq:boolean = false
serach_dealy:string=null
  constructor(private productService: ProductService,  public translate: TranslateService,
     private router: Router, private sharedService: SharedService,
    public messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProducts();
    this.getVats();

  }

  productChanged($event) {

    const product = $event as Product;

    if (!isSet(product)) {
      return;
    }
    if (product.id === '1') {
      this.product = null;
      this.product = new Product()
      this.showaddinlineProduct = true
      return;
    }
    if (product.id === '0') {
      this.product = null;
      this.showAddMiniProduct("");
      return;
    }

    if (product.id === '-1') {
      this.showAddMiniProduct(product.name);
      return;
    }
    this.filteredProducts.map(item => {
      if (product.id === item.id) {
        this.productChange.emit(product);
        this.onChange.emit(product);
        return;
      }
    })

    this.sharedService.chekcempey.next(true)

  }

  closeMiniProductModal() {
this.showreq=false
    this.product = null;
    this.displayAddProduct = false;
    this.outside_Status(false)
  }

  hide($event) {
    if (this.product.id !== '-1') {
      this.onHide.emit($event);
    }
    this.outside_Status(false)

  }

  addMiniProduct() {
    if (!isSet(this.miniProduct?.sell_price) || !isSet(this.miniProduct?.name) ) {
      this.showreq=true
    return  this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    }
    this.addProductLoading = true;

    const subscription = this.productService.saveMiniProduct(this.miniProduct).subscribe((product: Product) => {
      if (!isSet(product)) {
        return;
      }

      try {

        const cloned = Product.cloneObject(product);

      
        cloned.vat = this.miniProduct?.vat
        this.products.push(cloned);
        // this.dd.optionsToDisplay.push({label: cloned?.name, value: cloned});
        this.filteredProducts.push(this.product);

        setTimeout(() => {
          this.product = cloned;
          this.onChange.emit(this.product);

        }, 200);


      } catch (error) {
        this.log(error);

      } finally {

        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('NEW_PRO_ADDED')
        });
        this.addProductLoading = false;
        this.displayAddProduct = false;
        subscription.unsubscribe();
        this.ngOnInit()

      }
    }, error => {
      this.addProductLoading = false;
      this.displayAddProduct = false;
      subscription.unsubscribe();

    });
    this.outside_Status(false)

  }
  add_service() {
    this.service.emit(this.inline)
    this.showaddinlineProduct = false
    this.sharedService.dialog_status.next(false)

  }
  cancel_service() {
    this.product = new Product()
    this.inline = null
    this.showaddinlineProduct = false
    this.sharedService.dialog_status.next(true)

  }
  private showAddMiniProduct(name: string) {
    this.product = null;

    this.miniProduct = new Product();
    this.miniProduct.vat = new Vat_Product();
    this.miniProduct.name = name.replace('<span class="add-quick-option">', '').replace(' ('+this.translate.instant('ADD_PRO')+')</span>', '')
    setTimeout(() => {
      this.product = new Product();

    }, 200);
    this.displayAddProduct = true;
  }

  private getProducts() {
    if (this.products.length) {
      return
    }
    const subscription = this.productService.getProducts().subscribe((products: PaginatedResponse<Product[]>) => {
      if (!isSet(products)) {
        return;
      }

      this.products = Product.cloneManyObjects(products.results);
      // this.filteredProducts = this.products;
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  searchProducts(value) {

          const subscription = this.productService.searchProducts(value).subscribe((products: PaginatedResponse<Product[]>) => {
console.log(value);

          if (!isSet(products)) {
            return;
          }
    
          this.products = Product.cloneManyObjects(products.results);
          this.filteredProducts = this.products;
    
          if (value.length < 1) {
            const addProduct = new Product();
            addProduct.id = '0';
            addProduct.name = '<span class="add-quick-option">'+this.translate.instant('ADD_PRO')+'</span>'
            const insert = (arr, index, newItem) => [
              ...arr.slice(0, index),
              newItem,
              ...arr.slice(index)
            ]
            this.filteredProducts = insert(this.filteredProducts, 0, addProduct)
      
            const inlineProdct = new Product();
            inlineProdct.id = '1';
            inlineProdct.name = '<span class="add-quick-option">'+this.translate.instant('NEW_SERVICE')+'</span>'
            this.filteredProducts = insert(this.filteredProducts, 1, inlineProdct)
          }
         
          if (value.length > 1) {
    
            const addProduct = new Product();
            addProduct.id = '-1';
            addProduct.name = '<span class="add-quick-option">' + value + ' ('+this.translate.instant('ADD_PRO')+')</span>';
            this.filteredProducts.push(addProduct);
          }
          
          if (products.count==0 ) {
            this.searchProductBarCode(value)
          }
          
          subscription.unsubscribe();
        }, error => {
          subscription.unsubscribe();
        });

     

   
  }

  searchProductBarCode(value) {

    const subscription = this.productService.searchProductsBarcode(value).subscribe((products: PaginatedResponse<Product[]>) => {

      if (!isSet(products)) {
        return;
      }
      if (products.count > 0) {
        
    
      const cloned = Product.cloneObject(products.results[0]);

      this.filteredProducts.push(cloned)

        this.filteredProducts.splice(0, 1)
        this.product = cloned;
        this.onChange.emit(this.product);


      setTimeout(() => {
        this.selectItem.nativeElement.click()
      
      }, 100);
    }
  
    
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }


  private getVats() {
    const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(vats)) {
        return;
      }
      this.vat_Percentage = Vat_Product.cloneManyObjects(vats.results);
      let zeroVat_index = 0
      this.vat_Percentage.map((vat, index) => {
        if (vat.value == 0) {
          vat.value = '0'
          zeroVat_index = index
        }
      })
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.vat_Percentage = insert(this.vat_Percentage, 0, this.vat_Percentage[zeroVat_index])

      this.vat_Percentage.splice(zeroVat_index + 1, 1)
      this.vat_Percentage.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      subscription.unsubscribe();


    }, error => {

      console.log(error);
      subscription.unsubscribe();


    })


  }
  search(event) {
    const query = event.query;

    if (!isSet(this.product?.id)) {
      this.inlineProduct.emit(query);

    }

    this.searchProducts(event.query)


    // this.filteredProducts = this.products.filter((product: Product) => {
    //   return product.name.toLocaleLowerCase().includes(query.toLowerCase());
    // });
 
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
}
