import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseOrdersListComponent } from './PurchaseOrders-list/PurchaseOrders-list.component';

import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import {EditorModule} from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import {TooltipModule} from 'primeng/tooltip';
import {FileUploadModule} from 'primeng/fileupload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import {TieredMenuModule} from 'primeng/tieredmenu';
import { InputNumberModule } from 'primeng/inputnumber';
import {CheckboxModule} from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsModule } from 'primeng/chips';



export const routes: Routes = [
  {path: '', component: PurchaseOrdersListComponent}
];

@NgModule({
  declarations: [PurchaseOrdersListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    TableModule,
    CardModule,CheckboxModule,
    InplaceModule,
    CalendarModule,
    FormsModule,
    ToastModule,ChipsModule,
    InputTextModule,
    DropdownModule,InputNumberModule,TranslateModule,EditorModule,
    InputTextareaModule,SplitButtonModule,TieredMenuModule,
    DialogModule,TagModule,TooltipModule,FileUploadModule,PdfViewerModule,ImageToDataUrlModule
  ]
})
export class PurchaseOrdersModule { }
