import { isSet } from '../modules/core/util';

export class Unit {
  id: string;
  name: string;

  public constructor(params?: Unit) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Unit) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Unit(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Unit[]) {
    const objects: Unit[] = [];
    for (const obj of objectsToClone) {
      objects.push(Unit.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
    return{
      name:this.name
    
    }
      }
}
