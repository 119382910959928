<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-12  p-lg-12">
    <div class="card p-0 pt-1">
      <app-table-header (add)="showAddOrder()" (Reload)='Reload()' (remove)="removedialoguee = true"
        [disableRemove]="!selected.length" addLabel=' {{"SALE_INVO" | translate}}' addLabel2='Invoice Multi Orders'(add2)="gotheringOrders()">
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredProducts" [field_name]="keyword"
          (filter)="serach($event)" (onClear)="clearOrders_queries()" (completeMethod)="completeMethod($event)">
        </app-Search-select>
      </app-table-header>

      <p-table  [customSort]="true" styleClass="p-datatable-striped  p-datatable-responsive-demo"
        [paginator]="true" (onPage)="getNextorderPage($event)" [rows]="10" [rowsPerPageOptions]="[10,30]"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries"
        [value]="orders" [(selection)]="selected"
        [globalFilterFields]="['format','company_name','date','total','status?.status.value']">


        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="format"><span
                [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_QUOTE_NO" | translate}}</span>
              <p-sortIcon (click)="customSort('format')" field="format"></p-sortIcon>



              <p-columnFilter #companyNameFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false"  [showAddButton]="false" type="text" field="company_name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE" | translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('format',filterModeFormat_query?.type,filterModeFormat_query?.value);companyNameFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="format"><span
              [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">
              {{"INVO_FORMAT" | translate}}</span>
            <p-sortIcon (click)="customSort('invoice_format')" field="format"></p-sortIcon>



            <p-columnFilter #invoiceFormateFilter  class="p-ml-2"   matchMode="equals" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false" type="text" field="invoice_format" display="menu">
              <ng-template id="statusFilter" pTemplate="filter">

                <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeinvoice_format_query.type"
                  placeholder='{{"SELECT_FLTER_TYPE" | translate}}' optionValue="type" optionLabel="type">
                  <ng-template let-item pTemplate="item">
                    <span>{{item.type}}</span>
                  </ng-template>
                </p-dropdown>

                <input [(ngModel)]="filterModeinvoice_format_query.value" class="p-mt-3" type="text" pInputText>
                <div class="p-grid p-mt-2">
                  <div class="p-col-6">
                    <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                      type="button">
                    </button>
                  </div>
                  <div class="p-col-6">
                    <button pButton [disabled]="!filterModeinvoice_format_query?.type || !filterModeinvoice_format_query?.value"
                      label='{{"APPLY" | translate}}'
                      (click)="getOrders_queries('invoice_format',filterModeinvoice_format_query?.type,filterModeinvoice_format_query?.value);invoiceFormateFilter.overlayVisible=false"
                      type="button">
                    </button>
                  </div>
                </div>
              </ng-template>

            </p-columnFilter>
          </th>
            <th> <span [style.color]="filterModeApply_Cutstomer?'var(--primary-color)':null"> {{"DASHBORD_CUSTOMER" | translate}}</span>
              <p-columnFilter #comNameFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name">
                <ng-template pTemplate="filter">
                  <app-customers-select *ngIf="filterModeApply_Cutstomer==false" [(customer)]="selected_Cutstomerquery"
                    [filterMode]="true"></app-customers-select>
                  <input *ngIf="filterModeApply_Cutstomer==true" [disabled]="true" [(ngModel)]="selected_Cutstomerquery.company_name"
                    class="p-mt-3" type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries();filterModeApply_Cutstomer=false"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Cutstomerquery?.id" label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('customer',null,selected_Cutstomerquery?.id);filterModeApply_Cutstomer=true;comNameFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th pSortableColumn="date">

              <span
                [style.color]="filterModeDate_query?.type||filterModeDate_query?.value?'var(--primary-color)':null">{{"ORDERS_LIST_ORDER_DATE" | translate}}</span>
              <p-sortIcon (click)="customSort('date')" field="date"></p-sortIcon>

              <p-columnFilter #statusfilter class="p-ml-2" type="date" field="date" matchMode="equals" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDate_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar dateFormat="dd-mm-yy" [showIcon]="true" yearNavigator=true yearRange='2018:2024'
                      [(ngModel)]="filterModeDate_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDate_query?.type||!filterModeDate_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('date',filterModeDate_query.type,filterModeDate_query.value)"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="total">
              <span
                [style.color]="filterModeTotal_query?.type||filterModeTotal_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_TOTAL" | translate}}</span>
              <p-sortIcon (click)="customSort('total')" field="total"></p-sortIcon>
              <p-columnFilter #totalFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeTotal_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0"[(ngModel)]="filterModeTotal_query.value" [minFractionDigits]="2"  mode="decimal" ></p-inputNumber>

                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeTotal_query?.value || !filterModeTotal_query?.type"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('total',filterModeTotal_query?.type,filterModeTotal_query?.value);totalFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>

                </ng-template>



              </p-columnFilter>
            </th>
            <th>
              <span [style.color]="filterModeStatus_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_STATUS" | translate}}</span>
              <p-columnFilter #statusFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">


                  <p-dropdown [options]="statuses" [showClear]="true" [(ngModel)]="filterModeStatus_query.value"
                    placeholder="Select Status" optionLabel="status.value" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label}}</span>


                    </ng-template>

                  </p-dropdown>
                  <p-dropdown styleClass="p-mt-3" [options]="paid_status" [showClear]="true"
                  [(ngModel)]="filterModePaid_status.value" placeholder="Paid Status"
                  (onChange)="paid_Status_queries()" optionLabel="status.label" optionValue="status.value">
                  <ng-template let-statuses pTemplate="item">
                    <span [class]="statuses.status.color">{{statuses.status.label}}</span>


                  </ng-template>

                </p-dropdown>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeStatus_query?.value " label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('status',filterModeStatus_query?.value ,true);statusFilter.overlayVisible=false" type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th>
              {{"QUOTES_LIST_PRINT_ORDER" | translate}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              <!-- (click)="RemoveOrder(rowData)" -->
            </td>
            <td>
              <span class="responsive-th">{{"ORDERS_LIST_ORDER_NO" | translate}}</span>
              <a (click)="showOrder(rowData,i)" href="javascript:;">{{ rowData.format }}</a>
              <!-- <button pButton type="button" icon="pi pi-cog" iconPos="left"  class="p-button-raised p-button-rounded p-button-secondary"></button> -->

            </td>

            <td>
              <span class="responsive-th">Invoice Format</span>
              <span *ngIf="rowData.invoice_format" >{{ rowData.invoice_format }}</span>
              <a *ngIf="!rowData.invoice_format" >ــ</a>

            </td>

            <td>
              <span class="responsive-th">{{"DASHBORD_CUSTOMER" | translate}}</span>
              {{ rowData?.company_name }}
            </td>
            <td>
              <span class="responsive-th">{{"ORDERS_LIST_ORDER_DATE" | translate}}</span>

              {{ rowData.date | date:'dd-MM-yyyy' }}
            </td>
            <td>
              <span class="responsive-th">{{"QUOTES_LIST_TOTAL" | translate}}</span>
              {{ rowData.total | number:'1.2-2' }} <span style="color: var(--primary-color);font-size: small;">{{ rowData?.currency?.name }}</span>
            </td>
            <td>
              <span class="responsive-th">{{"QUOTES_LIST_STATUS" | translate}}</span>

              <span [class]="rowData.status?.status?.color">{{ rowData.status?.status?.label  |translate}}</span>

              /
              <span [class]="rowData.status?.status?.paid_color ">{{ rowData.status?.status?.paid_status |translate}}</span>
            </td>
            <td>
              <p-splitButton label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' [style]="{ height: '36px'}"
                [icon]="rowData.loadprint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint"
                (onClick)="printOrder_list(rowData.id,i)" (onDropdownClick)="onDropdownClick_language(rowData,i)"
                [model]="langOpctions" styleClass="p-button-secondary"></p-splitButton>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">{{"ORDERS_LIST_NO_ORDERS_FOUND" | translate}}</td>
          </tr>
        </ng-template>
        <!-- <ng-template pTemplate="footer">
          <p-paginator
          [rows]="10"
          [totalRecords]="ordersCount"
          currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries"
          [rowsPerPageOptions]="[10,30,60]"  ></p-paginator>
      </ng-template> -->

      </p-table>
    </div>
  </div>
</div>
<!-- (click)="RemoveOrder(rowData)" -->
<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [title2]="orderstatusTitle"
  [size]="size">
  <div header class="customerMedia">
    <!-- 'fas fa-comment-dollar'  -->
    <button *ngIf="selectedOrder?.id && selectedOrder?.status?.invoiced " (click)="create_creditnote()"
      style="margin-right: 5px;" pButton icon="icon-stock-change" [disabled]="loadingbtnPrint || loadingbtnPrint_d" type="button" label='{{"CREATE_CREDIT_NOTE" | translate}}'
      class="p-button-secondary"></button>

    <button *ngIf="selectedOrder?.id && !selectedOrder?.status?.invoiced && !selectedOrder?.status?.cc"
      (click)="convertInvoice()" style="margin-right: 5px;" [disabled]="loadingbtnPrint || loadingbtnPrint_d" pButton type="button" label='{{"ORDERS_LIST_CONVERT_INVOICE" | translate}}'
      class="p-button-secondary"></button>

    <button
      *ngIf="(selectedOrder?.status?.invoiced  && selectedOrder?.id == lastInvoiced_order ) ||selectedOrder?.status?.cc "
      (click)="converttoDraft()" [disabled]="loadingbtnPrint || loadingbtnPrint_d" style="margin-right: 5px;" pButton type="button" label='{{"TO_DRAFT" | translate}}'
      class="p-button-secondary"></button>

    <button *ngIf="selectedOrder?.status?.invoiced  && selectedOrder?.id != lastInvoiced_order "
      (click)="unlockinvoiced()" style="margin-right: 5px;" pButton type="button" [disabled]="disableUnlockInvoice"
      label='{{"UNLOCK_INVO" | translate}}' class="p-button-secondary"></button>


    <!-- [disabled]="disableRemove" -->

    <!-- <button *ngIf="selectedOrder?.id" (click)="printOrder_form(selectedOrder?.id)" pTooltip='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' tooltipPosition="bottom"
      style="margin-right: 5px;" pButton type="button" [disabled]="loadingbtnPrint"
      [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" class="p-button-secondary"></button> -->


      <p-splitButton label='{{"DELIVETY_NOTE" | translate}}' *ngIf="selectedOrder?.id"
      [icon]="loadingbtnPrint_d ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint || loadingbtnPrint_d"
      (onClick)="printDeliverNote(selectedOrder?.id,orderSelectedIndex)" [model]="deliveryNotelangOpctions"
      styleClass="p-button-secondary" class="p-mx-2"></p-splitButton>

      <p-splitButton label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' *ngIf="selectedOrder?.id"
      [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint || loadingbtnPrint_d"
      (onClick)="printOrder_list(selectedOrder?.id,orderSelectedIndex)" [model]="langOpctions"
      styleClass="p-button-secondary"></p-splitButton>

    <button *ngIf="selectedOrder?.id" (click)="duplicateOrder()" pTooltip='{{"ORDERS_LIST_DUPLICATE_ORDER" | translate}}' tooltipPosition="bottom"
      style="margin-right: 5px; margin-left: 5px;" pButton type="button" [disabled]="loadingbtnPrint || loadingbtnPrint_d" icon="fas fa-clone" class="p-button-secondary"></button>
   
      <button *ngIf="selectedOrder?.id" (click)="sendByEmail_order()"
      pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" 
      pButton type="button" [disabled]="loadingbtnemail"
      [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>

      
      <button type="button" *ngIf="selectedOrder?.id" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"class="p-button-secondary"
      pTooltip="Send Order to Whatsapp" (click)="sendToWhatsapp(selectedOrder?.id)" style="margin-left: 5px;"></button>


      <button pButton style="margin-left: 5px;" (click)="currecy.toggle($event)" [disabled]="selectedOrder?.id" class="p-button-secondary p-mr-2"
      type="button" [label]="selectedCurrancy?.name"> </button>

      <button (click)="deleteseletedorder(selectedOrder)" *ngIf="selectedOrder?.id &&  !selectedOrder?.status?.invoiced"
      pButton pRipple type="button" icon="fas fa-trash" [pTooltip]="'Delete' + selectedOrder?.format"
      tooltipPosition="bottom" class="p-button-secondary" [disabled]="loadingbtnPrint || loadingbtnPrint_d" style="margin-right: 5px;"></button>

    <p-tieredMenu appendTo="body" #currecy [model]="currancyOptions" [popup]="true"></p-tieredMenu>

    <p-splitButton *ngIf="!selectedOrder?.id" label=' {{"CUSTOMER_LIST_SAVE" | translate}} ' icon="pi pi-plus" (onClick)="saveOrder()" [model]="itemsSave"
      class="ui-button-raised ui-button-info"></p-splitButton>



    <button *ngIf="selectedOrder?.id" (click)="upadteFormClicked()" [disabled]="loadingbtnPrint || loadingbtnPrint_d" style="margin-left: 22px;" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-orders *ngIf="showSidebar && typeSidebar=='order'"></app-orders>
    <app-invoice-orders *ngIf="showSidebar && typeSidebar=='invoice' "></app-invoice-orders>
  </div>
</app-sidebar>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1" appendTo="body"
  [resizable]="false" [draggable]="false"
  [header]="this.selected.length == 1 ? 'Delete '+ selected[0]?.format : 'Delete Orders'" [(visible)]="removedialoguee"
  [style]="{width: '30vw'}">
  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> {{"CON_DELETE_ORDERS" | translate}} ?
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.format}} ?</span>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deleteOrders()" label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialoguee=false" label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} '
      class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" 
  [resizable]="false" [draggable]="false" class="p-shadow-1" [header]="selectedOrder?.format" [(visible)]="pdfViewer"
  [style]="{width: '60vw'}">
  <div style=" position: relative;" [style.height.px]="pdfheight">
    <pdf-viewer (after-load-complete)="afterLoadComplete($event)" (on-progress)="pdfLoader($event)"
                [src]="selectedOrder?.viewUrl" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
                [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"></pdf-viewer>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="fas fa-print" (click)="printPdf()" label=' {{"QUOTES_LIST_PRINT_ORDER" | translate}} '></button>
    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()" label=' {{"DOWNLOAD" | translate}} '></button>
    <button type="button" pButton icon="pi pi-envelope" (click)="sendByEmailS()" label=' {{"QUOTES_LIST_SEND_TO_EMAIL" | translate}} '></button>
   
    <!-- ll -->
    <button type="button" pButton icon="fab fa-whatsapp" tooltipPosition="bottom" showDelay="500"
             (click)="sendToWhatsapp()"
            label=' {{"Send to Whatsapp" | translate}}' ></button>
    <button type="button" pButton icon="pi pi-times" (click)="pdfViewer=false" label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}'
      class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" [resizable]="false"
          [draggable]="false" class="p-shadow-1" header='{{"ORDERS_FORM_SEND_BY_EMAIL" | translate}}'
          [(visible)]="sendEmailDialog" [style]="{width: '65vw'}">
  <div class="p-field p-grid">
    <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 ">{{"QUOTES_Form_RECIPIENTS" |
      translate}}</label>
    <div class="p-col">
      <p-chips (onRemove)="onRemovEmail()" addOnBlur='true' (keydown)="addEmailOnSpace($event)" id="Recipients"
               name="send_to_mails" [(ngModel)]="sendEmail.send_to_mails">
        <ng-template let-item pTemplate="item">
          <span>{{item}}</span>
        </ng-template>
      </p-chips>
      <div class="p-mt-2">
        <p-checkbox [binary]="userEmail" [(ngModel)]="userEmail" (onChange)="sendCopyToMyself()"
                    label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>
      </div>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"QUOTES_Form_SUBJECT" | translate}}</label>
    <div class="p-col">
      <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"MESSAGE" | translate}} </label>
    <div #col class="p-col">
      <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
        [style]="{'height':'180px'}"></p-editor>
      <div class="mt-2">
        <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="SaveMessage()"></p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-send" (click)="sendEmails()" label=' {{"QUOTES_Form_SEND" | translate}}'
            icon="far fa-paper-plane"></button>
    <button type="button" pButton icon="pi pi-times" (click)="sendEmailDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>


