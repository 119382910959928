import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { BaseComponent } from '../../core/base/base.component';
import { UserToken } from '../../../models/usertoken';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.scss']
})
export class verifyemailComponent extends BaseComponent implements OnInit {

  public loading = false;
  registration_token:string
  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    public translate: TranslateService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
   this.register_confirmation()



  }

    get_registration_token(){
      var url = window.location
    return new URLSearchParams(url.search).get('token');
    }

  public register_confirmation(): void {
    this.registration_token=this.get_registration_token()
    try {
      this.loading = true;
      const subscription = this.authService.register_confirmation(this.registration_token).subscribe((data: UserToken) => {
        this.loading = false;
        if (!isSet(data)) {
          return;
        }
        localStorage.setItem('user', JSON.stringify(data.user_data));
        localStorage.setItem('token_data', JSON.stringify(data.token_data));
        this.router.navigate(['/']);
      }, error => {
        this.loading = false;
        if (isSet(error.error.detail)) {
          setTimeout(()=>{
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail});

          },1000)
        }
        this.router.navigate(['/']);

        this.log('error', error.error.detail);
      });
      this.subscriptions.push(subscription);
    } catch (e) {
      this.handleError(e);
    }
  }

}
