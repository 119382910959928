import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseNoteService } from '../expense-note.service';
import { ExpenseNote } from 'src/app/models/expense/ExpenseNote';
import { Payer } from './../../../models/expense/payer';
import { Query } from 'src/app/models/query';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expenseNote-list',
  templateUrl: './expenseNote-list.component.html',
  styleUrls: ['./expenseNote-list.component.scss']

})
export class ExpenseListComponent extends BaseComponent implements OnInit {

  public showSidebar = false;
  public modalTitle = 'New Expense Note';
  public removedialogueeQ = false;
  public expenseNote: ExpenseNote[] = [];
  loadingbtnPrint = false
  loadingbtnemail = false
  public filteredExpenseNotes: ExpenseNote[] = [];
  itemSearch
  public currentModal;
  public selectedExpenseNote = new ExpenseNote()
  public selected: ExpenseNote[] = [];
  convertialogueeQ = false
  ToOrder = new ExpenseNote()
  keyword = "format"
  itemsSave = []
  expenseNotSelectedIndex = 0

  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  orderNextPage: any = 0
  orderPrevPage: any = 0

  selected_Payerrquery = new Payer()
  filterModeApply_Payer: boolean = false
  filterModeFormat_query = new Query()
  filterModeStatus_query = new Query()
  filterModeDate_query = new Query()
  filterModeexpiry_date_query = new Query()
  filterModeTotal_query = new Query()
  seletedquery_type: string

  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: 'Not Equal',
      value: 'ne'
    },
    {
      type: 'Equal',
      value: 'exact'
    },
    {
      type: 'Less than', value: 'lt'

    },
    {
      type: 'Grater than', value: 'gt'

    },


  ]
  ex_statues = [
    { ex_status: 'Company Account' },
    { ex_status: 'Claimed' },
  ]
  constructor(private expensesService: ExpenseNoteService,
    public translate: TranslateService,
     private SharedService: SharedService,
     private messageService: MessageService,
     private router: Router,
     private datePipe :DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.getExpenseNotes();
    this.subscribeOnExpenseNotesRefresh();


  }
  filter(evenet) {
  }
  private subscribeOnExpenseNotesRefresh() {
    const subscription = this.expensesService.refreshListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getExpenseNotes();
      }
    });
    this.subscriptions.push(subscription);
  }


  showAddExpenseNote() {
    this.modalTitle = this.translate.instant('NEW_EXPENSE_NOTE')
    this.showSidebar = true;
    this.SharedService.Create_form(true)
    this.expensesService.expenseNoteSelected.next(null);
    this.expensesService.upadteFormClicked.next(null)
    this.selectedExpenseNote = null;
  }


  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.expenseNote = e


  }

  // print() {
  //   this.loadingbtnPrint = true;

  //   const subscription = this.expensesService.PrintQoute(this.selectedExpenseNote.id).subscribe((ExpenseNote: any) => {
  //     if (!isSet(ExpenseNote)) {
  //       return;
  //     }
  //     this.expensesService.printqoute_link.next(ExpenseNote.url)
  //     subscription.unsubscribe();
  //     this.loadingbtnPrint = false;
  //   }, error => {
  //     subscription.unsubscribe();
  //     this.loadingbtnPrint = false;

  //   })
  // }
  // emailbtnLoading() {
  //   const subscription = this.expensesService.btnLoadingEmitter.subscribe((status: boolean) => {
  //     if (!isSet(status)) {
  //       return
  //     }
  //     if (status == false) {
  //       return this.loadingbtnemail = false

  //     }
  //     if (status == true) {
  //       return this.loadingbtnemail = true

  //     }
  //     this.expensesService.submitStuats.next(null)
  //   }, error => {
  //     this.handleError(error);
  //   });

  //   this.subscriptions.push(subscription);
  // }

  removeExpenseNotes() {

    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.expensesService.deleteExpenseNote(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.format + this.translate.instant('DELETED') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: 'Selected Expense deleted' });
          }
          this.loading = false;
          this.expensesService.refreshDataTable();
          subscription.unsubscribe();
          this.SharedService.dialog_status.next(false)



        }, error => {
          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();
          this.SharedService.dialog_status.next(false)

        })

        this.selected = []
        this.refreshList()
      })




    }
    catch (error) {
      console.log(error)
      this.log(error);
    }

    this.removedialogueeQ = false
  }


  showExpenseNote(expenseNote: ExpenseNote, index) {


    this.showSidebar = true;
    this.modalTitle = expenseNote.format
    this.expensesService.expenseNoteSelected.next(expenseNote);
    this.selectedExpenseNote = expenseNote;
    this.expenseNotSelectedIndex = index

  }
  public saveForm() {
    this.expensesService.saveForm();
    const subscription = this.expensesService.retrieve_lastExpenseNoteEmitter.subscribe((expenseNote: ExpenseNote) => {
      if (!isSet(expenseNote)) {
        return
      }
      this.selectedExpenseNote = ExpenseNote.cloneObject(expenseNote)

      this.modalTitle = ` ${this.selectedExpenseNote.format}`

      this.expensesService.retrieve_lastExpenseNote.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  // refreshSelectedexpenseNot() {
  //   this.loading = true
  //   const subscription = this.expensesService.refreshselectedEmitter.subscribe((status: boolean) => {
  //     if (!isSet(status) || !status) {
  //       return
  //     }
  //  setTimeout(() => {
  //   this.showExpenseNote(this.expenseNote[this.expenseNotSelectedIndex], this.expenseNotSelectedIndex)

  //  }, 1000);



  //     this.loading = false

  //     this.expensesService.refreshselected.next(null)



  //   }, error => {
  //     this.loading = false

  //     this.handleError(error);
  //   });
  //   this.subscriptions.push(subscription);



  // }

  public Sendemail() {
    this.expensesService.sendEmail_buuton(true)
  }

  private getExpenseNotes() {
    this.loading = true

    const subscription = this.expensesService.getExpenseNotes().subscribe((expenseNote: PaginatedResponse<ExpenseNote[]>) => {


      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expenseNote.count
      this.total_pages = expenseNote.total_pages
      this.expenseNote = Array(this.ordersCount).fill(0)
      const clone = ExpenseNote.cloneManyObjects(expenseNote.results);

      clone.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredExpenseNotes.push(e)


      })
      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expenseNote[index] = clone[index];

      }




      this.loading = false

    }, error => {
      this.loading = false
      console.log(error)
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  getNextExpenseNotesPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.expensesService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.expensesService.getExpenseNotes_NextPage(this.orderNextPage).subscribe((expenseNotes: PaginatedResponse<ExpenseNote[]>) => {
          if (!isSet(expenseNotes)) {
            return;
          }

          const clone = ExpenseNote.cloneManyObjects(expenseNotes.results);

          clone.map(e => {
            e.payer = new Payer()
            e.payer.name = e?.payer_name
            let datefillter = new Date(e?.date)
            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

            this.filteredExpenseNotes.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.expenseNote[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date' || query_name == 'expiry_date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.expensesService.queris.map((query, index) => {
      if (query.query_name == query_name) {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.expensesService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.ordersCount = 0
    const subscription = this.expensesService.getExpenseNotes().subscribe((expenseNotes: PaginatedResponse<ExpenseNote[]>) => {
      if (!isSet(expenseNotes)) {
        return;
      }
      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expenseNotes.count
      this.total_pages = expenseNotes.total_pages
      this.expenseNote = Array(this.ordersCount).fill(0)


      const clone = ExpenseNote.cloneManyObjects(expenseNotes.results);
      clone.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredExpenseNotes.push(e)


      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expenseNote[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.expensesService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeexpiry_date_query= new Query()
    this.filterModeStatus_query = new Query()
    this.selected_Payerrquery = new Payer()
    this.getExpenseNotes()
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.expensesService.sort = filed
      return this.getExpenseNotes()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.expensesService.sort = '-' + filed
      return this.getExpenseNotes()
    }


  }
  Update() {
    this.expensesService.upadteFormClicked.next(true)
  }
  refreshList() {
    this.expensesService.expenseNoteSelected.next(null)

    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);

    });
  }

  outside_Status(status: boolean) {
    this.SharedService.dialog_status.next(status)
  }

  deleteseletedexpense(expenseNote: ExpenseNote) {
    this.selected = []
    this.selected.push(expenseNote)
    this.removedialogueeQ = true
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }


}
