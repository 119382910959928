import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InplaceModule } from 'primeng/inplace';
import { SharedModule } from '../shared/shared.module';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {OrderListModule} from 'primeng/orderlist';
import { DialogModule } from 'primeng/dialog';
import { creditnotessupplirelistComponent } from './creditnotessupplier-list/creditnotessupplier-list.component';
import { creditnotessupplireComponent } from './creditnotessupplier-form/creditnotessupplier.component';
import { TranslateModule } from '@ngx-translate/core';
import { TieredMenuModule } from 'primeng/tieredmenu';

export const routes: Routes = [
  {path: '', component: creditnotessupplirelistComponent}
];

@NgModule({
  declarations: [creditnotessupplireComponent  , creditnotessupplirelistComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CardModule,
    DropdownModule,
    FormsModule,
    InplaceModule,
    InputTextModule,
    CalendarModule,
    InputTextareaModule,
    SharedModule,
    SplitButtonModule,
    TableModule,
    TranslateModule,TieredMenuModule,
    ToastModule,OrderListModule,DialogModule,
   
   
    
    
   




  
    
  ]
})
export class creditnotessupplireModule {
}
