import { Component, HostListener, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SharedService } from '../../shared/shared.service';
import { ExpenseService } from '../expense.service';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { Expenseitems } from 'src/app/models/expense/expenseItems';
import { Payer } from 'src/app/models/expense/payer';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import * as printJS from 'print-js';
import { Query } from 'src/app/models/query';
import { DatePipe } from '@angular/common';
import {Supplier} from "../../../models/supplier";

@Component({
  selector: 'app-expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss']
})
export class ExpenseListComponent extends BaseComponent implements OnInit {
  public showSidebar = false;
  public modalTitle = 'New Expense';
  public removedialogueeQ = false;
  public expense: Expenseitems[] = [];
  loadingbtnPrint = false
  loadingbtnemail = false
  public fillterSupplier: Supplier[] = [];
  public filteredexpenses: Expenseitems[] = [];
  itemSearch
  public currentModal;
  public selectedexpense = new Expenseitems()
  public addexpense = new Expenseitems()
  public selected: Expenseitems[] = [];
  convertialogueeQ = false
  keyword = "format"
  itemsSave = []
  expenseNotSelectedIndex = 0
  loadingbtnupload = false
  selectedIndex = 0
  filterModeApplySupplier: boolean = false
  selectedSupplier=new Supplier()
  viewAttachment_diloage = false
  pdfheight = 0

  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  orderNextPage: any = 0
  orderPrevPage: any = 0

  selected_Payerrquery = new Payer()
  filterModeApply_Payer: boolean = false
  filterModeFormat_query = new Query()
  filterModeStatus_query = new Query()
  filterModeDate_query = new Query()
  filterModeexpiry_date_query = new Query()
  filterModeTotal_query = new Query()
  seletedquery_type: string
  header_body: string
  currnecy =JSON.parse(localStorage.getItem('companySettings'))?.currency

  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith', label: this.translate.instant('START_WITH')
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL')
    },
    {
      type: 'Contains', label: this.translate.instant('CONTINES')
    },
    {
      type: 'EndsWith', label: this.translate.instant('END_WITH')
    },
  ];
  query_types2 = [
    {
      type: 'Not Equal', label: this.translate.instant('NOT_EQUAL'), value: 'ne'
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL'), value: 'exact'
    },
    {
      type: 'Less than', label: this.translate.instant('LESS_THAN'), value: 'lt'
    },
    {
      type: 'Grater than', label: this.translate.instant('GREATER_THAN'), value: 'gt'
    },
  ];
  ex_statues = [
    { ex_status:'Company Account ',label:this.translate.instant('Company Account') },
    { ex_status:'Claimed' ,label:this.translate.instant('Claimed')  },
  ]
  constructor(private expensesService: ExpenseService, public translate: TranslateService, private SharedService: SharedService, private messageService: MessageService, private router: Router,
    private datePipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.getexpenses();
    this.subscribeOnexpensesRefresh();
    this.subscribeOnexpensesRefresh()


  }
  filter(evenet) {
  }
  private subscribeOnexpensesRefresh() {
    const subscription = this.expensesService.refreshListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getexpenses();
      }
    });
    this.subscriptions.push(subscription);
  }


  showAddexpense() {
    this.modalTitle = this.translate.instant('NEW_EXPENSE')
    this.showSidebar = true;
    this.SharedService.Create_form(true)
    this.expensesService.expenseSelected.next(null);
    this.expensesService.upadteFormClicked.next(null)
    this.selectedexpense = null;
    this.header_body=null
  }


  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.expense = e


  }

  // print() {
  //   this.loadingbtnPrint = true;

  //   const subscription = this.expensesService.PrintQoute(this.selectedexpense.id).subscribe((expense: any) => {
  //     if (!isSet(expense)) {
  //       return;
  //     }
  //     this.expensesService.printqoute_link.next(expense.url)
  //     subscription.unsubscribe();
  //     this.loadingbtnPrint = false;
  //   }, error => {
  //     subscription.unsubscribe();
  //     this.loadingbtnPrint = false;

  //   })
  // }
  // emailbtnLoading() {
  //   const subscription = this.expensesService.btnLoadingEmitter.subscribe((status: boolean) => {
  //     if (!isSet(status)) {
  //       return
  //     }
  //     if (status == false) {
  //       return this.loadingbtnemail = false

  //     }
  //     if (status == true) {
  //       return this.loadingbtnemail = true

  //     }
  //     this.expensesService.submitStuats.next(null)
  //   }, error => {
  //     this.handleError(error);
  //   });

  //   this.subscriptions.push(subscription);
  // }

  removeexpenses() {

    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.expensesService.deleteExpense(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.format + this.translate.instant('DELETED') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_EXP_DEL') });
          }
          this.loading = false;
          this.expensesService.upadteFormClicked.next(true);
          subscription.unsubscribe();
          this.SharedService.dialog_status.next(false)



        }, error => {
          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();
          this.SharedService.dialog_status.next(false)

        })

        this.selected = []
        this.refreshList()
      })




    }
    catch (error) {
      console.log(error)
      this.log(error);
    }

    this.removedialogueeQ = false
  }


  showexpense(expense: Expenseitems, index) {


    this.showSidebar = true;
    this.modalTitle = 'Expense : '
    this.header_body = expense?.format
    this.SharedService.update_form.next(false)

    this.expensesService.expenseSelected.next(expense);
    this.selectedexpense = expense;
    this.expenseNotSelectedIndex = index
    this.addexpense = null
  }
  public saveForm() {
    this.expensesService.saveFormClicked.next(true);
    const subscription = this.expensesService.retrieve_lastExpenseEmitter.subscribe((expense: Expenseitems) => {
      if (!isSet(expense)) {
        return
      }
      this.selectedexpense = Expenseitems.cloneObject(expense)

      this.modalTitle = 'Expense : '
      this.header_body = expense?.format
      this.expensesService.retrieve_lastExpense.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  // refreshSelectedexpenseNot() {
  //   this.loading = true
  //   const subscription = this.expensesService.refreshselectedEmitter.subscribe((status: boolean) => {
  //     if (!isSet(status) || !status) {
  //       return
  //     }
  //  setTimeout(() => {
  //   this.showexpense(this.expense[this.expenseNotSelectedIndex], this.expenseNotSelectedIndex)

  //  }, 1000);



  //     this.loading = false

  //     this.expensesService.refreshselected.next(null)



  //   }, error => {
  //     this.loading = false

  //     this.handleError(error);
  //   });
  //   this.subscriptions.push(subscription);



  // }

  // public Sendemail() {
  //   this.expensesService.sendEmail_buuton(true)
  // }

  private getexpenses() {
    this.loading = true

    const subscription = this.expensesService.getExpenses().subscribe((expense: PaginatedResponse<Expenseitems[]>) => {



      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expense.count
      this.total_pages = expense.total_pages
      this.expense = Array(this.ordersCount).fill(0)
      const clone = Expenseitems.cloneManyObjects(expense.results);

      clone.map(e => {

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredexpenses.push(e)


      })
      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expense[index] = clone[index];

      }




      this.loading = false

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  Update() {
    this.expensesService.upadteFormClicked.next(true)
  }
  refreshList() {
    this.expensesService.refreshList.next(true)

    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);

    });
  }

  outside_Status(status: boolean) {
    this.SharedService.dialog_status.next(status)
  }

  deleteseletedexpense(expense: Expenseitems) {
    this.selected = []
    this.selected.push(expense)
    this.removedialogueeQ = true
  }



  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  showViewReceipt(selectedexpense: Expenseitems, index: number) {

    if (isSet(selectedexpense)) {
      this.selectedexpense = selectedexpense
      this.selectedIndex = index
    }

    this.viewAttachment_diloage = true
    this.generate_download()

  }

  generate_download() {
    try {
      this.loading = true

      this.expensesService.generate_download(this.selectedexpense?.attachment?.path).subscribe(link => {

        if (!isSet(link)) {
          return
        }
        this.selectedexpense.download_url = link?.download_url
        this.selectedexpense.view_url = link?.view_url



        this.loading = false

      }, error => {
        this.loading = false
        this.handleError(error);

      })

    } catch (error) {
      this.log(error);
    }
  }


  downloadlink() {
    window.location.href = this.selectedexpense.download_url;
  }

  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  deletefile() {
    this.viewAttachment_diloage = false

    try {

      this.loading = true;
      const subscription = this.expensesService.delete_File(this.selectedexpense?.attachment?.path).subscribe(() => {
        this.loading = false;

        this.selectedexpense.attachment = {}
        this.messageService.add({ key: 'list', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('RECE_DEL') });



        this.updateExpense()

        subscription.unsubscribe();


      }, error => {
        this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

        this.loading = false;
        subscription.unsubscribe();
      })
    }
    catch (error) {
      console.log(error)
      this.log(error);
    }
  }
  updateExpense() {
    try {
      this.loading = true;
      const subscription = this.expensesService.upadeExpense(this.selectedexpense).subscribe((expense: Expenseitems) => {

        if (!isSet(expense)) {
          return;
        }


        this.loading = false;
        subscription.unsubscribe();
        this.expensesService.refreshList.next(true)

      }, error => {
        this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

        this.loading = false;
        subscription.unsubscribe();

      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }
  printPdf() {

    printJS({ printable: this.selectedexpense.download_url, type: 'pdf', showModal: true })

  }

  getNextExpensesPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.expensesService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.expensesService.getExpenses_NextPage(this.orderNextPage).subscribe((expenseNotes: PaginatedResponse<Expenseitems[]>) => {
          if (!isSet(expenseNotes)) {
            return;
          }

          const clone = Expenseitems.cloneManyObjects(expenseNotes.results);

          clone.map(e => {
            let datefillter = new Date(e?.date)
            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

            this.filteredexpenses.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.expense[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date' || query_name == 'expiry_date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.expensesService.queris.map((query, index) => {
      if (query.query_name == query_name) {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.expensesService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.ordersCount = 0
    const subscription = this.expensesService.getExpenses().subscribe((expenseNotes: PaginatedResponse<Expenseitems[]>) => {
      if (!isSet(expenseNotes)) {
        return;
      }
      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expenseNotes.count
      this.total_pages = expenseNotes.total_pages
      this.expense = Array(this.ordersCount).fill(0)


      const clone = Expenseitems.cloneManyObjects(expenseNotes.results);
      clone.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredexpenses.push(e)


      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expense[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.expensesService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeexpiry_date_query = new Query()
    this.filterModeStatus_query = new Query()
    this.selected_Payerrquery = new Payer()
    this.selectedSupplier=new Supplier()
    this.getexpenses()
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.expensesService.sort = filed
      return this.getexpenses()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.expensesService.sort = '-' + filed
      return this.getexpenses()
    }


  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
