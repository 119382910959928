import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../../models/customer';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { AccountManager } from '../../models/accountmanager';
import { SpecialPrice } from '../../models/specialprice';
import { PricingCategory } from '../../models/pricingcategory';
import { isSet } from '../core/util';
import { Order } from 'src/app/models/orders/order';
import { Quote } from 'src/app/models/quotes/quote';
import { Region } from 'src/app/models/region';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public currentCustomer: Customer;
  public attachmentlink: string;
  public queris = []
  sort :any
  public customerSelected: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  public customerSelectedEmitter: Observable<Customer> = this.customerSelected.asObservable();

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public showaftersaveForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showaftersaveFormdEmitter: Observable<boolean> = this.showaftersaveForm.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();


  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  private refreshCustomersList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshCustomersListEmitter: Observable<boolean> = this.refreshCustomersList.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public CreateNewform: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public CreateNewformClickedEmitter: Observable<boolean> = this.CreateNewform.asObservable();

  public createClicke: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public createClickeEmitter: Observable<boolean> = this.createClicke.asObservable();

  
  constructor(private api: ApiService) { }

  getCustomers(): Observable<PaginatedResponse<Customer[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }
      
    return this.api.get<PaginatedResponse<Customer[]>>(`/customers?${sum_querirs}&order=${this.sort}`);
  }
  if (!this.queris.length) {
    return this.api.get<PaginatedResponse<Customer[]>>(`/customers?order=${this.sort}`);

  }
  }
  serach_Customers(value): Observable<PaginatedResponse<Customer[]>> {
    return this.api.get<PaginatedResponse<Customer[]>>(`/customers?query=company_name,startswith,${value}`);
  }
  getNextpageCustomers(num): Observable<PaginatedResponse<Customer[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<Customer[]>>(`/customers?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Customer[]>>(`/customers?page=${num}&order=${this.sort}`);

    }
  }

  getCustomer(id: string): Observable<Customer> {
    return this.api.get<Customer>(`/customers/rud/${id}`);
  }

  // DeleteCustomers(ids:[]): Observable<Customer> {
  //   return this.api.delete<Customer>(`/customers?action=delete`,ids);
  // }



  DeleteCustomer(id: string): Observable<Customer> {
    return this.api.delete<Customer>(`/customers/rud/${id}`);
  }
  deleteAccountManager(id: string): Observable<AccountManager> {
    return this.api.delete<AccountManager>(`/account_managers/rud/${id}`);
  }
  getAccountManagers(): Observable<PaginatedResponse<AccountManager[]>> {
    return this.api.get<PaginatedResponse<AccountManager[]>>('/account_managers');
  }

  getSpecialPrices(): Observable<PaginatedResponse<SpecialPrice[]>> {
    return this.api.get<PaginatedResponse<SpecialPrice[]>>('/special_prices');
  }
  deleteSpecialPrices(id: string): Observable<SpecialPrice> {
    return this.api.delete<SpecialPrice>(`/special_prices/rud/${id}`);
  }
  addSpecialPrices(specialPrice: SpecialPrice): Observable<SpecialPrice> {
    return this.api.post<SpecialPrice>(`/special_prices`, specialPrice.toDB())
  }
  updateSpecialPrices(specialPrice: SpecialPrice): Observable<SpecialPrice> {
    let update = {
      name: specialPrice.name
    }
    return this.api.patch<SpecialPrice>(`/special_prices/rud/${specialPrice.id}`, update)

  }
  getPricingCategories(): Observable<PaginatedResponse<PricingCategory[]>> {
    return this.api.get<PaginatedResponse<PricingCategory[]>>('/pricing_categories');
  }
  getRegions(): Observable<PaginatedResponse<Region[]>> {
    return this.api.get<PaginatedResponse<Region[]>>('/regions');
  }
  addPRegion(region: Region): Observable<Region> { 
    return this.api.post<Region>(`/regions`, region.toDB())
  }
  addPricingCategories(pricingCategory: PricingCategory): Observable<PricingCategory> {
    return this.api.post<PricingCategory>(`/pricing_categories`, pricingCategory.toDB())
  }
  deletePricingCategories(id: string): Observable<PricingCategory> {
    return this.api.delete<PricingCategory>(`/pricing_categories/rud/${id}`);
  }
  deleteRegion(id: string): Observable<Region> {
    return this.api.delete<Region>(`/regions/rud/${id}`);
  }
  updatePricingCategories(pricingCategory: PricingCategory): Observable<PricingCategory> {
    let update = {
      name: pricingCategory.name
    }
    return this.api.patch<PricingCategory>(`/pricing_categories/rud/${pricingCategory.id}`, update)

  }
  updateRegion(region: Region): Observable<Region> {
    let update = {
      name: region.name
    }
    return this.api.patch<Region>(`/regions/rud/${region.id}`, update)

  }
  addCustomer(customer: Customer): Observable<Customer> {
    
    return this.api.post<Customer>(`/customers`, customer.toDB())
  }
  addAccountManagers(accountManager: AccountManager): Observable<AccountManager> {

    return this.api.post<AccountManager>('/account_managers', accountManager.toDB());
  }

  addMiniCustomer(customer: Customer): Observable<Customer> {
    return this.api.post<Customer>(`/min_customers`, customer.toMiniDB());
  }


  upload(filee: any) {
    const subscription = this.uploadDetilsEmitter.subscribe(uploadDetils => {
      if (!isSet(uploadDetils)) {
        return;
      }

      const credential = uploadDetils.upload_data.fields as credentialfilee


      const body = new FormData()
      this.uploadkey.next(credential.key)
      body.append("key", credential.key)
      body.append("x-amz-algorithm", credential['x-amz-algorithm'])
      body.append("x-amz-credential", credential['x-amz-credential'])
      body.append("x-amz-date", credential['x-amz-date'])
      body.append("policy", credential.policy)
      body.append("x-amz-signature", credential['x-amz-signature'])
      body.append("file", filee, filee.name)

      const sub = this.api.Uploadfile<any>(uploadDetils.upload_data.url, body).subscribe(() => {
        this.attachmentlink = credential.key

        this.uploadstuats.next(true)
        sub.unsubscribe()
      }, error => {
        this.uploadstuats.next(false)
        sub.unsubscribe()

      }
      )
      this.uploadDetils.next(null)
      subscription.unsubscribe()

    }, error => {
      subscription.unsubscribe()

    })



  }
  generate_Upload(file: any) {
    const subscription = this.api.generate_upload_url<any>(`/generate_s3_upload_url`, file).subscribe(uploadDetils => {

      if (!isSet(uploadDetils)) {
        return
      }
      subscription.unsubscribe()
      return this.uploadDetils.next(uploadDetils)


    })
  }
  generate_download(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  updateCustomer(customer: Customer): Observable<Customer> {
    const updatecustomer = {
      vat: customer.vat,
      company_name: customer.company_name,
      main_info_extra_fields: customer.main_info_extra_fields,
      invoicing_address: customer.invoicing_address,
      shipping_address: customer.shipping_address,
      contact: customer.contact,
      payment_details: {
        credit_limit: customer.payment_details?.credit_limit,
        account_manager: customer.payment_details?.account_manager?.id,
        account_number: customer.payment_details?.account_number,
        BIC: customer.payment_details?.BIC,
        Language: customer.payment_details?.Language,
        payment_terms: customer.payment_details?.payment_terms,
        extra_fields: customer.payment_details?.extra_fields,
        IBAN:customer?.payment_details?.IBAN
      },
      pricing: {
        discount: customer.pricing?.discount,
        special_price: customer.pricing.special_price?.id,
        category: customer.pricing.category?.id,
        invoicing_frequency: customer.pricing?.invoicing_frequency,
      },
      remark: customer.remark,
      attachments: customer.attachments,
      region:customer.region?.id,
    }

    // for (const prop in updatecustomer.payment_details) {
    //   if (!isSet(updatecustomer.payment_details[prop])) {
    //     delete updatecustomer.payment_details[prop];
    //   }
    // }

    updatecustomer.contact.map(contact => {
      for (const prop in contact) {
        if (!isSet(contact[prop])) {
          delete contact[prop];

        }
      }
    })
    return this.api.patch<Customer>(`/customers/rud/${customer.id}`, updatecustomer);
  }
  updateAccountManger(accountManager: AccountManager): Observable<AccountManager> {
    let update = {
      name: accountManager.name
    }
    return this.api.patch<AccountManager>(`/account_managers/rud/${accountManager.id}`, update)

  }
  selectCustomer(customer: Customer) {
    this.customerSelected.next(customer);
  }

  saveForm() {

    this.saveFormClicked.next(true);
  }

  refreshCustomers() {
    this.refreshCustomersList.next(true);
  }

  removeEmpty(obj) {
    const newObj = {};
    setTimeout(() => {
      Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
          newObj[k] = this.removeEmpty(v);
        } else if (v != null) {
          newObj[k] = obj[k];
        }
      });
    }, 200)
    return newObj;
  }

  get_outstandingOrders(csutomer_id): Observable<PaginatedResponse<Order[]>> {
    
    return this.api.get<PaginatedResponse<Order[]>>(`/dashboard/orders/outstanding?customer=${csutomer_id}`);
  }
  get_outstanding_invoiced(csutomer_id): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>(`/dashboard/orders/outstanding_invoiced?customer=${csutomer_id}`);
  }
  get_outstanding_invoicedNext(num, csutomer_id): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>(`/dashboard/orders/outstanding_invoiced?customer=${csutomer_id}&page=${num}`);
  }
  get_outstandingOrdersNext(num, csutomer_id): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>(`/dashboard/orders/outstanding?customer=${csutomer_id}&page=${num}`);
  }
  get_outstandingQoute(csutomer_id): Observable<PaginatedResponse<Quote[]>> {
    return this.api.get<PaginatedResponse<Quote[]>>(`/dashboard/quotes/outstanding?customer=${csutomer_id}`);
  }
  get_outstandingQoutessNext(num, csutomer_id): Observable<PaginatedResponse<Quote[]>> {
    return this.api.get<PaginatedResponse<Quote[]>>(`/dashboard/quotes/outstanding?customer=${csutomer_id}&page=${num}`);
  }

  get_MostSoldProducts(csutomer_id): Observable<PaginatedResponse<any[]>> {
    return this.api.get<PaginatedResponse<any[]>>(`/dashboard/products/top_sold_product?limit=10&customer=${csutomer_id}`);
  }

  get_last_customer_order(csutomer_id): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>(`/orders/last_customer_order?customer=${csutomer_id}&limit=10`);
  }
}


export class credentialfilee {
  "key": string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "policy": string
  "x-amz-signature": string
}