
import { isSet } from '../modules/core/util';

export class Vat_Product {
  id?:string;
  name?:string
  value ?: any ;
  show ?: boolean

  public constructor(params?: Vat_Product) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Vat_Product) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new Vat_Product(objectToClone);
    
    
    return obj;
  }

  public static cloneManyObjects(Vat_Products: Vat_Product[]) {
    const vates: Vat_Product[] = [];
    for (const vat of Vat_Products) {
      vates.push(Vat_Product.cloneObject(vat));
    }

    return vates;
  }


  public toDB() {
    
    return {
      value: this.value,
      show: true,



    }
  }

}
