<p-toast key="form" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" class="p-shadow-1" header='{{"ACCOUNT_SEETING" | translate}}' [(visible)]="visable" [style]="{width: '45rem'}">
    <div class="p-grid p-p-3">
        <div class="p-col-12">
            <div class="p-grid ">
                <div class="p-col-3">
                    <h5 style="padding-top: 60px;">{{"PROFILE_PIC" | translate}}</h5>
                </div>
                <div class="p-col-4">
                    <img *ngIf="!user?.viewimage" src="assets/layout/images/Profile_avatar.png" alt="Avatar"
                        class="avatar">

                    <img *ngIf="user?.viewimage" [src]="user.viewimage" alt="Avatar" class="avatar">

                </div>
                <div class="p-col-5">
                    <button *ngIf="!user?.viewimage" style="margin-top: 60px;" type="button" (click)="showUpload()"
                        pButton icon="pi pi-upload" label='{{"UPLOAD" | translate}}' class="  p-button-outlined  p-ml-2"></button>

                    <input #upload accept="image/png, image/jpeg" type="file" id="upload-photo"
                        [imageToDataUrl]="options" (imageSelected)="uploadlogo($event)">
                    <button *ngIf="user?.viewimage" pButton type="button" style="margin-top: 60px;"
                        [disabled]="disablebtnupload"
                        [icon]="disablebtnupload? 'pi pi-spin pi-spinner': ' fas fa-trash'" (click)="deleteImage()"
                        label=' {{"CUSTOMER_FORM_REMOVE" | translate}} ' (click)="removeImage()"
                        class=" p-button-danger  p-button-outlined  p-ml-2"></button>
                </div>

            </div>

        </div>
        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-3">
                    <h5>{{"REGISTER_FIRST_NAME" | translate}}</h5>
                </div>
                <div class="p-col-9">
                    <input [(ngModel)]="user.first_name" (ngModelChange)="notempty($event)" id="title-input" type="text"
                        pInputText>
                </div>
            </div>

        </div>
        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-3">
                    <h5>{{"REGISTER_LAST_NAME" | translate}}</h5>
                </div>
                <div class="p-col-9">
                    <input [(ngModel)]="user.last_name" (ngModelChange)="notempty($event)" id="title-input" type="text"
                        pInputText>
                </div>
            </div>

        </div>
        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-3">
                    <h5>{{"CUSTOMER_FORM_PHONE" | translate}} </h5>
                </div>
                <div class="p-col-9">
                    <input [(ngModel)]="user.mobile" (ngModelChange)="notempty($event)" id="title-input" type="text"
                        pInputText>
                </div>
            </div>

        </div>
        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-3">
                    <h5>{{"CUSTOMER_FORM_EMAIL" | translate}}</h5>
                </div>
                <div class="p-col-9">
                    <div style="display: -webkit-inline-box;">
                        <h5>
                            {{user.email}}
                        </h5>

                        <button style="margin-top: -5px;" icon="pi pi-pencil" (click)="email_dialog=true" type="button" pButton label='{{"CHANGE" | translate}}'
                            class="  p-button-outlined  p-ml-3"></button>
                    </div>

                </div>

            </div>

        </div>

        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-3">
                    <h5>{{"LOGIN_PASSWORD" | translate}}</h5>
                </div>
                <div class="p-col-9">


                    <button (click)="password_dialog=true" style="margin-top: -5px;" icon="pi pi-pencil" type="button"
                        pButton label='{{"CHANGE_PASSWORD" | translate}}' class="  p-button-outlined  p-ml-3"></button>

                </div>

            </div>

        </div>


    </div>


    <p-footer *ngIf="!password_dialog && !email_dialog">

        <button (click)="updateProfile()" type="button" pButton icon="pi pi-check" label=' {{"CUSTOMER_LIST_SAVE" | translate}} '></button>

        <button type="button" pButton icon="pi pi-times" (click)="visable=false"
            label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} '
            class="p-button-outlined p-button-secondary"></button>



    </p-footer>
</p-dialog>

<p-dialog modal="true" [resizable]="false" [draggable]="false" class="p-shadow-1" header='{{"CHANGE_PASSWORD" | translate}}'
    [(visible)]="password_dialog" [style]="{width: '35rem'}">
    <div class="p-grid p-p-3">

        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-5">
                    <h5>{{"CURRENT_PASS" | translate}}</h5>
                </div>
                <div class="p-col-7">


                    <input required [(ngModel)]="currentPassword" name="password" autocomplete="nope"
                        id="password-input" type="password" pInputText>
                </div>
            </div>

        </div>


        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-5">
                    <h5>{{"NEW_PASSWORD" | translate}}</h5>
                </div>
                <div class="p-col-7">
                    <div class="new-pass">


                        <input [ngClass]="{invalid: passinvaild && passfoucs}" required [(ngModel)]="newPassword"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="password" (focus)="onfoucsPassword()"
                            (blur)="onblurPassword()" autocomplete="nope" (ngModelChange)='checkPassword()' #Password
                            id="password-input" type="password" pInputText>

                        <i *ngIf="!showPassword" (click)="showpassword()" class="fas fa-eye-slash p-mt-2"></i>
                        <i *ngIf="showPassword" (click)="hidepassword()" class="fas fa-eye  p-mt-2"></i>



                    </div>
                </div>
            </div>

        </div>
    </div>

    
    <p-footer>

        <button (click)="change_password()" type="button" pButton icon="pi pi-check" label=' {{"CUSTOMER_LIST_SAVE" | translate}} '></button>

        <button type="button" pButton icon="pi pi-times" (click)="password_dialog=false"
            label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} '
            class="p-button-outlined p-button-secondary"></button>



    </p-footer>
</p-dialog>

<p-dialog modal="true" [resizable]="false" [draggable]="false" class="p-shadow-1" header="Change Email"
    [(visible)]="email_dialog" [style]="{width: '35rem'}">
    <div class="p-grid p-p-3">

        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <h5> {{"LOGIN_PASSWORD" | translate}}</h5>
                </div>
                <div class="p-col-8">


                    <input required [(ngModel)]="currentPassword" name="password" autocomplete="nope"
                        id="password-input" type="password" pInputText>
                </div>
            </div>

        </div>


        <div class="p-col-12 p-mt-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <h5>{{"NEW_EMAIL" | translate}}</h5>
                </div>
                <div class="p-col-8">
            
                    <input required [ngClass]="{invalid: (Email.invalid || checkused_email) && emailfoucs}"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #Email="ngModel" name="Email" [(ngModel)]="new_email"
                    id="email-input" type="email" (focus)="emailfoucs=true" (blur)="onBlurEmail()"
                    (ngModelChange)="checkEmail()" pInputText>
                </div>
            </div>

        </div>
    </div>

    
    <p-footer>

        <button (click)="reset_email()" type="button" pButton icon="pi pi-check" label=' {{"CUSTOMER_LIST_SAVE" | translate}} '></button>

        <button type="button" pButton icon="pi pi-times" (click)="email_dialog=false"
            label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} '
            class="p-button-outlined p-button-secondary"></button>



    </p-footer>
</p-dialog>
<p-card *ngIf="checkpass==true"
[style]="{'width':'30rem','box-shadow':'none','position':'absolute','left':'75vw','top':'15vh','Background-color':'#e6eaf0','z-index':'10000'}">
<h4>{{"MUST_CON" | translate}} :</h4>
<div class="p-grid">
  <div  class="p-col-12 h4">
    <i *ngIf="passwrod_lowercase" class="far fa-check-circle text-success p-mr-2"></i>
    <i *ngIf="!passwrod_lowercase" class="far fa-times-circle text-danger p-mr-2"></i>
    {{"LOW_CASE" | translate}}
  </div>
  <div class="p-col-12 h4">
    <i *ngIf="passwrod_capitalcase" class="far fa-check-circle text-success p-mr-2"></i>
    <i *ngIf="!passwrod_capitalcase" class="far fa-times-circle text-danger p-mr-2"></i>
    {{"UP_CASE" | translate}}
  </div>
  <div class="p-col-12 h4">
    <i *ngIf="passwrod_numeric" class="far fa-check-circle text-success p-mr-2"></i>
    <i *ngIf="!passwrod_numeric" class="far fa-times-circle text-danger p-mr-2"></i>
    {{"QUOTES_LIST_QUOTE_NO" | translate}}
  </div>
  <div class="p-col-12 h4">
    <i *ngIf="passwrod_length" class="far fa-check-circle text-success p-mr-2"></i>
    <i *ngIf="!passwrod_length" class="far fa-times-circle text-danger p-mr-2"></i>
    {{"MIN_CHR" | translate}}
  </div>
</div>
</p-card>