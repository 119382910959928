import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { Customer } from '../../../models/customer';
import { Product } from '../../../models/product';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { DatePipe } from '@angular/common';
import { SendEmail } from 'src/app/models/SendEmail';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Vat_Product } from 'src/app/models/vat-product';
import * as printJS from 'print-js';
import { InputNumber } from 'primeng/inputnumber';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { CreditnotesCustomerService } from '../creditnotescustomers.service';
import { CreditNote } from 'src/app/models/CreditNotesCustomer';
import { Payment } from 'src/app/models/orders/Payment';
import { OrderItem } from 'src/app/models/orders/orderitem';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { Order } from 'src/app/models/orders/order';
import { OrdersService } from '../../orders/orders.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Currency } from 'src/app/models/currency';
import { Items } from 'src/app/models/PurchaseOrders/Items';
import {Calendar} from "primeng/calendar";

@Component({
  selector: 'app-creditnotesCustomer',
  templateUrl: './creditnotescustomers.component.html',
  styleUrls: ['./creditnotescustomers.component.scss'],
  providers: [MessageService]


})

export class creditnotesCustomerComponent extends BaseComponent implements OnInit, AfterViewInit {
  creditnote: CreditNote;
  orders: Order[] = []
  selectedOrders: Order[] = []
  selectedOrder: Order = new Order()

  savedOrders: Order[] = []
  filteredOrders: Order[] = []
  orders_dialog = false
  productTouched = false
  customercreditnote = false
  showPaymentDialog = false
  pdfViewer = false
  seletedstatus: boolean
  paymenttypes = [];
  paymentDue = 0
  oldpaymentDue = 0
  totalpayment_refund = 0
  cardHeight = 0
  paymentDuefromBalance = false
  paymentDuestatus = true
  Paymentlabel: string
  paymentUpdateindex = 0
  creditnoteTotal = 0
  creditnoteTotalChange = 0
  payment_cardHeight = 0
  newPayments: Payment[] = []
  sendEmaildialog = false
  sendEmail = new SendEmail()
  user_email: string
  convertInvoicedialogueeQ = false
  convertDraftdialogueeQ: boolean
  unlockInvoicedialogueeQ: boolean
  width_tdProduct: number
  payment = new Payment()
  selected_creditnote = false
  heightCardCustomer: boolean
  update_status = false
  itemsSave = []
  inlineProductValue: string
  indexinlineProduct = 0
  pdfheight = 0
  showaddinlineProduct: boolean
  appendCutsomer = 'body'
  draftCustomer = new Customer()
  cutrrentCustomer = new Customer()
  editCuostomericon = 'far fa-edit'
  vat_Percentage: Vat_Product[] = []
  productSize = 0
  confirm_Credit_dialog = false
  importform_order_module = false
  disable_VatsRatesChecks: boolean = false

  order_selected_index = 0
  orders_status = false
  statuses = [
    { status: { paid_status: 'Paid', paid_color: 'Paid' } },
    { status: { paid_status: 'UnPaid', paid_color: 'UnPaid' } },

  ]
  disable_payments: boolean
  fixed_amount: boolean


  orderNextPage: any = 0
  orderPrevPage: any = 0
  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  showAdd_exchange_rate: boolean = false
  add_exchange_rate = 1
  imported_Order: boolean = false
  show_afterdiscont: boolean
  selected_discount_type = { type: null }
  discount_type = [
    { type: 'Percent', label: this.translate.instant('PERCENT') },
    { type: 'Amount', label: this.translate.instant('ORDERS_FORM_AMOUNT') }
  ]
  default_currency = new Currency()

  @ViewChild('amountt') amount: any;
  @ViewChild('reference') reference: any;
  @ViewChildren('item.qty') qtyS: QueryList<ElementRef>;
  @ViewChildren('item.price') priceS: QueryList<InputNumber>;
  @ViewChild('paymentcard') paymentcard: ElementRef;
  @ViewChild('total_Height') total_Height: ElementRef;
  @ViewChild('productelemt') productelemt: ElementRef;

  @ViewChild('cn_format') cn_format: any;
  @ViewChild('cn_date') cn_date: any;
  @ViewChild('creditDueDateToggle') creditDueDateToggle: Calendar;
  @ViewChild('creditDateToggle') creditDateToggle: Calendar;


  constructor(private creditnoteService: CreditnotesCustomerService,
    private sharedService: SharedService,
    public messageService: MessageService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private ordersService: OrdersService,
    private router: Router) {
    super();

  }

  ngOnInit(): void {

    this.paymenttypes = [
      { type: 'Cash', label:this.translate.instant('CASH') },
      { type: 'Bank Cards', label:this.translate.instant('BANK_CARDS') },
      { type: 'Wire Transfer', label:this.translate.instant('WIRE_TRANSFER') },
      { type: 'Check', label:this.translate.instant('CHECK') },
      { type: 'Voucher', label:this.translate.instant('VOUCHER')},
      { type: 'Other', label:this.translate.instant('OTHER') }
    ]
    this.getVats()
    this.getNext_CreditnoteFormat();
    this.subscribeOnFormSaved();
    this.subscribeOnSendEmail()
    this.initCreditnote();
    this.Save_form()
    this.updateEmitter()
    this.getSavedCreditnote()
    this.subscribeOnCreditnoteSelected();
    this.getcreditnoteTotalChange()
    this.princreditnoteViewer()
    this.confirm_CreditEmitter()
    this.importSelected_Order()
    this.clearAll()
    this.showCreditnoteFromDash()
    this.currencyChanged()
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
        if (!isSet(this.creditnote.status.invoiced) || !this.creditnote.status.invoiced) {
          this.addItem();
        }

      }
    });
    this.itemsSave = [

      {

        label: this.translate.instant('FULL_CASH_PAID'), command: () => {
          if (this.paymentDue === 0) {
            this.paymentDuestatus = true
            return
          }
          this.update_status = true
          this.payment = new Payment()
          this.payment.amount = this.paymentDue
          this.totalpayment_refund = this.totalpayment_refund + this.payment.amount

          this.payment.type = 'Cash'
          this.payment.status = 'refund'
          this.payment.status2 = 'new'


          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.creditnote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()
          this.sharedService.update_form.next(true)

        },

      },
      {

        label: this.translate.instant('FULL_BANK_PAID'), command: () => {
          if (this.paymentDue === 0) {
            this.paymentDuestatus = true

            return
          }
          this.update_status = true
          this.payment = new Payment()

          this.payment.amount = this.paymentDue
          this.totalpayment_refund = this.totalpayment_refund + this.payment.amount

          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.type = 'Bank Cards'
          this.payment.status = 'refund'
          this.payment.status2 = 'new'


          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.creditnote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()
          this.sharedService.update_form.next(true)

        }
      }


    ]

  }

  ngAfterViewInit() {

    this.loading = true

    const x = setInterval(() => {

      const qtyS: ElementRef[] = this.qtyS.toArray()


      for (let index = 0; index < this.creditnote.items.length; index++) {
        qtyS[index].nativeElement.value = this.spaceNumber(this.creditnote.items[index].qty)


        if (index === this.creditnote.items.length - 1) {

          clearInterval(x);

        }
      }

      this.loading = false


    }, 1000);
    this.payment_cardHeight = this.paymentcard?.nativeElement?.offsetHeight
    this.cardHeight = this.total_Height?.nativeElement?.offsetHeight
    this.productSize = this.productelemt?.nativeElement?.offsetWidth - 30
    this.cdr.detectChanges();


  }
  private getVats() {
    const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(vats)) {
        return;
      }
      this.vat_Percentage = Vat_Product.cloneManyObjects(vats.results);
      let zeroVat_index = 0
      this.vat_Percentage.map((vat, index) => {
        if (vat.value == 0) {
          vat.value = '0'
          zeroVat_index = index
        }
      })
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.vat_Percentage = insert(this.vat_Percentage, 0, this.vat_Percentage[zeroVat_index])

      this.vat_Percentage.splice(zeroVat_index + 1, 1)
      this.vat_Percentage.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      subscription.unsubscribe();


    }, error => {

      console.log(error);
      subscription.unsubscribe();


    })


  }

  addItem() {

    const item = OrderItem.cloneObject(null);
    item.datePipe = new Date();
    item.product = new Product()
    item.product.id = 'new'
    item.qty = 1;
    item.price = 0;
    item.vat = new Vat_Product()
    item.vat.value = 0;
    item.checkinlne = false
    this.creditnote.items.push(item);
    this.update_status = true
    item.exchange_rate = 1

  }

  remomveItem(index: number) {
    this.update_status = true

    this.creditnote.items.splice(index, 1)
  }
  exchangeRateChange() {
    setTimeout(() => {
      this.creditnote.items.map(item => {
        item.exchange_rate = this.creditnote.exchange_rate
      })
    }, 300);

    this.getPaymentDue()
  }
  async productChanged(event, index) {
    await this.selectProduct(event, index)
    await this.discont_Percentage()
    this.getcreditnoteTotalChange()

    this.exchangeRateChange()

    await this.getVats_before_afterDiscont()

  }
  selectProduct(event, index) {
    return new Promise((resolve) => {
      this.creditnote.paid_vats = []
      setTimeout(() => {
        const product = event as Product;


        this.creditnote.items[index].price = product?.sell_price || 0;
        if (isSet(product?.vat?.id)) {
          this.creditnote.items[index].vat = product?.vat
        }
        this.creditnote.items[index].product = new Product()
        this.creditnote.items[index].datePipe = new Date()
        this.creditnote.items[index].product = product
        this.creditnote.items[index].name = product?.name
        this.creditnote.items[index].itemVaild = true
        this.productTouched = false
        this.update_status = true
        this.paymentDuestatus = false
        this.onChange_Item(index, null)
        resolve(this.creditnote.items[index])

      }, 200);


    })
  }


  inlineprodcut(event, index) {
    const inlineProduct = event

    this.productTouched = false
    this.update_status = true

    this.creditnote.items[index].product = new Product()
    this.creditnote.items[index].product.id = ''
    this.creditnote.items[index].product.name = inlineProduct
    this.creditnote.items[index].exchange_rate = this.creditnote?.exchange_rate
    this.creditnote.items[index].name = inlineProduct
    this.creditnote.items[index].itemVaild = true

  }
  serviceItem(event, index) {
    const service = event

    this.productTouched = false
    this.update_status = true
    this.creditnote.items[index].checkinlne = true

    this.creditnote.items[index].product = new Product()
    this.creditnote.items[index].product.id = ''
    this.creditnote.items[index].service = service
    this.creditnote.items[index].exchange_rate = this.creditnote?.exchange_rate

    this.creditnote.items[index].name = null
    this.creditnote.items[index].itemVaild = true

  }

  addCreditnote(): void {
    if (this.mathRound(this.creditnote?.creditnoteTotalIncVAT ) <  this.mathRound(this.totalpayment_refund )) {

     

      this.disable_payments = true
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        life: 4000,
        detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
      });
    } else {
      this.disable_payments = false

    }

    if (isSet(this.creditnote?.payments)) {
      this.creditnote.payments.map(payment => {
        if (payment.type === 'Wire Transfer') {
          payment.type = 'wt'
        }
        payment.amount = Number(payment.amount)
        payment.date = this.datePipe.transform(payment.dateP, 'yyyy-MM-dd')
        payment.status = "refund"

      })
    }

    this.removeSpaceNumber()

    let noerror: boolean

    if (isSet(this.creditnote?.items)) {
      this.creditnote?.items.map(item => {
        item.price = Number(item.price)
        item.qty = Number(item.qty)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')


        if (isSet(item.vat.value)) {

          item.vat.value = Number(item.vat.value)
        } else {
          item.vat.value = 0
        }
        if ((!isSet(this.creditnote.customer)) || (!isSet(this.creditnote.reference))
          || (!isSet(this.creditnote.date)) || (!isSet(this.creditnote.due_date)) || !item.itemVaild) {
          this.productTouched = true
          this.customercreditnote = true

          return noerror = false
        } else {
          return noerror = true

        }
      })
    }

    if (this.creditnote?.customer?.company_name === 'Cash & Carry') {
      this.creditnote.customer = null
      this.editCuostomericon = null
    }
    if (noerror === false) {
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror === true) {
      try {
        this.loading = true;
        const subscription = this.creditnoteService.addCredit_notes(this.creditnote).subscribe((creditNote: CreditNote) => {

          if (!isSet(creditNote)) {
            return;
          }
          this.messageService.add({
            key: 'form', severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_CREDIT_ADDED')
          });
          this.creditnote.id = creditNote.id
          this.creditnote.status = creditNote.status
          this.creditnote.vcs = creditNote.vcs

          this.creditnote.payments.map(payment => {
            payment.status2 = 'old'
            payment.dateP = new Date(payment.date)

          })

          this.creditnote.items.map(item => {
            if (isSet(item.product?.id)) {
              item.checkinlne = false
            }
            if (isSet(item?.name) && !isSet(item.product?.id)) {
              item.checkinlne = true
            }
            if (isSet(item.service)) {
              item.checkinlne = true
              item.name = item.service
            }
          })
          this.creditnoteService.retrieve_lastCreditnote.next(this.creditnote)


          this.selected_creditnote = true
          this.loading = false;
          subscription.unsubscribe();
          this.creditnoteService.refreshDataTable()
          this.creditnoteService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)
        }, error => {

          if (error.error.detail === 'the creditnote reference must be unique, please try changing it.') {
            const subscription2 = this.creditnoteService.getNextNumber().subscribe((data: { reference: string }) => {
              if (!isSet(data)) {
                return;
              }
              this.creditnote.reference = data.reference;
              this.addCreditnote()


              subscription2.unsubscribe();
            }, (error2) => {
              this.handleError(error2);
              subscription2.unsubscribe();
            });

          } else {
            this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();
        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }


    }

  }

  deletePayment(index) {
    if (this.creditnote?.customer?.due > 0) {
      this.creditnote.customer.due=this.creditnote.customer?.due+ this.totalpayment_refund

    }


    this.totalpayment_refund = this.totalpayment_refund - this.creditnote.payments[index].amount
    this.paymentDue = this.creditnote.creditnoteTotalIncVAT - this.totalpayment_refund

    this.creditnote.payments.splice(index, 1)
    this.getPaymentDue()
  }

  getPaymentDue() {
    const refundamounts = []
    if (this.creditnote.payments.length) {
      this.creditnote.payments.map(item => {
        refundamounts.push(item.amount)
      })
      if (refundamounts.length > 0) {
        this.totalpayment_refund = refundamounts.reduce((prev, next) => prev + next);
      }
    }


    this.paymentDue = this.creditnote.creditnoteTotalIncVAT - this.totalpayment_refund
    if (this.paymentDuefromBalance === false && isSet(this.creditnote.customer?.due)) {
      this.paymentDuefromBalance = true

    }
    if (this.paymentDue === 0) {
      this.paymentDuestatus = true
    } else if (this.paymentDue > 0) {
      this.paymentDuestatus = false
    }
  }

  getcreditnoteTotalChange() {
    this.oldpaymentDue = this.paymentDue
    this.paymentDue = this.creditnote.creditnoteTotalIncVAT

    setTimeout(() => {
      if (!this.creditnote.payments.length) {
        this.paymentDue = this.creditnote.creditnoteTotalIncVAT || 0
      } else if (this.creditnote.creditnoteTotalIncVAT == 0) {
        this.paymentDue = 0

      } else if (this.creditnote.creditnoteTotalIncVAT > 0) {

        this.paymentDue = this.creditnote.creditnoteTotalIncVAT - this.totalpayment_refund
      }
      if (this.paymentDue === 0) {
        this.paymentDuestatus = true
      } else if (this.paymentDue > 0) {
        this.paymentDuestatus = false
      }


    }, 300)

  }
  private subscribeOnFormSaved() {
    const subscription = this.creditnoteService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addCreditnote();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }



  private subscribeOnCreditnoteSelected() {
    const subscription = this.creditnoteService.creditNote_SelectedListEmitter.subscribe((creditNote: CreditNote) => {
      if (!isSet(creditNote)) {
        return
      }

      this.getCredit_note(creditNote.id);
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  private initCreditnote() {
    this.creditnote = CreditNote.cloneObject(null);
    this.creditnote.date = new Date()
    this.creditnote.due_date = new Date((new Date()).getTime() + (30 * 86400000));
    this.creditnote.items = [];
    this.creditnote.payments = []
    this.creditnote.orders = []
    this.payment.dateP = new Date()
    this.creditnote.total_discount = 0
    this.sharedService.removeCandCopformCusotmer.next(true)
    this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.creditnote.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.creditnote.exchange_rate = 1
    this.creditnote.discount = 0
    this.addItem();
  }

  showAddPaymnet() {
    if (this.paymentDue === 0) {
      return
    }
    this.Paymentlabel = this.translate.instant('REFUND_PAYMENT')
    this.payment = new Payment()
    this.payment.dateP = new Date()
    this.payment.status = 'refund'
    this.payment.status2 = 'new'
    this.amount.control.markAsUntouched()
    this.amount.control.clearValidators()
    this.showPaymentDialog = true

  }
  addPayment() {
    
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) === true)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });

      this.amount.control.markAsTouched();

    } else {
      if (this.mathRound(this.payment.amount )> this.mathRound(this.paymentDue )) {

        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_DUE')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_DUE')
        });
      } else {

        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }

        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        if (this.creditnote.payments.length) {
          this.creditnote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.paymentDue = this.paymentDue - Number(this.payment.amount)
          this.totalpayment_refund = this.totalpayment_refund + this.payment.amount

          if (this.paymentDue === 0) {
            this.paymentDuestatus = true
          }
        } else {
          this.creditnote.payments.push(this.payment)
          this.newPayments.push(this.payment)

          this.getPaymentDue()
        }
        this.update_status = true


        this.sharedService.update_form.next(true)

        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()
      }
    }
  }





  getCredit_note(id: string) {
    this.loading = true
    const subscription = this.creditnoteService.getCredit_note(id).subscribe((creditNote: CreditNote) => {

      if (!isSet(creditNote)) {
        return;
      }

      this.selected_creditnote = true
      const cloneObject = CreditNote.cloneObject(creditNote);

      this.creditnote = cloneObject;
      this.heightCardCustomer = true
      const default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      if (this.creditnote.currency?.id != default_currency?.id) {
        this.creditnote.exchange_rateStauts = true

      }

      if (this.creditnote.discount[this.creditnote.discount.length - 1] == "%") {
        this.creditnote.discount = this.creditnote.discount.substring(0, this.creditnote.discount.length - 1)

        this.selected_discount_type.type = 'Percent'
        this.creditnote.discount_type = 'Percent'
      } else {
        this.selected_discount_type.type = 'Amount'
        this.creditnote.discount_type = 'Amount'

      }
      if (this.creditnote.status?.issued) {
        this.creditnote.cn_date = new Date(this.creditnote?.cn_date)
        this.editCuostomericon = null
      }
      if (this.creditnote.status?.issued && !this.creditnote?.orders?.length) {
        this.orders_status = true
      }
      this.creditnote.date = new Date(creditNote.date)
      this.creditnote.due_date = new Date(creditNote.due_date)
      const disconnt = this.creditnote.discont_Percentage

      this.creditnote.items.map(item => {
        item.exchange_rate = this.creditnote.exchange_rate
        item.discont_Percentage = disconnt

        item.datePipe = new Date(item?.date);
        if (isSet(item.product?.id)) {
          item.checkinlne = false
        }
        if (isSet(item?.name) && !isSet(item.product?.id)) {
          item.checkinlne = true
        }
        if (isSet(item.service)) {
          item.checkinlne = true
          item.name = item.service
        }


      })
      if (this.creditnote.payments.length) {
        this.creditnote.payments.map(payment => {
          if (payment.type === 'Wire Transfer') {
            payment.type = 'wt'
          }
          payment.dateP = new Date(payment.date)
          payment.status2 = 'old'

        })

      }
      this.getPaymentDue()

      if (isSet(this.creditnote.customer?.contact)) {
        this.creditnote.customer.contact.forEach(customer => {
          SendEmail.cloneObject(this.sendEmail)
          this.sendEmail.send_to_mails = []
          this.sendEmail.send_to_mails.push(customer.email)

        })
      }

      if (this.creditnote.status?.draft || this.creditnote.status?.issued ) {
        this.sharedService.removeCandCopformCusotmer.next(true)
      }

      if (this.creditnote.discount_type == 'Amount') {
        const restdiscount = this.creditnote.discount
        this.cleardiscont()
        this.creditnote.discount = restdiscount
        this.discont_Percentage()
      }

      subscription.unsubscribe();
      this.creditnoteService.selectCredit_notes(null)
      this.loading = false

      this.getVats_before_afterDiscont()


    }, error => {
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false

    });
  }

  paymentReview(payment: Payment, index: number) {

    if (payment.status2 === 'old') {
      return
    }
    if (payment.notes == 'Total Changed') {
      return
    }
    if (index !== this.creditnote.payments.length - 1) {
      return
    }
    if (this.paymentDue === 0) {
      this.paymentDuestatus = true
    }
    this.payment = new Payment()
    this.payment.amount = payment.amount
    this.payment.notes = payment.notes
    this.payment.type = payment.type
    this.payment.status = payment.status
    this.payment.status2 = payment.status2
    this.payment.dateP = new Date(payment.dateP)
    this.paymentUpdateindex = index
    this.showPaymentDialog = true
    this.Paymentlabel = this.translate.instant('UPDATED_PAYMENT')
  }


  UpdatePayment() {
    const prv_paymnet = new Payment()
    prv_paymnet.amount = Number(this.creditnote.payments[this.paymentUpdateindex].amount)

    prv_paymnet.dateP = new Date(this.creditnote.payments[this.paymentUpdateindex].dateP)
    prv_paymnet.type = this.creditnote.payments[this.paymentUpdateindex].type
    prv_paymnet.notes = this.creditnote.payments[this.paymentUpdateindex].notes
    let paymentDue = Number(this.paymentDue)
    paymentDue = paymentDue - Number(prv_paymnet.amount)
    paymentDue = paymentDue + Number(this.payment.amount)
    if (this.payment.status === 'add') {

      if (((this.payment.amount > paymentDue) && (paymentDue > this.creditnote.creditnoteTotalIncVAT))) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_DUE')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_NO_ZERO')
        });
      }
    } else if (this.payment.status === 'refund') {

      if (this.creditnote.creditnoteTotalIncVAT < paymentDue) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_TOTAL')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_NO_ZERO')
        });
      }
    }
    this.paymentDue = paymentDue
    this.creditnote.payments[this.paymentUpdateindex] = this.payment
    this.creditnote.payments[this.paymentUpdateindex].amount = Number(this.creditnote.payments[this.paymentUpdateindex].amount)

    this.showPaymentDialog = false
    this.update_status = true
    this.payment = new Payment()
    this.getPaymentDue()

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  numberOnly_withdots(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;

  }

  private getNext_CreditnoteFormat() {
    const subscription = this.creditnoteService.getNextNumber().subscribe((data: { reference: string }) => {
      if (!isSet(data)) {
        return;
      }
      const d = new Date()
      if (!isSet(this.creditnote.reference)) {
        this.creditnote.reference = data.reference;
      }
      subscription.unsubscribe()


    }, error => {
      subscription.unsubscribe()
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

    });
  }

  // private getNextInvoiceFormat() {
  //   const subscription = this.creditnoteService.getNextInoviceNumber().subscribe((data: { reference: string }) => {
  //     if (!isSet(data)) {
  //       return;
  //     }
  //     this.creditnote.reference = data.reference;


  //     subscription.unsubscribe();

  //   }, error => {
  //     subscription.unsubscribe()
  //     this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

  //   });
  // }

  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {

        return;
      }
      this.sharedService.Save_object(this.creditnote)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }

  getSavedCreditnote() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((creditnote: any) => {
      if (!isSet(creditnote)) {
        this.sharedService.objectstauts.next(null)
        return;
      }
      if (creditnote.url === this.router.url) {
        this.creditnote = creditnote.object
        this.sharedService.objectstauts.next(true)

      } else {
        this.sharedService.objectstauts.next(null)

      }

    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }

  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }

      this.initCreditnote()
      this.getNext_CreditnoteFormat()
      this.paymentDue=0
      this.sharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }

  updateEmitter() {


    const subscription = this.creditnoteService.upadteFormClickedEmitter.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.updateCreditnote()

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);


  }

  customerChange(customer: Customer) {
    this.creditnote.customer = customer

    if (!isSet(this.creditnote.customer.due)) {
      this.creditnote.customer.due= 0
    }
    this.getcreditnoteTotalChange()
    this.getPaymentDue()
    this.update_status = true
    this.heightCardCustomer = true
  }

  async notempty(event) {
    this.update_status = true
    this.sharedService.update_form.next(true)

    if (this.disable_VatsRatesChecks == false) {
      const restdiscount = this.creditnote.discount
      this.cleardiscont()
      this.creditnote.discount = restdiscount
      await this.discont_Percentage()
      this.getcreditnoteTotalChange()
      await this.getVats_before_afterDiscont()

    }


    if (this.creditnote.discount > 0) {
      const x = this.creditnote.discount

      this.creditnote.discount = 0

      let disconnt = this.creditnote.discont_Percentage
      this.creditnote.items.map(item => {
        item.discont_Percentage = disconnt

      })
      this.creditnote.discount = x
      disconnt = this.creditnote.discont_Percentage
      this.creditnote.items.map(item => {
        item.discont_Percentage = disconnt

      })

    }







    setTimeout(() => {

      if (this.creditnote.creditnoteTotalIncVAT < this.totalpayment_refund) {



        this.disable_payments = true
        this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
        });
      } else {
        this.disable_payments = false

      }
    }, 300);

  }

  onChange_Item(index: number, filed) {

    const qtyS: ElementRef[] = this.qtyS.toArray()
    if (filed === 'qty') {

      qtyS[index].nativeElement.value = this.spaceNumber(this.creditnote.items[index].qty)
      this.creditnote.items[index].qty = this.creditnote.items[index].qty.replace(/\s/g, '')
    }

  }

  updateCreditnote() {

    if (this.mathRound(this.creditnote?.creditnoteTotalIncVAT ) < this.mathRound(this.totalpayment_refund )) {
      
      

      this.disable_payments = true
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        life: 4000,
        detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
      });
    } else {
      this.disable_payments = false

    }
    let noerror: boolean
    this.creditnoteService.newPayments = this.newPayments
    if (isSet(this.creditnote?.items)) {
      this.creditnote?.items.map(item => {
        if (this.creditnote.status?.invoiced === true) {

          if ((!isSet(this.creditnote.customer)) || (!isSet(this.creditnote.reference))
            || (!isSet(this.creditnote.date)) || (!isSet(this.creditnote.due_date)) || (!isSet(item.name))) {
            this.productTouched = true
            this.customercreditnote = true

            return noerror = false
          } else {
            return noerror = true

          }

        } else {


          if ((!isSet(this.creditnote.customer)) || (!isSet(this.creditnote.reference))
            || (!isSet(this.creditnote.date)) || (!isSet(this.creditnote.due_date)) || (!isSet(item.name))
          ) {
            this.productTouched = true
            this.customercreditnote = true

            return noerror = false
          } else {
            return noerror = true

          }
        }

      })
    }

    if (noerror === false) {
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror === true) {
      if (this.update_status === false) {
        this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

      } else {
        try {
          this.loading = true;
          const subscription = this.creditnoteService.updateCredit_note(this.creditnote).subscribe((creditnote: CreditNote) => {

            if (!isSet(creditnote)) {
              return;
            }
            this.loading = false;
            this.creditnote.payments.map(payment => {
              payment.status2 = 'old'
              payment.dateP = new Date(payment.date)

            })
            this.messageService.add({
              key: 'form',
              severity: 'success',
              summary: this.translate.instant('SUCCESSFUL'),
              detail: this.translate.instant('UPDATED_SUCC')
            });
            this.creditnoteService.refreshDataTable()
            this.sharedService.update_form.next(false)
            this.creditnoteService.upadteFormClicked.next(null)
            this.creditnoteService.refreshselected.next(true)

            this.update_status = false;
            this.newPayments = []
            subscription.unsubscribe();

          }, error => {
            this.messageService.add({
              key: 'form',
              severity: 'error',
              summary: this.translate.instant('ERR_ESSAGE'),
              detail: error.error.detail
            });

            this.loading = false;
            subscription.unsubscribe();
            this.creditnoteService.upadteFormClicked.next(null)

          })
        } catch (error) {
          this.loading = false;
          this.log(error);

        }
      }
    }

  }

  private subscribeOnSendEmail() {


    const subscription = this.creditnoteService.sendEmailClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }

      this.sendEmaildialog = true
      this.creditnoteService.sendEmailClicked.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  Save_Message() {
    localStorage.setItem('defaultMessage', this.sendEmail.message)
  }

  send_Email(): void {

    try {
      this.creditnoteService.btnLoading.next(true)
      const subscription = this.creditnoteService.sendEmail(this.sendEmail, this.creditnote.id).subscribe((sendEmail: SendEmail) => {

        if (!isSet(sendEmail)) {
          return;
        }
        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('SENT_EMAILS')
        });
        this.creditnoteService.btnLoading.next(false)
        subscription.unsubscribe();

      }, error => {

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error });

        this.creditnoteService.btnLoading.next(false)
        subscription.unsubscribe();

      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }
    this.sendEmaildialog = false
  }

  send_copyto_myself() {
    const user: User = JSON.parse(localStorage.getItem('user'))
    const email = this.sendEmail.send_to_mails
    if (!isSet(this.sendEmail.send_to_mails)) {
      this.sendEmail.send_to_mails = []
      this.sendEmail.send_to_mails.push(user.email)
    }
    if (this.sendEmail.send_to_mails?.includes(user.email) === false && this.user_email != null) {
      email.push(user.email)
      this.sendEmail.send_to_mails = []
      this.user_email = user.email

      email.map(em => {
        this.sendEmail.send_to_mails.push(em)
      })
    } else {

      this.sendEmail.send_to_mails = this.sendEmail.send_to_mails?.filter(item => item !== user.email)
      this.user_email = null

    }

  }

  onRemovemail() {
    this.sendEmail.send_to_mails.find(email => {
      if (email !== this.user_email) {
        this.user_email = null
      }
    })
  }
  addEmail_onSpace(event: KeyboardEvent) {
    if (event.key === " ") {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }





  princreditnoteViewer() {
    const subscription = this.creditnoteService.printCreditnote_linkEmitter.subscribe((link: any) => {
      if (!isSet(link)) {
        return
      }

      this.pdfViewer = true

      this.creditnote.download_url = link.download_url
      this.creditnote.view_url = link.view_url

      this.creditnoteService.printCreditnote_link.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }


  downloadPdf() {

    window.location.href = this.creditnote.download_url;

  }

  printPdf() {

    printJS({ printable: this.creditnote.download_url, type: 'pdf', showModal: true })

  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }





  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }




  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }

  erace_value(index, filedName: string) {
    const qtyS: ElementRef[] = this.qtyS?.toArray()

    const priceS: InputNumber[] = this.priceS?.toArray()


    if (filedName === 'price') {
      this.creditnote.items[index].price = 0
      setTimeout(() => {

        priceS[index].input.nativeElement.select()


      }, 100)

    } else {
      this.creditnote.items[index].qty = 1
      setTimeout(() => {
        qtyS[index].nativeElement.select()
      }, 100)


    }

  }

  spaceNumber(number) {
    return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

  removeSpaceNumber() {
    const qtyS: ElementRef[] = this.qtyS?.toArray()
    for (let index = 0; index < this.creditnote?.items?.length; index++) {
      const rmspaceQty = qtyS[index].nativeElement.value.replace(/\s/g, '')

      this.creditnote.items[index].qty = parseInt(rmspaceQty)


    }
  }

  showCreditnoteFromDash() {
    const subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }
      if (item.itemName === 'Creditnote') {
        this.getCredit_note(item.item?.id)

      }
      this.sharedService.diplayDash.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }

  expand_inLineProduct(index: number) {
    this.creditnote.items[index].inline_Epand = !this.creditnote.items[index].inline_Epand
  }

  showEidtinlineProduct(index: number) {
    this.inlineProductValue = this.creditnote.items[index].name
    this.indexinlineProduct = index
    this.showaddinlineProduct = true
  }

  edit_inlineProduct() {
    this.creditnote.items[this.indexinlineProduct].name = this.inlineProductValue
    this.showaddinlineProduct = false

  }
  onKeyUp(e) {
    switch (e.keyCode) {
      case 13:
        let focusableElements = document.querySelectorAll('input, button')
        let index = Array.prototype.indexOf.call(focusableElements, document.activeElement)
        if (e.shiftKey)
          this.focus(focusableElements, index - 1)
        else
          this.focus(focusableElements, index + 1)

        e.preventDefault()
        break;
    }

  }
  focus(elements, index) {
    if (elements[index])
      elements[index].focus()

  }
  private getnext_issued_reference() {
    const subscription = this.creditnoteService.getnext_issued_reference().subscribe((data: { cn_format: string }) => {
      if (!isSet(data)) {
        return;
      }

      this.creditnote.cn_format = data.cn_format;

      subscription.unsubscribe()


    }, error => {
      subscription.unsubscribe()
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

    });
  }
  private confirm_CreditEmitter() {
    const subscription = this.creditnoteService.confirmCreditnoteEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }
      this.getnext_issued_reference()
      this.creditnote.cn_date = new Date()
      this.confirm_Credit_dialog = true
      this.creditnoteService.confirmCreditnote.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  confirm_Credit() {
    if ((this.cn_format.control.invalid) ||
      (!isSet(this.cn_format)) || (this.cn_date.control.invalid) || (!isSet(this.cn_date))) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });

      this.cn_format.control.markAsTouched();
      this.cn_date.control.markAsTouched();

    } else {
      try {
        this.loading = true;
        const subscription = this.creditnoteService.convertToIssue(this.creditnote).subscribe((creditNote: CreditNote) => {

          if (!isSet(creditNote)) {
            return;
          }
          this.messageService.add({
            key: 'form', severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('CONV_INTO_ISSU')
          });

          this.creditnoteService.refreshDataTable()
          this.creditnoteService.confirmCreditnote.next(null)
          this.creditnoteService.refreshselected.next(true)

          this.confirm_Credit_dialog = false


          this.loading = false;
          this.subscriptions.push(subscription);

        }, error => {
          this.messageService.add({
            key: 'form',
            severity: 'error',
            summary: this.translate.instant('ERR_ESSAGE'),
            detail: error.error.detail
          });

          this.loading = false;
          this.subscriptions.push(subscription);

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }
  }
  importOrderS() {
    if (!isSet(this.creditnote.customer)) {
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('PLZ_CUS_FIRST')
      })
    }
    if (!isSet(this.cutrrentCustomer)) {
      this.cutrrentCustomer = this.creditnote?.customer
    }
    if (this.orders.length && this.cutrrentCustomer == this.creditnote?.customer) {
      return this.orders_dialog = true

    }
    this.loading = true;
    const subscription = this.creditnoteService.get_cutsomer_orders(this.creditnote?.customer?.id).subscribe((orders: PaginatedResponse<Order[]>) => {



      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = orders.count
      this.total_pages = orders.total_pages
      this.orders = Array(this.ordersCount).fill(0)


      const clone = Order.cloneManyObjects(orders.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.orders[index] = clone[index];

      }
      if (!this.orders.length) {
        this.loading = false;

        return this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('CUS_NO_INVO') });

      }

      this.cutrrentCustomer = this.creditnote?.customer
      this.orders_dialog = true
      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  getNextorderPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = this.mathRound(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.ordersService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.ordersService.ordesrNextPage(this.orderNextPage).subscribe((orders: PaginatedResponse<Order[]>) => {
          if (!isSet(orders)) {
            return;
          }



          const clone = Order.cloneManyObjects(orders.results);
          clone.map(e => {

            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.orders[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  selectOrder(order) {

    this.selectedOrders = []
    this.selectedOrders.push(order)
  }
  serach_orders(e) {
    if (!isSet(e)) {
      return
    }
    this.orders = e


  }
  importorder() {
    this.selectedOrders.push(this.selectedOrder)
    this.order_selected_index = 0
    this.selectedOrders.map(item => {
      if (this.savedOrders.includes(item)) {
        return this.messageService.add({ key: 'formM', severity: 'error', life: 2000, summary: this.translate.instant('ERROR'), detail: item.format + this.translate.instant('EXPE_ADDED') });
      } else {
        this.getOrder(item)
        this.creditnote.orders.push(item)
        this.savedOrders.push(item)
      }
    })
    this.orders_dialog = false
  }
  getOrder(selected: Order) {

    const subscription = this.ordersService.getOrder(selected.id).subscribe((order: Order) => {

      const cloneObject = Order.cloneObject(order);
      this.creditnote.discount = cloneObject.discount

      if (this.creditnote.discount[this.creditnote.discount.length - 1] == "%") {
        this.creditnote.discount = this.creditnote.discount.substring(0, this.creditnote.discount.length - 1)

        this.selected_discount_type.type = 'Percent'
        this.creditnote.discount_type = 'Percent'
      } else {
        this.selected_discount_type.type = 'Amount'
        this.creditnote.discount_type = 'Amount'

      }
      if (this.creditnote.items.length == 1 && this.creditnote?.items[0]?.price == 0) {
        this.creditnote.items = []
      }

      const disconnt = this.creditnote.discont_Percentage
      cloneObject.items.map(item => {
        item.discont_Percentage = disconnt
        item.exchange_rate = 1
        item.itemVaild = true

        item.id = null
        item.datePipe = new Date(item?.date);
        if (isSet(item.product?.id)) {
          item.checkinlne = false
        }
        if (isSet(item?.name) && !isSet(item.product?.id)) {
          item.checkinlne = true
          item.product = new Product()
          item.product.name = item?.name

        }
        if (isSet(item.service)) {
          item.checkinlne = true
        }
        item.order = selected?.format

        this.creditnote.items.push(item)

      })
      this.imported_Order = true
      this.creditnote.currency = order.currency
      // const default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      // if (this.creditnote.currency?.id != default_currency?.id) {
      //   this.creditnote.exchange_rate = order.exchange_rate
      //   this.creditnote.exchange_rateStauts = true

      // }
      this.creditnote.exchange_rate = 1

      this.creditnoteService.currencyChanged.next(this.creditnote.currency)
      this.getPaymentDue()
      this.update_status = true
      this.order_selected_index += 1





      if (this.order_selected_index == this.selectedOrders.length) {
        this.loading = false
        this.orders_dialog = false
        this.selectedOrders = []
      }

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);


  }
  removeorder(i) {
    this.selectedOrder = new Order()
    this.savedOrders.map((item, index) => {
      if (this.creditnote.orders[i].id == item.id) {
        this.savedOrders.splice(index, 1)
      }
    })

    this.creditnote.items.map((item, inedx) => {
      if (item.order == this.creditnote?.orders[i].format) {
        this.creditnote.items.splice(inedx, 1)
      }
    })
    if (this.creditnote.items.length == 0) {
      this.addItem()
    }
    this.creditnote?.orders.splice(i, 1)

    this.update_status = true
  }
  importSelected_Order() {
    const subscription = this.creditnoteService.importform_OrdersModuleEmitter.subscribe((creditNote: CreditNote) => {
      if (!isSet(creditNote)) {
        return
      }
      this.creditnote = CreditNote.cloneObject(creditNote)
      this.heightCardCustomer = true
      this.creditnote.date = new Date((new Date()).getTime() + (15 * 86400000));
      this.creditnote.due_date = new Date((new Date()).getTime() + (30 * 86400000));
      this.creditnote.payments = []
      this.sharedService.removeCandCopformCusotmer.next(true)
      this.creditnoteService.importform_OrdersModule.next(null)
      this.importform_order_module = true
      this.imported_Order = true
      this.creditnote.currency = creditNote.currency
      const default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      if (this.creditnote.currency?.id != default_currency?.id) {
        this.creditnote.exchange_rate = creditNote?.exchange_rate
        this.creditnote.exchange_rateStauts = true

      }
      this.selected_discount_type.type = this.creditnote.discount_type

      this.creditnoteService.currencyChanged.next(this.creditnote.currency)
      this.getPaymentDue()

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  currencyChanged() {
    const subscription = this.creditnoteService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }

      this.creditnote.currency = currancy
      const default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      this.heightCardCustomer = true
      if (this.creditnote.currency != default_currency && this.imported_Order == false) {
        this.creditnote.exchange_rateStauts = true
        this.showAdd_exchange_rate = true

      }


      this.creditnoteService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }
  cleardiscont() {
    this.creditnote.discount = 0

    let disconnt = this.creditnote.discont_Percentage
    this.creditnote.items.map(item => {
      item.discont_Percentage = disconnt
    })
    this.getcreditnoteTotalChange()
  }


  async total_discountchange() {

    if (this.selected_discount_type.type == 'Percent') {
      this.creditnote.discount_type = 'Percent'
    } else {
      this.creditnote.discount_type = 'Amount'

      if (this.creditnote.discount > this.creditnote.beforeDiscont_creditnoteTotalIncVAT) {
        return this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('DIS_BIG_TOTAL')
        });
      }
    }

    await this.discont_Percentage()
    this.getcreditnoteTotalChange()
    await this.getVats_before_afterDiscont()

    setTimeout(() => {

      if (this.creditnote.creditnoteTotalIncVAT < this.totalpayment_refund) {
        this.creditnote.creditnoteTotalIncVAT < this.totalpayment_refund
        this.disable_payments = true

        this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
        });
      } else {
        this.disable_payments = false

      }
    }, 300);
    this.update_status = true



  }

  discont_Percentage() {
    return new Promise((resolve) => {
      this.creditnote.paid_vats = []
      setTimeout(() => {
        const disconnt = this.creditnote.discont_Percentage
        this.creditnote.items.map(item => {
          item.discont_Percentage = disconnt

        })
        resolve(null)

      });


    })
  }

  getVats_before_afterDiscont() {
    return new Promise((resolve) => {
      this.creditnote.paid_vats = []
      setTimeout(() => {
        this.vat_Percentage.map(vat => {
          let beforediscont = 0
          let afterdiscont = 0

          beforediscont = _.sumBy(this.creditnote?.items, (item) => {
            if (item?.vat?.id == vat.id) {
              return item?.beforediscont_vatAmount
            } else {
              return 0
            }

          })
          afterdiscont = _.sumBy(this.creditnote?.items, (item) => {
            if (item?.vat?.id == vat?.id) {
              return item?.vatAmount
            } else {
              return 0
            }

          })
          if (beforediscont > 0) {

            let v = {
              name: vat?.value,
              beforediscont: beforediscont,
              afterdiscont: afterdiscont

            }

            this.creditnote.paid_vats.push(v)
            console.log( this.creditnote.paid_vats);

            resolve(this.creditnote.paid_vats.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

          }

        })
      }, 300);


    })
  }
  focusThenShow(type){
    switch (type) {
      case 'date':
        setTimeout(()=>{
          this.creditDateToggle.toggle()
        },0)
        break;
      case 'dueDate':
        setTimeout(()=>{
          this.creditDueDateToggle.toggle()
        },0)
        break;
    }
  }

  mathRound(value){
		return	Math.round(value*100)/100;
	}
}
