import { NgIf } from '@angular/common';
import { isSet } from '../modules/core/util';

export class Contact {
  name?: string;
  position?: string;
  email?: string;
  phone?: string;
  note?: string;
  GSM?: string;
  fax?: string;
  mobile_no?: number
  public constructor(params?: Contact) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Contact) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Contact(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Contact[]) {
    const objects: Contact[] = [];
    for (const obj of objectsToClone) {
      objects.push(Contact.cloneObject(obj));
    }

    return objects;
  }
}
