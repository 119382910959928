import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenData } from '../../models/tokendata';
import { isSet } from './util';
import { User } from '../../models/user';
import { environment } from 'src/environments/environment';
import { UserToken } from 'src/app/models/usertoken';
export interface HttpOptions {
  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public login_info: BehaviorSubject<UserToken> = new BehaviorSubject<UserToken>(null);
  public login_infoEmitter: Observable<UserToken> = this.login_info.asObservable();

  private readonly apiUrl;
  private  bearerToken;
  private  companyId;

  constructor(private httpClient: HttpClient) {
    let url = environment.apiUrl;
    if (url.charAt(url.length - 1) === '/') {
      url = url.slice(0, url.length - 1);
    }

    this.apiUrl = url;
    const tokenDataStorage = localStorage.getItem('token_data');
    const userStorage = localStorage.getItem('user');
    if (isSet(tokenDataStorage) && isSet(userStorage)) {
      const tokenData = JSON.parse(tokenDataStorage) as TokenData;
      this.bearerToken = tokenData.access;

      const user = JSON.parse(userStorage) as User;
      this.companyId = user?.user_companies[0]?.company;
    }else{
      this.getLogin_infoEmitter()

    }
  }
  getLogin_infoEmitter() {
    const subscription = this.login_infoEmitter.subscribe((data: UserToken) => {
      if (!isSet(data)) {
        return;
      }
      
      this.bearerToken = data.token_data.access;
      this.companyId = data.user_data.user_companies[0].company;


      subscription.unsubscribe()
      this.login_info.next(null)
    }, error => {
    });

  }

  post<T>(url: string, body: any | null): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.post<T>(`${this.apiUrl}${url}`, body, {
      headers: this.getHeaders()
    });
  }

  postGuest<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.post<T>(`${this.apiUrl}${url}`, body);
  }

  patch<T>(url: string, body: any | null): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.patch<T>(`${this.apiUrl}${url}`, body, {
      headers: this.getHeaders()
    });
  }

  put<T>(url: string, body: any | null): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.put<T>(`${this.apiUrl}${url}`, body, {
      headers: this.getHeaders()
    });
  }

  get<T>(url: string): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;


    return this.httpClient.get<T>(`${this.apiUrl}${url}`, {
      headers: this.getHeaders()
    })
  }

  delete<T>(url: string): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;


    return this.httpClient.delete<T>(`${this.apiUrl}${url}`, {
      headers: this.getHeaders()
    })
  }
  getGuest<T>(url: string, options?: HttpOptions): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.get<T>(`${this.apiUrl}${url}`);
  }

  generate_upload_url<T>(url: string, body: any | null): Observable<T> {
    url = url.charAt(0) === '/' ? url : `/${url}`;
    return this.httpClient.post<T>(`${this.apiUrl}${url}`, body, {
      headers: this.getHeaders()
    })
  }
  deleteFile(url: string, body: any | null): Observable<any> {
    const options = {
      headers: this.getHeaders(),
      body: body
    };
    url = url.charAt(0) === '/' ? url : `/${url}`;

    return this.httpClient.request('DELETE',
      `${this.apiUrl}${url}`, options)
  }
  Uploadfile<T>(url: string, body: any | null,): Observable<T> {
    // let headers = new HttpHeaders
    return this.httpClient.post<T>(`${url}`, body);
  }
  
  post_noHheader(url: string, body: any | null): Observable<any> {
  
    url = url.charAt(0) === '/' ? url : `/${url}`;

    return this.httpClient.post<any>(`${this.apiUrl}${url}`, body)
  }

  private getHeaders() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.bearerToken}`,
      Accept: 'application/json'
    });
    return headers.append('company', `${this.companyId}`);
  }
getfile(url: string){
 return this.httpClient.get(`${this.apiUrl}${url}`,{
    responseType: 'arraybuffer',headers:this.getHeaders()})
    
    }


}


