<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid list-purchase">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (remove)="removedialogueeQ = true" (Reload)='Reload()' [disableRemove]="!selected.length"
        addLabel2='{{"NEW_PUR_ORDER" | translate}} ' addLabel='{{"RQ_FOR_QUOTION" | translate}} ' (add)="checkQoute()"
        (add2)="showAddPurchaseOrders()">

        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredProducts" [field_name]="keyword"
          (filter)="serach($event)" (onClear)="clearOrders_queries()" (completeMethod)="completeMethod($event)">
        </app-Search-select>
      </app-table-header>
      <p-table appendTo="body" [value]="purchase" [(selection)]="selected" [customSort]="true"
        (onPage)="getNextorderPage($event)" styleClass="p-datatable-striped" [paginator]="true" [rows]="10"
        [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries"
        [globalFilterFields]="['internal_reference','type','vendor.name','date','total','status.status.value']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="internal_reference">

              <span
                [style.color]="filterModeFormat_query?.type && filterModeFormat_query?.value?'var(--primary-color)':null">
                {{"ORDERS_FORM_REFERENCE" | translate}}</span>

              <p-sortIcon (click)="customSort('internal_reference')" field="internal_reference"></p-sortIcon>



              <p-columnFilter #referenceFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE" | translate}} ' optionValue="type" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" placeholder="Enter Reference" class="p-mt-3"
                    type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-12">
                      <div class="p-grid">
                        <div class="p-col-6">
                          <div class="field-checkbox">
                            <p-checkbox [disabled]="fitlerQuote==false" (onChange)='onChange_Type_queries("Order")'
                              [binary]="true" [(ngModel)]="fitlerOrder">
                            </p-checkbox>
                            <label class="p-ml-2">{{"DASHBORD_QUOTE" | translate}}</label>
                          </div>

                        </div>
                        <div class="p-col-6">
                          <div class="field-checkbox">
                            <p-checkbox [disabled]="fitlerOrder==false" (onChange)='onChange_Type_queries("Quote")'
                              [binary]="true" [(ngModel)]="fitlerQuote">
                            </p-checkbox>
                            <label class="p-ml-2">{{"DASHBORD_ORDER" | translate}}</label>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="(!filterModeFormat_query?.type || !filterModeFormat_query?.value) && !filterModeType_query.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('internal_reference',filterModeFormat_query?.type,filterModeFormat_query?.value);referenceFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
              <!-- <p-columnFilter type="text" field="internal_reference" display="menu"></p-columnFilter> -->
            </th>
            <!-- <th>
              <span
              [style.color]="filterModeType_query?.type || filterModeType_query?.value?'var(--primary-color)':null">
              Type</span>
              <p-columnFilter class="p-ml-2" type="text" matchMode="equals" field="type" display="menu"
              [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter">


                <p-dropdown [options]="purchaseTypes" [showClear]="true" [(ngModel)]="filterModeType_query.value"
                  placeholder="Select Type" optionLabel="type" optionValue="type">
                  <ng-template let-item pTemplate="item">
                    <span >{{item.type}}</span>


                  </ng-template>

                </p-dropdown>
                <div class="p-grid p-mt-2">
                  <div class="p-col-6">
                    <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                      type="button">
                    </button>
                  </div>
                  <div class="p-col-6">
                    <button pButton [disabled]="!filterModeType_query?.value " label='{{"APPLY" | translate}}'
                      (click)="getOrders_queries('type','exact',filterModeType_query?.value)" type="button">
                    </button>
                  </div>
                </div>
              </ng-template>
            </p-columnFilter>

            </th> -->
            <th>
              <span [style.color]="filterModeApply_Vendor?'var(--primary-color)':null">{{"DASHBORD_VENDOR" |
                translate}}</span>
              <p-columnFilter #vendorFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name">
                <ng-template pTemplate="filter">
                  <app-supplier-select [filterMode]="true" *ngIf="filterModeApply_Vendor==false"
                    [(supplier)]="selected_Vendorquery"></app-supplier-select>
                  <input *ngIf="filterModeApply_Vendor==true" [(ngModel)]="selected_Vendorquery.name" class="p-mt-3"
                    type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}'
                        (click)="clearOrders_queries();filterModeApply_Vendor=false" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Vendorquery?.id" label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('vendor',null,selected_Vendorquery?.id);filterModeApply_Vendor=true;vendorFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>

            </th>
            <th pSortableColumn="date">
              <span [style.color]="filterModeDate_query?.type||filterModeDate_query?.value?'var(--primary-color)':null">
                {{"DASHBORD_DATE" | translate}}</span>
              <p-sortIcon (click)="customSort('date')" field="date"></p-sortIcon>

              <p-columnFilter #dateFilter class="p-ml-2" type="date" field="date" matchMode="equals" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDate_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar yearNavigator=true yearRange='2018:2024' dateFormat="dd-mm-yy" [showIcon]="true"
                      [(ngModel)]="filterModeDate_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDate_query?.type||!filterModeDate_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('date',filterModeDate_query.type,filterModeDate_query.value);dateFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="total">
              <span
                [style.color]="filterModeTotal_query?.type||filterModeTotal_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_TOTAL"
                | translate}}</span>
              <p-sortIcon (click)="customSort('total')" field="total"></p-sortIcon>

              <p-columnFilter #totalFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeTotal_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0" [(ngModel)]="filterModeTotal_query.value" [minFractionDigits]="2"
                      mode="decimal"></p-inputNumber>

                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeTotal_query?.value || !filterModeTotal_query?.type"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('total',filterModeTotal_query?.type,filterModeTotal_query?.value);totalFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>

                </ng-template>



              </p-columnFilter>
            </th>

            <th>
              <span [style.color]="filterModeInvoice_status?.value?'var(--primary-color)':null">
                {{"DASHBORD_INVOICE" | translate}}</span>
              <p-columnFilter #invoiceFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <input id="disabled-input" type="text" pInputText [disabled]="true" [(ngModel)]="firtlerInvoiced" />


                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="filterModeInvoice_status?.value " label='{{"APPLY" | translate}}'
                        (click)="filterModeInvoice_status.value='invoiced';getOrders_queries('status',filterModeInvoice_status?.value ,true);invoiceFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>

              <span
                [style.color]="filterModeDelivered_status?.value&&filterModePaid_status?.value?'var(--primary-color)':null">
                {{"DELVERF_PAID" | translate}}</span>
              <p-columnFilter #delveredPaidFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">


                  <p-dropdown [options]="delivered_status" [showClear]="true"
                    [(ngModel)]="filterModeDelivered_status.value" placeholder="Delivered Status"
                    optionLabel="status.label" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label |translate}}</span>


                    </ng-template>

                  </p-dropdown>
                  <p-dropdown styleClass="p-mt-3" [options]="paid_status" [showClear]="true"
                    [(ngModel)]="filterModePaid_status.value" placeholder="Paid Status"
                    (onChange)="paid_Status_queries()" optionLabel="status.label" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label |translate}}</span>


                    </ng-template>

                  </p-dropdown>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDelivered_status?.value || !filterModePaid_status?.value "
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('status',filterModeDelivered_status?.value ,true);delveredPaidFilter.overlayVisible=false" type="button" >
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>



            <th>
              <span
                [style.color]="filterModeDelivered_status?.value&&!filterModePaid_status?.value?'var(--primary-color)':null">
                {{"QUOOTE_STATUS" | translate}}</span>
              <p-columnFilter #quoteFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">


                  <p-dropdown [options]="statuses" [showClear]="true" [(ngModel)]="filterModeDelivered_status.value"
                    placeholder="Select Status" optionLabel="status.label" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label |translate}}</span>


                    </ng-template>

                  </p-dropdown>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDelivered_status?.value " label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('status',filterModeDelivered_status?.value ,true);quoteFilter.overlayVisible=false" type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th>PO/RQ

            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox (click)="seletedPurchaseOrders(rowData )" [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showPurchaseOrders(rowData,i,rowData.type)" href="javascript:;">{{rowData.type}}
                ({{rowData.internal_reference }})</a></td>
            <!-- <td>{{rowData.type }}</td> -->
            <td>{{ rowData.company_name }}</td>

            <td>{{ rowData.date | date:'d-M-y' }}</td>

            <td *ngIf="rowData.type=='Order'">{{ rowData.total | number:'1.2-2' }} <span
                style="color: var(--primary-color);font-size: small;">{{
                rowData.currency?.name }}</span></td>
            <td *ngIf="rowData.type=='Quote'" style="padding-left: 30px;">ــ</td>

            <td>
              <!-- <p-button *ngIf="rowData?.type=='Order' && isSet(rowData.invoice_attachment)" label="Invoiced"
                [style]="{ height: '36px'}"  [disabled]="loadingbtnPrint"
                (onClick)="viewPdf(rowData,i)" styleClass="p-button-secondary"></p-button> -->

              <span style="cursor:pointer;" *ngIf="rowData?.type=='Order' && isSet(rowData.invoice_attachment) else b"
                (click)="viewInvoice(rowData,i)" [class]="rowData.status?.status?.color">
                <i class="fas fa-eye h6"></i>
                {{rowData.status?.status?.label}}</span>
              <ng-template #b>
                <span style="padding-left: 30px;">ــ</span>
              </ng-template>
            </td>
            <td>
              <span *ngIf="rowData?.type=='Order'" [class]="rowData.delivered_status.status.color">
                {{rowData?.delivered_status?.status?.label | translate }}
              </span>
              <span *ngIf="rowData?.type=='Order'"> / </span>
              <span *ngIf="rowData?.type=='Order'" [class]="rowData.status?.status?.paid_color ">
                {{rowData.status?.status?.paid_status |translate }}
              </span>
              <span *ngIf="rowData?.type=='Quote'" style="padding-left: 30px;">ــ</span>
            </td>
            <td>
              <span *ngIf="rowData?.type=='Quote'" [class]="rowData.status.status.color">{{rowData.status?.status?.label |translate }}</span>
              <span *ngIf="rowData?.type=='Order'" style="padding-left: 30px;">ــ</span>
            </td>
            <td>
              <p-splitButton *ngIf="rowData?.type=='Quote'" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
                [style]="{ height: '36px'}" [icon]="rowData?.loadprint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'"
                [disabled]="loadingbtnPrint" (onClick)="printQuote_list(rowData,i)"
                (onDropdownClick)="onDropdownClick_language(rowData,i)" [model]="langOpctions"
                styleClass="p-button-secondary"></p-splitButton>

              <p-splitButton *ngIf="rowData?.type=='Order'" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
                [style]="{ height: '36px'}" [icon]="rowData?.loadprint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'"
                [disabled]="loadingbtnPrint" (onClick)="printOrder_list(rowData ,i)"
                (onDropdownClick)="onDropdownClick_language(rowData,i)" [model]="langOrderOpctions"
                styleClass="p-button-secondary"></p-splitButton>

            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [title2]="orderstatusTitle"
  [size]="size">
  <div header>
    <button style="margin-right: 5px;"
      *ngIf="selectedPurchaseOrders?.type=='Quote'&& !selectedPurchaseOrders?.status?.completed"
      (click)="convertPurQuote()" pButton type="button" label='{{"CONVERT_PUR" | translate}}'
      class="p-button-secondary"></button>
    <button *ngIf=" !selectedPurchaseOrders && qouteCheck==null" pButton (click)="saveFormQuote()" type="button"
      label='{{"SAVE_QUOTION" | translate}}' class="ui-button-raised ui-button-info"></button>
    <span *ngIf="selectedPurchaseOrders?.type=='Order'">

      <button
        *ngIf="(!isSet(selectedPurchaseOrders?.invoice_attachment?.path ))|| loadingbtnupload!=false; else viewRec"
        pButton type="button" style="margin-right: 5px;"
        [icon]="loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'" (click)="showUploadInovice()"
        label='{{"UPLOAD_INVOICE" | translate}}' class="p-button-secondary" for="upload-photo"></button>

      <input #upload type="file" id="upload-photo" accept=".jpg,.jpeg,.png,.pdf," [imageToDataUrl]="options"
        (imageSelected)="myUploader($event)">

      <ng-template #viewRec>
        <button style="margin-right: 5px;" pButton type="button" icon="fas fa-eye" (click)="showViewInoivce()"
          label='{{"VIEW_INVOICE" | translate}}' class="p-button-secondary" for="upload-photo"></button>

      </ng-template>
    </span>


    <!-- *ngIf="selectedPurchaseOrders.status.invoiced==true" -->

    <!-- <p-splitButton *ngIf="(!selectedPurchaseOrders && qouteCheck==true)"
       [label]="'Currancy :'+ selectedCurrancy"
      [model]="currancyOptions"
      class="p-mr-2 ui-button-info" styleClass="p-button-secondary"></p-splitButton> -->


    <p-splitButton *ngIf="selectedPurchaseOrders?.type=='Quote'"
      (onClick)="printQuote_list(selectedPurchaseOrders,purchaseSelectedIndex)"
      label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
      [icon]="loadingbtnPrint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'" [model]="langOpctions" class="p-mr-2"
      styleClass="p-button-secondary"></p-splitButton>
      
      <p-splitButton *ngIf="selectedPurchaseOrders?.type=='Order'"
      (onClick)="printOrder_list(selectedPurchaseOrders,purchaseSelectedIndex)"
      label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' [model]="langOrderOpctions"
      [icon]="loadingbtnPrint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'" class="p-mr-2"
      styleClass="p-button-secondary"></p-splitButton>

      <button *ngIf=" selectedPurchaseOrders" (click)="duplicateForm()" pTooltip='{{"DUPLICATE" | translate}}'
      tooltipPosition="bottom" style="margin-right: 5px;" pButton type="button" icon="fas fa-clone"
      class="p-button-secondary"></button>
      
      <button *ngIf=" selectedPurchaseOrders" (click)="sendByEmail()"
      pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" style="margin-right: 5px;" pButton
      type="button" [disabled]="loadingbtnemail" [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'"
      class="p-button-secondary"></button>

      <button type="button" *ngIf="selectedPurchaseOrders" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"class="p-button-secondary"
      pTooltip="Send to Whatsapp" (click)="sendToWhatsapp(selectedPurchaseOrders?.id)"style="margin-right: 5px;"></button>

    <button pButton style="margin-right: 5px;" *ngIf="qouteCheck==true" (click)="currecy.toggle($event)"
      [disabled]="selectedPurchaseOrders?.id" class="p-button-secondary p-mr-2" type="button"
      [label]="selectedCurrancy?.name"> </button>

      <button (click)="deleteseleted(selectedPurchaseOrders)" *ngIf="selectedPurchaseOrders?.id " pButton pRipple
      type="button" icon="fas fa-trash" [pTooltip]="'Delete ' + selectedPurchaseOrders?.internal_reference"
      tooltipPosition="bottom" class="p-button-secondary" style="margin-right: 5px;"></button>
    <p-tieredMenu appendTo="body" #currecy [model]="currancyOptions" [popup]="true"></p-tieredMenu>


    <button *ngIf="!selectedPurchaseOrders && qouteCheck==true" pButton (click)="saveForm()" type="button" icon="pi pi-plus"
      label='{{"SAVE_PUR" | translate}}' class="ui-button-raised ui-button-info"></button>
    <button style="margin-left: 22px;" *ngIf="selectedPurchaseOrders?.type=='Order' && selectedPurchaseOrders"
      (click)="Update()" pButton type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
      class="ui-button-raised ui-button-info"></button>
    <button style="margin-left: 22px;" *ngIf="selectedPurchaseOrders?.type=='Quote' && selectedPurchaseOrders"
      (click)="UpdateQuote()" pButton type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
      class="ui-button-raised ui-button-info"></button>
  </div>
  <div body>
    <app-PurchaseOrders-form *ngIf="showSidebar"></app-PurchaseOrders-form>
  </div>
</app-sidebar>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" header='{{"DEL_PUR_ORDER" | translate}}' [(visible)]="removedialogueeQ"
  [style]="{width: '30vw'}">
  <div *ngIf="this.selected.length > 1 " class="p-text-justify ">{{"DELETE_CONF" | translate}}
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify ">Are you sure want to delete
    <span style="color: var(--primary-color)">{{selected[0]?.internal_reference}} ?</span>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="removePurchaseOrders()"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialogueeQ=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" 
  [resizable]="false" [draggable]="false" class="p-shadow-1"
  [header]="'Invoice #'+selectedPurchaseOrders?.internal_reference" [(visible)]="inoivceViewer"
  [style]="{width: '60vw'}">

  <div style=" position: relative;" *ngIf="showDialogView =='pdf'" [style.height.px]="pdfheight">
    <pdf-viewer #pdf [src]="selectedPurchaseOrders?.viewImg" [render-text]="true" [show-all]="true"
      [stick-to-page]=" true" [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block ;z-index: 1;"
      (after-load-complete)="afterLoadComplete($event)" (on-progress)="pdfLoader($event)" ></pdf-viewer>
  </div>

  <div class="p-grid text-align-center" *ngIf="showDialogView =='invoice'">
    <div class="p-col-12"><img [src]="selectedPurchaseOrders?.viewImg "></div>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()"
      label=' {{"DOWNLOAD" | translate}} '></button>
    <button *ngIf="!removeInvoiceDisabled" type="button" (click)="removeInvoice_attachmet()" pButton icon="fas fa-trash"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} ' class="p-button-danger"></button>
      <button *ngIf="!removeInvoiceDisabled" type="button" pButton icon="pi pi-envelope" (click)="sendByEmail()"
      label=' {{"QUOTES_LIST_SEND_TO_EMAIL" | translate}} '></button>
      <button *ngIf="removeInvoiceDisabled" type="button" pButton icon="pi pi-envelope" (click)="sendByEmailList()"
      label=' {{"QUOTES_LIST_SEND_TO_EMAIL" | translate}} '></button>
    <button type="button" pButton icon="fab fa-whatsapp" tooltipPosition="bottom" showDelay="500"
             (click)="sendToWhatsapp()"
            label=' {{"Send to Whatsapp" | translate}}' ></button>
    <button type="button" pButton icon="pi pi-times" (click)="inoivceViewer=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" header='{{"ORDERS_FORM_SEND_BY_EMAIL" | translate}}'
  [(visible)]="sendEmaildialogList" [style]="{width: '65vw'}">
  <div class="p-field p-grid">
    <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 ">{{"QUOTES_Form_RECIPIENTS" |
      translate}}</label>
    <div class="p-col">
      <p-chips (onRemove)="onRemovemail()" addOnBlur='true' (keydown)="addEmail_onSpace($event)" id="Recipients"
        name="send_to_mails" [inputStyle]="{width: '49vw'}" [(ngModel)]="sendEmail.send_to_mails">
        <ng-template let-item pTemplate="item">
          <span>{{item}}</span>
        </ng-template>
      </p-chips>
      <div class="p-mt-2">
        <p-checkbox [binary]="user_email" [(ngModel)]="user_email" (onChange)="send_copyto_myself()"
          label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>
      </div>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"QUOTES_Form_SUBJECT" | translate}}</label>
    <div class="p-col">
      <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>

    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"MESSAGE" | translate}} </label>
    <div  class="p-col">
      <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
        [style]="{'height':'180px'}"></p-editor>
      <div class="mt-2">
        <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="Save_Message()"></p-checkbox>
      </div>

    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-send" (click)="send_Email()" label='{{"QUOTES_Form_SEND" | translate}}'
      icon="far fa-paper-plane"></button>

    <button type="button" pButton icon="pi pi-times" (click)="sendEmaildialogList=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>