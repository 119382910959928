<app-loading *ngIf="loading"></app-loading>

<div class="p-grid">
  <div class="p-col-3">
    <div class="my-card p-grid">
      <div class="p-col-2 text-align-center">
        <span class="my-icon bg-blue">
          <i class="fas fa-2x fa-file-invoice-dollar"></i>
        </span>
      </div>
      <div class="p-col-10 line-height-48">
        <h3 class="p-m-0">440 <small>{{"PRODUCT_DASHBORD_TOTAL_SALES" | translate}}</small></h3>
      </div>
    </div>
  </div>

  <div class="p-col-3">
    <div class="my-card p-grid">
      <div class="p-col-2 text-align-center">
        <span class="my-icon bg-orange">
          <i class="fas fa-2x fa-credit-card"></i>
        </span>
      </div>
      <div class="p-col-10 line-height-48">
        <h3 class="p-m-0">532 <small>{{"PRODUCT_DASHBORD_REVENUE" | translate}}</small></h3>
      </div>
    </div>
  </div>

  <div class="p-col-3">
    <div class="my-card p-grid">
      <div class="p-col-2 text-align-center">
        <span class="my-icon bg-yellow">
          <i class="fas fa-2x fa-shopping-cart"></i>
        </span>
      </div>
      <div class="p-col-10 line-height-48">
        <h3 class="p-m-0">%28 <small>{{"PRODUCT_DASHBORD_REVENUE_PRO" | translate}}</small></h3>
      </div>
    </div>
  </div>

  <div class="p-col-3">
    <div class="my-card p-grid">
      <div class="p-col-2 text-align-center">
        <span class="my-icon bg-green">
          <i class="fas fa-2x fa-receipt"></i>
        </span>
      </div>
      <div class="p-col-10 line-height-48">
        <h3 class="p-m-0">{{"PRODUCT_DASHBORD_STOCK" | translate}} <small></small></h3>
      </div>
    </div>
  </div>
</div>

<div class="p-grid layout-dashboard">
  <div class="p-col-6">
    <div class="p-grid p-dir-col p-nogutter">
      <div class="p-col-12 ">
        <div class="card statistics">
          <div class="p-grid">
            <div class="p-col-6 p-md-8">
              <h1>{{"PRODUCT_DASHBORD_REVENUE_PROFIT" | translate}}</h1>
            </div>
            <div class="p-col-6 p-md-4 dropdown">
              <p-dropdown [options]="dropdownYears" [(ngModel)]="selectedYear"></p-dropdown>
            </div>
          </div>
          <p-chart id="dashboard-chart" type="line" [data]="lineChartData" [options]="lineChartOptions"></p-chart>
        </div>
      </div>

      <div class="p-col-12">
        <div class="my-card">
          <h3>{{"PRODUCT_DASHBORD_LATEST_ORDERS" | translate}}</h3>
          <p-table [value]="orders">
            <ng-template pTemplate="header">
              <tr>
                <th>{{"CUSTOMER_DASHBORD_ORDER_NO" | translate}}</th>
                <th>{{"DASHBORD_DATE" | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr>
                <td><a href="javascript:;">{{ order.number }}</a></td>
                <td>{{ order.date }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="p-col-12">
        <div class="my-card">
          <h3>{{"PRODUCT_DASHBORD_LATEST_PURCH" | translate}}</h3>
          <p-table [value]="orders">
            <ng-template pTemplate="header">
              <tr>
                <th>{{"PRODUCT_DASHBORD_PURCHASE_NO" | translate}}.</th>
                <th>{{"DASHBORD_DATE" | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr>
                <td><a href="javascript:;">{{ order.number }}</a></td>
                <td>{{ order.date }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

    </div>

  </div>
  <div class="p-col-6">
    <div class="p-grid">

      <div class="p-col-12">
        <div class="my-card">
          <h3>{{"PRODUCT_DASHBORD_TOP_BUYERS" | translate}}</h3>
          <p-table [value]="buyers">
            <ng-template pTemplate="header">
              <tr>
                <th>{{"CUSTOMER_FORM_NAME" | translate}}</th>
                <th>{{"QUOTES_Form_QTY" | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-buyer>
              <tr>
                <td><a href="javascript:;">{{ buyer.name }}</a></td>
                <td>{{ buyer.qty }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="p-col-12">
        <div class="card">
          <h1 class="centerText">{{"PRODUCT_DASHBORD_STOCK_EVO" | translate}}</h1>
          <p-chart type="bar" [data]="stockEvolution"></p-chart>
        </div>
      </div>

    </div>
  </div>
</div>
