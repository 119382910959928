<app-loading *ngIf="loading"></app-loading>
<p-dropdown   appendTo="body" [(ngModel)]="order"
            [options]="filteredPurchaseOrders"
            [showClear]="true"
            placeholder='{{"ORDER_SELECT"| translate}}'
            optionLabel="order_format"
            [required]="true"
            (onChange)="OrdersChanged($event)"
            (onHide)="hide($event)"
            filter="true"
  
            >
            <ng-template let-item pTemplate="item">
              <span [innerHTML]="item.order_format"></span>
            </ng-template>
</p-dropdown>

