<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (Reload)='refreshList()' (remove)="removedialogueeQ = true" [disableRemove]="!selected.length"
        (add)="showAddQuote()" addLabel=' {{"QUOTES_LIST_CREATE_QUOTE" | translate}}'>
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredQuotes" [field_name]="keyword"
          (filter)="serach($event)" (onClear)="clearOrders_queries()" (completeMethod)="completeMethod($event)">
        </app-Search-select>

      </app-table-header>
      <p-table [customSort]="true" styleClass="p-datatable-striped " (onPage)="getNextorderPage($event)"
        [paginator]="true" [value]="quotes" [(selection)]="selected" [rows]="10" [rowsPerPageOptions]="[10,30]"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries"
        [globalFilterFields]="['format','expiry_date','date','total','status.status.label','company_name']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="format">
              <span
                [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_QUOTE_NO" | translate}}</span>
              <p-sortIcon (click)="customSort('format')" field="format"></p-sortIcon>

              <p-columnFilter #quoteNumberFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name"  >
                <ng-template id="statusFilter" pTemplate="filter" >

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label |translate}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('format',filterModeFormat_query?.type,filterModeFormat_query?.value);quoteNumberFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th> <span [style.color]="filterModeApply_Cutstomer?'var(--primary-color)':null">{{"QUOTES_LIST_CUSTOMER" |
                translate}}</span>
              <p-columnFilter #customerList class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name" >
                <!-- <ng-template pTemplate="filter">
                  <app-customers-select *ngIf="filterModeApply_Cutstomer==false" [(customer)]="selected_Cutstomerquery"
                    [filterMode]="true"></app-customers-select>
                  <input *ngIf="filterModeApply_Cutstomer==true" [disabled]="true" [(ngModel)]="selected_Cutstomerquery.company_name"
                    class="p-mt-3" type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries();filterModeApply_Cutstomer=false"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Cutstomerquery?.id" label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('customer',null,selected_Cutstomerquery?.id);filterModeApply_Cutstomer=true;customerList.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template> -->
              </p-columnFilter>
            </th>
            <th pSortableColumn="date">

              <span [style.color]="filterModeDate_query?.type||filterModeDate_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_QUOTE_DATE" | translate}}</span>
              <p-sortIcon (click)="customSort('date')" field="date"></p-sortIcon>

              <p-columnFilter #quoteDate class="p-ml-2" type="date" field="date" matchMode="equals" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDate_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label | translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar dateFormat="dd-mm-yy" [showIcon]="true" yearNavigator=true yearRange='2018:2024'
                      [(ngModel)]="filterModeDate_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDate_query?.type||!filterModeDate_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('date',filterModeDate_query.type,filterModeDate_query.value);quoteDate.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="expiry_date">

              <span
                [style.color]="filterModeexpiry_date_query?.type||filterModeexpiry_date_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_EXPIRY_DATE" | translate}}</span>
              <p-sortIcon (click)="customSort('expiry_date')" field="expiry_date"></p-sortIcon>

              <p-columnFilter #expiryDateFilter class="p-ml-2" type="date" field="expiry_date" matchMode="equals" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeexpiry_date_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label|translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar dateFormat="dd-mm-yy" [showIcon]="true" yearNavigator=true yearRange='2018:2024'
                      [(ngModel)]="filterModeexpiry_date_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="!filterModeexpiry_date_query?.type||!filterModeexpiry_date_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('expiry_date',filterModeexpiry_date_query.type,filterModeexpiry_date_query.value);expiryDateFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>

            <th pSortableColumn="total">
              <span
                [style.color]="filterModeTotal_query?.type||filterModeTotal_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_TOTAL"
                | translate}} </span>
              <p-sortIcon (click)="customSort('total')" field="total"></p-sortIcon>
              <p-columnFilter #totalFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeTotal_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="label">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.label| translate}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0"[(ngModel)]="filterModeTotal_query.value" [minFractionDigits]="2" mode="decimal"></p-inputNumber>

                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeTotal_query?.value || !filterModeTotal_query?.type"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('total',filterModeTotal_query?.type,filterModeTotal_query?.value);totalFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>

                </ng-template>



              </p-columnFilter>
            </th>
            <th>
              <span [style.color]="filterModeStatus_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_STATUS" |
                translate}} </span>
              <p-columnFilter #statusFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">


                  <p-dropdown [options]="statuses" [showClear]="true" [(ngModel)]="filterModeStatus_query.value"
                    placeholder="Select Status" optionLabel="status.label" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label |translate}}</span>


                    </ng-template>

                  </p-dropdown>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeStatus_query?.value " label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('status',filterModeStatus_query?.value ,true);statusFilter.overlayVisible=false" type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>




            <th style="width: 18%;">{{"QUOTES_LIST_CONVERT_ORDER" | translate}} </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showQuote(rowData,i)" href="javascript:;">{{ rowData.format }}</a></td>
            <td>
              {{ rowData?.company_name }}
            </td>
            <td> {{ rowData.date | date:'dd-MM-yyyy' }}</td>
            <td> {{ rowData.expiry_date | date:'dd-MM-yyyy' }}</td>
            <td>{{ rowData.total | number:'1.2-2'}} <span style="color: var(--primary-color);font-size: small;">{{
                rowData.currency?.name }}</span></td>
            <td><span  [class]="rowData.status?.status?.color">{{ rowData.status?.status?.label | translate }}</span></td>
            <td style="padding-left: 22px">
              <button *ngIf="rowData.status?.status?.label=='DRAFT'" (click)="Toorder(rowData)" type="button" pButton
                icon="pi pi-copy" label='{{"QUOTES_LIST_CONVERT_ORDER" | translate}}'
                class="  p-button-secondary"></button>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [title2]="modaltatusTitle"
  [size]="'p-sidebar-lg'">
  <div header>
    <button *ngIf="selectedQuote?.status?.status?.label=='DRAFT'" (click)="convert()" style="margin-right: 5px;"
      icon="pi pi-copy" pButton type="button" label='{{"QUOTES_LIST_CONVERT_ORDER" | translate}}'
      class="p-button-secondary"></button>


      <p-splitButton label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' *ngIf="selectedQuote?.id"
      [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint"
      (onClick)="print()" [model]="langOpctions" styleClass="p-button-secondary"></p-splitButton>

    <button style="margin-left: 5px;" *ngIf="selectedQuote" (click)="duplicateQoute()" style="margin-left: 5px;"
      icon="fas fa-clone" pTooltip="Duplicate Quote" tooltipPosition="bottom" pButton type="button"
      class="p-button-secondary"></button>
 



      <button *ngIf="selectedQuote?.id" (click)="sendemail()" style="margin-left: 5px;" pButton
      pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" type="button"
      [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>

      <button *ngIf="selectedQuote" type="button" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"class="p-button-secondary"
      pTooltip="Send to Whatsapp" (click)="sendToWhatsapp(selectedQuote?.id)"style="margin-left: 5px;"></button>

    <button pButton style="margin-left: 5px;" (click)="currecy.toggle($event)" [disabled]="selectedQuote?.id"
      class="p-button-secondary p-mr-2" type="button" [label]="selectedCurrancy?.name"> </button>
    <p-tieredMenu appendTo="body" #currecy [model]="currancyOptions" [popup]="true"></p-tieredMenu>

    <button (click)="deleteseletedQoute(selectedQuote)" *ngIf="selectedQuote" pButton pRipple type="button"
      icon="fas fa-trash" [pTooltip]="'Delete ' + selectedQuote?.format" tooltipPosition="bottom"
      class="p-button-secondary" style="margin-right: 5px;"></button>
    <!-- <button *ngIf="!selectedQuote"  pButton  type="button" label='{{"CUSTOMER_LIST_SAVE" | translate}}' (click)="saveForm()"class="ui-button-raised ui-button-info"></button> -->
    <p-splitButton *ngIf="!selectedQuote" label=' {{"CUSTOMER_LIST_SAVE" | translate}}' icon="pi pi-plus"
      (onClick)="saveQoute()" [model]="itemsSave" class="ui-button-raised ui-button-info"></p-splitButton>

    <button style="margin-left: 22px;" *ngIf="selectedQuote" (click)="Update()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-quote-form (sidebar)="convertt($event)" *ngIf="showSidebar"></app-quote-form>
  </div>
</app-sidebar>

<p-dialog modal="true" appendTo="body" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1"
  [header]="this.selected.length == 1 ? 'Delete '+ selected[0]?.format : 'Delete Quotes'" [(visible)]="removedialogueeQ"
  [style]="{width: '30vw'}">
  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> Are you sure you want to delete the selected quotes ?
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.format}} ?</span>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="removeQuotes()" label=' Delete'></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialogueeQ=false" label='Cancel'
      class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" appendTo="body" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" header="Convert to order" [(visible)]="convertialogueeQ"
  [style]="{width: '30vw'}">
  <div class="p-text-justify ">{{"QUOTES_LIST_CONFIRM" | translate}} <label style="color: #51749e;">{{
      this.ToOrder.format }}</label> {{"QUOTES_LIST_TO_ORDER" | translate}}</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="convertToOrder()" label='Convert'></button>
    <button type="button" pButton icon="pi pi-times" (click)="convertialogueeQ=false" label='Cancel'
      class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>
