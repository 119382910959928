import { Supplier } from './supplier';
import { isSet } from './../modules/core/util';
import * as _ from 'lodash';
import { OrderItem } from './orders/orderitem';
import { Customer } from './customer';



export class CreditNote {
  id: string;
  vendor: Supplier;
  order_format: string;
  items: OrderItem[];
  note:string
  invoiced : boolean
  currency:string

  get orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'totalNet');
  }

  get orderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'total');
  }

  get orderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatAmount');
  }

  public constructor(params?: CreditNote) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: CreditNote) {
    if (!isSet(objectToClone)) {
      return new CreditNote();
    }
    const obj = new CreditNote(objectToClone);
    obj.vendor = Supplier.cloneObject(objectToClone.vendor);
    if(objectToClone.items) {
      obj.items = OrderItem.cloneManyObjects(objectToClone.items);
    }
 
    return obj;
  }

  public static cloneManyObjects(objectsToClone: CreditNote[]) {
    const objects: CreditNote[] = [];
    for (const obj of objectsToClone) {
      objects.push(CreditNote.cloneObject(obj));
    }

    return objects;
  }

  public toDB() {
    return {
      order_format: this.order_format,
      vendor: this.vendor?.id,
        invoiced:true,
        note: this.note,
           items: _.map(this.items, item => {
        return {
          created_by : item.product.created_by,
          created_at:item.product.created_at,
          updated_at: item.product.updated_at,
          product: item.product.id,
          company:  item.product.company,
          name: item.product.name,
          qty: item.qty,
          qtyType: item.qtyType,
          price: item.price,
          vat: item.vat,
          price_type: item.price_type,
        }
        
      }),
      currency:this.currency  
    }
  }





}
