import { Address } from './address';
import { state } from '@angular/animations';
import { isSet } from '../modules/core/util';
import { Contact } from './contact';
export class CountryCode_currency {
  name: string;
  currency: string ;
  country_code: string;
  public constructor(params?: CountryCode_currency) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: CountryCode_currency) {
    if (!isSet(objectToClone)) {
      return objectToClone;
          }
          const obj = new CountryCode_currency(objectToClone);
          return obj;

   
        }
    public static cloneManyObjects(countryCode_currencyToClone: CountryCode_currency[]) {
      const countryCode_currency: CountryCode_currency[] = [];
      for (const c of countryCode_currencyToClone) {
        countryCode_currency.push(CountryCode_currency.cloneObject(c));
      }
  
      return countryCode_currency;
    }
  
}
