import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'rxjs/operators';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { OrdersService } from '../../orders/orders.service';
import { QuotesService } from '../../quotes/quotes.service';
import { SidebarSize } from '../enums/sidebarSize';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends BaseComponent implements OnInit {

  @Input() size: SidebarSize
  @Input() title: string;
  @Input() title2: string;
  @Input() header_body: string;


  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  crater_form_stauts: boolean
  clearBtn: boolean
  Showsavedialog: boolean
  loading = false
  collection = []
  outSidearea: boolean
  configSideBar: boolean = false
  customSidebar: string
  Showkeep_diloge: boolean
  update_dilgoe: boolean
  hidespace: number = 0
  @ViewChild('sidebar') sidebar: any;


  constructor(private quotesService: QuotesService, private router: Router, private ordersService: OrdersService, private sharedService: SharedService, private elementRef: ElementRef) {
    super();
  }



  ngOnInit(): void {

    this.sharedService.obj_Collection_Emitter.subscribe((item: any[]) => {
      this.collection = item

      item?.map(obj => {
        if (obj.url == this.router.url) {
          
          this.sharedService.saved_object.next(obj)

        }
      })

    }, error => {
      this.handleError(error);

    })
    this.update_form_Emitter()
    this.clearBtnstatus()
    this.dialog_status()
    this.clear_after_save()


  }
  onShowSidebar() {
    this.hidespace = document.body.offsetWidth - this.sidebar.nativeElement.offsetWidth
    this.outSidearea = false

  }    


  Reload() {
    this.visible = false;
    this.visibleChange.emit(this.visible)
    this.quotesService.convertToOrder(null)
    let currentRoute = this.router.url.split('?')[0];

    this.Showsavedialog = false



    setTimeout(() => {
      this.router.navigateByUrl(currentRoute, { skipLocationChange: true })

    }, 300);


  }


  Show_dialog() {
    this.configSideBar = false
    if (this.sharedService.Createform.value == true && this.sharedService.chekcempey.value == true) {
      this.Showsavedialog = true
      this.sharedService.Create_form(null)
      setTimeout(() => {
        this.outSidearea = true

      }, 300);
    } else if (this.sharedService.Createform.value == true) {
      this.Reload()
      this.sharedService.Create_form(null)
      this.visible = false;

      this.visibleChange.emit(this.visible)
      this.sharedService.objectstauts.next(null)

    } else if (this.update_dilgoe == true && !this.sharedService.Createform.value ) {
      this.Showkeep_diloge = true
    } else {

      this.sharedService.Create_form(null)
      this.sharedService.chekcempey.next(null)
      this.sharedService.Set_Saveform__state(null)

      this.Reload()
    }
    this.ngOnInit()

  }
  closeupdate_dilgoe() {

    this.Showkeep_diloge = false;
    this.visible = false;

    this.Reload();
    this.update_dilgoe = false


  }
  Save_form() {
    this.loading = true
    this.sharedService.Set_Saveform__state(true)
    this.sharedService.chekcempey.next(null)
    this.Showsavedialog = false
    this.visible = false;
    this.visibleChange.emit(this.visible)
    this.loading = false

  }
  closeboutton() {
    this.crater_form_stauts = false
    this.clearBtn = null
    this.sharedService.delete_obj()
    this.sharedService.chekcempey.next(null)
    this.sharedService.objectstauts.next(null)
    this.Showsavedialog = false
    this.sharedService.Create_form(null)
    this.ordersService.QouteToOrder(null)
    this.Reload()

  }

  clearAll() {
    this.sharedService.delete_obj()
    this.sharedService.clearAll.next(true)
    this.crater_form_stauts = false
    this.clearBtn = null
    this.sharedService.objectstauts.next(null)

  }



  clearBtnstatus() {
    const subscription = this.sharedService.Createform_Emitter.subscribe(status => {
      if (!isSet(status) || !status) {
        this.crater_form_stauts = false
        return;
      }
      this.crater_form_stauts = status
      if (status == true) {
        this.objStuts()
      }
    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);

  }

  update_form_Emitter() {
    const subscription = this.sharedService.update_form_Emitter.subscribe(status => {
      if (!isSet(status)) {
        return;
      }

      this.update_dilgoe = status
      this.sharedService.update_form.next(null)



    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);

  }
  objStuts() {
    const subscription = this.sharedService.objectstauts_Emitter.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }
      this.clearBtn = status
    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);

  }
  clear_after_save() {
    const subscription = this.sharedService.clearSave_Emitter.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }
      this.clearBtn = status
      setTimeout(() => {
        this.clearAll()
      }, 2000);
      this.sharedService.clearSave.next(null)

    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);

  }

  dialog_status() {
    const subscription = this.sharedService.dialog_status_Emitter.subscribe(status => {

      this.outSidearea = status

    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);

  }


}