import { isSet } from '../modules/core/util';

export class Currency {
  id:string;
  name: string;
  sympol:string
  company_settings: string


  public constructor(params?: Currency) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Currency) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    
    return new Currency(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Currency[]) {
    const objects: Currency[] = [];
    for (const obj of objectsToClone) {
      objects.push(Currency.cloneObject(obj));
    }

    return objects;
  }
}
