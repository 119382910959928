<p-toast position="top-right"></p-toast>
<p-autoComplete #autoComplete [style]="{'width':width}" id="autoComplete-product" appendTo="body" [(ngModel)]="product"
  [suggestions]="filteredProducts" (completeMethod)="search($event)" (onSelect)="productChanged($event)" field="name"
  [dropdown]="true" p-autoComplete [disabled]="disabled" placeholder='{{"DASHBORD_PRODUCT"| translate}} {{"CUSTOMER_FORM_NAME"| translate}} , {{"PRODUCT_LIST_BARCODE"| translate}}'>

  <ng-template let-item let-i="index" pTemplate="item">
    <span #selectItem [innerHTML]="item?.name"></span>

  </ng-template>
</p-autoComplete>
<p-dialog modal="true" *ngIf="displayAddProduct" header='{{"PRODUCT_SELECT_ADD"| translate}}' [(visible)]="displayAddProduct"
  [style]="{width: '25vw'}" (onShow)="outside_Status(true)" (onHide)="closeMiniProductModal()" closeIcon="false"
  [resizable]="false" [draggable]="false">

  <app-loading *ngIf="addProductLoading"></app-loading>

  <div class="p-grid form-group p-mt-2">
    <div class="p-col-12 ">
      <span class="p-float-label">
        <input [(ngModel)]="miniProduct.name" id="product-name-input" type="text" pInputText>

        <label for="product-name-input">{{"PRODUCT_FORM_PRO_NAME"| translate}}&nbsp;

          <label *ngIf="(miniProduct.name == null|| miniProduct.name == '') &&  showreq == true " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

        </label>
      </span>
    </div>
    <div class="p-col-6 p-mt-2">
      <span class="p-float-label">
        <p-inputNumber [min]="0"  [minFractionDigits]="2" 
        [(ngModel)]="miniProduct.sell_price" id="credit-input" mode="decimal"></p-inputNumber>
        <!-- <input [(ngModel)]="miniProduct.sell_price" id="product-price-input" type="number" pInputText> -->
        <label for="product-price-input">{{"PRODUCT_SELECT_PRICE"| translate}}&nbsp;
          <label *ngIf="miniProduct.sell_price == null &&  showreq == true " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

        </label>

      </span>
    </div>

    <div class="p-col-6 p-mt-2">
      <span class="p-float-label">
        <p-dropdown inputId="miniProduct_vat" appendTo="body" [options]="vat_Percentage" 
          [(ngModel)]="miniProduct.vat"  optionLabel="value">
        </p-dropdown>
        <label for="miniProduct_vat">{{"CUSTOMER_LIST_VAT"| translate}} <i class="fas fa-percent"></i></label>

      </span>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="addMiniProduct()"
      label='{{"CUSTOMER_LIST_SAVE"| translate}}'></button>

    <button type="button" pButton icon="pi pi-times" (click)="closeMiniProductModal()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined"></button>

  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  appendTo="body" class="p-shadow-1" header='{{"NEW_SERVICE"| translate}}' [(visible)]="showaddinlineProduct" [style]="{width: '60vw'}">

  <p-editor id="Message" name="inline" [(ngModel)]="inline" ngDefaultControl [style]="{'height':'240px'}"></p-editor>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="add_service()"
      label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="cancel_service()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined p-button-secondary"></button>



  </p-footer>
</p-dialog>