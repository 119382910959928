import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Calendar_event } from 'src/app/models/calendar/event';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private api: ApiService) { }

  getEvents(): Observable<Calendar_event[]> {
    return this.api.get<Calendar_event[]>('/events');
  }
  addEvent(event: Calendar_event): Observable<Calendar_event> {
    return this.api.post(`/events`, event.addtoDB());
  }
  updateEvent(event: Calendar_event) {
    let update = {
      id: event.id,
      title: event.title,
      description: event.description,
      start: event.start,
      end: event.end,
      color: event.color,
      priority: event.priority
    }
    return this.api.patch<Calendar_event>(`/events/rud/${event.id}`, update)
  }
  deleteEvent(event: Calendar_event) {
    return this.api.delete<Calendar_event>(`/events/rud/${event.id}`)
  }
}
