import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/modules/auth/auth.guard';
import { isSet } from 'src/app/modules/core/util';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {
  authGuardStuats : boolean
  constructor(private authGuard : AuthGuard) { }

  ngOnInit(): void {
    this.authGuard.activatedRouteEmitter.subscribe((status:boolean)=>{
   if (!isSet(status)) {
     return
   }
   this.authGuardStuats=status
    })
    
  }

}
