import { Component, HostListener, OnInit } from '@angular/core';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { VendorsService } from '../suppliers.service';
import { Supplier } from '../../../models/supplier';
import { Router } from '@angular/router';
import { Contact } from '../../../models/contact';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Query } from 'src/app/models/query';

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.scss']

})
export class VendorsListComponent extends BaseComponent implements OnInit {

  public selected: Supplier[] = [];
  public supplier: Supplier[] = [];
  public filteredProducts: Supplier[] = [];
  public header_body: string;
  itemSearch: string
  keyword = "name"
  public showSidebar = false;
  public size = SidebarSize.X_X_LARGE;
  public currentModal = 'Dashboard';
  public modalTitle = 'Add new supplier';
  public selectedSupplier: Supplier;
  public RemovedialogueS = false;



  vendorsNextPage: any = 0
  vendorsPrevPage: any = 0
  creditCount: any
  creditCurrentnumber: any
  vendors_inPage = 0
  total_pages = 0
  pageViwed = []
  sortMode: number = 1


  selected_Vendorquery = new Query()
  filterModeVat_query = new Query()
  filterModeContactEmail_query = new Query()
  filterModeContactPhone_query = new Query()
  filterModeContactName_query = new Query()
  filterModeDue_query = new Query()
  filterModeCredit_notes_query = new Query()
  seletedquery_type: string
  currency = JSON.parse(localStorage.getItem('companySettings'))?.currency


  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: 'Not Equal',
      value: 'ne'
    },
    {
      type: 'Equal',
      value: 'exact'
    },
    {
      type: 'Less than', value: 'lt'

    },
    {
      type: 'Grater than', value: 'gt'

    },


  ]
  constructor(private router: Router, private vendorsService: VendorsService, public translate: TranslateService,
    private SharedService: SharedService, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.getVendors();
    this.subscribeOnVendorsRefresh();
    this.showSaveVendors()
  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.modalTitle = this.translate.instant('Supplier Profile')+` : `
        this.header_body = `<span  style="margin-right: 5px;" Class="">${this.selectedSupplier?.name}</span>`
        break;
    }
  }

  public showVendors(Supplier) {

    this.showSidebar = true;
    this.vendorsService.currentSupplier = Supplier;

    this.selectedSupplier = Supplier;
    this.vendorsService.selectSupplier(Supplier);
    this.switchModal('Form');

  }

  public showAddSupplier() {
    this.header_body = null
    this.modalTitle = this.translate.instant('ADD_NEW_SUP')
    this.showSidebar = true;
    this.SharedService.Create_form(true)
    this.vendorsService.selectSupplier(null);
    this.selectedSupplier = null;
    this.vendorsService.currentSupplier = null;
    this.size = SidebarSize.X_LARGE;
    this.currentModal = 'Form';


  }

  public saveForm() {
    this.vendorsService.saveForm();
  }
  upadteFormClicked() {
    this.vendorsService.upadteFormClicked.next(true)
  }

  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.supplier = e


  }
  showSaveVendors() {
    this.loading = true
    const subscription = this.vendorsService.showaftersaveFormdEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      if (status == true) {
        setTimeout(() => {
          this.SharedService.Create_form(null)
          this.selectedSupplier = this.supplier[0];
          this.switchModal('Form');
        }, 2000);
        this.loading = false

      }

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  private subscribeOnVendorsRefresh() {
    const subscription = this.vendorsService.refreshSupplierListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getVendors();
      }
    });
    this.subscriptions.push(subscription);
  }

  private getVendors() {
    this.loading = true
    const subscription = this.vendorsService.getVendors().subscribe((vendors: PaginatedResponse<Supplier[]>) => {
      if (!isSet(vendors)) {
        return;
      }
      this.vendorsNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.creditCount = vendors.count
      this.total_pages = vendors.total_pages
      this.supplier = Array(this.creditCount).fill(0)
      const clone = Supplier.cloneManyObjects(vendors.results);

      clone.map(e => {
       if (e.contacts?.length) {
          e.firstcontact = e.contacts[0]

        }

        this.filteredProducts.push(e)
      })
      this.creditCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.supplier[index] = clone[index];


      }



      subscription.unsubscribe();
      this.loading = false;

    }, error => {
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  getNextVendorsPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.vendors_inPage = event.first
    } else {
      this.vendors_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.creditCount) {
      return
    }

    const num = (this.vendors_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.vendorsNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.vendorsNextPage = num + 1
      }
      if (this.vendorsNextPage > this.total_pages) {
        this.vendorsNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.vendorsNextPage = this.vendors_inPage / 30

    }


    if (this.vendors_inPage >= this.creditCurrentnumber) {

      if (this.vendorsNextPage == this.vendorsPrevPage) {
        this.vendorsNextPage = this.vendorsNextPage - 1
        this.vendorsPrevPage = this.vendorsNextPage
      }
      this.vendorsPrevPage = this.vendorsNextPage

      if (this.pageViwed.includes(this.vendorsNextPage)) {
        return
      } else {
        this.loading = true
        const subscription = this.vendorsService.getVendorsNextPage(this.vendorsNextPage).subscribe((vendors: PaginatedResponse<Supplier[]>) => {
          if (!isSet(vendors)) {
            return;
          }



          const clone = Supplier.cloneManyObjects(vendors.results);
          clone.map(e => {
             if (e.contacts?.length) {
                e.firstcontact = e.contacts[0]

              }

            



            this.filteredProducts.push(e)
          })
          const shown = this.vendors_inPage

          this.vendors_inPage = (this.vendorsNextPage - 1) * 30

          if (this.vendors_inPage > shown && this.vendorsNextPage == this.vendorsPrevPage) {
            this.vendors_inPage = this.vendors_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.supplier[this.vendors_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.vendorsNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  refresh_NextPage() {
    this.loading = true
    const subscription = this.vendorsService.getVendorsNextPage(this.vendorsNextPage).subscribe((vendors: PaginatedResponse<Supplier[]>) => {
      if (!isSet(vendors)) {
        return;
      }



      const clone = Supplier.cloneManyObjects(vendors.results);
      clone.map(e => {
         if (e.contacts?.length) {
          e.firstcontact = e.contacts[0]

        }

        this.filteredProducts.push(e)
      })
      const shown = this.vendors_inPage

      this.vendors_inPage = (this.vendorsNextPage - 1) * 30

      if (this.vendors_inPage > shown && this.vendorsNextPage == this.vendorsPrevPage) {
        this.vendors_inPage = this.vendors_inPage
      }


      for (let index = 0; index < clone.length; index++) {

        this.supplier[this.vendors_inPage + index] = clone[index];

      }

      this.pageViwed.push(this.vendorsNextPage)
      this.loading = false;


      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  getSupplier_queries(query_name, query_type, query_value) {
    this.loading = true;



    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.vendorsService.queris.map((query) => {
      if (query.query_name == query_name && query_name != 'status') {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.vendorsService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.creditCount = 0
    const subscription = this.vendorsService.getVendors().subscribe((vendors: PaginatedResponse<Supplier[]>) => {
      if (!isSet(vendors)) {
        return;
      }
      this.vendorsNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.creditCount = vendors.count
      this.total_pages = vendors.total_pages
      this.supplier = Array(this.creditCount).fill(0)


      const clone = Supplier.cloneManyObjects(vendors.results);
      clone.map(e => {
        if (e.contacts?.length) {
          e.firstcontact = e.contacts[0]

        }
        this.filteredProducts.push(e)
      })

      this.creditCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.supplier[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearSupplier_queries() {
    this.vendorsService.queris = []
    this.seletedquery_type = null
    this.filterModeVat_query = new Query()
    this.filterModeContactName_query = new Query()
    this.filterModeDue_query = new Query()
    this.filterModeContactEmail_query = new Query()
    this.filterModeContactPhone_query = new Query()
    this.selected_Vendorquery = new Query()
    this.getVendors()
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.vendorsService.sort = filed
      return this.getVendors()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.vendorsService.sort = '-' + filed
      return this.getVendors()
    }


  }
  Removesuppliers() {

    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length
      this.selected.map(item => {
        console.log(item.id);

        const subscription = this.vendorsService.DeleteSupplier(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.name + this.translate.instant('DELETED') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_SUP_DEL') });
          }
          this.loading = false;
          this.vendorsService.refreshVendors();
          subscription.unsubscribe();


        }, error => {
          console.log(error);

          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('ERR_OCCEURRED_DELETE') + item.name });
          }
          this.loading = false;
          subscription.unsubscribe();
        })

        this.selected = []
      })




    }
    catch (error) {
      console.log(error)
      this.log(error);
    }

    this.RemovedialogueS = false

  }
  Reload() {
    this.vendorsService.queris = []
    this.vendorsService.sort = null
    const currentRoute = this.router.url;
   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);

    });



  }
  outside_Status(status: boolean) {
    this.SharedService.dialog_status.next(status)
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
