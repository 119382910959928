<p-toast key="form" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid ">
  <div class="p-col-12 col-12-r">
    <div class="p-grid ">
      <div class="p-col-8 col-4-r ">
        <p-card>
          <div [style.height]="heightCardCustomer == true  ?'auto':payment_cardHeight+'px'" class="p-grid">
            <div class="p-col-8 col-4-r ">
              <div class="form-group ">
                <p-inplace [disabled]="creditnote.status?.issued || creditnote.status?.cc " #customer>
                  <span id="customer" aria-required="true" pInplaceDisplay>

                    <button #selecCustomer *ngIf="creditnote?.customer" iconPos="right" [icon]="editCuostomericon"
                      pButton [label]="creditnote.customer.company_name" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace customerinplace-icon"></button>


                    <button [ngClass]="{'alertBtn-Customer': creditnote.customer==null && customercreditnote==true}"
                      *ngIf="!creditnote.customer" id="selectCustomer" pButton label="Select Customer"
                      icon="fas fa-id-card-alt tags" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace">
                    </button>


                    <div *ngIf="creditnote.customer==null && customercreditnote==true" class="alert-Customer">
                      {{"CUSTOMER_FORM_REQUIRED" | translate}}
                    </div>


                  </span>

                  <app-customers-select #customerSelect pInplaceContent [(customer)]="creditnote.customer"
                    (customerChange)="customerChange($event)" (onHide)="customer.deactivate()"></app-customers-select>
                </p-inplace>
              </div>

              <div style="font-weight: 600;"
                *ngIf="isSet(creditnote.customer) && creditnote.customer?.company_name != 'Cash & Carry'"
                class="p-grid  text-muted">
                <div class="p-col-6 ">
                  <!-- <span *ngIf="creditnote.customer?.vat || creditnote.customer?.contact[0]?.email " class="bold"
                    [ngClass]="{'text-muted': creditnote.status?.issued}">
                    Main
                    <br></span> -->
                  <span *ngIf="creditnote.customer?.vat "
                    [ngClass]="{'text-muted': creditnote.status?.issued}">{{"CUSTOMER_LIST_VAT" | translate}}:
                  </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}">{{ creditnote.customer?.vat }}</span><br>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}"
                    *ngIf="creditnote.customer?.contact && creditnote.customer?.contact[0]?.email">{{"CUSTOMER_FORM_EMAIL"
                    | translate}}: </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}"
                    *ngIf="creditnote.customer?.contact && creditnote.customer?.contact[0]?.email">{{
                    creditnote.customer?.contact[0]?.email
                    }} <br> </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}"
                    *ngIf="creditnote.customer?.contact && creditnote.customer?.contact[0]?.phone">{{"CUSTOMER_FORM_PHONE"
                    | translate}}: </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}"
                    *ngIf="creditnote.customer?.contact && creditnote.customer?.contact[0]?.phone">{{
                    creditnote.customer?.contact[0]?.phone
                    }} <br></span>
                  <span *ngIf="creditnote.customer?.invoicing_address?.address" class="bold"
                    [ngClass]="{'text-muted': creditnote.status?.issued}">
                    {{"CUSTOMER_FORM_INV_ADDRESS" | translate}}
                    <br></span>
                  <span *ngIf="creditnote.customer?.invoicing_address?.address"
                    [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.invoicing_address?.address
                    }}<br></span>
                  <span *ngIf="creditnote.customer?.invoicing_address?.city"
                    [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.invoicing_address?.city }} <br></span>
                  <span *ngIf="creditnote.customer?.invoicing_address?.country_code  "
                    [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.invoicing_address?.country_code
                    }}<br></span>


                </div>
                <div *ngIf="creditnote.customer?.shipping_address?.length" class="p-col-6 bcreditnote-left">
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}"
                    class="bold">{{"CUSTOMER_FORM_SHIPP_ADDRESS" | translate}} <br> </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.shipping_address[0]?.address
                    }}<br> </span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.shipping_address[0]?.city
                    }} <br></span>
                  <span [ngClass]="{'text-muted': creditnote.status?.issued}">{{
                    creditnote.customer?.shipping_address[0]?.country_code
                    }}</span>
                </div>
              </div>


            </div>

            <div class="p-col-4">
              <div class="form-group creditnote-header-numbers">

                <div class="p-grid">
                  <div class="p-col-6 text-align-right">
                    <label class="formattext p-pr-2"
                      [ngClass]="{'text-muted': selected_creditnote}">{{"ORDERS_FORM_REFERENCE" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [disabled]="selected_creditnote" #creditnoteNumber>
                      <span pInplaceDisplay>

                        <button *ngIf=" creditnote.reference "
                          style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;     "
                          pButton [label]="creditnote.reference" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                        <button *ngIf=" creditnote.reference == '' && reference.touched  " style="  font-weight: 600;  "
                          pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>

                      </span>
                      <input name="creditnote.reference" #reference="ngModel" [(ngModel)]="creditnote.reference"
                        type="text" pInputText pInplaceContent (blur)="creditnoteNumber.deactivate()">
                    </p-inplace>
                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"CREDIT_DATE" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #creditnoteDate>
                      <span pInplaceDisplay>
                        <button *ngIf=" creditnote.date "
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton (click)="focusThenShow('date')"
                          [label]="creditnote.date | date:'dd-MM-yyyy'" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                        <button *ngIf=" creditnote.date == null && date.touched  " style="  font-weight: 600;  " pButton
                          label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>


                      </span>
                      <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true" #creditDateToggle
                        [(ngModel)]="creditnote.date" #date="ngModel" name="creditnote.date" [required]="true"
                        pInplaceContent (onClose)="creditnoteDate.deactivate()"
                        (onClickOutside)="creditnoteDate.deactivate()" (onSelect)="creditnoteDate.deactivate()">
                      </p-calendar>
                    </p-inplace>

                  </div>
                </div>

                <div class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext ">{{"ORDERS_FORM_DUE_DATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #duedate>
                      <span pInplaceDisplay>
                        <button *ngIf=" creditnote.due_date "
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton (click)="focusThenShow('dueDate')"
                          [label]="creditnote.due_date | date:'dd-MM-yyyy'" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                        <button *ngIf=" creditnote.due_date == null && creditnote_duedate.touched  "
                          style="  font-weight: 600;  " pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}'
                          type="button" (click)="focusThenShow('dueDate')"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid "> </button>

                      </span>
                      <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true" #creditDueDateToggle
                        [(ngModel)]="creditnote.due_date" #creditnote_duedate="ngModel" name="creditnote.due_date"
                        [required]="true" pInplaceContent (onClose)="duedate.deactivate()"
                        (onClickOutside)="duedate.deactivate()" (onSelect)="duedate.deactivate()"></p-calendar>
                    </p-inplace>

                  </div>
                </div>

                <div *ngIf=" creditnote.reference && creditnote.status?.issued"
                  [ngClass]="{'text-muted': creditnote.status?.issued}" class="p-grid">
                  <div class="p-col-6 text-align-right">
                    <label class="formattext p-pr-2">{{"ISSUED_NO" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <button [disabled]="creditnote.status?.issued"
                      style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;     "
                      pButton [label]="creditnote.reference" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>

                  </div>


                </div>
                <div *ngIf=" creditnote.reference && creditnote.status?.issued" class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext ">{{"ISSUED_DATE" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <button *ngIf=" creditnote.cn_date " [disabled]="creditnote.status?.issued"
                      style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                      [label]="creditnote.cn_date | date:'dd-MM-yyyy'" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "></button>



                  </div>
                </div>
                <div *ngIf="creditnote.exchange_rateStauts == true" class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"EXCHANGE_RATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #exchange_rate>
                      <span pInplaceDisplay>
                        <button *ngIf=" creditnote?.exchange_rate "
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                          [label]="creditnote?.exchange_rate | number:'1.2-2'" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "></button>


                      </span>



                      <p-inputNumber [min]="0" [disabled]="creditnote?.status?.issued"
                        (onFocus)="$event.target.select()" (keyup.enter)="onKeyUp($event)"
                        (ngModelChange)="notempty($event)" [(ngModel)]=" creditnote.exchange_rate" mode="decimal"
                        [minFractionDigits]="2" (onBlur)="exchange_rate.deactivate()" pInplaceContent></p-inputNumber>
                    </p-inplace>

                  </div>
                </div>
                <div *ngIf="!orders_status" class="p-grid">
                  <div class="p-col-6 text-align-right">
                    <label class="formattext p-pr-2">{{"DASHBORD_ORDER" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">


                    <button *ngIf="!creditnote?.orders?.length && !creditnote.status?.issued"
                      style=" color: var(--primary-color) !important; font-weight: 600; " icon="fas fa-plus" pButton
                      label='{{"ADD_ORDER" | translate}}' (click)="importOrderS()" type="button"
                      class="  p-button-outlined"> </button>
                    <div class="p-pt-2">
                      <p-chip [removable]="selected_creditnote ?false:true" (onRemove)="removeorder(i)" class="p-ml-2"
                        [label]="item.format" *ngFor="let item of creditnote?.orders;let i = index">
                      </p-chip>
                    </div>
                    <!-- <div *ngIf="creditnote?.orders.length && !creditnote.status?.issued" class="p-pl-3">
                      <button style=" color: var(--primary-color) !important; font-weight: 600;     " pButton
                        label="Add Order" (click)="importOrderS()" type="button"
                        class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>

                    </div> -->



                  </div>
                </div>

              </div>
            </div>


          </div>
        </p-card>
      </div>
      <div class="p-col-4 col-4-r">

        <p-card>
          <div #paymentcard class="p-grid">
            <div class="p-col-12 ">

              <h3 aria-required="true" class="table-inplace">
                <i class="fa-menu fa fa-money-bill-alt tags"></i>
                {{"ORDERS_FORM_PAYMENTS" | translate}}
              </h3>
            </div>

            <div class="p-col-12">

              <p-table id="payment-table" [resizableColumns]="true" [rowHover]="true" [scrollable]="true"
                scrollHeight="153px" [value]="creditnote.payments">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 50%; "> {{"QUOTES_LIST_TOTAL" | translate}}:
                      <span class="p-text-bold" style="color: #51749e;">{{ creditnote.creditnoteTotalIncVAT |
                        number:'1.2-2'}}</span>

                    </th>
                    <th class="text-align-right" style="width: 50%;">
                      <div> {{"ORDERS_FORM_DUE" | translate}}:
                        <span class="p-text-bold" style="color: #51749e;">{{paymentDue | number:'1.2-2'}}</span>

                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                  <tr [ngStyle]="{cursor: rowData.status2=='new'?'pointer':'auto'}" (click)="paymentReview(rowData,i)">
                    <!-- <td style="width: 50px;">

                  </td> -->
                    <td>
                      <div [ngClass]="{'p-ml-5': rowData.status2=='new'}" class="p-text-bold ">
                        {{rowData.notes}}</div>
                      <div style="position: relative;" [ngClass]="{'p-ml-5': rowData.status2=='new'}"
                        class="p-text-light ">
                        <button style="position: absolute; left: -36px;
                      top: -12px;" *ngIf="rowData.status2=='new'" (click)="deletePayment(i)" pButton pRipple
                          type="button" icon="fas fa-trash" class="p-button-lg p-button-text p-button-danger "></button>
                        {{ rowData.dateP | date:'d-M-y' }}
                        <p-tag styleClass="ml-3" severity="primary">{{ rowData.type}}</p-tag>
                      </div>
                    </td>
                    <td style="text-align:end;">
                      <span class="p-text-bold " style="color: #51749e;">
                        <label>-</label>
                        {{rowData.amount | number:'1.2-2'}}</span>
                    </td>

                  </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                  <div class="p-grid ">
                    <div class="p-col-6">
                    </div>
                    <div style="text-align: end !important;" class="p-col-6 ">
                      <p-splitButton #ua appendTo="body" [disabled]="paymentDuestatus"
                        label='{{"ORDERS_FORM_REFUND" | translate}}' icon="fas fa-plus" (onClick)="showAddPaymnet()"
                        [model]="itemsSave" class="ui-button-raised ui-button-info">
                      </p-splitButton>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-card>
      </div>
    </div>


    <p-card>
      <!-- <h3  aria-required="true"   class="table-inplace">
        <i class="fa-menu fa fa-truck-moving tags"></i>
        Inventory
      </h3> -->
      <p-table key="form" [value]="creditnote.items" styleClass="p-datatable-striped p-datatable-gridlines">

        <ng-template pTemplate="header">
          <tr>
            <th [ngClass]="{'expand-item': creditnote.items.length > 1 && !creditnote.status?.issued}"
              style="width: 27% ">{{"QUOTES_Form_ITEM" | translate}}
            </th>
            <th style="width: 13%">{{"DASHBORD_DATE" | translate}}</th>
            <th style="width: 15%">{{"QUOTES_Form_QTY" | translate}}</th>
            <th style="width: 15%">{{"QUOTES_Form_PRICE" | translate}}</th>
            <th style="width: 11%">{{"CUSTOMER_LIST_VAT" | translate}} <i class="fas fa-percent"></i> </th>
            <th style="width: 11%">{{"VAT_AMOUNT" | translate}}</th>
            <th style="width: 15%">{{"QUOTES_LIST_TOTAL" | translate}}</th>
            <th *ngIf="creditnote.items.length > 1 && !creditnote.status?.issued" style="width: 4%"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr [style.background]="'#f2f2f2'"
            *ngIf="(item?.order) && creditnote.items[i]?.order != creditnote.items[i-1]?.order">
            <td colspan="8">
              {{"DASHBORD_ORDER" | translate}} : # <span
                style=" color: var(--primary-color) !important;">{{item.order}}</span>
            </td>
          </tr>
          <tr [style.background]="item.order?'#f8f9fa':null">
            <td [style.padding-left]="creditnote.items.length==1&& !creditnote.status?.issued?6+'px': null "
              #productelemt id="item">
              <app-products-select class="p-pr-3" [width]="productelemt.offsetWidth - 30 + 'px'"
                (keyup.enter)="onKeyUp($event)"
                *ngIf="((isSet(item.product?.id) && item.product?.id !='') || isSet(item?.name)) && item.checkinlne==false ; else checkinlineProduct"
                (service)="serviceItem($event,i)" (inlineProduct)="inlineprodcut($event,i)"
                [disabled]="creditnote.status?.issued" [(product)]="item.product"
                (onChange)="productChanged($event, i)">
              </app-products-select>
              <div *ngIf=" item.name == null && productTouched == true " class="alert-Item">{{"CUSTOMER_FORM_REQUIRED" |
                translate}} </div>

              <ng-template #checkinlineProduct>
                <input *ngIf="!isSet(item?.service) && isSet(item?.name)  ; else inline" #service="ngModel"
                  (ngModelChange)="notempty($event)" [(ngModel)]="item.name" type="text" pInputText>
                <ng-template #inline>
                  <div #inlineProductt class="inlineProduct">
                    <p-editor id="inlineProduct" name="inline" readonly=true [(ngModel)]="item.service" ngDefaultControl
                      [ngClass]="{'showless': item.inline_Epand==false || item.inline_Epand==null}"> </p-editor>
                    <div class="inlineProduct-btnSetings">
                      <button *ngIf="(inlineProductt?.offsetHeight > 138)" type="button" pButton
                        class="p-button-text  p-button-secondary" (click)="expand_inLineProduct(i)"
                        [pTooltip]="item.inline_Epand==true  ?'Compress':'Expand'" tooltipPosition="bottom"
                        [icon]="item.inline_Epand==true  ? 'fas fa-compress-alt':'fas fa-expand-alt'"></button>
                      <button (click)="showEidtinlineProduct(i)" type="button" pButton class="p-button-text p-ml-1"
                        (click)="showEidtinlineProduct(i)" pTooltip='{{"CUSTOMER_LIST_EDIT" | translate}}'
                        tooltipPosition="bottom" icon="fas fa-edit"></button>

                    </div>
                  </div>
                </ng-template>

              </ng-template>
              <!-- [width]="productelemt.offsetWidth-30+'px'"  -->
            </td>
            <td style="width: 17%">
              <p-calendar dateFormat="dd-mm-yy" (keyup.enter)="onKeyUp($event)" [disabled]="creditnote.status?.issued"
                (change)="onChange_Item(i,null)" appendTo="body" [(ngModel)]="item.datePipe" showIcon="true"
                name="item.datePipe">
              </p-calendar>
            </td>
            <td style="width: 10%">
              <div class="clearable-input">
                <input (keypress)="numberOnly($event)" (keyup.enter)="onKeyUp($event)" #item.qty
                  (change)="onChange_Item(i,'qty');notempty($event)" (focus)="$event.target.select()"
                  [disabled]="creditnote.status?.issued" name="item.qty" [(ngModel)]="item.qty" type="text" pInputText>
                <i *ngIf="(isSet(item.qty)&& item.qty !== 1 && item.qty !== '') && !creditnote.status?.issued"
                  (click)="erace_value(i,'qty')" class="pi pi-times"></i>
              </div>
            </td>
            <td style="width: 15%">
              <div class="clearable-input">
                <!-- <input (keypress)="numberOnly_withdots($event)" #item.price type="number" (ngModelChange)="notempty($event)"
                  (change)="onChange_Item(i,'price')" (focus)="$event.target.select()" [disabled]="creditnote.status?.issued"
                  name="item.price" [(ngModel)]="item.price "  type="text" pInputText> -->
                <p-inputNumber [min]="0" #item.price [disabled]="creditnote.status?.issued"
                  (onFocus)="$event.target.select()" (keyup.enter)="onKeyUp($event)" (ngModelChange)="notempty($event)"
                  [(ngModel)]="item.price" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                <i *ngIf="(isSet(item.price)&& item.price !== 0 && item.price !== '') && !creditnote.status?.issued"
                  (click)="erace_value(i,'price')" class="pi pi-times"></i>
              </div>

            </td>
            <td style="width: 15%">

              <p-dropdown (ngModelChange)="notempty($event)" (keyup.enter)="onKeyUp($event)"
                (onChange)="onChange_Item(i,'vat')" [disabled]="creditnote.status?.issued" appendTo="body"
                [options]="vat_Percentage" [(ngModel)]="item.vat" optionLabel="value">
              </p-dropdown>
            </td>
            <td [ngClass]="{'text-muted': creditnote.status?.issued}">
              {{ item.vatAmount | number:'1.2-2'}}
            </td>
            <td [ngClass]="{'text-muted': creditnote.status?.issued}">
              {{ item.item_total | number:'1.2-2'}}
            </td>
            <td *ngIf="creditnote.items.length > 1 && !creditnote.status?.issued" style="width: 5%; ">
              <button id='rm' (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null"
                (keyup.enter)="onKeyUp($event)" (click)="remomveItem(i)" pButton pRipple type="button"
                icon="fas fa-trash" class="p-button-text  p-button-delete p-button-danger"></button>

            </td>
          </tr>
          <tr [style.background]="'#f2f2f2'"
            *ngIf="(item?.order) && creditnote.items[i]?.order != creditnote.items[i+1]?.order && !creditnote.items[i+1]?.order && creditnote.items.length != i+1">
            <td colspan="8">
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-mt-3" *ngIf="!creditnote.status?.issued">
        <button (click)="addItem()" (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null"
          (keyup.enter)="onKeyUp($event)" pButton type="button" icon="pi pi-plus"
          label='{{"CUSTOMER_FORM_ADD" | translate}}' class="ui-button-raised p-mt-2"></button>
        <label class="p-ml-3">{{"QUOTES_Form_OR_PREES" | translate}} <code>ctrl+enter</code></label>
      </div>
    </p-card>
    <div class="p-grid">
      <div class="p-col-8">

        <p-card>
          <div [style.height.px]="cardHeight" class="p-grid">
            <div class="p-col-12">

              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 139px;" (ngModelChange)="notempty($event)" autoResize="false"
                    (focus)="disable_VatsRatesChecks=true" (blur)="disable_VatsRatesChecks=false"
                    [(ngModel)]="creditnote.notes" id="message-input" rows="6" pInputTextarea></textarea>
                  <label for="message-input">{{"QUOTES_Form_MESSAGE" | translate}}</label>
                </span>

              </div>
            </div>

            <div style="background-color: #f8f9fa;" *ngIf="selected_creditnote" class="p-col-12 ">
              <span class="formattext text-muted">{{"ORDERS_FORM_STRUCTED_COMM" | translate}}:&nbsp;</span>
              <span style="font-weight: bolder; " class="number-total">{{creditnote.vcs}} </span>
            </div>


          </div>

        </p-card>


      </div>
      <div class="p-col-4">

        <p-card>
          <div #total_Height class=" order-summary text-muted">


            <div style="background-color: #f8f9fa; position: relative;" class="p-grid">
              <div style=" padding-top: 15px;" class="p-col-5">
                {{"DISCOUNT_EXCL_VAT" | translate}} :
              </div>
              <div class="p-col-7 text-align-right">
                <p-inplace [disabled]="creditnote?.status?.issued||paymentDue==0" #discount>

                  <span pInplaceDisplay>
                    <span *ngIf="creditnote?.discount>0 && selected_discount_type?.type == 'Amount'"
                      style="font-weight: 800;" class="  number-total">{{creditnote?.discount |number:'1.2-2'}}
                      <!-- <i [class]="creditnote.currency"></i> -->
                    </span>

                    <span *ngIf="creditnote?.discount>0 && selected_discount_type?.type == 'Percent'"
                      style="font-weight: 800;" class="  number-total">{{creditnote?.discount |number:'1.2-2'}}%</span>

                    <p-selectButton [disabled]="paymentDue==0" *ngIf="creditnote?.discount==0" [options]="discount_type"
                      [(ngModel)]="selected_discount_type" optionLabel="label">
                    </p-selectButton>

                  </span>
                  <div class="p-inputgroup" pInplaceContent>
                    <!-- prefix="{{order.currencySign}}" -->
                    <p-inputNumber [min]="0" *ngIf="selected_discount_type?.type == 'Amount'"
                      [(ngModel)]="creditnote.discount" (onFocus)="$event.target.select()"
                      (ngModelChange)="total_discountchange()" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                    <p-inputNumber [min]="0" *ngIf="selected_discount_type?.type == 'Percent'" prefix="%"
                      (ngModelChange)="total_discountchange()" [(ngModel)]="creditnote.discount" max="100">
                    </p-inputNumber>

                    <button type="button"
                      [disabled]="(this.creditnote?.discount > this.creditnote.beforeDiscont_creditnoteTotalIncVAT && creditnote.discount_type == 'Amount') || disable_payments == true"
                      (click)="discount.deactivate()" pButton pRipple icon="pi pi-check"></button>

                    <button type="button" (click)="discount.deactivate();cleardiscont()" pButton pRipple
                      icon="pi pi-times" class="p-button-danger"></button>

                  </div>
                </p-inplace>
              </div>
              <div *ngIf="creditnote?.discount>0" class="p-col-12">

                <div class="p-field-checkbox">
                  <p-checkbox [(ngModel)]="show_afterdiscont" binary="true" inputId="binary"></p-checkbox>
                  <label for="binary">{{"SHOW_BF_DS" | translate}}</label>
                </div>
              </div>

            </div>
            <div class="p-grid p-mt-3">
              <div class="p-col-5">{{"QUOTES_Form_TOTAL_NET" | translate}} : </div>
              <div class="p-col-7 text-align-right number-total">
                <span *ngIf="creditnote?.discount==0">{{creditnote?.creditnoteTotal |number:'1.2-2'}}
                  <!-- <i [class]="creditnote.currency"></i> -->
                </span>

                <span *ngIf="creditnote?.discount>0">
                  <del
                    *ngIf="show_afterdiscont && creditnote?.beforeDiscont_creditnoteTotal != creditnote?.creditnoteTotal"
                    style="font-weight: 600;" class="p-text-secondary">{{
                    creditnote?.beforeDiscont_creditnoteTotal |
                    number:'1.2-2'}}
                    <!-- <i [class]="creditnote.currency"></i> -->
                  </del>
                  {{creditnote?.creditnoteTotal |number:'1.2-2'}}
                  <!-- <i [class]="creditnote.currency"></i> -->


                </span>

              </div>

            </div>
            <div style="background-color: #f8f9fa;" class="p-grid">
              <div class="p-col-5">{{"QUOTES_Form_TOTAL_VAT" | translate}} : </div>
              <div class="p-col-7 text-align-right number-total">
                <span *ngIf="creditnote?.discount==0">{{creditnote?.creditnoteTotalVAT |number:'1.2-2'}}
                  <!-- <i [class]="creditnote.currency"></i> -->
                </span>
                <span *ngIf="creditnote?.discount>0">
                  <del
                    *ngIf="show_afterdiscont && creditnote?.beforediscontcreditnoteTotalVAT != creditnote?.creditnoteTotal"
                    style="font-weight: 600;" class="p-text-secondary">{{creditnote?.beforediscontcreditnoteTotalVAT|
                    number:'1.2-2'}}
                    <!-- <i [class]="creditnote.currency"></i> -->
                  </del>
                  {{creditnote?.creditnoteTotalVAT |number:'1.2-2'}}
                  <!-- <i [class]="creditnote.currency"></i> -->


                </span>
              </div>
            </div>
            <ng-container *ngIf="creditnote?.paid_vats?.length>1">
              <div *ngFor="let item of creditnote.paid_vats">
                <div *ngIf="item.afterdiscont >0" style="background-color: #f8f9fa;" class=" p-grid">
                  <div [ngClass]="{'text-muted': creditnote?.status?.invoiced}" class="p-col-5">VAT {{item.name}} %
                    <!-- <label
                      *ngIf="default_currency?.id != creditnote?.currency?.id &&  defaultcurrency_status">({{default_currency?.name}} /
                      {{creditnote?.currency?.name}})</label>  -->
                    :

                  </div>
                  <div [ngClass]="{'text-muted': creditnote?.status?.invoiced}"
                    class="p-col-7 text-align-right number-total">
                    <span *ngIf="creditnote?.discount==0">

                      <label
                        *ngIf="default_currency?.id != creditnote?.currency?.id;else currency">{{item?.beforediscont
                        |
                        number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                          default_currency?.name }} </span> <label class="divide">/</label> {{item?.beforediscont *
                        creditnote?.exchange_rate |
                        number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                          creditnote?.currency?.name }} </span></label>
                      <ng-template #currency>{{item?.beforediscont | number:'1.2-2'}}
                      </ng-template>
                    </span>

                    <span *ngIf="creditnote?.discount>0">

                      <label *ngIf="default_currency?.id != creditnote?.currency?.id ;else currency">

                        <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                          class="p-text-secondary">{{item?.beforediscont / creditnote?.exchange_rate | number:'1.2-2'}}
                          <span style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                            default_currency?.name }} </span>
                        </del>
                        {{item?.afterdiscont / creditnote?.exchange_rate|number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                          default_currency?.name }} </span> <label class="divide">/</label>

                        {{item?.afterdiscont |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                          creditnote?.currency?.name }} </span>
                        <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                          class="p-text-secondary">{{item?.beforediscont | number:'1.2-2'}} <span
                            style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                            creditnote?.currency?.name }} </span>
                        </del>
                      </label>
                      <ng-template #currency>
                        <del *ngIf="show_afterdiscont " style="font-weight: 600;"
                          class="p-text-secondary">{{item?.beforediscont| number:'1.2-2'}}
                        </del>
                        {{item?.afterdiscont |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                          creditnote?.currency?.name }} </span>
                      </ng-template>




                    </span>


                  </div>
                </div>
              </div>
            </ng-container>



            <div [ngClass]="{'p-p-0': creditnote?.status?.cc == false}" class="p-grid">
              <div class="p-col-5">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}} :</div>
              <div class="p-col-7 text-align-right number-total">
                <span *ngIf="creditnote?.discount==0">{{creditnote?.creditnoteTotalIncVAT |number:'1.2-2'}}
                  <!-- <i [class]="creditnote.currency"></i> -->
                </span>
                <span *ngIf="creditnote?.discount>0">
                  <del
                    *ngIf="show_afterdiscont && creditnote?.beforeDiscont_creditnoteTotalIncVAT != creditnote?.creditnoteTotalIncVAT"
                    style="font-weight: 600;" class="p-text-secondary">{{
                    creditnote?.beforeDiscont_creditnoteTotalIncVAT |
                    number:'1.2-2'}}
                    <!-- <i [class]="creditnote.currency"></i> -->
                  </del>
                  {{creditnote?.creditnoteTotalIncVAT |number:'1.2-2'}}

                  <!-- <i [class]="creditnote.currency"></i> -->

                </span>
              </div>
            </div>

            <hr class="divider">
            <div style="background-color: #f8f9fa; " class="p-grid p-mt-3 ">
              <div class="p-col-8">{{"QUOTES_Form_CUSTOMER_BALANCE" | translate}} :</div>
              <div class="p-col-4 text-align-right number-total">
                <label class="" >{{creditnote.customer?.due + totalpayment_refund|
                  number:'1.2-2'}}</label>



              </div>
            </div>

          </div>
        </p-card>
      </div>
    </div>

  </div>


  <p-dialog   modal="true" appendTo="body" [header]="Paymentlabel" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)"
    [resizable]="false" [draggable]="false" [(visible)]="showPaymentDialog" [style]="{width: '40vw'}">
    <div style="margin-top: 2px; " class="p-grid">

      <div class="p-col-5">
        <div class="form-text text-muted">{{"DASHBORD_DATE" | translate}}</div>
        <p-calendar dateFormat="dd-mm-yy" appendTo="body" [inputStyle]="{'width':'18.5vw'}" class="calendar"
          [(ngModel)]="payment.dateP" #payment_date="ngModel" name="payment.dateP"
          placeholder='{{"DASHBORD_DATE" | translate}}'></p-calendar>
      </div>
      <div class="p-col-7">
        <div class="form-text text-muted">{{"ORDERS_FORM_PAYMENT_TYPES" | translate}}</div>
        <p-dropdown appendTo="body" [(ngModel)]="payment.type" [options]="paymenttypes" optionValue="type"
          optionLabel="label"></p-dropdown>

      </div>


      <div class="p-col-12">
        <div class="form-text text-muted ">{{"ORDERS_FORM_AMOUNT" | translate}}
          <label *ngIf=" (payment?.amount == null || amountt.invalid) &&  amountt.touched " class="alert-invalida">
            {{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

        </div>

        <p-inputNumber [min]="0" [inputStyle]="{'width': '38.2vw' }" id="Amount-input" #amountt="ngModel"
          (ngModelChange)="notempty($event)" [(ngModel)]="payment.amount" mode="decimal" [minFractionDigits]="2">
        </p-inputNumber>
      </div>
      <div class="p-col-12">
        <div class="form-text text-muted">{{"ORDERS_FORM_REFERENCE" | translate}}

        </div>
        <input [(ngModel)]="payment.notes" id="Reference -input" type="text" pInputText>

      </div>
    </div>

    <p-footer>
      <button type="button" pButton (click)="showPaymentDialog=false" icon="pi pi-times"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
      <button *ngIf="Paymentlabel==translate.instant('REFUND_PAYMENT')" type="button" pButton (click)="addPayment()"
        label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
      <button *ngIf="Paymentlabel==translate.instant('UPDATED_PAYMENT')" type="button" pButton (click)="UpdatePayment()"
        label='{{"CUSTOMER_LIST_UPDATE" | translate}}'></button>


    </p-footer>
  </p-dialog>

  <p-dialog  modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    appendTo="body" class="p-shadow-1" header="Send by Email" [(visible)]="sendEmaildialog" [style]="{width: '65vw'}">
    <div class="p-field p-grid">
      <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 ">{{"QUOTES_Form_RECIPIENTS" |
        translate}}</label>
      <div class="p-col">

        <p-chips (onRemove)="onRemovemail()" addOnBlur='true' (keydown)="addEmail_onSpace($event)" id="Recipients"
          name="send_to_mails" [(ngModel)]="sendEmail.send_to_mails">
          <ng-template let-item pTemplate="item">
            <span>{{item}}</span>
          </ng-template>

        </p-chips>

        <div class="mt-2">
          <p-checkbox [binary]="user_email" [(ngModel)]="user_email" (onChange)="send_copyto_myself()"
            label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>
        </div>
      </div>

    </div>

    <div class="p-field p-grid">
      <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"QUOTES_Form_SUBJECT" |
        translate}}</label>
      <div class="p-col">
        <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>

      </div>
    </div>

    <div class="p-field p-grid">
      <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"MESSAGE" | translate}} </label>
      <div class="p-col">
        <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
          [style]="{'height':'180px'}"></p-editor>
        <div class="mt-2">
          <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="Save_Message()"></p-checkbox>
        </div>

      </div>
    </div>

    <p-footer>

      <button type="button" pButton icon="pi pi-send" (click)="send_Email()"
      label='{{"QUOTES_Form_SEND" | translate}}' icon="far fa-paper-plane"></button>
      <button type="button" pButton icon="pi pi-times" (click)="sendEmaildialog=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>



    </p-footer>
  </p-dialog>




  <!-- <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header="Convert To Draft " [(visible)]="convertDraftdialogueeQ" [style]="{width: '27vw'}">
    <div *ngIf="creditnote.customer?.company_name != 'Cash & Carry' || !isSet(draftCustomer)" class="p-text-justify "> Are
      you sure you want to return <span style="color: var(--primary-color)">{{creditnote.reference}}</span> the Draft ?
    </div>
    <div *ngIf="creditnote.customer?.company_name == 'Cash & Carry'" class="p-text-justify ">
      <app-customers-select [(customer)]="draftCustomer" [(append)]="appendCutsomer"></app-customers-select>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="convertToDraft()" label="Convert"></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="convertDraftdialogueeQ=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog> -->




  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body" [resizable]="false"
    [draggable]="false" class="p-shadow-1" [header]="creditnote.reference" [(visible)]="pdfViewer"
    [style]="{width: '60vw'}">
    <div style=" position: relative;" [style.height.px]="pdfheight">
      <pdf-viewer [src]="creditnote?.view_url" (after-load-complete)="afterLoadComplete($event)"
        (on-progress)="pdfLoader($event)" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
        [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"></pdf-viewer>
    </div>

    <p-footer class="p-pt-4">
      <button type="button" pButton icon="fas fa-print" (click)="printPdf()"
        label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
      <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()"
        label='{{"DOWNLOAD" | translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="pdfViewer=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    appendTo="body" class="p-shadow-1" header='{{"EDIT_SERVICE" | translate}}' [(visible)]="showaddinlineProduct"
    [style]="{width: '60vw'}">

    <p-editor id="Message" name="inline" [(ngModel)]="inlineProductValue" ngDefaultControl [style]="{'height':'200px'}">
    </p-editor>

    <p-footer> <button type="button" pButton icon="pi pi-times" (click)="showaddinlineProduct=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" (click)="edit_inlineProduct()"
        label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>


    </p-footer>
  </p-dialog>







  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header="Confirm Credit Note" [(visible)]="confirm_Credit_dialog" [style]="{width: '19vw'}">
    <div class="p-grid">

      <div class="p-col-12">
        <div class="  p-grid">
          <div style=" margin-top:2%  ; " class="p-col-6 ">
            <label>{{"CREDIT" | translate}} :#</label>
          </div>

          <div class="p-col-6">

            <p-inplace id="cn_format" #issuereference>
              <span pInplaceDisplay>


                <button *ngIf=" creditnote.cn_format"
                  style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;     " pButton
                  [label]="creditnote.cn_format" type="button"
                  class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                <button *ngIf=" creditnote.cn_format == '' && cn_format.touched  " style="  font-weight: 600;  " pButton
                  label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                  class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>

              </span>
              <input name="creditnote.cn_format" [(ngModel)]="creditnote.cn_format" #cn_format="ngModel" id="cn_format"
                type="text" pInputText pInplaceContent (blur)="issuereference.deactivate()">
            </p-inplace>

          </div>

        </div>
      </div>
      <div class="p-col-12">

        <div class=" p-grid">
          <div style=" margin-top:2%  ; " class="p-col-6 ">
            <label>{{"CREDIT_DATE" | translate}} :</label>
          </div>
          <div class="p-col-6 ">
            <p-inplace id="cn_date" [ngStyle]="{cursor: 'pointer'}" #issueDate>
              <span pInplaceDisplay>
                <button *ngIf=" creditnote.cn_date "
                  style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                  [label]="creditnote.cn_date | date:'dd-MM-yyyy'" type="button"
                  class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                <button *ngIf=" creditnote.cn_date == null && cn_date.touched  " style="  font-weight: 600;  " pButton
                  label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                  class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
              </span>
              <p-calendar dateFormat="dd-mm-yy" appendTo="body" showIcon="true" [(ngModel)]="creditnote.cn_date"
                #cn_date="ngModel" name="creditnote.cn_date" [required]="true" pInplaceContent
                (onClose)="issueDate.deactivate()" (onClickOutside)="issueDate.deactivate()"
                (onSelect)="issueDate.deactivate()" ngDefaultControl></p-calendar>
            </p-inplace>

          </div>

        </div>
      </div>
    </div>


    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="confirm_Credit()"
        label='{{"CONFIRM" | translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="confirm_Credit_dialog=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>


  <p-dialog modal="true" header="Import Invoices " (onShow)="outside_Status(true)" (onHide)="outside_Status(false)"
    [resizable]="false" [draggable]="false" [(visible)]="orders_dialog" [style]="{width: '65vw'}">
    <div class="p-grid">
      <div class="p-col-12 text-align-right">
        <app-Search-select [Searchitems]="filteredOrders" [field_name]="'format'" (filter)="serach_orders($event)">
        </app-Search-select>
      </div>

      <div class="p-col-12">

        <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped  p-datatable-responsive-demo"
          [paginator]="true" (onPage)="getNextorderPage($event)" [rows]="10" [rowsPerPageOptions]="[10,30]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {{ordersCount}} entries" [value]="orders"
          [(selection)]="selectedOrder"
          [globalFilterFields]="['format','company_name','date','total','status?.status.value']">

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 3em">
              </th>
              <th>{{"ORDERS_LIST_ORDER_NO" | translate}}

              </th>
              <th>{{"DASHBORD_DATE" | translate}}
              </th>
              <th>{{"QUOTES_LIST_TOTAL" | translate}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr>
              <td style="width: 3em">
                <p-tableRadioButton [value]="rowData"></p-tableRadioButton>

              </td>
              <td>{{ rowData?.format }}</td>



              <td> {{ rowData?.date | date:'dd-MM-yyyy' }}</td>
              <td>{{ rowData?.total | number:'1.2-2' }} <span style="color: var(--primary-color);font-size: small;">{{
                  rowData.currency?.name }}</span></td>



            </tr>
          </ng-template>
        </p-table>

      </div>

    </div>





    <p-footer>
      <button type="button" pButton [disabled]="selectedOrder?.id==null" (click)="importorder()"
        label='{{"IMPORT" | translate}}'></button>
      <button type="button" pButton (click)="orders_dialog=false" icon="pi pi-times"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
    </p-footer>
  </p-dialog>


  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1" appendTo="body"
    [resizable]="false" [draggable]="false" header='{{"EXCHANGE_RATE" | translate}}' [(visible)]="showAdd_exchange_rate"
    [style]="{width: '15vw'}">


    <p-inputNumber [min]="0" [inputStyle]="{'width':13+'vw'}" (onFocus)="$event.target.select()"
      [(ngModel)]="add_exchange_rate" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
    <p-footer>
      <button type="button" pButton icon="pi pi-check"
        (click)="creditnote.exchange_rate=add_exchange_rate;showAdd_exchange_rate=false"
        label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="creditnote.exchange_rate=1 ;showAdd_exchange_rate=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
