import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { VendorsService } from './suppliers.service';
import { VendorsFormComponent } from './suppliers-form/suppliers-form.component';
import { VendorsListComponent } from './suppliers-list/suppliers-list.component';
import {BadgeModule} from 'primeng/badge';
import {InputMaskModule} from 'primeng/inputmask';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';



export const routes: Routes = [
  {path: '', component: VendorsListComponent}
];

@NgModule({
  declarations: [VendorsFormComponent, VendorsListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    TableModule,
    SharedModule,
    SidebarModule,
    ToolbarModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    DialogModule,
    PaginatorModule,
    FileUploadModule,
    OverlayPanelModule,
    InputNumberModule,
    ChartModule,TranslateModule,
    ToastModule,BadgeModule,InputMaskModule
  ],
  providers: [VendorsService, MessageService]
})
export class VendorsModule {
}
