import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private activatedRoute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public activatedRouteEmitter: Observable<boolean> = this.activatedRoute.asObservable();

  constructor(private route: ActivatedRoute, private router: Router) {

  }
  token_expires = new Date(localStorage.getItem('token_expires'))
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('user') && localStorage.getItem('token_data')) {
      let currentDate = new Date(Date.now())
      if (Date.parse(currentDate.toString()) < Date.parse(this.token_expires.toString())) {
        this.activatedRoute.next(true)
        return true;

      } else {
        localStorage.removeItem('supported_currencies')
        localStorage.removeItem('companySettings')
        localStorage.removeItem('token_expires')
        localStorage.removeItem('token_data')
        localStorage.removeItem('user')
        localStorage.removeItem('returnUrl')


        location.reload()
      }


    }
    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: localStorage.setItem('returnUrl', state.url)

      }
    });

    // this.router.navigate(['/auth/login']);
    return false;
  }
}
