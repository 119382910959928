import { Component, OnInit, ViewChild } from '@angular/core';
import { MainComponent } from '../main/main.component';
import { User } from '../../models/user';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { TranslateService } from '@ngx-translate/core';
import { isSet } from 'src/app/modules/core/util';
import { DashboardService } from 'src/app/modules/dashboard/dashboard.service';
import { MenuItem, MessageService } from 'primeng/api';
import { Calendar_event } from 'src/app/models/calendar/event';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { CalendarService } from 'src/app/modules/erp/calendar/calendar.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [MessageService],

})
export class TopbarComponent implements OnInit {

  activeItem: number;

  darkColors: any;

  lightColors: any;

  customColors: any;

  menuColors: any;
  todayEvents: Calendar_event[] = []
  eventDialog: boolean
  eventDialogHeader = 'View Event'
  selected_event = new Calendar_event()
  update_status: boolean
  selectedColorOptions: any;

  componentThemes: any;
  sections = [];
  filteredsections = []
  serachItem;
  langs = [
    { lang: 'EN', code: 'flag-icon flag-icon-gb flag-icon-squared', value: 'English' },
    { lang: 'FR', code: 'flag-icon flag-icon-fr flag-icon-squared', value: 'Français' },
    { lang: 'NL', code: 'flag-icon flag-icon-nl flag-icon-squared', value: 'Nederlands' }
  ]
  currentLang: any
  public currentuser: User;

  items: MenuItem[]
  currentUrl: string
  oldtUrl: string
  account_dialog = false
  constructor(public app: MainComponent, private router: Router,
    private menuService: MenuService, public translate: TranslateService,
     private dashboard: DashboardService,
    private sharedService: SharedService, private messageService: MessageService, private calendarService: CalendarService
  ) {
  }

  ngOnInit() {


    this.currentuser = JSON.parse(localStorage.getItem("user"))
    this.getCurrentlanguage()
    this.openSettings()
    this.getTodayEvents()
    this.subscribeOnrefreshEvents()
    this.sections = [
      { name: 'Customers', routerLink: '/customers' },
      { name: 'Orders', routerLink: '/customers/orders' },
      { name: 'Quotes', routerLink: '/customers/quotes' },
      { name: 'Credit notes(Customers)', routerLink: '/customers/creditnotes' },
      { name: 'Vendors', routerLink: '/suppliers' },
      { name: 'Taxes', routerLink: '/suppliers/taxes' },
      { name: 'Purchase orders', routerLink: '/suppliers/purchaseorders' },
      { name: 'Credit notes(Vendors)', routerLink: '/suppliers/creditnotes' },
      { name: 'Payments', routerLink: '/components/forms' },
      { name: 'Products', routerLink: '/products' },

    ]

    this.lightColors = [
      { name: 'Blue', file: 'blue', image: 'blue.svg' },
      { name: 'Green', file: 'green', image: 'green.svg' },
      { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
      { name: 'Purple', file: 'purple', image: 'purple.svg' },
      { name: 'Orange', file: 'orange', image: 'orange.svg' },
      { name: 'Teal', file: 'teal', image: 'teal.svg' },
      { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
      { name: 'Lime', file: 'lime', image: 'lime.svg' },
      { name: 'Brown', file: 'brown', image: 'brown.svg' },
      { name: 'Red', file: 'red', image: 'red.svg' },
      { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
    ];

    this.darkColors = [
      { name: 'Blue', file: 'blue', image: 'blue.svg' },
      { name: 'Green', file: 'green', image: 'green.svg' },
      { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
      { name: 'Purple', file: 'purple', image: 'purple.svg' },
      { name: 'Orange', file: 'orange', image: 'orange.svg' },
      { name: 'Teal', file: 'teal', image: 'teal.svg' },
      { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
      { name: 'Lime', file: 'lime', image: 'lime.svg' },
      { name: 'Brown', file: 'brown', image: 'brown.svg' },
      { name: 'Red', file: 'red', image: 'red.svg' },
      { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
    ];

    this.customColors = [
      { name: 'Chile', file: 'chile', image: 'chile.png' },
      { name: 'Naples', file: 'naples', image: 'naples.png' },
      { name: 'Georgia', file: 'georgia', image: 'georgia.png' },
      { name: 'Infinity', file: 'infinity', image: 'infinity.png' },
      { name: 'Chicago', file: 'chicago', image: 'chicago.png' },
      { name: 'Majesty', file: 'majesty', image: 'majesty.png' },
      { name: 'Fish', file: 'fish', image: 'fish.png' },
      { name: 'Dawn', file: 'dawn', image: 'dawn.png' },
      { name: 'Record', file: 'record', image: 'record.png' },
      { name: 'Pool', file: 'pool', image: 'pool.png' },
      { name: 'Metal', file: 'metal', image: 'metal.png' },
      { name: 'China', file: 'china', image: 'china.png' },
      { name: 'Table', file: 'table', image: 'table.png' },
      { name: 'Panorama', file: 'panorama', image: 'panorama.png' },
      { name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
      { name: 'Underwater', file: 'underwater', image: 'underwater.png' },
      { name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
      { name: 'Rain', file: 'rain', image: 'rain.png' },
      { name: 'Utah', file: 'utah', image: 'utah.png' },
      { name: 'Wave', file: 'wave', image: 'wave.png' },
      { name: 'Flora', file: 'flora', image: 'flora.png' },
      { name: 'Speed', file: 'speed', image: 'speed.png' },
      { name: 'Canopy', file: 'canopy', image: 'canopy.png' },
      { name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
      { name: 'Basketball', file: 'basketball', image: 'basketball.png' },
      { name: 'Misty', file: 'misty', image: 'misty.png' },
      { name: 'Summit', file: 'summit', image: 'summit.png' },
      { name: 'Wall', file: 'wall', image: 'wall.png' },
      { name: 'Ferris', file: 'ferris', image: 'ferris.png' },
      { name: 'Ship', file: 'ship', image: 'ship.png' },
      { name: 'NY', file: 'ny', image: 'ny.png' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.png' },
      { name: 'Violet', file: 'violet', image: 'violet.png' },
      { name: 'Red', file: 'red', image: 'red.png' },
      { name: 'Blue', file: 'blue', image: 'blue.png' },
      { name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
      { name: 'Pink', file: 'pink', image: 'pink.png' },
      { name: 'Purple', file: 'purple', image: 'purple.png' },
      { name: 'Orange', file: 'orange', image: 'orange.png' },
    ];

    this.menuColors = [
      { name: 'light' },
      { name: 'custom' },
      { name: 'dark' }
    ];

    this.selectedColorOptions = this.lightColors;

    this.componentThemes = [
      { name: 'Blue', file: 'blue', image: 'blue.svg' },
      { name: 'Green', file: 'green', image: 'green.svg' },
      { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
      { name: 'Purple', file: 'purple', image: 'purple.svg' },
      { name: 'Orange', file: 'orange', image: 'orange.svg' },
      { name: 'Teal', file: 'teal', image: 'teal.svg' },
      { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
      { name: 'Lime', file: 'lime', image: 'lime.svg' },
      { name: 'Brown', file: 'brown', image: 'brown.svg' },
      { name: 'Red', file: 'red', image: 'red.svg' },
      { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
    ];
  }
  onSelectsection(event) {

    this.router.navigateByUrl(event.routerLink)
  }
  mobileMegaSellMenuItemClick(index) {
    this.app.megaSellMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  mobileMegaBuyMenuItemClick(index) {
    this.app.megaBuyMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  changeLayout(event, mode) {
    this.app.darkMode = mode;
    if (mode === true) {
      this.app.menuColorMode = 'dark';
      this.app.menuColor = 'layout-menu-dark';
      this.selectedColorOptions = this.darkColors;
      this.app.layoutColor = this.selectedColorOptions[0].file;
      this.changeLightDarkLayout('layout-css', this.selectedColorOptions[0].file, 'layout-dark');
      this.changeLightDarkTheme('theme-css', 'theme-dark');
    } else {
      this.app.menuColorMode = 'light';
      this.app.menuColor = 'layout-menu-light';
      this.selectedColorOptions = this.lightColors;
      this.app.layoutColor = this.selectedColorOptions[0].file;
      this.changeLightDarkLayout('layout-css', this.selectedColorOptions[0].file, 'layout-light');
      this.changeLightDarkTheme('theme-css', 'theme-light');
    }

    event.preventDefault();
  }

  filter(event) {
    const query = event.query;


    this.filteredsections = this.sections.filter((item) => {
      return item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    });

  }
  openConfig() {
    
    this.menuService.configClicke.next(true)
  }
  changeLightDarkTheme(id, value) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = value + '.css';
    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  changeLightDarkLayout(id, color, mode) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = color;
    urlTokens[urlTokens.length - 1] = mode + '.css';
    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  signOut() {
    localStorage.removeItem('supported_currencies')
    localStorage.removeItem('companySettings')
    localStorage.removeItem('token_expires')
    localStorage.removeItem('token_data')
    localStorage.removeItem('user')
    localStorage.removeItem('vats_Product')   
    localStorage.removeItem('returnUrl')
    this.router.navigate(['/auth/login'])

  }

  langChanged(lang) {
    this.translate.use(lang?.lang.toLocaleLowerCase())
    this.menuService.refreshShapes.next(true)
    localStorage.removeItem("currentLang")
    localStorage.setItem("currentLang", JSON.stringify(lang))
    this.currentLang.lang = lang.lang
    this.menuService.langStatus.next(lang.lang)
    
  }
  getCurrentlanguage() {
    this.currentLang = JSON.parse(localStorage.getItem("currentLang")) || this.langs[0]
    this.translate.use(this.currentLang?.lang.toLocaleLowerCase())

  }
  openSettings() {
    this.dashboard.openSettingsEmitter.subscribe(openUp => {
      if (openUp == false) {
        return
      } else if (openUp == true) {
        this.openConfig()
      }
    })
  }

  getTodayEvents() {
    const subscription = this.menuService.getTodayEvents().subscribe((calendar_events: Calendar_event[]) => {
      if (!isSet(calendar_events)) {
        return;
      }
      this.todayEvents = Calendar_event.cloneManyObjects(calendar_events)
      this.todayEvents.map((ev) => {
        ev.start = new Date(ev.start)
        ev.end = new Date(ev.end)

        let differnce = ev.start.getHours() - ev.start.getUTCHours()

        ev.start.setHours(ev.start.getHours() + differnce)
        ev.end.setHours(ev.end.getHours() + differnce)
        ev.startTime = ev.start.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      })
      this.todayEvents.sort((a, b) => {
        return (a.start < b.start) ? -1 : ((a.start > b.start) ? 1 : 0);
      });



      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();

    });
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  notempty(event) {
    this.update_status = true
  }
  subscribeOnrefreshEvents() {
    this.menuService.refreshcalendarEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }
      this.getTodayEvents()
    }, error => {
      console.log(error);

    });

  }
  updateEvent() {

    this.selected_event.end.setHours(this.selected_event.endTime.getHours())
    this.selected_event.end.setMinutes(this.selected_event.endTime.getMinutes())
    this.selected_event.start.setHours(this.selected_event.startTime.getHours())
    this.selected_event.start.setMinutes(this.selected_event.startTime.getMinutes())


    if (this.update_status === false) {
      return this.messageService.add({ key: 'menu', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

    }
    if (!isSet(this.selected_event.title)) {
      return this.messageService.add({
        key: 'menu', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: 'Please fill title field'
      });
    }
    if (this.selected_event.start.getDate() == this.selected_event.end.getDate()) {
      if ((this.selected_event.start.getHours() >= this.selected_event.end.getHours())) {
        return this.messageService.add({
          key: 'menu', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: 'Start time can not be greater or equal End Time'
        });
      }
    } else if (this.selected_event.start.getDate() > this.selected_event.end.getDate()) {
      return this.messageService.add({
        key: 'menu', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: 'Start Date can not be greater or equal End Date'
      });
    } {

    }



    try {
      const subscription = this.calendarService.updateEvent(this.selected_event).subscribe((event: Calendar_event) => {

        if (!isSet(event)) {
          return;
        }
        this.messageService.add({
          key: 'menu', severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: ' Event updated successfully'
        });

        this.getTodayEvents()

        this.eventDialog = false
        this.update_status = false

        subscription.unsubscribe();

      }, error => {

        subscription.unsubscribe();
      })
    } catch (error) {

    }
  }
  deleteEvent() {
    const subscription = this.calendarService.deleteEvent(this.selected_event).subscribe(() => {
      this.messageService.add({ key: 'menu', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: 'Selected event deleted' });
      this.eventDialog = false
      this.menuService.refreshcalendar.next(true)

      this.getTodayEvents()


      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();


    })
  }
  showEvent(eve) {

    this.selected_event = new Calendar_event()
    this.selected_event = Calendar_event.cloneObject(eve)
    this.selected_event.start = new Date(this.selected_event.start)
    this.selected_event.startTime = new Date()
    this.selected_event.startTime.setHours(this.selected_event.start.getHours())
    this.selected_event.startTime.setMinutes(this.selected_event.start.getMinutes())



    this.selected_event.end = new Date(this.selected_event.end)
    this.selected_event.endTime = new Date()
    this.selected_event.endTime.setHours(this.selected_event.end.getHours())
    this.selected_event.endTime.setMinutes(this.selected_event.end.getMinutes())

    this.eventDialog = true
  }
  clearEmitte(){
    this.dashboard.displayFilter.next(null)
  }
  onHideAccount(event){
    this.account_dialog=event
  }
}
