import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {InputSwitchModule} from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import {RippleModule} from 'primeng/ripple';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { PdfViewerModule } from 'ng2-pdf-viewer';

export const routes: Routes = [
  {path: '', component: DashboardComponent}
];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    OverlayPanelModule,
    TableModule,
    SharedModule,
    DropdownModule,
    ChartModule,
    FormsModule,
    TranslateModule,
    ToastModule ,
    InputSwitchModule,InputTextareaModule,
    ButtonModule,TooltipModule
    ,DialogModule,ImageToDataUrlModule
    ,SplitButtonModule,RippleModule,PdfViewerModule
     
  ]
})
export class DashboardModule { }
