import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VatInfo } from '../models/vatinfo';
import { isSet } from '../../core/util';
import { ApiService } from '../../core/api.service';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProgressComponent } from './progress/progress.component';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import places from 'places.js'
import { CountryCode_currency } from 'src/app/models/country_code&currency';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

  public currentStep = 1;

  public dontHaveVAT = false;
  public vatChecked = false;
  public vatBtnDisabled = false;
  public btnLoading = false;
  public btnVisible = true;
  public registerCompleteMsg: string;
  public complete = false
  public agree: boolean;
  public email: string;
  public vat: string;
  public mobile: string;
  public password: string;
  public first_name: string;
  public last_name: string;
  public name: string;
  public address: string;
  countries: CountryCode_currency[] = []
  currencies: any[] = []
  selectedCurrency: any
  selectedCountry: any

  completeBtn: boolean = false
  emailfoucs: boolean
  checkused_email: boolean
  items: MenuItem[];
  activeIndex = 0
  captchaRes: boolean
  passfoucs: boolean
  passwrod_numeric: boolean
  passwrod_lowercase: boolean
  passwrod_capitalcase: boolean
  passwrod_length: boolean
  checkpass: boolean
  passinvaild: boolean
  subscription: Subscription;
  resend_verification: boolean
  resend_timer = 30
  showpassword_: boolean
  aFormGroup: FormGroup;
  placesAutocomplete: any
  email_delay: string = null

  @ViewChild('Email') emailcheck: any;
  @ViewChild('co') co: Dropdown;
  @ViewChild('Password') passwordCheck: ElementRef;
  @ViewChild('addressInput') addressInput: any;
  siteKey = '6Le9n_EfAAAAAGVKWXf2gOKGSDn8LZTcR8DOUYKC'
  constructor(private api: ApiService, private messageService: MessageService,
    public translate: TranslateService, private auth: AuthService, private formBuilder: FormBuilder) {
  }
  ngOnInit(): void {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.items = [{
      label: 'Company ',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account ',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },
    {
      label: 'Personal',
      command: (event: any) => {
        this.activeIndex = 2;
        this.messageService.add({ severity: 'info', summary: 'Pay with CC', detail: event.item.label });
      }
    }

    ];
    this.listCounties()

  }
  ngAfterViewInit() {
    this.placesAutocomplete = places({
      container: this.addressInput?.nativeElement
    });


    this.placesAutocomplete.on('change', (e: any) => { this.address = e.suggestion?.value });

  }
  goNext(progress: ProgressComponent) {

    progress.next();
  }
  goBack(progress: ProgressComponent) {
    progress.prev()
  }
  onStateChange(event) {
    console.log(event);
  }

  activeIndex1() {

    if (isSet(this.name) && this.name != '' && isSet(this.address) && this.address != '') {
      this.activeIndex = 1

    }
  }

  onblurPassword() {
    this.checkpass = false
    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.password?.length < 8
    ) {
      this.passinvaild = true
      this.messageService.add({
        key: 'register', life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter valid password'
      });
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.password?.length >= 8
    ) {
      this.passinvaild = false

    }
  }
  onfoucsPassword() {
    this.passfoucs = true
    this.checkpass = true

  }

  checkPassword() {
    let lowerCaseLetters = /[a-z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(lowerCaseLetters)) {
      this.passwrod_lowercase = true
    } else {
      this.passwrod_lowercase = false
    }

    let numbers = /[0-9]/g;
    if (this.passwordCheck?.nativeElement?.value.match(numbers)) {
      this.passwrod_numeric = true

    } else {
      this.passwrod_numeric = false
    }

    let upperCaseLetters = /[A-Z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(upperCaseLetters)) {
      this.passwrod_capitalcase = true

    } else {
      this.passwrod_capitalcase = false
    }
    if (this.password?.length >= 8) {
      this.passwrod_length = true
    } else {
      this.passwrod_length = false
    }

    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.password?.length < 8
    ) {
      this.passinvaild = true
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.password?.length >= 8
    ) {
      this.passinvaild = false

    }
  }
  onBlurEmail() {
    if (this.emailcheck.control.invalid) {
      return this.messageService.add({
        key: 'register', life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter vaild Email'
      });
    }
  }
  checkEmai() {
    this.btnLoading = true;


    if (!this.emailcheck.control.invalid) {

      const subscription = this.api.postGuest('/check_email_available', {
        email: this.email
      }).subscribe((data: { status: boolean, message: string }) => {

        if (!isSet(data)) {
          return
        }
        if (data.status) {
          return this.checkused_email = false
        }
        if (!data.status) {
          this.checkused_email = true
          this.messageService.add({ key: 'register', severity: 'error', summary: this.translate.instant('ERROR'), detail: data.message });
        }

      }, error => {
        this.btnLoading = false;
        subscription.unsubscribe();
      }, () => {
        this.btnLoading = false;
        subscription.unsubscribe();
      });

    }



  }
  checkEmail_delay() {

    const x = setInterval(() => {
      if (this.email_delay != this.email) {
        this.email_delay = this.email
        return this.checkEmai()

      } else {
        clearInterval(x);

      }
    }, 1500);
  }
  getDataFromVat(): void {
    if (this.vat.length < 9) {
      return
    }

    this.vatBtnDisabled = true;

    const subscription = this.api.postGuest('/extract_vat_info', {
      vat_number: this.vat
    }).subscribe((vatInfo: VatInfo) => {
      if (!isSet(vatInfo)) {
        return;
      }

      this.vatChecked = true;
      this.name = vatInfo.name;
      this.address = vatInfo.address;
    }, error => {
      if (isSet(error.error.detail)) {
        this.vatChecked = false;
        this.name = null;
        this.address = null;
        this.messageService.add({ key: 'register', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      }
      console.error(error);
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    }, () => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    });
  }

  register() {

    const body = {
      user_data: {
        first_name: this.first_name,
        last_name: this.last_name,
        password: this.password,
        email: this.email,
      },
      company_data: {
        name: this.name,
        vat: this.vat,
        currency: this.selectedCurrency
      },
      address_data: {
        address: this.address,
        country: this.selectedCountry
      },

    }

    this.completeBtn = true
    const subscription = this.auth.register(body).subscribe(data => {
      this.complete = true


      if (!isSet(data)) {
        return;
      }
      console.log(data);

      this.messageService.add({
        key: 'register',
        severity: 'success',
        summary: 'Successfully Pperation',
        detail: 'verification link sent , please check your email.'
      });
      this.completeBtn = false

    }, error => {
      console.log(error);

      this.completeBtn = false
      this.messageService.add({
        key: 'register',
        severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: 'Please check your inputs'

      });

      subscription.unsubscribe();
    });
  }

  normalize_VAT($event: any) {
    const vat = $event

    this.vat = vat.replace(/\s/g, '')
    this.vat = this.vat.replace(/\./g, '').toUpperCase()

  }
  captchaResponse(event) {
    setTimeout(() => {
      this.captchaRes = true
    }, 1000);
  }
  resendVerification() {

    const subscription = this.api.post_noHheader('/resend_register_token', {
      email: this.email
    }).subscribe((data: any) => {
      this.messageService.add({
        key: 'register',
        severity: 'success',
        summary: 'Successfully ',
        detail: 'verification link sent , please check your email.'
      });
      this.resend_verification = true


      const x = setInterval(() => {
        if (this.resend_timer != 0) {
          this.resend_timer = this.resend_timer - 1
        } else {
          this.resend_verification = false
          this.resend_timer = 30
          clearInterval(x);
        }

      }, 1000);





    })
  }
  showpassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'text');
    this.showpassword_ = true
  }
  hidepassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'password');
    this.showpassword_ = false
  }
  listCounties() {
    const subscription = this.auth.countries().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.countries = CountryCode_currency.cloneManyObjects(countryCode_currency)

      this.countries.map(item => {
        this.currencies.push({ currency: item.currency })
      })
      let rmAduplicate = []
      this.currencies.forEach((item) => {
        if (rmAduplicate.findIndex(i => i.currency == item.currency) === -1) {
          rmAduplicate.push(item)
        }
      });
      this.currencies = rmAduplicate
      this.currencies.sort((a,b)=>{
        return a.currency.localeCompare(b.currency)
      })
      this.locateUser()

      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  selectCountryCurrency(event) {
    this.countries.map((can: any) => {

      if (can.name == event) {
        this.selectedCurrency = can.currency
        this.selectedCountry = can.name
      }
    })

  }
  locateUser() {
    const subscription = this.auth.ipInfo().subscribe(data => {
      if (!isSet(data)) {
        return;
      }

      const current_countryCode = data.country



      this.countries.map((can: any) => {
        if (can.country_code == current_countryCode) {
          this.selectedCountry = can.name
          this.selectedCurrency = can.currency
        }
      })
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }

}
