import { Component, HostListener, OnInit } from '@angular/core';
import { Query } from 'src/app/models/query';
import { User } from 'src/app/models/user';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends BaseComponent implements OnInit {
  public selected: User[] = [];
  selectedUsers: User[] = [];
  public selectedSingleUser:User;
  users:User[]=[];

  itemSearch: string
  public filteredUsers: User[] = [];
  keyword: string = 'test'
  usersCount: any
  selected_Usernamequery = new Query()
  filterModeName_query = new Query()
  filterModeEmail_query = new Query()
  filterModePhone_query = new Query()
  filterModeCmpanyName_query = new Query()
  selectedquery_type: string
  public showSidebar = false;
  Removedialogue = false
  public modalTitle = 'Add new user';
  header_body: string
  public size: SidebarSize;
  
  public currentModal ;
  sortMode:number=1
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]


  constructor(private sharedService: SharedService, private messageService: MessageService,
    private router: Router,
    public translate: TranslateService,private userService:UserService) {
    super();
  }

  ngOnInit(): void {
    this.users=this.userService.user
  }

  Reload() {
    this.loading = true
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }

  RemoveUser(){
    try {
      this.loading = true;
      let index = 0
      let length = this.selectedUsers.length
      
      
      this.selectedUsers.map(item => {
        console.log(item);
        

        // const subscription = this.customersService.DeleteCustomer(item.id).subscribe(() => {
        //   if (!isSet(item)) {
        //     return;
        //   }
        //   index += 1

        //   if (length < 3) {
        //     this.customersService.refreshCustomers();
        //     this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: item.company_name + this.translate.instant('DELETED') });

        //   } else if (index == length) {
        //     this.customersService.refreshCustomers();
        //     this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: 'Selected customers deleted' });
        //   }
        //   subscription.unsubscribe();

        // }, error => {
        //   if (isSet(item)) {
        //     if (error.error.detail == 'Could not delete document (Order.customer refers to it)' || error.error.detail == 'Could not delete document (Quote.customer refers to it)') {
        //       return this.messageService.add({ key: 'list', severity: 'error', life: 4000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('CANT_DELETE_CUSTOMER') });
        //     }
        //     this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        //   }
        //   this.loading = false;
        //   subscription.unsubscribe();
        //   this.customersService.refreshCustomers();
        // })
      })
      this.loading = false;
      this.selectedUsers = []
      this.selected = []
    }
    catch (error) {
      this.log(error);
    }
    this.Removedialogue = false
  }
  remove(){
    if (this.selectedUsers.length !== 0) {
      return this.Removedialogue = true
    }
  }
  showAddNewUser(){
    this.selectedSingleUser = {id:1,username:"",first_name:"",last_name:"",email:"",mobile:"",image:"",viewimage:"",showSettings:true,deletePic:false};

    this.userService.selectUser(this.selectedSingleUser)
    this.sharedService.Create_form(true)
    this.userService.createClicke.next(true)
    this.switchModal('Form');
    this.showSidebar = true;

  }

  getUser_queries(query_name, query_type, query_value) {
    this.loading = true;

    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    // this.customersService.queris.map((query, index) => {
    //   if (query.query_name == query_name) {
    //     query.query_value = query_value
    //     query.query_type = query_type?.toLocaleLowerCase()
    //     re = true
    //   }
    // })
    //if (query.query_name == query_name) {
      //     query.query_value = query_value
      //     query.query_type = query_type?.toLocaleLowerCase()
      //     re = true
      //   }
    if (re == false) {


      //this.customersService.queris.push({
      //  query_name: query_name,
      //  query_type: query_type?.toLocaleLowerCase(),
     //   query_value: query_value
     // })
    }



    this.usersCount = 0
    // const subscription = this.customersService.getCustomers().subscribe((customers: PaginatedResponse<Customer[]>) => {
    //   if (!isSet(customers)) {
    //     return;
    //   }
    //   this.customerNextPage = 0
    //   this.pageViwed = []
    //   this.pageViwed.push(1)
    //   this.customersCount = customers.count
    //   this.total_pages = customers.total_pages
    //   this.customers = Array(this.customersCount).fill(0)


    //   const clone = Customer.cloneManyObjects(customers.results);
    //   clone.map(e => {
    //     if (e?.contact) {
    //       if (isSet(e?.contact[0])) {
    //         e.firstcontact = e?.contact[0]
    //       }
    //     }
    //     e.cn_remained = e.credit_notes - (e.cn_consumed + e.cn_payments)
    //      this.filteredCustomers.push(e)
    //  })

    //   this.customerCurrentnumber = clone.length
    //   for (let index = 0; index < clone.length; index++) {
    //     this.customers[index] = clone[index];

    //   }



    //   subscription.unsubscribe();
    //   this.loading = false;
    // }, error => {
    //   this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    //   subscription.unsubscribe();
    //   this.loading = false;

    // });

  }

  completeMethod(serach_item) {
    this.getUser_queries('username', 'startswith', serach_item)

  }
  clearUsers_queries(){
   // this.customersService.queris = []
    this.selectedquery_type = null
    this.selected_Usernamequery = new Query()
    this.filterModeName_query = new Query()
    this.filterModeEmail_query = new Query()
    this.filterModePhone_query = new Query()
    this.filterModeCmpanyName_query = new Query()
   
    this.getUsers()
  }
  getUsers(){

  }
  serach(e){
    if (!isSet(e)) {
      return
    }
    this.users = e
  }
  
  getNextUserPage(event){

  }
  customSort(filed: string){
    if (this.sortMode == 1) {
      this.sortMode = -1
     // this.customersService.sort = filed
      return this.getUsers()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
   //   this.customersService.sort = '-' + filed
      return this.getUsers()
    }

  }


  getUsers_queries(query_name, query_type, query_value) {

  }
  selectedUser(user){
    if (this.selectedUsers.includes(user.id) == false) {
      this.selectedUsers.push(user)
    
      
    } else {
      this.selectedUsers = this.selectedUsers.filter(item => item.id !== user)
    }
  }
  public showUser(user) {
    this.selectedSingleUser=user
    this.userService.selectUser(user);
    this.sharedService.update_form.next(false)
    this.switchModal('Form');
    this.showSidebar = true;
  }
  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.modalTitle = this.selectedSingleUser ? this.translate.instant('USER_PROF') : this.translate.instant('USER_FORM_ADD_USERS');
        this.header_body = this.selectedSingleUser ? ` ${this.selectedSingleUser.username}` : null;
        break;
      case 'Dashboard':
        // setTimeout(() => {
        //   this.customersService.selectCustomer(this.selectedCustomer);
         
        // }, 300);
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Dashboard';
        this.modalTitle = this.translate.instant('USER_DASH');
        this.header_body = this.selectedSingleUser.username;
        break;
    }
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    }
  }

  public saveForm() { }
  upadteFormClicked() { 
   // this.customersService.upadteFormClicked.next(true) 
  }
  
}
