<app-loading *ngIf="loading"></app-loading>
<p-toast key="form" position="top-right"></p-toast>

<div class="p-grid">
    <div class="p-col-12">
        <p-card>
            <div class="p-col-11" *ngFor="let role of userRolesMainContent">
                
                <h3>{{role.label}}</h3>
                    <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped"
                    [value]="role.items" >
                        <ng-template pTemplate="header" let-columns>
                            <th style="width: 3em">
                            </th>
                            <th pSortableColumn="lable">
                                <span
                                >
                                {{"ROLES_LIST_NAME"| translate}} </span>
                
                
                            </th>
                            <th pSortableColumn="lable">
                                <span
                                >
                                    {{"ROLES_LIST_ADD"| translate}} </span>
                    
                    
                             </th>
                             <th pSortableColumn="lable">
                                <span
                                >
                                    {{"ROLES_LIST_EDIT"| translate}} </span>
                    
                    
                            </th>
                            <th pSortableColumn="lable">
                                <span
                                >
                                    {{"ROLES_LIST_DELETE"| translate}} </span>
                    
                    
                             </th>
                             <th pSortableColumn="lable">
                                <span
                                >
                                    {{"ROLES_LIST_CONVERT_TO_ORDER"| translate}} </span>
                    
                    
                             </th>
            
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" >
                            
                        <tr >
                            <td style="width: 3em" >
                            <!-- <p-tableCheckbox  [value]="rowData"></p-tableCheckbox> -->
                            </td >
                            <td>{{ rowData.label }}</td>
                            <td (click)="logkt(rowData)" >
                                <p-checkbox  [name]="rowIndex" [value]="rowData" ngDefaultControl [(ngModel)]="selectedReadValues"></p-checkbox>
                                </td>
                            <td  ><p-checkbox  [name]="rowIndex" [value]="rowData" ngDefaultControl [(ngModel)]="selectedEditValues"></p-checkbox></td>
                            <td><p-checkbox  [name]="rowIndex" [value]="rowData" ngDefaultControl [(ngModel)]="selectedDeleteValues"></p-checkbox></td>
                            <td><p-checkbox  [name]="rowIndex" [value]="rowData" ngDefaultControl [(ngModel)]="selectedConvertToOrderValues"></p-checkbox></td>
                        </tr>
    
                    </ng-template>
                    </p-table>
                 </div>
         
         
        </p-card>
    </div>
</div>
