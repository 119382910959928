import { Component, HostListener, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { CreditNote } from 'src/app/models/CreditNotesCustomer';
import { CreditnotesCustomerService } from '../creditnotescustomers.service';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from 'src/app/models/customer';
import { MenuItem, MessageService } from 'primeng/api';
import * as printJS from 'print-js';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';
import { Query } from 'src/app/models/query';
import { Currency } from 'src/app/models/currency';
import { MenuService } from 'src/app/shared/menu.service';
import { OrderStatus } from 'src/app/models/orders/orderstatus';


@Component({
  selector: 'app-creditnotes-list',
  templateUrl: './creditnotescustomers-list.component.html',
  styleUrls: ['./creditnotescustomers-list.component.scss']
})
export class creditnoteslistCustomerComponent extends BaseComponent implements OnInit {
  public selectedCreditNote = new CreditNote()
  public showSidebar = false;
  public LangStats = new OrderStatus()
  public size = SidebarSize.X_LARGE;
  public currentModal;
  public modalTitle = this.translate.instant('NEW_CREDIT_NOTE')
  public header_body
  public selected: CreditNote[] = [];
  public creditNotes: CreditNote[] = [];
  public filteredProducts: CreditNote[] = [];
  public fillterCustomers: Customer[] = [];
  removedialoguee = false
  itemSearch
  keyword = "reference"
  creditSelectedIndex = 0
  credittatusTitle: string
  checkempty: boolean
  langOpctions = [];
  loadingbtnPrint
  loadingbtnemail
  creditNotesNextPage: any = 0
  creditNotesPrevPage: any = 0
  creditCount: any
  creditCurrentnumber: any
  creditNotes_inPage = 0
  total_pages = 0
  pageViwed = []
  pdfViewer: boolean
  pdfheight: number
  statuses = [
    { status: { value: 'draft', label: this.translate.instant('DRAFT'), color: 'Draft' } },
    { status: { value: 'issued', label: this.translate.instant('ISSUED'), color: 'Issued' } },

  ]
  paid_status = [
    { status: { value: 'paid', label:  this.translate.instant('PAID'), color: 'Paid' } },
    { status: { value: 'unPaid', label: this.translate.instant('UNPAID'), color: 'UnPaid' } }

  ]
  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: 'Not Equal',
      value: 'ne'
    },
    {
      type: 'Equal',
      value: 'exact'
    },
    {
      type: 'Less than', value: 'lt'

    },
    {
      type: 'Grater than', value: 'gt'

    },


  ]
  selected_Cutstomerquery = new Customer()
  filterModeApply_Cutstomer: boolean = false
  filterModeFormat_query = new Query()
  filterModeStatus_query = new Query()
  filterModePaid_status = new Query()
  filterModeDate_query = new Query()
  filterModeexpiry_date_query = new Query()
  filterModeTotal_query = new Query()
  seletedquery_type: string
  refresh_selectedCreditNote: boolean = true
  selectedCurrancy = new Currency()
  currencies: Currency[] = []
  currancyOptions: MenuItem[] = [];
  constructor(
    private creditnotesService: CreditnotesCustomerService,
    public translate: TranslateService,
    private router: Router,
    private sharedService: SharedService,private menuService: MenuService,
    public messageService: MessageService,
    private datePipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    // this.getCurrencies()
    this.getCreditNotes();
    this.subscribeOnCreditNotes_Refresh()
    this.refreshSelectedCreditNotes()
    this.importSelected_Order()
    this.currency_ImportOrder()
    this.currencyChanged()
    this.emailbtnLoading()
    this.langOpctions = [
      {
        label: 'EN', command: () => {

          this.creditnotesService.printLang = 'en'
          this.printCreidtt(this.selectedCreditNote.id, this.creditSelectedIndex)
        }
      },
      {
        label: 'FR', command: () => {

          this.creditnotesService.printLang = 'fr'

          this.printCreidtt(this.selectedCreditNote.id, this.creditSelectedIndex)
        }
      },
      {
        label: 'NL', command: () => {

          this.creditnotesService.printLang = 'nl'

          this.printCreidtt(this.selectedCreditNote.id, this.creditSelectedIndex)
        }
      },
    ]


  }


  getCurrencies() {
    this.currencies = []
    this.currancyOptions = []
    const subscription = this.sharedService.currnceyChangeEmitter.subscribe((currencies: Currency[]) => {

      if (!isSet(currencies)) {
        this.currencies = JSON.parse(localStorage.getItem('supported_currencies'))
      } else {
        localStorage.setItem('supported_currencies', JSON.stringify(this.currencies))

        this.currencies = Currency.cloneManyObjects(currencies)

      }
      this.currencies= this.currencies?.reverse();
      this.currencies?.map(c => {
        this.currancyOptions.push(
          {
            label: c.name, command: () => {
              if (this.selectedCurrancy?.id == c?.id) {
                return
              }
              this.selectedCurrancy = c

              this.creditnotesService.currencyChanged.next(c)
            }
          }
        )
      })


    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);
  }

  showAddOrder() {



    this.creditnotesService.upadteFormClicked.next(null)
    this.showSidebar = true;
    this.sharedService.Create_form(true)
    this.selectedCreditNote = new CreditNote();
    this.credittatusTitle = null
    this.modalTitle = this.translate.instant('NEW_CREDIT_NOTE')
    this.header_body = null
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

  }


  showCreditNote(creditNote: CreditNote, index: number) {
    this.creditnotesService.selectCredit_notes(creditNote);
    this.showSidebar = true;
    this.selectedCreditNote = creditNote;
    this.creditSelectedIndex = index
    this.selectedCurrancy = creditNote.currency


    this.modalTitle = this.translate.instant('CREDIT_NOTE')
    this.header_body = `${this.selectedCreditNote.reference}`
    this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status.status?.paid_status)}</span>`
  }
  upadteFormClicked() {
    this.creditnotesService.upadteFormClicked.next(true)
  }
  save() {
    this.creditnotesService.saveForm();
    const subscription = this.creditnotesService.retrieve_lastCreditnoteEmitter.subscribe((creditNote: CreditNote) => {
      if (!isSet(creditNote)) {
        return
      }
      this.selectedCreditNote = CreditNote.cloneObject(creditNote)

      this.modalTitle = ` ${this.selectedCreditNote.reference}`
      this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status?.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status?.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status?.status?.paid_status)}</span>`

      this.creditnotesService.retrieve_lastCreditnote.next(null)

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }

  ///ee
  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.creditNotes = e


  }
  private getCreditNotes() {
    this.loading = true;

    const subscription = this.creditnotesService.getCredit_notes().subscribe((creditNotes: PaginatedResponse<CreditNote[]>) => {
      if (!isSet(creditNotes)) {
        return;
      }

      this.creditNotesNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.creditCount = creditNotes.count
      this.total_pages = creditNotes.total_pages
      this.creditNotes = Array(this.creditCount).fill(0)
      const clone = CreditNote.cloneManyObjects(creditNotes.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
        this.fillterCustomers.push(e.customer)

        this.filteredProducts.push(e)

      })
      this.creditCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.creditNotes[index] = clone[index];


      }


      if (this.refresh_selectedCreditNote == true && clone.length !=0) {

        this.selectedCreditNote = this.creditNotes[this.creditSelectedIndex]
        this.modalTitle = this.translate.instant('CREDIT_NOTE')
        this.header_body = `${this.selectedCreditNote?.reference}`
        console.log(this.selectedCreditNote)
        this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status?.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status?.status?.paid_status)}</span>`

      }
      subscription.unsubscribe();
      this.loading = false;

    }, error => {
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  private subscribeOnCreditNotes_Refresh() {
    const subscription = this.creditnotesService.refreshListEmitter.subscribe((status: boolean) => {

      if (status == true) {


        if (this.creditNotesNextPage != 0) {
          this.refresh_NextPage()
        }
        if (this.creditNotesNextPage == 0) {
          this.getCreditNotes();


        }

        this.creditnotesService.refreshList.next(false);

      }

    });
    this.subscriptions.push(subscription);
  }
  refreshSelectedCreditNotes() {
    this.loading = true
    const subscription = this.creditnotesService.refreshselectedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.refresh_selectedCreditNote = true




      this.creditnotesService.refreshselected.next(null)



    }, error => {
      this.loading = false

      this.handleError(error);
    });

    this.subscriptions.push(subscription);


  }
  delete_Creditnote() {
    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.creditnotesService.deleteCredit_note(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.reference + this.translate.instant(' DELETED ') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: 'Selected credit note deleted' });
          }
          this.creditnotesService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

          subscription.unsubscribe();

        }, error => {
          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
          }
          this.loading = false;
          subscription.unsubscribe();
          this.creditnotesService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

        })

      })


      this.loading = false;
      this.selected = []




    }
    catch (error) {
      this.log(error);
    }

    this.removedialoguee = false
    this.showSidebar = false
  }
  Reload() {
    this.creditnotesService.sort = null
    this.creditnotesService.queris = []
    this.creditnotesService.selectCredit_notes(null)
    let currentRoute = this.router.url.split('?')[0];
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.getCreditNotes()

      this.router.navigate([currentRoute]);
    });
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  confirm_Credit() {
    this.creditnotesService.confirmCreditnote.next(true)
  }
  importSelected_Order() {
    const subscription = this.creditnotesService.importform_OrdersModuleEmitter.subscribe((creditNote: CreditNote) => {
      if (!isSet(creditNote)) {
        return
      }
    setTimeout(() => {
      this.creditnotesService.upadteFormClicked.next(null)
      this.showSidebar = true;
      this.sharedService.Create_form(true)
      this.selectedCreditNote = new CreditNote();
      this.credittatusTitle = null
          this.header_body = null

      this.modalTitle = this.translate.instant('NEW_CREDIT_NOTE')
    }, 500);
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  deleteseletectd_creditNote(creditNote: CreditNote) {
    this.selected = []
    this.selected.push(creditNote)
    this.removedialoguee = true
  }
  public sendByEmail() {
    this.creditnotesService.sendEmailClicked.next(true)
  }
  duplicateOrder() {
    this.creditnotesService.duplicateClicked.next(true)
  }

  printCreidtt(id: string, i: number) {
    this.creditSelectedIndex = i
    // this.pdflistmode = true
    this.loadingbtnPrint = true;
    this.creditNotes[this.creditSelectedIndex].loadprint = true
    const subscription = this.creditnotesService.printCreditNote(id).subscribe((c: any) => {
      if (!isSet(c)) {
        return;
      }

      this.creditnotesService.printLang = null

      this.selectedCreditNote.download_url = c.download_url
      this.selectedCreditNote.view_url = c.view_url
      this.pdfViewer = true




      subscription.unsubscribe();
      this.loadingbtnPrint = false;
      this.creditNotes[this.creditSelectedIndex].loadprint = false

    }, error => {
      subscription.unsubscribe();
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      this.loadingbtnPrint = false;
      this.creditNotes[this.creditSelectedIndex].loadprint = false


    });
  }
  downloadPdf() {
    window.location.href = this.selectedCreditNote?.download_url;

  }
  printPdf() {

    printJS({ printable: this.selectedCreditNote.download_url, type: 'pdf', showModal: true })

  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020
  }
  onDropdownClick_language(rowData: CreditNote, i: number) {
    this.selectedCreditNote = rowData
    this.creditSelectedIndex = i
  }
  getNextCreditPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.creditNotes_inPage = event.first
    } else {
      this.creditNotes_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.creditCount) {
      return
    }

    const num = (this.creditNotes_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.creditNotesNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.creditNotesNextPage = num + 1
      }
      if (this.creditNotesNextPage > this.total_pages) {
        this.creditNotesNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.creditNotesNextPage = this.creditNotes_inPage / 30

    }


    if (this.creditNotes_inPage >= this.creditCurrentnumber) {

      if (this.creditNotesNextPage == this.creditNotesPrevPage) {
        this.creditNotesNextPage = this.creditNotesNextPage - 1
        this.creditNotesPrevPage = this.creditNotesNextPage
      }
      this.creditNotesPrevPage = this.creditNotesNextPage

      if (this.pageViwed.includes(this.creditNotesNextPage)) {
        return
      } else {
        this.loading = true
        const subscription = this.creditnotesService.credit_notesNextPage(this.creditNotesNextPage).subscribe((credit: PaginatedResponse<CreditNote[]>) => {
          if (!isSet(credit)) {
            return;
          }



          const clone = CreditNote.cloneManyObjects(credit.results);
          clone.map(e => {
            e.customer = new Customer()
            e.customer.company_name = e.company_name
            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
            this.fillterCustomers.push(e.customer)

            this.filteredProducts.push(e)
          })
          const shown = this.creditNotes_inPage

          this.creditNotes_inPage = (this.creditNotesNextPage - 1) * 30

          if (this.creditNotes_inPage > shown && this.creditNotesNextPage == this.creditNotesPrevPage) {
            this.creditNotes_inPage = this.creditNotes_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.creditNotes[this.creditNotes_inPage + index] = clone[index];

          }
          if (this.refresh_selectedCreditNote == true) {
            this.selectedCreditNote = this.creditNotes[this.creditSelectedIndex]
            this.modalTitle = this.translate.instant('CREDIT_NOTE')
            this.header_body = `${this.selectedCreditNote.reference}`
            this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status.status?.paid_status)}</span>`


          }
          this.pageViwed.push(this.creditNotesNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  refresh_NextPage() {
    this.loading = true
    const subscription = this.creditnotesService.credit_notesNextPage(this.creditNotesNextPage).subscribe((creditNotes: PaginatedResponse<CreditNote[]>) => {
      if (!isSet(creditNotes)) {
        return;
      }



      const clone = CreditNote.cloneManyObjects(creditNotes.results);
      clone.map(e => {
        e.customer = new Customer()
        e.customer.company_name = e.company_name
        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
        this.fillterCustomers.push(e.customer)

        this.filteredProducts.push(e)
      })
      const shown = this.creditNotes_inPage

      this.creditNotes_inPage = (this.creditNotesNextPage - 1) * 30

      if (this.creditNotes_inPage > shown && this.creditNotesNextPage == this.creditNotesPrevPage) {
        this.creditNotes_inPage = this.creditNotes_inPage
      }


      for (let index = 0; index < clone.length; index++) {

        this.creditNotes[this.creditNotes_inPage + index] = clone[index];

      }

      this.pageViwed.push(this.creditNotesNextPage)
      this.loading = false;
      console.log(this.creditNotes[this.creditSelectedIndex]);

      if (this.refresh_selectedCreditNote == true) {
        this.selectedCreditNote = this.creditNotes[this.creditSelectedIndex]
        this.modalTitle = this.translate.instant('CREDIT_NOTE')
        this.header_body = `${this.selectedCreditNote.reference}`
        this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status.status?.paid_status)}</span>`

      }
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }

  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date' || query_name == 'expiry_date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.creditnotesService.queris.map((query) => {
      if (query.query_name == query_name && query_name != 'status') {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.creditnotesService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.creditCount = 0
    const subscription = this.creditnotesService.getCredit_notes().subscribe((creditNotes: PaginatedResponse<CreditNote[]>) => {
      if (!isSet(creditNotes)) {
        return;
      }
      this.creditNotesNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.creditCount = creditNotes.count
      this.total_pages = creditNotes.total_pages
      this.creditNotes = Array(this.creditCount).fill(0)


      const clone = CreditNote.cloneManyObjects(creditNotes.results);
      clone.map(e => {

        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        this.filteredProducts.push(e)
      })

      this.creditCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.creditNotes[index] = clone[index];

      }

      if (this.refresh_selectedCreditNote == true) {
        this.selectedCreditNote = this.creditNotes[this.creditSelectedIndex]
        this.modalTitle = this.translate.instant('CREDIT_NOTE')
        this.header_body = `${this.selectedCreditNote.reference}`
        this.credittatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedCreditNote?.status?.status?.color}">${this.translate.instant(this.selectedCreditNote?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedCreditNote?.status.status?.paid_color}">${this.translate.instant(this.selectedCreditNote?.status.status?.paid_status)}</span>`

      }

      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.creditnotesService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeStatus_query = new Query()
    this.filterModePaid_status = new Query()
    this.selected_Cutstomerquery = new Customer()
    this.getCreditNotes()
  }
  paid_Status_queries() {

    this.creditnotesService.queris.push(
      {
        query_name: 'status',
        query_type: 'paid',
        query_value: this.filterModePaid_status?.value == 'paid' ? true : false
      }


    )
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.creditnotesService.sort = filed
      return this.getCreditNotes()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.creditnotesService.sort = '-' + filed
      return this.getCreditNotes()
    }







  }
  completeMethod(serach_item) {
    this.creditnotesService.queris = []
    this.getOrders_queries('reference', 'startswith', serach_item)
  }


  currency_ImportOrder() {
    const subscription = this.creditnotesService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }
      this.selectedCreditNote.currency = currancy
      this.selectedCurrancy = currancy
      this.creditnotesService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }

  currencyChanged() {
    const subscription = this.creditnotesService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }

      this.selectedCurrancy = currancy


      this.creditnotesService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }

  emailbtnLoading() {
    const subscription = this.creditnotesService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false

      }
      if (status == true) {
        return this.loadingbtnemail = true

      }
      this.creditnotesService.btnLoading.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
