import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { Quote } from '../../models/quotes/quote';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { OrdersService } from '../orders/orders.service';
import { SendEmail } from 'src/app/models/SendEmail';
import { isSet } from '../core/util';
import { Order } from '../../models/orders/order';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  public currentOrder: Quote;
  public updateOrderItem: boolean = false;
  public itemIndex: number;
  public printLang: string;
  public queris = []
  sort :any

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public submitStuats: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public submitStuatsEmitter: Observable<boolean> = this.submitStuats.asObservable();


  public printqoute_link: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public printqoute_linkEmitter: Observable<any> = this.printqoute_link.asObservable();

  public btnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public btnLoadingEmitter: Observable<boolean> = this.btnLoading.asObservable();

  private refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();

  public duplicateClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public duplicateClickedEmitter: Observable<boolean> = this.duplicateClicked.asObservable();


  public QuoteSelected: BehaviorSubject<Quote> = new BehaviorSubject<Quote>(null);
  public QuoteSelectedListEmitter: Observable<Quote> = this.QuoteSelected.asObservable();


  private ConvertClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public ConvertClickedClickedEmitter: Observable<boolean> = this.ConvertClicked.asObservable();


  private sendEmailClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sendEmailClickedEmitter: Observable<boolean> = this.sendEmailClicked.asObservable();

  public refreshselected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshselectedEmitter: Observable<boolean> = this.refreshselected.asObservable();

  public retrieve_lastOrder: BehaviorSubject<Quote> = new BehaviorSubject<Quote>(null);
  public retrieve_lastOrderEmitter: Observable<Quote> = this.retrieve_lastOrder.asObservable();


  public currencyChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currencyChangedEmitter: Observable<any> = this.currencyChanged.asObservable();

  constructor(private api: ApiService, private router: Router, private OrdersService: OrdersService, private datePipe: DatePipe) {
  }

  getQuotes() {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<Quote[]>>(`/quotes?${sum_querirs}&order=${this.sort}`);

    }

    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Quote[]>>(`/quotes?order=${this.sort}`);

    }
  }
  quotesNextPage(num): Observable<PaginatedResponse<Quote[]>>{
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<Quote[]>>(`/quotes?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Quote[]>>(`/quotes?page=${num}&order=${this.sort}`);

    }
  }
  getQuote(id: string): Observable<Quote> {
    return this.api.get<Quote>(`/quotes/rud/${id}`);
  }
  getNextOrderNumber(): Observable<{ format: string }> {
    return this.api.get<{ format: string }>('quotes/next_format');
  }

  addQuote(quote: Quote): Observable<Quote> {
    let ddiscont = quote.discount
    if (quote.discount_type == 'Percent') {
      ddiscont = quote.discount + '%'
    }

    let date = this.datePipe.transform(quote.date, 'yyyy-MM-dd')
    for (let x = 0; x < quote.items.length; x++) {
      if (isSet(quote.items[x].service)) {
        quote.items[x].name = null
      }
    }
    let expiry_date = this.datePipe.transform(quote.expiry_date, 'yyyy-MM-dd')
    console.log(quote.toDB(date, expiry_date, ddiscont));

    return this.api.post('/quotes', quote.toDB(date, expiry_date, ddiscont));
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }
  Converttoroeder_buuton(state) {
    this.ConvertClicked.next(state);
  }
  sendEmail_buuton(state) {
    this.sendEmailClicked.next(state);
  }
  refreshDataTable() {
    this.refreshList.next(true);
  }

  deleteQuotes(id: string): Observable<Quote> {
    return this.api.delete<Quote>(`/quotes/rud/${id}`);
  }
  selectQuote(quote: Quote) {
    this.QuoteSelected.next(quote);
  }

  convertToOrder(quote: Quote) {
    if (!isSet(quote)) {
      return
    }
    this.router.navigateByUrl(`sell/orders?from-quote=${quote.id}`)
    let order = new Order()
    order.idQuoteConvert = quote.id
    order.date = quote.expiry_date
    order.customer = quote.customer
    order.discount = quote.discount
    order.discountType = quote.discount_type
    order.items = quote.items
    order.currency = quote.currency
    order.exchange_rate = quote.exchange_rate

    order.items.map(items => {
      items.datePipe = new Date(items.date)
    })
    order.payments = []

    order.notes = quote.notes
    order.payment_terms = quote.payment_terms
    return this.OrdersService.QouteToOrder(order)

  }
  SendEmail(SendEmail: SendEmail, id: string): Observable<SendEmail> {
    return this.api.post(`/quotes/send_mail/${id}`, SendEmail.toDB());
  }




  PrintQoute(id: string): Observable<any> {
    let printLang
    if (!isSet(this.printLang)) {
      printLang = JSON.parse(localStorage.getItem("currentLang"))?.lang.toLocaleLowerCase() || 'en'
    } else {
      printLang = this.printLang
    }
    let body = {
      lang: printLang.toLocaleLowerCase()
    }


    return this.api.post<any>(`/quotes/print_quote/${id}`, body);
  }

  upadeQoute(quote: Quote) {
    let ddiscont = quote.discount
    if (quote.discount_type == 'Percent') {
      ddiscont = quote.discount + '%'
    }
    let date = this.datePipe.transform(quote.date, 'yyyy-MM-dd')
    let expiry_date = this.datePipe.transform(quote.expiry_date, 'yyyy-MM-dd')

    let itemstoUpdate = []

    for (let x = 0; x < quote.items.length; x++) {
      if (isSet(quote.items[x].service)) {
        quote.items[x].name = null
      }
      quote.items[x].price = Number(quote.items[x]?.price)
      quote.items[x].qty = Number(quote.items[x]?.qty)
      quote.items[x].vat.value = Number(quote.items[x]?.vat.value)
      quote.items[x].date = this.datePipe.transform(quote.items[x]?.datePipe, 'yyyy-MM-dd')

      itemstoUpdate[x] = {
        id: quote.items[x]?.id,
        product: quote.items[x]?.product?.id,
        name: quote.items[x]?.name,
        qty: quote.items[x]?.qty,
        price: quote.items[x]?.price,
        vat: quote.items[x]?.vat?.id,
        date: quote.items[x]?.date,
        service: quote.items[x]?.service
      }

    }

    let update = {
      date: date,
      expiry_date: expiry_date,
      customer: quote.customer?.id,
      notes: quote.notes,
      payment_terms: quote.payment_terms,
      items: itemstoUpdate,
      discount: ddiscont,
      exchange_rate:quote?.exchange_rate

    }


    return this.api.patch<any>(`/quotes/rud/${quote.id}`, update)



  }
}
