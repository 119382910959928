import { isSet } from 'src/app/modules/core/util';
import { Address } from './../address';

export class Warehouses {
    id:string;
    name:string;
    contact_email:string;
    contact_phone:string;
    address:Address[]

    public constructor(params?: Warehouses) {
        Object.assign(this, params);
      }
      public static cloneObject(objectToClone: Warehouses) {
        if (!isSet(objectToClone)) {
          return new Warehouses();
        }
        const obj = new Warehouses(objectToClone);

        if (isSet(obj.address)) {
            obj.address = Address.cloneManyObjects(objectToClone.address);
        }

    
        return obj;
      }
    
      public static cloneManyObjects(objectsToClone: Warehouses[]) {
        const objects: Warehouses[] = [];
        for (const obj of objectsToClone) {
          objects.push(Warehouses.cloneObject(obj));
        }
    
        return objects;
      }

      public toDB() {
            return {
            name:this.name,
            contact_email:this.contact_email,
            contact_phone:this.contact_phone,
            address:this.address
      }
    }
}
