import { isSet } from '../../modules/core/util';
import { Summary } from './summary';



export class Summaries {
  count :number
  invoices: Summary;
  cc: Summary;
  total: Summary

  public constructor(params?: Summaries) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Summaries) {
    if (!isSet(objectToClone)) {
      return new Summaries();
    }
    const obj = new Summaries(objectToClone);

    if (isSet(objectToClone.invoices)) {
      obj.invoices = Summary.cloneObject(objectToClone.invoices)
    }
    if (isSet(objectToClone.cc)) {
      obj.cc = Summary.cloneObject(objectToClone.cc)
    }
    if (isSet(objectToClone.total)) {
      obj.total = Summary.cloneObject(objectToClone.total)
    }
    return obj

  }

  public static cloneManyObjects(objectsToClone: Summaries[]) {
    const objects: Summaries[] = [];
    for (const obj of objectsToClone) {
      objects.push(Summaries.cloneObject(obj));
    }

    return objects;
  }









}


