<div class="layout-footer">
  <div class="logo-text">
    <img style="    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;"  src="assets/img/logo7.png" alt="mirage-layout" />

  </div>
  <div class="icons">
    <div class="icon icon-hastag">
      <i class="pi pi-home"></i>
    </div>
    <div class="icon icon-twitter">
      <i class="pi pi-globe"></i>
    </div>
    <div class="icon icon-prime">
      <i class="pi pi-bookmark"></i>
    </div>
  </div>
</div>
