import { DimensionUnits } from './../../models/dimension-units';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../../models/product';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { ApiService } from '../core/api.service';
import { Vat } from '../../models/vat';
import { Unit } from '../../models/unit';
import { Category } from '../../models/category';
import { Supplier } from '../../models/supplier';
import { PackageType } from '../../models/packagetype';
import { isSet } from '../core/util';
import { Brand } from 'src/app/models/brand';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public attachmentlink: string;
  public queris = []
  sort: any
  private productSelected: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);
  public productSelectedEmitter: Observable<Product> = this.productSelected.asObservable();

  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();

  public retrieveLastProduct: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);
  public retrieveLastProductEmitter: Observable<Product> = this.retrieveLastProduct.asObservable();

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  private refreshProductsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshProductsListEmitter: Observable<boolean> = this.refreshProductsList.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();
  constructor(private api: ApiService) { }

  getProducts(): Observable<PaginatedResponse<Product[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<Product[]>>(`/products?${sum_querirs}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Product[]>>(`/products?order=${this.sort}`)
    }
  }
  getProductsNextPage(num): Observable<PaginatedResponse<Product[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<Product[]>>(`/products?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Product[]>>(`/products?page=${num}&order=${this.sort}`);

    }
  }
  searchProducts(value): Observable<PaginatedResponse<Product[]>> {
    return this.api.get<PaginatedResponse<Product[]>>(`/products?query=name,startswith,${value}`);
  }
  searchProductsBarcode(value): Observable<PaginatedResponse<Product[]>> {
    return this.api.get<PaginatedResponse<Product[]>>(`/products/barcode_search?barcode=${value}`);
  }
  getProduct(id: string): Observable<Product> {
    return this.api.get<Product>(`/products/rud/${id}`);
  }

  getVats(): Observable<PaginatedResponse<Vat[]>> {
    return this.api.get<PaginatedResponse<Vat[]>>('/vats');
  }

  deleteDimentionUnite(id: string): Observable<DimensionUnits> {
    return this.api.delete<DimensionUnits>(`/dimension_units/${id}`)
  }

  getUnits(): Observable<PaginatedResponse<Unit[]>> {
    return this.api.get<PaginatedResponse<Unit[]>>('/units');
  }

  updateUnit(id: Unit): Observable<Unit> {
    let update = {
      name: id.name
    }
    return this.api.patch(`/units/${id.id}`, update)
  }

  updateDimentionUnit(id: DimensionUnits): Observable<DimensionUnits> {
    let update = {
      name: id.name
    }
    return this.api.patch<DimensionUnits>(`/dimension_units/${id.id}`, update)
  }
  updateBrand(id: Brand): Observable<Brand> {
    let update = {
      name: id.name
    }
    return this.api.patch<Brand>(`/brands/rud/${id.id}`, update)
  }

  updateCatchClause(id: Category): Observable<Category> {
    let update = {
      name: id.name
    }
    return this.api.patch<Category>(`/product_categories/${id.id}`, update)
  }

  updatePackageType(id: PackageType): Observable<PackageType> {
    let update = {
      name: id.name
    }
    return this.api.patch<PackageType>(`/package_types/${id.id}`, update)
  }

  getCategories(): Observable<PaginatedResponse<Category[]>> {
    return this.api.get<PaginatedResponse<Category[]>>('/product_categories');
  }

  getDimensionUnits(): Observable<PaginatedResponse<DimensionUnits[]>> {
    return this.api.get<PaginatedResponse<DimensionUnits[]>>('/dimension_units');
  }
  getBrands(): Observable<PaginatedResponse<Brand[]>> {
    return this.api.get<PaginatedResponse<Brand[]>>('/brands');
  }

  getPackageTypes(): Observable<PaginatedResponse<PackageType[]>> {
    return this.api.get<PaginatedResponse<PackageType[]>>('/package_types');
  }

  saveProduct(product: Product): Observable<Product> {

    return this.api.post('/products', product?.toDB());
  }
  updateProduct(product: Product): Observable<Product> {
 
    return this.api.put(`/products/rud/${product.id}`,  product?.toDB());
  }
  save_Product_categories(category: Category): Observable<Category> {

    return this.api.post('/product_categories', category?.toDB());
  }
  save_package_type(packageType: PackageType): Observable<PackageType> {
    return this.api.post(`/package_types`, packageType.toDB())

  }
  saveBrand(brand: Brand): Observable<Brand> {
    return this.api.post(`/brands`, brand.toDB())

  }
  save_dimension_units(dimensionUnits: DimensionUnits): Observable<DimensionUnits> {

    return this.api.post('/dimension_units', dimensionUnits?.toDB());
  }

  save_units(unit: Unit): Observable<Unit> {
    return this.api.post('/units', unit?.toDB())
  }


  saveMiniProduct(product: Product): Observable<Product> {

    return this.api.post('/min_product', product.toMiniDB());
  }


  deleteProduct(id: string): Observable<Product> {
    return this.api.delete<Product>(`/products/rud/${id}`);
  }
  deletePackageType(id: string): Observable<PackageType> {
    return this.api.delete<PackageType>(`/package_types/${id}`)
  }
  deleteBrand(id: string): Observable<Brand> {
    return this.api.delete<Brand>(`/brands/rud/${id}`)
  }
  public selectProduct(product: Product) {
    this.productSelected.next(product);
  }
  deleteUnit(id: string): Observable<Unit> {
    return this.api.delete<Unit>(`/units/${id}`)
  }
  deleteCat(id: string): Observable<Category> {
    return this.api.delete<Category>(`/product_categories/${id}`)
  }

  public saveForm() {
    this.saveFormClicked.next(true);
  }

  refreshProducts() {
    this.refreshProductsList.next(true);
  }


  generate_Upload(file: any) {
    const subscription = this.api.generate_upload_url<any>(`/generate_s3_upload_url`, file).subscribe(uploadDetils => {

      if (!isSet(uploadDetils)) {
        return
      }
      subscription.unsubscribe()
      return this.uploadDetils.next(uploadDetils)


    })
  }



  generate_download(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  remove_download() {
    let d = {
      file_type: 'user_report',
      file_name: null
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  productNextPage(num): Observable<PaginatedResponse<Product[]>> {
    return this.api.get<PaginatedResponse<Product[]>>(`/products?page=${num}`);
  }

  upload(filee: any) {
    const subscription = this.uploadDetilsEmitter.subscribe(uploadDetils => {
      if (!isSet(uploadDetils)) {
        return;
      }

      const credential = uploadDetils.upload_data.fields as credentialfilee


      const body = new FormData()
      this.uploadkey.next(credential.key)
      body.append("key", credential.key)
      body.append("x-amz-algorithm", credential['x-amz-algorithm'])
      body.append("x-amz-credential", credential['x-amz-credential'])
      body.append("x-amz-date", credential['x-amz-date'])
      body.append("policy", credential.policy)
      body.append("x-amz-signature", credential['x-amz-signature'])
      body.append("file", filee, filee.name)

      const sub = this.api.Uploadfile<any>(uploadDetils.upload_data.url, body).subscribe(() => {
        this.attachmentlink = credential.key

        this.uploadstuats.next(true)
        sub.unsubscribe()
      }, error => {
        this.uploadstuats.next(false)
        sub.unsubscribe()

      }
      )
      this.uploadDetils.next(null)
      subscription.unsubscribe()

    }, error => {
      subscription.unsubscribe()

    })



  }
  delete_File(filename: any) {
    console.log(filename);

    let d = {
      file_type: 'user_report',
      file_name: filename?.path
    }
    return this.api.deleteFile(`/delete_s3_file`, d)
  }
}
export class credentialfilee {
  "key": string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "policy": string
  "x-amz-signature": string
}
