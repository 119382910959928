<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
    <div class="p-col-12">
      <div class="card p-0 pt-1">
        <app-table-header (Reload)='Reload()' (remove)="remove()" (add)="showAddNewUser()"
          addLabel='Create User' [disableRemove]="!selected.length">
          <app-Search-select [selecteditem]="itemSearch" (completeMethod)="completeMethod($event)"
            (onClear)="clearUsers_queries()" [Searchitems]="filteredUsers" [field_name]="keyword"
            (filter)="serach($event)"></app-Search-select>
        </app-table-header>
  
        <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped"
          (onPage)="getNextUserPage($event)" [value]="users" [paginator]="true" [rows]="10"
          [(selection)]="selected" [scrollable]="true" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {{usersCount}} entries"
          [globalFilterFields]="['image','username','name','role','email','mobile']">
  
          <ng-template pTemplate="header" let-columns>
  
            <tr>
              <th style="width: 3em">
                <!-- <p-tableHeaderCheckbox (click)="deleteRow(selected)"></p-tableHeaderCheckbox> -->
              </th>

              <th pSortableColumn="username">
                <span
                  [style.color]="selected_Usernamequery?.type && selected_Usernamequery?.value?'var(--primary-color)':null">
                  User Picture </span>
                <p-sortIcon (click)="customSort('username')" field="username"></p-sortIcon>
  
                <p-columnFilter #companyNameFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                  [showAddButton]="false" type="text" field="reference" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">
  
                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="selected_Usernamequery.type"
                      placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type}}</span>
                      </ng-template>
                    </p-dropdown>
  
                    <input [(ngModel)]="selected_Usernamequery.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearUsers_queries()"
                          class="p-button-outlined" type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton [disabled]="!selected_Usernamequery?.type || !selected_Usernamequery?.value"
                          label='{{"APPLY" | translate}}'
                          (click)="getUsers_queries('username',selected_Usernamequery?.type,selected_Usernamequery?.value);companyNameFilter.overlayVisible=false"
                          type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
  
                </p-columnFilter>
  
  
              </th>
              <th>
  
                <span [style.color]="filterModeName_query?.type && filterModeName_query?.value ?'var(--primary-color)':null"
                  class="p-mr-1"> User Name </span>
  
                <p-columnFilter #customerListVatFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                  [showAddButton]="false" type="text" field="vat" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">
  
                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeName_query.type"
                      placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type}}</span>
                      </ng-template>
                    </p-dropdown>
  
                    <input [(ngModel)]="filterModeName_query.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearUsers_queries()"
                          class="p-button-outlined" type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton [disabled]="!filterModeName_query?.type || !filterModeName_query?.value"
                          label='{{"APPLY" | translate}}'
                          (click)="getUsers_queries('name',filterModeName_query?.type,filterModeName_query?.value);customerListVatFilter.overlayVisible=false"
                          type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
  
                </p-columnFilter>
              </th>
              <th>
  
                <span
                  [style.color]="filterModeEmail_query?.type && filterModeEmail_query?.value ?'var(--primary-color)':null"
                  class="p-mr-1"> Role </span>
  
                <p-columnFilter #roleFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                  [showAddButton]="false" type="text" field="vat" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">
  
                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeEmail_query.type"
                      placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type}}</span>
                      </ng-template>
                    </p-dropdown>
  
                    <input [(ngModel)]="filterModeEmail_query.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearUsers_queries()"
                          class="p-button-outlined" type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton
                          [disabled]="!filterModeEmail_query?.type || !filterModeEmail_query?.value"
                          label='{{"APPLY" | translate}}'
                          (click)="getUsers_queries('email',filterModeEmail_query?.type,filterModeEmail_query?.value);roleFilter.overlayVisible=false"
                          type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
  
                </p-columnFilter>
              </th>
              <th>
  
                <span
                  [style.color]="filterModeEmail_query?.type && filterModeEmail_query?.value ?'var(--primary-color)':null"
                  class="p-mr-1"> {{"LOGIN_EMAIL"| translate}} </span>
  
                <p-columnFilter #contactEmailFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                  [showAddButton]="false" type="text" field="vat" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">
  
                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeEmail_query.type"
                      placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type}}</span>
                      </ng-template>
                    </p-dropdown>
  
                    <input [(ngModel)]="filterModeEmail_query.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearUsers_queries()"
                          class="p-button-outlined" type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton
                          [disabled]="!filterModeEmail_query?.type || !filterModeEmail_query?.value"
                          label='{{"APPLY" | translate}}'
                          (click)="getUsers_queries('email',filterModeEmail_query?.type,filterModeEmail_query?.value);contactEmailFilter.overlayVisible=false"
                          type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
  
                </p-columnFilter>
              </th>

              <th>
  
                <span
                  [style.color]="filterModePhone_query?.type && filterModePhone_query?.value ?'var(--primary-color)':null"
                  class="p-mr-1"> {{"CUSTOMER_FORM_PHONE"| translate}} </span>
  
                <p-columnFilter #contactPhoneFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                  [showAddButton]="false" type="text" field="vat" display="menu">
                  <ng-template id="statusFilter" pTemplate="filter">
  
                    <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModePhone_query.type"
                      placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                      <ng-template let-item pTemplate="item">
                        <span>{{item.type}}</span>
                      </ng-template>
                    </p-dropdown>
  
                    <input [(ngModel)]="filterModePhone_query.value" class="p-mt-3" type="text" pInputText>
                    <div class="p-grid p-mt-2">
                      <div class="p-col-6">
                        <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearUsers_queries()"
                          class="p-button-outlined" type="button">
                        </button>
                      </div>
                      <div class="p-col-6">
                        <button pButton
                          [disabled]="!filterModePhone_query?.type || !filterModePhone_query?.value"
                          label='{{"APPLY" | translate}}'
                          (click)="getUsers_queries('mobile',filterModePhone_query?.type,filterModePhone_query?.value);contactPhoneFilter.overlayVisible=false"
                          type="button">
                        </button>
                      </div>
                    </div>
                  </ng-template>
  
                </p-columnFilter>
              </th>

            

           
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width: 3em">
                <p-tableCheckbox (click)="selectedUser(rowData)" [value]="rowData"></p-tableCheckbox>
              </td>
              <td>
                <span *ngIf="rowData.image  ;else nul"> 
                  <img class="p-ml-5" (click)="showUser(rowData)" style="width: 50px; height: 50px;border-radius: 50%;cursor: pointer" [src]='rowData.image'> </span>
                <ng-template #nul>ــ</ng-template>
              <td>
                <span style="cursor: pointer" (click)="showUser(rowData)" *ngIf="rowData.first_name||rowData.last_name  ;else nul">
                   {{ rowData.first_name }} {{ rowData.last_name }} </span>
                <ng-template #nul>ــ</ng-template>
              </td>
              <td>
                <span *ngIf="rowData.email  ;else nul"> {{ rowData?.role }}</span>
                <ng-template #nul>ــ</ng-template>
              </td>
              <td>
                <span *ngIf="rowData.email  ;else nul"> {{ rowData?.email }}</span>
                <ng-template #nul>ــ</ng-template>
              </td>
              <td>
                <span *ngIf="rowData.mobile  ;else nul"> {{ rowData.mobile }}</span>
                <ng-template #nul>ــ</ng-template>
              </td>
              <!-- <td>
  
                <span *ngIf="rowData.firstcontact?.email  ;else nul"> {{ rowData.firstcontact?.email }}</span>
                <ng-template #nul>ــ</ng-template>
              </td>-->

            </tr>
          </ng-template>
  
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">No Users found</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <p-dialog modal="true"  class="p-shadow-1" header="Delete"
  [(visible)]="Removedialogue" [style]="{width: '30vw'}">
  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> {{"DASHBORD_DELETE_USER_LIST" | translate}}
  </div>
  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"USER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.username}} ?</span>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="RemoveUser()"
      label=' {{"USER_LIST_DELETE_BUTTON"| translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="Removedialogue=false"
      label=' {{"USER_LIST_CANACEL_BUTTON"| translate}} ' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [size]="size">
  <div header>

    <button pButton *ngIf="selectedSingleUser != null && selectedSingleUser != undefined && currentModal == 'Dashboard'"
      (click)="switchModal('Form')" type="button" label='Profile'
      class="ui-button-raised ui-button-info p-mr-2"></button>
    <button pButton (click)="saveForm()" *ngIf="!selectedSingleUser" type="button"
      label='{{"CUSTOMER_LIST_SAVE"| translate}}' class="ui-button-raised ui-button-info"></button>
    <button *ngIf=" selectedSingleUser  && currentModal == 'Form'" (click)="upadteFormClicked()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE"| translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-user-form *ngIf="currentModal == 'Form' && showSidebar"></app-user-form>
  </div>
</app-sidebar>