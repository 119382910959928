import { Component, HostListener, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { CreditNote } from 'src/app/models/CreditNotesSupplier';
import { creditnotessupplireService } from '../creditnotessupplier.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Supplier } from 'src/app/models/supplier';

@Component({
  selector: 'app-creditnotessupplier-list',
  templateUrl: './creditnotessupplier-list.component.html',
  styleUrls: ['./creditnotessupplier-list.component.scss']
})
export class creditnotessupplirelistComponent extends BaseComponent implements OnInit {
  public selectedCreditNote :CreditNote
  public showSidebar = false;
  public size = SidebarSize.X_X_LARGE;
  public currentModal;
  public modalTitle = 'CreateCredit Note' 

  public selected: CreditNote[] = [];
  public CreditNotes: CreditNote[] = [];
  public filteredProducts: CreditNote[] = [];
  public filteredVendors: Supplier[] = [];
  currancyOptions = [];
  CreditNotesNextPage: any = 0
  creditNotesPrevPage: any = 0
  CreditCount: any
  currentChangedCurrancy:string;
  public selectedCurrancy:any
  CreditCurrentnumber: any
  creditNotes_inPage = 0
  total_pages = 0
  pageViwed = []
  itemSearch
  keyword="invoice_format"
  checkempty:boolean
  constructor(private creditnotesService: creditnotessupplireService ,public messageService: MessageService,
    public translate:TranslateService, private router:Router,private SharedService : SharedService ) {
    super();
  }

  ngOnInit(): void {
    this.getCreditNotes();
    this. ShowSideBar()
    this.currancyOptions = [
      {
        label: "Erou ",
        icon: 'fas fa-euro-sign',

        command: () => {
          this.creditnotesService.currencyChanged.next('€')
          let body ={
            type:'Erou',
            sign:"€",
            icon:'fas fa-euro-sign'
          }
          localStorage.setItem("currentCurrncy", JSON.stringify(body))
          this.currentChangedCurrancy='€'
          this.selectedCurrancy={
            type:'Erou',
            sign:"$",
            icon:'fas fa-euro-sign'
          }
        },
      },
      {
        label: "Dollar",
        icon: 'fas fa-dollar-sign',

        command: () => {
          this.creditnotesService.currencyChanged.next('$')
          let body ={
            type:'Dollar',
            sign:"$",
            icon:'fas fa-dollar-sign'
          }
          localStorage.setItem("currentCurrncy", JSON.stringify(body))
          this.currentChangedCurrancy='$'
          this.selectedCurrancy='Dollar '
          this.selectedCurrancy={
            type:'Dollar',
            sign:"$",
            icon:'fas fa-dollar-sign'
          }
          
        },
      },
      {
        label: "Pound sterling ",
        icon:'fas fa-pound-sign',
        command: () => {
          this.creditnotesService.currencyChanged.next('£')
          let body ={
            type:'Pound sterling',
            sign:"£",
            icon:'fas fa-pound-sign'
          }
          localStorage.setItem("currentCurrncy", JSON.stringify(body))
          this.currentChangedCurrancy='£'
          this.selectedCurrancy={
            type:'Pound sterling',
            sign:"£",
            icon:'fas fa-pound-sign'
          }

          
        },
      },
    ];

  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.modalTitle = this.selectedCreditNote ? `Edit ${this.selectedCreditNote.order_format}` :  this.translate.instant('NEW_CREDIT_NOTE');
        break;
     }
  }

  ShowSideBar(){
  this.creditnotesService.CreditNoteSelectedListEmitter.subscribe((order: CreditNote) => {
      if(!isSet(order)) {
        return;
      }
this.showSidebar=true

this.switchModal('Form');
    }, error => {
      this.handleError(error);
    });
  }

  showAddOrder() {

    this.creditnotesService.selectCredit_notes(null);
    this.showSidebar = true;
    this.SharedService.Create_form(true)

    this.selectedCreditNote = null;
    this.switchModal('Form');
  }

  showOrder(CreditNote: CreditNote) {
    this.creditnotesService.selectCredit_notes(CreditNote);
    this.showSidebar = true;
    this.selectedCreditNote = CreditNote;
    this.switchModal('Form');
  }

  save() {
    this.creditnotesService.saveForm();
  }
  RemoveOrder(CreditNote:CreditNote) {
  
    try{
      

        this.loading = true;
        const subscription = this.creditnotesService.deleteCredit_note(CreditNote.id).subscribe(() => {
          if (!isSet(CreditNote)) {
            return;
          }
          this.loading = false;
          this.creditnotesService.refreshDataTable();
          subscription.unsubscribe();
          this.Reload()


        }, error => {
      
          this.loading = false;
          subscription.unsubscribe();
        })


      
      

    
    }       
    catch (error) {
      console.log(error)
      this.log(error);
    }
  }
  ///ee
serach(e){
  if(!isSet(e)){
    return
  }
  this.CreditNotes = e


}
  private getCreditNotes() {
    this.loading = true;

    const subscription = this.creditnotesService.getCredit_notes().subscribe((creditNote: PaginatedResponse<CreditNote[]>) => {
      if (!isSet(creditNote)) {
        return;
      }
      this.pageViwed.push(1)
      this.CreditNotes = CreditNote.cloneManyObjects(creditNote.results);
      console.log(this.CreditNotes);
      
      this.CreditNotes.map(e=>{
        this.filteredProducts.push(e)

      })
      subscription.unsubscribe();
      this.loading = false;

    }, error => {
      console.log(error);
      
      subscription.unsubscribe();
      this.loading = false;

    });
  }
 
  Reload(){
    let currentRoute = this.router.url.split('?')[0];
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); 

  });


    
  }
  getNextCreditPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.creditNotes_inPage = event.first
    } else {
      this.creditNotes_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.CreditCount) {
      return
    }

    const num = (this.creditNotes_inPage / 30)
if ( event.rows == 10) {
     if (num % 1 != 0) {
      this.CreditNotesNextPage = Math.round(num) + 1
    } else if (num % 1 == 0) {
      this.CreditNotesNextPage = num + 1
    }
    if (this.CreditNotesNextPage > this.total_pages) {
      this.CreditNotesNextPage = this.total_pages
    }
  }
  if ( event.rows == 30) {
    this.CreditNotesNextPage = this.creditNotes_inPage /30

  }

  
    if (this.creditNotes_inPage >= this.CreditCurrentnumber) {

      if (this.CreditNotesNextPage == this.creditNotesPrevPage) {
        this.CreditNotesNextPage = this.CreditNotesNextPage - 1
        this.creditNotesPrevPage = this.CreditNotesNextPage
      }
      this.creditNotesPrevPage = this.CreditNotesNextPage

      if (this.pageViwed.includes(this.CreditNotesNextPage)) {
        return
      } else {
        this.loading = true
        const subscription = this.creditnotesService.credit_notesNextPage(this.CreditNotesNextPage).subscribe((credit: PaginatedResponse<CreditNote[]>) => {
          if (!isSet(credit)) {
            return;
          }



          const clone = CreditNote.cloneManyObjects(credit.results);
          clone.map(e => {
            e.vendor = new Supplier()
            e.vendor.name = e.vendor.name
            // let datefillter = new Date(e.date)

            // e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
            this.filteredVendors.push(e.vendor)

            this.filteredProducts.push(e)
          })
          const shown = this.creditNotes_inPage

          this.creditNotes_inPage = (this.CreditNotesNextPage - 1) * 30

          if (this.creditNotes_inPage > shown && this.CreditNotesNextPage == this.creditNotesPrevPage) {
            this.creditNotes_inPage = this.creditNotes_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.CreditNotes[this.creditNotes_inPage + index] = clone[index];

          }
          
          this.pageViwed.push(this.CreditNotesNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
