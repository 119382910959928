<p-toast key="form" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid order-form">
  <div class="p-col-12 col-12-r">
    <div class="p-grid ">
      <div class="p-col-8 col-4-r ">
        <p-card>
          <div
            [style.height]="heightCardCustomer == true || confirmExchangeRate ==true  ?'auto':paymentCardHeight+'px'"
            class="p-grid">
            <div class="p-col-8 col-4-r ">
              <div class="form-group ">
                <p-inplace [disabled]="order?.status?.invoiced || order?.status?.cc " #customer>
                  <span id="customer" aria-required="true" pInplaceDisplay>
                    <button #selecCustomer *ngIf="order?.customer" iconPos="right" [icon]="editCustomerIcon" pButton
                            [label]="order?.customer.company_name" type="button"
                            class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace customerinplace-icon"></button>
                    <button [ngClass]="{'alertBtn-Customer': order?.customer==null && customerOrder==true}"
                      *ngIf="!order?.customer" pButton id="selectCustomer"
                      label='{{"QUOTES_LIST_SELECT_CUSTOMER" | translate}}' icon="fas fa-id-card-alt tags" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace">
                    </button>
                    <div *ngIf="order?.customer==null && customerOrder==true" class="alert-Customer">
                      {{"CUSTOMER_FORM_REQUIRED" | translate}} </div>
                  </span>
                  <app-customers-select #customerSelect pInplaceContent [(customer)]="order.customer"
                    (customerChange)="customerChange($event)" (onHide)="customer.deactivate()"></app-customers-select>
                </p-inplace>
              </div>
              <div style="font-weight: 600;"
                *ngIf="isSet(order?.customer) && order?.customer?.company_name != 'Cash & Carry'"
                class="p-grid  text-muted">
                <div class="p-col-6 ">
                  <span *ngIf="order?.customer?.vat "
                    [ngClass]="{'text-muted': order?.status?.invoiced}">{{"CUSTOMER_LIST_VAT" | translate}}: </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}">{{ order?.customer?.vat }}</span><br>

                  <span *ngIf="order?.customer?.invoicing_address?.address" class="bold p-mt-2"
                    [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{"CUSTOMER_FORM_INV_ADDRESS" | translate}}
                    <br></span>
                  <span *ngIf="order?.customer?.invoicing_address?.address"
                    [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.invoicing_address?.address}}
                    <br></span>
                  <span *ngIf="order?.customer?.invoicing_address?.city"
                    [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.invoicing_address?.city }}
                    <br></span>
                  <span *ngIf="order?.customer?.invoicing_address?.country_code  "
                    [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.invoicing_address?.country_code}}
                    <br></span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}"
                    *ngIf="order?.customer?.contact && order?.customer?.contact[0]?.email">
                    {{"CUSTOMER_FORM_EMAIL" |translate}}:
                  </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}"
                    *ngIf="order?.customer?.contact && order?.customer?.contact[0]?.email">
                    {{order?.customer?.contact[0]?.email}}
                    <br> </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}"
                    *ngIf="order?.customer?.contact && order?.customer?.contact[0]?.phone">
                    {{"CUSTOMER_FORM_PHONE" |translate}}:
                  </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}"
                    *ngIf="order?.customer?.contact && order?.customer?.contact[0]?.phone">
                    {{order?.customer?.contact[0]?.phone}}
                    <br></span>
                </div>
                <div *ngIf="order?.customer?.shipping_address?.length" class="p-col-6 border-left">
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}" class="bold">
                    {{"CUSTOMER_FORM_SHIPP_ADDRESS"| translate}}
                    <br> </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.shipping_address[0]?.address}}
                    <br> </span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.shipping_address[0]?.city}}
                    <br></span>
                  <span [ngClass]="{'text-muted': order?.status?.invoiced}">
                    {{order?.customer?.shipping_address[0]?.country_code}}
                  </span>
                </div>
              </div>
            </div>
            <div class="p-col-4">
              <div class="form-group order-header-numbers">
                <div class="p-grid">
                  <div class="p-col-6 text-align-right">
                    <label class="formattext p-pr-2"
                      [ngClass]="{'text-muted': selectedOrder}">{{"CUSTOMER_DASHBORD_ORDER_NO" | translate}} : </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [disabled]="selectedOrder" #orderNumber>
                      <span pInplaceDisplay>
                        <button *ngIf=" order?.format "
                          style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;  "
                          pButton [label]="order?.format" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
                        <button *ngIf=" order?.format == '' && format.touched  " style="  font-weight: 600;  " pButton
                          label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
                      </span>
                      <input name="order?.format" #format="ngModel" [(ngModel)]="order.format" type="text" pInputText
                        pInplaceContent (blur)="orderNumber.deactivate()">
                    </p-inplace>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"ORDERS_LIST_ORDER_DATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #orderDate>
                      <span pInplaceDisplay>
                        <button *ngIf=" order?.date " (click)="focusThenShow('date')"
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                          [label]="order?.date | date:'dd-MM-yyyy'" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                        <button *ngIf=" order?.date == null && date.touched  " style="  font-weight: 600;  " pButton
                          label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
                      </span>
                      <p-calendar dateFormat="dd-mm-yy" yearNavigator=true yearRange='2018:2024' #orderDateToggle
                                  (ngModelChange)="notEmpty($event)" showIcon="true" [(ngModel)]="order.date" #date="ngModel"
                                  name="order?.date" [required]="true" pInplaceContent (onClose)="orderDate.deactivate()"
                                  (onClickOutside)="orderDate.deactivate()" (onSelect)="orderDate.deactivate()"></p-calendar>
                    </p-inplace>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"ORDERS_FORM_DUE_DATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #dueDate>
                      <span pInplaceDisplay>
                        <button *ngIf=" order?.due_date "
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton (click)="focusThenShow('dueDate')"
                          [label]="order?.due_date | date:'dd-MM-yyyy'" type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                        <button *ngIf=" order?.due_date == null && order_duedate.touched  "
                          style="  font-weight: 600;  " pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}'
                          type="button" (click)="focusThenShow('dueDate')"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid "> </button>
                      </span>
                      <p-calendar dateFormat="dd-mm-yy" yearNavigator=true yearRange='2018:2024' #orderDueDateToggle
                                  (ngModelChange)="notEmpty($event)" showIcon="true" [(ngModel)]="order.due_date"
                                  #order_duedate="ngModel" name="order?.due_date" [required]="true" pInplaceContent
                                  (onClose)="dueDate.deactivate()" (onClickOutside)="dueDate.deactivate()"
                                  (onSelect)="dueDate.deactivate()"></p-calendar>
                    </p-inplace>
                  </div>
                </div>
                <div *ngIf=" order?.invoice_format && order?.status?.invoiced"
                  [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-grid">
                  <div class="p-col-6 text-align-right">
                    <label class="formattext p-pr-2"
                      [ngClass]="{'text-muted': selectedOrder}">{{"NOTES_CUSTOMERS_LIST_INVOICE_NO" | translate}}:
                    </label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6">
                    <label
                      style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;  word-break: break-all    "
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">{{order?.invoice_format}}
                    </label>
                  </div>
                </div>
                <div *ngIf=" order?.status?.invoiced " class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"ORDERS_FORM_INVOICE_DATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #invoiceDates>
                      <span pInplaceDisplay>
                        <button *ngIf=" order?.invoice_date "
                                style="  color: var(--primary-color) !important; font-weight: 600; " pButton (click)="focusThenShow('invoice')"
                                [label]="order?.invoice_date | date:'dd-MM-yyyy'" type="button"
                                class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
                        <button *ngIf=" order?.invoice_date == null && invoice_date.touched  "
                          style="  font-weight: 600;  " pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}'
                          type="button" (click)="focusThenShow('invoice')"
                          class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid "> </button>
                      </span>
                      <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notEmpty($event)" showIcon="true"
                                  [(ngModel)]="order.invoice_date" #invoice_date="ngModel" name="order.invoice_date"
                                  [required]="true" pInplaceContent (onClose)="invoiceDates.deactivate()" #orderInvoiceDateToggle
                                  (onClickOutside)="invoiceDates.deactivate()" (onSelect)="invoiceDates.deactivate()"></p-calendar>
                    </p-inplace>
                  </div>
                </div>
                <div *ngIf="order.exchangeRateStauts == true" class="p-grid">
                  <div class="p-col-6  text-align-right">
                    <label class="formattext p-pr-2">{{"EXCHANGE_RATE" | translate}} :</label>
                  </div>
                  <div style="padding-left: 0%;" class="p-col-6 ">
                    <p-inplace [ngStyle]="{cursor: 'pointer'}" #exchangeRate>
                      <span pInplaceDisplay>
                        <button *ngIf=" order?.exchange_rate "
                          style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                          [label]=" order?.currency?.name " type="button"
                          class=" p-button-text p-button-plain p-ripple p-button p-component ">
                          1 {{defaultCurrency.name}}
                          <i style="margin-left: 1px; margin-right: 1px;" class="fas fa-arrow-right"></i>
                          <label style="margin-right: 2px;"> {{ order?.exchange_rate |number:'1.2-2'}} </label>
                        </button>
                      </span>
                      <p-inputNumber [min]="0" [disabled]="order?.status?.invoiced" (onFocus)="$event.target.select()"
                                     (keyup.enter)="onKeyUp($event)" (ngModelChange)="notEmpty($event);exchangeRateChange()"
                                     [(ngModel)]=" order.exchange_rate" mode="decimal" [minFractionDigits]="2"
                                     (onBlur)="exchangeRate.deactivate()" pInplaceContent></p-inputNumber>
                    </p-inplace>
                  </div>
                  <div class="p-col-12">
                    <div class="p-grid">
                      <div class="p-col-6  text-align-right">
                        <label class="formattext p-pr-2">{{defaultCurrency?.name}} Summary :</label>
                      </div>
                      <div class="p-col-6 p-mt-2  text-center">
                        <div class="p-field-checkbox">
                          <p-checkbox [(ngModel)]="defaultCurrencyStatus" binary="true"></p-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
      <div class="p-col-4 col-4-r">
        <p-card>
          <div #paymentcard class="p-grid ">
            <div class="p-col-12 ">
              <h3 aria-required="true" class="table-inplace">
                <i class="fa-menu fa fa-money-bill-alt tags"></i>
                {{"ORDERS_FORM_PAYMENTS" | translate}}
              </h3>
            </div>
            <div class="p-col-12">
              <p-table id="payment-table" [resizableColumns]="true" [rowHover]="true" [scrollable]="true"
                scrollHeight="153px" [value]="order?.payments">
                <ng-template pTemplate="header" let-columns>
                  <tr >
                    <th style="width: 50%; "> {{"QUOTES_LIST_TOTAL" | translate}} :
                      <span class="p-text-bold" style="color: #51749e;">
                        {{ order?.defaultCurrencyOrderTotalIncVAT |number:'1.2-2'}}
                        <span *ngIf="defaultCurrency?.id != order?.currency?.id"
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                      </span>
                    </th>
                    <th class="text-align-right" style="width: 50%;">
                      <div> {{"ORDERS_FORM_DUE" | translate}} :
                        <span class="p-text-bold" style="color: #51749e;">{{order.paymentDue | number:'1.2-2'}}
                          <span *ngIf="defaultCurrency?.id != order?.currency?.id"
                            style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                        </span>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                  <tr *ngIf="rowData.status=='add'"
                    [ngStyle]="{cursor: rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'?'pointer':'auto'}"
                    (click)="paymentReview(rowData,i)">
                    <td>
                      <div
                        [ngClass]="{'p-ml-5': rowData.status2=='new'  && i==order?.payments.length-1 && rowData.notes!='Total Changed'}"
                        class="p-text-bold ">
                        {{rowData.notes}}</div>
                      <div style="position: relative;"
                        [ngClass]="{'p-ml-5': rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'}"
                        class="p-text-light ">
                        <button style="position: absolute; left: -36px;top: -12px;"
                          *ngIf="rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'"
                          (click)="deletePayment(i)" pButton pRipple type="button" icon="fas fa-trash"
                          class="p-button-lg p-button-text p-button-danger "></button>
                        {{ rowData.dateP | date:'d-M-y' }}
                        <p-tag styleClass="ml-3" severity="primary">{{ rowData.type |translate}}</p-tag>
                      </div>
                    </td>
                    <td style="text-align:end;">
                      <span class="p-text-bold " style="color: #51749e;">
                        <label *ngIf="rowData.status == 'refund'">-</label>
                        {{rowData.amount | number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="rowData.status=='refund'"
                  style="background-color: #ffdada;"
                    [ngStyle]="{cursor: rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'?'pointer':'auto'}"
                    (click)="paymentReview(rowData,i)">
                    <td>
                      <div
                        [ngClass]="{'p-ml-5': rowData.status2=='new'  && i==order?.payments.length-1 && rowData.notes!='Total Changed'}"
                        class="p-text-bold ">
                        {{rowData.notes}}</div>
                      <div style="position: relative;"
                        [ngClass]="{'p-ml-5': rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'}"
                        class="p-text-light ">
                        <button style="position: absolute; left: -36px;top: -12px;"
                          *ngIf="rowData.status2=='new' && i==order?.payments.length-1 && rowData.notes!='Total Changed'"
                          (click)="deletePayment(i)" pButton pRipple type="button" icon="fas fa-trash"
                          class="p-button-lg p-button-text p-button-danger "></button>
                        {{ rowData.dateP | date:'d-M-y' }}
                        <p-tag styleClass="ml-3" severity="primary">{{ rowData.type |translate}}</p-tag>
                      </div>
                    </td>
                    <td style="text-align:end;">
                      <span class="p-text-bold " style="color: #51749e;">
                        <label *ngIf="rowData.status == 'refund'">-</label>
                        {{rowData.amount | number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="p-grid ">
                    <div class="p-col-6">
                      <p-splitButton #ua appendTo="body" [disabled]="paymentDueStatus || disablePayments || mathRound(order?.paymentDue)===0"
                        label='{{"ORDERS_FORM_PAYMENTS" | translate}}' icon="fas fa-plus" (onClick)="showAddPaymnet()"
                        [model]="itemsSave" class="ui-button-raised ui-button-info"></p-splitButton>
                    </div>
                    <div style="text-align: end !important;" class="p-col-6 ">
                      <button pButton class="p-button-danger " (click)="showrefundPayment()"
                        [disabled]="order?.payments?.length==0 || order?.orderTotalIncVAT ==  order.paymentDue"
                        icon="fas fa-minus" type="button" label='{{"ORDERS_FORM_REFUND" | translate}}'></button>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-card>
      </div>
    </div>
    <p-card>
      <p-table key="form" [value]="order?.items" styleClass="p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 20vw;">{{"QUOTES_Form_ITEM" |translate}}
            </th>
            <th style="width: 14%">{{"DASHBORD_DATE" | translate}}</th>
            <th [style.width]=" defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus? '10%':'14%'">
              {{"QUOTES_Form_QTY" | translate}}</th>
            <th [style.width]=" defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus? '15%':'15%'">
              {{"QUOTES_Form_PRICE" | translate}} </th>
            <th style="width: 10%">{{"CUSTOMER_LIST_VAT" | translate}} <i class="fas fa-percent"></i> </th>
            <th style="width: 16%;">
              <span *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus; else block">VAT Amt
              </span>
              <ng-template #block>{{"VAT_AMOUNT" | translate}}</ng-template>
            </th>
            <th style="width: 16%;">
              {{"QUOTES_LIST_TOTAL" | translate}}
            </th>
            <th
              *ngIf="order?.items.length > 1 && !order?.status?.invoiced && !unlockInvoiceEdit && checkDiscountPy==false  "
              style="width: 40px">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td style="position: relative" #productelemt id="item">
              <app-products-select (keyup.enter)="onKeyUp($event)"
                *ngIf="((isSet(item.product?.id) && item.product?.id !='') || isSet(item?.name)) && item.checkinlne==false ; else checkInlineProduct"
                (service)="serviceItem($event,i)" (inlineProduct)="inlineProduct($event,i)"
                [disabled]="order?.status?.invoiced" [(product)]="item.product" (onChange)="productChanged($event, i)">
              </app-products-select>
              <div *ngIf=" item.name == null && productTouched == true " class="alert-Item">{{"CUSTOMER_FORM_REQUIRED" |
                translate}} </div>
              <ng-template #checkInlineProduct>
                <input *ngIf="isSet(item?.name)  ; else inline" [disabled]="order?.status?.invoiced" #service="ngModel"
                       (ngModelChange)="notEmpty($event)" [(ngModel)]="item.name" type="text" pInputText>
                <ng-template #inline>
                  <div #inlineProduct class="inlineProduct">
                    <p-editor id="inlineProduct" [disabled]="order?.status?.invoiced" name="inline" readonly=true
                      [(ngModel)]="item.service" ngDefaultControl
                      [ngClass]="{'showless': item.inline_Epand==false || item.inline_Epand==null}"> </p-editor>
                    <div class="inlineProduct-btnSetings">
                      <button
                        *ngIf="(inlineProduct?.offsetHeight > 138) || (order?.status?.invoiced && inlineProduct?.offsetHeight > 100)"
                        type="button" pButton class="p-button-text  p-button-secondary"
                        (click)="expand_inLineProduct(i)" [pTooltip]="item.inline_Epand==true  ?'Compress':'Expand'"
                        tooltipPosition="bottom"
                        [icon]="item.inline_Epand==true  ? 'fas fa-compress-alt':'fas fa-expand-alt'"></button>

                      <button *ngIf="!order?.status?.invoiced" type="button" pButton class="p-button-text p-ml-1"
                              (click)="showEidtInlineProduct(i)" pTooltip='{{"CUSTOMER_LIST_EDIT" | translate}}'
                              tooltipPosition="bottom" icon="fas fa-edit"></button>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </td>
            <td style="width: 17%">
              <p-calendar dateFormat="dd-mm-yy" showIcon="true" yearNavigator=true yearRange='2018:2024'
                (keyup.enter)="onKeyUp($event)" [disabled]="order?.status?.invoiced" (ngModelChange)="notEmpty($event)"
                (change)="onChange_Item(i,null)" appendTo="body" [(ngModel)]="item.datePipe" name="item.datePipe">
              </p-calendar>
            </td>
            <td style="width: 10%">
              <div class="clearable-input">
                <input (keypress)="numberOnly($event)" (keyup.enter)="onKeyUp($event)" #item.qty
                       (change)="onChange_Item(i,'qty');notEmpty($event)" (focus)="$event.target.select()"
                       [disabled]="order?.status?.invoiced" name="item.qty" [(ngModel)]="item.qty" type="text" pInputText>
                <i *ngIf="(isSet(item.qty)&& item.qty !== 1 && item.qty !== '') && !order?.status?.invoiced"
                   (click)="eraceValue(i,'qty')" class="pi pi-times"></i>
              </div>
            </td>
            <td style="width: 15%">
              <div class="clearable-input">
                <p-inputNumber *ngIf="defaultCurrency?.id == order?.currency?.id; else qprice"
                               [disabled]="order?.status?.invoiced" [min]="0" #item.price (onFocus)="$event.target.select()"
                               (ngModelChange)="notEmpty($event)" [(ngModel)]="item.price" mode="decimal" [minFractionDigits]="2">
                </p-inputNumber>
                <ng-template #qprice>
                  <p-inputNumber #item.price [min]="0" mode="currency" [disabled]="order?.status?.invoiced"
                                 currency="{{defaultCurrency?.name}}" locale="de-DE" currencyDisplay="code"
                                 (onFocus)="$event.target.select()" (ngModelChange)="notEmpty($event)" [(ngModel)]="item.price"
                                 [minFractionDigits]="2"></p-inputNumber>
                </ng-template>
                <i *ngIf="(isSet(item.price)&& item.price !== 0 && item.price !== '') && !order?.status?.invoiced"
                   (click)="eraceValue(i,'price')" class="pi pi-times"></i>
              </div>
            </td>
            <td style="width: 15%">
              <p-dropdown (ngModelChange)="notEmpty($event)" (keyup.enter)="onKeyUp($event)"
                          (onChange)="onChange_Item(i,'vat')" [disabled]="order?.status?.invoiced" appendTo="body"
                          [options]="vatPercentage" [(ngModel)]="item.vat" optionLabel="value">
              </p-dropdown>
            </td>
            <td style="font-weight: 800;" class="number-total" [ngClass]="{'text-muted': order?.status?.invoiced}">
              <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                {{item?.default_currncey_vatAmount | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span> <label class="divide">/</label>
                {{item?.vatAmount | number:'1.2-2'}} <span
                  style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span></label>
              <ng-template #currency>
                <span style=" color: var(--primary-color) !important; font-weight: 800;">{{ item.vatAmount | number:'1.2-2'}} </span>
              </ng-template>
            </td>
            <td [ngClass]="{'text-muted': order?.status?.invoiced}">

              <div style="font-weight: 800;" class="number-total"
                *ngIf="order.exchange_rate >0 && defaultCurrencyStatus ;else currency2">
                {{item?.default_currncey_item_total | number:'1.2-2'}} <span
                  style="font-size: small;font-weight: 600 !important;">{{ defaultCurrency?.name }} </span> <label
                  class="divide">/</label>
                {{item?.item_total | number:'1.2-2'}}
                <span style="font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span>
              </div>
              <ng-template #currency2>
                <span style=" color: var(--primary-color) !important; font-weight: 800;">
                  {{ item?.item_total | number:'1.2-2'}}
                </span>
              </ng-template>
            </td>
            <td
              *ngIf="(order?.items.length > 1 && !order?.status?.invoiced && !unlockInvoiceEdit )&& checkDiscountPy==false">
              <button id='rm' (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null"
                (keyup.enter)="onKeyUp($event)" (click)="removeItem(i)" pButton pRipple type="button"
                icon="fas fa-trash" class="p-button-text  p-button-delete p-button-danger"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="!order?.status?.invoiced && !unlockInvoiceEdit && checkDiscountPy==false">
        <button (click)="addItem()" (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null"
          (keyup.enter)="onKeyUp($event)" pButton type="button" icon="pi pi-plus"
          label='{{"CUSTOMER_FORM_ADD" | translate}}' class="ui-button-raised p-mt-4"></button>
        <label class="p-ml-3">{{"QUOTES_Form_OR_PREES" | translate}} <code>ctrl+enter</code></label>
      </div>
    </p-card>
    <div class="p-grid">
      <div
        [ngClass]="order?.discount>0 && defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus && showAfterDiscount?'p-col-7' : 'p-col-8'">
        <p-card>
          <div [style.height.px]="cardHeight" class="p-grid">
            <div class="p-col-6">

              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 139px;" autoResize="false" [(ngModel)]="order.notes"
                            (ngModelChange)="notEmpty($event)" (focus)="disableVatsRatesChecks=true"
                            (blur)="disableVatsRatesChecks=false" id="message-input" rows="6" pInputTextarea></textarea>
                  <label for="message-input">{{"QUOTES_Form_MESSAGE" | translate}}</label>
                </span>
              </div>
            </div>
            <div class="p-col-6">
              <div class="form-group">
                <span class="p-float-label">
                  <textarea style="max-height: 139px;" (ngModelChange)="notEmpty($event)"
                            (focus)="disableVatsRatesChecks=true" (blur)="disableVatsRatesChecks=false"
                            [(ngModel)]="order.payment_terms" id="payment-terms-input" rows="6" autoResize="false"
                            pInputTextarea></textarea>
                  <label for="payment-terms-input">{{"CUSTOMER_FORM_PAY_TERMS" | translate}}</label>
                </span>
              </div>
            </div>
            <div style="background-color: #f8f9fa;" *ngIf="selectedOrder" class="p-col-12 ">
              <span class="formattext text-muted">{{"ORDERS_FORM_STRUCTED_COMM" | translate}}:&nbsp;</span>
              <span style="font-weight: bolder; " class="number-total"> {{order?.vcs}} </span>
            </div>
          </div>
        </p-card>
      </div>
      <div
        [ngClass]="order?.discount>0 && defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus && showAfterDiscount?'p-col-5' : 'p-col-4'">
        <p-card>
          <div #total_Height class=" order-summary text-muted">
            <div style="background-color: #f8f9fa; position: relative;" class="p-grid">
              <div [style.padding-top]="order?.discount>0 ?null: 15+'px'"
                [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-6">
                {{"DISCOUNT_EXCL_VAT" | translate}} :
              </div>
              <div class="p-col-6 text-align-right">
                <p-inplace
                  [disabled]="order?.status?.invoiced||order.beforeDiscountOrderTotalIncVAT==0 || order.paymentDue==0"
                  [ngClass]="{'text-muted': order?.status?.invoiced}" #discount>
                  <span pInplaceDisplay>
                    <span *ngIf="order?.discount>0 && selectedDiscountType?.type == 'Amount'"
                      style="font-weight: 800;" class="  number-total"> {{order?.discount |number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                        defaultCurrency?.name }} </span> <i *ngIf="paymentDueStatus==false" class="far fa-edit"></i>
                    </span>
                    <span *ngIf="order?.discount>0 && selectedDiscountType?.type == 'Percent'"
                      style="font-weight: 800; " class="  number-total "> {{order?.discount |number:'1.2-2'}} % <i
                      *ngIf=" paymentDueStatus==false" class="far fa-edit"></i></span>
                    <p-selectButton
                      [disabled]="order?.status?.invoiced ||order.beforeDiscountOrderTotalIncVAT==0 || order.paymentDue==0"
                      *ngIf="order?.discount==0" [options]="discountType" [(ngModel)]="selectedDiscountType"
                      optionLabel="label">
                    </p-selectButton>
                  </span>
                  <div class="p-inputgroup" pInplaceContent>
                    <p-inputNumber [min]="0" *ngIf="selectedDiscountType?.type == 'Amount'"
                                   [(ngModel)]="order.discount" (onFocus)="$event.target.select()"
                                   (ngModelChange)="totalDiscountChange()" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
                    <p-inputNumber [min]="0" *ngIf="selectedDiscountType?.type == 'Percent'" prefix="%"
                                   (ngModelChange)="totalDiscountChange()" [(ngModel)]="order.discount" max="100"></p-inputNumber>
                    <button type="button"
                      [disabled]="(this.order?.discount > this.order.beforeDiscountOrderTotalIncVAT && order.discountType == 'Amount') || disablePayments == true"
                      (click)="discount.deactivate()" pButton pRipple icon="pi pi-check"></button>
                    <button type="button" (click)="discount.deactivate();clearDiscount()" pButton pRipple
                            icon="pi pi-times" class="p-button-danger"></button>
                  </div>
                </p-inplace>
              </div>
              <div *ngIf="order?.discount>0" class="p-col-12">
                <div class="p-field-checkbox">
                  <p-checkbox [(ngModel)]="showAfterDiscount" binary="true" inputId="binary"></p-checkbox>
                  <label for="binary">{{"SHOW_BF_DS" | translate}}</label>
                </div>
              </div>
            </div>
            <div class="p-grid p-mt-3">
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-5">{{"QUOTES_Form_TOTAL_NET" |
                translate}}:
              </div>
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-7 text-align-right number-total">
                <span *ngIf="order?.discount==0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    {{order?.defaultCurrencyOrderTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      defaultCurrency?.name }} </span> <label class="divide">/</label>
                    {{order?.orderTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      order?.currency?.name }} </span></label>
                  <ng-template #currency> {{order?.orderTotal | number:'1.2-2'}}
                  </ng-template>
                </span>
                <span *ngIf="order?.discount>0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotal != order?.orderTotal"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{order?.defaultCurrencyBeforeDiscountOrderTotal | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                        defaultCurrency?.name }} </span></del>
                    {{order?.defaultCurrencyOrderTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                      defaultCurrency?.name }} </span> <label class="divide">/</label>
                    {{order?.orderTotal | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotal != order?.orderTotal"
                      style="font-weight: 600;" class="p-text-secondary"> {{order?.beforeDiscountOrderTotal |number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }}</span></del>
                  </label>
                  <ng-template #currency>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotal != order?.orderTotal"
                      style="font-weight: 600;" class="p-text-secondary"> {{order?.beforeDiscountOrderTotal | number:'1.2-2'}} </del>
                    {{order?.orderTotal | number:'1.2-2'}}
                  </ng-template>
                </span>
              </div>
            </div>
            <div class="p-grid ">
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-5">{{"QUOTES_Form_TOTAL_VAT" |translate}}:
              </div>
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-7 text-align-right number-total">
                <span *ngIf="order?.discount==0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    {{order?.defaultCurrencyOrderTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span> <label class="divide">/</label>
                    {{order?.orderTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span></label>
                  <ng-template #currency> {{order?.orderTotalVAT | number:'1.2-2'}}
                  </ng-template>
                </span>
                <span *ngIf="order?.discount>0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    <del
                      *ngIf="showAfterDiscount && order?.defaultCurrencyBeforeDiscountOrderTotalVAT != order?.defaultCurrencyOrderTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{order?.defaultCurrencyBeforeDiscountOrderTotalVAT | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span></del>
                    {{order?.defaultCurrencyOrderTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;"> {{defaultCurrency?.name}} </span><label class="divide">/</label>
                    {{order?.orderTotalVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotalVAT != order?.orderTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{order?.beforeDiscountOrderTotalVAT |
                      number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span></del>
                  </label>
                  <ng-template #currency>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotalVAT != order?.orderTotalVAT"
                      style="font-weight: 600;" class="p-text-secondary"> {{order?.beforeDiscountOrderTotalVAT |
                      number:'1.2-2'}} </del>
                    {{order?.orderTotalVAT | number:'1.2-2'}}
                  </ng-template>
                </span>
              </div>
            </div>
            <ng-container *ngIf="order?.paidVats?.length>1">
              <div *ngFor="let item of order.paidVats">
                <div *ngIf="item.afterDiscount >0" style="background-color: #f8f9fa;" class=" p-grid">
                  <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-5">VAT {{item.name}} % :
                  </div>
                  <div [ngClass]="{'text-muted': order?.status?.invoiced}"
                    class="p-col-7 text-align-right number-total">
                    <span *ngIf="order?.discount==0">
                      <label
                        *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                        {{item?.beforeDiscount|number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                        <label class="divide">/</label> {{item?.beforeDiscount * order?.exchange_rate |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{ order?.currency?.name }} </span></label>
                      <ng-template #currency>{{item?.beforeDiscount | number:'1.2-2'}}
                      </ng-template>
                    </span>
                    <span *ngIf="order?.discount>0">
                      <label
                        *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                        <del *ngIf="showAfterDiscount " style="font-weight: 600;"
                             class="p-text-secondary">{{item?.beforeDiscount / order?.exchange_rate | number:'1.2-2'}} <span style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span>
                        </del>
                        {{item?.afterDiscount / order?.exchange_rate|number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span> <label class="divide">/</label>

                        {{item?.afterDiscount |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{ order?.currency?.name }} </span>
                        <del *ngIf="showAfterDiscount " style="font-weight: 600;"
                             class="p-text-secondary">{{item?.beforeDiscount | number:'1.2-2'}} <span
                            style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{
                            order?.currency?.name }} </span>
                        </del>
                      </label>
                      <ng-template #currency>
                        <del *ngIf="showAfterDiscount " style="font-weight: 600;"
                             class="p-text-secondary">{{item?.beforeDiscount| number:'1.2-2'}}
                        </del>
                        {{item?.afterDiscount |number:'1.2-2'}} <span
                          style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span>
                      </ng-template>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div [ngClass]="{'p-p-0': order?.status?.cc == false}" class="p-grid">
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-5">
                {{"QUOTES_Form_TOTAL_INC_VAT" |translate}}:
              </div>
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-7 text-align-right number-total">
                <span *ngIf="order?.discount==0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    {{order?.defaultCurrencyOrderTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span> <label class="divide">/</label>
                    {{order?.orderTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name }} </span></label>
                  <ng-template #currency> {{order?.orderTotalIncVAT | number:'1.2-2'}}
                  </ng-template>
                </span>
                <span *ngIf="order?.discount>0">
                  <label *ngIf="defaultCurrency?.id != order?.currency?.id &&  defaultCurrencyStatus;else currency">
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotalIncVAT != order?.orderTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{order?.defaultCurrencyBeforeDiscontTotalInc | number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }}</span></del>
                    {{order?.defaultCurrencyOrderTotalIncVAT | number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span> <label class="divide">/</label>
                    {{order?.orderTotalIncVAT |number:'1.2-2'}} <span
                      style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name}}</span>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotalIncVAT != order?.orderTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary">{{
                      order?.beforeDiscountOrderTotalIncVAT |number:'1.2-2'}} <span
                        style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{order?.currency?.name}}</span>
                    </del>
                  </label>
                  <ng-template #currency>
                    <del *ngIf="showAfterDiscount && order?.beforeDiscountOrderTotalIncVAT != order?.orderTotalIncVAT"
                      style="font-weight: 600;" class="p-text-secondary">
                      {{order?.beforeDiscountOrderTotalIncVAT |number:'1.2-2'}}
                    </del>
                    {{order?.orderTotalIncVAT |number:'1.2-2'}}
                  </ng-template>
                </span>
              </div>
            </div>
            <hr *ngIf="order?.customer?.company_name != 'Cash & Carry'" class="divider">
            <div *ngIf="order?.customer?.company_name != 'Cash & Carry'" style="background-color: #f8f9fa; "
              class="p-grid p-mt-3 ">
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-8">{{"QUOTES_Form_CUSTOMER_BALANCE"
                | translate}} :</div>
              <div [ngClass]="{'text-muted': order?.status?.invoiced}" class="p-col-4 text-align-right number-total">
                <label class="" >
                  {{order?.customerDue|number:'1.2-2'}} <span
                    style="color: var(--primary-color);font-size: small;font-weight: 600 !important;">{{defaultCurrency?.name }} </span></label>
     
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>

<p-dialog modal="true"  [header]="PaymentLabel" (onShow)="outsideStatus(true)"
          (onHide)="outsideStatus(false)" [resizable]="false" [draggable]="false" [(visible)]="showPaymentDialog"
          [style]="{width: '40vw'}">
  <div style="margin-top: 2px; " class="p-grid">
    <div class="p-col-6">
      <div class="form-text text-muted">{{"DASHBORD_DATE" | translate}}</div>
      <p-calendar dateFormat="dd-mm-yy" appendTo="body" [inputStyle]="{'width':'18.5vw'}" class="calendar"
        [(ngModel)]="payment.dateP" #payment_date="ngModel" name="payment.dateP"
        placeholder='{{"DASHBORD_DATE" | translate}}'></p-calendar>
    </div>
    <div class="p-col-6">
      <div class="form-text text-muted">{{"ORDERS_FORM_PAYMENT_TYPES" | translate}}</div>
      <p-dropdown appendTo="body" [(ngModel)]="payment.type" [options]="paymentTypes" optionValue="type"
                  optionLabel="label"></p-dropdown>
    </div>
    <div class="p-col-12">
      <div class="form-text text-muted "> {{"ORDERS_FORM_AMOUNT" | translate}}
        <label *ngIf=" (payment?.amount == null || amountt.invalid) &&  amountt.touched " class="alert-invalida">
          {{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
      </div>
      <p-inputNumber [min]="0" [inputStyle]="{'width': '38.2vw' }" id="Amount-input" #amountt="ngModel"
                     [(ngModel)]="payment.amount" mode="decimal" [disabled]="disablePayments" [minFractionDigits]="2">
      </p-inputNumber>
    </div>
    <div class="p-col-12">
      <div class="form-text text-muted">{{"ORDERS_FORM_REFERENCE" | translate}}
      </div>
      <input [(ngModel)]="payment.notes" id="Reference -input" type="text" pInputText>
    </div>
  </div>
  <p-footer>
    <button *ngIf="PaymentLabel==translate.instant('ADD_PAYMENT')" type="button" pButton (click)="addPayment()"
            label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="PaymentLabel==translate.instant('UPDATED_PAYMENT')" type="button" pButton (click)="UpdatePayment()"
            label='{{"CUSTOMER_LIST_UPDATE" | translate}}'></button>
    <button *ngIf="PaymentLabel==translate.instant('REFUND_PAYMENT')" type="button" pButton (click)="refundPayment()"
            label='{{"ORDERS_FORM_REFUND" | translate}}'></button>
    <button type="button" pButton (click)="showPaymentDialog=false" icon="pi pi-times"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" [resizable]="false" appendTo="body"
          [draggable]="false" class="p-shadow-1" header='{{"ORDERS_FORM_SEND_BY_EMAIL" | translate}}'
          [(visible)]="sendEmailDialog" [style]="{width: '65vw'}">
  <div class="p-field p-grid">
    <label for="Recipients" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0 ">{{"QUOTES_Form_RECIPIENTS" |
      translate}}</label>
    <div class="p-col">
      <p-chips (onRemove)="onRemovEmail()" addOnBlur='true' (keydown)="addEmailOnSpace($event)" id="Recipients"
               name="send_to_mails" [(ngModel)]="sendEmail.send_to_mails">
        <ng-template let-item pTemplate="item">
          <span>{{item}}</span>
        </ng-template>
      </p-chips>
      <div class="p-mt-2">
        <p-checkbox [binary]="userEmail" [(ngModel)]="userEmail" (onChange)="sendCopyToMyself()"
                    label='{{"QUOTES_Form_SEND_COPY_MYSELF" | translate}}'></p-checkbox>
      </div>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Subject" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"QUOTES_Form_SUBJECT" | translate}}</label>
    <div class="p-col">
      <input [(ngModel)]="sendEmail.subject" id="Subject" type="text" pInputText>
    </div>
  </div>
  <div class="p-field p-grid">
    <label for="Message" class="p-col-12 p-mb-2 p-md-2 formattext p-mt-0">{{"MESSAGE" | translate}} </label>
    <div #col class="p-col">
      <p-editor id="Message" name="message" [(ngModel)]="sendEmail.message" ngDefaultControl
        [style]="{'height':'180px'}"></p-editor>
      <div class="mt-2">
        <p-checkbox label='{{"QUOTES_Form_SAVE_DEFAULT_MESSAGE" | translate}}' (click)="SaveMessage()"></p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-send" (click)="sendEmails()" label='{{"QUOTES_Form_SEND" | translate}}'
            icon="far fa-paper-plane"></button>
    <button type="button" pButton icon="pi pi-times" (click)="sendEmailDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" [resizable]="false"
          [draggable]="false" class="p-shadow-1" header='{{"DASHBORD_INVOICE" | translate}}'
          [(visible)]="convertInvoiceDialogueQ" [style]="{width: '290px'}">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="  p-grid">
        <div class="p-col-6 p-mt-1 text-align-right">
          <label class="formattext p-pr-2">Invoice Num : </label>
        </div>
        <div class="p-col-6">
          <p-inplace id="invoice_Format" #invoice_Format>
            <span pInplaceDisplay>
              <button *ngIf=" order?.invoice_format"
                      style=" pointer-events:none; color: var(--primary-color) !important; font-weight: 600;     " pButton
                      [label]="order?.invoice_format" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "> </button>
              <button *ngIf=" order?.invoice_format == '' && invoice_format.touched  " style="  font-weight: 600;  "
                      pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
            </span>
            <input name="order?.invoice_format" [(ngModel)]="order.invoice_format" #invoice_format="ngModel"
                   type="text" pInputText pInplaceContent (blur)="invoice_Format.deactivate()">
          </p-inplace>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class=" p-grid">
        <div class="p-col-6 p-mt-1 text-align-right">
          <label class="formattext p-pr-2">Invoice Date : </label>
        </div>
        <div class="p-col-6 ">
          <p-inplace id="invoice_date" [ngStyle]="{cursor: 'pointer'}" #invoice_date>
            <span pInplaceDisplay>
              <button *ngIf=" order?.invoice_date " style="  color: var(--primary-color) !important; font-weight: 600; "
                      pButton [label]="order?.invoice_date | date:'dd-MM-yyyy'" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component "></button>
              <button *ngIf=" order?.invoice_date == null && invoice_Date.touched  " style="  font-weight: 600;  "
                      pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid"> </button>
            </span>
            <p-calendar dateFormat="dd-mm-yy" appendTo="body" showIcon="true" [(ngModel)]="order.invoice_date"
              #invoice_Date="ngModel" name="order?.invoice_date" [required]="true" pInplaceContent
              (onClose)="invoice_date.deactivate()" (onClickOutside)="invoice_date.deactivate()"
              (onSelect)="invoice_date.deactivate()" ngDefaultControl></p-calendar>
          </p-inplace>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="convertToInvoice()"
      label='{{"ORDERS_FORM_CONVERT" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="convertInvoiceDialogueQ=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" [resizable]="false"
          [draggable]="false" class="p-shadow-1" header='{{"TO_DRAFT" | translate}}' [(visible)]="convertDraftDialogueQ"
          [style]="{width: '27vw'}">
  <div *ngIf="order?.customer?.company_name != 'Cash & Carry' || !isSet(draftCustomer)" class="p-text-justify ">
    Are you sure want to convert <span style="color: var(--primary-color)"> {{order?.format}}</span> To Draft ?
  </div>
  <div *ngIf="order?.customer?.company_name == 'Cash & Carry'" class="p-text-justify ">
    <app-customers-select [(customer)]="draftCustomer" [(append)]="appendCustomer"></app-customers-select>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="convertToDraft()"
      label='{{"ORDERS_FORM_CONVERT" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="convertDraftDialogueQ=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" appendTo="body"
          [resizable]="false" [draggable]="false" class="p-shadow-1" header='{{"UNLOCK_INVO" | translate}}'
          [(visible)]="unlockInvoiceDialogueQ" [style]="{width: '27vw'}">
  <div class="p-text-justify "> {{"CONF_UNLOCK" | translate}} <span style="color: var(--primary-color)">
      {{order?.format}}</span> {{"TO_EDIT" | translate}} ?
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="unlockInvoice()"
      label='{{"UNLOCK" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="unlockInvoiceDialogueQ=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)"
          [resizable]="false" [draggable]="false" class="p-shadow-1" [header]="order?.format" [(visible)]="pdfViewer"
          [style]="{width: '60vw'}">
  <div style=" position: relative;" [style.height.px]="pdfHeight">
    <pdf-viewer [src]="order?.viewUrl" (after-load-complete)="afterLoadComplete($event)"
                (on-progress)="pdfLoader($event)" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
                [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"></pdf-viewer>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="fas fa-print" (click)="printPdf()"
      label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()"
      label='{{"DOWNLOAD" | translate}}'></button>
      <button type="button" pButton icon="pi pi-envelope" (click)="sendEmailDialog=true"
      label=' {{"QUOTES_LIST_SEND_TO_EMAIL" | translate}} '></button>

    <button type="button" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"
             (click)="sendToWhatsapp()"
            label=' {{"Send to Whatsapp" | translate}}' ></button>
    <button type="button" pButton icon="pi pi-times" (click)="pdfViewer=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="outsideStatus(false)" [resizable]="false"
          [draggable]="false" appendTo="body" class="p-shadow-1" header='{{"EDIT_SERVICE" | translate}}'
          [(visible)]="showAddInlineProduct" [style]="{width: '60vw'}">
  <p-editor id="Message" name="inline" [(ngModel)]="inlineProductValue" ngDefaultControl [style]="{'height':'200px'}">
  </p-editor>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="editInlineProduct()"
      label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="showAddInlineProduct=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outsideStatus(true)" (onHide)="cancelAddExchangeRate()" class="p-shadow-1"
          appendTo="body" [resizable]="false" [draggable]="false" header='{{"EXCHANGE_RATE" | translate}}'
          [(visible)]="showAddExchangeRate" [style]="{width: '15vw'}">
  <p-inputNumber [min]="0" [inputStyle]="{'width':13+'vw'}" (onFocus)="$event.target.select()"
                 [(ngModel)]="addExchangeRate" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="confrimAddExchangeRate()"
      label=' {{"CUSTOMER_FORM_ADD" | translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="showAddExchangeRate = false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>
