<app-loading *ngIf="loading"></app-loading>
<p-toast  key="list" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-12">
  <p-card>
      
        <div  [ngClass]="{'invalid': Tax_name.invalid && (Tax_name.dirty || Tax_name.touched) }" class="form-group">
              <span class="p-float-label ">
                    <input required [(ngModel)]="Tax.name"  #Tax_name="ngModel"  id="Name-input" type="text" pInputText>
                    <label for="Name-input">Name&nbsp;
                      <label *ngIf="Tax.name == '' || Tax.name == null &&  Tax_name.touched ; else Tax_name_err" class="alert-invalid">({{"CUSTOMER_FORM_REQUIRED" | translate}})</label>
                      <ng-template #Tax_name_err>
                        <label *ngIf="Tax_name.invalid && Tax_name.dirty " class="alert-invalid"> Not Valid</label>
                                              </ng-template>
                    </label>

                   
                </span>
              </div>
       
              <div style="margin-top: 30px;" [ngClass]="{'invalid': Tax_value.invalid && (Tax_value.dirty || Tax_value.touched) }" class="form-group">
                <span class="p-float-label ">
                      <input required [(ngModel)]="Tax.value"  #Tax_value="ngModel"  id="Name-input" type="text" pInputText>
                      <label for="Name-input">Value&nbsp;

                        <label *ngIf=" Tax.value == null &&  Tax_value.touched ; else Tax_value_err" class="alert-invalid">({{"CUSTOMER_FORM_REQUIRED" | translate}})</label>
  
                        <ng-template #Tax_value_err>
                          <label *ngIf="Tax_value.invalid && Tax_value.dirty " class="alert-invalid"> Not Valid</label>
                                                </ng-template>
                      </label>
             
                  </span>
                </div>
       
      <!-- <div *ngFor="let field of Tax.ExtraFields, let i = index" class="form-group">
        <div class="p-inputgroup">
            <span class="p-float-label">
                <input [(ngModel)]="field.value" id="maininfo-{{i}}-input" type="text" pInputText>
                <label for="maininfo-{{i}}-input">{{ field.name }}</label>
            </span>
          <button (click)="Tax.ExtraFields.splice(i, 1)" pButton type="button" icon="pi pi-times" class="ui-button-danger"></button>
        </div>
      </div>

      <a (click)="addCustomFieldSection = 'mainInfo';showAddCustomFieldDialog = true" href="javascript:">Add custom field </a> -->

      </p-card>

 

    </div>

  
  

  </div>






<!-- <p-dialog appendTo="body" header="Custom field" [(visible)]="showAddCustomFieldDialog" [style]="{width: '22vw'}">

  <div class="p-grid form-group">
    <div class="p-col-6">
      <span  class="p-float-label">
        <input style="width: 250px;" [(ngModel)]="customFieldName" id="customfield-input" type="text" pInputText>
        <label for="customfield-input"> field name</label>
      </span>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="addCustomField()" label="Add"></button>
    <button type="button" pButton icon="pi pi-times" (click)="showAddCustomFieldDialog=false" label="Cancel" class="ui-button-secondary"></button>
  </p-footer>
</p-dialog> -->