import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderComponent } from './table-header/table-header.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { SqxButtonComponent } from './sqx-button/sqx-button.component';
import { SqxTelInputComponent } from './sqx-tel-input/sqx-tel-input.component';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { CustomersSelectComponent } from './customers-select/customers-select.component';
import { ProductsSelectComponent } from './products-select/products-select.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchComponent } from './Search/Search.component';
import { suppliersSelectComponent } from './supplier-select/supplier-select.component';
import { OrderSelectComponent } from './order-select/order-select.component';
import { PurchaseSelectComponent } from './purchase-select/purchase-select.component';
import { ToastModule } from 'primeng/toast';
import { ShipmentMethodsComponent } from './shipment-methods/shipment-methods.component';
import { TranslateModule } from '@ngx-translate/core';
import { OrdersComponent } from '../orders/orders/orders.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CheckboxModule } from 'primeng/checkbox';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { ChipsModule } from 'primeng/chips';
import { QuoteFormComponent } from '../quotes/quote-form/quote-form.component';
import { PurchaseOrdersFormComponent } from '../PurchaseOrders/PurchaseOrders-form/PurchaseOrders-form.component';
import { TooltipModule } from 'primeng/tooltip';
import { ProductsFormComponent } from '../products/products-form/products-form.component';
import { FileUploadModule } from 'primeng/fileupload';
import { CustomerFormComponent } from '../customers/customer-form/customer-form.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ExpenseNoteFormComponent } from '../expense-note//expense-note-form/expense-note-form.component';
import { payersSelectComponent } from './payer-select/payer-select.component';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { ExpenseFormComponent } from '../expense/expense-form/expense-form.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { creditnotesCustomerComponent } from '../CreditNotes-customers/creditnotesCustomers-form/creditnotescustomers.component';
import { ChipModule } from 'primeng/chip';
import {ListboxModule} from 'primeng/listbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TabViewModule} from 'primeng/tabview';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CustomerDashboardComponent } from '../customers/customer-dashboard/customer-dashboard.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    TableHeaderComponent,
    SidebarComponent,
    ImageUploadComponent,
    SqxButtonComponent,
    SqxTelInputComponent,
    LoadingComponent, ProductsFormComponent,
    CustomersSelectComponent, CustomerFormComponent,
    ProductsSelectComponent,
    SearchComponent, suppliersSelectComponent, ProductsSelectComponent, OrderSelectComponent
    , ProductsSelectComponent, PurchaseSelectComponent, ShipmentMethodsComponent, OrdersComponent, QuoteFormComponent, ExpenseNoteFormComponent
    , PurchaseOrdersFormComponent, payersSelectComponent, ExpenseFormComponent,CustomerDashboardComponent
    , creditnotesCustomerComponent],
  imports: [
    CommonModule,
    OverlayPanelModule,
    MenuModule,
    ToolbarModule,
    ButtonModule,
    FormsModule,
    PdfViewerModule,
    DropdownModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    SidebarModule,
    ProgressSpinnerModule,
    RippleModule,
    AutoCompleteModule, FileUploadModule, InputNumberModule,
    ToastModule,SelectButtonModule,
    TranslateModule, CheckboxModule, InplaceModule, CalendarModule, CardModule, TableModule, SplitButtonModule, TagModule
    , InputTextareaModule, EditorModule, ChipsModule, TooltipModule, InputMaskModule, InputSwitchModule, ImageToDataUrlModule
    , ChipModule,ListboxModule,TabViewModule
  ],
  exports: [
    TableHeaderComponent,
    SidebarComponent,
    ImageUploadComponent,
    SqxButtonComponent, CustomerFormComponent,
    SqxTelInputComponent,
    LoadingComponent, ProductsFormComponent,
    CustomersSelectComponent,CustomerDashboardComponent,
    ProductsSelectComponent, SearchComponent, suppliersSelectComponent, ProductsSelectComponent, OrderSelectComponent
    , PurchaseSelectComponent, ShipmentMethodsComponent, OrdersComponent, QuoteFormComponent, PurchaseOrdersFormComponent,
    ExpenseNoteFormComponent, payersSelectComponent, ExpenseFormComponent, creditnotesCustomerComponent
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'de-DE'
}]
})
export class SharedModule {
}
