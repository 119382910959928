<app-loading *ngIf="loading"></app-loading>
<div class="p-grid">

    <div class="p-col-9">
        <p-card>

            <div class="p-grid p-pt-1">

                <div class="p-col-3 ">
                    <span class="p-float-label">
                        <p-calendar yearNavigator=true yearRange='2018:2024'
                            [inputStyle]="{'width':'14vw','font-size':'medium','font-weight': '600'}" id="to_date"
                            dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true"
                            [(ngModel)]="form_date" [required]="true">
                        </p-calendar>
                        <label class="formattext" for="to_date">{{"FROM_DATE" | translate}}</label>
                    </span>
                </div>
                <div class="p-col-3 ">
                    <span class="p-float-label">
                        <p-calendar id="to_date" yearNavigator=true yearRange='2018:2024'
                            [inputStyle]="{'width':'14vw','font-size':'medium','font-weight': '600'}"
                            dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true"
                            [(ngModel)]="to_date" [required]="true">
                        </p-calendar>
                        <label class="formattext" for="to_date">{{"TO_DATE" | translate}}</label>
                    </span>
                </div>

                <div class="p-col-6 text-align-left">
                    <button pButton style="font-size: medium;" (click)="searchSales()" class="p-mr-2 "
                        icon="pi pi-search " type="button" label=' {{"SEARCH_SALES" | translate}}'></button>

                    <button pButton (click)="menu.toggle($event)" style="font-size: medium;"
                        class="p-button-secondary p-ml-4" icon="pi pi-upload" type="button"
                        label=' {{"EXPORT_SALES" | translate}}'></button>
                    <!-- (click)="downloadSales()" -->
                    <p-tieredMenu id="downloadtypes" #menu [model]="downloadtypes" [popup]="true"></p-tieredMenu>

                </div>
            </div>
        </p-card>
        <p-card>
            <p-table appendTo="body" styleClass="p-datatable-striped  p-datatable-responsive-demo" [paginator]="true"
                [rows]="10" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [value]="invoices"
                [globalFilterFields]="['invoice_format','.customer?.company_name','date','total','total','total_vat','status?.status.value']">


                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Internal Reference
                        </th>
                        <th>{{"ORDERS_FORM_INVOICE_DATE" | translate}}


                        </th>
                        <th>Vendor

                        </th>

                        <th>{{"QUOTES_Form_TOTAL_NET" | translate}}

                        </th>
                        <th>{{"QUOTES_Form_TOTAL_VAT" | translate}}


                        </th>
                        <th>{{"QUOTES_Form_TOTAL_INC_VAT" | translate}}

                        </th>
                        <th>{{"QUOTES_LIST_STATUS" | translate}}

                        </th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                    <tr>
                        <td>
                            <span class="responsive-th">{{"ORDERS_LIST_ORDER_NO" | translate}}</span>
                            <a href="javascript:;" (click)="showInvoices(rowData)">{{ rowData.internal_reference }}</a>
                            <!-- <button pButton type="button" icon="pi pi-cog" iconPos="left"  class="p-button-raised p-button-rounded p-button-secondary"></button> -->

                        </td>

                        <td>
                            <span class="responsive-th">{{"ORDERS_LIST_ORDER_DATE" | translate}}</span>


                            <span *ngIf="rowData.invoice_date else notSetDate">{{ rowData.invoice_date |
                                date:'dd-MM-yyyy' }}</span>
                            <ng-template #notSetDate>ــ</ng-template>
                        </td>
                        <td>
                            <span class="responsive-th">{{"QUOTES_LIST_CUSTOMER" | translate}}</span>
                            {{ rowData.vendor_name }}
                        </td>
                        <td>
                            <span class="responsive-th">{{"QUOTES_Form_TOTAL_NET" | translate}}</span>
                            {{ rowData.sub_total | number:'1.2-2' }} <span
                                style="color: var(--primary-color);font-size: small;">{{default_currency }}</span>
                        </td>
                        <td>
                            <span class="responsive-th">{{"QUOTES_Form_TOTAL_VAT" | translate}}:</span>
                            <span *ngIf="rowData.total_vat>0" style="cursor: pointer !important;"
                                [style.color]="rowData.total_vat>0?'var(--primary-color)':null"
                                [pTooltip]="rowData?.items_vats?.length?null:show_vat(rowData.items_vats)"
                                [escape]="false" tooltipPosition="bottom"> {{ rowData.total_vat
                                |number:'1.2-2' }} <span
                                    style="color: var(--primary-color);font-size: small;">{{default_currency }}</span>
                            </span>
                            <span *ngIf="rowData.total_vat==0">ــ</span>

                        </td>
                        <td>
                            <span class="responsive-th">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}}</span>
                            {{ rowData.total | number:'1.2-2' }} <span
                                style="color: var(--primary-color);font-size: small;">{{default_currency }}</span>

                        </td>
                        <td>
                            <span class="responsive-th">{{"QUOTES_LIST_STATUS" | translate}}</span>


                            <span [class]="rowData.status?.status?.paid_color ">{{
                                rowData.status?.status?.paid_status}}</span>

                        </td>


                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">{{"NO_INCOICE" | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>


    <p-card class="p-col-3">
        <p-tabView id="summary">
            <p-tabPanel header='{{"Total summary" | translate}}'>

                <div #total_Height class=" order-summary text-muted">
                    <div class="p-grid ">
                        <div class="p-col-6">{{"QUOTES_Form_TOTAL_NET" | translate}} :</div>
                        <div class="p-col-6 text-align-right number-total">

                            <span
                                *ngIf="summaries?.total?.sub_total;else total_vat">{{summaries?.total?.sub_total|number:'1.2-2'}}</span>
                            <ng-template #sub_total>0</ng-template>
                        </div>
                    </div>

                    <div style="background-color: #f8f9fa;" class="p-grid">
                        <div class="p-col-6">{{"QUOTES_Form_TOTAL_VAT" | translate}} :</div>
                        <div class="p-col-6 text-align-right number-total">

                            <span
                                *ngIf="summaries?.total?.total_vat;else total_vat">{{summaries?.total?.total_vat|number:'1.2-2'}}</span>
                            <ng-template #total_vat>0</ng-template>
                        </div>
                    </div>
                    <div *ngFor="let item of summaries?.total?.vat">
                        <div style="background-color: #f8f9fa;" class=" p-grid">
                            <div class="p-col-6">{{"CUSTOMER_LIST_VAT" | translate}} {{item.vat.name}}% :</div>
                            <div class="p-col-6 text-align-right number-total">
                                {{ item.vat.value |number:'1.2-2'}}

                            </div>
                        </div>



                    </div>

                    <div class="p-grid">
                        <div class="p-col-6">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}} :</div>
                        <div class="p-col-6 text-align-right number-total">
                            <span *ngIf="summaries?.total?.total;else total">{{summaries?.total?.total|
                                number:'1.2-2'}}</span>
                            <ng-template #total>0</ng-template>
                        </div>
                        <div class="p-col-6">{{"CURRANECY" | translate}} :</div>
                        <div class="p-col-6 text-align-right number-total">
                            {{default_currency}}
                        </div>
                    </div>

                    <hr class="divider">
                    <div style="background-color: #f8f9fa; " class="p-grid p-mt-3 ">
                        <div class="p-col-8">{{"NO_OF_SALES" | translate}} :</div>
                        <div class="p-col-4 text-align-right number-total">
                            <label *ngIf="summaries?.total?.count;else count"> {{summaries?.total?.count}}</label>
                            <ng-template #count>0</ng-template>
                        </div>
                        <div *ngIf=" summaries?.cc" class="p-col-8">{{"Invoices sales" | translate}} :</div>
                        <div *ngIf="summaries?.cc" class="p-col-4 text-align-right number-total">
                            <label> {{summaries?.invoices?.count}}</label>

                        </div>
                        <div *ngIf="summaries?.cc" class="p-col-8">{{"C&C Sales" | translate}} :</div>
                        <div *ngIf="summaries?.cc" class="p-col-4 text-align-right number-total">
                            <label> {{summaries?.cc?.count}}</label>

                        </div>
                    </div>

                </div>
            </p-tabPanel>
        </p-tabView>

    </p-card>


</div>