
<p-autoComplete #search_el id="search" [(ngModel)]="selecteditem"
[suggestions]="filteredProducts"
(completeMethod)="search($event)"
field="{{field_name}}"
(onClear)="onCClear()"
(click)="onSelectSearch()"
placeholder='{{"SEARCH"| translate}}'
>

</p-autoComplete>

<i *ngIf="progress==false" class="pi pi-search"></i>




  

