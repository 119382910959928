import { isSet } from '../modules/core/util';

export class Region {
  id: string;
  name: string;
  company:number;
  
  public constructor(params?: Region) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Region) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Region(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Region[]) {
    const objects: Region[] = [];
    for (const obj of objectsToClone) {
      objects.push(Region.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
return{
  name:this.name

}
  }
}
