<!-- <div  class="moon"><img src="assets/pages/asset-moon.svg" alt="mirage-layout"></div> -->

<div class="login-body layout-light">
  <div class="login-content ">

    <div class="login-panel">

      <div class="exception-panel-content ">
            <i style="color:#c82333; font-size: 5rem;" class="fas fa-exclamation-triangle"></i>
        <h1 class="mb-4 display-4" style="color: #555b62; ">Page Not Found
        </h1>
        <div class="panel text-align-left">
        <div class="seperator "></div>
        <p class="p-content pt-2">Requested resource is not available right now. Please try again later.</p>

        <button *ngIf="authGuardStuats==true" [routerLink]="['/']" routerLinkActive="router-link-active"   type="button" label="Go To DashBoard"  class="p-button p-component mt-2"><span class="p-button-label">Go To DashBoard</span></button>
      </div>
    </div>
    </div>

    <div class="desert"></div>
  </div>
</div>


