<app-loading *ngIf="loading"></app-loading>
<p-toast key="form" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-6">
    <p-card>
      <h3>
        <i class="fas fa-id-badge tags"></i> {{"CUSTOMER_FORM_MAIN_INFO" | translate}}
      </h3>

      <div class="form-group">
        <div class="p-inputgroup">
          <span class="p-float-label p-inputgroup">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.vat" (ngModelChange)="normalize_VAT($event)" (input)="getDataFromVat()"
              id="vat-input" type="text" pInputText>
            <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}</label>
          </span>
          <button pButton (click)="getDataFromVat()" type="button" [disabled]="vatBtnDisabled"
            [icon]="vatBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
            class="ui-button-warn"></button>
        </div>
      </div>


      <div [ngClass]="{'invalid': Supplier_name.invalid && (Supplier_name.dirty || Supplier_name.touched) }"
        class="form-group pt-4">
        <span class="p-float-label ">
          <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.name" #Supplier_name="ngModel" id="Name-input"
            type="text" pInputText>
          <label for="Name-input">{{"CUSTOMER_FORM_NAME" | translate}}&nbsp;

            <label *ngIf="supplier.name == '' || supplier.name == null &&  Supplier_name.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>


          </label>

        </span>
      </div>




    </p-card>



    <p-card>
      <h3>
        <i class="fas fa-money-check-alt tags"></i>
        {{"DASHBORD_PAYMENT_DETAILS" | translate}}
      </h3>
      <div class="form-group p-grid">
        <div class="p-col-6">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.IBAN" id="IBAN" type="text"
              pInputText>
            <label for="IBAN">IBAN</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.BIC" id="bic-input" type="text" pInputText>
            <label for="bic-input">BIC</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label">
 
              <input   (ngModelChange)="notempty($event)" [(ngModel)]="supplier.bank_account_number"
              id="account-number-input" pInputText>
            <label for="account-number-input">{{"CUSTOMER_FORM_ACCOUNT_NO" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label" #credits>
            <!-- <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.credit_limit" id="credit-limit-input"
              type="number" pInputText> -->
            <p-inputNumber [min]="0" [inputStyle]="{'width':credits.offsetWidth+'px'}"
              (ngModelChange)="notempty($event)" [minFractionDigits]="2" [(ngModel)]="supplier.credit_limit"
              id="credit-input" mode="decimal"></p-inputNumber>
            <label for="credit-limit-input">{{"DASHBORD_CREDIT_LIMITS" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="supplier.bank_name" id="Bank-Name-input" type="text"
              pInputText>
            <label for="Bank-Name-input">{{"SUPPLIERS_FORM_BANK_NAME" | translate}}</label>
          </span>
        </div>

        <div class="p-col-6">
          <span class="p-float-label">
            <input [(ngModel)]="supplier.language" id="language-input" type="text" pInputText>
            <label for="language-input">{{"CUSTOMER_FORM_LANG" | translate}}</label>
          </span>
        </div>

        <!-- <div class="p-col-6">
            <p-dropdown [(ngModel)]="supplier.Bank.bank_account_number" [options]="accountManagers" placeholder="Account manager" optionLabel="name" [showClear]="true"></p-dropdown>
          </div> -->
      </div>

      <div class="form-group p-grid">
        <div class="p-col-12">
          <span class="p-float-label">
            <textarea (ngModelChange)="notempty($event)" [(ngModel)]="supplier.payment_terms" id="payment-terms-input"
              pInputTextarea [autoResize]="true"></textarea>
            <label for="payment-terms-input">{{"CUSTOMER_FORM_PAY_TERMS" | translate}}</label>
          </span>
        </div>
      </div>



    </p-card>
  </div>

  <div class="p-col-6">
    <p-card>
      <div class="p-grid">
        <div class="p-col-6">
          <h3 class="p-m-0">
            <i class="fas fa-map-marker-alt tags"></i>
            {{"CUSTOMER_FORM_ADDRESS" | translate}}



          </h3>
        </div>
        <div class="p-col-6" style="text-align: right">
          <p-button (onClick)="showAddAdrees()" icon="pi pi-plus" label='{{"CUSTOMER_FORM_ADD_ADDRESS" | translate}}'>
          </p-button>
        </div>
      </div>
      <div *ngFor="let address of supplier.address; let i = index" class="p-grid p-mt-2 border-bottom">
        <div class="p-col-12">
          <h4>
            - <span class="tags">{{ address.address }}, {{ address.zip }}, {{ address.city }}, {{ address.country_code
              }}</span>
          </h4>
        </div>
        <div class="p-col-12 text-align-right">
          <button type="button" pButton class="p-button-text p-ml-3" (click)="showEidtAdrees(i)"
            label='{{"CUSTOMER_LIST_EDIT" | translate}}' icon="fas fa-edit"></button>
          <button type="button" pButton (click)="rmAdrees(i)" class="p-button-text  p-button-danger "
            label='{{"CUSTOMER_FORM_REMOVE" | translate}}' icon="fas fa-trash"></button>

        </div>
      </div>
    </p-card>

    <p-card>
      <div class="p-grid">
        <div class="p-col-6">
          <h3 class="p-m-0">
            <i class="fas fa-id-card tags"></i>
            {{"CUSTOMER_FORM_CONTACT" | translate}}
          </h3>
        </div>
        <div class="p-col-6" style="text-align: right">
          <p-button (onClick)="showAddContact()" icon="pi pi-plus" label='{{"CUSTOMER_FORM_ADD_CONTACT" | translate}}'>
          </p-button>
        </div>
      </div>
      <div *ngFor="let contact of supplier.contacts ; let i = index" class="p-grid p-mt-2 border-bottom">
        <div class="p-col-6">
          <strong>{{ contact.name }}</strong>
        </div>
        <div class="p-col-6">
          <ul>
            <li *ngIf="contact.position"><i class="tags">{{"CUSTOMER_FORM_POSITION"| translate}} : </i> {{
              contact.position }}</li>
            <li *ngIf="contact.email"><i class="tags">{{"LOGIN_EMAIL"| translate}} : </i> {{ contact.email }}</li>
            <li *ngIf="contact.phone"><i class="tags"> {{"CUSTOMER_FORM_PHONE"| translate}} : </i> {{ contact.phone }}
            </li>
            <li *ngIf="contact.mobile_no"> <i class="tags">{{"Mobile Number"| translate}} : </i> {{ contact.mobile_no }}</li>
            <li *ngIf="contact.note"><i class="tags">{{"CUSTOMER_FORM_NOTES"| translate}} : </i> {{ contact.note }}</li>

          </ul>


        </div>
        <div class="p-col-6 ">
        </div>

        <div class="p-col-12 text-align-right">
          <button type="button" pButton class="p-button-text p-ml-3" (click)="showEidtContact(i)"
            label='{{"CUSTOMER_LIST_EDIT" | translate}}' icon="fas fa-edit"></button>
          <button type="button" pButton (click)="rmContact(i)" class="p-button-text  p-button-danger "
            label='{{"CUSTOMER_FORM_REMOVE" | translate}}' icon="fas fa-trash"></button>

        </div>
      </div>
    </p-card>
    <p-card>
      <div class="p-grid">
        <div class="p-col-6">
          <h3 class="p-m-0">

            <i class="fas fa-plus tags"></i>


            {{"EXTRA_FIELDS" | translate}}
          </h3>
        </div>
        <div class="p-col-6" style="text-align: right">
          <button icon="pi pi-plus" (click)="addCustomFieldSection = 'mainInfo';showAddCustomFieldDialog = true" pButton
            label='{{"ADD_FILED" | translate}}' type="button"></button>
        </div>
        <div *ngIf="supplier?.extra_fields?.length" class="p-col-12">
          <div *ngFor="let field of supplier.extra_fields, let i = index" class="form-group">
            <div class="p-inputgroup">
              <span class="p-float-label">
                <input (ngModelChange)="notempty($event)" [(ngModel)]="field.value" id="maininfo-{{i}}-input"
                  type="text" pInputText>
                <label for="maininfo-{{i}}-input">Extra Field - {{ field.name }}


                </label>
              </span>
              <button (click)="supplier.extra_fields.splice(i, 1);notempty($event)" pButton type="button"
                icon="pi pi-times" class="ui-button-danger"></button>
            </div>
          </div>


        </div>
      </div>
    </p-card>
  </div>


</div>





<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" [header]="ContactDialogHeader" [(visible)]="showContactDialog"
  [style]="{width: '30vw'}">
  <div class="p-grid ">
    <div class="p-col-12">
      <span [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched) }" class="p-float-label">
        <input required email (ngModelChange)="notempty($event)" [(ngModel)]="contact.email" #email="ngModel"
          name="email" id="email-input" type="text" pInputText>
        <label for="email-input">{{"LOGIN_EMAIL"| translate}}&nbsp;
          <label *ngIf="contact.email == '' || contact.email == null &&  email.touched ; else anotheral"
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

          <ng-template #anotheral>
            <label *ngIf="email.invalid && email.dirty " class="alert-invalid">{{"CUSTOMER_FORM_NOT_VALID"|
              translate}}</label>
          </ng-template>
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input required minlength="2" (keydown.space)="preventDefault($event,contactname)"
          (ngModelChange)="notempty($event)" [(ngModel)]="contact.name" #contactname="ngModel" name="name"
          id="contact-name-input" type="text" pInputText>
        <label for="contact-name-input">{{"CUSTOMER_LIST_CONTACT_NAME"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (keydown.space)="preventDefault($event,position)" (ngModelChange)="notempty($event)"
          [(ngModel)]="contact.position" #position="ngModel" name="position" id="position-input" type="text" pInputText>
        <label for="position-input">{{"CUSTOMER_FORM_POSITION"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.phone" name="phone" id="phone-input" type="text"
          pInputText>
        <label for="phone-input">{{"CUSTOMER_FORM_PHONE"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.mobile_no" name="phone" id="phone-input"
          type="text" pInputText>
        <label for="mobile-input">Mobile Number</label>
      </span>
    </div>
    <div class="p-col-12">
      <span class="p-float-label">
        <textarea (ngModelChange)="notempty($event)" [(ngModel)]="contact.note" id="notes-input" pInputTextarea
          rows="4"></textarea>
        <label for="notes-input">{{"CUSTOMER_FORM_NOTES"| translate}}</label>
      </span>
    </div>
  </div>

  <p-footer>
    <button *ngIf="ContactDialogHeader ==translate.instant('CUSTOMER_FORM_ADD_CONTACT') " type="button" pButton icon="pi pi-check" (click)="addContact()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="ContactDialogHeader ==translate.instant('EDIT_CONTACT') " type="button" pButton icon="pi pi-check"
      (click)="eidtContact()" label='{{"CUSTOMER_LIST_EDIT" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" (click)="showContactDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" header="Add Extra Field" [(visible)]="showAddCustomFieldDialog"
  [style]="{width: '22vw'}">

  <div class="p-grid form-group">
    <div class="p-col-12">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" [(ngModel)]="customFieldName" id="customfield-input" type="text"
          pInputText>
        <label for="customfield-input">{{"FILED_NAME" | translate}}</label>
      </span>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="addCustomField()"
      label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="showAddCustomFieldDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" appendTo="body" [header]="addressDialogHeader" [(visible)]="showAddressDialog"
  [style]="{width: '40vw'}">


  <div class="p-grid">
    <div class="p-col-6">
      <span class="p-float-label">

        <input required minlength="2" #addressInput (ngModelChange)="notempty($event)"
          [(ngModel)]="shipping_address.address" #address="ngModel" 
          (keydown.space)="$event.preventDefault();" id="address-input" type="text" pInputText>

          <label  [style.top]="shipping_address.address?.length > 2  ?'-27%':null" for="address-input">{{"CUSTOMER_FORM_ADDRESS" | translate}}
            <label *ngIf="shipping_address.address == '' || shipping_address.address == null &&  address.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
          </label>
      </span>
    </div>
    <div class="p-col-6">
      <div class="form-group">
        <span class="p-float-label">
          <p-inputMask (ngModelChange)="notempty($event)" [(ngModel)]="shipping_address.zip" #zip="ngModel"
            id="zip-input" mask="99999"></p-inputMask>
          <label for="zip-input">{{"CUSTOMER_FORM_ZIP" | translate}}&nbsp;
            <label *ngIf="shipping_address.zip == '' || shipping_address.zip == null &&  zip.touched  "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
          </label>
        </span>
      </div>
    </div>
    <div class="p-col-6">
      <div class="form-group">
        <span class="p-float-label">
          <input required minlength="2" (keydown.space)="$event.preventDefault();" (ngModelChange)="notempty($event)"
            [(ngModel)]="shipping_address.city" #city="ngModel" id="city-input" type="text" pInputText>
          <label for="city-input">{{"CUSTOMER_FORM_CITY" | translate}}&nbsp;

            <label *ngIf="shipping_address.city == '' || shipping_address.city == null &&  city.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

          </label>


        </span>
      </div>
    </div>
    <div class="p-col-6">
      <div [ngClass]="{'invalid': country_code.invalid && (country_code.dirty || country_code.touched) }"
        class="form-group">
        <span class="p-float-label">
          <!-- <input required minlength="2" (keydown.space)="$event.preventDefault();" (ngModelChange)="notempty($event)"
            [(ngModel)]="shipping_address.country_code" #country_code="ngModel" id="country-input" type="text"
            pInputText> -->
          <label for="country-input">{{"CUSTOMER_FORM_COUNTRY" | translate}}
            <label
              *ngIf="shipping_address.country_code == '' || shipping_address.country_code == null &&  country_code.touched ; else shipping_addresscountry_codeCountry_err "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
            <ng-template #shipping_addresscountry_codeCountry_err>
              <label *ngIf="country_code.invalid && country_code.dirty " class="alert-invalid">
                {{"CUSTOMER_FORM_NOT_VALID" | translate}}</label>
            </ng-template>
          </label>
          <p-dropdown appendTo="body" id="country-input" [options]="countries" [filter]="true" #country_code="ngModel"
            (keydown.space)="preventDefault($event,country_code)" optionValue='name'
            [(ngModel)]="shipping_address.country_code" placeholder='{{"CUSTOMER_FORM_COUNTRY" | translate}}' optionLabel="name">
          </p-dropdown>

        </span>
      </div>
    </div>
  </div>


  <p-footer>
    <button *ngIf="addressDialogHeader == translate.instant('CUSTOMER_FORM_ADD_ADDRESS')" type="button" pButton icon="pi pi-check"
      (click)="adShipping_address()" label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
    <button *ngIf="addressDialogHeader == translate.instant('EDIT_ADDRESS')" type="button" pButton icon="pi pi-check"
      (click)="eidtAdrees()" label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" (click)="showAddressDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>


