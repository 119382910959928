import { isSet } from '../../modules/core/util';

export class Payment {
  amount?: number;
  date: any;
  dateP:any
  type: string;
  notes: string;
  status:string;
  status2:string
  expense:number

  public constructor(params?: Payment) {
    Object.assign(this, params);
  }
  public static cloneObject(objectToClone: Payment) {
    if (!isSet(objectToClone)) {
      return new Payment();
    }
    return new Payment(objectToClone);
  }
 

public static cloneManyObjects(objectsToClone: Payment[]) {
  const objects: Payment[] = [];
  for (const obj of objectsToClone) {
    objects.push(Payment.cloneObject(obj));
  }

  return objects;
}

}
