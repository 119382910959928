import { OrderItem } from '../orders/orderitem';
import { OrderStatus } from '../orders/orderstatus';
import { isSet } from '../../modules/core/util';
import * as _ from 'lodash';
import { Contact } from '../contact';
import { Address } from '../address';
import { Supplier } from '../supplier';
import { ShipmentMethods } from './shipment-methods';
import { Payment } from '../orders/Payment';
import { Attachment } from '../Attachment';
import { Customer } from '../customer';
import { Currency } from '../currency';

export class PurchaseOrders {
  id: string;
  vendor: Supplier;
  company_name: string
  customer: Customer;
  internal_reference: string;
  internal_invoice: string
  vendor_invoice?: string
  vendor_order?: string
  date: Date;
  items: OrderItem[];
  payments: Payment[];
  total: number;
  sub_total: number;
  total_vat: number;
  balance: number;
  total_discount: number;
  discount: any;
  discount_type: any;
  status: OrderStatus;
  notes: string;
  payment_terms: string;
  linktoview: string
  complete_date: string;
  delivered_date: Date;
  delivered_on: Date;
  invoice_date: Date;
  invoice_file: any;
  invoice_number: number;
  invoice_attachment: any
  invoiced: boolean;
  shipping_method: ShipmentMethods;
  static_items: any;
  updated_at: Date;
  type: string
  download_url: string
  view_url: string
  loadprint: boolean
  currency: Currency
  viewImg: string
  attachment: any
  convert: boolean
  delivered_status:any
  exchange_rate:number
  exchange_rateStauts:boolean
  paid_vats: Array<{
		name?: number;
		beforediscont?: number;
		afterdiscont: number;
	}>
  whatsappUrl:string
  get orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'totalNet') || 0
  }
  get beforeDiscont_orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_totalNet') || 0
  }

  get orderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.orderTotal + this.orderTotalVAT|| 0
  }
  get beforeDiscont_orderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'beforediscont_total') || 0
  }

  get discont_Percentage() {
    if (this.discount_type == 'Percent') {

      return (this.discount / 100)
    }
    const x = (this.discount / _.sumBy(this.items, 'beforediscont_totalNet'))
    //*this.exchange_rate
    return x
  }

  get orderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatAmount') || 0
  }

  get beforediscontorderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_vatAmount') || 0
  }

  get default_currncey_orderTotalVAT(){
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_vatAmount') || 0
  }
  get default_currncey_orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_totalNet') || 0
  }
  get default_currncey_orderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.default_currncey_orderTotal + this.default_currncey_orderTotalVAT|| 0
  }

  get default_currncey_beforeDiscont_TotalInc() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'default_currncey_beforediscont_total') || 0
  }
  get  default_currncey_beforeDiscont_orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_totalNet') || 0
  }
  get  default_currncey_beforediscontorderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_vatAmount') || 0
  }

  public constructor(params?: PurchaseOrders) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: PurchaseOrders) {
    if (!isSet(objectToClone)) {
      return new PurchaseOrders();
    }


    const obj = new PurchaseOrders(objectToClone);
    obj.vendor = Supplier.cloneObject(objectToClone.vendor);
    if (isSet(objectToClone.items)) {
      obj.items = OrderItem.cloneManyObjects(objectToClone.items);
    }

    obj.status = OrderStatus.cloneObject(objectToClone.status);
    return obj;
  }

  public static cloneManyObjects(objectsToClone: PurchaseOrders[]) {
    const objects: PurchaseOrders[] = [];
    for (const obj of objectsToClone) {
      objects.push(PurchaseOrders.cloneObject(obj));
    }

    return objects;
  }

  public toDB(date, discount,invoiceDate) {

    return {

      internal_reference: this.internal_reference,
      internal_invoice: this.internal_invoice,
      vendor_invoice: this.vendor_invoice,
      vendor_order: this.vendor_order,
      vendor: this.vendor.id,
      date: date,
      invoice_date:invoiceDate,
      invoiced: true,
      invoice_number: 1,
      notes: this.notes,
      currency: this.currency?.id,
      exchange_rate:this.exchange_rate,
      discount: discount ?? 0,
      total_discount: 0,
       items: _.map(this.items, item => {
        return {
          product: item.product?.id,
          company: item.product?.company,
          name: item?.name,
          qty: item?.qty,
          qtyType: item?.qtyType,
          price: item?.price,
          vat: item?.vat?.id,
          date: item?.date,
          price_type: item?.price_type,
          service: item?.service,
          received_qty: Number(item.received_qty),
          warehouse: item.warehouse?.id
        }
      }),
      payments : this.payments,
      billing_email: this.contact()?.email,
      payment_terms: this.payment_terms,
      shipping_address:
      {
        address: this.shipping_address()?.address,
        zip: this.shipping_address()?.zip,
        city: this.shipping_address()?.city,
        country_code: this.shipping_address()?.country_code
      },
      phone: this.contact()?.phone
    }

  }
  public toDBQuote(date) {

    return {

      internal_reference: this.internal_reference,
      internal_invoice: this.internal_invoice,
      vendor_invoice: this.vendor_invoice,
      vendor_order: this.vendor_order,
      vendor: this.vendor.id,
      date: date,
      invoiced: true,
      invoice_number: 1,
      notes: this.notes,
      currency: this.currency?.id,
      invoice_date:this.invoice_date,


      items: _.map(this.items, item => {
        return {
          date: item.date,
          product: item.product.id,
          company: item.product.company,
          name: item.product.name,
          qty: item.qty,
          warehouse: item.warehouse?.id,
          service: item?.service,



        }
      }),
      billing_email: this.contact()?.email,
      payment_terms: this.payment_terms,
      status: {
        completed: false,
        draft: true,
        cancelled: false,
        paid: false
      },


    }

  }
  contact() {
    let contactt: Contact
    this.vendor?.contacts?.map(item => {
      contactt = item
    })
    return contactt
  }
  shipping_address() {
    let shipping_address: Address
    this.vendor.address?.map(item => {
      shipping_address = item
    })
    return shipping_address

  }
}
