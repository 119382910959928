<app-loading *ngIf="loading"></app-loading>
<p-toast key="form" position="top-right"></p-toast>

<div class="p-grid">
    <div class="p-col-12">
        <h3>
          <i class="fas fa-id-badge tags"></i>
          {{"USER_FORM_MAIN_INFO"| translate}}
        </h3>

        <div class="p-grid" >

          <div class=" p-col-6" >
            <p-card >
            <h4>
              {{"USER_IMAGE"| translate}}
            </h4>
            
            <div style=" margin:5px; text-align: center;">
             
                <img class="center" style="width: 150px; height: 150px;border-radius: 50%;margin-bottom: 5px;" src="{{user.image}}">
                <p-fileUpload  #fubauto mode="basic" name="myfile[]"  accept="image/*" maxFileSize="1000000" (onUpload)="onBasicUploadAuto($event)" [auto]="true" chooseLabel="CHANGE_IMAGE"></p-fileUpload>
  
             
              
            
            </div>

          </p-card>
          </div>
          
     
          <div class=" p-col-6">
            <p-card>
              <h4>
                {{"USER_PERSONAL_INFORMATIONS"| translate}}
              </h4>
              <div class="p-inputgroup" style="margin-top: 20px;">
                <span class="p-float-label p-inputgroup">
                  <input [(ngModel)]="user.username" (ngModelChange)="normalize_user($event)" (input)="getDataFromUser()"
                    id="vat-input" type="text" pInputText>
                  <label for="vat-input">{{"USERNAME_FORM_USER"| translate}}</label>
                </span>
                <button pButton (click)="getDataFromUser()" type="button" [disabled]="usernameBtnDisabled"
                  [icon]="usernameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                  class="ui-button-warn"></button>
              </div>
              <div class="p-inputgroup" style="margin-top: 20px;">
                <span class="p-float-label p-inputgroup">
                  <input [(ngModel)]="user.first_name" (ngModelChange)="normalize_user($event)" (input)="getDataFromUser()"
                    id="vat-input" type="text" pInputText>
                  <label for="vat-input">{{"FIRSTNAME_FORM_USER"| translate}}</label>
                </span>
                <button pButton (click)="getDataFromUser()" type="button" [disabled]="userFirstNameBtnDisabled"
                  [icon]="userFirstNameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                  class="ui-button-warn"></button>
              </div>
              <div class="p-inputgroup" style="margin-top: 20px;">
                <span class="p-float-label p-inputgroup">
                  <input [(ngModel)]="user.last_name" (ngModelChange)="normalize_user($event)" (input)="getDataFromUser()"
                    id="vat-input" type="text" pInputText>
                  <label for="vat-input">{{"LASTNAME_FORM_USER"| translate}}</label>
                </span>
                <button pButton (click)="getDataFromUser()" type="button" [disabled]="userLastNameBtnDisabled"
                  [icon]="userLastNameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                  class="ui-button-warn"></button>
              </div>
          </p-card>

          </div>
  
        
        <div class=" p-col-6">
          <p-card>
            <h4>
              {{"USER_ROLE"| translate}}
            </h4>
            <div class="p-inputgroup" style="margin-top: 20px;">
              <span class="p-float-label p-inputgroup">
                <input [(ngModel)]="user.role" (ngModelChange)="normalize_user($event)" (input)="getdataFromRoles()"
                  id="vat-input" type="text" pInputText>
                <label for="vat-input">{{"ROLE_FORM_USER"| translate}}</label>
              </span>
              <button pButton (click)="getdataFromRoles()" type="button" [disabled]="userRoleNameBtnDisabled"
                [icon]="userRoleNameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                class="ui-button-warn"></button>
            </div>
         </p-card>
         </div>
         
        <div class=" p-col-6">
         <p-card>
          <h4>
            {{"USER_CONTACT_INFO"| translate}}
          </h4>

          <div class="p-inputgroup" style="margin-top: 20px;">
            <span class="p-float-label p-inputgroup">
              <input [(ngModel)]="user.email" (ngModelChange)="normalize_user($event)" (input)="getDataFromUser()"
                id="vat-input" type="text" pInputText>
              <label for="vat-input">{{"EMAIL_FORM_USER"| translate}}</label>
            </span>
            <button pButton (click)="getDataFromUser()" type="button" [disabled]="userEmailNameBtnDisabled"
              [icon]="userEmailNameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
              class="ui-button-warn"></button>
          </div>

          <div class="p-inputgroup" style="margin-top: 20px;">
            <span class="p-float-label p-inputgroup">
              <input [(ngModel)]="user.mobile" (ngModelChange)="normalize_user($event)" (input)="getDataFromUser()"
                id="vat-input" type="text" pInputText>
              <label for="vat-input">{{"MOBILE_FORM_USER"| translate}}</label>
            </span>
            <button pButton (click)="getDataFromUser()" type="button" [disabled]="userMobileNameBtnDisabled"
              [icon]="userMobileNameBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
              class="ui-button-warn"></button>
          </div>
        </p-card>
        </div>
      </div>
  

  </div>