/* eslint-disable no-mixed-spaces-and-tabs */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { Order } from '../../../models/orders/order';
import { Customer } from '../../../models/customer';
import { Product } from '../../../models/product';
import { OrderItem } from '../../../models/orders/orderitem';
import { OrdersService } from '../orders.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { Payment } from 'src/app/models/orders/Payment';
import { DatePipe } from '@angular/common';
import { SendEmail } from 'src/app/models/SendEmail';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Vat_Product } from 'src/app/models/vat-product';
import * as printJS from 'print-js';
import { InputNumber } from 'primeng/inputnumber';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from 'src/app/models/currency';
import * as _ from 'lodash';
import { OrderStatus } from 'src/app/models/orders/orderstatus';
import * as moment from 'moment';
import { Calendar } from "primeng/calendar";

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
	providers: [MessageService]
})
export class OrdersComponent extends BaseComponent implements OnInit, AfterViewInit {
	order: Order;
	products: Product[] = [];
	productTouched = false;
	customerOrder = false;
	showPaymentDialog = false;
	pdfViewer = false;
	paymentTypes = [];
	customerBalance = 0;
	totalPaymentRefund = 0;
	cardHeight = 0;
	paymentDueFromBalance = false;
	paymentDueStatus = true;
	totalPayment = 0;
	PaymentLabel: string;
	paymentUpdateIndex = 0;
	orderTotal = 0;
	paymentCardHeight = 0;
	newPayments: Payment[] = [];
	sendEmailDialog = false;
	sendEmail = new SendEmail();
	userEmail: string;
	convertInvoiceDialogueQ = false;
	convertDraftDialogueQ: boolean;
	unlockInvoiceDialogueQ: boolean;
	quoteToOrder: boolean;
	payment = new Payment();
	selectedOrder = false;
	heightCardCustomer: boolean;
	updateStatus = false;
	itemsSave = [];
	inlineProductValue: string;
	indexInlineProduct = 0;
	pdfHeight = 0;
	showAddInlineProduct: boolean;
	appendCustomer = 'body';
	draftCustomer = new Customer();
	editCustomerIcon = 'far fa-edit';
	disablePayments: boolean;
	showAfterDiscount = true;
	defaultCurrencyStatus = false;
	fixedAmount: boolean;
	selectedDiscountType = { type: null };
	defaultCurrency = new Currency();
	confirmExchangeRate = false;
	addExchangeRate = 1;
	showAddExchangeRate = false;
	vatPercentage: Vat_Product[] = [];
	disableVatsRatesChecks = false;
	unlockInvoiceEdit = false;
	checkDiscountPy = false;
	discountType = [
		{ type: 'Percent', label: this.translate.instant('PERCENT') },
		{ type: 'Amount', label: this.translate.instant('ORDERS_FORM_AMOUNT') }
	];

	@ViewChild('amountt') amount: any;
	@ViewChild('invoice_format') invoice_format: any;
	@ViewChild('invoice_Date') invoice_Date: any;
	@ViewChildren('item.qty') qtyS: QueryList<ElementRef>;
	@ViewChildren('item.price') priceS: QueryList<InputNumber>;
	@ViewChild('paymentcard') paymentCard: ElementRef;
	@ViewChild('total_Height') totalHeight: ElementRef;
	@ViewChild('orderDueDateToggle') orderDueDateToggle: Calendar;
	@ViewChild('orderDateToggle') orderDateToggle: Calendar;
	@ViewChild('orderInvoiceDateToggle') orderInvoiceDateToggle: Calendar;

	constructor(private orderService: OrdersService,
		private sharedService: SharedService,
		public messageService: MessageService,
		private datePipe: DatePipe,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService,
		private router: Router) { super(); }

	ngOnInit(): void {
		this.paymentTypes = [
			{ type: 'Cash', label: this.translate.instant('Cash') },
			{ type: 'Bank Cards', label: this.translate.instant('Bank Cards') },
			{ type: 'Wire Transfer', label: this.translate.instant('Wire Transfer') },
			{ type: 'Check', label: this.translate.instant('Check') },
			{ type: 'Voucher', label: this.translate.instant('Voucher') },
			{ type: 'Other', label: this.translate.instant('other') }];
		this.getVats();
		this.getNextOrderFormat();
		this.subscribeOnFormSaved();
		this.subscribeOnSendEmail();
		this.initOrder();
		this.GetqouteTOorder();
		this.SaveForm();
		this.updateEmitter();
		this.getSavedOrder();
		this.subscribeOnOrderSelected();
		this.getorderTotalChange();
		this.subscribeOnDuplicateOrder();
		this.convertToInvoiceEmitter();
		this.convertToDraftEmitter();
		this.unLockInvoiceEmitter();
		this.createCreditNoteEmitter();
		this.printOrderViewer();
		this.clearAll();
		this.currencyChanged();
		this.showOrderFromDash();
		this.beforeUnload();
		window.addEventListener('keydown', (event: KeyboardEvent) => {
			if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
				if (!isSet(this.order.status.invoiced) || !this.order.status.invoiced) {
					this.addItem();
				}
			}
		});
		this.itemsSave = [
			{
				label: this.translate.instant('FULL_CASH_PAID'), command: () => {
					if (this.order.paymentDue === 0) {
						this.paymentDueStatus = true;
						return;
					}
					this.updateStatus = true;
					this.payment = new Payment();
					this.payment.amount = this.order.paymentDue;
					this.totalPayment = this.totalPayment + this.payment.amount;
					this.payment.type = 'Cash';
					this.payment.status = 'add';
					this.payment.status2 = 'new';
				
					this.order.paymentDue = 0;
					this.paymentDueStatus = true;
					this.payment.dateP = new Date();
					this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
					this.order.payments.push(this.payment);
					this.newPayments.push(this.payment);
					this.payment = new Payment();
					if (this.order.discount > 0) {
						this.checkDiscountPy = true;
					}
					this.sharedService.update_form.next(true);
				},
			},
			{
				label: this.translate.instant('FULL_BANK_PAID'), command: () => {
					if (this.order.paymentDue === 0) {
						this.paymentDueStatus = true;
						return;
					}
					this.updateStatus = true;
					this.payment = new Payment();
					this.payment.amount = this.order.paymentDue;
					this.totalPayment = this.totalPayment + this.payment.amount;
					this.order.paymentDue = 0;
					this.paymentDueStatus = true;
					this.payment.type = 'Bank Cards';
					this.payment.status = 'add';
					this.payment.status2 = 'new';
				
					this.payment.dateP = new Date();
					this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
					this.order.payments.push(this.payment);
					this.newPayments.push(this.payment);
					this.payment = new Payment();
					if (this.order.discount > 0) {
						this.checkDiscountPy = true;
					}
					this.sharedService.update_form.next(true);
				}
			}
		];
	}

	ngAfterViewInit() {
		this.loading = true;
		const interval = setInterval(() => {
			const qtyS: ElementRef[] = this.qtyS.toArray();
			for (let index = 0; index < this.order.items.length; index++) {
				qtyS[index].nativeElement.value = this.spaceNumber(this.order.items[index].qty);
				if (index === this.order.items.length - 1) {
					clearInterval(interval);
				}
			}
			this.loading = false;
		}, 1000);
		this.paymentCardHeight = this.paymentCard?.nativeElement?.offsetHeight;
		this.cardHeight = this.totalHeight?.nativeElement?.offsetHeight;
		this.cdr.detectChanges();
	}

	private getVats() {
		const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
			if (!isSet(vats)) {
				return;
			}
			this.vatPercentage = Vat_Product.cloneManyObjects(vats.results);
			let zeroVatIndex = 0;
			this.vatPercentage.map((vat, index) => {
				if (vat.value == 0) {
					vat.value = '0';
					zeroVatIndex = index;
				}
			});
			const insert = (arr, index, newItem) => [
				...arr.slice(0, index),
				newItem,
				...arr.slice(index)
			];
			this.vatPercentage = insert(this.vatPercentage, 0, this.vatPercentage[zeroVatIndex]);
			this.vatPercentage.splice(zeroVatIndex + 1, 1);
			this.vatPercentage.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
			subscription.unsubscribe();
		}, error => {
			console.log(error);
			subscription.unsubscribe();
		});
	}

	beforeUnload() {
		window.addEventListener('beforeunload', (event) => {
			if (this.updateStatus == true) {
				event.returnValue = 'Unsaved modifications';
				return event;
			}
		});
	}

	addItem() {
		const item = OrderItem.cloneObject(null);
		item.datePipe = new Date();
		item.product = new Product();
		item.product.id = 'new';
		item.qty = 1;
		item.price = 0;
		item.vat = new Vat_Product();
		item.vat.value = 0;
		item.checkinlne = false;
		item.exchange_rate = this.order?.exchange_rate;
		this.order.items.push(item);
	}

	removeItem(index: number) {
		if (this.order.discount > 0) {
			const discount = this.order.discount;
			setTimeout(() => {
				this.order.discount = 0;
				let discountPercentage = this.order.discountPercentage;
				this.order.items.map(item => {
					item.discont_Percentage = discountPercentage;
				});
				this.order.discount = discount;
				discountPercentage = this.order.discountPercentage;
				this.order.items.map(item => {
					item.discont_Percentage = discountPercentage;
				});
			}, 100);
		}
		this.updateStatus = true;
		this.order.items.splice(index, 1);
		this.getorderTotalChange();
		if (this.order?.defaultCurrencyOrderTotalIncVAT < this.totalPayment - this.totalPaymentRefund) {
			this.disablePayments = true;
			return this.messageService.add({
				key: 'form', severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				life: 4000,
				detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
			});
		} else {
			this.disablePayments = false;
		}
	}

	async productChanged(event, index) {
		await this.selectProduct(event, index);
		await this.discountPercentage();
		this.getorderTotalChange();
		this.exchangeRateChange();
		await this.getVatsBeforeAfterDiscount();
	}

	selectProduct(event, index) {
		return new Promise((resolve) => {
			this.order.paidVats = [];
			setTimeout(() => {
				const product = event as Product;
				this.order.items[index].price = product?.sell_price || 0;
				if (isSet(product?.vat?.id)) {
					this.order.items[index].vat = product?.vat;
				}
				this.order.items[index].product = new Product();
				this.order.items[index].datePipe = new Date();
				this.order.items[index].product = product;
				this.order.items[index].name = product?.name;
				this.order.items[index].itemVaild = true;
				this.productTouched = false;
				this.updateStatus = true;
				this.paymentDueStatus = false;
				this.onChange_Item(index, null);
				resolve(this.order.items[index]);
			}, 200);
		});
	}

	serviceItem(event, index) {
		const service = event;
		this.productTouched = false;
		this.updateStatus = true;
		this.order.items[index].checkinlne = true;
		this.order.items[index].product = new Product();
		this.order.items[index].product.id = '';
		this.order.items[index].service = service;
		this.order.items[index].exchange_rate = this.order?.exchange_rate;
		this.order.items[index].name = null;
		this.order.items[index].itemVaild = true;
	}

	inlineProduct(event, index) {
		const inlineProduct = event;
		this.productTouched = false;
		this.updateStatus = true;
		this.order.items[index].product = new Product();
		this.order.items[index].product.id = '';
		this.order.items[index].product.name = inlineProduct;
		this.order.items[index].exchange_rate = this.order?.exchange_rate;
		this.order.items[index].name = inlineProduct;
		this.order.items[index].itemVaild = true;
	}

	addOrder(): void {
		if (this.order?.payments.length) {
			this.order.payments.map(payment => {
				if (payment.type === 'Wire Transfer') {
					payment.type = 'wt';
				}
				payment.amount = Number(payment.amount);
				payment.date = this.datePipe.transform(payment.dateP, 'yyyy-MM-dd');
			});
			
			if (this.mathRound(this.order?.defaultCurrencyOrderTotalIncVAT) < this.mathRound(this.totalPayment - this.totalPaymentRefund)) {
				this.disablePayments = true;
				return this.messageService.add({
					key: 'form', severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					life: 4000,
					detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
				});
			} else {
				this.disablePayments = false;
			}
		}
		this.removeSpaceNumber();
		let noError: boolean;
		if (isSet(this.order?.items)) {
			this.order?.items.map(item => {
				item.price = Number(item.price);
				item.qty = Number(item.qty);
				item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd');
				if (isSet(item.vat.value)) {
					item.vat.value = Number(item.vat.value);
				} else {
					item.vat.value = 0;
				}
				if ((!isSet(this.order.customer)) || (!isSet(this.order.format))
					|| (!isSet(this.order.date)) || (!isSet(this.order.due_date)) || !item.itemVaild) {
					this.productTouched = true;
					this.customerOrder = true;
					return noError = false;
				} else {
					return noError = true;
				}
			});
		}
		if (this.order?.customer?.company_name === 'Cash & Carry') {
			this.order.customer = null;
			this.editCustomerIcon = null;
		}
		if (noError === false) {
			this.messageService.add({
				key: 'form', severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				detail: this.translate.instant('FILL_REQUIR_FIELDS')
			});
		} else if (noError === true) {
			try {
				this.loading = true;
				const subscription = this.orderService.addOrder(this.order).subscribe((order: Order) => {
					if (!isSet(order)) {
						return;
					}
					this.order.id = order.id;
					this.order.status = order.status;
					this.order.vcs = order.vcs;
					for (let index = 0; index < order.items.length; index++) {
						this.order.items[index].id = order.items[index].id;
					}
					if (!isSet(this.order.customer)) {
						this.order.customer = new Customer();
						this.order.customer.company_name = 'Cash & Carry';
						this.editCustomerIcon = null;
					}
					if (isSet(this.order?.customer?.contact)) {
						this.order.customer.contact.forEach(customer => {
							SendEmail.cloneObject(this.sendEmail);
							this.sendEmail.send_to_mails = [];
							this.sendEmail.send_to_mails.push(customer.email);
						});
					}
					this.order.payments.map(payment => {
						payment.status2 = 'old';
					});
					this.newPayments = [];
					this.order.items.map(item => {
						if (isSet(item.product?.id)) {
							item.checkinlne = false;
						}
						if (isSet(item?.name) && !isSet(item.product?.id)) {
							item.checkinlne = true;
							item.product = new Product();
							item.product.name = item?.name;
						}
						if (isSet(item.service)) {
							item.checkinlne = true;
						}
						if (item.vat?.value == 0) {
							item.vat.value = '0';
						}
					});
					this.orderService.retrieve_lastOrder.next(this.order);
					this.selectedOrder = true;
					this.messageService.add({
						key: 'form', severity: 'success',
						summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
						detail: this.translate.instant('NEW_ORDER_ADDED')
					});
					this.loading = false;
					window.addEventListener('unload', (event) => {
						if (this.updateStatus == false) {
							return event;
						}
					});
					this.orderService.refreshDataTable();
					this.orderService.saveFormClicked.next(null);
					this.sharedService.chekcempey.next(null);
					subscription.unsubscribe();
				}, error => {
					if (!isSet(error.error.detail)) {
						this.messageService.add({ key: 'form', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
					} else if (error.error.detail === this.translate.instant('FORMAT_ORDER_UNIQ')) {
						const subscription2 = this.orderService.getNextOrderNumber().subscribe((data: { format: string }) => {
							if (!isSet(data)) {
								return;
							}
							this.order.format = data.format;
							this.addOrder();

							subscription2.unsubscribe();
						}, (error2) => {
							this.handleError(error2);
							subscription2.unsubscribe();
						});

					} else {
						this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
					}
					this.loading = false;
					subscription.unsubscribe();
				});
			} catch (error) {
				this.loading = false;
				this.log(error);
			}
		}
	}

	deletePayment(index) {
		this.paymentDueStatus = false;
		if (this.order.payments[index].status == 'add') {
			this.totalPayment = this.totalPayment - this.order.payments[index].amount;
		} else if (this.order.payments[index].status == 'refund') {
			this.totalPaymentRefund = this.totalPaymentRefund - this.order.payments[index].amount;
		}
		this.order.payments[index].amount = 0;
		this.getPaymentDue();
		this.order.payments.splice(index, 1);
		this.newPayments.splice(this.newPayments.length - 1, 1);
		setTimeout(() => {
			if (this.order.defaultCurrencyOrderTotalIncVAT > (this.totalPayment - this.totalPaymentRefund)) {
				this.disablePayments = false;
			}
		}, 300);
		if (this.order.paymentDue > this.order.defaultCurrencyOrderTotalIncVAT) {
			this.order.paymentDue = this.order.defaultCurrencyOrderTotalIncVAT;
		}
		if (this.order.paymentDue < 0) {
			this.order.paymentDue = this.order.defaultCurrencyOrderTotalIncVAT;
		}
	}

	getPaymentDue() {
		const payments_amountsadd = [];
		const refundamounts = [];
		if (this.order.payments.length) {
			this.order.payments.map(item => {
				if (item.status === 'add') {
					payments_amountsadd.push(item.amount);
				}
				if (item.status === 'refund') {
					refundamounts.push(item.amount);
				}
			});
			if (payments_amountsadd.length > 0) {
				this.totalPayment = payments_amountsadd.reduce((prev, next) => prev + next);
			}
			if (refundamounts.length > 0) {
				this.totalPaymentRefund = refundamounts.reduce((prev, next) => prev + next);
			}
		}
		this.orderTotal = this.order.defaultCurrencyOrderTotalIncVAT;
		this.order.paymentDue = (this.orderTotal - this.totalPayment) || 0;
		this.order.paymentDue = this.totalPaymentRefund + this.order.paymentDue;
		if (this.paymentDueFromBalance === false && isSet(this.order.customer?.due)) {
			this.customerBalance = this.order.customer?.due + this.order.paymentDue;
			this.paymentDueFromBalance = true;
		}
		if (this.order.discount > 0 && this.order.paymentDue == this.order.defaultCurrencyOrderTotalIncVAT) {
			this.checkDiscountPy = false;
		} else if (this.order.discount > 0) {
			this.checkDiscountPy = true;
		}
		if (this.order.paymentDue === 0) {
			this.paymentDueStatus = true;
		} else if (this.order.paymentDue > 0) {
			this.paymentDueStatus = false;
		}
	}

	getorderTotalChange() {
		this.order.paymentDue = this.order.defaultCurrencyOrderTotalIncVAT;
		setTimeout(() => {
			if (!this.order.payments.length) {
				this.order.paymentDue = this.order.defaultCurrencyOrderTotalIncVAT || 0;
			} else if (this.order.defaultCurrencyOrderTotalIncVAT == 0) {
				this.order.paymentDue = 0;
			} else if (this.order.defaultCurrencyOrderTotalIncVAT > 0) {
				this.order.paymentDue = this.order.defaultCurrencyOrderTotalIncVAT - (this.totalPayment - this.totalPaymentRefund) || 0;
				this.orderTotal = this.order.defaultCurrencyOrderTotalIncVAT || 0;
			}
			if (this.order.paymentDue === 0) {
				this.paymentDueStatus = true;
			} else if (this.order.paymentDue > 0) {
				this.paymentDueStatus = false;
			}
	
		}, 300);
	}

	private subscribeOnFormSaved() {
		const subscription = this.orderService.saveFormClickedEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.addOrder();
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	GetqouteTOorder() {
		const subscription = this.orderService.QuoteToOrderListEmitter.subscribe((order: Order) => {
			if (!isSet(order)) {
				return;
			}
			this.order = order;
			this.heightCardCustomer = true;
			this.quoteToOrder = true;
			this.getNextOrderFormat();
			this.order.date = new Date(moment().add(15, 'day').toISOString())
			this.order.due_date = new Date(moment().add(1, 'month').toISOString())
			if (this.order.discountType == 'Percent') {
				this.order.discount = this.order.discount.substring(0, this.order.discount.length);
				this.selectedDiscountType.type = 'Percent';
			} else {
				this.selectedDiscountType.type = 'Amount';
				this.order.discountType = 'Amount';
			}
			const disconnt = this.order.discountPercentage;
			this.order.items.map(item => {
				item.discont_Percentage = disconnt;
				item.itemVaild = true;
				item.datePipe = new Date(item?.date);
				if (isSet(item.product?.id)) {
					item.checkinlne = false;
				}
				if (isSet(item?.name) && !isSet(item.product?.id)) {
					item.checkinlne = true;
					item.product = new Product();
					item.product.name = item?.name;
				}
				if (isSet(item.service)) {
					item.checkinlne = true;
				}
			});
			this.orderService.QuoteToOrder.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	private subscribeOnOrderSelected() {
		const subscription = this.orderService.orderSelectedListEmitter.subscribe((order: Order) => {
			if (!isSet(order)) {
				return;
			}
			this.getOrder(order.id);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	private initOrder() {
		this.order = Order.cloneObject(null);
		this.order.date = new Date(moment().toISOString());
		this.order.due_date = new Date(moment().add(1, 'month').toISOString());
		this.order.invoice_date = new Date();
		this.order.items = [];
		this.order.payments = [];
		this.payment.dateP = new Date();
		this.order.discount = 0;
		this.sharedService.removeCandCopformCusotmer.next(false);
		this.defaultCurrency = JSON.parse(localStorage.getItem('companySettings'))?.currency;
		this.order.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency;
		this.order.exchange_rate = 1;
		this.addItem();
	}

	showAddPaymnet() {
		if (this.order.paymentDue === 0) {
			return;
		}
		this.PaymentLabel = this.translate.instant('ADD_PAYMENT');
		this.payment = new Payment();
		this.payment.dateP = new Date();
		this.payment.status = 'add';
		this.payment.status2 = 'new';
		this.amount.control.markAsUntouched();
		this.amount.control.clearValidators();
		this.showPaymentDialog = true;
	}

	addPayment() {
		if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) === true)) {
			this.messageService.add({
				key: 'form',
				severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				detail: this.translate.instant('FILL_REQUIR_FIELDS')
			});
			this.amount.control.markAsTouched();
		} else {
			if ( this.mathRound(this.payment.amount) > this.mathRound(this.order.paymentDue)) {

				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_MORE_DUE')
				});
			} else if (this.payment.amount === 0) {
				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_NO_ZERO')
				});
			} else {
				if (!isSet(this.payment.type)) {
					this.payment.type = 'Cash';
				}
				this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
				if (this.order.payments.length) {
					this.order.payments.push(this.payment);
					this.newPayments.push(this.payment);
					this.order.paymentDue = this.order.paymentDue - this.payment.amount;
					this.totalPayment = this.totalPayment + this.payment.amount;
				} else {
					this.order.payments.push(this.payment);
					this.newPayments.push(this.payment);
					this.order.paymentDue = this.order.paymentDue - this.payment.amount;
				}
				this.updateStatus = true;
				if (this.order.discount > 0) {
					this.checkDiscountPy = true;
				}
		
				this.showPaymentDialog = false;
				this.amount.control.markAsUntouched();
				this.amount.control.clearValidators();
				this.sharedService.update_form.next(true);
			}
		}
	}

	showrefundPayment() {
		this.PaymentLabel = this.translate.instant('REFUND_PAYMENT');
		this.payment = new Payment();
		this.payment.status = 'refund';
		this.payment.status2 = 'new';
		this.payment.dateP = new Date();
		this.amount.control.markAsUntouched();
		this.amount.control.clearValidators();
		if (this.disablePayments == true) {
			this.fixedAmount = true;
			this.payment.amount = this.totalPayment - this.totalPaymentRefund;
			this.payment.notes = this.translate.instant('TOTAL_CHANGED');
		}
		this.showPaymentDialog = true;
	}

	refundPayment() {
		if (this.disablePayments == true) {
			if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
				this.amount.control.markAsTouched();
				return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });
			} else if (this.payment.amount == 0) {
				return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
			} else {
				let paymentDue = Number(this.order.paymentDue);
				this.payment.amount = Number(this.payment.amount);
				paymentDue = this.order.paymentDue + this.payment.amount;
				if ( this.mathRound(this.order.defaultCurrencyOrderTotalIncVAT) < this.mathRound(paymentDue)) {
					return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
				}
				if (!isSet(this.payment.type)) {
					this.payment.type = 'Cash';
				}
				this.totalPaymentRefund = Number(this.totalPaymentRefund) + this.payment.amount;
				this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
				this.order.payments.push(this.payment);
				this.newPayments.push(this.payment);
				this.order.paymentDue = Number(paymentDue);
				this.paymentDueStatus = false;
				this.updateStatus = true;
				this.disablePayments = false;
				if (this.order.discount > 0 && this.order.paymentDue == this.order.defaultCurrencyOrderTotalIncVAT) {
					this.checkDiscountPy = true;
				}
			
				this.showPaymentDialog = false;
				this.amount.control.markAsUntouched();
				this.amount.control.clearValidators();
				this.sharedService.update_form.next(true);
			}
		} else {
			if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
				this.amount.control.markAsTouched();
				return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });
			} else if (this.payment.amount > this.order.defaultCurrencyOrderTotalIncVAT) {
				return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
			} else if (this.payment.amount == 0) {
				return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
			} else {
				let paymentDue = Number(this.order.paymentDue);
				this.payment.amount = Number(this.payment.amount);
				paymentDue = this.order.paymentDue + this.payment.amount;
				if (this.order.defaultCurrencyOrderTotalIncVAT < paymentDue) {
					return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
				}
				if (!isSet(this.payment.type)) {
					this.payment.type = 'Cash';
				}
				this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
				this.order.payments.push(this.payment);
				this.newPayments.push(this.payment);
				this.order.paymentDue = Number(paymentDue);
				this.totalPaymentRefund = Number(this.totalPaymentRefund) + this.payment.amount;
				this.paymentDueStatus = false;
				this.updateStatus = true;
			
				this.showPaymentDialog = false;
				this.amount.control.markAsUntouched();
				this.amount.control.clearValidators();
			}
		}
	}

	private getOrder(id: string) {
		this.loading = true;
		const subscription = this.orderService.getOrder(id).subscribe((order: Order) => {
			if (!isSet(order)) {
				return;
			}
			this.selectedOrder = true;
			this.order = Order.cloneObject(order);
			this.heightCardCustomer = true;
			this.defaultCurrency = JSON.parse(localStorage.getItem('companySettings'))?.currency;
			if (this.order.currency?.id != this.defaultCurrency?.id) {
				this.order.exchangeRateStauts = true;
				this.confirmExchangeRate = true;
			}
			if (this.order.discount[this.order.discount.length - 1] == '%') {
				this.order.discount = this.order.discount.substring(0, this.order.discount.length - 1);
				this.selectedDiscountType.type = 'Percent';
				this.order.discountType = 'Percent';
			} else {
				this.selectedDiscountType.type = 'Amount';
				this.order.discountType = 'Amount';
			}
			if (this.order.status?.cc === true) {
				this.order.customer = new Customer();
				this.order.customer.company_name = 'Cash & Carry';
				this.editCustomerIcon = null;
				this.heightCardCustomer = false;
			}
			if (this.order.status?.invoiced) {
				this.order.invoice_date = new Date(this.order?.invoice_date);
				this.editCustomerIcon = null;
			}
			this.order.date = new Date(order.date);
			this.order.due_date = new Date(order.due_date);
			this.customerBalance = this.order.customer?.due;
			const discountPercentage = this.order.discountPercentage;
			this.order.items.map(item => {
				item.exchange_rate = this.order.exchange_rate;
				item.discont_Percentage = discountPercentage;
				item.itemVaild = true;
				item.datePipe = new Date(item?.date);
				if (isSet(item.product?.id)) {
					item.checkinlne = false;
				}
				if (isSet(item?.name) && !isSet(item.product?.id)) {
					item.checkinlne = true;
					item.product = new Product();
					item.product.name = item?.name;
				}
				if (isSet(item.service)) {
					item.checkinlne = true;
				}
			});
			if (this.order.payments.length) {
				this.order.payments.map(payment => {
					if (payment.type === 'Wire Transfer') {
						payment.type = 'wt';
					}
					payment.dateP = new Date(payment.date);
					payment.status2 = 'old';

				});
			}
			this.getPaymentDue();
			if (isSet(this.order.customer?.contact)) {
				this.order.customer.contact.forEach(customer => {
					SendEmail.cloneObject(this.sendEmail);
					this.sendEmail.send_to_mails = [];
					this.sendEmail.send_to_mails.push(customer.email);
				});
			}
			if (this.order.status?.draft || this.order.status?.invoiced || this.order.status?.cc) {
				this.sharedService.removeCandCopformCusotmer.next(true);
			}
			if (this.order.discountType == 'Amount') {
				const discount = this.order.discount;
				this.clearDiscount();
				this.order.discount = discount;
				this.discountPercentage();
			}
			subscription.unsubscribe();
			this.orderService.selectOrder(null);
			this.loading = false;
			this.getVatsBeforeAfterDiscount();
		}, error => {
			this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
			subscription.unsubscribe();
			this.loading = false;
		});
	}

	paymentReview(payment: Payment, index: number) {
		if (payment.status2 === 'old') {
			return;
		}
		if (payment.notes == 'Total Changed') {
			return;
		}
		if (index !== this.order.payments.length - 1) {
			return;
		}
		if (this.order.paymentDue === 0) {
			this.paymentDueStatus = true;
		}
		this.payment = new Payment();
		this.payment.amount = payment.amount;
		this.payment.notes = payment.notes;
		this.payment.type = payment.type;
		this.payment.status = payment.status;
		this.payment.status2 = payment.status2;
		this.payment.dateP = new Date(payment.dateP);
		this.paymentUpdateIndex = index;
		this.showPaymentDialog = true;
		this.PaymentLabel = this.translate.instant('UPDATED_PAYMENT');
	}

	UpdatePayment() {
		const prevPayment = new Payment();
		prevPayment.amount = Number(this.order.payments[this.paymentUpdateIndex].amount);
		prevPayment.dateP = new Date(this.order.payments[this.paymentUpdateIndex].dateP);
		prevPayment.type = this.order.payments[this.paymentUpdateIndex].type;
		prevPayment.notes = this.order.payments[this.paymentUpdateIndex].notes;
		let paymentDue = Number(this.order.paymentDue);
		paymentDue = paymentDue - Number(prevPayment.amount);
		paymentDue = paymentDue + Number(this.payment.amount);
		if (this.payment.status === 'add') {
			if (((this.payment.amount > paymentDue) && (paymentDue > this.order.defaultCurrencyOrderTotalIncVAT))) {
				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_MORE_DUE')
				});
			} else if (this.payment.amount === 0) {
				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_NO_ZERO')
				});
			}
		} else if (this.payment.status === 'refund') {
			if (this.order.defaultCurrencyOrderTotalIncVAT < paymentDue) {
				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_MORE_TOTAL')
				});
			} else if (this.payment.amount === 0) {
				return this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: this.translate.instant('AMOUNT_NO_ZERO')
				});
			}
		}
		this.order.paymentDue = paymentDue;
		this.order.payments[this.paymentUpdateIndex] = this.payment;
		this.order.payments[this.paymentUpdateIndex].amount = Number(this.order.payments[this.paymentUpdateIndex].amount);
		this.showPaymentDialog = false;
		this.updateStatus = true;
		this.payment = new Payment();
		this.getPaymentDue();
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	private getNextOrderFormat() {
		const subscription = this.orderService.getNextOrderNumber().subscribe((data: { format: string }) => {
			if (!isSet(data)) {
				return;
			}
			if (!isSet(this.order.format)) {
				this.order.format = data.format;
			}
			subscription.unsubscribe();
		}, error => {
			subscription.unsubscribe();
			this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
		});
	}

	private getNextInvoiceFormat() {
		const subscription = this.orderService.getNextInoviceNumber().subscribe((data: { format: string }) => {
			if (!isSet(data)) {
				return;
			}
			this.order.invoice_format = data.format;
			subscription.unsubscribe();
		}, error => {
			subscription.unsubscribe();
			this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
		});
	}

	SaveForm() {
		this.sharedService.Saveform_Observable_state.subscribe(status => {
			if (!isSet(status) || !status) {
				return;
			}
			this.sharedService.Save_object(this.order);
			this.sharedService.Set_Saveform__state(null);
		}, error => {
			this.handleError(error);
		});
	}

	getSavedOrder() {
		let subscription: Subscription;
		subscription = this.sharedService.Saved_object_Emitter.subscribe((order: any) => {
			if (!isSet(order)) {
				this.sharedService.objectstauts.next(null);
				return;
			}
			if (order.url === this.router.url) {
				this.order = order.object;
				this.sharedService.objectstauts.next(true);
			} else {
				this.sharedService.objectstauts.next(null);
			}
		}, error => {
			this.handleError(error);
		});
		subscription.unsubscribe();
	}

	clearAll() {
		this.sharedService.clearAll_Emitter.subscribe(status => {
			if (!isSet(status)) {
				return;
			}
			this.initOrder();
			this.getNextOrderFormat();
			this.order.paymentDue = 0;
			this.sharedService.clearAll.next(null);
		}
			, error => {
				this.handleError(error);
			});
	}

	updateEmitter() {
		const subscription = this.orderService.updateFormClickedEmitter.subscribe(status => {
			if (!isSet(status) || !status) {
				return;
			}
			this.updateOrder();
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	customerChange(customer: Customer) {
		
		this.order.customer = Customer.cloneObject(customer);
		this.getorderTotalChange();
		this.getPaymentDue();
		this.updateStatus = true;
		this.heightCardCustomer = true;
	}

	async totalDiscountChange() {
		if (this.selectedDiscountType.type == 'Percent') {
			this.order.discountType = 'Percent';
		} else {
			this.order.discountType = 'Amount';
			if (this.order.discount > this.order.defaultCurrencyOrderTotalIncVAT) {
				return this.messageService.add({
					key: 'form', severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					life: 4000,
					detail: this.translate.instant('DIS_BIG_TOTAL')
				});
			}
		}
		await this.discountPercentage();
		this.getorderTotalChange();
		await this.getVatsBeforeAfterDiscount();
		this.updateStatus = true;
		setTimeout(() => {
			if ( this.mathRound(this.order?.defaultCurrencyOrderTotalIncVAT) < this.mathRound(this.totalPayment - this.totalPaymentRefund)) {
				this.disablePayments = true;
				this.messageService.add({
					key: 'form', severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					life: 4000,
					detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
				});
			} else {
				this.disablePayments = false;
			}
		}, 300);
	}

	discountPercentage() {
		return new Promise((resolve) => {
			this.order.paidVats = [];
			setTimeout(() => {
				const discount = this.order.discountPercentage;
				this.order.items.map(item => {
					item.discont_Percentage = discount;
				});
				resolve(null);
			});
		});
	}

	clearDiscount() {
		this.order.discount = 0;
		const discount = this.order.discountPercentage;
		this.order.items.map(item => {
			item.discont_Percentage = discount;
		});
		this.getorderTotalChange();
	}

	async notEmpty(event) {
		this.updateStatus = true;
		this.sharedService.update_form.next(true);
		if (this.disableVatsRatesChecks == false) {
			const restDiscount = this.order.discount;
			this.clearDiscount();
			this.order.discount = restDiscount;
			await this.discountPercentage();
			this.getorderTotalChange();
			await this.getVatsBeforeAfterDiscount();
		}
		if (this.order.discount > 0) {
			const discount = this.order.discount;
			this.order.discount = 0;
			let discountPercentage = this.order.discountPercentage;
			this.order.items.map(item => {
				item.discont_Percentage = discountPercentage;
			});
			this.order.discount = discount;
			discountPercentage = this.order.discountPercentage;
			this.order.items.map(item => {
				item.discont_Percentage = discountPercentage;
			});
		}
		setTimeout(() => {
			if ( this.mathRound(this.order?.defaultCurrencyOrderTotalIncVAT) < this.mathRound(this.totalPayment - this.totalPaymentRefund)) {
				this.disablePayments = true;
				this.messageService.add({
					key: 'form', severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					life: 4000,
					detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
				});
			} else {
				this.disablePayments = false;
			}
		}, 300);
	}

	getVatsBeforeAfterDiscount() {
		return new Promise((resolve) => {
			this.order.paidVats = [];
			setTimeout(() => {
				this.vatPercentage.map(vat => {
					let beforeDiscount = 0;
					let afterDiscount = 0;
					beforeDiscount = _.sumBy(this.order?.items, (item) => {
						if (item?.vat?.id == vat.id) {
							return item?.beforediscont_vatAmount;
						} else {
							return 0;
						}
					});
					afterDiscount = _.sumBy(this.order?.items, (item) => {
						if (item?.vat?.id == vat?.id) {
							return item?.vatAmount;
						} else {
							return 0;
						}
					});
					if (beforeDiscount > 0) {
						const v = {
							name: vat?.value,
							beforeDiscount: beforeDiscount,
							afterDiscount: afterDiscount
						};
						this.order.paidVats.push(v);
						resolve(this.order.paidVats.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
					}
				});
			}, 200);
		});
	}

	exchangeRateChange() {
		setTimeout(() => {
			this.order.items.map(item => {
				item.exchange_rate = this.order.exchange_rate;
			});
		}, 300);
		this.getPaymentDue();
	}

	onChange_Item(index: number, filed) {
		const qtyS: ElementRef[] = this.qtyS.toArray();
		if (filed === 'qty') {
			qtyS[index].nativeElement.value = this.spaceNumber(this.order.items[index].qty);
			this.order.items[index].qty = this.order.items[index].qty.replace(/\s/g, '');
		}
	}

	updateOrder() {
		if (this.order.payments.length) {
			if ( this.mathRound(this.order?.defaultCurrencyOrderTotalIncVAT) <this.mathRound(this.totalPayment - this.totalPaymentRefund)) {
				return this.messageService.add({
					key: 'form', severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					life: 4000,
					detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
				});
			}
		}
		let noError: boolean;
		this.orderService.newPayments = this.newPayments;
		if (isSet(this.order?.items)) {
			this.order?.items.map(item => {
				if (this.order.status?.invoiced === true) {
					if ((!isSet(this.order.customer)) || (!isSet(this.order.format))
						|| (!isSet(this.order.date)) || (!isSet(this.order.due_date)) || !item.itemVaild
						|| (!isSet(this.order?.invoice_date))) {
						this.productTouched = true;
						this.customerOrder = true;
						return noError = false;
					} else {
						return noError = true;
					}
				} else {
					if ((!isSet(this.order.customer)) || (!isSet(this.order.format))
						|| (!isSet(this.order.date)) || (!isSet(this.order.due_date)) || !item.itemVaild) {
						this.productTouched = true;
						this.customerOrder = true;
						return noError = false;
					} else {
						return noError = true;
					}
				}
			});
		}
		if (noError === false) {
			this.messageService.add({
				key: 'form', severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				detail: this.translate.instant('FILL_REQUIR_FIELDS')
			});
		} else if (noError === true) {
			if (this.updateStatus === false) {
				this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });
			} else {
				try {
					this.loading = true;
					const subscription = this.orderService.UpadeOrder(this.order).subscribe((order: Order) => {
						if (!isSet(order)) {
							return;
						}
						this.loading = false;
						this.messageService.add({
							key: 'form',
							severity: 'success',
							summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
							detail: this.translate.instant('UPDATED_SUCC')
						});
						window.addEventListener('unload', (event) => {
							if (this.updateStatus == false) {
								return event;
							}
						});
						this.order.payments.map(payment => {
							payment.status2 = 'old';
						});
						this.updateStatus = false;
						this.newPayments = [];
						this.unlockInvoiceEdit = false;
						this.orderService.refreshDataTable();
						this.sharedService.update_form.next(false);
						this.orderService.updateFormClicked.next(null);
						this.orderService.refreshSelected.next(true);
						subscription.unsubscribe();
					}, error => {
						this.messageService.add({
							key: 'form',
							severity: 'error',
							summary: this.translate.instant('ERR_ESSAGE'),
							detail: error.error.detail
						});
						this.loading = false;
						subscription.unsubscribe();
						this.orderService.updateFormClicked.next(null);
					});
				} catch (error) {
					this.loading = false;
					this.log(error);
				}
			}
		}
	}

	private subscribeOnSendEmail() {
		const subscription = this.orderService.sendEmailClickedEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.sendEmailDialog = true;
			this.orderService.sendEmailClicked.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	SaveMessage() {
		localStorage.setItem('defaultMessage', this.sendEmail.message);
	}

	sendEmails(): void {
		try {
			this.orderService.btnLoading.next(true);
			const subscription = this.orderService.sendEmail(this.sendEmail, this.order.id).subscribe((sendEmail: SendEmail) => {
				if (!isSet(sendEmail)) {
					return;
				}
				this.messageService.add({
					key: 'form',
					severity: 'success',
					summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
					detail: this.translate.instant('SENT_EMAILS')
				});
				this.orderService.btnLoading.next(false);
				subscription.unsubscribe();
			}, error => {
				this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error });
				this.orderService.btnLoading.next(false);
				subscription.unsubscribe();
			});
		} catch (error) {
			this.loading = false;
			this.log(error);
		}
		this.sendEmailDialog = false;
	}

	sendCopyToMyself() {
		const user: User = JSON.parse(localStorage.getItem('user'));
		const email = this.sendEmail.send_to_mails;
		if (!isSet(this.sendEmail.send_to_mails)) {
			this.sendEmail.send_to_mails = [];
			this.sendEmail.send_to_mails.push(user.email);
		}
		if (this.sendEmail.send_to_mails?.includes(user.email) === false && this.userEmail != null) {
			email.push(user.email);
			this.sendEmail.send_to_mails = [];
			this.userEmail = user.email;
			email.map(em => {
				this.sendEmail.send_to_mails.push(em);
			});
		} else {
			this.sendEmail.send_to_mails = this.sendEmail.send_to_mails?.filter(item => item !== user.email);
			this.userEmail = null;
		}
	}

	onRemovEmail() {
		this.sendEmail.send_to_mails.find(email => {
			if (email !== this.userEmail) {
				this.userEmail = null;
			}
		});
	}

	addEmailOnSpace(event: KeyboardEvent) {
		if (event.key === ' ') {
			event.preventDefault();
			const element = event.target as HTMLElement;
			element.blur();
			element.focus();
		}
	}

	convertToInvoiceEmitter() {
		const subscription = this.orderService.ConvertInvoiceClickedEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.getNextInvoiceFormat();
			this.order.invoice_date = new Date();
			this.convertInvoiceDialogueQ = true;
			this.orderService.ConvertInvoiceClicked.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	convertToDraftEmitter() {
		const subscription = this.orderService.convertDraftClickedEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.convertDraftDialogueQ = true;
			if (this.order.customer?.company_name === 'Cash & Carry') {
				this.messageService.add({
					key: 'form',
					severity: 'info',
					life: 2000,
					summary: this.translate.instant('INFO'),
					detail: this.translate.instant('MUST_ADD_CUS')
				});
			}
			this.orderService.convertDraft.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	unLockInvoiceEmitter() {
		const subscription = this.orderService.unlockInoviceEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.unlockInvoiceDialogueQ = true;
			this.orderService.unlockInovice.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	printOrderViewer() {
		const subscription = this.orderService.printOrderLinkEmitter.subscribe((link: any) => {
			if (!isSet(link)) {
				return;
			}
			this.pdfViewer=true;
			this.order.downloadUrl = link.download_url;
			this.order.viewUrl = link.view_url;
			this.getShortUrl(link.download_url, link.file_name)
			this.orderService.printOrderLink.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}


	downloadPdf() {
		window.location.href = this.order.downloadUrl;
	}

	printPdf() {
		printJS({ printable: this.order.downloadUrl, type: 'pdf', showModal: true });
	}

	pdfLoader(event: { loaded: number, total: number }) {
		this.loading = event.loaded < event.total;
	}

	afterLoadComplete(pdf: PDFDocumentProxy) {
		this.pdfHeight = pdf.numPages * 1020;
	}

	convertToInvoice() {
		if ((this.invoice_format.control.invalid) ||
			(!isSet(this.invoice_format)) || (this.invoice_Date.control.invalid) || (!isSet(this.invoice_Date))) {
			this.messageService.add({
				key: 'form',
				severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				detail: this.translate.instant('FILL_REQUIR_FIELDS')
			});
			this.invoice_format.control.markAsTouched();
			this.invoice_Date.control.markAsTouched();
		} else {
			try {
				this.loading = true;
				const subscription = this.orderService.convertToInvoice(this.order).subscribe((order: Order) => {
					if (!isSet(order)) {
						return;
					}
					this.messageService.add({
						key: 'form', severity: 'success',
						summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
						detail: this.translate.instant('CONV_INTO_INVO')
					});
					this.order.status.draft = false;
					this.order.status.invoiced = true;
					this.orderService.refreshDataTable();
					this.sharedService.update_form.next(false);
					this.orderService.updateFormClicked.next(null);
					this.orderService.ConvertInvoiceStatus.next(true);
					this.orderService.refreshSelected.next(true);
					this.orderService.saveFormClicked.next(null);
					this.orderService.ConvertInvoiceClicked.next(null);
					this.convertInvoiceDialogueQ = false;
					this.loading = false;
					this.subscriptions.push(subscription);
				}, error => {
					this.messageService.add({
						key: 'form',
						severity: 'error',
						summary: this.translate.instant('ERR_ESSAGE'),
						detail: error.error.detail
					});
					this.loading = false;
					this.subscriptions.push(subscription);
					this.orderService.ConvertInvoiceClicked.next(null);
				});
			} catch (error) {
				this.loading = false;
				this.log(error);
			}
		}
	}

	convertToDraft() {
		if (isSet(this.order.customer?.id)) {
			this.draftCustomer = this.order.customer;
		}
		try {
			this.loading = true;
			const subscription = this.orderService.convertToDraft(this.order.id, this.draftCustomer?.id).subscribe((state: any) => {
				this.order.status.invoiced = false;
				this.order.status.draft = true;
				this.order.customer = this.draftCustomer;
				this.messageService.add({
					key: 'form', severity: 'success',
					summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
					detail: this.translate.instant('RETURN_TO_DRAFT')
				});
				this.loading = false;
				this.subscriptions.push(subscription);
				this.orderService.refreshDataTable();
				this.orderService.convertDraft.next(null);
				this.orderService.refreshSelected.next(true);
				this.convertDraftDialogueQ = false;
				this.draftCustomer = null;
			}, error => {
				this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: error.error.detail
				});
				this.loading = false;
				this.subscriptions.push(subscription);
				this.orderService.ConvertInvoiceClicked.next(null);
			});
		} catch (error) {
			this.loading = false;
			this.log(error);
		}
	}

	unlockInvoice() {
		try {
			this.loading = true;
			const subscription = this.orderService.unlockInvoice(this.order.id).subscribe((state: any) => {
				this.messageService.add({
					key: 'form', severity: 'success',
					summary: this.translate.instant(this.translate.instant('SUCCESSFUL')),
					detail: this.translate.instant('INV_ALLOW_EDIT')
				});
				this.loading = false;
				this.subscriptions.push(subscription);
				this.orderService.refreshDataTable();
				this.orderService.unlockInovice.next(null);
				this.orderService.disableUnlockInvoice.next(true);
				this.unlockInvoiceDialogueQ = false;
				this.unlockInvoiceEdit = true;
				this.order.status.invoiced = false;
			}, error => {
				this.messageService.add({
					key: 'form',
					severity: 'error',
					summary: this.translate.instant('ERR_ESSAGE'),
					detail: error.error.detail
				});
				this.loading = false;
				this.subscriptions.push(subscription);
				this.orderService.ConvertInvoiceClicked.next(null);
			});
		} catch (error) {
			this.loading = false;
			this.log(error);
		}
	}

	outsideStatus(status: boolean) {
		this.sharedService.dialog_status.next(status);
	}

	private subscribeOnDuplicateOrder() {
		const subscription = this.orderService.duplicateClickedEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			const duplicate = Order.cloneObject(this.order);
			this.selectedOrder = false;
			duplicate.id = null;
			duplicate.payments = [];
			duplicate.newPayments = [];
			this.orderService.newPayments = [];
			duplicate.status = new OrderStatus();
			duplicate.vcs = null;
			duplicate.format = null;
			this.getNextOrderFormat();
			duplicate.date = new Date(moment().add(15, 'days').toISOString())
			duplicate.due_date = new Date(moment().add(1, 'month').toISOString());
			this.initOrder();
			this.order = Order.cloneObject(duplicate);
			this.order.duplicate = true;
			this.order.paymentDue = this.order?.defaultCurrencyOrderTotalIncVAT;
			this.disablePayments = false;
			this.totalPayment = 0;
			this.totalPaymentRefund = 0;
			this.paymentDueStatus = false;
			this.getPaymentDue();
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	isSet(value: any) {
		if (isSet(value)) {
			return true;
		} else {
			return false;
		}
	}

	eraceValue(index, filedName: string) {
		const qtyS: ElementRef[] = this.qtyS?.toArray();
		const priceS: InputNumber[] = this.priceS?.toArray();
		if (filedName === 'price') {
			this.order.items[index].price = 0;
			setTimeout(() => {
				priceS[index].input.nativeElement.select();
			}, 100);
		} else {
			this.order.items[index].qty = 1;
			setTimeout(() => {
				qtyS[index].nativeElement.select();
			}, 100);
		}
		this.getorderTotalChange();
		if (this.order?.defaultCurrencyOrderTotalIncVAT < this.totalPayment - this.totalPaymentRefund) {
			this.disablePayments = true;
			return this.messageService.add({
				key: 'form', severity: 'error',
				summary: this.translate.instant('ERR_ESSAGE'),
				life: 4000,
				detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
			});
		} else {
			this.disablePayments = false;
		}
	}

	spaceNumber(number) {
		return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
	}

	removeSpaceNumber() {
		const qtyS: ElementRef[] = this.qtyS?.toArray();
		for (let index = 0; index < this.order?.items?.length; index++) {
			const replace = qtyS[index].nativeElement.value.replace(/\s/g, '');
			this.order.items[index].qty = parseInt(replace);
		}
	}

	showOrderFromDash() {
		const subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
			if (!isSet(item)) {
				return;
			}
			if (item.itemName === 'Order') {
				this.getOrder(item.item?.id);
			}
			this.sharedService.diplayDash.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	expand_inLineProduct(index: number) {
		this.order.items[index].inline_Epand = !this.order.items[index].inline_Epand;
	}

	showEidtInlineProduct(index: number) {
		this.inlineProductValue = this.order.items[index].service;
		this.indexInlineProduct = index;
		this.showAddInlineProduct = true;
	}

	editInlineProduct() {
		this.order.items[this.indexInlineProduct].service = this.inlineProductValue;
		this.showAddInlineProduct = false;
		this.updateStatus = true;
	}
	onKeyUp(press) {
		switch (press.keyCode) {
			case 13:
				const focusableElements = document.querySelectorAll('input, button');
				const index = Array.prototype.indexOf.call(focusableElements, document.activeElement);
				if (press.shiftKey)
					this.focus(focusableElements, index - 1);
				else
					this.focus(focusableElements, index + 1);
				press.preventDefault();
				break;
		}

	}
	focus(elements, index) {
		if (elements[index])
			elements[index].focus();
	}

	createCreditNoteEmitter() {
		const subscription = this.orderService.orderToCreditEmitter.subscribe((status: boolean) => {
			if (!isSet(status) || !status) {
				return;
			}
			this.orderService.create_Creditnote(this.order);
			this.orderService.orderToCredit.next(null);
		}, error => {
			this.handleError(error);
		});
		this.subscriptions.push(subscription);
	}

	currencyChanged() {
		const subscription = this.orderService.currencyChangedEmitter.subscribe(currancy => {
			if (!isSet(currancy)) {
				return;
			}
			this.confirmExchangeRate = false;
			this.order.currency = currancy;
			this.defaultCurrency = JSON.parse(localStorage.getItem('companySettings'))?.currency;
			if (this.order.currency?.id != this.defaultCurrency?.id) {
				this.showAddExchangeRate = true;
			} else {
				this.order.exchangeRateStauts = false;
				this.showAddExchangeRate = false;
				this.order.exchange_rate = 1;
				this.addExchangeRate = 1;
				this.defaultCurrencyStatus = false;
				this.order.items.map(item => {
					item.exchange_rate = 1;
					if (item.product.id?.length > 4) {
						item.price = item.product?.sell_price / item.exchange_rate;
					}
				});
				this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.defaultCurrency?.name });
			}
			this.orderService.currencyChanged.next(null);
		}, error => { console.log(error); });
		this.subscriptions.push(subscription);
	}

	confrimAddExchangeRate() {
		this.confirmExchangeRate = true;
		this.showAddExchangeRate = false;
		this.order.exchange_rate = this.addExchangeRate;
		this.order.exchangeRateStauts = true;
		this.defaultCurrencyStatus = true;
		this.order.items.map(item => {
			item.exchange_rate = this.order.exchange_rate;
		});
		this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.order?.currency?.name });
	}

	cancelAddExchangeRate() {
		this.sharedService.dialog_status.next(false);
		if (this.confirmExchangeRate == false) {
			this.orderService.currencyChanged.next(this.defaultCurrency);
			this.showAddExchangeRate = false;
			this.order.exchange_rate = 1;
			this.addExchangeRate = 1;
			this.order.exchangeRateStauts = false;
			this.defaultCurrencyStatus = false;
			this.order.items.map(item => {
				item.exchange_rate = 1;
			});
		}
	}

	sendToWhatsapp() {
		window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.order.whatsappUrl}`, "_blank")
	}

	getShortUrl(downloadUrl, fileName) {
		const subscription = this.sharedService.urlShortener(downloadUrl, fileName).subscribe(url => {
			if (!isSet(url)) {
				return
			}
			this.order.whatsappUrl = url?.id
			subscription.unsubscribe()
		}, error => {
			console.log(error)
			subscription.unsubscribe()
		})
	}

	focusThenShow(type) {
		switch (type) {
			case 'date':
				setTimeout(() => {
					this.orderDateToggle.toggle()
				}, 0)
				break;
			case 'dueDate':
				setTimeout(() => {
					this.orderDueDateToggle.toggle()
				}, 0)
				break;
			case 'invoice':
				setTimeout(() => {
					this.orderInvoiceDateToggle.toggle()
				}, 0)
				break;
		}
	}
	mathRound(value){
		return	Math.round(value*100)/100;
	}
}
