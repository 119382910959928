import { Component, OnInit, ViewChild } from '@angular/core';
import { Contact } from '../../../models/contact';
import { Address } from '../../../models/address';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { ApiService } from '../../core/api.service';
import { MessageService } from 'primeng/api';
import { Supplier } from '../../../models/supplier';
import { VendorsService } from '../suppliers.service';
import { VatInfo, VatInfoCountry } from '../../auth/models/vatinfo';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import places from 'places.js'
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { CountryCode_currency } from 'src/app/models/country_code&currency';

@Component({
  selector: 'app-Vendors-form',
  templateUrl: './suppliers-form.component.html',
  styleUrls: ['./suppliers-form.component.scss']
})
export class VendorsFormComponent extends BaseComponent implements OnInit {

  public supplier = new Supplier;
  public showContactDialog = false;
  public showAddressDialog = false;
  public contacts: Contact[] = [];
  public contact: Contact;
  public shipping_address = new Address()
  public showAddCustomFieldDialog = false;
  public addCustomFieldSection;
  public customFieldName;
  ContactDialogHeader = 'Add Contact'
  addressDialogHeader=''
  index_Toupdate = 0
  countries: CountryCode_currency[] = []
  updateStatus = false
  @ViewChild('Supplier_name') Supplier_name: any;
  @ViewChild('zip') zip: any;
  @ViewChild('city') city: any;
  @ViewChild('country_code') country_code: any;
  @ViewChild('contactname') contactname: any;
  @ViewChild('address') address: any;
  @ViewChild('position') position: any;
  @ViewChild('email') email: any;
  @ViewChild('phone') phone: any;
  @ViewChild('addressInput') addressInput: any;


  constructor(private SharedService: SharedService, private vendorsService: VendorsService,public translate: TranslateService,
     private router: Router,private auth: AuthService, private api: ApiService, private messageService: MessageService) {
    super();
  }
  vatBtnDisabled = false;

  ngOnInit(): void {



    this.subscribeOnSaveFormClicked();
    this.subscribeOnCusomterSelected()
    this.Save_form()
    this.updateCustomerEmiiter()
    this.getSavedSupplier()
    this.listCounties()
    this.clearAll()

  }
  ngAfterViewInit() {
    var placesAutocomplete = places({
      container: this.addressInput.nativeElement
    });
    placesAutocomplete.on('change', (e: any) => {
      this.shipping_address.address= e?.suggestion.value
    });
    
  }
  getsupplier(id: string) {
    this.loading = true
    const subscription = this.vendorsService.getSupplier(id).subscribe((supplier: Supplier) => {
      if (!isSet(supplier)) {
        return;
      }
      this.loading = false
      const cloneObject = Supplier.cloneObject(supplier);

      this.supplier = cloneObject;


 
 
      
      if (!isSet(this.supplier.contacts)) {
        this.supplier.contacts = []
      }
    
      // if (!isSet(this.supplier.attachments)) {
      //   this.supplier.attachments = []
      // }
      if (!isSet(this.supplier.extra_fields)) {
        this.supplier.extra_fields = []
      }
     
      subscription.unsubscribe();
      


    }, error => {
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false

    });
  }
  private subscribeOnCusomterSelected() {

    const subscription = this.vendorsService.supplierSelectedEmitter.subscribe((supplier: Supplier) => {
      if (!isSet(supplier)) {
        return this.initsupplier()
        

      }
      this.getsupplier(supplier?.id)
      this.vendorsService.supplierSelected.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  initsupplier() {

    this.supplier = new Supplier();
    this.supplier.address = [];
    this.supplier.contacts = [];
    this.supplier.extra_fields = [];



    this.contact = new Contact();

  }

  public adShipping_address(): void {
    if ((this.address?.control?.invalid) || (this.country_code?.control?.invalid) || (this.city?.control?.invalid)
      || (this.zip?.control?.invalid)) {
      this.address.control.markAsTouched();
      this.zip.control.markAsTouched();
      this.country_code.control.markAsTouched();
      this.city.control.markAsTouched();

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

    } else {
      if (!isSet(this.supplier.address)) {
        this.supplier.address=[]
      }
      this.supplier.address.push(this.shipping_address);
      this.shipping_address = new Address()

      this.address.control.markAsUntouched()
      this.address.control.clearValidators()
      this.city.control.markAsUntouched()
      this.city.control.clearValidators()
      this.zip.control.markAsUntouched()
      this.zip.control.clearValidators()
      this.country_code.control.markAsUntouched()
      this.country_code.control.clearValidators()
      this.showAddressDialog = false;
    }
  }

  
  getDataFromVat(): void {
    if (this.supplier.vat.length < 9) {
      return
    }

    this.vatBtnDisabled = true;
    const subscription = this.api.postGuest('/extract_vat_info', {
      vat_number: this.supplier.vat
    }).subscribe((vatInfo: VatInfo) => {
      if (!isSet(vatInfo)) {
        return;
      }
 
      this.supplier.name = vatInfo.name;
      let _adress = new Address()

      const address = vatInfo.address.split('\n');
      if (address.length >= 2) {
        _adress.address = address[0]
        const zip = address[1].split(' ')
        if (zip.length >= 2) {
          _adress.zip = zip[0]
          _adress.city = zip[1]


        }

        if (isSet(VatInfoCountry[vatInfo.countryCode])) {
          _adress.country_code = VatInfoCountry[vatInfo.countryCode];
        }

        this.supplier.address.push(_adress)
      }
    }, error => {
      if (isSet(error.error.detail)) {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('SUCCESSFUL'), detail: error.error.detail });
      }
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    }, () => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    });
  }

  normalize_VAT(event) {
    this.supplier.vat = event?.replace(/\s/g, '')
    this.supplier.vat = this.supplier?.vat?.replace(/\./g, '').toUpperCase()
    this.SharedService.chekcempey.next(true)
    this.updateStatus = true
  }
  addCustomField(): void {
    if (!isSet(this.supplier.extra_fields))  this.supplier.extra_fields = []

    this.supplier.extra_fields.push({ name: this.customFieldName, value: null });
    
    this.addCustomFieldSection = null;
    this.customFieldName = null;
    this.showAddCustomFieldDialog = false;
  }

  private subscribeOnSaveFormClicked(): void {
    try {
      const subscription = this.vendorsService.saveFormClickedEmitter.subscribe((data: boolean) => {
        if (!isSet(data) || !data) {
          return;
        }

          this.saveNewSupplier();

      }, error => {
        this.log(error);
      });
      this.subscriptions.push(subscription);
    } catch (error) {
      this.log(error);
    }
  }
  preventDefault(event, element) {
    const el = element.model
    if (!el.replace(/\s/g, '').length) {
      event.preventDefault();
    }}
    showAddContact() {
      this.contact = new Contact()
      this.ContactDialogHeader = this.translate.instant('CUSTOMER_FORM_ADD_CONTACT')
      this.showContactDialog = true;

    }
    public addContact(): void {
      if (!isSet(this.contact.email) || this.email.control.invalid) {
        this.email.control.markAsTouched();

        this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERROR'),
          detail: this.translate.instant('FILL_REQUIR_FIELDS')
        });
      } else {
        this.supplier.contacts.push(this.contact);
        console.log(this.supplier.contacts);
        
        this.contact = new Contact()
        this.email.control.markAsUntouched()
        this.email.control.clearValidators()
        this.showContactDialog = false;
        this.updateStatus = true

      }
    }
  showEidtContact(index: number) {
    this.ContactDialogHeader = this.translate.instant('EDIT_CONTACT')
    this.contact = Contact.cloneObject(this.supplier.contacts[index])


    this.index_Toupdate = index
    this.showContactDialog = true;

  }
  eidtContact() {
    if (!isSet(this.contact.email) && this.email.control.invalid) {
      this.email.control.markAsTouched();

      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.supplier.contacts[this.index_Toupdate] = this.contact
      this.contact = new Contact()
      this.email.control.markAsUntouched()
      this.email.control.clearValidators()
      this.showContactDialog = false;
      this.updateStatus = true

    }

  }
  rmContact(index: number) {
    this.supplier.contacts.splice(index, 1)
    this.updateStatus = true


  }
  showAddAdrees() {
    this.shipping_address = new Address()
    this.addressDialogHeader = this.translate.instant('CUSTOMER_FORM_ADD_ADDRESS')
    this.showAddressDialog = true;

  }
  showEidtAdrees(index: number) {
    this.addressDialogHeader = this.translate.instant('EDIT_ADDRESS')
    this.shipping_address = Address.cloneObject(this.supplier.address[index])


    this.index_Toupdate = index
    this.showAddressDialog = true;

  }
  eidtAdrees() {
    if ((this.address.control.invalid) || (this.country_code.control.invalid) || (this.city.control.invalid)
    || (this.zip.control.invalid)) {
    this.address.control.markAsTouched();
    this.zip.control.markAsTouched();
    this.country_code.control.markAsTouched();
    this.city.control.markAsTouched();
      this.messageService.add({

        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.supplier.address[this.index_Toupdate] = this.shipping_address
      this.shipping_address = new Address()
      this.showAddressDialog = false;
      this.address.control.markAsUntouched()
      this.address.control.clearValidators()
      this.country_code.control.markAsUntouched()
      this.country_code.control.clearValidators()
      this.city.control.markAsUntouched()
      this.city.control.clearValidators()
      this.zip.control.markAsUntouched()
      this.zip.control.clearValidators()
    }


  }
  rmAdrees(index: number) {
    this.supplier.address.splice(index, 1)
    this.updateStatus = true


  }
  private saveNewSupplier(): void {
    let state: boolean
    if (!isSet(this.supplier.name)) {
      this.Supplier_name?.control.markAsTouched();

      this.messageService.add({ key: 'form', severity: 'error',
      summary: this.translate.instant('ERROR'),
      detail: this.translate.instant('FILL_REQUIR_FIELDS')
    });

      this.messageService.add({ severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

    } else {
      try {
        this.loading = true;
        const subscription = this.vendorsService.addSupplier(this.supplier).subscribe((supplier: Supplier) => {

          this.supplier.id = supplier.id
          this.vendorsService.currentSupplier = Supplier.cloneObject(supplier);
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('NEW_SUP_ADDED') });
          this.vendorsService.showaftersaveForm.next(true)

          this.loading = false;
          state = true
          this.vendorsService.refreshVendors();
          subscription.unsubscribe();

        }, error => {
          console.log(error);
          
          // this.messageService.add({ key: 'form',severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail});

          if (error.error.name=='This field must be unique.') {
            this.messageService.add({ key: 'form',severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Supplier name cannot be used twice'});


          }


          this.loading = false;
          subscription.unsubscribe();
          this.vendorsService.saveFormClicked.next(null)

        });
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }
  }
  private updateCustomerEmiiter(): void {
    try {
      const subscription = this.vendorsService.upadteFormClickedEmitter.subscribe(status => {

        if (!isSet(status) || !status) {
          return;
        }
        this.updateSupplier()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);


    } catch (error) {
      this.log(error);
    }

  }

  updateSupplier() {
    if (this.updateStatus == false) {

      this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

    } else {
      try {
        this.loading = true;
        const subscription = this.vendorsService.updateSupplier(this.supplier).subscribe((supplier: Supplier) => {

          if (!isSet(supplier)) {
            return;
          }
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
          this.loading = false;
          subscription.unsubscribe();
          this.vendorsService.refreshVendors();
          this.vendorsService.upadteFormClicked.next(null)
          this.updateStatus = false

        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          this.loading = false;
          subscription.unsubscribe();
          this.vendorsService.upadteFormClicked.next(null)
          this.updateStatus = false

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }
  }

  notempty(event) {
      this.SharedService.chekcempey.next(true)
    this.updateStatus = true

  }
  Save_form() {


    this.SharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.SharedService.Save_object(this.supplier)
      this.SharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }


  getSavedSupplier() {

    let subscription: Subscription = new Subscription();

    subscription = this.SharedService.Saved_object_Emitter.subscribe((supplier: any) => {
      if (!isSet(supplier)) {
        return;
      }
      if (supplier.url == this.router.url) {
        this.supplier = supplier.object
      }
      if (isSet(this.SharedService.Createform.value)) {
        this.SharedService.objectstauts.next(true)
      } else {
        this.SharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }

  clearAll() {
    this.SharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }
      this.initsupplier()
      this.SharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }
  outside_Status(status: boolean) {
    this.SharedService.dialog_status.next(status)
  }
  listCounties() {
    const subscription = this.auth.countries().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.countries = CountryCode_currency.cloneManyObjects(countryCode_currency)
      
      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
}
