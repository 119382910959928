import { Component, OnInit, ViewChild, } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { Product } from '../../../models/product';
import { ProductService } from '../../products/product.service';
import { OrderItem } from '../../../models/orders/orderitem';
import { Unit } from '../../../models/unit';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { CreditNote } from 'src/app/models/CreditNotesSupplier';
import { Order } from 'src/app/models/orders/order';
import { creditnotessupplireService } from '../creditnotessupplier.service';
import { Router } from '@angular/router';
import { Vat_Product } from 'src/app/models/vat-product';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-creditnotessupplier',
  templateUrl: './creditnotessupplier.component.html',
  styleUrls: ['./creditnotessupplier.component.scss'],
  providers: [MessageService]


})
export class creditnotessupplireComponent extends BaseComponent implements OnInit {
  CreditNote: CreditNote;
  products: Product[] = [];
  units: Unit[] = [];
  productTouched:boolean = false
  VendorOrder :boolean =false
  showPaymentDialog = false
  import_orderstate: boolean
  orderformat:string
  currenyType:string
  constructor(private creditnotesService: creditnotessupplireService, public translate:TranslateService,private router: Router,private sharedService : SharedService, private productService: ProductService ,public messageService: MessageService , public primengConfig: PrimeNGConfig) {
    super();
  }

  ngOnInit(): void {

    this.initCreditNote();
    this.getProducts();
    this.getUnits();
    this.getNextinvoice_format();
    this.subscribeOnFormSaved();
    this.subscribeOnOrderSelected();
    this.Save_form()
    this.getSavedCreditNote()
    this.clearAll()
    this.currencyChanged()
   
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
        this.addItem();
      }
    });
 
  }

  addItem() {

    const item = OrderItem.cloneObject(null);
    item.date = new Date();
    item.qty = 1;
    item.price = 0;
    item.vat = new Vat_Product()
    item.vat.value = 0;
    this.CreditNote.items.push(item);
  }
  serach($event){
    if(!isSet($event) ){
       return
     }
  
     this.CreditNote =$event
  this.orderformat =this.CreditNote.order_format
this.import_orderstate = true


      }

      selectvendor($event){
        if(!isSet($event) ){
           return
         }
         const vndor  = $event
         this.CreditNote.vendor= vndor
       }

   
  productChanged(event, index) {
    try {
      const product = event as Product;
      this.CreditNote.items[index].price = product.sell_price || 0;
      this.CreditNote.items[index].vat = product?.vat?.value;
    }
    
    catch (error) {
      this.log(error);

    }
  }

  addOrder(): void {
    let noerror : boolean
    this.CreditNote.items.map(ee=>{
if((!isSet(ee.product))  || (!isSet(this.CreditNote.vendor)|| (!isSet(this.CreditNote.order_format)))){
  this.productTouched=true
  this.VendorOrder=true
  this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS')});
  return  noerror = false 
}else{
 return noerror = true 

} })
  if(noerror == true) {
    try {  
      this.loading = true;
      const subscription = this.creditnotesService.addCredit_notes(this.CreditNote).subscribe((creditNote: CreditNote) => {

        if(!isSet(creditNote)) {
          return;
        }
        this.CreditNote = creditNote;
        this.creditnotesService.currentCreditNote = CreditNote.cloneObject(creditNote)
        this.messageService.add({severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'New order added successfully'});
        this.loading = false;
this.creditnotesService.refreshDataTable()
subscription.unsubscribe();
this.sharedService.clearSave.next(true)

    },error => {
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error});
  

this.loading = false;
subscription.unsubscribe();
 this.creditnotesService.saveFormClicked.next(null)
  })
} catch (error) {
  this.loading = false;
  this.log(error);
 
}
}  

  }

  private subscribeOnFormSaved() {
    const subscription = this.creditnotesService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if(!isSet(status) || !status) {
        return;
      }

      this.addOrder();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  private subscribeOnOrderSelected() {
    const subscription = this.creditnotesService.CreditNoteSelectedListEmitter.subscribe((creditNote: CreditNote) => {
      if(!isSet(creditNote)) {
        return 
      }

      this.getCreditNote(creditNote.id);
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
    this.creditnotesService.selectCredit_notes(null)
  }

  private initCreditNote() {
    this.CreditNote = CreditNote.cloneObject(null);
    this.CreditNote.items = [];
    this.addItem();
    let curruncyInit=JSON.parse(localStorage.getItem('currentCurrncy'))
    this.currenyType=curruncyInit.sign
  }

  private getCreditNote(id: string) {
    this.loading =true
    const subscription = this.creditnotesService.getCredit_note(id).subscribe((creditNote: CreditNote) => {
      if (!isSet(creditNote)) {
        return;
      }
      console.log(creditNote);
      
      const cloneObject = creditNote;
      let items  : OrderItem[] = []

        // this.creditnotesService.getproducts( e).subscribe((prdocut:any)=>{
        //   if (!isSet(prdocut)) {
        //     return;
        //   }
        // })
      
   
        if(this.products.length) {
          for (const item of cloneObject.items) {
            item.datePipe = new Date(item.date);
            if(item.product_id) {
              console.log(item.product_id);
              
                item.product = this.products.find(prod => {
                    return prod.id === item.product_id;
              })
            }
          }  }
          this.CreditNote = cloneObject;
      


      subscription.unsubscribe();
      this.loading =false

    }, error => {
      subscription.unsubscribe();
      this.loading =false

    });
  }

  private getProducts() {
    const subscription = this.productService.getProducts().subscribe((products: PaginatedResponse<Product[]>) => {
      if (!isSet(products)) {
        return;
      }
      this.products = Product.cloneManyObjects(products.results);

      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    }, error => {
      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    });
  }

  private getUnits() {
    const subscription = this.productService.getUnits().subscribe((units: PaginatedResponse<Unit[]>) => {
      if (!isSet(units)) {
        return;
      }

      this.units = Unit.cloneManyObjects(units.results);

      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    }, error => {
      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    });
  }

  private getNextinvoice_format() {
    const subscription = this.creditnotesService.getNextinvoice_format().subscribe((data: { order_format: string }) => {
      if (!isSet(data)) {
        return;
      }

      if(!isSet(this.CreditNote.order_format)) {
        this.CreditNote.order_format = data.order_format;
      }

      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    }, error => {
      subscription.unsubscribe();
      this.subscriptions.push(subscription);

    });
  }
  Save_form(){
  
    this.sharedService.Saveform_Observable_state.subscribe(status=>{
    
      if(!isSet(status) || !status) {

        return;
      }
    this.sharedService.Save_object(this.CreditNote)
    this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
    }
    getSavedCreditNote(){
  
      let subscription: Subscription = new Subscription();
    
      subscription =this.sharedService.Saved_object_Emitter.subscribe((creditNote: any) => {
        if(!isSet(creditNote)) {
          this.sharedService.objectstauts.next(null)
          return;
        }
        if(creditNote.url == this.router.url){
          this.CreditNote = creditNote.object
          this.sharedService.objectstauts.next(true)

        }else {
          this.sharedService.objectstauts.next(null)
  
        }
    }, error => { 
        this.handleError(error);
      });
      subscription.unsubscribe()
    
    }
    clearAll(){
      this.sharedService.clearAll_Emitter.subscribe(status=>{
    
           if(!isSet(status)){
        return
      }

   this.initCreditNote()
   this.sharedService.clearAll.next(null)}

    , error => {
        this.handleError(error);
      });
    }
    currencyChanged(){
      const subscription = this.creditnotesService.currencyChangedEmitter.subscribe(type=>{
        if(!isSet(type)){
          return
        }
        this.CreditNote.currency=type
        if(this.CreditNote.currency == '$'){
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'The currancy has been changed to Dollar ' + this.CreditNote.currency });
        }
        if(this.CreditNote.currency == '€'){
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'The currancy has been changed to Erou ' + this.CreditNote.currency});
        }
        if(this.CreditNote.currency == '£'){
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'The currancy has been changed to Pound sterling '+ this.CreditNote.currency });
        }
        this.currenyType=this.CreditNote.currency
        
        this.creditnotesService.currencyChanged.next(null)
      },error=>{console.log(error)})
      this.subscriptions.push(subscription)
    }
}
