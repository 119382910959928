import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Customer } from 'src/app/models/customer';
import { Summaries } from 'src/app/models/orders/summaries';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { Query } from 'src/app/models/query';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { isSet } from 'src/app/modules/core/util';
import { PurchaseService } from '../purchase.service';
@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss']
})
export class PurchaseListComponent extends BaseComponent implements OnInit {


  constructor(
    private purchaseService: PurchaseService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private messageService: MessageService,
    private router: Router) {
    super()
  }
  form_date: Date
  to_date: Date = new Date()
  invoices: PurchaseOrders[] = []
  summaries = new Summaries()
  total_Net_Invoices = 0
  total_vat_Invoices = 0
  total_vat_6_Invoices = 0
  total_vat_12_Invoices = 0
  total_vat_21_Invoices = 0
  total_incVat_Invoices = 0
  inovice_count = 0

  total_Net_cc = 0
  total_vat_cc = 0
  total_vat_6_cc = 0
  total_vat_12_cc = 0
  total_vat_21_cc = 0
  total_incVat_cc = 0
  cc_count = 0


  total_Net_all = 0
  total_vat_all = 0
  total_vat_6__all = 0
  total_vat_12_all = 0
  total_vat_21_all = 0
  total_incVat_all = 0
  count_all = 0
  public fillterCustomers: Customer[] = [];

  statuses = [
    { status: { paid_status: 'Paid', paid_color: 'Paid' } },
    { status: { paid_status: 'UnPaid', paid_color: 'UnPaid' } },

  ]

  update_status = false
  default_currency: string
  downloadtypes = []
  index = 1
  filterModeinvoice_format_query = new Query()
  filterModeStatus_query = new Query()
  filterModeDate_query = new Query()
  filterModeTotal_query = new Query()
  filterModePaid_status = new Query()



  ngOnInit(): void {
    this.downloadtypes = [
      {
        label: this.translate.instant('EXPORT_ZIP'), command: () => {
          this.downloadSaleszip()

        },

      },
      {
        label: this.translate.instant('EXPORT_XLS'), command: () => {
          this.download_salesxls()

        },

      },
      {
        label: this.translate.instant('EXPORT_CSV'), command: () => {
          this.download_salescsv()

        },

      }
    ];

    this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency?.name

  }

  searchSales() {
    if (!isSet(this.form_date) && !isSet(this.form_date)) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('FROM_TO_EMMPTY') });

    }
    if (!isSet(this.form_date)) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('FROM_EMPTY') });

    }
    if (!isSet(this.to_date)) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('TO_EMPT') });

    }
    if (this.form_date > this.to_date) {
      return this.messageService.add({ key: 'form', life: 4000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('DATE_GREAT_TO') });

    }
    this.loading = true
    let form = this.datePipe.transform(this.form_date, 'yyyy-MM-dd')
    let to = this.datePipe.transform(this.to_date, 'yyyy-MM-dd')
    const subscription = this.purchaseService.getpurchases(form, to).subscribe((purchases: PaginatedResponse<PurchaseOrders[]>) => {

      this.invoices = PurchaseOrders.cloneManyObjects(purchases.results);

      this.invoices.map(e => {
        if (isSet(e.invoice_date)) {
          let datefillter = new Date(e.invoice_date)
          e.invoice_date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
        }
        e.sub_total = e.total - e.total_vat
    




      })
      // remove duplicate form customers fillter
      // let rmAduplicate = []
      // this.fillterCustomers.forEach((item) => {
      //   if (rmAduplicate.findIndex(i => i == item) === -1) {
      //     rmAduplicate.push(item)
      //   }
      // });
      this.summaries = new Summaries()
      this.summaries = purchases.summaries
      this.summaries.invoices.vat = []
      for (const prop in this.summaries?.invoices) {
        if (prop.includes("total_vat_")) {
          let vat = {
            value: this.summaries.invoices[prop],
            name: Number(prop.split('vat_')[1])
          }
          this.summaries.invoices.vat.push({ vat })
        }

      }
      this.summaries.total = this.summaries.invoices


      this.loading = false

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  downloadSaleszip() {
    this.loading = true

    if (this.form_date > this.to_date) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('DATE_GREAT_TO') });

    }
    let form = this.datePipe.transform(this.form_date, 'yyyy-MM-dd')
    let to = this.datePipe.transform(this.to_date, 'yyyy-MM-dd')

    const subscription = this.purchaseService.download_purchasesZip(form, to).subscribe(response => {
      this.loading = false

      this.downLoadFile(response, "application/zip", `${form}_to_${to}Invoices`)
      this.messageService.add({ key: 'form', life: 3000, severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_INVO_DOWN') });
      subscription.unsubscribe()

    }, error => {

      this.messageService.add({ key: 'form', severity: 'info', life: 3000, summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_INVO_EXPORT') });
      subscription.unsubscribe()

    })


  }

  download_salesxls() {
    this.loading = true
    if (this.form_date > this.to_date) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('DATE_GREAT_TO') });

    }
    let form = this.datePipe.transform(this.form_date, 'yyyy-MM-dd')
    let to = this.datePipe.transform(this.to_date, 'yyyy-MM-dd')

    const subscription = this.purchaseService.download_purchasesxls(form, to).subscribe(response => {
      this.loading = false

      this.downLoadFile(response, "application/vnd.ms-excel", `${form}_to_${to}Invoices`)
      this.messageService.add({ key: 'form', life: 3000, severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_INVO_DOWN') });
      subscription.unsubscribe()

    }, error => {
      console.log(error);

      this.messageService.add({ key: 'form', severity: 'info', life: 3000, summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_INVO_EXPORT') });
      subscription.unsubscribe()

    })


  }
  download_salescsv() {
    this.loading = true

    if (this.form_date > this.to_date) {
      return this.messageService.add({ key: 'form', life: 3000, severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('DATE_GREAT_TO') });

    }
    let form = this.datePipe.transform(this.form_date, 'yyyy-MM-dd')
    let to = this.datePipe.transform(this.to_date, 'yyyy-MM-dd')

    const subscription = this.purchaseService.download_purchasescsv(form, to).subscribe((response: any) => {
      this.loading = false

      this.downLoadFile(response, "text/csv;charset=utf-8;", `${form}_to_${to}Invoices`)




      this.messageService.add({ key: 'form', life: 3000, severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEL_INVO_DOWN') });
      subscription.unsubscribe()

    }, error => {

      this.messageService.add({ key: 'form', severity: 'info', life: 3000, summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_INVO_EXPORT') });
      subscription.unsubscribe()

    })


  }
  show_vat(items_vats): string {

    let temp: string = ``;

    if (!isSet(items_vats)) {
      return
    }

    for (const prop in items_vats) {

      temp += `<span>${prop}  : ${items_vats[prop]} </span><br>`;

    }

    return temp;
  }
  notempty(event) {
    if (isSet(event)) {
      this.update_status = true
    }
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }
  showInvoices(selectedInovice: PurchaseOrders) {
    this.router.navigateByUrl('buy/purchaseorders')
    this.purchaseService.showpurchaseOrders.next(selectedInovice)
  }

  downLoadFile(data: any, type: string, filename) {

    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)

  }


}
