<app-loading *ngIf="loading"></app-loading>
<p-toast key="myKey1" key="list" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-12">
    <div class="card">
      <app-table-header  (Reload)='Reload()' (remove)="RemovedialogueS = true" (add)="showAddTax()" addLabel=' {{"NOTES_CUSTOMERS_LIST_CREATE" | translate}}'>
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredProducts" [field_name]="keyword"  (filter)="serach($event)" >
          
        </app-Search-select>      
      </app-table-header>
      <p-table styleClass="p-datatable-striped" [paginator]="true" [rows]="10" [scrollable]="true" 
      [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [value]="Tax" [(selection)]="selected" [scrollable]="true" >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th  pSortableColumn="name">{{"CUSTOMER_FORM_NAME" | translate}}<p-sortIcon field="name"></p-sortIcon></th>
            <th>{{"TAX_LIST_VALUE" | translate}}</th>
                </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox (click)="seletedTax(rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showtax(rowData)" href="javascript:;">{{ rowData.name }}</a></td>
            <td>{{ rowData.value }}</td>
                  </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"[draggable]="false"  class="p-shadow-1" header='{{"TAX_LIST_REMOVE_TAX" | translate}}' [(visible)]="RemovedialogueS" [style]="{width: '30vw'}">
  <div class="p-text-justify ">{{"CUSTOMER_LIST_DELETE_CONFIRM" | translate}}</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="RemoveTax()" label=' {{"QUOTES_LIST_YES" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="RemovedialogueS=false" label='{{"QUOTES_LIST_NO" | translate}}' class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<app-sidebar
  [(visible)]="showSidebar"
  [title]="modalTitle"
  [size]="size">
  <div header>
    <button pButton (click)="saveForm()"  type="button" label=' {{"CUSTOMER_LIST_SAVE" | translate}}' class="ui-button-raised ui-button-info"></button>
  </div>
  <div body>
    <app-Tax-form *ngIf="showSidebar"></app-Tax-form>
  </div>
</app-sidebar>

