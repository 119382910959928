import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import * as _ from 'lodash';
import { VatInfo, VatInfoCountry } from '../../auth/models/vatinfo';
import { ApiService } from '../../core/api.service';
import { Dropdown } from 'primeng/dropdown';
import { SharedService } from '../shared.service';
import { Supplier } from 'src/app/models/supplier';
import { VendorsService } from '../../suppliers/suppliers.service';
import { Address } from 'src/app/models/address';
import { Contact } from 'src/app/models/contact';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {ExpenseService} from "../../expense/expense.service";

@Component({
  selector: 'app-supplier-select',
  templateUrl: './supplier-select.component.html',
  styleUrls: ['./supplier-select.component.scss']
})
export class suppliersSelectComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('dd') dd: Dropdown;
  @ViewChild('supplier_name') supplier_name: any
  @Input() supplier = new Supplier()
  @Input() floatSupplier: boolean
  @Input() customstyle: boolean
  @Input() width: any
  @Input() append: string;
  @Input() filterMode: boolean = false
  @Input() serach_Vendor: boolean = true



  supplierselected: boolean
  @Output() supplierChange = new EventEmitter<Supplier>();
  @Output() onHide = new EventEmitter<any>();

  endload: boolean = false

  filteredVendors: Supplier[] = [];
  supplierss: Supplier[] = []
  supplier_nametouched = false
  addSupplierLoading: boolean;
  displayAddsupplier: boolean;
  vatBtnDisabled: boolean;
  miniSupplier = new Supplier();
  DropdownonFocus_Stuats: boolean
  serach: string
  public contact = new Contact()
  public moredetail_contact = false
  public showContactDialog = false;
  @ViewChild('email') email: any;
  constructor(
    private api: ApiService,
    private sharedService: SharedService,
    public messageService: MessageService,
    public translate: TranslateService,
    private suppliersService: VendorsService,
    private expenseService: ExpenseService,

) {
    super();
  }


  ngOnInit(): void {


    this.getSupplier();
    this.openDropdown()
    setTimeout(async () => {
      this.initDropdown();
    }, 200);

    if (this.serach_Vendor == true) {

      this.supplierselected = true
      this.serachVendors(this.supplier?.name)
    }
   

  }
  ngAfterViewInit() {

    if (this.append !== 'body' && (this.filterMode == false && !isSet(this.supplier))) {


      const openDropdown = setInterval(() => {
        if (this.endload == true) {

          // this.dd.containerViewChild.nativeElement.click()

          if (this.DropdownonFocus_Stuats === true) {

            this.DropdownonFocus_Stuats = false
            clearInterval(openDropdown);

          }

        }
      }, 1500)

      this.expenseVatEmmiter()

    }




  }

  normalize_VAT(event) {
    this.miniSupplier.vat = event.replace(/\s/g, '')
    this.miniSupplier.vat = this.miniSupplier.vat.replace(/\./g, "")

  }
  supplierChanged($event) {
    const supplier = $event.value as Supplier;
    if (!isSet(supplier)) {
      this.supplierselected = false
      return;
    }
    this.supplierselected = true

    if (supplier.id === '0') {
      this.showAddminiSupplier('');
      return;
    }
    if (supplier.id === '-1') {
      this.showAddminiSupplier(supplier.name);
      return;
    }
    this.retriveSupplier(supplier.id)
    this.sharedService.chekcempey.next(true)
  }

  closeminiSupplierModal() {
    this.supplier = new Supplier();
    this.supplierselected = false
    this.displayAddsupplier = false;
    this.outside_Status(false)
  }
  public addContact(): void {
    if (this.email.control.invalid) {

      this.email.control.markAsTouched();


      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.miniSupplier.contacts = []
      this.miniSupplier.contacts.push(this.contact)
      this.supplier.contacts = []
      this.supplier.contacts.push(this.contact)


      this.email.control.markAsUntouched()
      this.email.control.clearValidators()
      this.showContactDialog = false;


    }
  }
  more_detail() {
    if (this.DropdownonFocus_Stuats == false) {
      this.DropdownonFocus_Stuats = true
    } else if (this.DropdownonFocus_Stuats == true) {
      this.DropdownonFocus_Stuats = false

    }

  }
  addminiSupplier() {

    if (!isSet(this.miniSupplier?.name) || this.miniSupplier.name == '') {
      this.supplier_name?.control.markAsTouched();
      this.supplier_nametouched = true
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('PLZ_FILL_NAME_REQ')
      });

    } else {
      this.addSupplierLoading = true;
      const subscription = this.suppliersService.addSupplier(this.miniSupplier).subscribe((supplier: Supplier) => {
        if (!isSet(supplier)) {
          return;
        }
        try {


          this.supplierss = []
          const x = setInterval(() => {

            this.getSupplier()
            if (this.supplierss[1]?.id == supplier?.id) {
              this.supplier = this.supplierss[1]
              this.supplierChange.emit(this.supplierss[1]);

              clearInterval(x);

            }
          }, 300)


          this.dd.optionsToDisplay.push({ label: this.supplier.name, value: this.supplier });

          this.dd.containerViewChild.nativeElement.click()
          this.dd.containerViewChild.nativeElement.click()


          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_SUP_ADDED')
          });
          this.supplier_name?.control.markAsUntouched()
          this.supplier_name?.control.clearValidators()
          this.supplier_nametouched = false

        } catch (e) {
        } finally {
          this.addSupplierLoading = false;
          this.displayAddsupplier = false;
          subscription.unsubscribe();
        }
      }, (error) => {
        if (error.error.name == 'This field must be unique.') {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Supplier name cannot be used twice' });


        }
        this.addSupplierLoading = false;
        subscription.unsubscribe();
      });
    }
    this.outside_Status(false)

  }
  notempty(event) {

    this.supplier_nametouched = false
  }
  getDataFromVat(): void {
    if (this.miniSupplier.vat.length < 9) {
      return
    }

    this.vatBtnDisabled = true;
    const subscription = this.api.postGuest('/extract_vat_info', {
      vat_number: this.miniSupplier.vat
    }).subscribe((vatInfo: VatInfo) => {
      if (!isSet(vatInfo)) {
        return;
      }
      this.miniSupplier.name = vatInfo.name;
      const address = vatInfo.address.split('\n');
      if (address.length >= 2) {
        this.miniSupplier.address = []
        this.miniSupplier.address[0] = new Address()
        this.miniSupplier.address[0].address = address[0]
        const zip = address[1].split(' ')
        if (zip.length >= 2) {
          this.miniSupplier.address[0].zip = zip[0]
          this.miniSupplier.address[0].city = zip[1]
        }

        if (isSet(VatInfoCountry[vatInfo.countryCode])) {
          this.miniSupplier.address[0].country_code = VatInfoCountry[vatInfo.countryCode];
        }
      }
    }, error => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    }, () => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    });
  }

  hide($event) {
    if (this.supplier && this.supplier?.id !== '-1') {
      this.onHide.emit($event);
    }

  }

  private showAddminiSupplier(name: string) {
    this.supplier = null
    this.miniSupplier = new Supplier();
    this.miniSupplier.name = name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('ADD_NEW_SUP') + ')</span>', '')
    this.miniSupplier.address = []
    this.miniSupplier.address[0] = new Address();
    this.miniSupplier.contacts = []
    this.displayAddsupplier = true;
    setTimeout(() => {
      this.supplier = new Supplier();

    }, 200);
  }

  private getSupplier(): void {

  
    const subscription = this.suppliersService.getVendors().subscribe((suppliers: PaginatedResponse<Supplier[]>) => {
      if (!isSet(suppliers)) {
        return;
      }
      this.supplierss = Supplier.cloneManyObjects(suppliers.results);
      
      



      if (this.filterMode == false) {
        const addvendor = new Supplier();
        addvendor.id = '0';
        addvendor.name = '<span class="add-quick-option">' + this.translate.instant('ADD_NEW_SUP') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.supplierss = insert(this.supplierss, 0, addvendor)

        this.endload = true

      }
      setTimeout(() => {
        this.filteredVendors = this.supplierss;
      }, 300);

      if (this.filteredVendors.length == 0) {
       this.getSupplier()   
      }
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }

  private initDropdown(): void {
    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;



      if (this.serach != value) {
        return this.serachVendors(value.toLocaleLowerCase())
      }
    };
  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  onFocus() {
    this.DropdownonFocus_Stuats = true

  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }
  expenseVatEmmiter(){
    const subscription = this.expenseService.filterVatEmitter.subscribe((vat) => {
      if (!isSet(vat) ) {
      return
      }
      console.log('pass')
      this.filterByVat(vat)
      this.expenseService.filterVat.next(null)
    },error => {
      console.log(error)
    });
    this.subscriptions.push(subscription);
  }
  private filterByVat(vat) {
    const subscription = this.suppliersService.filterByVat(vat).subscribe((suppliers) => {
      if (!isSet(suppliers)) {
        return;
      }
      console.log(suppliers)
      if (suppliers.count ==0){
        this.miniSupplier.vat=vat
        this.displayAddsupplier=true
        this.getDataFromVat()
        this.messageService.add({ key: 'formM', severity: 'info', detail: "This Vat's supplier doesn't exist" ,life: 3000});
      }else {
        this.supplierss = Supplier.cloneManyObjects(suppliers.results);
        this.filteredVendors = this.supplierss;
        this.supplier = suppliers.results[0]
        setTimeout(async () => {
          await this.supplierChange.emit(this.supplier);
        },0)
      }

      console.log(this.supplier)
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  serachVendors(value: any) {

    const subscription = this.suppliersService.serachSupplier(value).subscribe((suppliers: PaginatedResponse<Supplier[]>) => {

      if (!isSet(suppliers)) {
        return;
      }

      this.supplierss = Supplier.cloneManyObjects(suppliers.results);
      this.filteredVendors = this.supplierss;
      this.endload = true

      if (this.serach_Vendor == true) { this.supplier = suppliers.results[0] }


      if (this.filterMode == false) {
        const addCustomer = new Supplier();
        addCustomer.id = '-1';
        addCustomer.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('ADD_NEW_SUP') + ')</span>';

        this.filteredVendors.push(addCustomer)
      }




      this.dd.optionsToDisplay = this.filteredVendors;

      this.serach = this.dd.filterValue
      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();
    });
  }
  preventDefault(event, element) {
    const el = element.model
    if (!el.replace(/\s/g, '').length) {
      event.preventDefault();
    }
  }

  openDropdown(){
    const subscription = this.sharedService.openDropdownEmitter.subscribe((status) => {
      if (!isSet(status) ) {
      return
      }
      setTimeout(()=>{
        this.dd.focus()
        this.dd.show()
      },0)
      this.sharedService.openDropdown.next(null)
    },error => {
      console.log(error)
    });
    this.subscriptions.push(subscription);
  }
  retriveSupplier(id){
    const subscription = this.suppliersService.getSupplier(id).subscribe((supplier: Supplier) => {
      if (!isSet(supplier)) {
        return;
      }
      this.supplierChange.emit(supplier);
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
}