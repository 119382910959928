import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { CustomerService } from '../customer.service';
import { Customer } from '../../../models/customer';
import { isSet } from '../../core/util';
import { TranslateService } from '@ngx-translate/core';
import { Order } from 'src/app/models/orders/order';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { SharedService } from '../../shared/shared.service';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { Product } from 'src/app/models/product';
import { Quote } from 'src/app/models/quotes/quote';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { OrdersService } from '../../orders/orders.service';
import { MessageService } from 'primeng/api';
import { QuotesService } from '../../quotes/quotes.service';
import { MenuService } from 'src/app/shared/menu.service';
import { OrderStatus } from 'src/app/models/orders/orderstatus';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent extends BaseComponent implements OnInit {

  public products = [
    { title: 'Product 1', date: '04/05/2020' },
    { title: 'Test Product 2', date: '04/05/2020' },
    { title: 'consectetur adipisicing elit', date: '04/05/2020' },
    { title: 'aperiam atque culpa', date: '04/05/2020' },
    { title: 'molestias odit', date: '04/05/2020' },
    { title: 'Lorem ipsum dolor', date: '04/05/2020' },
    { title: 'Product 1', date: '04/05/2020' },
    { title: 'Test Product 2', date: '04/05/2020' },
    { title: 'consectetur adipisicing elit', date: '04/05/2020' },
    { title: 'aperiam atque culpa', date: '04/05/2020' },
    { title: 'molestias odit', date: '04/05/2020' },
    { title: 'Lorem ipsum dolor', date: '04/05/2020' },
  ];
  orders: Order[] = []
  invoices: Order[] = []
  quotes: Quote[] = []
  mostSolid_Product: any[] = []
  last_customer_order: Order[] = []
  LangStats = new OrderStatus()
  dropdownYears: any[];
  selectedYear: any;
  lineChartData: any;
  productsTimeline: any;
  all_quote_counts: number = 0
  all_order_counts: number = 0
  purchasesCount: number = 0
  invoiced_order_count: number = 0
  lineChartOptions: any;
  customer = new Customer()

  sidebarName: string
  selected: any
  size: string
  modalTitle: string
  header_body: string
  statusTitle: string
  showSidebar: boolean = false
  itemsSave = []

  lastInvoiced_order: string
  loadingbtnPrint: boolean
  loadingbtnemail: boolean
  removedialoguee: boolean
  disableUnlockInvoice: boolean

  // orders
  getNextPage_outOrder = 0
  disablePagetion_outOrde: boolean
  outstandorder_inPage = 0
  outstandorder_pageViwed = []
  outstandorder_Count: any
  outstandorder_NextPage: any = 0
  outstandorder_Currentnumber: any
  outstandorder_PrevPage: any

  //invoices
  getNextPage_outinvoice = 0
  disablePagetion_outinoive: boolean
  outstandinoive_inPage = 0
  outstandinoive_pageViwed = []
  outstandinoive_Count: any
  outstandinoive_NextPage: any = 0
  outstandinoive_Currentnumber: any
  outstandinoive_PrevPage: any

  //qoutes
  getNextPage_outqoutes = 0
  disablePagetion_outqoutes: boolean
  outstandqoutes_inPage = 0
  outstandqoutes_pageViwed = []
  outstandqoutes_Count: any
  outstandqoutes_NextPage: any = 0
  outstandqoutes_Currentnumber: any
  outstandqoutes_PrevPage: any

  pieData = {
    labels: ['A', 'B', 'C', 'D'],
    datasets: [
      {
        data: [540, 325, 702, 421],
        backgroundColor: [
          '#81A1C1',
          '#EBCB8B',
          '#88C0D0',
          '#D08770'
        ]
      }]
  };
  @ViewChild('outstand_order') outstand_order: any;
  @ViewChild('op3') op3: any;



  constructor(private customerService: CustomerService,
    private saredService: SharedService,
    private ordersService: OrdersService,
    private quotesService: QuotesService,
    public translate: TranslateService,
    private menuService: MenuService,
    private sharedService: SharedService,
    private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnCusomterSelected();


    this.lineChartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Amounts',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#45b0d5'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Payments',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.productsTimeline = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Product 1',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#45b0d5'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Product 2',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
      hover: {
        mode: 'index'
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }]
      },
      legend: {
        display: true,
        labels: {
          fontColor: '#9199a9'
        }
      }
    };
    this.dropdownYears = [
      { label: '2020', value: 2020 },
      { label: '2019', value: 2019 },
      { label: '2018', value: 2018 },
    ];

    this.itemsSave = [
      {
        label: this.translate.instant('SAVE_PRINT'), icon: 'fas fa-print', command: () => {
          this.updateOrder()
          this.print_order()
        }
      },
      {
        label: this.translate.instant('SAVE_EAMIL'), icon: 'far fa-envelope', command: () => {
          this.updateOrder()
          this.sendByEmail_order()
        }
      },
      {
        label: this.translate.instant('SAVE_INVOICE'), icon: 'fas fa-file-invoice', command: () => {

          this.updateOrder()
          this.convertInvoice()

        }
      },

    ]

  }

  private subscribeOnCusomterSelected() {

    const subscription = this.customerService.customerSelectedEmitter.subscribe((customer: Customer) => {
      if (!isSet(customer) || isSet(this.customer?.id)) {
        return

      }
      this.customer = Customer.cloneObject(customer)
      this.get_outstandingOrders()
      this.get_outstandingQoutes()
      this.get_outstanding_invoiced()
      this.get_MostSoldProducts()
      this.get_last_customer_order()


    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  displayitem(item: any, itemName: string) {
    const _item = {
      item,
      itemName
    }
    this.saredService.diplayDash.next(_item)
    this.selected = item
    this.sidebarName = itemName

    if (this.sidebarName == 'Order') {
      this.modalTitle = this.translate.instant('DASHBORD_ORDER') + ': '
      this.header_body = item.format
      this.selected = Order.cloneObject(item)
      if (this.selected?.status?.invoiced) {
        this.getlast_invoiced_order()
      }
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`
      this.size = SidebarSize.x_order

    } else if (this.sidebarName == 'Quotes') {
      this.modalTitle = this.translate.instant('DASHBORD_QUOTE') + ': '
      this.header_body = item.format
      this.selected = Quote.cloneObject(item)
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> `
      this.size = SidebarSize.x_order
    } else if (this.sidebarName == 'Product') {
      this.modalTitle = this.translate.instant('CUSTOMER_LIST_EDIT') + '' + item.name
      this.selected = Product.cloneObject(item)
      this.statusTitle = null
      this.size = SidebarSize.X_LARGE
    } else if (this.sidebarName == 'Custmoer') {
      this.modalTitle = item.company_name
      this.selected = Customer.cloneObject(item)
      this.size = SidebarSize.X_LARGE
      this.statusTitle = null
    } else if (this.sidebarName == 'Purchases') {
      this.modalTitle = item.internal_reference
      this.selected = PurchaseOrders.cloneObject(item)
      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`
      this.size = SidebarSize.X_LARGE
    }
    this.showSidebar = true
  }
  getlast_invoiced_order() {
    const subscription = this.ordersService.getlast_invoiced_order().subscribe((order: Order) => {
      this.lastInvoiced_order = order.id
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
      this.loading = false

    });
  }
  get_outstanding_invoiced() {

    if (!isSet(this.customer.id)) {
      return
    }
    const subscription = this.customerService.get_outstanding_invoiced(this.customer.id).subscribe((inovices: PaginatedResponse<Order[]>) => {
      if (!isSet(inovices)) {
        return;
      }

      this.invoiced_order_count = inovices.count
      this.outstandinoive_pageViwed.push(1)
      this.outstandinoive_Count = inovices.count
      this.invoices = Array(this.outstandinoive_Count).fill(0)

      const clone = Order.cloneManyObjects(inovices.results);
      this.outstandinoive_Currentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.invoices[index] = clone[index];

      }

      subscription.unsubscribe()
    }, error => {
      subscription.unsubscribe()
    })
  }

  load_outstandinginvoiced(event) {

    let row_status = false
    this.outstandinoive_inPage = event.rows + event.first
    row_status = true


    if (this.outstandinoive_pageViwed == this.outstandinoive_Count) {
      return
    }

    const num = (this.outstandinoive_inPage / 30)

    this.outstandinoive_NextPage = this.outstandinoive_inPage / 30




    if (this.outstandinoive_inPage >= this.outstandinoive_Currentnumber) {

      if (this.outstandinoive_NextPage == this.outstandinoive_PrevPage) {
        this.outstandinoive_NextPage = this.outstandinoive_NextPage - 1
        this.outstandinoive_PrevPage = this.outstandinoive_NextPage
      }
      this.outstandinoive_PrevPage = this.outstandinoive_NextPage

      if (this.outstandinoive_pageViwed.includes(this.outstandinoive_NextPage)) {


        return
      } else {
        this.loading = true

        const subscription = this.customerService.get_outstanding_invoicedNext(this.outstandinoive_NextPage, this.customer.id).subscribe((invocies: PaginatedResponse<Order[]>) => {
          if (!isSet(invocies)) {
            return;
          }

          const clone = Order.cloneManyObjects(invocies.results);


          const shown = this.outstandinoive_inPage

          this.outstandinoive_inPage = (this.outstandinoive_NextPage - 1) * 30

          if (this.outstandinoive_inPage > shown && this.outstandinoive_NextPage == this.outstandinoive_PrevPage) {
            this.outstandinoive_inPage = this.outstandinoive_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.invoices[this.outstandinoive_inPage + index] = clone[index];

          }

          this.outstandinoive_pageViwed.push(this.outstandinoive_NextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  get_outstandingOrders() {

    if (!isSet(this.customer.id)) {
      return
    }

    const subscription = this.customerService.get_outstandingOrders(this.customer.id).subscribe((orders: PaginatedResponse<Order[]>) => {

      if (!isSet(orders)) {
        return;
      }

      this.all_order_counts = orders.count
      this.outstandorder_pageViwed.push(1)
      this.outstandorder_Count = orders.count
      this.orders = Array(this.outstandorder_Count).fill(0)
      const clone = Order.cloneManyObjects(orders.results);

      this.outstandorder_Currentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.orders[index] = clone[index];

      }
      subscription.unsubscribe()
    }, error => {
      console.log(error);

      subscription.unsubscribe()
    })
  }
  get_last_customer_order() {
    this.loading = true
    if (!isSet(this.customer.id)) {
      return
    }
    const subscription = this.customerService.get_last_customer_order(this.customer.id).subscribe((orders: PaginatedResponse<Order[]>) => {
      if (!isSet(orders)) {
        return;
      }

      this.loading = false

      this.last_customer_order = Order.cloneManyObjects(orders.results);


      subscription.unsubscribe()
    }, error => {
      subscription.unsubscribe()
    })
  }

  load_outstandingOrder(event) {

    let row_status = false
    this.outstandorder_inPage = event.rows + event.first
    row_status = true


    if (this.outstandorder_pageViwed == this.outstandorder_Count) {
      return
    }

    const num = (this.outstandorder_inPage / 30)

    this.outstandorder_NextPage = this.outstandorder_inPage / 30




    if (this.outstandorder_inPage >= this.outstandorder_Currentnumber) {

      if (this.outstandorder_NextPage == this.outstandorder_PrevPage) {
        this.outstandorder_NextPage = this.outstandorder_NextPage - 1
        this.outstandorder_PrevPage = this.outstandorder_NextPage
      }
      this.outstandorder_PrevPage = this.outstandorder_NextPage

      if (this.outstandorder_pageViwed.includes(this.outstandorder_NextPage)) {


        return
      } else {
        this.loading = true
        const subscription = this.customerService.get_outstandingOrdersNext(this.outstandorder_NextPage, this.customer.id).subscribe((orders: PaginatedResponse<Order[]>) => {
          if (!isSet(orders)) {
            return;
          }

          const clone = Order.cloneManyObjects(orders.results);


          const shown = this.outstandorder_inPage

          this.outstandorder_inPage = (this.outstandorder_NextPage - 1) * 30

          if (this.outstandorder_inPage > shown && this.outstandorder_NextPage == this.outstandorder_PrevPage) {
            this.outstandorder_inPage = this.outstandorder_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.orders[this.outstandorder_inPage + index] = clone[index];

          }

          this.outstandorder_pageViwed.push(this.outstandorder_NextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  updateOrder() {
    this.ordersService.saveForm();
    const subscription = this.ordersService.retrieve_lastOrderEmitter.subscribe((order: Order) => {
      if (!isSet(order)) {
        return
      }

      this.modalTitle = order.format
      order.status.draft = true

      this.selected = Order.cloneObject(order)

      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`
      this.ordersService.retrieve_lastOrder.next(null)
      this.ngOnInit()
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  sendByEmail_order() {
    this.ordersService.sendEmailClicked.next(true)

  }
  print_order() {
    this.loadingbtnPrint = true;

    const subscription = this.ordersService.PrintOrder(this.selected?.id).subscribe((order: any) => {
      if (!isSet(order)) {
        return;
      }

      this.ordersService.printOrderLink.next(order)


      subscription.unsubscribe();
      this.loadingbtnPrint = false;

    }, error => {
      subscription.unsubscribe();

      this.loadingbtnPrint = false;

    });
  }
  convertInvoice() {
    this.ordersService.ConvertInvoiceClicked.next(true)
    this.refreshSelectedOrder()
  }
  refreshSelectedOrder() {
    const subscription = this.ordersService.refreshSelectedEmitter.subscribe((status: boolean) => {

      if (!isSet(status) || !status) {
        return
      }

      this.getOrder_refresh()


      this.ordersService.refreshSelected.next(null)


    }, error => {

      this.handleError(error);
    });
    this.subscriptions.push(subscription);



  }
  getOrder_refresh() {

    const subscription = this.ordersService.getOrder(this.selected?.id).subscribe((order: Order) => {

      if (!isSet(order)) {
        return;
      }

      const cloneObject = Order.cloneObject(order);
      this.selected = cloneObject

      this.statusTitle = `<span  style="margin-right: 5px; " Class="${this.selected?.status?.status?.color}">${this.translate.instant(this.selected?.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selected?.status.status?.paid_color}">${this.translate.instant(this.selected?.status.status?.paid_status)}</span>`
      this.ngOnInit()

      subscription.unsubscribe();

    }, error => {

      subscription.unsubscribe();

    });
  }
  upadteFormClicked_order() {
    this.ordersService.updateFormClicked.next(true)
    this.refreshSelectedOrder()

  }
  deleteOrders() {

    try {
      this.loading = true;
      const subscription = this.ordersService.orderdelete(this.selected.id).subscribe(() => {
        this.loading = false;
        setTimeout(() => {
          this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('OUTSTAND_ORDER') + this.selected?.format + this.translate.instant('DELETED') });
        }, 1000);
        this.ngOnInit();
        subscription.unsubscribe();

      }, error => {
        this.loading = false;
        if (isSet(this.selected)) {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        }
        subscription.unsubscribe();
        this.saredService.dialog_status.next(false)



      })




    }
    catch (error) {
      this.log(error);
    }

    this.removedialoguee = false
    this.showSidebar = false
  }
  outside_Status(status: boolean) {
    this.saredService.dialog_status.next(status)
  }
  duplicateOrder() {
    this.ordersService.duplicateClicked.next(true)
  }
  unlockinvoiced() {
    this.ordersService.unlockInovice.next(true)

  }
  converttoDraft() {
    this.ordersService.convertDraft.next(true)
    this.refreshSelectedOrder()

  }


  get_outstandingQoutes() {

    if (!isSet(this.customer.id)) {
      return
    }
    const subscription = this.customerService.get_outstandingQoute(this.customer.id).subscribe((quotes: PaginatedResponse<Quote[]>) => {
      if (!isSet(quotes)) {
        return;
      }





      this.all_quote_counts = quotes.count
      this.outstandqoutes_pageViwed.push(1)
      this.outstandqoutes_Count = quotes.count
      this.quotes = Array(this.outstandqoutes_Count).fill(0)
      const clone = Quote.cloneManyObjects(quotes.results);

      this.outstandqoutes_Currentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.quotes[index] = clone[index];

      }

      subscription.unsubscribe()
    }, error => {
      subscription.unsubscribe()
    })
  }
  load_outstandingQoutes(event) {

    let row_status = false
    this.outstandqoutes_inPage = event.rows + event.first
    row_status = true


    if (this.outstandqoutes_pageViwed == this.outstandqoutes_Count) {
      return
    }

    const num = (this.outstandqoutes_inPage / 30)

    this.outstandqoutes_NextPage = this.outstandqoutes_inPage / 30




    if (this.outstandqoutes_inPage >= this.outstandqoutes_Currentnumber) {

      if (this.outstandqoutes_NextPage == this.outstandqoutes_PrevPage) {
        this.outstandqoutes_NextPage = this.outstandqoutes_NextPage - 1
        this.outstandqoutes_PrevPage = this.outstandqoutes_NextPage
      }
      this.outstandqoutes_PrevPage = this.outstandqoutes_NextPage

      if (this.outstandqoutes_pageViwed.includes(this.outstandqoutes_NextPage)) {


        return
      } else {
        this.loading = true
        const subscription = this.customerService.get_outstandingQoutessNext(this.outstandqoutes_NextPage, this.customer.id).subscribe((quotes: PaginatedResponse<Quote[]>) => {
          if (!isSet(quotes)) {
            return;
          }

          const clone = Quote.cloneManyObjects(quotes.results);


          const shown = this.outstandqoutes_inPage

          this.outstandqoutes_inPage = (this.outstandqoutes_NextPage - 1) * 30

          if (this.outstandqoutes_inPage > shown && this.outstandqoutes_NextPage == this.outstandqoutes_PrevPage) {
            this.outstandqoutes_inPage = this.outstandqoutes_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.quotes[this.outstandqoutes_inPage + index] = clone[index];

          }

          this.outstandqoutes_pageViwed.push(this.outstandqoutes_NextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  convert() {
    // this.quotesService.convertToOrder(this.ToOrder)
    //     this.outside_Status(false)

  }
  Sendemail() {
    this.quotesService.sendEmail_buuton(true)
  }
  print() {
    this.loadingbtnPrint = true;

    const subscription = this.quotesService.PrintQoute(this.selected.id).subscribe((Quote: any) => {
      if (!isSet(Quote)) {
        return;
      }
      this.quotesService.printqoute_link.next(Quote)
      subscription.unsubscribe();
      this.loadingbtnPrint = false;
    }, error => {
      subscription.unsubscribe();
      this.loadingbtnPrint = false;

    })
  }
  UpdateQoute() {
    this.quotesService.upadteFormClicked.next(true)
  }

  get_MostSoldProducts() {
    if (!isSet(this.customer.id)) {
      return
    }
    const subscription = this.customerService.get_MostSoldProducts(this.customer.id).subscribe((proudcts: PaginatedResponse<any[]>) => {
      if (!isSet(proudcts)) {
        return;
      }


      this.mostSolid_Product = proudcts.results

      subscription.unsubscribe()
    }, error => {
      subscription.unsubscribe()
    })
  }
}
