<app-loading   *ngIf="loading"></app-loading>
<p-sidebar  [(visible)]="visible"
           blockScroll= true
           position="right"
           styleClass="{{size}} my-sidebar"
           [showCloseIcon]="false"
           [closeOnEscape]="false"
            [dismissible]="false"
            (onShow)="onShowSidebar()"

           >
           <div #sidebar>

  <p-toolbar>
    <div class="ui-toolbar-group-left">
      <!-- <a   (click)=" Show_dialog()  "
      class="my-sidebar-toolbar-close" href="javascript:"><i class="pi pi-times"></i></a> -->
      <button style="margin-left: -13px; ; margin-right: 3px;  transform: scale(0.8); " (click)="Show_dialog()" pButton type="button" icon="pi pi-times" class="p-button-rounded close-Sidebar p-button-sm" ></button>

      <span class="my-sidebar-toolbar-title" [innerHTML]="title"></span>
      <span class="my-sidebar-toolbar-title3" [innerHTML]="header_body"></span>
      <span class="my-sidebar-toolbar-title2" [innerHTML]="title2"></span>

    </div>

    <div class="ui-toolbar-group-right p-grid " style="margin-top: 5px;width: '100%';display: flex; justify-content: flex-end; ">

      <button  *ngIf="clearBtn ==true && crater_form_stauts==true " style="margin-right: 0.5em;" (click)="clearAll()"  pButton  type="button" label='{{"SIDE_BAR_CLEAR"| translate}} All'  ></button>
      <ng-content  select="[header]"></ng-content>
    </div>
  </p-toolbar>

  <div class="sidebar-body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>

</p-sidebar>
<!-- [ngClass]="[configSideBar==false ? 'outSideOn' : customSidebar ]"  -->
<div  [style.width]="hidespace+'px'"  class="emptySpace-sidebar" *ngIf="outSidearea==false && visible==true " (click)="Show_dialog()"  ></div>

<p-dialog modal="true" class="p-shadow-1" header='{{"SIDE_BAR_KEEP_FORM"| translate}}' [(visible)]="Showsavedialog" [style]="{width: '30vw'}">
  <div class="p-text-justify ">{{"SIDE_BAR_KEEP_DATA"| translate}} </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="Save_form()" label=' {{"SIDE_BAR_KEEP"| translate}} '></button>
    <button type="button" pButton icon="pi pi-times"  (click)="closeboutton()" label=' {{"SIDE_BAR_CLEAR"| translate}} ' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true"   [draggable]="false" class="p-shadow-1" header='Leave form' [(visible)]="Showkeep_diloge" [style]="{width: '30vw'}">
  <div class="p-text-justify ">{{"chan_not_save"| translate}}</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="Showkeep_diloge=false" label=' {{"SIDE_BAR_KEEP"| translate}} '></button>
    <button type="button" pButton icon="pi pi-times"  (click)="closeupdate_dilgoe()" label=' {{"LEAVE"| translate}} ' class="p-button-outlined"></button>

  </p-footer>
</p-dialog>



