<div class="login-body layout-light">
  <div class="login-content">
      <div class="login-panel">
          <div style="padding-bottom:18%" class="login-panel-content">
              <div class="logo">
                  <img style="transform: scale(1.2)" src="assets/img/logo7.png" alt="mirage-layout" />
              </div>

              <h1 class="p-pt-4">Successfully Verified</h1>
              <p style="line-height: 35px;"> Email successfully verified and Changed. Click the button below to return to the site 
              </p>

              <div style="display: block ; text-align: center;" class="buttons">
                  <button [routerLink]="['/']" routerLinkActive="router-link-active" pButton type="button"
                      label='Return ' class="p-button-raised p-mt-5 p-button-lg"></button>

              </div>
          </div>

      </div>

      <div class="desert"></div>
  </div>
</div>