import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { userRoles } from 'src/app/models/roles';
import { User } from 'src/app/models/user';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { RolesService } from '../../roles/roles.service';
import { SharedService } from '../../shared/shared.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent extends BaseComponent implements OnInit {
  user:User
  role:userRoles[]
  public updateStatus = false
  usernameBtnDisabled = false
  userFirstNameBtnDisabled=false
  userLastNameBtnDisabled=false
  userEmailNameBtnDisabled=false
  userMobileNameBtnDisabled=false
  userRoleNameBtnDisabled=false
  constructor(private sharedService: SharedService,private router: Router,private userService:UserService,private roleService:RolesService) {
    super();
   this.role= roleService.roles
  }

  ngOnInit(): void {
    this.user=this.userService.selectedUser
    this.getSavedUser()
    
    this.Save_form()
  }
  normalize_user(event) {
    this.user.username = event?.replace(/\s/g, '')
    this.user.username = this.user?.username?.replace(/\./g, '').toUpperCase()
   // this.sharedService.chekcempey.next(true)
    this.updateStatus = true
  }
  getDataFromUser(): void {
    if (this.user.username.length < 9) {
      return
    }

    this.usernameBtnDisabled = true;
    this.userFirstNameBtnDisabled=true;
    this.userLastNameBtnDisabled=true;
    this.userEmailNameBtnDisabled=true
    this.userMobileNameBtnDisabled=true
    this.userRoleNameBtnDisabled=true
    // const subscription = this.api.postGuest('/extract_vat_info', {
    //   vat_number: this.customer.vat
    // }).subscribe((vatInfo: VatInfo) => {
    //   if (!isSet(vatInfo)) {
    //     return;
    //   }
    //   this.customer.company_name = vatInfo.name;
    //   const address = vatInfo.address.split('\n');
    //   if (address.length >= 2) {
    //     this.customer.invoicing_address.address = address[0]
    //     const zip = address[1].split(' ')
    //     if (zip.length >= 2) {
    //       this.customer.invoicing_address.zip = zip[0]
    //       this.customer.invoicing_address.city = zip[1]
    //     }
    //     if (isSet(VatInfoCountry[vatInfo.countryCode])) {
    //       this.customer.invoicing_address.country_code = VatInfoCountry[vatInfo.countryCode];
    //     }
    //   }
    // }, error => {

    //     this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: "Can't find vat information" });
    
    //   this.usernameBtnDisabled = false;
    //   subscription.unsubscribe();
    // }, () => {
    //   this.usernameBtnDisabled = false;
    //   subscription.unsubscribe();
    // });
  }
  getdataFromRoles(){
    this.role= this.roleService.roles
  }
  preventDefault(event, element) {
    const el = element.model
    if (!el.replace(/\s/g, '').length) {
      event.preventDefault();
    }
  }
  
  notempty(event) {
    this.sharedService.chekcempey.next(true)
    this.updateStatus = true
    this.sharedService.update_form.next(true)

  }
  getSavedUser() {
    const subscription = this.sharedService.Saved_object_Emitter.subscribe((user: any) => {
      console.log(user);
      
      if (!isSet(user)) {
        return;
      }
      
      if (user.url === this.router.url) {
        this.user = user.object
        this.sharedService.objectstauts.next(true)
      } else {
        this.sharedService.objectstauts.next(null)
      }
      if (isSet(this.sharedService.Createform.value)) {
        this.sharedService.objectstauts.next(true)
      } else {
        this.sharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  Save_form() {
    this.sharedService.Saveform_Observable_state.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }
      
      this.sharedService.Save_object(this.user)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }
  onBasicUploadAuto(file){
    console.log(file);
    
  }
}