import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(
          event => {},
          (error: HttpErrorResponse) => {
            if (error.status === 401) {
              localStorage.removeItem('token_data');
              this.router.navigate(['auth/login']);
              setInterval((lo)=>{
                location.reload()
              },200)
              
              return ;
            }
          }
        ),
      );
  }
}
