import { isSet } from '../modules/core/util';

export class SpecialPrice {
  id: string;
  name: string;

  public constructor(params?: SpecialPrice) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: SpecialPrice) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new SpecialPrice(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: SpecialPrice[]) {
    const objects: SpecialPrice[] = [];
    for (const obj of objectsToClone) {
      objects.push(SpecialPrice.cloneObject(obj));
    }

    return objects;
  }
  toDB(){
  return{
    name:this.name
  }
  }
}
