import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListComponent } from './users-list/users-list.component';
import { RouterModule,Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import { UserFormComponent } from './user-form/user-form.component';
import {CardModule} from 'primeng/card';
import {FileUploadModule} from 'primeng/fileupload';

export const routes: Routes = [
  { path: '', component: UsersListComponent }
];

@NgModule({
  declarations: [
    UsersListComponent,
    UserFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ToastModule,
    TranslateModule,
    TableModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    CardModule,
    FileUploadModule
  ]
})
export class UsersModule { }
