import { isSet } from '../modules/core/util';

export class Brand {
  id: string;
  name: string;
  company:number;
  
  public constructor(params?: Brand) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Brand) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Brand(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Brand[]) {
    const objects: Brand[] = [];
    for (const obj of objectsToClone) {
      objects.push(Brand.cloneObject(obj));
    }

    return objects;
  }
  public toDB(){
return{
  name:this.name

}
  }
}
