import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { isSet } from '../util';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  public loading = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribeAll(this.subscriptions);
  }

  public unsubscribeAll(subscriptions: Subscription[] = null): void {
    try {
      subscriptions.forEach((subscription: Subscription) => {
        if (isSet(subscription) && !subscription.closed) {
          subscription.unsubscribe();
        }
      });
    } catch (error) {
    }
  }

  public log(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams);
  }

  public handleError(e: any): void {}
  // this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });

 

}
