<p-toast key="formM" position="top-right"></p-toast>
<app-loading *ngIf="loading"></app-loading>
<div class="p-grid">
  <div class="p-col-8">
    <p-card>
      <!-- [style.height.px]="payment_cardHeight" -->

      <div [style.height.px]="payment_cardHeight+52" class="p-grid">
        <div class="p-col-7 p-pr-6">
          <div class="p-grid">
            <div class="p-col-4 ">
              <label style="pointer-events: none; " class="formattext "> {{"EXPENSE_TYPE" | translate}} </label>
            </div>
            <div style="padding-left: 0%;" class="p-col-8">
              <!-- <p-inplace #ex_type>
                                <span pInplaceDisplay>
                                    <button *ngIf=" expense.ex_type"
                                        style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                                        [label]="expense.ex_type " type="button"
                                        class=" p-button-text p-button-plain p-ripple p-button p-component ">
                                    </button>
                                </span>

                                <p-dropdown pInplaceContent appendTo="body" placeholder="Select Type"
                                    [options]="expenseNoteType" (ngModelChange)="notempty($event)"
                                    [(ngModel)]="expense.ex_type" optionValue="ex_type" optionLabel="ex_type"
                                    (onHide)="ex_type.deactivate()">
                                </p-dropdown>
                            </p-inplace> -->
              <p-dropdown pInplaceContent appendTo="body" placeholder='{{"SELECT_TYPE" | translate}}'
                [options]="expenseNoteType"
                (ngModelChange)="notempty($event)"  [(ngModel)]="expense.ex_type"
                optionValue="ex_type" optionLabel="label">
              </p-dropdown>
            </div>


          </div>

          <div class="p-grid">
            <div class="p-col-4 ">
              <label style="pointer-events: none; " class="formattext ">{{"TITLE" | translate}} </label>
            </div>
            <div style="padding-left: 0%;" class="p-col-8 ">
              <input #title="ngModel" maxlength="40" autocomplete="on" (ngModelChange)="notempty($event)" [(ngModel)]="expense.title"
                type="text" name="expense.title" (focus)="$event.target.select()" pInputText>

            </div>

          </div>
          <div class="p-grid p-pt-4 ">
            <!-- <div class="p-col-4 ">
                        </div>
                        <div class="p-col-8 text-align-center ">
                            <div *ngIf="(!isSet(expense?.attachment?.path ) &&  !isSet(expense?.attachment?.path ) )|| loadingbtnupload!=false; else viewRec"
                                style="display: inline-block;">
                                <button (click)="showUploadReceipt()" iconPos="left"
                                    [icon]="loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'" pButton
                                    label="Upload Receipt" type="button"
                                    class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace uploadtage"></button>

                                <input #upload type="file" id="upload-photo" [imageToDataUrl]="options"
                                    (imageSelected)="uploadReceipt($event)">

                            </div>
                            <ng-template #viewRec>
                                <button (click)="showViewReceipt()" iconPos="left" pButton pButton label="View Receipt"
                                    icon="fas fa-eye " type="button"
                                    class=" p-button-text p-button-plain p-ripple p-button p-component customerinplace uploadtage">
                                </button>
                            </ng-template>

                        </div> -->


            <div class="p-col-4 ">
              <label style="pointer-events: none " class="formattext ">{{"RECIPET" | translate}} </label>
            </div>
            <div style="padding-left: 0%;" class="p-col-8 ">

              <div style="display: inline-block;"
                *ngIf="(!isSet(expense?.attachment?.path ) &&  !isSet(expense?.attachment?.path ) )|| loadingbtnupload!=false; else viewRec">
                <button pButton type="button" style="margin-right: 5px;"
                  [icon]="loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'" (click)="showUploadReceipt()"
                  label='{{"UPLOAD_RECIEPT" | translate}}' class=" ui-button-info" for="upload-photo"></button>


                <input #upload accept=".jpg,.jpeg,.png,.pdf," type="file" id="upload-photo" [imageToDataUrl]="options"
                  (imageSelected)="uploadReceipt($event)">
              </div>
              <ng-template #viewRec>
                <button style="margin-right: 5px;" pButton type="button" icon="fas fa-eye" (click)="showViewReceipt()"
                  label='{{"VIEW_RECEIPT" | translate}}' class="ui-button-info" for="upload-photo"></button>

              </ng-template>

            </div>



          </div>

        </div>

        <div class="p-col-5 p-pl-6">
          <div class="form-group order-header-numbers">

            <div class="p-grid">
              <div class="p-col-5 ">
                <label style="pointer-events: none; " class="formattext ">{{"EXPENSE" | translate}} #</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7">
                <p-inplace [disabled]="selected_expense" #expenseformat>
                  <span pInplaceDisplay>
                    <button *ngIf=" expense.format "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton
                      [label]="expense.format" type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                    </button>

                    <button *ngIf=" expense.format == '' && formatt.touched  " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid">
                    </button>

                  </span>
                  <input #formatt="ngModel" [(ngModel)]="expense.format" type="text" pInputText pInplaceContent
                    (blur)="expenseformat.deactivate()">

                </p-inplace>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col-5">
                <label style="pointer-events: none; " class="formattext "> {{"EXPEANE_DATE" | translate}}</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7">
                <p-inplace #qouteDate>
                  <span pInplaceDisplay>

                    <button *ngIf=" expense.date  "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton (click)="focusThenShow('date')"
                      [label]="expense.date | date:'dd-MM-yyyy' " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                    </button>
                    <button *ngIf=" expense.date == null && date.touched " style="  font-weight: 600;  " pButton
                      label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button" (click)="focusThenShow('date')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid">
                    </button>
                  </span>
                  <p-calendar #openCalender dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" showIcon="true"
                    [(ngModel)]="expense.date" pInplaceContent #date="ngModel" [required]="true"
                    (onClose)="qouteDate.deactivate()" (onClickOutside)="qouteDate.deactivate()"
                    (onSelect)="qouteDate.deactivate()"></p-calendar>
                </p-inplace>
              </div>


            </div>
            <div class="p-grid">
              <div class="p-col-5">
                <label style="pointer-events: none; " class="formattext "> {{"EXPENSE_STATUS" | translate}}</label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7">
                <p-inplace #ex_status>
                  <span pInplaceDisplay>

                    <button *ngIf=" expense.ex_status  "
                      style="  color: var(--primary-color) !important; font-weight: 600;     " pButton  (click)="focusThenShow('status')"
                            label='{{expense.ex_status|translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                    </button>
                    <button *ngIf=" expense.ex_status == null && ex_statuse.touched " style="  font-weight: 600;  "
                      pButton label='{{"CUSTOMER_FORM_REQUIRED" | translate}}' type="button"
                            (click)="focusThenShow('status')"
                      class=" p-button-text p-button-plain p-ripple p-button p-component alert-invalid">
                    </button>
                  </span>
                  <p-dropdown #DropDownStatus pInplaceContent appendTo="body" [options]="expenseStatus"
                    (ngModelChange)="notempty($event)" [(ngModel)]="expense.ex_status" #ex_statuse="ngModel"
                    name="ex_status" optionValue="ex_status" optionLabel="label" (onSelect)="ex_status.deactivate()"
                    (onClickOutside)="ex_status.deactivate()" (onHide)="ex_status.deactivate()">

                  </p-dropdown>
                </p-inplace>
              </div>


            </div>

            <div class="p-grid">
              <div class="p-col-5 ">
                <label style="pointer-events: none; " class="formattext "> {{"CUSTOMER_FORM_CAT" | translate}} </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7">
                <p-inplace #category>
                  <span pInplaceDisplay>

                    <button *ngIf=" expense.ex_type_2  "
                      style="  color: var(--primary-color) !important; font-weight: 600; " pButton (click)="focusThenShow('cat')"
                      label='{{expense.ex_type_2|translate}}' type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                    </button>

                    <button *ngIf="!expense.ex_type_2 " icon="fas fa-plus"
                      style="  color: var(--primary-color) !important; font-weight: 600;  " (click)="focusThenShow('cat')"
                      pButton label='{{"SELECT_CAT" | translate}}' type="button" class="  p-button-outlined"></button>
                  </span>
                  <p-dropdown #dropCategory pInplaceContent appendTo="body" placeholder='{{"SELECT_CAT" | translate}}'
                    [options]="expenseNoteType2"  (ngModelChange)="notempty($event)" [(ngModel)]="expense.ex_type_2"
                    optionValue="ex_type_2" optionLabel="label" (onHide)="category.deactivate()">

                  </p-dropdown>


                </p-inplace>
              </div>


            </div>

            <div class="p-grid">
              <div class="p-col-5 ">
                <label style="pointer-events: none; " class="formattext "> {{"PAYER" | translate}} </label>
              </div>
              <div style="padding-left: 0%;" class="p-col-7">
                <p-inplace #exp_payer>
                  <span pInplaceDisplay>

                    <button *ngIf=" expense.payer  "
                      style="  color: var(--primary-color) !important; font-weight: 600; " pButton
                      [label]="expense.payer.name " type="button"
                      class=" p-button-text p-button-plain p-ripple p-button p-component ">
                    </button>

                    <button *ngIf="!expense.payer " icon="fas fa-plus"
                      style="  color: var(--primary-color) !important; font-weight: 600;  " pButton id="selectCustomer"
                      pButton label='{{"ADD_PAYER" | translate}}' type="button" class="  p-button-outlined"></button>


                  </span>

                  <app-payer-select pInplaceContent [(payer)]="expense.payer" (payerChange)="payerChange($event)"
                    (onHide)="exp_payer.deactivate()">
                  </app-payer-select>

                </p-inplace>
              </div>


            </div>
          </div>
        </div>

      </div>
    </p-card>


  </div>
  <div class="p-col-4 col-4-r">
    <p-card>
      <div #paymentcard class="p-grid">
        <div class="p-col-12 ">

          <h3 aria-required="true" class="table-inplace">
            <i class="fa-menu fa fa-money-bill-alt tags"></i>
            {{"ORDERS_FORM_PAYMENTS" | translate}}
          </h3>
        </div>

        <div class="p-col-12">

          <p-table id="payment-table" [resizableColumns]="true" [rowHover]="true" [scrollable]="true"
            scrollHeight="153px" [value]="expense.payments">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 50%; "> {{"QUOTES_Form_TOTAL_INC_VAT" | translate}}:
                  <span class="p-text-bold" style="color: #51749e;">{{ expense.totalwithVat |
                    number:'1.2-2'}}
                  </span>

                </th>
                <th class="text-align-right" style="width: 50%;">
                  <div> {{"ORDERS_FORM_DUE" | translate}}:
                    <span class="p-text-bold" style="color: #51749e;">{{paymentDue |
                      number:'1.2-2'}}
                    </span>

                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
              <tr [ngStyle]="{cursor: rowData.status2=='new'?'pointer':'auto'}" (click)="paymentReview(rowData,i)">
                <!-- <td style="width: 50px;">

                    </td> -->
                <td>
                  <div [ngClass]="{'p-ml-5': rowData.status2=='new'}" class="p-text-bold ">
                    {{rowData.notes}}</div>
                  <div style="position: relative;" [ngClass]="{'p-ml-5': rowData.status2=='new'}" class="p-text-light ">
                    <button style="position: absolute; left: -36px;
                        top: -12px;" *ngIf="rowData.status2=='new'" (click)="deletePayment(i)" pButton pRipple
                      type="button" icon="fas fa-trash" class="p-button-lg p-button-text p-button-danger "></button>
                    {{ rowData.dateP | date:'d-M-y' }}
                    <p-tag styleClass="ml-3" severity="primary">{{ rowData.type|translate}}</p-tag>
                  </div>
                </td>
                <td style="text-align:end;">
                  <span class="p-text-bold " style="color: #51749e;">
                    <label *ngIf="rowData.status == 'refund'">-</label>
                    {{rowData.amount| number:'1.2-2'}} </span>
                </td>

              </tr>


            </ng-template>

            <ng-template pTemplate="summary">
              <div class="p-grid ">
                <div class="p-col-6">

                  <p-splitButton #ua appendTo="body" [disabled]="paymentDuestatus" label='{{"PAYMENT" | translate}}'
                    icon="fas fa-plus" (onClick)="showAddPaymnet()" [model]="payment_Options"
                    class="ui-button-raised ui-button-info">
                  </p-splitButton>

                </div>
                <div style="text-align: end !important;" class="p-col-6 ">
                  <button pButton class="p-button-danger " (click)="showrefundPayment()"
                    [disabled]="expense.payments?.length==0 || expense.totalwithVat ==  paymentDue" icon="fas fa-minus"
                    type="button" label='{{"ORDERS_FORM_REFUND" | translate}}'></button>

                </div>

              </div>

            </ng-template>
          </p-table>



        </div>

      </div>

    </p-card>


  </div>
  <div class="p-col-12 col-12-r">

    <p-card>
      <div class="p-grid p-pl-3 p-mb-6 border ">
        <div *ngIf="expense.ex_type == 'Ticket'" class="p-col-12">
          <div class="p-grid">
            <div class="p-col-7">
              <div class="p-grid p-pr-3  ">
                <div class="p-col-12 p-mt-4 ">

                  <span *ngIf="!isSet(expense?.vendor?.id) && isSet(expense?.vendor?.name) ; else inlineVendor"
                    class="p-float-label">
                    <input id="supplier-input" [(ngModel)]="expense.vendor.name" type="text" pInputText>
                    <label for="supplier-input">{{"SUP_VEN" | translate}}</label>
                  </span>
                  <ng-template #inlineVendor>
                    <app-supplier-select *ngIf="!loading" id="supplier-input" [floatSupplier]='true'
                      (supplierChange)="selectvendor($event)" [serach_Vendor]='serach_Vendor'
                      [(supplier)]="expense.vendor">
                    </app-supplier-select>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">


                    <input id="vat-input" name="expense.vat" [(ngModel)]="expense.vat"
                      (ngModelChange)="vatProcess($event)" type="text" pInputText>


                    <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-4 ">
                  <span class="p-float-label">


                    <p-dropdown appendTo="body" [placeholder]="expense.payment_type? null:'Payment Type'"
                      [options]="paymenttypes" (ngModelChange)="notempty($event)" [(ngModel)]="expense.payment_type"
                      optionValue="type" optionLabel="label">

                    </p-dropdown>
                    <label *ngIf="expense.payment_type" for="category-input">{{"PAYMENT_TYPE" | translate}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label ">

                    <textarea (ngModelChange)="notempty($event)" autoResize="false" [(ngModel)]="expense.description"
                      id="message-input" rows="5" pInputTextarea></textarea>

                    <label for="currency-input">{{"DISCRIPTION" | translate}}</label>

                  </span>
                </div>
              </div>
            </div>
            <div class="p-col-5 p-pl-4 custom-col-6 border-btween">
              <div class="p-grid p-pr-4 ">

                <div class="p-col-12 p-mt-4">
                  <span style="position: relative;" class="p-float-label ">
                    <div class="clearable-input">


                      <p-inputNumber [min]="0" #amount [inputStyle]="{'width':currencyinput.offsetWidth+'px'}"
                        (onFocus)="$event.target.select()" (ngModelChange)="notempty($event);expense.ticketAmount=true"
                        [(ngModel)]="expense.amount" mode="decimal" [minFractionDigits]="2">
                      </p-inputNumber>

                      <i *ngIf="isSet(expense.amount)&& expense.amount !== 0 " (click)="erace_value('amount')"
                        class="pi pi-times"></i>
                      <label style="position: absolute;left:1.5%;" class="p-text-secondary"
                        for="amount-input">{{"ORDERS_FORM_AMOUNT" | translate}}

                        &nbsp;
                        <label *ngIf="required && expense.amount==0" class="alert-invalida">{{"CUSTOMER_FORM_REQUIRED"|
                          translate}}</label>
                      </label>

                    </div>

                  </span>

                </div>

                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">

                    <div class="clearable-input">
                      <input [style]="{'width':currencyinput.offsetWidth+'px'}" #exchange_rate
                        name="expense.exchange_rate" (keypress)="numberOnly($event)" (ngModelChange)="notempty($event)"
                        (focus)="$event.target.select()" [(ngModel)]="expense.exchange_rate" type="text" pInputText>
                      <i *ngIf="isSet(expense.exchange_rate)&& expense.exchange_rate !== 1 "
                        (click)="erace_value('exchange_rate')" class="pi pi-times"></i>

                      <label style="position: absolute;left:1.5%;" class="p-text-secondary"
                        for="exchange_rate-input">{{"EXCHANGE_RATE" | translate}}</label>

                    </div>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">

                  <span #currencyinput class="p-float-label text-align-left">
                    <p-dropdown id="currency-input" [options]="currencies" [filter]="true" optionValue='currency'
                      [(ngModel)]="expense.currency" optionLabel="currency"></p-dropdown>

                    <label *ngIf="expense?.currency" for="currency-input">{{"CURRANECY" | translate}}</label>

                  </span>
                </div>
                <div class="p-col-12   p-mt-3">


                  <hr class="divider">
                  <div style="background-color: #f8f9fa;" class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ expense.totalwithVat |
                      number:'1.2-2'}}
                    </div>
                  </div>


                  <!-- <div style="background-color: #f8f9fa;" class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_LIST_TOTAL" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ expense.totalwithVat |
                      number:'1.2-2'}}
                    </div>
                  </div> -->
                </div>


              </div>
            </div>

          </div>
        </div>
        <div *ngIf="expense.ex_type == 'Invoice'" class="p-col-12">
          <div class="p-grid">
            <div class="p-col-7 custom-col-4  ">
              <div class="p-grid  p-pr-3">

                <div class="p-col-12 p-mt-4 ">

                  <span *ngIf="!isSet(expense?.vendor?.id) && isSet(expense?.vendor?.name) ; else inlineVendor"
                    class="p-float-label">
                    <input id="supplier-input" [(ngModel)]="expense.vendor.name" type="text" pInputText>
                    <label for="supplier-input">{{"SUP_VEN" | translate}}</label>
                  </span>
                  <ng-template #inlineVendor>
                    <app-supplier-select *ngIf="!loading" id="supplier-input" [floatSupplier]='true'
                      (supplierChange)="selectvendor($event)" [serach_Vendor]='serach_Vendor'
                      [(supplier)]="expense.vendor">
                    </app-supplier-select>

                  </ng-template>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">


                    <input id="vat-input" name="expense.vat" [(ngModel)]="expense.vat"
                      (ngModelChange)="notempty($event)" type="text" pInputText>


                    <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}</label>
                  </span>
                </div>

                <div class="p-col-12 p-mt-3 " *ngFor="let iban of expense.iban , let i = index">
                  <span class="p-float-label" >
                    <input id="iban-input" name="expense.iban" [value]="iban" 
                      (change)="changeINAN(i,$event.target.value) ;update_status = true" type="text" pInputText>
                    <label for="iban-input">IBAN ({{i+1}})</label>
                    <button *ngIf="expense?.iban.length>1" style="position: absolute;left:8.5%; top: -82%;"
                      label="Remove" icon="fas fa-trash" (click)="deleteIBANInput(i);update_status = true"  pButton
                      type="button" class="p-button-text p-button-danger expenes-remove-btn"></button>
                    <button *ngIf="i==0" style="position: absolute;left:70.5%; top: -82%;"
                      label="Add IBAN" icon="fas fa-plus" (ngModelChange)="notempty($event)"(click)="addIBAN();update_status = true"  pButton
                      type="button" class="p-button-text p-button-primary expenes-remove-btn"></button>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3 ">
                  <span class="p-float-label">
                    <input id="vcs-input" name="expense.vcs" [(ngModel)]="expense.vcs"
                      (ngModelChange)="notempty($event)" type="text" pInputText>
                    <label for="vcs-input">VCS</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">
                  <span class="p-float-label ">

                    <textarea (ngModelChange)="notempty($event)" autoResize="false" [(ngModel)]="expense.description"
                      id="message-input" rows="8" pInputTextarea></textarea>

                    <label for="currency-input">{{"DISCRIPTION" | translate}}</label>

                  </span>
                </div>

              </div>
            </div>
            <div style="border-right: 1px solid #dee2e6;" class="p-col-5 border-btween p-pl-4 custom-col-4 ">
              <div class="p-grid p-pr-4  text-align-center">

                <div *ngFor="let item of expense?.items , let i = index" class="p-col-12 p-mt-4">
                  <div class="p-grid ">
                    <div class="p-col-6">

                      <span style="position: relative;" class="p-float-label ">
                        <div class="clearable-input">
                          <p-inputNumber [min]="0" [inputStyle]="{'width':15+'vw'}"
                            (ngModelChange)="onChangeAmount(item.vat_rate,$event)"
                            [(ngModel)]="item.base" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

                          <i *ngIf="isSet(item.base)&& item.base !== 0  "
                            (click)="item.base=0 ;onChangeAmount(item.vat_rate,0);vat?.input?.nativeElement?.select()"
                            class="pi pi-times"></i>
                          <label style="position: absolute;left:1.5%;" class="p-text-secondary"
                            for="vat-input">{{"ORDERS_FORM_AMOUNT" | translate}}

                            <label *ngIf=" expense?.items?.length>1">{{i+1}}</label>
                            &nbsp;
                            <label *ngIf="required && item.base==0" class="alert-invalida">{{"CUSTOMER_FORM_REQUIRED"|
                              translate}}</label>
                          </label>
                          <button *ngIf="expense?.items.length>1" style="position: absolute;left:70.5%; top: -82%;"
                            label="Remove" icon="fas fa-trash" (click)="deleteVatInput(item.vat_rate,i)" pButton
                            type="button" class="p-button-text p-button-danger expenes-remove-btn"></button>

                            <button  style="position: absolute;left:122.5%; top: -82%;"
                              label="Edit" icon="fas fa-edit" (click)="changeVatInput(item.vat_rate,i)" pButton
                              type="button" class="p-button-text expenes-remove-btn"></button>

                        </div>
                      </span>
                    </div>
                    <div class="p-col-6">

                      <span style="position: relative;" class="p-float-label ">
                        <div class="clearable-input">
                          <p-inputNumber id="vat-input" [min]="0" [disabled]="true" [inputStyle]="{'width':15+'vw'}"
                            #vat (onFocus)="$event.target.select()" (ngModelChange)="onChangeVat(item.vat_rate,$event)"
                            [(ngModel)]="item.vat" mode="decimal" [minFractionDigits]="2"></p-inputNumber>


                          <label style="position: absolute;left:1.5%;" class="p-text-secondary"
                            for="vat-input">{{"CUSTOMER_LIST_VAT" | translate}}
                            {{item.vat_rate}} %
                          </label>


                        </div>
                      </span>
                    </div>


                  </div>

                </div>








                <div class="p-col-12 p-mt-3">
                  <span style="position: relative;" class="p-float-label ">

                    <div class="clearable-input">
                      <input [style]="{'width':currencyinput.offsetWidth+'px'}" #exchange_rate
                        name="expense.exchange_rate" (keypress)="numberOnly($event)" (ngModelChange)="notempty($event)"
                        (focus)="$event.target.select()" [(ngModel)]="expense.exchange_rate" type="text" pInputText>
                      <i *ngIf="isSet(expense.exchange_rate)&& expense.exchange_rate !== 1 "
                        (click)="erace_value('exchange_rate')" class="pi pi-times"></i>

                      <label style="position: absolute;left:1.5%;" class="p-text-secondary"
                        for="exchange_rate-input">{{"EXCHANGE_RATE" | translate}}</label>

                    </div>
                  </span>
                </div>
                <div class="p-col-12 p-mt-3">

                  <span #currencyinput class="p-float-label text-align-left">

                    <p-dropdown id="currency-input" [options]="currencies" [filter]="true" optionValue='currency'
                      [(ngModel)]="expense.currency" optionLabel="currency"></p-dropdown>
                    <label *ngIf="expense?.currency" for="currency-input">{{"CURRANECY" | translate}}</label>

                  </span>
                </div>
                <div class="p-col-12   p-mt-3">
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_VAT" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ expense.vatTotal | number:'1.2-2'}}
                    </div>
                  </div>
                  <div class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_NET" | translate}} </div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ expense.subTotal | number:'1.2-2'}}
                    </div>
                  </div>
                  <hr class="divider">
                  <div style="background-color: #f8f9fa;" class=" p-grid order-summary text-muted">
                    <div class="p-col-6 p-pl-4 text-align-left">{{"QUOTES_Form_TOTAL_INC_VAT" | translate}}</div>
                    <div class="p-col-6  p-pr-4 text-align-right number-total">{{ expense.totalwithVat |
                      number:'1.2-2'}}
                    </div>
                  </div>

                </div>
                <div class="p-col-12 text-align-left">

                  <button pButton type="button" (click)="showDialogvat=true;dialogVat='Add VAT'" icon="fas fa-plus"
                    class="p-button-lg p-button-outlined" label='{{"ORDERS_FORM_AMOUNT" | translate}}'></button>

                </div>

              </div>
            </div>

            <!-- <div class="p-col-4  custom-col-4  ">
                  <div class="p-grid  p-px-4 ">
                    <div class="p-col-12 p-mt-4 ">
                      <span class="p-float-label">
                        <p-dropdown appendTo="body" [placeholder]="item.payment_type? null:'Payment Type'"
                          [options]="paymenttypes" (ngModelChange)="notempty($event)" [(ngModel)]="item.payment_type"
                          optionValue="type" optionLabel="type">

                        </p-dropdown>

                        <label *ngIf="item.payment_type" for="category-input">Payment Type</label>
                      </span>
                    </div>
                    <div class="p-col-12 p-mt-3 text-align-center">
                      <div *ngIf="!isSet(item?.attachment?.path) || item.loadingbtnupload!=false; else viewRec">
                        <button pButton type="button" [disabled]="disablebtnupload"
                          [icon]="item.loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'"
                          (click)="showUploadReceipt(i)" class="p-button-lg" label='Upload Receipt'
                          for="upload-photo"></button>
                        <input #upload type="file" id="upload-photo" [imageToDataUrl]="options"
                          (imageSelected)="uploadReceipt($event)">
                      </div>
                      <ng-template #viewRec>
                        <button pButton type="button" [disabled]="disablebtnupload" icon="fas fa-eye"
                          (click)="showViewReceipt(i,'item')" label='View Receipt' class="p-button-lg"
                          for="upload-photo"></button>

                      </ng-template>
                    </div>
                    <div class="p-col-12 p-mt-3 text-align-center">
                      <button *ngIf="expenseNote.items.length > 1" pButton type="button"
                        (click)="expenseNote.items.splice(i, 1)" icon="fas fa-trash " label='Delete Expense'
                        class="p-button-lg  p-button-danger"></button>
                    </div>

                  </div>
                </div> -->
          </div>
        </div>



      </div>

    </p-card>




  </div>

  <p-dialog modal="true" [header]="paymentheader" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)"
    [resizable]="false" [draggable]="false" [(visible)]="showPaymentDialog" [style]="{width: '40vw'}">
    <div style="margin-top: 2px; " class="p-grid">

      <div class="p-col-6">
        <div class="form-text text-muted">{{"DASHBORD_DATE" | translate}}</div>
        <p-calendar dateFormat="dd-mm-yy" appendTo="body" [inputStyle]="{'width':'18.5vw'}" class="calendar"
          [(ngModel)]="payment.dateP" #payment_date="ngModel" name="payment.dateP"
          placeholder='{{"DASHBORD_DATE" | translate}}'>
        </p-calendar>
      </div>
      <div class="p-col-6">
        <div class="form-text text-muted">{{"ORDERS_FORM_PAYMENT_TYPES" | translate}}</div>
        <p-dropdown appendTo="body" [(ngModel)]="payment.type" [options]="paymenttypes" optionValue="type"
          optionLabel="label"></p-dropdown>

      </div>


      <div class="p-col-12">
        <div class="form-text text-muted ">{{"ORDERS_FORM_AMOUNT" | translate}}
          <label *ngIf=" (payment.amount == null || amountt.invalid) &&  amountt.touched " class="alert-invalida">
            {{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

        </div>

        <p-inputNumber [min]="0" [inputStyle]="{'width': '38.2vw' }" id="Amount-input" #amountt="ngModel"
          (ngModelChange)="notempty($event)" [(ngModel)]="payment.amount" mode="decimal" [minFractionDigits]="2">
        </p-inputNumber>

      </div>
      <div class="p-col-12">
        <div class="form-text text-muted">{{"ORDERS_FORM_REFERENCE" | translate}}

        </div>
        <input [(ngModel)]="payment.notes" id="Reference -input" type="text" pInputText>

      </div>
    </div>

    <p-footer>
      <button type="button" pButton (click)="showPaymentDialog=false" icon="pi pi-times"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>
      <button *ngIf="paymentheader==translate.instant('ADD_PAYMENT')" type="button" pButton (click)="addPayment()"
        label='{{"CUSTOMER_FORM_ADD" | translate}}'></button>
      <button *ngIf="paymentheader==translate.instant('UPDATED_PAYMENT')" type="button" pButton
        (click)="updatePayment()" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'></button>
      <button *ngIf="paymentheader==translate.instant('REFUND_PAYMENT')" type="button" pButton (click)="refundPayment()"
        label='{{"ORDERS_FORM_REFUND" | translate}}'></button>

    </p-footer>
  </p-dialog>

  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" class="p-shadow-1 " [header]="'View Receipt '+ '('+expense.format+')'"
    [(visible)]="viewAttachment_diloage" [style]="{width: '60vw'}">

    <div
      *ngIf="(expense?.attachment?.type=='application/pdf') ||(expense?.attachment?.type=='application/pdf') ;else img_viewer"
      style=" position: relative;" [style.height.px]="pdfheight">
      <pdf-viewer (after-load-complete)="afterLoadComplete($event)" [src]="expense?.view_url" [render-text]="true"
        [show-all]="true" [stick-to-page]=" true" [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"
        (on-progress)="pdfLoader($event)"></pdf-viewer>
    </div>
    <ng-template #img_viewer>
      <div *ngIf="expense?.view_url" class="p-grid text-align-center">
        <div class="p-col-12"><img [src]="getSantizeUrl(expense?.view_url) "></div>
      </div>


    </ng-template>

    <p-footer class="p-pt-4">
      <button *ngIf="expense?.attachment?.type == 'application/pdf'" type="button" pButton icon="fas fa-print"
        (click)="printPdf()" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
      <button type="button" pButton icon="pi pi-download" (click)="downloadlink()"
        label='{{"DOWNLOAD" | translate}}'></button>

      <button *ngIf="isSet(expense?.attachment?.path ) " pButton type="button" (click)="deletefile()"
        icon="fas fa-trash " label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}' class=" p-button-danger "
        for="upload-photo"></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="viewAttachment_diloage=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <p-dialog modal="true" appendTo="body" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)"
    [resizable]="false" [draggable]="false" class="p-shadow-1" header="Reset Expense " [(visible)]="restexpense"
    [style]="{width: '30vw'}">
    <div class="p-text-justify ">{{"DELETE_ALL_EXPENSE" | translate}}</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="rest_Expense()"
        label='{{"CONFIRM" | translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="restexpense=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined p-button-secondary"></button>
    </p-footer>
  </p-dialog>


  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false); canceleviewRecpie()"
    [resizable]="false" [draggable]="false" class="p-shadow-1 " [header]="'Confirm Receipt'" [(visible)]="viewRecpie"
    [style]="{width: '80vw'}">
    <div style="position: relative;" class="p-grid p-p-2">
      <div class="p-col-8">
        <div *ngIf="selectedexpense?.attachment?.type == 'application/pdf'" style="position: relative;"
          [style.height.px]="pdfheight">
          <p-tabView>
            <p-tabPanel header="PDF">

              <pdf-viewer [src]="selectedexpense?.view_url" [render-text]="true" [show-all]="true"
                [stick-to-page]=" true" (after-load-complete)="afterLoadComplete($event)" [zoom-scale]="'page-width'"
                [zoom]="0.9" style="display: block" (on-progress)="pdfLoader($event)"></pdf-viewer>
            </p-tabPanel>

          </p-tabView>

        </div>
        <div *ngIf="selectedexpense?.attachment?.type != 'application/pdf'" class="p-grid">
          <p-tabView (onChange)="tabSelectedRecpit=!tabSelectedRecpit">

            <p-tabPanel header='{{"AI_CROOPED" | translate}}'>

              <div *ngFor="let item of selectedexpense?.aiCropped" style="text-align: left !important; "
                class="p-col-12"><img style="width:38vw  !important; "
                  [src]="getSantizeUrl('data:image/png;base64,' + item)"></div>
            </p-tabPanel>
            <p-tabPanel header="Original">
              <div style="text-align: left !important; " class="p-col-12"><img style="width:38vw  !important; "
                  [src]="displayimg_localy"></div>
            </p-tabPanel>
          </p-tabView>

        </div>



      </div>
      <div class="p-col-4 ">
        <div *ngIf="selected_Dates?.length" style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
          class="p-col-12" class="p-grid p-mb-4">
          <div #vendor_name class="p-col-12">
            <label #vendor_namelabel class="formattext">{{"THEY_R" | translate}} {{selected_Dates?.length}}
              {{"DATES_RECIPT" | translate}}
              <br>
              {{"APROVED_ONE" | translate}} : </label>


            <p-listbox [options]="selected_Dates" [(ngModel)]="selectedexpense.datePipe" optionLabel="date"
              optionValue="date">

              <ng-template let-item pTemplate="item">
                <label>
                  {{item?.date|date:'dd-MM-yyyy'}}</label>
              </ng-template>
            </p-listbox>


          </div>

        </div>
        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid; " *ngIf="!selected_Dates?.length"
          class="p-grid p-mb-4">
          <div #selectedexpensedate class="p-col-12">
            <label #datelabel class="formattext">{{"DASHBORD_DATE" | translate}} : </label>&nbsp;

            <p-inplace (click)="focusThenShow('selectedDate')" [style]="{display: 'inline-block'}" #expenseNote_date>

              <span pInplaceDisplay>

                <label style=" color: var(--primary-color) !important; font-weight: 600;  ">
                  {{selectedexpense?.datePipe|date:'dd-MM-yyyy'}}</label>
              </span>
              <p-calendar appendTo="body"
                [inputStyle]="{'width':selectedexpensedate?.offsetWidth-datelabel?.offsetWidth-65+'px'}"
                dateFormat="dd-mm-yy" showIcon="true" #selectedDate
                [(ngModel)]="selectedexpense.datePipe" pInplaceContent #date="ngModel" [required]="true"
                (onClose)="expenseNote_date.deactivate()" (onClickOutside)="expenseNote_date.deactivate()"
                (onSelect)="expenseNote_date.deactivate()"></p-calendar>


            </p-inplace>
          </div>

        </div>
        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
          *ngIf="selectedexpense?.payment_type" class="p-grid p-mb-4">
          <div #selectedexppayment_type class="p-col-12 ">
            <label #payment_typeabel style="pointer-events: none; " class="formattext"> {{"PAYMENT_TYPE" | translate}} :
            </label>&nbsp;
            <p-inplace (click)="focusThenShow('selectedPaymentType')" [style]="{display: 'inline-block'}" #expenseNotepayment_type>

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                  {{selectedexpense?.payment_type}}</label>
              </span>

              <p-dropdown [style]="{width:selectedexppayment_type.offsetWidth-payment_typeabel.offsetWidth-35+'px'}"
                appendTo="body" [placeholder]="selectedexpense?.payment_type? null:'Payment Type'"
                [options]="paymenttypes" [(ngModel)]="selectedexpense.payment_type"
                optionValue="type" (onSelect)="expenseNotepayment_type.deactivate()" #selectedPaymentType
                (onHide)="expenseNotepayment_type.deactivate()" optionLabel="label" pInplaceContent>

              </p-dropdown>


            </p-inplace>

          </div>

        </div>

        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " class="p-grid p-mb-4">
          <div #selectedexpex_type_2 class="p-col-12 ">
            <label #typelabel style="pointer-events: none; " class="formattext"> {{"CUSTOMER_FORM_CAT" | translate}} :
            </label>&nbsp;
            <p-inplace [style]="{display: 'inline-block'}" #expenseNoteex_type_2
                       (click)="focusThenShow('selectedCat')">

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                  <label *ngIf="selectedexpense.ex_type_2 == null;else selected">{{"NOT_SELECTED" | translate}}</label>
                </label>
                <ng-template #selected >
                  {{selectedexpense?.ex_type_2 |translate}}

                </ng-template>
              </span>



              <p-dropdown id="category-input" appendTo="body"
                [placeholder]="selectedexpense.ex_type_2? null:'Select Category'" [options]="expenseNoteType2"
                [(ngModel)]="selectedexpense.ex_type_2" optionValue="ex_type_2" #selectedCat
                optionLabel="ex_type_2" (onSelect)="expenseNoteex_type_2.deactivate()"
                (onHide)="expenseNoteex_type_2.deactivate() " pInplaceContent>
              </p-dropdown>

            </p-inplace>

          </div>

        </div>

        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selectedexpense?.ex_type"
          class="p-grid p-mb-4">
          <div #selectedexpex_type class="p-col-12 ">
            <label #typelabel style="pointer-events: none; " class="formattext"> {{"TYPE" | translate}} : </label>&nbsp;
            <p-inplace [style]="{display: 'inline-block'}" #expenseNoteex_type>

              <span pInplaceDisplay>

                <label (click)="focusThenShow('selectedType')"
                  style=" color: var(--primary-color) !important; font-weight: 600;  padding-top: 0.4rem !important; ">
                  {{selectedexpense?.ex_type |translate}}</label>
              </span>

              <p-dropdown [style]="{width:selectedexpex_type.offsetWidth-typelabel.offsetWidth-35+'px'}" appendTo="body"
                [options]="expenseNoteType" (ngModelChange)="onSelectedChangeExType()" [(ngModel)]="selectedexpense.ex_type"
                optionValue="ex_type" optionLabel="label" (onClickOutside)="expenseNoteex_type.deactivate()" #selectedType
                (onHide)="expenseNoteex_type.deactivate()" (onSelect)="expenseNoteex_type.deactivate()" pInplaceContent>
              </p-dropdown>
            </p-inplace>

          </div>

        </div>

        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
        *ngIf="selectedexpense?.amount && selectedexpense?.ex_type=='Ticket'; else viewBase "
          class="p-grid p-mb-4">
          <div #selectedexpenseamount class="p-col-12">
            <label #amountlabel class="formattext">{{"ORDERS_FORM_AMOUNT" | translate}} : </label>&nbsp;


            <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.amount}}</label>

              </span>
              <p-inputNumber [min]="0"
                [inputStyle]="{'width':selectedexpenseamount.offsetWidth-amountlabel.offsetWidth-30+'px'}"
                id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
                [(ngModel)]="selectedexpense.amount" mode="decimal" [minFractionDigits]="2"
                (onBlur)="expenseamount.deactivate()" pInplaceContent></p-inputNumber>




            </p-inplace>
          </div>

        </div>
        <ng-template #viewBase>

          <div  style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
          *ngFor="let item of selectedexpense?.items  , let i = index" class="p-grid p-mb-4">

          <div #selectedexpenseamount class="p-col-6 ">
            <label #amoutInput_label class="formattext">{{"ORDERS_FORM_AMOUNT" | translate}} <label
                *ngIf=" selectedexpense?.items.length">{{i+1}}</label> : </label>&nbsp;


            <p-inplace [style]="{display: 'inline-block'}" #expenseamount>

              <span pInplaceDisplay>

                <label style=" color: var(--primary-color) !important; font-weight: 600;  ">{{item.base |
                  number:'1.2-2'}}</label>

              </span>
              <p-inputNumber [min]="0"
                [inputStyle]="{'width':selectedexpenseamount.offsetWidth-amoutInput_label.offsetWidth-30+'px'}"
                id="amount-input" (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"
                [(ngModel)]="item.base" mode="decimal" [minFractionDigits]="2" (onBlur)="expenseamount.deactivate()"
                pInplaceContent></p-inputNumber>
            </p-inplace>
          </div>
          <div #selectedexpensevat class="p-col-6 ">
            <p-inplace [style]="{display: 'inline-block'}" #expenseVat>
                <span pInplaceDisplay>
            <label #vatInput_label class="formattext">{{"CUSTOMER_LIST_VAT" | translate}} {{item.vat_rate}} % :</label>&nbsp;
                            </span>
              <p-inputNumber [min]="0"
                             [inputStyle]="{'width':selectedexpensevat.offsetWidth-vatInput_label.offsetWidth-30+'px'}"
                             (onFocus)="$event.target.select()" (ngModelChange)="notempty($event)"prefix="%" placeholder="VAT %"
                             [(ngModel)]="item.vat_rate" mode="decimal" [minFractionDigits]="2" (onBlur)="expenseVat.deactivate()"
                             pInplaceContent></p-inputNumber>
            </p-inplace>
            <label style=" color: var(--primary-color) !important; font-weight: 600;  ">{{item.base * (item.vat_rate / 100) | number:'1.2-2'}}</label>

          </div>


        </div>
        </ng-template>


        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
          *ngIf="selectedexpense?.vendor_name" class="p-grid p-mb-4">
          <div #vendor_name class="p-col-12">
            <label #vendor_namelabel class="formattext">{{"DASHBORD_VENDOR" | translate}} : </label>&nbsp;


            <p-inplace [style]="{display: 'inline-block'}" #vendor_nameselected>

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vendor?.name}}</label>

              </span>

              <app-supplier-select *ngIf="!loading" (supplierChange)="selectvendor_2($event)" [customstyle]='true'
                [width]='vendor_name.offsetWidth-vendor_namelabel.offsetWidth-30' [(supplier)]="selectedexpense.vendor"
                (onHide)="vendor_nameselected.deactivate()" pInplaceContent>
              </app-supplier-select>






            </p-inplace>
          </div>

        </div>

        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_VATs?.length > 1"
          class="p-grid p-mb-4">
          <div #vendor_name class="p-col-12">
            <label #vendor_namelabel class="formattext">{{"THEY_R" | translate}} {{selected_VATs?.length}}
              {{"VATS_RECEPIT" | translate}}
              <br>
              {{"APROVED_ONE" | translate}} :</label>


            <p-listbox [options]="selected_VATs" [(ngModel)]="selectedexpense.vat" optionLabel="vat" optionValue="vat">
            </p-listbox>


          </div>

        </div>


        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_VATs?.length==1"
          class="p-grid p-mb-4">
          <div #vat_name class="p-col-12">
            <label #vat_label2 class="formattext">Vat : </label>

            <p-inplace [style]="{display: 'inline-block'}" #vat_formatelected>

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vat}}</label>
                &nbsp;

              </span>

              <input [style]="{'width':vat_name.offsetWidth-vat_label2.offsetWidth-30+'px'}" name="selectedexpense.vat"
                [(ngModel)]="selectedexpense.vat" (ngModelChange)="notempty($event)"
                (blur)="vat_formatelected.deactivate()" type="text" pInplaceContent pInputText>


            </p-inplace>



          </div>

        </div>

        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_IBans?.length > 1"
          class="p-grid p-mb-4">
          <div #vendor_name class="p-col-12">
            <label #vendor_namelabel class="formattext">{{"THEY_R" | translate}} {{selected_IBans?.length}}
              {{"IBAN_RECIPT" | translate}}</label>
            <p-listbox [options]="selected_IBans" [checkbox]="true"
            [multiple]="true" [(ngModel)]="selectedexpense.iban" optionLabel="iban"
              optionValue="iban">
            </p-listbox>
          </div>
        </div>


        <div style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  " *ngIf="selected_IBans?.length==1"
          class="p-grid p-mb-4">
          <div #iban class="p-col-12">
            <label #vat_label2 class="formattext">IBAN : </label>&nbsp;

            <p-inplace [style]="{display: 'inline-block'}" #iban_formatelected>

              <span pInplaceDisplay>

                <label
                  style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.iban}}</label>
                &nbsp;

              </span>

              <input [style]="{'width':iban.offsetWidth-vat_label2.offsetWidth-30+'px'}" name="selectedexpense.iban"
                [(ngModel)]="selectedexpense.iban" (ngModelChange)="notempty($event)"
                (blur)="iban_formatelected.deactivate()" type="text" pInplaceContent pInputText>


            </p-inplace>



          </div>

        </div>

        <div *ngIf="selectedexpense?.vcs" style="border-width: 1px ;border-color: #dee2e6; border-style: solid;  "
          class="p-grid p-mb-4">
          <div #vcs_name class="p-col-12">
            <label #vcs_label2 class="formattext">VCS : </label>&nbsp;

            <p-inplace [style]="{display: 'inline-block'}" #vcs_formatelected>

              <span pInplaceDisplay>

                <label style=" color: var(--primary-color) !important; font-weight: 600;  ">{{selectedexpense?.vcs}}
                </label>
                &nbsp;

              </span>

              <input [style]="{'width':vcs_name.offsetWidth-vcs_label2.offsetWidth-30+'px'}" name="selectedexpense.vat"
                [(ngModel)]="selectedexpense.vcs" (ngModelChange)="notempty($event)"
                (blur)="vcs_formatelected.deactivate()" type="text" pInplaceContent pInputText>


            </p-inplace>



          </div>

        </div>
      </div>

    </div>


    <p-footer class="p-pt-4">
      <button *ngIf="selectedexpense?.attachment?.type == 'application/pdf' "
        label='{{"CUSTOMER_LIST_SAVE" | translate}}' (click)="confirmRreceipt_withpdf()" pButton type="button"></button>

      <button *ngIf="selectedexpense?.attachment?.type != 'application/pdf' && tabSelectedRecpit == true" type="button"
        pButton icon="pi pi-check" (click)="confirmRreceipt_withOriginal()"
        label='{{"SAVE_ORIGINAL" | translate}}'></button>
      <button *ngIf="selectedexpense?.attachment?.type != 'application/pdf' && tabSelectedRecpit == false" type="button"
        pButton icon="pi pi-check" (click)="confirmRreceipt_withCropped()"
        label='{{"SAVE_CROPPED" | translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="canceleviewRecpie()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined ui-button-secondary"></button>

    </p-footer>
  </p-dialog>



  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" header="" [(visible)]="showDialogvat" [style]="{width: '20vw'}">

    <div class="p-grid form-group">
      <div class="p-col-12 p-mt-3">
        <span class="p-float-label">

          <p-inputNumber [min]="0" [max]="100" prefix="%" [inputStyle]="{'width':18.2  +'vw'}" #vatInput
            (focus)="$event.target.select()" [(ngModel)]="customVatAmount" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
          <label for="customfield-input">{{"CUSTOMER_LIST_VAT" | translate}} </label>
        </span>
      </div>
    </div>
    <p-footer>
      <button *ngIf="dialogVat=='Add VAT'" type="button" pButton icon="pi pi-check" (click)="addvatInput()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
        <button *ngIf="dialogVat=='Edit VAT'" type="button" pButton icon="pi pi-check" (click)="editVatInput()"
        label='Edit Vat'></button>
      <button type="button" pButton icon="pi pi-times" (click)="showDialogvat=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
