import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsListComponent } from './products-list/products-list.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { ProductsFormComponent } from './products-form/products-form.component';
import { FormsModule } from '@angular/forms';
import { ProductDashboardComponent } from './product-dashboard/product-dashboard.component';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {FileUploadModule} from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {InputNumberModule} from 'primeng/inputnumber';

export const routes: Routes = [
  {path: '', component: ProductsListComponent}
];

@NgModule({
  declarations: [ProductsListComponent, ProductDashboardComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    TableModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    FormsModule,
    InputMaskModule,
    InputTextareaModule,
    OverlayPanelModule,
    TranslateModule,
    ChartModule,ToastModule,FileUploadModule,DialogModule,TooltipModule,
    InputNumberModule
  ]
})
export class ProductsModule { }
