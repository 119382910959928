import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { PurchaseOrders } from '../models/PurchaseOrders/PurchaseOrders';
import { ApiService } from '../modules/core/api.service';
import { isSet } from '../modules/core/util';
@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  public queris = []
  sort: any

  constructor(private api: ApiService) { }
  public showpurchaseOrders: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public purchaseOrdersEmitter: Observable<any> = this.showpurchaseOrders.asObservable();


  getpurchases(form_date: any, to_date: any) {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`jornal/purchases?from_date=${form_date}&to_date=${to_date}&${sum_querirs}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`jornal/purchases?from_date=${form_date}&to_date=${to_date}&order=${this.sort}`)
    }

  }
 
  download_purchasesZip(form_date: any, to_date: any) {
    
    return this.api.getfile(`/jornal/purchases/export_zip_files?from_date=${form_date}&to_date=${to_date}`);
  }

  download_purchasesxls(form_date: any, to_date: any) {
 

    return this.api.getfile(`/jornal/purchases/export_xls_file?from_date=${form_date}&to_date=${to_date}`);
  }

  download_purchasescsv(form_date: any, to_date: any) {
    
    return this.api.getfile(`/jornal/purchases/export_csv_file?from_date=${form_date}&to_date=${to_date}`);
  }
}