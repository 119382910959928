import { isSet } from './../modules/core/util';
import * as _ from 'lodash';
import { OrderItem } from './orders/orderitem';
import { Customer } from './customer';
import { Payment } from './orders/Payment';
import { OrderStatus } from './orders/orderstatus';
import { Address } from './address';
import { Contact } from './contact';
import { Order } from './orders/order';
import { Currency } from './currency';



export class CreditNote {
  id: string;
  customer: Customer;
  company_name: string
  reference: string;
  vcs:string;
  orders:Order[]
  date: Date;
  due_date: Date
  cn_format: string
  cn_date: Date
  items: OrderItem[];
  total: number;
  sub_total: number;
  balance: number;
  terms: string;
  payments: Payment[];
  newpayments: Payment[];
  status: OrderStatus
  total_discount: number;
  notes: string
  invoiced: boolean
  download_url: any
  view_url: any
  loadprint: boolean
  currency:Currency
  currencySign:string
  exchange_rate:number
  exchange_rateStauts:boolean
  discount: any;
  discount_type: any;
  paid_vats: Array<{
		name?: number;
		beforediscont?: number;
		afterdiscont: number;
	}>

  get creditnoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return  _.sumBy(this.items, 'totalNet') || 0
  }
  get beforeDiscont_creditnoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return  _.sumBy(this.items, 'beforediscont_totalNet') || 0
  }

  get creditnoteTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.creditnoteTotal + this.vatAmount_6 + this.vatAmount_21 + this.vatAmount_12 || 0
  }
  get beforeDiscont_creditnoteTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'beforediscont_total') || 0
  }

  get discont_Percentage() {
    if (this.discount_type == 'Percent') {
      
      return this.discount / 100
    }
   const x = this.discount / _.sumBy(this.items, 'beforediscont_totalNet')

    return x
  }

  get creditnoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatAmount') || 0
  }
  get beforediscontcreditnoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_vatAmount') || 0
  }
  get vatAmount_6befordiscont(){
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 6) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_12befordiscont(){
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 12) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_21befordiscont(){
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 21) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_6() {
    // if (this.total_discount > 0) {

    //   const base6=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 6  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0
    // return ( base6-(base6* this.discont_Percentage)) * 0.06
    //   }
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 6) {
        return item.vatAmount

      }
    }) || 0

  }
  get vatAmount_12() {
    //   if (this.total_discount > 0) {

    //   const base12=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 12  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0
    // return  ( base12-(base12* this.discont_Percentage))* 0.12
    //   }


    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 12) {

        return item.vatAmount
      }
    }) || 0

  }
  get vatAmount_21() {
    // if (this.total_discount > 0) {

    //   const base21=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 21  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0


    // return  ( base21-(base21* this.discont_Percentage)) * 0.21
    //   }
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 21) {
        return item.vatAmount

      }
    }) || 0

  }
  public constructor(params?: CreditNote) {
    Object.assign(this, params);
  }



  public static cloneObject(objectToClone: CreditNote) {
    if (!isSet(objectToClone)) {
      return new CreditNote();
    }
    const obj = new CreditNote(objectToClone);
    obj.customer = Customer.cloneObject(objectToClone.customer);
    if (objectToClone.items) {
      obj.items = OrderItem.cloneManyObjects(objectToClone.items);
    }
    if (objectToClone.orders) {
      obj.orders = Order.cloneManyObjects(objectToClone.orders);
    }
    if (isSet(objectToClone.payments)) {
      obj.payments = Payment.cloneManyObjects(objectToClone.payments)
    }
    if (isSet(objectToClone.newpayments)) {
      obj.newpayments = Payment.cloneManyObjects(objectToClone.newpayments)
    }
    obj.status = OrderStatus.cloneObject(objectToClone.status);
    return obj;
  }
  public static cloneManyObjects(objectsToClone: CreditNote[]) {
    const objects: CreditNote[] = [];
    for (const obj of objectsToClone) {
      objects.push(CreditNote.cloneObject(obj));
    }

    return objects;
  }

  public toDB(date, due_date,discont_) {
 
    return {
      reference: this.reference,
      customer: this.customer?.id,
      date: date,
      due_date: due_date,
      orders:  _.map(this.orders, item => {
        return {
          _id: item.id,
              }

      }),
      items: _.map(this.items, item => {
        return {
          created_by: item.product?.created_by,
          created_at: item.created_at,
          updated_at: item.product?.updated_at,
          product: item.product?.id,
          company: item.product?.company,
          name: item?.name,
          qty: item?.qty,
          qtyType: item?.qtyType,
          price: item?.price,
          vat: item?.vat?.id,
          date: item?.date,
          price_type: item?.price_type,
          service: item?.service,
          order:item?.order
        }

      }),
      shipping_address:
      {
        address: this.shipping_address()?.address,
        zip: this.shipping_address()?.zip,
        city: this.shipping_address()?.city,
        country_code: this.shipping_address()?.country_code
      },
      notes: this.notes,
      payments:this.payments,
      currency: this.currency?.id,
      exchange_rate:this.exchange_rate,
      discount: discont_ ?? 0,

    }

  }
  contact() {
    let contactt = new Contact()
    if (isSet(this.customer?.contact)) {
      contactt = this.customer?.contact[0]
    }

    return contactt
  }
  shipping_address() {
    let shipping_address = new Address()
    if (isSet(this.customer?.shipping_address)) {
      shipping_address = this.customer.shipping_address[0]
    }
    return shipping_address
  }


}
