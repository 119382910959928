import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Supplier } from '../../models/supplier';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { Payment } from 'src/app/models/orders/Payment';
import { isSet } from '../core/util';


@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  public currentSupplier: Supplier;
  public newPayments: Payment[] = []
  public printLang: string;
  public queris = []
  public sort: any

  public supplierSelected: BehaviorSubject<Supplier> = new BehaviorSubject<Supplier>(null);
  public supplierSelectedEmitter: Observable<Supplier> = this.supplierSelected.asObservable();

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public showaftersaveForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showaftersaveFormdEmitter: Observable<boolean> = this.showaftersaveForm.asObservable();

  private refreshSupplierList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshSupplierListEmitter: Observable<boolean> = this.refreshSupplierList.asObservable();


  constructor(private api: ApiService) { }

  getVendors(): Observable<PaginatedResponse<Supplier[]>> {

    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?${sum_querirs}&order=${this.sort}`);
    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?order=${this.sort}`);

    }
  }

  getVendorsNextPage(num): Observable<PaginatedResponse<Supplier[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?page=${num}&order=${this.sort}`);

    }
  }

  getSupplier(id: string): Observable<Supplier> {
    return this.api.get<Supplier>(`/vendors/rud/${id}`);
  }

  // DeleteCustomers(ids:Supplier[]): Observable<Supplier> {
  //   return this.api.delete<Supplier>(`/customers?action=delete`,ids);
  // }



  DeleteSupplier(id: string): Observable<Supplier> {
    return this.api.delete<Supplier>(`/vendors/rud/${id}`);
  }

  addSupplier(Supplier: Supplier): Observable<Supplier> {
    return this.api.post<Supplier>(`/vendors`, Supplier.toDB());
  }

  updateSupplier(Supplier: Supplier): Observable<Supplier> {
    return this.api.put<Supplier>(`/vendors/rud/${Supplier.id}`, Supplier.toDB());
  }

  selectSupplier(Supplier: Supplier) {
    this.supplierSelected.next(Supplier);
  }
  VendorsNextPage(num): Observable<PaginatedResponse<Supplier[]>> {
    return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?page=${num}`);
  }
  serachSupplier(value): Observable<PaginatedResponse<Supplier[]>> {
    return this.api.get<PaginatedResponse<Supplier[]>>(`/vendors?query=name,startswith,${value}`);
  }
  filterByVat(vat): Observable<any>{
    return this.api.get<any>(`/vendors?query=vat,startswith,${vat}`);
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }

  refreshVendors() {
    this.refreshSupplierList.next(true);
  }
}
