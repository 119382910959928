import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { UserToken } from 'src/app/models/usertoken';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent  extends BaseComponent implements OnInit {


  public loading = false;
  registration_token:string
  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private router: Router

  ) {
    super();
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.registration_token =params.token;
      
      if(!isSet(this.registration_token)){
        return this.router.navigateByUrl('auth/login')
      }
      this.register_confirmation()
    })

  }
  public register_confirmation(): void {
    try {
      this.loading = true;
      const subscription = this.authService.register_confirmation(this.registration_token).subscribe((data: UserToken) => {
        this.loading = false;
        if (!isSet(data)) {
          return;
        }
        localStorage.setItem('user', JSON.stringify(data.user_data));
        localStorage.setItem('token_data', JSON.stringify(data.token_data));
        this.router.navigate(['/']);
      }, error => {
        this.loading = false;
        if (isSet(error.error.detail)) {
          setTimeout(()=>{
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail});

          },1000)
        }
        this.router.navigate(['/']);

        this.log('error', error.error.detail);
      });
      this.subscriptions.push(subscription);
    } catch (e) {
      this.handleError(e);
    }
  }

}
