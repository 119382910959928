import { Payment } from 'src/app/models/orders/Payment';
import { OrderItem } from './../../models/orders/orderitem';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { Order } from '../../models/orders/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SendEmail } from 'src/app/models/SendEmail';
import { DatePipe } from '@angular/common';
import { isSet } from '../core/util';
import { Customer } from 'src/app/models/customer';
import { Product } from 'src/app/models/product';
import { CreditnotesCustomerService } from '../CreditNotes-customers/creditnotescustomers.service';
import { CreditNote } from 'src/app/models/CreditNotesCustomer';
import { Currency } from 'src/app/models/currency';

@Injectable({
	providedIn: 'root'
})
export class OrdersService {
	public printLang: string;
  queris = [];
	sort: any;
	currency = new Currency();
	public newPayments: Payment[] = [];

	public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

	public updateFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public updateFormClickedEmitter: Observable<boolean> = this.updateFormClicked.asObservable();

	public ConvertInvoiceClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public ConvertInvoiceClickedEmitter: Observable<boolean> = this.ConvertInvoiceClicked.asObservable();

	public ConvertInvoiceStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public ConvertInvoiceStatusEmitter: Observable<boolean> = this.ConvertInvoiceStatus.asObservable();

	public retrieve_lastOrder: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
	public retrieve_lastOrderEmitter: Observable<Order> = this.retrieve_lastOrder.asObservable();

	public sendEmailClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public sendEmailClickedEmitter: Observable<boolean> = this.sendEmailClicked.asObservable();

	public printOrderLink: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public printOrderLinkEmitter: Observable<any> = this.printOrderLink.asObservable();

	public duplicateClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public duplicateClickedEmitter: Observable<boolean> = this.duplicateClicked.asObservable();

	public convertDraft: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public convertDraftClickedEmitter: Observable<boolean> = this.convertDraft.asObservable();

	public unlockInovice: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public unlockInoviceEmitter: Observable<boolean> = this.unlockInovice.asObservable();

	public disableUnlockInvoice: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public disableUnlockInvoiceEmitter: Observable<boolean> = this.disableUnlockInvoice.asObservable();

	public btnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public btnLoadingEmitter: Observable<boolean> = this.btnLoading.asObservable();

	private refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();

	public refreshSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public refreshSelectedEmitter: Observable<boolean> = this.refreshSelected.asObservable();

	public currencyChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public currencyChangedEmitter: Observable<any> = this.currencyChanged.asObservable();

	public orderSelected: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
	public orderSelectedListEmitter: Observable<Order> = this.orderSelected.asObservable();

	public QuoteToOrder: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
	public QuoteToOrderListEmitter: Observable<Order> = this.QuoteToOrder.asObservable();

	public orderToCredit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public orderToCreditEmitter: Observable<boolean> = this.orderToCredit.asObservable();

	constructor(private api: ApiService,
    private router: Router,
    private datePipe: DatePipe,
    private creditnotesCustomerService: CreditnotesCustomerService) {
	}

	getOrders(): Observable<PaginatedResponse<Order[]>> {
		if (this.queris.length) {
			const querirs_ = [...this.queris];
			const length: number = querirs_.length - 1;
			for (let index = 0; index < querirs_.length; index++) {
				if (isSet(querirs_[index].query_type)) {
					querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`;
					querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index];
				} else {
					querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`;
					querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index];
				}
			}
			let sum_querirs = '';
			for (let index = 0; index < querirs_.length; index++) {
				sum_querirs = sum_querirs + querirs_[index];
			}
			return this.api.get<PaginatedResponse<Order[]>>(`/orders?${sum_querirs}&order=${this.sort}`);
		}

		if (!this.queris.length) {
			return this.api.get<PaginatedResponse<Order[]>>(`/orders?order=${this.sort}`);
		}
	}

	ordesrNextPage(num): Observable<PaginatedResponse<Order[]>> {
		if (this.queris.length) {
			const querirs_ = [...this.queris];
			const length: number = querirs_.length - 1;
			for (let index = 0; index < querirs_.length; index++) {
				querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`;
				querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index];
			}
			return this.api.get<PaginatedResponse<Order[]>>(`/orders?${querirs_}&page=${num}&order=${this.sort}`);
		}
		if (!this.queris.length) {
			return this.api.get<PaginatedResponse<Order[]>>(`/orders?page=${num}&order=${this.sort}`);
		}
	}

	getOrder(id: string): Observable<Order> {
		return this.api.get<Order>(`/orders/rud/${id}`);
	}

	getlast_invoiced_order(): Observable<Order> {
		return this.api.get<Order>('/orders/last_invoiced_order');
	}

	QouteToOrder(order: Order) {
		this.QuoteToOrder.next(order);
	}

	getNextOrderNumber(): Observable<{ format: string }> {
		return this.api.get<{ format: string }>('/orders/next_format');
	}

	getNextInoviceNumber(): Observable<{ format: string }> {
		return this.api.get<{ format: string }>('/orders/next_invoiced_format');
	}

	addOrder(order: Order): Observable<Order> {
		let discont_ = order.discount;
		if (order.discountType == 'Percent') {
			discont_ = order.discount + '%';
		}
		const orderDate = this.datePipe.transform(order.date, 'yyyy-MM-dd');
		const dueDate = this.datePipe.transform(order.due_date, 'yyyy-MM-dd');
		for (let x = 0; x < order.items.length; x++) {
			if (isSet(order.items[x].service)) {
				order.items[x].name = null;
				order.items[x].product = new Product();
			}
		}
		return this.api.post('/orders', order.toDB(orderDate, dueDate, discont_));
	}

	saveForm() {
		this.saveFormClicked.next(true);
	}

	refreshDataTable() {
		this.refreshList.next(true);
	}

	selectOrder(order: Order) {
		this.orderSelected.next(order);
	}

	orderdelete(id: string): Observable<Order> {
		return this.api.delete<Order>(`/orders/rud/${id}`);
	}

	getorderbyname(oderformat: string): Observable<Order> {
		return this.api.get<Order>(`/orders/${oderformat}/using_format`);
	}

	UpadeOrder(order: Order) {
		const date = this.datePipe.transform(order.date, 'yyyy-MM-dd');
		const due_date = this.datePipe.transform(order.due_date, 'yyyy-MM-dd');
		const invoice_date = this.datePipe.transform(order.invoice_date, 'yyyy-MM-dd');
		let discount = order.discount;
		if (order.discountType == 'Percent') {
			discount = order.discount + '%';
		}
		const itemstoUpdate = [];
		for (let x = 0; x < order.items.length; x++) {
			order.items[x].price = Number(order.items[x]?.price);
			order.items[x].qty = Number(order.items[x]?.qty);
			order.items[x].vat.value = Number(order.items[x]?.vat.value);
			order.items[x].date = this.datePipe.transform(order.items[x]?.datePipe, 'yyyy-MM-dd');
			itemstoUpdate[x] = {
				id: order.items[x]?.id,
				product: order.items[x]?.product?.id,
				name: order.items[x]?.name,
				qty: order.items[x]?.qty,
				price: order.items[x]?.price,
				vat: order.items[x]?.vat?.id,
				date: order.items[x]?.date,
				service: order.items[x]?.service
			};
		}
		const update = {
			date: date,
			due_date: due_date,
			customer: order.customer?.id,
			notes: order.notes,
			payment_terms: order?.payment_terms,
			payments: this.newPayments,
			items: itemstoUpdate,
			invoice_date: invoice_date,
			discount: discount,
			exchange_rate: order?.exchange_rate
		};
		update?.payments.map(payment => {
			payment.amount = Number(payment?.amount);
			if (payment?.type == 'Wire Transfer') {
				payment.type = 'wt';
			}
		});
		return this.api.patch<any>(`/orders/rud/${order.id}`, update);
	}

	Reload() {
		this.QouteToOrder(null);
		const currentRoute = this.router.url.split('?')[0];
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate([currentRoute]);
		});
	}

	sendEmail(sendEmail: SendEmail, id: string): Observable<SendEmail> {
		return this.api.post(`/orders/send_mail/${id}`, sendEmail.toDB());
	}

	PrintOrder(id: string): Observable<any> {
		let printLang;
		if (!isSet(this.printLang)) {
			printLang = JSON.parse(localStorage.getItem('currentLang'))?.lang.toLocaleLowerCase() || 'en';
		} else {
			printLang = this.printLang;
		}
		const body = {
			lang: printLang.toLocaleLowerCase()
		};
		return this.api.post<any>(`/orders/print_order/${id}`, body);
	}

	printDeliverNote(id: string): Observable<any> {
		let printLang;
		if (!isSet(this.printLang)) {
			printLang = JSON.parse(localStorage.getItem('currentLang'))?.lang.toLocaleLowerCase() || 'en';
		} else {
			printLang = this.printLang;
		}
		const body = {
			lang: printLang.toLocaleLowerCase()
		};
		return this.api.post<any>(`/orders/print_delivery_note/${id}`, body);
	}

	convertToInvoice(order: Order) {
		function  addDays(date, days) {
			const result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}
		order.invoice_date;
		const invoice = {
			invoice_format: order.invoice_format ,
			invoice_date: addDays(order.invoice_date,1)
		};
		return this.api.post<any>(`/orders/invoice/${order.id}`, invoice);
	}

	convertToDraft(id: string, customerID: string) {
		const body = {
			customer: customerID
		};
		console.log(body);

		return this.api.post<any>(`/orders/draft/${id}`, body);
	}

	unlockInvoice(id: string) {
		return this.api.post<any>(`/orders/unlock/${id}`, '');
	}

	quotetoOrder(id: string) {
		return this.api.post<any>(`/quotes/complete/${id}`, '');
	}

	removeEmpty(obj) {
		const newObj = {};
		Object.entries(obj).forEach(([k, v]) => {
			if (v === Object(v)) {
				newObj[k] = this.removeEmpty(v);
			} else if (v != null) {
				newObj[k] = obj[k];
			}
		});
		return newObj;
	}

	create_Creditnote(order: Order) {
		this.router.navigateByUrl('sell/creditnotes').then(() => {
			const credit = new CreditNote();
			credit.customer = order.customer;
			credit.items = [];
			order.items.map(item => {
				item.exchange_rate=1;
				item.id = null;
				item.order = order.format;
			});
			credit.items= order.items;
			credit.orders = [];
			credit.orders.push(order);
			credit.currency = order.currency;
			credit.exchange_rate = 1;
			credit.discount = order?.discount;
			credit.discount_type = order?.discountType;
			this.creditnotesCustomerService.importform_OrdersModule.next(credit);
		});
	}
}
