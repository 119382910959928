import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent extends BaseComponent implements OnInit {

  @Output() public add = new EventEmitter();
  @Output() public add2 = new EventEmitter();
  @Output() public remove = new EventEmitter();
  @Output() public Reload = new EventEmitter();
  @Input() public hasFilter: boolean;
  @Input() public addLabel: string;
  @Input() public addLabel2: string;
  @Input() public disableRemove = true
  @Input() pur:PurchaseOrders
  filled: boolean

  constructor(private route: Router,public translate: TranslateService,
     private ShareSrv:SharedService,) {
    super();
  }
  url: string
  public actionsItems = [
    {label: this.translate.instant('IMPO_CSV')},
    {label: this.translate.instant('Export')}
  ];

  ngOnInit(): void {
    if (!isSet(this.addLabel)) {
      this.addLabel = this.translate.instant('NOTES_CUSTOMERS_LIST_CREATE');
    }else if(!isSet(this.addLabel2)){
      this.addLabel2 = null
    }
    this.url = this.route.url
  }

  rm() {
    this.remove.emit()
  }

  ad() {
    this.add.emit()
  }
  ad2($event:PurchaseOrders) {
    this.add2.emit($event)
  }

  
 
}
