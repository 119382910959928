import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { TableModule } from 'primeng/table';
import { RouterModule, Routes } from '@angular/router';
import { CustomerService } from './customer.service';
import { SharedModule } from '../shared/shared.module';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';

import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RippleModule } from 'primeng/ripple';
import { InputNumberModule } from 'primeng/inputnumber';
export const routes: Routes = [
  { path: '', component: CustomersListComponent }
];

@NgModule({
  declarations: [CustomersListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    PdfViewerModule,
    TableModule,
    SharedModule,
    SidebarModule,
    ToolbarModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    DialogModule,
    PaginatorModule,
    FileUploadModule,
    OverlayPanelModule,
    ChartModule,
    InputNumberModule,
    ToastModule, InputMaskModule,
    AutoCompleteModule, TooltipModule, TranslateModule, InputSwitchModule, SplitButtonModule, RippleModule
  ],
  providers: [CustomerService, MessageService]
})
export class CustomersModule {
}
