import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserToken } from '../../models/usertoken';
import { ApiService } from '../core/api.service';
import { CountryCode_currency } from 'src/app/models/country_code&currency';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  placeQurey

  constructor(private httpClient: HttpClient, private api: ApiService) {
  }

  public login(email: string, password: string): Observable<UserToken> {
    return this.httpClient.post<UserToken>(`${environment.apiUrl}/login`, {
      email,
      password
    });
  }
  public register_confirmation(registration_token: string): Observable<UserToken> {
    return this.httpClient.get<UserToken>(`${environment.apiUrl}/register_confirmation/${registration_token}`)
  }
  public change_email(token: string): Observable<any> {

    return this.api.get<any>(`/users/change_email/${token}`)
  }
  public register(body): Observable<any> {
    console.log(body)
    return this.api.postGuest('/register', body)
  }
  public countries(): Observable<CountryCode_currency[]> {
    return this.api.getGuest('/countries/list')
  }
  public ipInfo(): Observable<any> {
    return this.httpClient.get('https://ipinfo.io/json?token=e7c04903fb0a2a')
  }

  public sendEmail(body): Observable<any> {
    return this.api.postGuest('/users/reset_password', body)
  }
  public setPassword(body: any): Observable<any> {
    const new_password = {
      new_password: body.new_password
    }
    return this.api.postGuest(`/users/set_password/${body.token}`, new_password)
  }

}
