<p-toast key="list" position="top-right"></p-toast>
<p-toast position="bottom-right" key="first" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i style="font-size: 3rem"></i>
        <h4>{{"COMPLETE_CO_INFO" |translate}} ? </h4>

      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-6">
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
        </div>
        <div class="p-col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right" key="reject" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i style="font-size: 3rem"></i>
        <h4>
          <h2 style="font-size: 1.5rem" class="pi pi-arrow-up"></h2> {{"SEEETING_WINDOW" |translate}} <h2
            style="font-size: 1.5rem" class="pi pi-cog"></h2>
        </h4>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-center" key="confirm" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i style="font-size: 3rem"></i>
        <h4> {{"CAN_COMPLETE_INFO_WINDOW" |translate}} <h2 style="font-size: 1.5rem" class="pi pi-arrow-right"></h2>
          <br> {{"GOOD_TIME" |translate}} !
        </h4>
      </div>
    </div>
  </ng-template>
</p-toast>
<app-loading *ngIf="loading"></app-loading>

<div style="padding-top: 0px !important;" class="layout-content ">
  <div class="p-grid  ">

    <div class="p-col-12 p-xl-3  p-md-6 pionter">
      <div pRipple (click)="op4.toggle($event)" class="p-grid ripple  p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Quote/qoutes.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_QUOTES" |translate}}</h5>

          <span class="overview-box-status ">{{all_quote_counts}}</span>


        </div>

      </div>

    </div>
    <div class="p-col-12 p-xl-3  p-md-6 pionter">
      <div pRipple (click)="op1.toggle($event)" class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Sell/Invoice.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_INVOICE" |translate}}</h5>
          <span class="overview-box-status">{{invoiced_order_count}}</span>
        </div>
      </div>



    </div>


    <div class="p-col-12  p-xl-3 p-md-6 pionter">



      <div pRipple (click)="op3.toggle($event)" class="p-grid ripplep  p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Sell/order.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_ORDERS" |translate}}</h5>
          <span class="overview-box-status">{{all_order_counts}}</span>
        </div>
      </div>


    </div>

    <div class="p-col-12 p-xl-3 p-md-6 pionter">
      <div pRipple (click)="op2.toggle($event)" class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
        <div class="p-col-3 overview-box-icon">
          <img src="assets/icons/Purchase Order/Outstanding Purchase.svg" alt="mirage-layout">
        </div>
        <div class="p-col-6 overview-box-text">
          <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_PURCHASES" |translate}}</h5>
          <span class="overview-box-status">{{purchasesCount}}</span>
        </div>
      </div>


    </div>
  </div>
  <div class="p-col-12 p-md-12 ">

    <div class="p-grid ">
      <div class="p-col-6 p-colsm-12  col-4-r">
        <div class="p-grid p-dir-col p-nogutter">
          <div class="p-col-12 ">
            <div class="card statistics">
              <div class="p-grid">
                <div class="p-col-6 p-md-8">
                  <h5 style="font-weight: 600;" class="p-text-secondary">{{"DASHBORD_RECIVES_VS_PAYABLE" | translate}}
                  </h5>
                </div>
                <div class="p-col-6 p-md-4 dropdown">
                  <p-dropdown (onChange)="chooseYearFilter()" [options]="dropdownYears" [(ngModel)]="selectedYear">
                  </p-dropdown>
                </div>
              </div>
              <p-chart id="dashboard-chart" #chart type="line" [data]="cashInLine" [options]="barChartOptions">
              </p-chart>
            </div>
          </div>
        </div>



      </div>
      <div class="p-col-6 col-4-r">
        <div class="p-grid">


          <div class="p-col-6 col-4-r pionter">
            <div pRipple (click)="op7.toggle($event)"
              class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Outstanding Customer payment/Outstanding Customer  payment.svg"
                  alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_DUE_CUSTOMERS_PAYMENTS" | translate}}</h5>
                <span class="overview-box-status">{{dueCustomerPaymentsCount | number:'1.2-2'}}</span>
              </div>
            </div>
          </div>

          <div class="p-col-6 col-4-r pionter">
            <div pRipple (click)="op12.toggle($event)">

              <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                <div class="p-col-3 overview-box-icon">
                  <img src="assets/icons/Outstanding Supplier  Payment/Outstanding supplier Payment.svg"
                    alt="mirage-layout" />
                </div>
                <div class="p-col-6 overview-box-text">
                  <h5 class="p-text-secondary">{{"DASHBORD_DUE_SUPPLIERS_PAYMENTS" | translate}}</h5>
                  <span class="overview-box-status auto">{{dueVendorsPaymentsCount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-6 col-4-r pionter">
            <div pRipple (click)="op13.toggle($event)"
              class="p-grid ripplep p-nogutter widget-overview-box overview-box-4">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Outstanding Customer payment/Outstanding Customer  payment.svg"
                  alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_OUTSTANDING_CUSTOMER_PAYMENTS" |translate}}</h5>
                <span class="overview-box-status">{{outCustomerPayCount }} </span>
              </div>
            </div>
          </div>
          
          <div class="p-col-6 col-4-r pionter">
            <div pRipple (click)="op14.toggle($event)">

              <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                <div class="p-col-3 overview-box-icon">
                  <img src="assets/icons/Outstanding Supplier  Payment/Outstanding supplier Payment.svg"
                    alt="mirage-layout" />
                </div>
                <div class="p-col-6 overview-box-text">
                  <h5 class="p-text-secondary">{{"DASHBORD_OUTSTANDING_SUPPLIER_PAYMENTS" |translate}}</h5>                
                  <span class="overview-box-status auto">{{outSuppliersPayCount }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-col-6 col-4-r">
            <div class="p-grid p-nogutter widget-overview-box overview-box-2">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Outstanding Credit Note/Outstanding Credit Note.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_CREDITES_IN" | translate}}</h5>
                <span class="overview-box-status">440</span>
              </div>
            </div>
          </div>

          <div class="p-col-6 col-4-r">
            <div class="p-grid p-nogutter widget-overview-box overview-box-2">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Outstanding Credit Note/Outstanding Credit Note.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_OUSTANDING_CREDITES_OUT" | translate}}</h5>
                <span class="overview-box-status">532</span>
              </div>
            </div>
          </div> -->

          <!-- <div class="p-col-6 col-4-r">
            <div class="p-grid p-nogutter widget-overview-box overview-box-2">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Time Sheet/Time Sheet.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_Time_COMPLETE" | translate}}</h5>
                <span class="overview-box-status">440</span>
              </div>
            </div>
          </div> -->

          <div class="p-col-6 col-4-r pionter">
            <div class="p-grid p-nogutter widget-overview-box overview-box-4" (click)="op19.toggle($event)">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Calendar/Calendar.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_CALENDER&TASKS" | translate}} </h5>
                <span class="overview-box-status">{{getEventsCounts }}</span>
              </div>
            </div>
          </div>

          <div class="p-col-6 col-4-r">
            <div class="p-grid p-nogutter widget-overview-box overview-box-1">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Cashflow/Cashflow.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_CASH_POSITION" | translate}}</h5>
                <span class="overview-box-status">{{cashPostion | number:'1.2-2'}}</span>
              </div>
            </div>
          </div>

          <div class="p-col-6 col-4-r pionter">
            <div class="p-grid p-nogutter widget-overview-box overview-box-4" (click)="op18.toggle($event)">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/EXpenses/EXpenses.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_EXPENSES" | translate}}</h5>
                <span class="overview-box-status">{{expenseCounts}}</span>
              </div>
            </div>
          </div>
          <div class="p-col-6 col-4-r">
            <div class="p-grid p-nogutter widget-overview-box overview-box-4">
              <div class="p-col-3 overview-box-icon">
                <img src="assets/icons/Casher/casher.svg" alt="mirage-layout" />
              </div>
              <div class="p-col-6 overview-box-text">
                <h5 class="p-text-secondary">{{"DASHBORD_CASHER" | translate}}</h5>
                <span class="overview-box-status">752</span>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
  <div class="p-grid p-mt-2">
    <div class="p-col-6 ">
      <div class="card statistics">
        <div class="p-grid ">

          <div class="p-col-12 ">
            <h5 class="p-text-secondary">{{"DASHBORD_MOST_SOLD_PRO" | translate}}</h5>

          </div>

          <p-table styleClass="p-datatable-striped " id="dashbored_table" [value]="topProduct" class="p-col-12 ">
            <ng-template pTemplate="header">
              <tr>
                <!-- <th class="p-col-1" >No.</th> -->
                <th> No </th>
                <!-- <th pSortableColumn='company_name'>Custmoer</th>
              <th pSortableColumn='payments'>Payments</th> -->
                <th>{{"PRODUCTS" | translate}} </th>
                <th pSortableColumn="count"> {{"QUANTITY" | translate}} <p-sortIcon field="count"></p-sortIcon>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-Product let-i="rowIndex">
              <tr>
                <td>{{i+1}}</td>
                <td> <a href="javascript:;" (click)="displayitem(Product,'Product')">{{Product.name}}</a></td>
                <td>{{Product.count }}</td>

              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="p-col-6 ">
      <div class="card statistics">
        <div class="p-grid ">

          <div class="p-col-12 ">
            <h5 style="font-weight: 600;" class="p-text-secondary">{{"BRANDS" | translate}}
            </h5>
          </div>
          <div class="p-col-12 ">

            <p-chart #BrandChart id="dashboard-chart" type="bar" [data]="saleByBrandsBar" height='460' responsive='true'
              [options]="lineChartOptions"></p-chart>
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="p-grid  p-mt-2">

    <div class="p-col-6 ">
      <div class="card statistics">
        <div class="p-grid">
          <div class="p-col-12">
            <h5 class="p-text-secondary">{{"DASHBORD_TOP_CUSTOMERS" | translate}}</h5>
          </div>
          <div class="p-col-12">

            <p-table styleClass="p-datatable-striped " id="dashbored_table" [value]="topCustmer">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 7%"> No </th>
                  <th style="width: 40%">{{"QUOTES_LIST_CUSTOMER" | translate}} </th>
                  <th>{{"ORDERS_FORM_PAYMENTS" | translate}} </th>
                  <th>{{"DASHBORD_BLANCE" | translate}} </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-Custmoer let-i="rowIndex">
                <tr>
                  <td>{{i+1}}</td>
                  <td> <a href="javascript:;" (click)="displayitem(Custmoer,'Custmoer')">{{Custmoer.company_name}}</a>
                  </td>
                  <td>{{Custmoer.amount | number:'1.2-2' }} </td>
                  <td>{{Custmoer.due | number:'1.2-2'}} </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </div>
      </div>
    </div>
    <div class="p-col-6 ">
      <div class="card statistics">
        <div class="p-grid">
          <div class="p-col-12 ">
            <h5 style="font-weight: 600;" class="p-text-secondary">{{"REGIONS" | translate}}
            </h5>
          </div>
          <!-- <div class="p-col-6 p-md-4 dropdown" >
          <p-dropdown [options]="dropdownYears"  [(ngModel)]="selectedYear"></p-dropdown>
        </div> -->
          <div class="p-col-12 ">

            <p-chart #RegionChart id="dashboard-chart" type="bar" height='460' responsive='true'
              [data]="saleByRegionBar" [options]="lineChartOptions"></p-chart>
          </div>
        </div>

      </div>
    </div>
  </div>

  <p-overlayPanel #op1 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="Invoice" [style]="{width: '600px'}" [autoLayout]="true "
        [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"DASHBORD_INVOICE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-order let-i="rowIndex">


          <tr>
            <td class="p-col-4"><a href="javascript:;" (click)="displayitem(order,'Order')">{{ order.format}}</a></td>
            <td class="p-col-4">{{ order.company_name }}</td>
            <td class="p-col-4">{{ order.date |date:'dd-MM-yyyy'}}</td>
            <td class="p-col-4">{{ order.total | number:'1.2-2'}}

              <span style="color: var(--primary-color);font-size: small;">{{
                order.currency?.name }}</span>
            </td>

          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_INVOICE" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <div style="width: 100%;">
        <button class="p-button-raised  p-button-text" *ngIf="Invoice?.length" (click)="filterDashboard('invoices')"
          style="padding-top: 10px; " pButton type="button" label='{{"VIEW_OUT_INVO" | translate}}'
          [routerLink]="['/customers/orders']"></button>
      </div>
    </ng-template>

  </p-overlayPanel>

  <p-overlayPanel id="op2" #op2 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="purchases" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"PUR_ORDER" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_DATE" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-purchases let-i="rowIndex">
          <tr>

            <td class="p-col-4"><a href="javascript:;"
                (click)="displayitem(purchases,'Purchases');selectedIndex=i">{{purchases.internal_reference }}</a></td>
            <td class="p-col-4">{{ purchases.company_name }}</td>
            <td class="p-col-4">{{ purchases.date |date:'dd-MM-yyyy'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_PURCHASES" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <button class="p-button-raised p-button-text" (click)="filterDashboard('purchase')" *ngIf="purchases?.length"
        style="padding-top: 10px; " pButton type="button"
        label='{{"DASHBORD_VIEW_OUTSTANDING_PURCHASE" | translate}}'></button>

    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel id="op18" #op18 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="expense" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"QUOTES_LIST_QUOTE_NO" | translate}}</th>
            <th class="p-col-4">{{"DASHBORD_VENDOR" | translate}}</th>
            <th class="p-col-4">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-expense let-i="rowIndex">
          <tr>

            <td class="p-col-4" (click)="displayitem(expense,'Expense')">
              <a href="javascript:;">{{expense.format }}</a></td>
            <td class="p-col-4">{{ expense.vendor_name }}</td>
            <td class="p-col-4">{{ expense.total | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">No Expenses Yet</td>
          </tr>
        </ng-template>
      </p-table>
      <button class="p-button-raised p-button-text" (click)="routing('expense')" *ngIf="expense?.length"
        style="padding-top: 10px; " pButton type="button" label='{{"VIEW_EXPENSE" | translate}}'></button>

    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel id="op19" #op19 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="events" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-4">{{"TITLE" | translate}}</th>
            <th class="p-col-4">{{"START_DATE" | translate}}</th>
            <th class="p-col-4">{{"END_DATE" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-events>
          <tr>

            <td class="p-col-4"><a href="javascript:;">{{events.title }}</a></td>
            <td class="p-col-4">{{ events.startP }}</td>
            <td class="p-col-4">{{ events.endP }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">No Events Yet</td>
          </tr>
        </ng-template>
      </p-table>
      <button class="p-button-raised p-button-text" (click)="routing('erp')" *ngIf="purchases?.length"
        style="padding-top: 10px; " pButton type="button" label='{{"VIEW_CAL" | translate}}'></button>

    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op3 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="orders" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-3">{{"DASHBORD_ORDER" | translate}}</th>
            <th class="p-col-3">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-3">{{"DASHBORD_DATE" | translate}}</th>
            <th class="p-col-3 text-justify ">{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order let-i="rowIndex">
          <tr>
            <td class="p-col-3 text-left"><a href="javascript:;" (click)="displayitem(order,'Order');selectedIndex=i">{{
                order.format
                }}</a></td>
            <td class="p-col-3 text-justify">{{ order.company_name }}</td>
            <td class="p-col-3 text-left">{{ order.date |date:'dd-MM-yyyy'}}</td>
            <td class="p-col-3 text-left">{{ order.total | number:'1.2-2'}} </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_ORDERS" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <button class="p-button-raised p-button-text" (click)="filterDashboard('orders')" *ngIf="orders?.length"
        [routerLink]="['/customers/orders']" style="padding-top: 10px; " pButton type="button"
        label='{{"DASHBORD_VIEW_OUTSTANDING_ORDERS" | translate}}'></button>

    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op4 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="quotes" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-col-3">{{"DASHBORD_QUOTE" | translate}}</th>
            <th class="p-col-3">{{"QUOTES_LIST_CUSTOMER" | translate}}</th>
            <th class="p-col-3">{{"QUOTES_LIST_EXPIRY_DATE" | translate}}</th>
            <th class="p-col-3">{{"QUOTES_LIST_TOTAL" | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-quotes let-i="rowIndex">
          <tr>
            <td class="p-col-3"><a href="javascript:;" (click)="displayitem(quotes,'Quotes');selectedIndex=i">{{
                quotes.format }}</a>
            </td>
            <td class="p-col-3">{{ quotes.company_name }}</td>
            <td class="p-col-3">{{ quotes.expiry_date |date:'dd-MM-yyyy'}}</td>
            <td class="p-col-3"> {{ quotes.total | number:'1.2-2'}}
              <span style="color: var(--primary-color);font-size: small;">{{
                quotes.currency?.name }}</span>
            </td>
          </tr>

        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="1">{{"DASHBORD_NO_OUTSTANDING_QUOTES" | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <button class="p-button-raised p-button-text" [routerLink]="['/customers/quotes']"
        (click)="filterDashboard('quotes')" *ngIf="quotes?.length" style="padding-top: 10px; " pButton type="button"
        label='{{"DASHBORD_VIEW_OUTSTANDING_QUOTES" | translate}}'></button>

    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op7 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="customerPaymentsLsit" [style]="{width: '600px'}"
        [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"DASHBORD_CUSTOMER" | translate}}</th>
            <th>{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-i="rowIndex">
          <tr>
            <!-- (click)="displayitem(customer,'Custmoer')" -->
            <td><a href="javascript:;" (click)="displayitem(customer,'Order')" >{{ customer.company_name }}</a></td>
            <td>{{ customer.total | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>

    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #op13 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="outCustomerPay" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th  >{{"DASHBORD_CUSTOMER" | translate}}</th>
            <th>{{"QUOTES_LIST_TOTAL" | translate}}</th>
            <th>{{"DASHBORD_CREDIT_LIMITS" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td ><a href="javascript:;" (click)="displayitem(customer,'Order')">{{ customer.company_name }}</a></td>
            <td [ngClass]="{credit: customer.total > customer.credit_limit}">{{ customer.total | number:'1.2-2'}}</td>
            <td style="text-align: center;">{{ customer.credit_limit | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>

    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #op14 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="outSuppliersPay" [style]="{width: '600px'}"
        [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"DASHBORD_VENDORS" | translate}}</th>
            <th>{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-i="rowIndex">
          <tr>
            <td><a href="javascript:;" (click)="displayitem(customer,'Purchases')">{{ customer.company_name }}</a></td>
            <td>{{ customer.total | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>

    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #op12 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="SuppliersPaymentsList" [style]="{width: '600px'}"
        [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"DASHBORD_VENDOR" | translate}}</th>
            <th>{{"QUOTES_LIST_TOTAL" | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vendor>
          <tr>
            <td><a href="javascript:;" (click)="displayitem(vendor,'Purchases')">{{ vendor.company_name }}</a></td>
            <td>{{ vendor.total | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>

    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #op10 [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>
      <p-table styleClass="p-datatable-striped " [value]="products" [style]="{width: '600px'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"DASHBORD_PRODUCT" | translate}}</th>
            <th>{{"DASHBORD_DATE" | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
          <tr>
            <td>{{ product.title }}</td>
            <td>{{ product.date |date:'dd-MM-yyyy'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>
  <app-sidebar (keydown)="onKeydown($event)"  [(visible)]="showSidebar" [header_body]="modalTitle" [title]="sidebar_header" [title2]="statusTitle"
    [size]="size">
    <div header>
      <div *ngIf="sidebarName=='Order'">
        <button *ngIf="selected?.id && selected?.status?.invoiced " (click)="create_creditnote()"
          style="margin-right: 5px;" pButton icon="icon-stock-change" [disabled]="loadingbtnPrint || loadingbtnPrint_d"
          type="button" label='{{"CREATE_CREDIT_NOTE" | translate}}' class="p-button-secondary"></button>

        <button *ngIf="selected?.id && !selected?.status?.invoiced && !selected?.status?.cc" (click)="convertInvoice()"
          style="margin-right: 5px;" pButton type="button" label='{{"ORDERS_LIST_CONVERT_INVOICE" | translate}}'
          class="p-button-secondary"></button>

        <button *ngIf="(selected?.status?.invoiced  && selected?.id == lastInvoiced_order ) ||selected?.status?.cc "
          (click)="converttoDraft()" style="margin-right: 5px;" pButton type="button" label='{{"TO_DRAFT" | translate}}'
          class="p-button-secondary"></button>
          
          <p-splitButton label='{{"DELIVETY_NOTE" | translate}}' *ngIf="selected?.id"
          [icon]="loadingbtnPrint_d ? 'pi pi-spin pi-spinner': ' fas fa-print'"
          [disabled]="loadingbtnPrint || loadingbtnPrint_d" (onClick)="printDeliverNote_form(selected?.id)"
          [model]="deliveryNotelangOpctions" styleClass="p-button-secondary" class="p-mx-2"></p-splitButton>
       
          <p-splitButton *ngIf="selected?.id" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
          [style]="{ height: '36px'}" [icon]="loadingbtnPrint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'"
          [disabled]="loadingbtnPrint" (onClick)="printOrder_form(selected.id)" [model]="langOpctions_O"
          styleClass="p-button-secondary" style="margin-right: 5px;">
        </p-splitButton>

        
        <button *ngIf="selected?.id" (click)="duplicateOrder()" pTooltip="Duplicate order" tooltipPosition="bottom"
        style="margin-right: 5px;" pButton type="button" icon="fas fa-clone" class="p-button-secondary" ></button>


        <button *ngIf="selected?.id" (click)="sendByEmail_order()"
          pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" style="margin-right: 5px;"
          pButton type="button" [disabled]="loadingbtnemail"
          [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>


        
        <button type="button" *ngIf="selected?.id" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"class="p-button-secondary" 
           pTooltip="Send Invoice to Whatsapp" (click)="sendToWhatsapp(selected?.id)"></button>


        <button *ngIf="selected?.id" pButton style="margin-left: 5px;" [disabled]="selected?.id"
          class="p-button-secondary p-mr-2" type="button" [label]="selected?.currency?.name"> </button>

          <button (click)="removedialoguee = true" *ngIf="selected?.id &&  !selected?.status?.invoiced" pButton pRipple
          type="button" icon="fas fa-trash" [pTooltip]="'Delete ' + selected?.format" tooltipPosition="bottom"
          class="p-button-secondary" style="margin-right: 5px;"></button>

          <button *ngIf="selected?.status?.invoiced  && selected?.id != lastInvoiced_order " (click)="unlockinvoiced()"
          style="margin-right: 5px;" pButton type="button" [disabled]="disableUnlockInvoice"
          label='{{"UNLOCK_INVO" | translate}}' class="p-button-secondary"></button>

        <button *ngIf="selected?.id" (click)="upadteFormClicked_order()" style="margin-left: 22px;" pButton
          type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>
        <p-splitButton *ngIf="!selected?.id" label='{{"CUSTOMER_LIST_SAVE" | translate}}' icon="pi pi-plus"
          (onClick)="saveOrder()" [model]="itemsSave" class="ui-button-raised ui-button-info"></p-splitButton>
      </div>
      <div *ngIf="sidebarName=='Expense'">
        <button (click)="removedialoguee=true" *ngIf="selected?.id" pButton pRipple type="button" icon="fas fa-trash"
                [pTooltip]="'Delete ' + selected?.format" tooltipPosition="bottom" class="p-button-secondary"
                style="margin-right: 5px;"></button>
        <button *ngIf="selected?.id" (click)="updateFormClickedExpense()" style="margin-left: 22px;" pButton
                type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
                class="ui-button-raised ui-button-info"></button>
      </div>
      <div *ngIf="sidebarName=='Quotes'">
        <button *ngIf="selected?.id" [disabled]="loadingbtnPrint" (click)="convert()" style="margin-right: 5px;"
          icon="pi pi-copy" pButton type="button" label='{{"QUOTES_LIST_CONVERT_ORDER" | translate}}'
          class="p-button-secondary"></button>

          

        <p-splitButton *ngIf="selected?.id" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
          [icon]="loadingbtnPrint ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint"
          (onClick)="print_Qoute()" [model]="langOpctions" styleClass="p-button-secondary"></p-splitButton>

          <button *ngIf="selected?.id" style="margin-left: 5px;" (click)="duplicateQoute()" style="margin-left: 5px;"
          icon="fas fa-clone" pTooltip="Duplicate Quote" tooltipPosition="bottom" pButton type="button"
          class="p-button-secondary"></button>

          <button *ngIf="selected?.id" (click)="Sendemail()" style="margin-left: 5px;" pButton
          pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' [disabled]="loadingbtnemail || loadingbtnPrint"
          tooltipPosition="bottom" type="button" [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'"
          class="p-button-secondary"></button>

          <button type="button" *ngIf="selected?.id" pButton icon="fab fa-whatsapp"tooltipPosition="bottom" showDelay="500"class="p-button-secondary"style="margin-left: 5px;" 
          pTooltip="Send Quote to Whatsapp" (click)="sendToWhatsapp(selected?.id)"></button>

        <button pButton *ngIf="selected?.id" style="margin-left: 5px;" [disabled]="selected?.id"
          class="p-button-secondary p-mr-2" type="button" [label]="selected?.currency?.name"> </button>

          <button (click)="removedialoguee=true" *ngIf="selected?.id" pButton pRipple type="button" icon="fas fa-trash"
          [pTooltip]="'Delete ' + selected?.format" tooltipPosition="bottom" class="p-button-secondary"
          style="margin-right: 5px;"></button>
        <button style="margin-left: 22px;" *ngIf="selected?.id" (click)="UpdateQoute()" [disabled]="loadingbtnPrint"
          pButton type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}'
          class="ui-button-raised ui-button-info"></button>

        <button style="margin-left: 22px;" *ngIf="!selected?.id" (click)="saveQoute()" [disabled]="loadingbtnPrint"
          pButton type="button" label='{{"CUSTOMER_LIST_SAVE" | translate}}'
          class="ui-button-raised ui-button-info"></button>
      </div>
      <div *ngIf="sidebarName=='Purchases'">
        <button
          *ngIf="selected?.id && (!isSet(selected?.invoice_attachment?.path ))|| loadingbtnupload!=false; else viewRec"
          pButton type="button" style="margin-right: 5px;"
          [icon]="loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'" (click)="showUploadInovice()"
          label='{{"UPLOAD_INVOICE" | translate}}' class="p-button-secondary" for="upload-photo"></button>

        <input #upload type="file" id="upload-photo" accept="image/png, image/jpeg" [imageToDataUrl]="options"
          (imageSelected)="myUploader($event)">
        <ng-template #viewRec>
          <button *ngIf="selected?.id " style="margin-right: 5px;" pButton type="button" icon="fas fa-eye"
            (click)="showViewInoivce()" label='{{"VIEW_INVOICE" | translate}}' class="p-button-secondary"
            for="upload-photo"></button>

        </ng-template>
        <p-splitButton *ngIf="selected?.id" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'
          [model]="langOrderOpctions" (onClick)="printPurchaseOrders_form(this.selected.id)"
          [disabled]="loadingbtnPrint" [icon]="loadingbtnPrint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'"
          class="p-mr-2" styleClass="p-button-secondary"></p-splitButton>

          <button *ngIf="selected?.id " (click)="duplicatePurchase()" pTooltip='{{"DUPLICATE" | translate}}'
          tooltipPosition="bottom" style="margin-right: 5px;" pButton type="button" icon="fas fa-clone"
          class="p-button-secondary"></button>
          <button *ngIf="selected?.id" (click)="sendByEmail()" pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}'
          tooltipPosition="bottom" style="margin-right: 5px;" pButton type="button" [disabled]="loadingbtnemail"
          [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>

          
          <button *ngIf="selected?.id" pButton style="margin-left: 5px;" [disabled]="selected?.id"
          class="p-button-secondary p-mr-2" type="button" [label]="selected?.currency?.name"> </button>

        <button (click)="deleteseletedPO()" *ngIf="selected?.id " pButton pRipple type="button" icon="fas fa-trash"
          [pTooltip]="'Delete ' + selected?.internal_reference" tooltipPosition="bottom" class="p-button-secondary"
          style="margin-right: 5px;"></button>




       
        <button *ngIf="selected?.id" style="margin-left: 22px;" (click)="updatePurchase()" pButton type="button"
          label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

        <button *ngIf="!selected" pButton (click)="savePurchase()" type="button" icon="pi pi-plus"
          label='{{"CUSTOMER_LIST_SAVE" | translate}}' class="ui-button-raised ui-button-info"></button>
        <!-- <span *ngIf="selected?.type=='Order'">

          <button
            *ngIf="(!isSet(selected?.invoice_attachment?.path ))|| loadingbtnupload!=false; else viewRec"
            pButton type="button" style="margin-right: 5px;"
            [icon]="loadingbtnupload ? 'pi pi-spin pi-spinner': ' pi pi-upload'" (click)="showUploadInovice()"
            label='Upload Invoice' class="p-button-secondary" for="upload-photo"></button>

          <input #upload type="file" id="upload-photo" [imageToDataUrl]="options" (imageSelected)="myUploader($event)">

          <ng-template #viewRec>
            <button style="margin-right: 5px;" pButton type="button" icon="fas fa-eye" (click)="showViewInoivce()"
              label='View Invoice' class="p-button-secondary" for="upload-photo"></button>

          </ng-template>
        </span> -->

      </div>
      <div *ngIf="sidebarName=='Product'">
        <button *ngIf="selected?.id" pButton type="button" style="margin-right: 5px;" label="Stock"
          class="ui-button-raised ui-button-info p-mr-2"></button>
        <button *ngIf="selected?.id" (click)="UpdatePro()" style="margin-right: 5px;" pButton type="button"
          label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>
      </div>
      <div *ngIf="sidebarName=='Custmoer'">
        <button pButton *ngIf="currentModal == 'Form'"
          (click)="switchModalCustomer('Dashboard')" type="button" label='{{"CUSTOMER_LIST_DASHBOARD"| translate}}'
          icon="pi pi-chart-bar" class="ui-button-raised ui-button-secondary p-mr-2"></button>
        <button pButton *ngIf="currentModal == 'Dashboard'"
          (click)="switchModalCustomer('Form')" type="button" label='Profile'
          class="ui-button-raised ui-button-info p-mr-2"></button>
        <button *ngIf=" selected?.id" (click)="UpadteCustmoer()" pButton type="button"
          label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>
      </div>
    </div>

    <div body>
      <app-orders *ngIf="showSidebar && sidebarName=='Order'"></app-orders>
      <app-quote-form *ngIf="showSidebar && sidebarName=='Quotes'" (sidebar)="convertt($event)"></app-quote-form>
      <app-PurchaseOrders-form *ngIf="showSidebar && sidebarName=='Purchases'"></app-PurchaseOrders-form>
      <app-products-form *ngIf="showSidebar && sidebarName=='Product'"></app-products-form>
      <!-- <app-customer-form *ngIf="showSidebar && sidebarName=='Custmoer'&& currentModal == 'Form'" ></app-customer-form> -->
      <app-customer-form *ngIf="showSidebar && sidebarName=='Custmoer'" ></app-customer-form>
     <!-- <app-customer-dashboard *ngIf="currentModal == 'Dashboard' "></app-customer-dashboard> -->
      <app-expense-form *ngIf="sidebarName == 'Expense' && showSidebar"></app-expense-form>
    </div>
  </app-sidebar>


  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1"
    appendTo="body" [resizable]="false" [draggable]="false" [header]="'Delete '+ selected?.format "
    [(visible)]="removedialoguee" [style]="{width: '30vw'}">


    <div class="p-text-justify "> {{ "CUSTOMER_FORM_DELETE_CON" | translate }}
      <span style="color: var(--primary-color)">{{ selected?.format}} ?</span>
    </div>

    <p-footer>
      <button *ngIf="sidebarName=='Order'" type="button" pButton icon="pi pi-check" (click)="deleteOrders()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
      <button *ngIf="sidebarName=='Quotes'" type="button" pButton icon="pi pi-check" (click)="deleteQuotes()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
      <button *ngIf="sidebarName=='Expense'" type="button" pButton icon="pi pi-check" (click)="deleteExpense()"
              label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
      <button type="button" pButton icon="pi pi-times" (click)="removedialoguee=false"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <p-dialog modal="true" (onShow)="outside_Status(false)" (onHide)="outside_Status(false)" [resizable]="false"
    [draggable]="false" class="p-shadow-1" header="Remove Purchase Orders" [(visible)]="removeDialogueeQ"
    [style]="{width: '30vw'}">


    <div class="p-text-justify "> {{ "CUSTOMER_FORM_DELETE_CON" | translate }}
      <span style="color: var(--primary-color)">{{ selected?.internal_reference}} ?</span>
    </div>
    <p-footer>

      <button type="button" pButton icon="pi pi-check" (click)="removePurchaseOrders()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
      <button type="button" pButton icon="pi pi-times" (click)="removeDialogueeQ=false"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" 
  [resizable]="false" [draggable]="false" class="p-shadow-1" [header]="'Invoice #'+selected?.internal_reference"
  [(visible)]="inoivceViewer" [style]="{width: '60vw'}">

  <div style="height: 1000px; position: relative;" *ngIf="showDialogView =='pdf'">
    <pdf-viewer #pdf [src]="selected?.view_url" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
      [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block ;z-index: 1;"></pdf-viewer>
  </div>

  <div class="p-grid text-align-center" *ngIf="showDialogView =='invoice'">
    <div class="p-col-12"><img [src]="selected?.viewImg "></div>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()"
      label=' {{"DOWNLOAD" | translate}} '></button>
      <button type="button" pButton icon="pi pi-envelope" (click)="Sendemail()"  label=' {{"Send To Email" | translate}} '></button> 

    <button type="button" pButton icon="fab fa-whatsapp" tooltipPosition="bottom" showDelay="500"
             (click)="sendToWhatsapp()"
            label=' {{"Send to Whatsapp" | translate}}' ></button>

    <button type="button" (click)="removeInvoice_attachmet()" pButton icon="fas fa-trash"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} ' class="p-button-danger"></button>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="inoivceViewer=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
