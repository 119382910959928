import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../product.service';
import { Product } from '../../../models/product';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { BaseComponent } from '../../core/base/base.component';
import { SharedService } from '../../shared/shared.service';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from 'src/app/models/currency';
import { Query } from 'src/app/models/query';
import { Category } from 'src/app/models/category';
import { Brand } from 'src/app/models/brand';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  providers: [MessageService, DialogModule]

})
export class ProductsListComponent extends BaseComponent implements OnInit {

  public products: Product[];
  public selected: Product[] = []
  public selectedProduct = new Product()
  public currentProduct: Product;
  public filteredProducts: Product[] = [];
  public removedialoguee = false
  public showSidebar = false;
  public size = SidebarSize.LARGE;
  public currentModal = 'Form';
  public modalTitle = 'Add new product';
  header_body: string
  itemSearch: string
  keyword: string = 'name'
  public datefilter


  selectedCurrancy = new Currency()

  refresh_selectedProduct: boolean = false
  productslectedIndex = 0
  productNextPage: any = 0
  productPrevPage: any = 0
  productsCount: any
  productCurrentnumber: any
  products_inPage = 0
  total_pages = 0
  pageViwed = []
  showDialogView: string
  currentChangedCurrancy: string;

  currencies: Currency[] = []

  sortMode: number = 1

  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: 'Not Equal',
      value: 'ne'
    },
    {
      type: 'Equal',
      value: 'exact'
    },
    {
      type: 'Less than', value: 'lt'

    },
    {
      type: 'Grater than', value: 'gt'

    },


  ]
  seletedquery_type: string
  filterModeName_query = new Query()
  filterModeApply_BarCode = new Query()
  filterModePrice_query = new Query()
  filterModeType_query = new Query()
  filterModeCat_query: Category
  filterModeBrand_query: Brand


  public categories: Category[] = [];
  public brands: Brand[] = [];


  constructor(private productsService: ProductService, private SharedService: SharedService,
    public translate: TranslateService, private router: Router, private sharedService: SharedService,
    private messageService: MessageService, private datePipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.getProducts();
    this.subscribeOnRefreshList();
    this.getCategories()
    this.getBrands()
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

  }

  private getProducts(): void {
    this.loading = true

    const subscription = this.productsService.getProducts().subscribe((data: PaginatedResponse<Product[]>) => {
      if (!data) {
        return;
      }


      this.productNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.productsCount = data?.count
      this.total_pages = data.total_pages
      this.products = Array(this.productsCount).fill(0)

      const clone = Product.cloneManyObjects(data.results);

      clone.map(e => {

        this.filteredProducts.push(e)
      })
      this.productCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.products[index] = clone[index];

      }
      if (this.refresh_selectedProduct == true) {
        this.selectedProduct = Product.cloneObject(this.products[this.productslectedIndex])

        this.modalTitle = 'Product : '
        this.header_body = this.selectedProduct.name

      }
      this.loading = false;

    }, error => {
      subscription.unsubscribe();
      this.loading = false;

    });


  }
  getNextproductPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.products_inPage = event.first
    } else {
      this.products_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.productsCount) {
      return
    }

    const num = (this.products_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.productNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.productNextPage = num + 1
      }
      if (this.productNextPage > this.total_pages) {
        this.productNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.productNextPage = this.products_inPage / 30

    }


    if (this.products_inPage >= this.productCurrentnumber) {

      if (this.productNextPage == this.productPrevPage) {
        this.productNextPage = this.productNextPage - 1
        this.productPrevPage = this.productNextPage
      }
      this.productPrevPage = this.productNextPage

      if (this.productsService.queris.length && this.productNextPage == 1) {
        this.productNextPage = 2
      }


      if (this.pageViwed.includes(this.productNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.productsService.getProductsNextPage(this.productNextPage).subscribe((products: PaginatedResponse<Product[]>) => {
          if (!isSet(products)) {
            return;
          }



          const clone = Product.cloneManyObjects(products.results);
          clone.map(e => {


            this.filteredProducts.push(e)
          })
          const shown = this.products_inPage

          this.products_inPage = (this.productNextPage - 1) * 30

          if (this.products_inPage > shown && this.productNextPage == this.productPrevPage) {
            this.products_inPage = this.products_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.products[this.products_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.productNextPage)
          if (this.refresh_selectedProduct == true) {
            this.selectedProduct = Product.cloneObject(this.products[this.productslectedIndex])

            this.modalTitle = 'Product : '
            this.header_body = this.selectedProduct.name

          }
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  clearProdutct_queries() {
    this.productsService.queris = []
    this.seletedquery_type = null
    this.filterModePrice_query = new Query()
    this.filterModeType_query = new Query()
    this.filterModeName_query = new Query()
    this.filterModeApply_BarCode = new Query()
    this.filterModeCat_query = null
    this.filterModeBrand_query = null
    this.getProducts()
  }
  getProduts_queries(query_name, query_type, query_value) {
    this.loading = true;



    if (query_type == 'Equal') {
      query_type = 'exact'
    }

    let re = false
    this.productsService.queris.map((query) => {
      if (query.query_name == query_name && query.query_name != 'status') {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.productsService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }

    this.filterModeType_query = new Query()


    this.productsCount = 0
    const subscription = this.productsService.getProducts().subscribe((products: PaginatedResponse<Product[]>) => {
      if (!isSet(products)) {
        return;
      }

      this.productNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.productsCount = products.count
      this.total_pages = products.total_pages
      this.products = Array(this.productsCount).fill(0)


      const clone = Product.cloneManyObjects(products.results);
      clone.map(e => {

        this.filteredProducts.push(e)
      })
      this.productCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.products[index] = clone[index];

      }

      this.productCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.products[index] = clone[index];

      }

      if (this.refresh_selectedProduct == true) {
        this.selectedProduct = Product.cloneObject(this.products[this.productslectedIndex])

        this.modalTitle = 'Product : '
        this.header_body = this.selectedProduct.name
      }

      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.productsService.sort = filed
      return this.getProducts()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.productsService.sort = '-' + filed
      return this.getProducts()
    }







  }

  searchProductBarCode(value) {

    const subscription = this.productsService.searchProductsBarcode(value).subscribe((products: PaginatedResponse<Product[]>) => {

      if (!isSet(products)) {
        return;
      }
      console.log(products);

      this.productNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.productsCount = products?.count
      this.total_pages = products.total_pages
      this.products = Array(this.productsCount).fill(0)
      products.results[0].barcode = this.filterModeApply_BarCode?.value
      const clone = Product.cloneManyObjects(products.results);

      clone.map(e => {

        this.filteredProducts.push(e)
      })
      this.productCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.products[index] = clone[index];

      }
      if (this.refresh_selectedProduct == true) {
        this.selectedProduct = Product.cloneObject(this.products[this.productslectedIndex])

        this.modalTitle = 'Product : '
        this.header_body = this.selectedProduct.name

      }


      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }




  upadteFormClicked() {
    this.productsService.upadteFormClicked.next(true)
  }
  public showAddProduct() {
    this.productsService.selectProduct(null);
    this.sharedService.Create_form(true)
    this.currentProduct = null;
    this.modalTitle = this.translate.instant('ADD_PRO');
    this.header_body = null
    this.currentModal = 'Form';
    this.showSidebar = true;

  }

  public showEditProduct(product: Product) {
    this.currentProduct = product
    this.productsService.selectProduct(product)
    this.modalTitle = this.translate.instant('DASHBORD_PRODUCT') + ` : `
    this.header_body = `<span  style="margin-right: 5px;" Class="">${this.currentProduct?.name}</span>`
    this.showSidebar = true;
    this.currentModal = 'Form';
  }

  public showDashboard() {
    this.modalTitle = `${this.currentProduct.name} Dashboard`;
    this.showSidebar = true;
    this.currentModal = 'Dashboard';
  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.showEditProduct(this.currentProduct);
        break;
      case 'Dashboard':
        this.showDashboard();
        break;
    }
  }
  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.products = e
  }
  saveForm() {
    this.productsService.saveForm();
    const subscription = this.productsService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      this.retriveLastProduct()
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }

  retriveLastProduct() {
    const subscription = this.productsService.retrieveLastProductEmitter.subscribe((product: Product) => {
      if (!isSet(product)) {
        return
      }
      this.currentProduct = Product.cloneObject(product)
      this.modalTitle = `Product:  `
      this.header_body = `<span  style="margin-right: 5px;" Class="">${this.currentProduct?.name}</span>`
      this.productsService.retrieveLastProduct.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription)
  }

  private subscribeOnRefreshList() {
    const subscription = this.productsService.refreshProductsListEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.getProducts();

    });
    this.subscriptions.push(subscription);
  }
  Reload() {

    this.productsService.queris = []
    this.productsService.sort = null
    this.loading = true
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });

  }

  deleteProducts() {
    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.productsService.deleteProduct(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.name + this.translate.instant(' DELETED ') });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: ' Selected products deleted ' });
          }
          this.Reload()
          this.sharedService.dialog_status.next(false)

          subscription.unsubscribe();

        }, error => {

          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', life: 3500, summary: this.translate.instant('DELETED') + " (" + item?.name + ")", detail: error.error.detail });
          }
          this.loading = false;
          subscription.unsubscribe();
          this.sharedService.dialog_status.next(false)

        })

      })


      this.loading = false;
      this.selected = []




    }
    catch (error) {
      this.log(error);
    }
    this.removedialoguee = false

  }
  remoe() {
    if (this.selected.length !== 0) {
      return this.removedialoguee = true
    }
  }
  deleteRow(selected) {
    this.selected = selected
  }
  

  private getCategories() {
    const subscription = this.productsService.getCategories().subscribe((categories: PaginatedResponse<Category[]>) => {
      if (!isSet(categories)) {
        return;
      }

      this.categories = Category.cloneManyObjects(categories.results);



      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }

  private getBrands() {
    const subscription = this.productsService.getBrands().subscribe((brands: PaginatedResponse<Brand[]>) => {
      if (!isSet(brands)) {
        return;
      }

      this.brands = Brand.cloneManyObjects(brands.results);


      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }



  completeMethod(serach_item) {
    this.productsService.queris = []
    this.getProduts_queries('name', 'startswith', serach_item)
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
}
