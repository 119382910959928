import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InplaceModule } from 'primeng/inplace';
import { SharedModule } from '../shared/shared.module';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {OrderListModule} from 'primeng/orderlist';
import { DialogModule } from 'primeng/dialog';
import {FileUploadModule} from 'primeng/fileupload';
import {ChipsModule} from 'primeng/chips';
import {CheckboxModule} from 'primeng/checkbox';
import {EditorModule} from 'primeng/editor';
import {TooltipModule} from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {ButtonModule} from 'primeng/button';
import {PaginatorModule} from 'primeng/paginator';
import { TieredMenuModule } from 'primeng/tieredmenu';
import {InputNumberModule} from 'primeng/inputnumber';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceOrdersComponent } from './invoice-orders/invoice-orders.component';


export const routes: Routes = [
  {path: '', component: OrdersListComponent}
];

@NgModule({
  declarations: [ OrdersListComponent, InvoiceOrdersComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    PdfViewerModule,
    CardModule,
    DropdownModule,
    FormsModule,
    InplaceModule,
    InputTextModule,
    CalendarModule,
    InputTextareaModule,
    SharedModule,
    SplitButtonModule,
    TranslateModule,
    TableModule,InputNumberModule,
    ToastModule,OrderListModule,DialogModule,TieredMenuModule
    ,FileUploadModule,ChipsModule,CheckboxModule,EditorModule,TooltipModule,TagModule,ButtonModule,PaginatorModule
  ]
})
export class OrdersModule {
}
