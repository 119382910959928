<!-- Reset Password -->
<p-toast key='reset' position="top-right"></p-toast>
<div class="login-body layout-light">
  <div class="login-content">
    <div class="login-panel">
      <div style="padding-bottom:18%" class="login-panel-content">
        <div class="logo">
          <img src="assets/img/logo7.png" alt="mirage-layout" />
        </div>

        <div *ngIf="swapSeccuess==false">
          <h1>Reset Password</h1>
          <p style="margin-top: -6px !important;">Please enter your new password</p>
          <div style="margin-top: 0px;" class="forms p-pt-3" >
          <div class="login-input-wrapper ">
            <label for="password-input">Password</label>
              <input [ngClass]="{invalid: passinvaild && passfoucs}" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                type="password" [(ngModel)]="password" id="password" (focus)="onfoucsPassword()"
                (blur)="onblurPassword()" autocomplete="nope" (ngModelChange)='checkPassword()' #Password
                id="password-input" type="password" pInputText />
          </div>
          <div class="login-input-wrapper ">
            <label for="confimpass-input">Confim Password</label>

            <input id="confimpass-input" type="password" [(ngModel)]="confirmPassword"  pPassword
              [feedback]="false" />
          </div>
        </div>
          <div class="buttons">
            <p-button (click)='resetPassword()' [icon]='resetBtn ? "pi pi-spin pi-spinner":null' label="Set Password">
            </p-button>
          </div>


        </div>
        <div *ngIf="swapSeccuess==true" style="padding-top: 50px;">
          <h3>Your password has been changed</h3>
          <div style="padding-top: 30px;">
            <p-button [routerLink]="['/auth/login']" class="p-button-lg" label=" Go to Login Page"></p-button>
          </div>
        </div>
      </div>
    </div>
    <p-card *ngIf="checkpass==true"
      [style]="{'width':'30rem','box-shadow':'none','position':'absolute','left':'75vw','top':'15vh','background-color':'#e6eaf0'}">
      <h4>Your password must contain :</h4>
      <div class="p-grid">
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_lowercase" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_lowercase" class="far fa-times-circle text-danger p-mr-2"></i>
          Lowercase letter
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_capitalcase" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_capitalcase" class="far fa-times-circle text-danger p-mr-2"></i>
          Uppercase letter
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_numeric" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_numeric" class="far fa-times-circle text-danger p-mr-2"></i>
          Number
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_length" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_length" class="far fa-times-circle text-danger p-mr-2"></i>
          Minimum 8 characters
        </div>
      </div>
    </p-card>
    <div class="desert"></div>
  </div>
</div>