import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatus } from './models/orders/orderstatus';
import { isSet } from './modules/core/util';
import { MenuService } from './shared/menu.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'erp';
  subscription
  public status = new OrderStatus()

  constructor(private router: Router, private menuService: MenuService,) { }
  ngOnInit() {
    this.checkLogIN()
    const lang = JSON.parse(localStorage.getItem('currentLang'))?.lang
    this.status.langStatus = lang
  }

  checkLogIN() {
    this.subscription = setInterval(() => {
      if (!localStorage.getItem("user") && !localStorage.getItem("token_data")) {
        return this.router.navigate(['/auth/login'])
      }
    }, 3000000)
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
  

}
