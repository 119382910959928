import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/orders/order';

@Component({
  selector: 'app-invoice-orders',
  templateUrl: './invoice-orders.component.html',
  styleUrls: ['./invoice-orders.component.scss']
})
export class InvoiceOrdersComponent implements OnInit {

  orders:Order[]=[]

  constructor() { }

  ngOnInit(): void {
  }

}
