<p-toast></p-toast>




<app-loading *ngIf="loading">
  <h1 class="verfiytext">
    Confirming email …
  </h1>
  <div verfiymessage class="login-body layout-light">
    <div class="login-content">

      <div class="desert"></div>
    </div>
  </div>
</app-loading>