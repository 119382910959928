import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { ExpenseNoteService } from '../expense-note.service';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { SendEmail } from 'src/app/models/SendEmail';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseNote } from 'src/app/models/expense/ExpenseNote';
import { Expenseitems } from 'src/app/models/expense/expenseItems';
import { Payment } from 'src/app/models/orders/Payment';
import { Payer } from 'src/app/models/expense/payer';
import { Options, ImageResult } from "ngx-image2dataurl";
import { Supplier } from 'src/app/models/supplier';
import { ChangeDetectorRef } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ExpenseService } from '../../expense/expense.service';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import * as printJS from 'print-js';
import { DomSanitizer } from '@angular/platform-browser';
import { CountryCode_currency } from 'src/app/models/country_code&currency';
import { Query } from 'src/app/models/query';
import {Calendar} from "primeng/calendar";
import {Dropdown} from "primeng/dropdown";

@Component({
  selector: 'app-expense-note-form',
  templateUrl: './expense-note-form.component.html',
  styleUrls: ['./expense-note-form.component.scss']
})

export class ExpenseNoteFormComponent extends BaseComponent implements OnInit, AfterViewInit {


  expenseNote: ExpenseNote;

  SendEmaildialog: boolean = false
  SendEmail = new SendEmail()
  SubjectSize
  SaveMessageState: boolean
  SendemailButton: boolean
  user_email: string
  selected_expenseNote: boolean
  update_status: boolean = false
  itemIndex = 0

  cardHeight = 0
  payerBalance = 0

  viewAttachment_diloage = false
  newPayments: Payment[] = []
  expenseNoteStatus = [
    { ex_status: 'Company Account', label: this.translate.instant('Company Account') },
    { ex_status: 'Claimed', label: this.translate.instant('Claimed') },
  ]
  selected_VATs = []
  selected_Dates = []
  selected_IBans = []

  expenseNoteType = [
    { ex_type: 'Ticket', label: this.translate.instant('TICKET') },
    { ex_type: 'Invoice', label: this.translate.instant('DASHBORD_INVOICE') },
  ]
  expenseNoteType2 = [
    { ex_type_2: 'Fuel', label: this.translate.instant('Fuel') },
    { ex_type_2: "Hotel", label: this.translate.instant("Hotel") },
    { ex_type_2: "Travel", label: this.translate.instant("Travel") },
    { ex_type_2: "Transport", label: this.translate.instant("Transport") },
    { ex_type_2: "Meals", label: this.translate.instant("Meals") },
    { ex_type_2: "Parking", label: this.translate.instant("Parking") },
    { ex_type_2: "Communications", label: this.translate.instant("Communications") },
    { ex_type_2: "Tips", label: this.translate.instant("Tips") },
    { ex_type_2: "Purchase Material", label: this.translate.instant("Purchase Material") },
    { ex_type_2: "Purchase Consumables", label: this.translate.instant("Purchase Consumables") },
    { ex_type_2: "Insurance", label: this.translate.instant("Insurance") },
    { ex_type_2: "Supplies / Purchases", label: this.translate.instant("Supplies / Purchases") },
    { ex_type_2: "GSA", label: this.translate.instant("GSA") },
    { ex_type_2: "General & Administration", label: this.translate.instant("General & Administration") },
    { ex_type_2: "Outsourcing", label: this.translate.instant("Outsourcing") },
    { ex_type_2: "Utilities", label: this.translate.instant("Utilities") },
    { ex_type_2: "other", label: this.translate.instant("other") },
  ]
  paymenttypes = [
    { type: 'Cash', label: this.translate.instant('Cash') },
    { type: 'Bank Cards', label: this.translate.instant('Bank Cards') },
    { type: 'Wire Transfer', label: this.translate.instant('Wire Transfer') },
    { type: 'Check', label: this.translate.instant('Check') },
    { type: 'Voucher', label: this.translate.instant('Voucher') },
    { type: 'Other', label: this.translate.instant('other') }
  ]
  paymentDue = 0
  payment_Options
  paymentDuestatus: boolean = true
  totalpayment = 0
  totalpayment_refund = 0
  showPaymentDialog: boolean
  paymentheader = ''
  payment = new Payment()
  paymentUpdateindex = 0
  expenseTotal = 0
  expenseTotalChange = 0
  payment_cardHeight = 0
  paymentDuefromBalance: boolean
  recpieImage: string
  viewRecpie: boolean
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  };
  pdfheight = 0
  inoicelenghtType = -1
  disablebtnupload = false
  vat_Percentage = [{ vat: '0' }, { vat: 6 }, { vat: 12 }, { vat: 21 }]


  expenses: Expenseitems[] = [];
  filteredexpenses: Expenseitems[] = [];
  selectedexpenses: Expenseitems[] = [];
  savedExpenses: Expenseitems[] = [];
  selectedexpense = new Expenseitems()


  public fillterPayers: Payer[] = [];
  showImportExpenss: boolean
  imageRecpitName: string
  imageRecpitType: string
  tabSelectedRecpit: boolean = false
  filetoUpload: any
  displayimg_localy: any
  displayimg_ai: any
  currencies = []


  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  orderNextPage: any = 0
  orderPrevPage: any = 0
  filterModeFormat_query = new Query()
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  @Output() public sidebar = new EventEmitter();
  @ViewChild('amountt') amount: any;
  @ViewChildren('amount') itemsAmounts: QueryList<any>;
  @ViewChildren('vat_21') vat_21: QueryList<any>;
  @ViewChildren('vat_12') vat_12: QueryList<any>;
  @ViewChildren('vat_6') vat_6: QueryList<any>;
  @ViewChildren('exchange_rate') exchange_rates: QueryList<any>;


  @ViewChild('total_Height') total_Height: ElementRef;
  @ViewChild('paymentcard') paymentcard: ElementRef;
  @ViewChild('upload') upload: ElementRef;
  @ViewChild('openCalender') openCalender: Calendar;
  @ViewChild('DropDownStatus') DropDownStatus: Dropdown;




  constructor(
    private expenseNoteService: ExpenseNoteService,
    private expenseService: ExpenseService,
    private router: Router, private sharedService: SharedService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCountryCode_currency()
    this.getexpenses()
    this.getNextExpenseFormat();
    this.subscribeOnFormSaved();
    // this.subscribeOnSendEmail()
    this.initExpenseNote();
    this.Save_form()
    this.updateEmitter()
    // this.printOrderViewer()
    this.getSavedExpenseNote()
    this.subscribeOnExpenseSelected()
    this.showexpense_formDashboered()
    this.clearAll()

    // window.addEventListener('keydown', (event: KeyboardEvent) => {
    //   if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
    //     this.addItem();
    //   }
    // });
    if (!isSet(localStorage.getItem("defaultMessage"))) {
      this.SendEmail.message = ""
    } else {
      this.SendEmail.message = localStorage.getItem("defaultMessage")
    }
    this.payment_Options = [


      {

        label: this.translate.instant('FULL_CASH_PAID'), command: () => {
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true
            return
          }
          this.update_status = true
          this.payment = new Payment()
          this.payment.amount = this.paymentDue
          this.payment.type = 'Cash'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.expenseNote.payer?.due)) {
            this.expenseNote.payer.due = this.expenseNote.payer.due + this.paymentDue
          }
          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.expenseNote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()

        },

      },
      {

        label: this.translate.instant('FULL_BANK_PAID'), command: () => {
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true

            return
          }
          this.update_status = true
          this.payment = new Payment()

          this.payment.amount = this.paymentDue
          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.type = 'Bank Cards'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.expenseNote.payer?.due)) {
            this.expenseNote.payer.due = this.expenseNote.payer.due + this.paymentDue
          }
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.expenseNote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()



        }
      }


    ]

  }

  ngAfterViewInit() {

    this.cardHeight = this.total_Height.nativeElement.offsetHeight
    this.payment_cardHeight = this.paymentcard.nativeElement.offsetHeight
    this.cdr.detectChanges();

  }

  payerChange(payer: Payer) {
    this.expenseNote.payer = payer
    this.payerBalance = this.expenseNote.payer?.due

    this.getPaymentDue()
    this.update_status = true


  }
  addItem() {

    const item = Expenseitems.cloneObject(null);
    item.datePipe = new Date();
    item.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency;

    item.exchange_rate = 1
    item.ex_type = 'Ticket'
    item.vat_6 = 0
    item.vat_12 = 0
    item.vat_21 = 0
    item.vendor = new Supplier()
    item.amount = 0;
    this.expenseNote.items.push(item);




  }
  removeItem(i) {
    console.log(i);

    this.savedExpenses.map((item, index) => {
      if (this.expenseNote.items[i].id == item.id) {
        this.savedExpenses.splice(index, 1)
      }
    })

    this.expenseNote.items.splice(i, 1)

  }
  selectvendor($event, i) {
    const vndor = $event as Supplier
    this.expenseNote.items[i].vendor = vndor
    this.expenseNote.items[i].vat = vndor?.vat

    this.update_status = true


  }
  selectvendor_2($event) {
    const vndor = $event as Supplier
    this.selectedexpense.vendor = vndor

    this.update_status = true


  }
  ex_typeonChanged(event, index) {

    if (event.value == 'Invoice') {
      this.inoicelenghtType += 1
      this.expenseNote.items[index].inoiceIndex = this.inoicelenghtType

    }
  }

  private subscribeOnFormSaved() {
    const subscription = this.expenseNoteService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addExpenseNote();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  // private subscribeOnSendEmail() {
  //   const subscription = this.expenseNoteService.sendEmailClickedEmitter.subscribe((status: boolean) => {
  //     if (!isSet(status) || !status) {
  //       return
  //     }

  //     this.SendEmaildialog = true
  //     this.expenseNoteService.sendEmail_buuton(null)

  //   }, error => {
  //     this.handleError(error);
  //   });

  //   this.subscriptions.push(subscription);
  // }

  private initExpenseNote() {
    this.expenseNote = ExpenseNote.cloneObject(null);
    this.expenseNote.ex_status = 'Claimed'
    this.expenseNote.date = new Date();
    this.expenseNote.items = [];
    this.expenseNote.payments = [];

    this.addItem();
  }
  private subscribeOnExpenseSelected() {
    const subscription = this.expenseNoteService.expenseNoteSelectedListEmitter.subscribe((expenseNote: ExpenseNote) => {
      if (!isSet(expenseNote)) {
        return;
      }
      this.SendemailButton = true

      this.getExpenseNote(expenseNote.id);
    }, error => {

      this.handleError(error);
    });

    this.subscriptions.push(subscription);
    this.expenseNoteService.expenseNoteSelected.next(null)

  }


  addExpenseNote(): void {
    let checkerr: Boolean

    if (isSet(this.expenseNote?.items)) {
      this.expenseNote.items.map(item => {
        item.amount = Number(item.amount)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')
      })
    }
    if ((!isSet(this.expenseNote?.format)
      || (!isSet(this.expenseNote?.date)))) {

      checkerr = false
    } else {
      checkerr = true

    }


    if (checkerr == false) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

    } else if (checkerr == true) {

      try {
        this.loading = true;
        const subscription = this.expenseNoteService.addExpenseNote(this.expenseNote).subscribe((expenseNote: ExpenseNote) => {

          if (!isSet(expenseNote)) {
            return;
          }

          this.expenseNote.id = expenseNote.id
          this.expenseNote.payments.map(payment => {
            payment.status2 = 'old'
          })
          this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'New Expense Note added successfully' });
          this.loading = false;
          this.expenseNoteService.refreshDataTable()
          subscription.unsubscribe();
          this.expenseNoteService.retrieve_lastExpenseNote.next(this.expenseNote)
          this.expenseNoteService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)

        }, error => {

          if (error.error.detail == 'the expensen_note format must be unique, please try changing it.') {
            const subscription = this.expenseNoteService.getNextdExpenseNoteNumber().subscribe((data: { format: string }) => {
              if (!isSet(data)) {
                return;
              }
              this.expenseNote.format = data.format;
              this.addExpenseNote()


              subscription.unsubscribe();
            }, error => {
              subscription.unsubscribe();
            });

          } else {
            this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }



  }




  private getNextExpenseFormat() {
    const subscription = this.expenseNoteService.getNexExpenseNumber().subscribe((data: { format: string }) => {
      if (!isSet(data)) {
        return;
      }
      if (!isSet(this.expenseNote.format)) {
        this.expenseNote.format = data.format;
      }

      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }



  private getExpenseNote(id: string) {
    this.loading = true


    const subscription = this.expenseNoteService.getExpenseNote(id).subscribe((expenseNote: ExpenseNote) => {
      this.initExpenseNote()
      const cloneObject = ExpenseNote.cloneObject(expenseNote);

      this.selected_expenseNote = true

      this.expenseNote = cloneObject;
      this.expenseNote.date = new Date(expenseNote.date)
      this.payerBalance = this.expenseNote.payer?.due

      // this.expenseNote.payer.contact.forEach(customer => {
      //   SendEmail.cloneObject(this.SendEmail)
      //   this.SendEmail.send_to_mails = []
      //   this.SendEmail.send_to_mails.push(customer.email)

      // })
      let i = 0
      this.expenseNote.items.map(item => {
        item.datePipe = new Date(item.date);
        if (!isSet(item.vendor)) {

          item.vendor = new Supplier()
          item.vendor.name = item.vendor_name
        } else {
          item.vat = item.vendor?.vat

        }
        if (item.ex_type == 'Invoice') {
          this.inoicelenghtType += 1
          item.inoiceIndex = this.inoicelenghtType
        }
        if (isSet(item.attachment?.path)) {
          i += 1
          item.loadingbtnupload = false
          this.generate_download(i, 'item')

        }

      })
      this.expenseNote.payments.map(payment => {
        payment.status2 = 'old'
        payment.dateP = new Date(payment.date)

      })
      this.getPaymentDue()
      subscription.unsubscribe();
      this.loading = false

    }, error => {
      subscription.unsubscribe();
    });

  }
  // Save_Message() {


  //   localStorage.setItem("defaultMessage", this.SendEmail.message)


  // }
  // send_Email(): void {


  //   try {
  //     this.expenseNoteService.btnLoading.next(true)
  //     const subscription = this.expenseNoteService.SendEmail(this.SendEmail, this.expenseNote.id).subscribe((sendEmail: SendEmail) => {

  //       if (!isSet(sendEmail)) {
  //         return;
  //       }
  //       this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SENT_EMAILS') });
  //       this.expenseNoteService.btnLoading.next(false)

  //       subscription.unsubscribe();

  //     }, error => {

  //       this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error });
  //       this.expenseNoteService.btnLoading.next(false)

  //       subscription.unsubscribe();

  //     })
  //   } catch (error) {
  //     this.loading = false;
  //     this.log(error);

  //   }
  //   this.SendEmaildialog = false
  // }

  // send_copyto_myself() {
  //   const user: User = JSON.parse(localStorage.getItem("user"))
  //   const email = this.SendEmail.send_to_mails
  //   if (this.SendEmail.send_to_mails.includes(user.email) == false && this.user_email != null) {
  //     email.push(user.email)
  //     this.SendEmail.send_to_mails = []
  //     this.user_email = user.email

  //     email.map(em => {
  //       this.SendEmail.send_to_mails.push(em)
  //     })
  //   } else {

  //     this.SendEmail.send_to_mails = this.SendEmail.send_to_mails.filter(item => item !== user.email)
  //     this.user_email = null

  //   }

  // }
  // onRemovemail() {
  //   this.SendEmail.send_to_mails.find(email => {
  //     if (email != this.user_email) {
  //       this.user_email = null
  //     }
  //   })
  // }
  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.sharedService.Save_object(this.expenseNote)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }

  updateEmitter() {
    try {
      const subscription = this.expenseNoteService.upadteFormClickedEmitter.subscribe(status => {

        if (!isSet(status) || !status) {
          return;
        }
        this.updateExpenseNote()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);


    } catch (error) {
      this.log(error);
    }

  }

  updateExpenseNote() {
    this.expenseNoteService.newPayments = this.newPayments
    let noerror: Boolean

    if (isSet(this.expenseNote?.items)) {
      this.expenseNote.items.map(item => {
        item.amount = Number(item.amount)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')
      })
    }
    if ((!isSet(this.expenseNote.format))
      || (!isSet(this.expenseNote.date))) {

      noerror = false
    } else {
      noerror = true



    }
    if (noerror == false) {
      this.messageService.add({
        key: 'formM', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      if (this.update_status == false) {
        this.messageService.add({ key: 'formM', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

      } else {
        try {
          this.loading = true;
          const subscription = this.expenseNoteService.upadeExpenseNote(this.expenseNote).subscribe((expenseNote: ExpenseNote) => {

            if (!isSet(expenseNote)) {
              return;
            }
            this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
            this.expenseNote.payments.map(payment => {
              payment.status2 = 'old'
            })
            this.update_status = false
            this.newPayments = []

            this.loading = false;
            subscription.unsubscribe();
            this.expenseNoteService.refreshDataTable()
            this.expenseNoteService.upadteFormClicked.next(null)
            // this.expenseNoteService.refreshselected.next(true)


          }, error => {

            this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

            this.loading = false;
            subscription.unsubscribe();

          })
        } catch (error) {
          this.loading = false;
          this.log(error);

        }
      }
    }
  }
  getSavedExpenseNote() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((expenseNote: any) => {
      if (!isSet(expenseNote)) {
        this.sharedService.objectstauts.next(null)

        return;
      }
      if (expenseNote.url == this.router.url) {

        this.expenseNote = expenseNote.object
        this.sharedService.objectstauts.next(true)

      } else {
        this.sharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }

      this.initExpenseNote()
      this.sharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }
  notempty(event) {
    this.update_status = true

    this.getexpenseTotalChange()

  }

  numberOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;


  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }
  erace_value(index, filedName: string) {

    if (filedName == 'amount') {
      const itemsAmounts: any[] = this.itemsAmounts?.toArray()


      this.expenseNote.items[index].amount = 0

      setTimeout(() => {
        itemsAmounts[index].input?.nativeElement.select()
      }, 100)
    }
    if (filedName == 'exchange_rate') {
      const exchange_rates: any[] = this.exchange_rates?.toArray()
      this.expenseNote.items[index].exchange_rate = 1
      setTimeout(() => {
        exchange_rates[index].input?.nativeElement.select()

      }, 100)
    }
    if (filedName == 'vat_6') {
      const vat_6: any[] = this.vat_6?.toArray()
      this.expenseNote.items[index].vat_6 = 0

      setTimeout(() => {
        vat_6[this.expenseNote.items[index].inoiceIndex].input?.nativeElement.select()
      }, 100)
    }
    if (filedName == 'vat_12') {
      const vat_12: any[] = this.vat_12?.toArray()


      this.expenseNote.items[index].vat_12 = 0
      setTimeout(() => {
        vat_12[this.expenseNote.items[index].inoiceIndex].input?.nativeElement.select()
      }, 100)
    }
    if (filedName == 'vat_21') {
      const vat_21: any[] = this.vat_21?.toArray()

      this.expenseNote.items[index].vat_21 = 0
      setTimeout(() => {
        vat_21[this.expenseNote.items[index].inoiceIndex].input?.nativeElement.select()
      }, 100)
    }

  }
  spaceNumber(number) {
    return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }




  showexpense_formDashboered() {

    const subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }
      if (item.itemName == 'expenseNote') {
        this.getExpenseNote(item.item?.id)

      }
      this.sharedService.diplayDash.next(null)

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  onChange_Item(index: number, filed) {

    const itemsAmounts: ElementRef[] = this.itemsAmounts.toArray()
    if (filed == 'amount') {
      if (!isSet(this.expenseNote.items[index].amount) || this.expenseNote.items[index].amount == '') {
        this.expenseNote.items[index].amount = 0
      }
      itemsAmounts[index].nativeElement.value = this.spaceNumber(this.expenseNote.items[index].amount)
      this.expenseNote.items[index].amount = this.expenseNote.items[index].amount.replace(/\s/g, '')
    } else {

      if (!isSet(this.expenseNote.items[index].exchange_rate)) {
        this.expenseNote.items[index].exchange_rate = 1
      }
    }
  }

  getexpenseTotalChange() {

    setTimeout(() => {
      if (this.paymentDue == 0 || !this.expenseNote.payments.length) {
        this.paymentDue = this.expenseNote.totalwithVat || 0
      }


      this.expenseTotalChange = this.expenseNote.totalwithVat || 0
      this.paymentDue = this.paymentDue + this.expenseTotalChange || 0


      this.expenseTotal = this.expenseNote.totalwithVat || 0
      if (this.paymentDue > 0) {
        this.paymentDuestatus = false
      }
      if (this.expenseNote.totalwithVat < this.paymentDue) {
        this.getPaymentDue()
      }
      if (isSet(this.expenseNote.payer?.due)) {
        this.expenseNote.payer.due = this.payerBalance
        this.expenseNote.payer.due = this.expenseNote.payer.due - this.paymentDue
        this.expenseNote.payer.due = -Math.abs(this.expenseNote.payer.due)
      }
    }, 500)





  }
  getPaymentDue() {
    const payments_amountsadd = []
    const refundamounts = []
    if (this.expenseNote.payments.length) {
      this.expenseNote.payments.map(item => {
        if (item.status == "add") {
          payments_amountsadd.push(item.amount)
        }
        if (item.status == "refund") {
          refundamounts.push(item.amount)
        }
      })
      if (payments_amountsadd.length > 0) {
        this.totalpayment = payments_amountsadd.reduce((prev, next) => prev + next);
      }
      if (refundamounts.length > 0) {
        this.totalpayment_refund = refundamounts.reduce((prev, next) => prev + next);
      }
    }
    if (this.expenseTotal == 0) {
      this.expenseTotal = this.expenseNote.totalwithVat
    }
    this.paymentDue = (this.expenseTotal - this.totalpayment) || 0
    this.paymentDue = this.totalpayment_refund + this.paymentDue
    if (this.paymentDuefromBalance == false && isSet(this.expenseNote.payer?.due)) {
      this.payerBalance = this.expenseNote.payer?.due + this.paymentDue
      this.paymentDuefromBalance = true

    }
    if (this.paymentDue == 0) {
      this.paymentDuestatus = true
    } else if (this.paymentDue > 0) {
      this.paymentDuestatus = false
    }
  }
  showAddPaymnet() {
    if (this.paymentDue == 0) {
      return
    }
    this.paymentheader = "Add Payment"
    this.payment = new Payment()
    this.payment.dateP = new Date()
    this.payment.status = 'add'
    this.payment.status2 = 'new'
    this.amount?.control?.markAsUntouched()
    this.amount?.control?.clearValidators()
    this.showPaymentDialog = true
  }
  addPayment() {
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

      this.amount.control.markAsTouched();

    } else {
      if (this.payment.amount > this.paymentDue) {

        return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_DUE') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      } else {

        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }

        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        if (this.expenseNote.payments.length) {
          this.expenseNote.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.paymentDue = this.paymentDue - this.payment.amount
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true
          }
        } else {
          this.expenseNote.payments.push(this.payment)
          this.getPaymentDue()

        }
        this.update_status = true
        if (isSet(this.expenseNote.payer?.due)) {
          this.expenseNote.payer.due = this.expenseNote.payer.due + this.paymentDue
        }


        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()



      }
    }
  }
  showrefundPayment() {
    this.paymentheader = "Refund Payment"
    this.payment = new Payment()
    this.payment.status = 'refund'
    this.payment.status2 = 'new'
    this.payment.dateP = new Date()
    this.amount.control.markAsUntouched()
    this.amount.control.clearValidators()
    this.showPaymentDialog = true
  }
  refundPayment() {
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

      this.amount.control.markAsTouched();

    } else if (this.payment.amount > this.expenseNote.totalwithVat) {
      return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
    } else if (this.payment.amount == 0) {
      return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
    } else {
      let paymentDue = Number(this.paymentDue)
      this.payment.amount = Number(this.payment.amount)
      paymentDue = this.paymentDue + this.payment.amount
      if (this.expenseNote.totalwithVat < paymentDue) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });

      }
      if (!isSet(this.payment.type)) {
        this.payment.type = 'Cash'
      }
      this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
      this.expenseNote.payments.push(this.payment)
      this.newPayments.push(this.payment)
      this.paymentDue = Number(paymentDue)


      this.paymentDuestatus = false
      this.update_status = true

      if (isSet(this.expenseNote.payer?.due)) {
        this.expenseNote.payer.due = this.expenseNote.payer.due + this.paymentDue
      }
      this.showPaymentDialog = false
      this.amount.control.markAsUntouched()
      this.amount.control.clearValidators()
    }
  }
  paymentReview(payment: Payment, index: number) {
    if (payment.status2 == 'old') {
      return
    }
    if (this.paymentDue == 0) {
      this.paymentDuestatus = true
    }
    this.payment = new Payment()
    this.payment.amount = payment.amount
    this.payment.notes = payment.notes
    this.payment.type = payment.type
    this.payment.status = payment.status
    this.payment.status2 = payment.status2
    this.payment.dateP = new Date(payment.dateP)
    this.paymentUpdateindex = index
    this.showPaymentDialog = true
    this.paymentheader = "Update Payment"
  }
  updatePayment() {
    const prv_paymnet = new Payment()
    prv_paymnet.amount = Number(this.expenseNote.payments[this.paymentUpdateindex].amount)
    prv_paymnet.dateP = new Date(this.expenseNote.payments[this.paymentUpdateindex].dateP)
    prv_paymnet.type = this.expenseNote.payments[this.paymentUpdateindex].type
    prv_paymnet.notes = this.expenseNote.payments[this.paymentUpdateindex].notes
    let paymentDue = Number(this.paymentDue)
    paymentDue = paymentDue - Number(prv_paymnet.amount)
    paymentDue = paymentDue + Number(this.payment.amount)
    if (this.payment.status == 'add') {

      if (((this.payment.amount > paymentDue) && (paymentDue > this.expenseNote.totalwithVat))) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_DUE') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      }
    } else if (this.payment.status == 'refund') {

      if (this.expenseNote.totalwithVat < paymentDue) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      }
    }
    this.paymentDue = paymentDue
    this.expenseNote.payments[this.paymentUpdateindex] = this.payment
    this.expenseNote.payments[this.paymentUpdateindex].amount = Number(this.expenseNote.payments[this.paymentUpdateindex].amount)

    this.showPaymentDialog = false
    this.update_status = true
    this.payment = new Payment()
    this.getPaymentDue()





  }
  deletePayment(index) {
    this.expenseNote.payments.splice(index, 1)

    this.newPayments.splice(this.newPayments.length - 1, 1)

    this.getPaymentDue()
  }
  showUploadReceipt(index: number) {
    this.itemIndex = index
    this.upload.nativeElement.click()

  }
  uploadReceipt(imageResult: ImageResult) {
    this.selected_VATs = []
    this.selected_Dates = []
    this.selected_IBans = []
    this.selectedexpense = new Expenseitems()
    this.selectedexpense.payment_type = 'Cash'

    this.disablebtnupload = true;
    this.expenseNote.items[this.itemIndex].loadingbtnupload = true
    this.recpieImage = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;




    const filee = new FormData();
    this.imageRecpitName = imageResult.file.name
    this.imageRecpitType = imageResult.file.type
    this.srcToFile(this.recpieImage, imageResult.file.name, imageResult.file.type).then((file) => {

      if (imageResult.file.type == 'application/pdf') {

        this.myUploader(file)
      } else {
        this.displayimg_localy = this.sanitizer.bypassSecurityTrustResourceUrl(imageResult?.resized?.dataURL)
      }

      filee.append('file', file)
      this.messageService.add({ key: 'formM', severity: 'info', life: 2000, summary: this.translate.instant('INFO'), detail: 'Receipt is Uploading' });
      this.filetoUpload = file
      try {
        const subscription = this.expenseNoteService.uploadReceipt(filee).subscribe(receipt => {

          if (!isSet(receipt)) {
            return;
          }


          console.log(receipt);

          if (imageResult.file.type != 'application/pdf') {

            for (const prop in receipt.files) {
              this.selectedexpense.aiCropped = []

              this.selectedexpense.aiCropped.push(receipt.files[prop])
              let x = 'data:image/jpg;base64,' + this.selectedexpense.aiCropped[0]
              this.displayimg_ai = this.sanitizer.bypassSecurityTrustResourceUrl(x)

            }
          }
          if (isSet(receipt?.date) && receipt?.date != '') {


            if (Array.isArray(receipt?.date)) {
              receipt?.date.map(d => {
                d = new Date(d)
                this.selected_Dates.push({ date: d })
              })

            } else {
              this.selectedexpense.datePipe = new Date(receipt?.date)

            }
          }



          this.selectedexpense.amount = parseFloat(receipt?.total) || 0

          this.selectedexpense.exchange_rate = 1
          if (receipt?.type == 'ticket') {
            this.selectedexpense.ex_type = 'Ticket'

          } else {
            this.selectedexpense.ex_type = 'Invoice'

          }

          if (receipt?.vendor_name) {
            this.selectedexpense.vendor = new Supplier()
            this.selectedexpense.vendor.name = receipt?.vendor_name
            this.selectedexpense.vendor_name = receipt?.vendor_name

          }

          if (receipt?.vat?.length > 1) {
            for (let index = 0; index < receipt?.vat?.length; index++) {

              this.selected_VATs[index] = { vat: receipt?.vat[index][0] }

            }
          }

          if (receipt?.vat?.length == 1) {
            this.selectedexpense.vat = receipt?.vat[0][0]
            this.selected_VATs.push(receipt?.vat[0][0])
          }

          if (receipt?.vcs) {
            this.selectedexpense.vcs = receipt?.vcs

          }
          if (receipt?.iban?.length > 1) {

            for (let index = 0; index < receipt?.iban?.length; index++) {

              this.selected_IBans[index] = { iban: receipt?.iban[index][0] }


            }
          }

          if (receipt?.iban?.length == 1) {

            this.selectedexpense.iban = receipt?.iban[0][0]
            this.selected_IBans.push(receipt?.iban[0][0])
          }
          if (receipt?.vat_rate) {

            const vats = receipt?.vat_rate

            if (vats['21']) {
              this.selectedexpense.vat_21 = vats['21']
            }
            if (vats['12']) {
              this.selectedexpense.vat_21 = vats['12']
            }
            if (vats['6']) {
              this.selectedexpense.vat_21 = vats['6']
            }

          }

          this.expenseNote.items[this.itemIndex].loadingbtnupload = false
          this.recpieImage = null
          this.disablebtnupload = false;
          this.viewRecpie = true
          setTimeout(() => {
            this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Receipt Uploaded Successfully' });

          }, 300);
          subscription.unsubscribe();

        }, error => {


          this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
          this.disablebtnupload = false;
          this.expenseNote.items[this.itemIndex].loadingbtnupload = false

          subscription.unsubscribe();

        })
      } catch (error) {
        console.log(error);

      }

    })
  }
  confirmRreceipt_withpdf() {
    this.viewRecpie = false
    setTimeout(() => {


      if (!isSet(this.selectedexpense.vat_21)) {
        this.selectedexpense.vat_21 = 0
      }
      if (!isSet(this.selectedexpense.vat_12)) {
        this.selectedexpense.vat_12 = 0
      }
      if (!isSet(this.selectedexpense.vat_6)) {
        this.selectedexpense.vat_6 = 0
      }
      this.expenseNote.items[this.itemIndex] = Expenseitems.cloneObject(this.selectedexpense)
      this.expenseNote.items[this.itemIndex].currency = JSON.parse(localStorage.getItem('companySettings'))?.currency;

      this.expenseNote.items[this.itemIndex].loadingbtnupload = false
      this.payment = new Payment()
      this.payment.dateP = new Date()
      this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
      this.payment.status = 'add'
      this.payment.status2 = 'new'
      this.payment.type = this.expenseNote.items[this.itemIndex]?.payment_type
      this.payment.amount = Number(this.expenseNote.items[this.itemIndex]?.totalwithVat)
      this.payment.expense = this.itemIndex
      this.expenseNote.payments.push(this.payment)
      this.newPayments.push(this.payment)
      this.tabSelectedRecpit = false
    }, 500);
  }
  confirmRreceipt_withOriginal() {
    this.viewRecpie = false
    setTimeout(() => {
      if (!isSet(this.selectedexpense.vat_21)) {
        this.selectedexpense.vat_21 = 0
      }
      if (!isSet(this.selectedexpense.vat_12)) {
        this.selectedexpense.vat_12 = 0
      }
      if (!isSet(this.selectedexpense.vat_6)) {
        this.selectedexpense.vat_6 = 0
      }
      this.myUploader(this.filetoUpload)

      this.expenseNote.items[this.itemIndex] = Expenseitems.cloneObject(this.selectedexpense)
      this.expenseNote.items[this.itemIndex].currency = JSON.parse(localStorage.getItem('companySettings'))?.currency;


      this.expenseNote.items[this.itemIndex].loadingbtnupload = false
      this.payment = new Payment()
      this.payment.dateP = new Date()
      this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
      this.payment.status = 'add'
      this.payment.status2 = 'new'
      this.payment.type = this.expenseNote.items[this.itemIndex]?.payment_type
      this.payment.amount = Number(this.expenseNote.items[this.itemIndex]?.totalwithVat)
      this.payment.expense = this.itemIndex

      this.expenseNote.payments.push(this.payment)
      this.newPayments.push(this.payment)


      this.tabSelectedRecpit = false
    }, 500);

  }
  confirmRreceipt_withCropped() {
    this.viewRecpie = false
    setTimeout(() => {


      if (!isSet(this.selectedexpense.vat_21)) {
        this.selectedexpense.vat_21 = 0
      }
      if (!isSet(this.selectedexpense.vat_12)) {
        this.selectedexpense.vat_12 = 0
      }
      if (!isSet(this.selectedexpense.vat_6)) {
        this.selectedexpense.vat_6 = 0
      }
      this.selectedexpense.aiCropped[0] = 'data:image/jpg;base64,' + this.selectedexpense.aiCropped[0]
      this.srcToFile(this.selectedexpense.aiCropped[0], this.imageRecpitName, this.imageRecpitType).then((file) => {
        this.myUploader(file)
      }).then(() => {
        this.expenseNote.items[this.itemIndex] = Expenseitems.cloneObject(this.selectedexpense)
        this.expenseNote.items[this.itemIndex].currency = JSON.parse(localStorage.getItem('companySettings'))?.currency;

        this.expenseNote.items[this.itemIndex].loadingbtnupload = false

        this.payment = new Payment()
        this.payment.dateP = new Date()
        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        this.payment.status = 'add'
        this.payment.status2 = 'new'
        this.payment.type = this.expenseNote.items[this.itemIndex]?.payment_type
        this.payment.amount = Number(this.expenseNote.items[this.itemIndex]?.totalwithVat)
        this.payment.expense = this.itemIndex

        this.expenseNote.payments.push(this.payment)
        this.newPayments.push(this.payment)
        this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Receipt confirmed Successfully' });
        this.selectedexpense = new Expenseitems()
      })
    }, 500);

  }
  canceleviewRecpie() {
    this.viewRecpie = false
    this.recpieImage = null

    setTimeout(() => {
      this.selectedexpense = new Expenseitems()

    }, 500);
  }
  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  showViewReceipt(index: number, type: string) {
    this.itemIndex = index
    this.generate_download(index, type)

    setTimeout(() => {
      this.viewAttachment_diloage = true

    }, 300);

  }
  myUploader(file) {
    this.loading = true
    const generatUpload = {
      file_type: 'ExpenseItem',
      file_path: file.name

    }

    this.expenseNoteService.generate_Upload(generatUpload)

    this.expenseNoteService.upload(file)
    try {
      const subscription = this.expenseNoteService.uploadstuatsEmitter.subscribe(status => {
        if (!isSet(status) || !status) {
          return;
        }

        if (status === true) {
          this.expenseNote.items[this.itemIndex].attachment = {
            name: file.name,
            path: this.expenseNoteService.attachmentlink,
            type: file.type
          }
          this.selectedexpense.attachment = this.expenseNote.items[this.itemIndex].attachment
          this.generate_download(this.itemIndex, null)

          this.update_status = true
          this.loading = false


        }
        else if (status === false) {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });

        }
        this.expenseNoteService.uploadstuats.next(null)
        this.loading = false

        subscription.unsubscribe()
      }, error => {
        this.loading = false
        this.handleError(error);
        subscription.unsubscribe()

      });
    } catch (error) {
      console.log(error);

    }
  }
  generate_download(index: number, type) {
    this.loading
    if (!isSet(this.expenseNote?.items[index]?.attachment)) {
      return
    }
    if (isSet(this.expenseNote.items[index]?.download_url) && type == 'item') {

      return this.selectedexpense = this.expenseNote.items[index]
    }
    if (isSet(this.expenses[index]?.download_url) && type == 'ImportExpense') {
      return this.selectedexpense = this.expenses[index]
    }
    if (type == 'item') {
      this.selectedexpense = this.expenseNote?.items[index]
    }
    if (type == 'ImportExpense') {
      this.selectedexpense = this.expenses?.[index]
    }

    try {

      const subscription = this.expenseNoteService.generate_download(this.selectedexpense?.attachment?.path).subscribe(link => {

        if (type == 'item') {
          this.expenseNote.items[index].download_url = link?.download_url
          this.expenseNote.items[index].view_url = link?.view_url

        }
        if (type == 'ImportExpense') {
          this.expenses[index].download_url = link?.download_url
          this.expenses[index].view_url = link?.view_url
        }
        this.selectedexpense.download_url = link?.download_url
        this.selectedexpense.view_url = link?.view_url

        this.loading = false

        subscription.unsubscribe()
      }, error => {
        this.loading = false
        this.handleError(error);
        subscription.unsubscribe()

      })
    } catch (error) {
      this.log(error);
    }
  }


  downloadlink() {
    window.location.href = this.selectedexpense.download_url;
  }


  deletefile() {
    this.viewAttachment_diloage = false
    if (this.expenseNote?.items[this.itemIndex]?.attachment?.path) {
      try {

        this.loading = true;
        const subscription = this.expenseNoteService.delete_File(this.expenseNote?.items[this.itemIndex]?.attachment?.path).subscribe(() => {
          this.loading = false;

          this.expenseNote.items[this.itemIndex].attachment = {}
          this.update_status = true
          if (this.selected_expenseNote == true) {
            this.expenseNoteService.upadteFormClicked.next(true)

          }

          this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Receipt deleted Successful  ' });


          subscription.unsubscribe();


        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

          this.loading = false;
          subscription.unsubscribe();
        })
      }
      catch (error) {
        console.log(error)
        this.log(error);
      }
    }
  }
  deleteExpense(indexExpense) {

    this.itemIndex = indexExpense;

    if (this.expenseNote?.items[this.itemIndex]?.attachment?.path) {
      this.loading = true;

      try {

        const subscription = this.expenseNoteService.delete_File(this.expenseNote?.items[this.itemIndex]?.attachment?.path).subscribe(() => {

          this.expenseNote.items[this.itemIndex].attachment = {}
          this.update_status = true
          if (this.selected_expenseNote == true) {
            this.expenseNoteService.upadteFormClicked.next(true)

          }

          this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Receipt deleted Successful  ' });
          this.expenseNote.items.splice(indexExpense, 1);
          this.expenseNote.payments.map((item, index) => {
            console.log(indexExpense);
            console.log(item.expense);
            if (item.expense == indexExpense) {


              this.expenseNote.payments.splice(index, 1);

            }
          })
          this.update_status = true;
          this.loading = false;

          subscription.unsubscribe();


        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

          this.loading = false;
          subscription.unsubscribe();
        })
      }
      catch (error) {
        console.log(error)
        this.log(error);
      }
    } else {
      this.expenseNote.items.splice(indexExpense, 1);

    }



  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  printPdf() {

    printJS({ printable: this.selectedexpense.download_url, type: 'pdf', showModal: true })

  }
  // Import Expense
  getexpenses() {
    this.loading = true

    const subscription = this.expenseService.getfree_expenses().subscribe((expense: PaginatedResponse<Expenseitems[]>) => {

      this.expenses = Expenseitems.cloneManyObjects(expense.results);

      this.expenses.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        if (isSet(e?.date)) {
          e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        }
        this.fillterPayers.push(e?.payer)
        this.filteredexpenses.push(e)


      })
      // remove duplicate form payers fillter
      let rmAduplicate = []
      this.fillterPayers.forEach((item) => {
        if (rmAduplicate.findIndex(i => i == item) === -1) {
          rmAduplicate.push(item)
        }
      });
      this.fillterPayers = rmAduplicate



      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expense.count
      this.total_pages = expense.total_pages
      this.expenses = Array(this.ordersCount).fill(0)
      const clone = Expenseitems.cloneManyObjects(expense.results);

      clone.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredexpenses.push(e)


      })
      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expenses[index] = clone[index];

      }


      this.loading = false

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  getNextExpensesPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.expenseService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.expenseService.getExpenses_NextPage(this.orderNextPage).subscribe((expenseNotes: PaginatedResponse<Expenseitems[]>) => {
          if (!isSet(expenseNotes)) {
            return;
          }

          const clone = Expenseitems.cloneManyObjects(expenseNotes.results);

          clone.map(e => {
            e.payer = new Payer()
            e.payer.name = e?.payer_name
            let datefillter = new Date(e?.date)
            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

            this.filteredexpenses.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.expenses[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;


    if (query_name == 'date' || query_name == 'expiry_date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }
    if (query_type == 'C&C') {
      query_type = 'cc'

    }
    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.expenseService.queris.map((query, index) => {
      if (query.query_name == query_name) {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.expenseService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.ordersCount = 0
    const subscription = this.expenseService.getExpenses().subscribe((expenseNotes: PaginatedResponse<Expenseitems[]>) => {
      if (!isSet(expenseNotes)) {
        return;
      }
      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = expenseNotes.count
      this.total_pages = expenseNotes.total_pages
      this.expenses = Array(this.ordersCount).fill(0)


      const clone = Expenseitems.cloneManyObjects(expenseNotes.results);
      clone.map(e => {
        e.payer = new Payer()
        e.payer.name = e?.payer_name

        let datefillter = new Date(e?.date)
        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())



        this.filteredexpenses.push(e)


      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.expenses[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearOrders_queries() {
    this.expenseService.queris = []
    this.filterModeFormat_query = new Query()

    this.getexpenses()
  }
  getexpense(id) {

    const subscription = this.expenseService.getExpense(id).subscribe((expense: Expenseitems) => {

      const cloneObject = Expenseitems.cloneObject(expense);

      cloneObject.datePipe = new Date(expense.date)

      if (!isSet(cloneObject.vendor)) {

        cloneObject.vendor = new Supplier()
        cloneObject.vendor.name = cloneObject.vendor_name

      } else {
        cloneObject.vat = cloneObject.vendor?.vat
      }
      if (isSet(cloneObject.attachment)) {
        cloneObject.loadingbtnupload = false

      }
      if (cloneObject.ex_type == 'Invoice') {
        this.inoicelenghtType += 1
        cloneObject.inoiceIndex = this.inoicelenghtType
      }

      this.expenseNote.items.push(cloneObject)
      this.update_status = true
      this.itemIndex += 1





      if (this.itemIndex == this.selectedexpenses.length) {
        this.loading = false
        this.showImportExpenss = false
        this.selectedexpenses = []
      }

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);

  }
  serachexpenses(e) {
    if (!isSet(e)) {
      return
    }
    this.expenses = e


  }
  importExpense() {

    this.loading = true
    this.itemIndex = 0
    this.selectedexpenses.map(item => {
      if (this.savedExpenses.includes(item)) {
        this.loading = false

        return this.messageService.add({ key: 'form', severity: 'error', life: 2000, summary: this.translate.instant('ERROR'), detail: item.format + " Expense already Added" });
      } else {
        this.savedExpenses.push(item)
        this.getexpense(item.id)
      }

    })

  }
  getCountryCode_currency() {
    this.currencies = JSON.parse(localStorage.getItem('currencies'))

    if (this.currencies?.length) {
      return
    }
    const subscription = this.expenseNoteService.countryCode_currency().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.currencies = []
      countryCode_currency.map(c => {
        this.currencies.push({ currency: c.currency })
      })
      let rmAduplicate = []
      this.currencies.forEach((item) => {
        if (rmAduplicate.findIndex(i => i.currency == item.currency) === -1) {
          rmAduplicate.push(item)
        }
      });

      this.currencies = rmAduplicate
      localStorage.setItem("currencies", JSON.stringify(this.currencies))

      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  focusThenShow(type){
    switch (type) {
      case 'status':
        setTimeout(()=>{
          this.DropDownStatus.focus()
          this.DropDownStatus.show()
        },0)
        break;
      case 'date':
        setTimeout(()=>{
          this.openCalender.toggle()
        },0)
        break;
    }
  }

}


