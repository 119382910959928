import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { Customer } from '../../../models/customer';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { CustomerService } from '../../customers/customer.service';
import * as _ from 'lodash';
import { CustomerDetails } from '../../../models/customerdetails';
import { VatInfo, VatInfoCountry } from '../../auth/models/vatinfo';
import { ApiService } from '../../core/api.service';
import { Dropdown } from 'primeng/dropdown';
import { SharedService } from '../shared.service';
import { Address } from '../../../models/address';
import { MessageService } from 'primeng/api';
import { Contact } from 'src/app/models/contact';
import { TranslateService } from '@ngx-translate/core';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-customers-select',
  templateUrl: './customers-select.component.html',
  styleUrls: ['./customers-select.component.scss']
})
export class CustomersSelectComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('dd') dd: Dropdown;

  @ViewChild('company_name') company_name: any
  company_nametouched = false
  @Input() customer: Customer;
  @Input() append: string;
  @Input() filterMode: boolean = false

  @Output() customerChange = new EventEmitter<Customer>();
  @Output() onHide = new EventEmitter<any>();
  addCustomerLoading: boolean;
  displayAddCustomer: boolean;
  vatBtnDisabled: boolean;
  miniCustomer: Customer;
  cash_cary = false
  customers: Customer[] = [];
  filteredCustomers: Customer[] = [];
  DropdownonFocus_Stuats: boolean
  public contact = new Contact()
  public moredetail_contact = false
  public showContactDialog = false;
  serach: string
  endload: boolean = false
  @ViewChild('email') email: any;
  constructor(private customerService: CustomerService,
    private api: ApiService,
    private sharedService: SharedService,
    public translate: TranslateService,
    public messageService: MessageService,
  ) {
    super();
  }


  ngOnInit(): void {


    // Dropdown.prototype.onInputFocus = function (event) {
    //   setTimeout(() => {
    //     this.focused = true;
    //     this.onFocus.emit(event);
    //   });
    // };
    if (this.append !== 'body') {
      Dropdown.prototype.onInputBlur = function (event) {
        setTimeout(() => {
          this.focused = false;
          this.onModelTouched();
          this.onBlur.emit(event);
        });
      };
    }
    this.removeCashandCarryCustomer()
    this.getCustomers();
    setTimeout(() => {
      this.initDropdown();
    }, 100);
  }

  ngAfterViewInit() {
    if (this.append !== 'body' && (this.filterMode == false && !isSet(this.customer))) {


      const openDropdown = setInterval(() => {
        if (this.endload == true) {


          this.dd.containerViewChild.nativeElement.click()

          if (this.DropdownonFocus_Stuats === true) {

            this.DropdownonFocus_Stuats = false
            clearInterval(openDropdown);

          }
        }
      }, 1500)


    }


  }

  normalize_VAT(event) {
    this.miniCustomer.vat = event.replace(/\s/g, '')
    this.miniCustomer.vat = this.miniCustomer.vat.replace(/\./g, '').toUpperCase()
  }

  customerChanged($event) {
    const customer = $event.value as Customer;
    if (!isSet(customer)) {
      return;
    }
    if (customer.id === '1') {
      this.customer.company_name = 'Cash & Carry'
    }
    if (customer.id === '0') {
      this.showAddMiniCustomer('');
      return;
    }
    if (customer.id === '-1') {
      this.showAddMiniCustomer(customer.company_name);
      return;
    }

    this.sharedService.chekcempey.next(true)
    this.retriveCustomer(customer.id)
  }

  closeMiniCustomerModal() {
    this.customer = null;
    this.displayAddCustomer = false;
    this.outside_Status(false)
  }

  public addContact(): void {
    if (this.email.control.invalid) {

      this.email.control.markAsTouched();


      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else {
      this.miniCustomer.contact = []
      this.miniCustomer.contact.push(this.contact)
      this.customer.contact = []
      this.customer.contact.push(this.contact)


      this.email.control.markAsUntouched()
      this.email.control.clearValidators()
      this.showContactDialog = false;


    }
  }

  more_detail() {
    if (this.DropdownonFocus_Stuats === false) {
      this.DropdownonFocus_Stuats = true
    } else if (this.DropdownonFocus_Stuats === true) {
      this.DropdownonFocus_Stuats = false

    }

  }

  addMiniCustomer() {

    if (!isSet(this.miniCustomer?.company_name) || this.miniCustomer.company_name === '') {
      this.company_name?.control.markAsTouched();
      this.company_nametouched = true
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('PLZ_FILL_NAME_REQ')
      });

    } else {
      this.addCustomerLoading = true;
      const subscription = this.customerService.addMiniCustomer(this.miniCustomer).subscribe((customer: Customer) => {
        if (!isSet(customer)) {
          return;
        }
        try {
          this.customers = []
          const x = setInterval(() => {
            this.getCustomers();

            if (this.customers[0]?.id === customer?.id) {
              this.customer = this.customers[0]
              this.customerChange.emit(this.customers[0]);
              clearInterval(x);
            }
          }, 100)


          this.dd.optionsToDisplay.push({ label: this.customer.company_name, value: this.customer });

          this.dd.containerViewChild.nativeElement.click()
          this.dd.containerViewChild.nativeElement.click()


          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_CUSTOMER_SUCCESS')
          });
          this.company_name?.control.markAsUntouched()
          this.company_name?.control.clearValidators()
          this.company_nametouched = false

        } catch (e) {
        } finally {
          this.addCustomerLoading = false;
          this.displayAddCustomer = false;
          subscription.unsubscribe();
        }
      }, (error) => {
        this.addCustomerLoading = false;
        console.log(error);
        
        if (error?.error?.detail) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('ERROR'),
            detail: error?.error?.detail
          });
        }



        subscription.unsubscribe();
      });
    }
    this.outside_Status(false)

  }

  notempty(event) {

    this.company_nametouched = false
  }

  getDataFromVat(): void {

    if (this.miniCustomer.vat.length < 9) {
      return
    }
    this.vatBtnDisabled = true;

    const subscription = this.api.postGuest('/extract_vat_info', {
      vat_number: this.miniCustomer.vat
    }).subscribe((vatInfo: VatInfo) => {
      if (!isSet(vatInfo)) {
        return;
      }
      this.miniCustomer.company_name = vatInfo.name;
      const address = vatInfo.address.split('\n');
      if (address.length >= 2) {
        this.miniCustomer.invoicing_address.address = address[0]
        const zip = address[1].split(' ')
        if (zip.length >= 2) {
          this.miniCustomer.invoicing_address.zip = zip[0]
          this.miniCustomer.invoicing_address.city = zip[1]
        }

        if (isSet(VatInfoCountry[vatInfo.countryCode])) {
          this.miniCustomer.invoicing_address.country_code = VatInfoCountry[vatInfo.countryCode];
        }
      }
    }, error => {
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: "Can't find vat information" });

      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    }, () => {
      this.vatBtnDisabled = false;
      subscription.unsubscribe();
    });
  }

  hide($event) {
    if (this.customer && this.customer.id !== '-1') {
      this.onHide.emit($event);
    }

  }

  private showAddMiniCustomer(name: string) {
    this.customer = null
    this.miniCustomer = new Customer();
    this.miniCustomer.company_name = name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('CUSTOMER_FORM_ADD_CUSTOMER') + ')</span>', '')

    this.miniCustomer.payment_details = new CustomerDetails();
    this.miniCustomer.invoicing_address = new Address();
    this.miniCustomer.contact = []
    this.displayAddCustomer = true;
    setTimeout(() => {
      this.customer = new Customer();

    }, 200);
  }

  getCustomers(): void {

    const subscription = this.customerService.getCustomers().subscribe((customers: PaginatedResponse<Customer[]>) => {
      if (!isSet(customers)) {
        return;
      }
      console.log(customers);
      
      this.customers = Customer.cloneManyObjects(customers.results);

      this.filteredCustomers = this.customers;
      this.dd.optionsToDisplay = this.filteredCustomers
      this.dd.filterValue = null
      this.endload = true
      if (this.filterMode == false) {
        const addCustomer = new Customer();
        addCustomer.id = '0';
        addCustomer.company_name = '<span class="add-quick-option">' + this.translate.instant('CUSTOMER_FORM_ADD_CUSTOMER') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.filteredCustomers = insert(this.filteredCustomers, 0, addCustomer)



        if (this.cash_cary === true) {
          const cashandCary = new Customer();
          cashandCary.id = '1';
          cashandCary.company_name = '<span class="add-quick-option">' + this.translate.instant('CASH_CARRY') + '</span>'
          this.filteredCustomers = insert(this.filteredCustomers, 1, cashandCary)
        }
      }

      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  serachCustomers(value: any) {

    const subscription = this.customerService.serach_Customers(value).subscribe((customers: PaginatedResponse<Customer[]>) => {

      if (!isSet(customers)) {
        return;
      }

      this.customers = Customer.cloneManyObjects(customers.results);
      this.filteredCustomers = this.customers;


      this.endload = true


      if (this.filterMode == false) {
        const addCustomer = new Customer();
        addCustomer.id = '-1';
        addCustomer.company_name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('CUSTOMER_FORM_ADD_CUSTOMER') + ')</span>';

        this.filteredCustomers.push(addCustomer)
      }

      this.dd.optionsToDisplay = this.filteredCustomers;

      this.serach = this.dd.filterValue
      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();
    });
  }
  private initDropdown(): void {
    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;


      if (this.serach != value) {
        return this.serachCustomers(value.toLocaleLowerCase())
      }


    };
  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }

  onFocus() {
    this.DropdownonFocus_Stuats = true

  }

  removeCashandCarryCustomer() {
    const subscription = this.sharedService.removeCandCopformCusotmerEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }

      if (status === true) {
        this.cash_cary = false
      }
      if (status === false) {
        this.cash_cary = true
      }

    }, error => {


      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  preventDefault(event, element) {
    const el = element.model
    if (!el.replace(/\s/g, '').length) {
      event.preventDefault();
    }
  }
  retriveCustomer(id){
    const subscription = this.customerService.getCustomer(id).subscribe((customers: Customer) => {
      if (!isSet(customers)) {
        return;
      }
      this.customerChange.emit(customers);
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
}
