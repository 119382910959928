import { isSet } from '../modules/core/util';

export class ExtraFields {
  name: string;
  value: any | null;

  public constructor(params?: ExtraFields) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: ExtraFields) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new ExtraFields(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: ExtraFields[]) {
    const objects: ExtraFields[] = [];
    for (const obj of objectsToClone) {
      objects.push(ExtraFields.cloneObject(obj));
    }

    return objects;
  }
}
