import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { BaseComponent } from '../../core/base/base.component';
import { UserToken } from '../../../models/usertoken';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../core/api.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MenuService } from 'src/app/shared/menu.service';
import * as moment from 'moment';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit ,AfterViewInit{

  public loading = false;
  public rememberMe = false;
  public email: string;
  public password: string;
  public showEmailDialog: boolean = false
  public resetEmail: string
  mobileView:boolean=false
  showPassword: boolean
  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private router: Router,
    private menuService: MenuService,
    public translate: TranslateService,
    private apiService: ApiService,public breakpointObserver: BreakpointObserver
  ) {
    super();
  }
  @ViewChild('Email') emailcheck: any;
  @ViewChild('Password') passwordCheck: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;

  ngOnInit(): void {

      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user')) as User;
        this.email = user.email;
      }

      this.breakpointObserver
      .observe(['(max-width: 991.98px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
// this.mobileView=true
        } else {


        }
      });
      setTimeout(() => {
        this.emailInput.nativeElement.focus()

      }, 0);
  }
  ngAfterViewInit(){
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();

    }
    else{
      localStorage.removeItem('firstLoad');

  }

  }
  public login(): void {
    try {
      this.loading = true;
      const subscription = this.authService.login(this.email, this.password).subscribe((data: UserToken) => {


        this.loading = false;
        if (!isSet(data)) {
          return;
        }
        localStorage.setItem('user', JSON.stringify(data.user_data));
        localStorage.setItem('token_data', JSON.stringify(data.token_data));
        this.RememberMe()

        this.apiService.login_info.next(data)
        this.dashboardService.toturial.next(data.user_data.tutorial)
        // this.dashboardService.toturial.next(false)

        if (isSet(localStorage.getItem('returnUrl'))) {
          this.router.navigate([localStorage.getItem('returnUrl')]);
          localStorage.setItem('returnUrl', '/')
        } else {
          this.router.navigate(['/']);
        }
        this.menuService.refreshShapes.next(true)

        this.log('data', data);
      }, error => {
        this.loading = false;
          this.messageService.add({ severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.non_field_errors[0] });
      });
      this.subscriptions.push(subscription);
    } catch (e) {
      this.handleError(e);
    }
  }
  RememberMe() {

    if (this.rememberMe == true) {
      let dateNow = new Date()
      let expires = moment(dateNow).add(20,'days').toString()      
      return localStorage.setItem('token_expires', expires)
    } else {
      let dateNow = new Date()
      let expires = new Date(dateNow);
      expires.setMinutes(expires.getMinutes() + 30);
      return localStorage.setItem('token_expires', expires.toString());
    }
  }
  sendEmailDailog() {
    this.showEmailDialog = true
  }
  sendEmail() {
    if (this.emailcheck.control.invalid) {

      return this.messageService.add({
        life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter vaild Email'
      });
    } else {
      const body = {
        email: this.resetEmail
      }
      const subscription = this.authService.sendEmail(body).subscribe(send => {

        if (!isSet(send)) {
          return;
        }
        this.messageService.add({ severity: 'success', detail: 'Please check your Email' });
        this.showEmailDialog = false
      }
        , error => {
          this.messageService.add({
            life: 5000,
            severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter vaild Email'
          });

        })
      this.subscriptions.push(subscription);

    }

  }

  showpassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'text');
    this.showPassword = true
  }
  hidepassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'password');
    this.showPassword = false
  }

  onKeyUp(e) {
    switch (e.keyCode) {
      case 13:
        this.login();
    }

  }
}
