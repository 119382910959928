import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditNote } from 'src/app/models/CreditNotesSupplier';
import { Product } from 'src/app/models/product';

@Injectable({
  providedIn: 'root'
})
export class creditnotessupplireService {
  public currentCreditNote: CreditNote;
  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();
  private refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();
  public CreditNoteSelected: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
  public CreditNoteSelectedListEmitter: Observable<CreditNote> = this.CreditNoteSelected.asObservable();
  public ImportCreditNoteSelected: BehaviorSubject<CreditNote> = new BehaviorSubject<CreditNote>(null);
  public ImportCreditNoteSelectedEmitter: Observable<CreditNote> = this.ImportCreditNoteSelected.asObservable();

  public currencyChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currencyChangedEmitter: Observable<any> = this.currencyChanged.asObservable();


  constructor(private api: ApiService) {
  }

  getCredit_notes(): Observable<PaginatedResponse<CreditNote[]>> {
    return this.api.get<PaginatedResponse<CreditNote[]>>('/credit_note_purchases');
  }

  credit_notesNextPage(num): Observable<PaginatedResponse<CreditNote[]>> {
    return this.api.get<PaginatedResponse<CreditNote[]>>(`/credits_notes?page=${num}`);
  }

  getCredit_note(id: string): Observable<CreditNote> {
    return this.api.get<CreditNote>(`/credit_note_purchases/${id}`);
  }

  getNextinvoice_format(): Observable<{ order_format: string }> {
    return this.api.get<{ order_format: string }>('/order_format');
  }

  addCredit_notes(CreditNote: CreditNote): Observable<CreditNote> {
    return this.api.post('/credit_note_purchases', CreditNote.toDB());
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }

  refreshDataTable() {
    this.refreshList.next(true);
  }

  selectCredit_notes(CreditNote: CreditNote) {
    this.CreditNoteSelected.next(CreditNote);
  }

deleteCredit_note(id: string): Observable<CreditNote> {
    return this.api.delete<CreditNote>(`/credit_note_purchases/${id}`);
  }


  
// getproducts(id: string): Observable<Product> {
//   return this.api.get<Product>(`/products/${id}`);
// }
}
