<p-toast key='register' position="top-right"></p-toast>
<div class="login-body layout-light">
  <div style="position: relative;" class="login-content">
    <div class="login-panel">
      <div  class="login-panel-content login-padding">
        <div class="logo">
          <img src="assets/img/logo7.png" alt="mirage-layout" />
        </div>
        <h1 *ngIf="complete==false">{{"REGISTER_REGISTER" | translate}}</h1>
        <p *ngIf="complete==false">Already have an account ? <a style="font-weight: 600;" [routerLink]="['/auth/login']"
            routerLinkActive="router-link-active">Sign in </a></p>
        <form style="display: inline-block; text-align: center;" class="p-mt-6" *ngIf="!captchaRes"
          [formGroup]="aFormGroup">
          <ngx-recaptcha2  #captchaElem [siteKey]="siteKey" (success)="captchaResponse($event)"
            formControlName="recaptcha">
          </ngx-recaptcha2>
        </form>

        <div [style.display]="captchaRes ? null:'none'" *ngIf="!complete " class="p-py-6">
          <p-steps class="p-mt-6" [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
        </div>
        <div [style.display]="captchaRes ? null:'none'" *ngIf="!complete ">
          <div style="margin-top: 0px !important;" class="forms" *ngIf="activeIndex==0 ">
            <div *ngIf="!dontHaveVAT" class="form-group">
              <div class="login-input-wrapper vat-btn">
                <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO" | translate}}  (Optional)</label>
                <input (ngModelChange)="normalize_VAT($event)" (input)="getDataFromVat()" [(ngModel)]="vat"
                  id="vat-input" name="vat" type="text" pInputText>
                <button pButton (click)="getDataFromVat()" type="button" [disabled]="vatBtnDisabled"
                  [icon]="vatBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
                  class="ui-button-warn"></button>
              </div>
            </div>
            <div>
              <div class="login-input-wrapper">
                <label for="company-name-input">{{"CUSTOMER_FORM_COMPANT_NAME" | translate}}</label>
                <input [(ngModel)]="name" name="name" id="company-name-input" type="text" pInputText>
              </div>
            </div>
            <div class="login-input-wrapper">

              <div class="p-grid">
                <div class="p-col-6 p-pb-0 ">
                  <label for="country-input" class="p-mb-1">{{"CUSTOMER_FORM_COUNTRY" | translate}}</label>
                  <p-dropdown id="country-input" [options]="countries" (ngModelChange)='selectCountryCurrency($event)' [filter]="true"
                    optionValue='name' [(ngModel)]="selectedCountry" placeholder="Select Country" optionLabel="name">
                  </p-dropdown>
                </div>
                <div class="p-col-6 p-pb-0 ">
                  <label for="currency-input " class="p-mb-1">{{"CURRANECY" | translate}}</label>

                  <p-dropdown  id="currency-input"[options]="currencies" (ngModelChange)='selectCountryCurrency($event)' [filter]="true"
                    optionValue='currency' [(ngModel)]="selectedCurrency" placeholder="Select Currency"
                    optionLabel="currency"></p-dropdown>
                </div>
              </div>
            </div>


            <div class="login-input-wrapper">
              <label for="company-address-input">{{"REGISTER_ADDRESS_CO" | translate}}</label>
              <input [(ngModel)]="address"  #addressInput name="address" id="company-address-input" type="text"
                pInputText>

            </div>

            <!-- <div style="margin-top: 25px;">
              <p-checkbox [(ngModel)]="dontHaveVAT" [binary]="true" label="Continue without VAT number ?"
              class="dont-have-vat">
            </p-checkbox>
            </div> -->

            <div class="p-pt-5 text-align-right">
              <p-button [disabled]="name==null || name =='' || address==null  || address ==''" (click)="activeIndex1()"
                label='{{"REGISTER_NEXT" | translate}}' icon="pi pi-angle-right" iconPos="right"
                styleClass="p-button-raised"></p-button>
            </div>

          </div>
          <div *ngIf="activeIndex==1 ">
            <div style=" margin-top: 0px !important;" class="forms">

              <div class=" login-input-wrapper">
                <label for="email-input">{{"LOGIN_EMAIL" | translate}}</label>
                <input required [ngClass]="{invalid: (Email.invalid || checkused_email) && emailfoucs}"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #Email="ngModel" name="Email" [(ngModel)]="email"
                  id="email-input" type="email" (focus)="emailfoucs=true" (blur)="onBlurEmail()" (keyup)="checkEmail_delay()"
                  pInputText>
                <i class="pi pi-user"></i>

              </div>
              <div class=" login-input-wrapper pt-2">
                <label for="password-input">{{"LOGIN_PASSWORD" | translate}}</label>

                <input [ngClass]="{invalid: passinvaild && passfoucs}" required [(ngModel)]="password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="password" (focus)="onfoucsPassword()"
                  (blur)="onblurPassword()" autocomplete="nope" (ngModelChange)='checkPassword()' #Password
                  id="password-input" type="password" pInputText>

                <i *ngIf="!showpassword_" (click)="showpassword()" class="fas fa-eye-slash p-mt-2"></i>
                <i *ngIf="showpassword_" (click)="hidepassword()" class="fas fa-eye  p-mt-2"></i>
              </div>


            </div>

            <div class="p-grid p-pt-5 ">
              <div class="p-col-6 text-align-left ">

                <button pButton icon="pi pi-angle-left" iconPos="left" (click)="activeIndex=0" type="button"
                  label="Back" class="p-button-raised"></button>

              </div>
              <div class="p-col-6 text-align-right">


                <button pButton icon="pi pi-angle-right" iconPos="right"
                  [disabled]="Email.invalid || checkused_email || passinvaild" (click)="activeIndex=2" type="button"
                  label='{{"REGISTER_NEXT" | translate}}' class="p-button-raised"></button>

              </div>


            </div>



          </div>
          <div *ngIf="activeIndex==2 && !complete ">

            <div style="margin-top: 0px !important;" class="forms">
              <div class="login-input-wrapper">
                <label for="first_name-input">{{"REGISTER_FIRST_NAME" | translate}}</label>
                <input class="form-control ng-untouched" [(ngModel)]="first_name" name="first_name"
                  id="first_name-input" type="text" pInputText>
              </div>
              <div class="login-input-wrapper">
                <label for="last_name-input">{{"REGISTER_LAST_NAME" | translate}}</label>

                <input [(ngModel)]="last_name" id="last_name-input" name="last_name" type="text" pInputText>
              </div>
              <div class="login-input-wrapper">
                <p-checkbox [(ngModel)]="agree" name='terms' id='terms' label=" Agree Terms and Conditions"
                  [ngModelOptions]="{standalone: true}" [binary]="true">
                </p-checkbox>
                <!-- <label for="terms" class="p-ml-2 p-mb-1"> Agree <b>Terms and
                Conditions  </b> </label> -->
              </div>

            </div>



            <div class="p-grid p-pt-5 ">
              <div class="p-col-6 text-align-left ">

                <button pButton icon="pi pi-angle-left" iconPos="left" (click)="activeIndex=1" type="button"
                  label="Back" class="p-button-raised"></button>

              </div>
              <div class="p-col-6 text-align-right">



                <p-button [disabled]="!agree || first_name==null && first_name==''|| address==null && address==''"
                  label='Complete' [icon]='completeBtn ? "pi pi-spin pi-spinner":"pi pi-angle-right"' iconPos="right"
                  type='submit' styleClass="p-button-raised" (click)='register()'></p-button>
              </div>


            </div>
          </div>
        </div>
        <div class="p-pt-6" *ngIf="complete==true">
          <h1>Verification account</h1>
          <p>Please check your email for verification your accont</p>

          <button [disabled]="resend_verification" pButton (click)="resendVerification()" type="button"
            [label]='resend_verification?"Resend Again "+"("+resend_timer+")":"Resend Verification"'
            class="p-button-raised p-mt-5 p-button-lg"></button>
        </div>
      </div>

    </div>
    <p-card *ngIf="checkpass==true"
      [style]="{'width':'30rem','box-shadow':'none','position':'absolute','left':'75vw','top':'15vh','Background-color':'#e6eaf0'}">
      <h4>Your password must contain :</h4>
      <div class="p-grid">
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_lowercase" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_lowercase" class="far fa-times-circle text-danger p-mr-2"></i>
          Lowercase letter
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_capitalcase" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_capitalcase" class="far fa-times-circle text-danger p-mr-2"></i>
          Uppercase letter
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_numeric" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_numeric" class="far fa-times-circle text-danger p-mr-2"></i>
          Number
        </div>
        <div class="p-col-12 h4">
          <i *ngIf="passwrod_length" class="far fa-check-circle text-success p-mr-2"></i>
          <i *ngIf="!passwrod_length" class="far fa-times-circle text-danger p-mr-2"></i>
          Minimum 8 characters
        </div>
      </div>
    </p-card>
    <div class="desert"></div>
  </div>

</div>