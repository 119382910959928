import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Expenseitems } from 'src/app/models/expense/expenseItems';
import { Payment } from 'src/app/models/orders/Payment';
import { SendEmail } from 'src/app/models/SendEmail';
import { BaseComponent } from '../../core/base/base.component';
import { Options, ImageResult } from "ngx-image2dataurl";
import { ExpenseService } from '../expense.service';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { isSet } from '../../core/util';
import { Supplier } from 'src/app/models/supplier';
import { Subscription } from 'rxjs';
import { Payer } from 'src/app/models/expense/payer';
import { InputNumber } from 'primeng/inputnumber';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import * as printJS from 'print-js';
import { DomSanitizer } from '@angular/platform-browser';
import { CountryCode_currency } from 'src/app/models/country_code&currency';
import {Dropdown} from "primeng/dropdown";
import {Calendar} from "primeng/calendar";


@Component({
  selector: 'app-expense-form',
  templateUrl: './expense-form.component.html',
  styleUrls: ['./expense-form.component.scss']
})
export class ExpenseFormComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentChecked {
  expense: Expenseitems
  expensess: Expenseitems[]

  SendEmaildialog: boolean = false
  SendEmail = new SendEmail()
  SubjectSize
  SaveMessageState: boolean
  SendemailButton: boolean
  user_email: string
  pdfheight = 0
  selected_expense: boolean
  update_status: boolean = false
  loadingbtnupload: boolean = false
  viewAttachment_diloage = false
  restexpense = false
  viewRecpie: boolean
  currencies = []
  holdBaseValues = []
  indexVat
  expenseStatus = [
    { ex_status: 'Company Account', label: this.translate.instant('Company Account') },
    { ex_status: 'Claimed', label: this.translate.instant('Claimed') },
  ]
  required: boolean = false
  expenseNoteType = [
    { ex_type: 'Ticket', label: this.translate.instant('TICKET') },
    { ex_type: 'Invoice', label: this.translate.instant('DASHBORD_INVOICE') },
  ]
  expenseNoteType2 = [
    { ex_type_2: 'Fuel', label: this.translate.instant('Fuel') },
    { ex_type_2: "Hotel", label: this.translate.instant("Hotel") },
    { ex_type_2: "Travel", label: this.translate.instant("Travel") },
    { ex_type_2: "Transport", label: this.translate.instant("Transport") },
    { ex_type_2: "Meals", label: this.translate.instant("Meals") },
    { ex_type_2: "Parking", label: this.translate.instant("Parking") },
    { ex_type_2: "Communications", label: this.translate.instant("Communications") },
    { ex_type_2: "Tips", label: this.translate.instant("Tips") },
    { ex_type_2: "Purchase Material", label: this.translate.instant("Purchase Material") },
    { ex_type_2: "Purchase Consumables", label: this.translate.instant("Purchase Consumables") },
    { ex_type_2: "Insurance", label: this.translate.instant("Insurance") },
    { ex_type_2: "Supplies / Purchases", label: this.translate.instant("Supplies / Purchases") },
    { ex_type_2: "GSA", label: this.translate.instant("GSA") },
    { ex_type_2: "General & Administration", label: this.translate.instant("General & Administration") },
    { ex_type_2: "Outsourcing", label: this.translate.instant("Outsourcing") },
    { ex_type_2: "Utilities", label: this.translate.instant("Utilities") },
    { ex_type_2: "other", label: this.translate.instant("other") },
  ]

  paymenttypes = [
    { type: 'Cash', label: this.translate.instant('Cash') },
    { type: 'Bank Cards', label: this.translate.instant('Bank Cards') },
    { type: 'Wire Transfer', label: this.translate.instant('Wire Transfer') },
    { type: 'Check', label: this.translate.instant('Check') },
    { type: 'Voucher', label: this.translate.instant('Voucher') },
    { type: 'Other', label: this.translate.instant('other') }
  ]
  showPaymentDialog: boolean
  paymentheader: string
  newPayments: Payment[] = []
  paymentDue = 0
  paymentDuestatus: boolean = true
  totalpayment = 0
  totalpayment_refund = 0
  payment = new Payment()
  paymentUpdateindex = 0
  expenseTotal = 0
  paymentDuefromBalance: boolean
  payment_Options
  payerBalance = 0
  expenseTotalChange = 0
  payment_cardHeight = 0
  cardHeight = 0
  selected_VATs = []
  selected_IBans = []
  selected_Dates = []
  recpieImage: string
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  }

  vat_Percentage = [{ vat: '0' }, { vat: 6 }, { vat: 12 }, { vat: 21 }]


  showImportExpenss: boolean
  imageRecpitName: string
  imageRecpitType: string
  tabSelectedRecpit: boolean = false
  filetoUpload: any
  displayimg_localy: any
  displayimg_ai: any
  showDialogvat: boolean = false
  customVatAmount: string
  defultVatAmount: string
  serach_Vendor = false
  edit_expense = false
  dialogVat: string

  @Output() public sidebar = new EventEmitter();
  @ViewChild('amountt') amount: any;
  selectedexpense = new Expenseitems()


  @ViewChildren('amount') itemsAmount: any;

  @ViewChildren('exchange_rate') exchange_rates: ElementRef;

  @ViewChild('exchange_rate') exchange_rate: ElementRef;
  @ViewChild('total_Height') total_Height: ElementRef;
  @ViewChild('paymentcard') paymentcard: ElementRef;
  @ViewChild('upload') upload: ElementRef;
  @ViewChild('dropCategory') dropCategory: Dropdown;
  @ViewChild('DropDownStatus') DropDownStatus: Dropdown;
  @ViewChild('openCalender') openCalender: Calendar;
  @ViewChild('vatInput') vatInput: InputNumber;
  @ViewChild('selectedDate') selectedDate: Calendar;
  @ViewChild('selectedPaymentType') selectedPaymentType: Dropdown;
  @ViewChild('selectedCat') selectedCat: Dropdown;
  @ViewChild('selectedType') selectedType: Dropdown;

  constructor(
    private expenseService: ExpenseService,
    private router: Router,
    private sharedService: SharedService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private sanitizer: DomSanitizer

  ) {
    super()
  }

  ngOnInit(): void {
    this.getCountryCode_currency()
    this.getNextExpenseFormat();
    this.initExpense();
    this.subscribeOnFormSaved();
    this.updateEmitter()
    this.getSavedExpenseNote()
    this.subscribeOnExpenseSelected()
    this.showexpense_formDashboered()

    this.clearAll()


    if (!isSet(localStorage.getItem("defaultMessage"))) {
      this.SendEmail.message = ""
    } else {
      this.SendEmail.message = localStorage.getItem("defaultMessage")
    }
    this.payment_Options = [


      {

        label: this.translate.instant('FULL_CASH_PAID'), command: () => {
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true
            return
          }
          this.update_status = true
          this.payment = new Payment()
          this.payment.amount = this.paymentDue
          this.payment.type = 'Cash'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.expense.payer?.due)) {
            this.expense.payer.due = this.expense.payer.due + this.paymentDue
          }
          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.expense.payments.push(this.payment)
          this.payment = new Payment()

        },

      },
      {

        label: this.translate.instant('FULL_BANK_PAID'), command: () => {
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true

            return
          }
          this.update_status = true
          this.payment = new Payment()

          this.payment.amount = this.paymentDue
          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.type = 'Bank Cards'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.expense.payer?.due)) {
            this.expense.payer.due = this.expense.payer.due + this.paymentDue
          }
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.expense.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()



        }
      }


    ]
  }
  ngAfterViewInit() {
    this.payment_cardHeight = this.paymentcard.nativeElement.offsetHeight
    // this.cardHeight = this.total_Height.nativeElement.offsetHeight

  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();

  }
  private getNextExpenseFormat() {
    const subscription = this.expenseService.getNexExpenseNumber().subscribe((data: { format: string }) => {
      if (!isSet(data)) {
        return;
      }
      if (!isSet(this.expense.format)) {
        this.expense.format = data.format;
      }

      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }

  private initExpense() {
    this.required = false
    this.restexpense = false
    this.expense = Expenseitems.cloneObject(null);
    this.expense.ex_status = 'Claimed'
    this.expense.date = new Date();
    this.expense.payments = [];
    this.expense.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency?.name
    this.expense.vatRate = 0;
    this.expense.exchange_rate = 1
    this.expense.ex_type = 'Invoice'
    this.expense.vendor = new Supplier()
    this.expense.amount = 0;
    this.expense.items_vats = {}
    this.expense.iban=['']
    this.expense.base = {}
    this.expense.items = []
    this.expense.items.push({
      vat_rate: 0,
      base: 0,
      vat: 0,
    })
  }

  private subscribeOnFormSaved() {
    const subscription = this.expenseService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addExpense();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  addExpense(): void {
    console.log(this.expense)
    this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
    this.toVaild()
    let checkerr: Boolean
    if (
      (!isSet(this.expense?.format)) || (!isSet(this.expense?.date)) || (!isSet(this.expense?.amount))
      || this.expense?.amount == 0) {
      checkerr = false
      this.required = true
    } else {
      checkerr = true

    }
    if (!isSet(this.expense.vendor?.id)) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: "Please insert supplier name" });
      return
    }

    if (checkerr == false) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

    } else if (checkerr == true) {

      try {
        this.loading = true;
        const subscription = this.expenseService.addExpense(this.expense).subscribe((expense: Expenseitems) => {

          if (!isSet(expense)) {
            return;
          }
          this.expense.id = expense.id
          this.expense.payments?.map(payment => {
            payment.status2 = 'old'
          })
          this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('NEW_EXP_ADDED') });
          this.loading = false;
          this.edit_expense = false
          this.expenseService.refreshList.next(true)
          subscription.unsubscribe();
          this.expenseService.retrieve_lastExpense.next(this.expense)
          this.expenseService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)

        }, error => {

          if (error.error.detail == 'the expense format must be unique, please try changing it') {
            const subscription = this.expenseService.getNexExpenseNumber().subscribe((data: { format: string }) => {
              if (!isSet(data)) {
                return;
              }
              this.expense.format = data.format;
              this.addExpense()


              subscription.unsubscribe();
            }, error => {
              subscription.unsubscribe();
            });

          } else {
            if (isSet(error.error.detail)) {
              this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

            }
            this.messageService.add({ key: 'formM', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });

          }
          this.loading = false;
          subscription.unsubscribe();

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }



  }
  updateEmitter() {
    try {
      const subscription = this.expenseService.upadteFormClickedEmitter.subscribe(status => {

        if (!isSet(status) || !status) {
          return;
        }
        this.updateExpense()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);


    } catch (error) {
      this.log(error);
    }

  }
  updateExpense() {
    this.toVaild()
    let noerror: Boolean
    noerror = !((!isSet(this.expense.format))
      || (!isSet(this.expense.date)));
    if (noerror == false) {
      this.messageService.add({
        key: 'formM', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      if (this.update_status == false) {
        this.messageService.add({ key: 'formM', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });
      } else {
        try {
          this.loading = true;
          const subscription = this.expenseService.upadeExpense(this.expense).subscribe((expense: Expenseitems) => {

            if (!isSet(expense)) {
              return;
            }
            this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
            this.expense.payments.map(payment => {
              payment.status2 = 'old'
            })
            this.update_status = false
            this.edit_expense = false
            this.loading = false;
            subscription.unsubscribe();
            this.expenseService.refreshList.next(true)
            this.sharedService.update_form.next(false);
          }, error => {
            this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
            this.loading = false;
            subscription.unsubscribe();
          })
        } catch (error) {
          this.loading = false;
          this.log(error);
        }
      }
    }
  }
  private subscribeOnExpenseSelected() {
    const subscription = this.expenseService.expenseSelectedListEmitter.subscribe((expense: Expenseitems) => {
      if (!isSet(expense)) {
        return this.initExpense()
      }
      this.SendemailButton = true

      this.getExpense(expense.id);
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
    this.expenseService.expenseSelected.next(null)

  }
  private getExpense(id: string) {
    this.loading = true


    const subscription = this.expenseService.getExpense(id).subscribe((expense: Expenseitems) => {

      const cloneObject = Expenseitems.cloneObject(expense);

      this.selected_expense = true

      this.expense = cloneObject;
      this.expense.date = new Date(expense.date)
      this.payerBalance = this.expense.payer?.due

      // this.expenseNote.payer.contact.forEach(customer => {
      //   SendEmail.cloneObject(this.SendEmail)
      //   this.SendEmail.send_to_mails = []
      //   this.SendEmail.send_to_mails.push(customer.email)

      // })
      if (!isSet(this.expense.vendor?.id)) {

        this.expense.vendor = new Supplier()
        this.expense.vendor.name = this.expense.vendor_name
      } else {
        this.expense.vat = this.expense.vendor?.vat

        this.serach_Vendor = true

      }

      if (isSet(this.expense.base)) {
        this.expense.items = []
        for (const prop in this.expense.base) {

          this.expense.items.push({
            vat_rate: prop,
            base: this.expense?.base[prop],
          })
        }
        for (const prop in this.expense.items_vats) {
          this.expense.items.map(item => {
            if (item.vat_rate === prop) {
              item.vat = this.expense.items_vats[prop]
            }
          })

        }
      }

      this.expense.payments.map(payment => {
        payment.status2 = 'old'
        payment.dateP = new Date(payment.date)
      })
      if (this.expense.ex_type == 'Ticket') {
        this.expense.amount = this.expense.total

      }

      this.getPaymentDue()
      subscription.unsubscribe();



      this.loading = false

    }, error => {
      subscription.unsubscribe();
    });

  }
  payerChange(payer: Payer) {

    this.expense.payer = payer
    this.payerBalance = this.expense.payer?.due

    this.getPaymentDue()
    this.update_status = true


  }
  selectvendor($event) {
    const vndor = $event as Supplier
    this.expense.vendor = vndor
    this.expense.vat = vndor?.vat

    this.update_status = true


  }
  selectvendor_2($event) {
    const vndor = $event as Supplier
    this.expense.vendor = vndor

    this.update_status = true


  }

  getPaymentDue() {
    const payments_amountsadd = []
    const refundamounts = []
    if (this.expense?.payments?.length) {
      this.expense.payments.map(item => {
        if (item.status == "add") {
          payments_amountsadd.push(item.amount)
        }
        if (item.status == "refund") {
          refundamounts.push(item.amount)
        }
      })
      if (payments_amountsadd.length > 0) {
        this.totalpayment = payments_amountsadd.reduce((prev, next) => prev + next);
      }
      if (refundamounts.length > 0) {
        this.totalpayment_refund = refundamounts.reduce((prev, next) => prev + next);
      }
    }
    if (this.expenseTotal == 0) {
      this.expenseTotal = this.expense.totalwithVat
    }
    this.paymentDue = (this.expenseTotal - this.totalpayment) || 0
    this.paymentDue = this.totalpayment_refund + this.paymentDue
    if (this.paymentDuefromBalance == false && isSet(this.expense.payer?.due)) {
      this.payerBalance = this.expense.payer?.due + this.paymentDue
      this.paymentDuefromBalance = true

    }
    if (this.paymentDue == 0) {
      this.paymentDuestatus = true
    } else if (this.paymentDue > 0) {
      this.paymentDuestatus = false
    }
  }
  getexpenseTotalChange() {

    setTimeout(() => {
      if (this.paymentDue == 0 || !this.expense.payments.length) {
        this.paymentDue = this.expense.totalwithVat || 0
      }


      this.expenseTotalChange = this.expense.totalwithVat || 0
      this.paymentDue = this.paymentDue + this.expenseTotalChange || 0


      this.expenseTotal = this.expense.totalwithVat || 0
      if (this.paymentDue > 0) {
        this.paymentDuestatus = false
      }
      if (this.expense.totalwithVat < this.paymentDue) {
        this.getPaymentDue()
      }
      if (isSet(this.expense.payer?.due)) {
        this.expense.payer.due = this.payerBalance
        this.expense.payer.due = this.expense.payer.due - this.paymentDue
        this.expense.payer.due = -Math.abs(this.expense.payer.due)
      }
    }, 500)





  }
  showAddPaymnet() {
    if (this.paymentDue == 0) {
      return
    }
    this.paymentheader = this.translate.instant('ADD_PAYMENT')
    this.payment = new Payment()
    this.payment.dateP = new Date()
    this.payment.status = 'add'
    this.payment.status2 = 'new'
    this.amount.control.markAsUntouched()
    this.amount.control.clearValidators()
    this.showPaymentDialog = true
  }
  addPayment() {
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

      this.amount.control.markAsTouched();

    } else {
      if (Math.round(this.payment.amount*100)/100 >Math.round(this.paymentDue*100)/100 ) {
        
        return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_DUE') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      } else {

        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }

        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        if (this.expense.payments.length) {
          this.expense.payments.push(this.payment)
          this.paymentDue = this.paymentDue - this.payment.amount
          if (this.paymentDue == 0) {
            this.paymentDuestatus = true
          }
        } else {
          this.expense.payments.push(this.payment)
          this.getPaymentDue()
        }
        this.update_status = true
        if (isSet(this.expense.payer?.due)) {
          this.expense.payer.due = this.expense.payer.due + this.paymentDue
        }
        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()

      }
    }
  }
  showrefundPayment() {
    this.paymentheader = this.translate.instant('REFUND_PAYMENT')
    this.payment = new Payment()
    this.payment.dateP = new Date()
    this.payment.status = 'refund'
    this.payment.status2 = 'new'
    this.amount?.control?.markAsUntouched()
    this.amount?.control?.clearValidators()
    this.showPaymentDialog = true


  }
  refundPayment() {
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
      this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });

      this.amount.control.markAsTouched();
      let paymentDue = Number(this.paymentDue);
      this.payment.amount = Number(this.payment.amount);
      paymentDue = this.paymentDue + this.payment.amount;
    } else if ( Math.round(this.payment.amount*100)/100  > Math.round(this.expense.totalwithVat*100)/100) {
      return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
    } else if (this.payment.amount == 0) {
      return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
    } else {
      let paymentDue = Number(this.paymentDue)
      this.payment.amount = Number(this.payment.amount)
      paymentDue = this.paymentDue + this.payment.amount
      if ( Math.round(this.expense.totalwithVat*100)/100< Math.round(paymentDue*100)/100) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });

      }
      if (!isSet(this.payment.type)) {
        this.payment.type = 'Cash'
      }
      this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
      this.expense.payments.push(this.payment)
      this.paymentDue = Number(paymentDue)
      this.paymentDuestatus = false
      this.update_status = true

      if (isSet(this.expense.payer?.due)) {
        this.expense.payer.due = this.expense.payer.due + this.paymentDue
      }
      this.showPaymentDialog = false
      this.amount?.control?.markAsUntouched()
      this.amount?.control?.clearValidators()
    }
  }
  paymentReview(payment: Payment, index: number) {
    // if (payment.status2 == 'old') {
    //   return
    // }
    if (this.paymentDue == 0) {
      this.paymentDuestatus = true
    }
    this.payment = new Payment()
    this.payment.amount = payment.amount
    this.payment.notes = payment.notes
    this.payment.type = payment.type
    this.payment.status = payment.status
    this.payment.status2 = payment.status2
    this.payment.dateP = new Date(payment.dateP)
    this.paymentUpdateindex = index
    this.showPaymentDialog = true
    this.paymentheader = this.translate.instant('UPDATED_PAYMENT')
  }
  updatePayment() {

    const prv_paymnet = new Payment()
    prv_paymnet.amount = Number(this.expense.payments[this.paymentUpdateindex].amount)
    prv_paymnet.dateP = new Date(this.expense.payments[this.paymentUpdateindex].dateP)
    prv_paymnet.type = this.expense.payments[this.paymentUpdateindex].type
    prv_paymnet.notes = this.expense.payments[this.paymentUpdateindex].notes
    let paymentDue = Number(this.paymentDue)
    paymentDue = paymentDue - Number(prv_paymnet.amount)
    paymentDue = paymentDue + Number(this.payment.amount)
    if (this.payment.status == 'add') {

      if (((this.payment.amount > paymentDue) && (paymentDue > this.expense.totalwithVat))) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_DUE') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      }
    } else if (this.payment.status == 'refund') {

      if (this.expense.totalwithVat < paymentDue) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      }
    }
    this.paymentDue = paymentDue
    // this.payment.dateP=new Date()
    // this.payment.date =new Date(this.payment.dateP)
    this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');

    this.expense.payments[this.paymentUpdateindex] = this.payment
    this.expense.payments[this.paymentUpdateindex].amount = Number(this.expense.payments[this.paymentUpdateindex].amount)

    this.showPaymentDialog = false
    this.update_status = true
    this.payment = new Payment()
    this.getPaymentDue()





  }
  deletePayment(index) {

    this.paymentDuestatus = false
    if (this.expense.payments[index].status == 'add') {
      this.totalpayment = this.totalpayment - this.expense.payments[index].amount
    } else if (this.expense.payments[index].status == 'refund') {
      this.totalpayment_refund = this.totalpayment_refund - this.expense.payments[index].amount
    }
    this.expense.payments[index].amount = 0
    this.getPaymentDue()
    this.expense.payments.splice(index, 1)
    this.newPayments.splice(this.newPayments.length - 1, 1)

    this.getPaymentDue()
  }
  vatProcess(event){
    console.log(event);
    
    this.expenseService.filterVat.next(event)
  }

  notempty(event) {

    this.onChangeExType()
    this.update_status = true
    this.sharedService.update_form.next(true)
    this.getexpenseTotalChange()
  }
  onChangeVat(vatKey: any, amount: any) {
    this.update_status = true
    this.expense.items_vats[vatKey] = amount
    this.getexpenseTotalChange()
  }
  changeINAN(index:number,value){
    this.expense.iban[index]=value
  
  }
  onChangeAmount(vatKey: any, amount: any) {
    this.update_status = true

    this.expense.base[vatKey] = amount

    this.expense.items_vats[vatKey] = this.expense.base[vatKey] * (parseFloat(`${[vatKey]}`) / 100)
    this.expense.items.map(item => {
      if (item.vat_rate === vatKey) {
        item.vat = this.expense.items_vats[vatKey]
      }
    })

    this.getexpenseTotalChange()

  }
  numberOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;


  }
  toVaild() {
    this.expense.amount = Number(this.expense.amount)
    this.expense.exchange_rate = Number(this.expense.exchange_rate)
  }
  isSet(value: any) {
    return isSet(value);
  }
  erace_value(filedName: any) {


    if (filedName == 'amount') {

      this.expense.amount = 0
      setTimeout(() => {
        this.itemsAmount?.first?.input?.nativeElement?.select()
      }, 100)
    }
    if (filedName == 'exchange_rate') {
      this.expense.exchange_rate = 1
      setTimeout(() => {
        this.exchange_rates.nativeElement.select()

      }, 100)


    }
  }
  spaceNumber(number) {
    return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }
  // removeSpaceNumber() {

  //   let rmspaceQty = this.itemsAmount.nativeElement?.value.replace(/\s/g, '')

  //   this.expense.amount = parseInt(rmspaceQty)



  // }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }

      this.initExpense()
      this.sharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }
  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.sharedService.Save_object(this.expense)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }
  getSavedExpenseNote() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((expense: any) => {
      if (!isSet(expense)) {
        this.sharedService.objectstauts.next(null)

        return;
      }
      if (expense.url == this.router.url) {

        this.expense = expense.object
        this.sharedService.objectstauts.next(true)

      } else {
        this.sharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  showexpense_formDashboered() {
    let subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }
      if (item.itemName == 'Expense') {
        this.getExpense(item.item?.id)
      }
      this.sharedService.diplayDash.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }





  showUploadReceipt() {

    if (this.expense.payments.length || this.expense.amount > 0) {

      this.restexpense = true
    } else {
      this.upload.nativeElement.click()

    }
  }
  rest_Expense() {
    this.expense.ex_status = 'Claimed'
    this.expense.date = new Date();
    this.expense.payments = [];
    this.expense.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency?.name
    this.expense.exchange_rate = 1
    this.expense.ex_type = 'Ticket'
    this.expense.vendor = new Supplier()
    this.expense.amount = 0;
    this.expense.items = []
    this.expense.base = {}
    this.paymentDue = 0
    this.expense.items_vats = {}
    this.expense.vat = null
    this.expense.iban = null
    this.expense.description = null

    this.edit_expense = true
    this.getPaymentDue()
    this.restexpense = false
    this.upload.nativeElement.click()


  }
  uploadReceipt(imageResult: ImageResult) {

    this.selected_VATs = []
    this.selected_Dates = []
    this.selected_IBans = []
    this.selectedexpense = new Expenseitems()
    this.selectedexpense.payment_type = 'Cash'
    this.selectedexpense.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency.name

    this.loadingbtnupload = true;
    this.recpieImage = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;




    const filee = new FormData();
    this.imageRecpitName = imageResult.file.name
    this.imageRecpitType = imageResult.file.type
    this.srcToFile(this.recpieImage, imageResult.file.name, imageResult.file.type).then((file) => {
      if (imageResult.file.type == 'application/pdf') {

        this.myUploader(file)
      } else {
        this.displayimg_localy = this.sanitizer.bypassSecurityTrustResourceUrl(imageResult?.resized?.dataURL)
      }
      filee.append('file', file)
      this.messageService.add({ key: 'formM', severity: 'info', life: 2000, summary: this.translate.instant('INFO'), detail: 'Receipt is Uploading' });
      this.filetoUpload = file

      try {
        const subscription = this.expenseService.uploadReceipt(filee).subscribe((receipt: any) => {

          if (!isSet(receipt)) {
            return;
          }
          this.viewRecpie = true

          console.log(receipt)
          if (imageResult.file.type != 'application/pdf') {

            for (const prop in receipt.files) {
              this.selectedexpense.aiCropped = []

              this.selectedexpense.aiCropped.push(receipt.files[prop])
              let x = 'data:image/jpg;base64,' + this.selectedexpense.aiCropped[0]
              this.displayimg_ai = this.sanitizer.bypassSecurityTrustResourceUrl(x)

            }
          }
          // receipt type
          if (receipt?.type == 'ticket') {
            this.selectedexpense.ex_type = 'Ticket'

          } else {
            this.selectedexpense.ex_type = 'Invoice'

          }
          // receipt date
          if (isSet(receipt?.date) && receipt?.date != '') {


            if (Array.isArray(receipt?.date)) {
              receipt?.date.map(d => {
                d = new Date(d)
                this.selected_Dates.push({ date: d })
              })

            } else {
              this.selectedexpense.datePipe = new Date(receipt?.date)
              this.selectedexpense.date = new Date(receipt?.date)

            }
          }else{
            this.selectedexpense.date=new Date()
            this.selectedexpense.datePipe =new Date()
          }

          // receipt vendor_name
          if (receipt?.vendor_name) {
            this.selectedexpense.vendor = new Supplier()
            this.selectedexpense.vendor.name = receipt?.vendor_name
            this.selectedexpense.vendor_name = receipt?.vendor_name

          }


          // receipt summary
          if (Array.isArray(receipt.total)) {

            if (!this.selectedexpense?.items?.length) {
              this.selectedexpense.items = []
            }
            receipt?.total.map(item => {
              this.selectedexpense.base = {
                [item.vat_rate]: item?.base
              }
              this.selectedexpense.items_vats = {
                [item.vat_rate]: item?.vat
              }
              this.selectedexpense.items.push({
                vat_rate: item.vat_rate,
                base: item.base,
                vat: item.vat,
              })
            })

          } else {

            this.selectedexpense.amount = parseFloat(receipt?.total) || 0

          }
          this.selectedexpense.amount = parseFloat(receipt?.total) || 0
          // receipt invoice_format
          // this.selectedexpense.invoice_format = receipt?.invoice_nu


          this.selectedexpense.exchange_rate = 1

          // receipt vats
          if (receipt?.vat?.length > 1) {
            for (let index = 0; index < receipt?.vat?.length; index++) {

              this.selected_VATs[index] = { vat: receipt?.vat[index][0] }

            }
          }
          if (receipt?.vat?.length == 1) {
            this.selectedexpense.vat = receipt?.vat[0][0]
            this.selected_VATs.push(receipt?.vat[0][0])
          }

          // receipt vcs
          if (receipt?.vcs) {
            this.selectedexpense.vcs = receipt?.vcs

          }

          // receipt iban
          if (receipt?.iban?.length > 1) {

            for (let index = 0; index < receipt?.iban?.length; index++) {

              this.selected_IBans[index] = { iban: receipt?.iban[index][0] }


            }

            if (receipt?.iban?.length == 1) {

              this.selectedexpense.iban = receipt?.iban[0][0]
              this.selected_IBans.push(receipt?.iban[0][0])
            }

          }
          console.log(this.expense)
          this.recpieImage = null
          this.update_status = true
          this.loadingbtnupload = false

          this.messageService.add({ key: 'formM', life: 4000, severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Receipt Uploaded Successfully' });



          subscription.unsubscribe();

        }, error => {


          this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
          this.loadingbtnupload = false;
          subscription.unsubscribe();


        })
      } catch (error) {
        console.log(error);

      }

    })
  }
  confirmRreceipt_withpdf() {
    this.viewRecpie = false
    setTimeout(() => {
      this.selectedExpenseRecipe()
      this.recpieImage = null
      this.tabSelectedRecpit = false
    }, 500);
    this.getexpenseTotalChange()
  }

  confirmRreceipt_withOriginal() {
    this.viewRecpie = false
    setTimeout(() => {
      this.selectedExpenseRecipe()
    }, 500);
    this.getexpenseTotalChange()
  }

  confirmRreceipt_withCropped() {
    this.viewRecpie = false
    this.recpieImage = null
    setTimeout(() => {
      let aiCropped = this.selectedexpense.aiCropped[0]
      this.selectedexpense.aiCropped = []
      aiCropped = 'data:image/png;base64,' + aiCropped
      this.srcToFile(aiCropped, this.imageRecpitName, this.imageRecpitType).then((file) => {
        this.myUploader(file)
      }).then(() => {
        this.selectedExpenseRecipe()
      })
    }, 0);
    this.getexpenseTotalChange()
  }

  selectedExpenseRecipe(){
    this.loading=true
    const clone = Expenseitems.cloneObject(this.expense)
    this.expense = Expenseitems.cloneObject(this.selectedexpense)
    console.log(this.expense)
    this.expense.format = clone.format
    this.expense.ex_status = clone.ex_status
    this.expense.title = clone.title
    this.expense.id = clone?.id

    this.expense.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency?.name
    this.expense.loadingbtnupload = false
    console.log(this.expense)
    if (this.expense.ex_type=='Ticket'&& this.expense.amount==0){
      this.expense.payments=[]
    }else {
      if (this.expense?.items?.length) {
        this.expense?.items?.map(item => {
          this.expense.items_vats={}
          this.expense.base={}
          this.expense.items.shift()
          this.expense.items_vats[item.vat_rate] = item?.base*(Number(item.vat_rate)/100)
          this.expense.base[item.vat_rate] =item?.base
          this.expense.items.push({
            vat_rate: item.vat_rate,
            base: item?.base,
            vat: item?.base*(Number(item.vat_rate)/100),
          })
        })
      }
      this.expense.payments = clone.payments
      this.payment = new Payment()
      this.payment.dateP = this.expense.datePipe
      this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
      this.payment.status = 'add'
      this.payment.status2 = 'new'
      this.payment.type = this.expense?.payment_type
      this.payment.amount = Number(this.expense?.totalwithVat)
      this.expense.payments.push(this.payment)
    }
    this.expense.ex_type=null
    setTimeout(()=>{
      this.expense.ex_type=this.selectedexpense.ex_type
      this.expenseService.filterVat.next(this.expense.vat)
    },0)

    this.getPaymentDue()
    this.loading=false
    this.viewRecpie = false
    this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('RECE_CONF') });
  }
  canceleviewRecpie() {
    this.viewRecpie = false
    this.recpieImage = null
    this.loadingbtnupload = false
    setTimeout(() => {
      this.selectedexpense = null
      this.selectedexpense = new Expenseitems()
    }, 500);
  }

  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  myUploader(file) {

    const generatUpload = {
      file_type: 'ExpenseItem',
      file_path: file.name

    }

    this.expenseService.generate_Upload(generatUpload)

    this.expenseService.upload(file)
    try {
      this.expenseService.uploadstuatsEmitter.subscribe(status => {
        if (!isSet(status) || !status) {
          return;
        }
        if (status === true) {
          this.selectedexpense.attachment = {
            name: file.name,
            path: this.expenseService.attachmentlink,
            type: file.type
          }
          this.expense.attachment = this.selectedexpense.attachment
          this.generate_download(this.selectedexpense)



        }
        else if (status === false) {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });

        }
      }, error => {
        this.loading = false
        this.handleError(error);

      });
    } catch (error) {
      console.log(error);

    }
  }
  generate_download(expense) {
    let attch = expense

    if (expense != null) {
      attch = expense?.attachment?.path
    } else {
      attch = this.expense?.attachment?.path

    }
    try {

      this.expenseService.generate_download(attch).subscribe(link => {

        if (expense != null) {

          this.selectedexpense.download_url = link?.download_url
          this.selectedexpense.view_url = link?.view_url
        } else {
          this.expense.download_url = link?.download_url
          this.expense.view_url = link?.view_url
        }

      }, error => {
        this.loading = false
        this.handleError(error);

      })

    } catch (error) {
      this.log(error);
    }
  }
  getSantizeUrl(url: string) {
    if (isSet(url)) {
      return this.sanitizer.bypassSecurityTrustUrl(url);

    }
  }

  downloadlink() {
    window.location.href = this.expense.download_url;
  }

  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  showViewReceipt() {

    this.viewAttachment_diloage = true
    this.generate_download(null)

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  deletefile() {
    this.viewAttachment_diloage = false

    try {

      this.loading = true;
      const subscription = this.expenseService.delete_File(this.expense?.attachment?.path).subscribe(() => {
        this.loading = false;

        this.expense.attachment = {}

        this.update_status = true
        if (this.selected_expense == true) {
          this.expenseService.upadteFormClicked.next(true)

        }

        this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('RECE_DEL') });


        subscription.unsubscribe();


      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

        this.loading = false;
        subscription.unsubscribe();
      })
    }
    catch (error) {
      console.log(error)
      this.log(error);
    }
  }
  getCountryCode_currency() {
    this.currencies = JSON.parse(localStorage.getItem('currencies'))

    if (this.currencies?.length) {
      return
    }
    const subscription = this.expenseService.CountryCode_currency().subscribe((countryCode_currency: CountryCode_currency[]) => {
      if (!isSet(countryCode_currency)) {
        return;
      }
      this.currencies = []
      countryCode_currency.map(c => {
        this.currencies.push({ currency: c.currency })
      })
      let rmAduplicate = []

      this.currencies.forEach((item) => {
        if (rmAduplicate.findIndex(i => i.currency == item.currency) === -1) {
          rmAduplicate.push(item)
        }
      });

      this.currencies = rmAduplicate
      localStorage.setItem("currencies", JSON.stringify(this.currencies))

      subscription.unsubscribe()
    }, error => {
      console.log(error);
      subscription.unsubscribe()
    })
  }
  printPdf() {

    printJS({ printable: this.expense.download_url, type: 'pdf', showModal: true })

  }
  // Save_Message() {


  //   localStorage.setItem("defaultMessage", this.SendEmail.message)


  // }
  // send_Email(): void {


  //   try {
  //     this.expenseService.btnLoading.next(true)
  //     const subscription = this.expenseService.SendEmail(this.SendEmail, this.expenseNote.id).subscribe((sendEmail: SendEmail) => {

  //       if (!isSet(sendEmail)) {
  //         return;
  //       }
  //       this.messageService.add({ key: 'formM', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SENT_EMAILS') });
  //       this.expenseService.btnLoading.next(false)

  //       subscription.unsubscribe();

  //     }, error => {

  //       this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: error });
  //       this.expenseService.btnLoading.next(false)

  //       subscription.unsubscribe();

  //     })
  //   } catch (error) {
  //     this.loading = false;
  //     this.log(error);

  //   }
  //   this.SendEmaildialog = false
  // }

  // send_copyto_myself() {
  //   const user: User = JSON.parse(localStorage.getItem("user"))
  //   const email = this.SendEmail.send_to_mails
  //   if (this.SendEmail.send_to_mails.includes(user.email) == false && this.user_email != null) {
  //     email.push(user.email)
  //     this.SendEmail.send_to_mails = []
  //     this.user_email = user.email

  //     email.map(em => {
  //       this.SendEmail.send_to_mails.push(em)
  //     })
  //   } else {

  //     this.SendEmail.send_to_mails = this.SendEmail.send_to_mails.filter(item => item !== user.email)
  //     this.user_email = null

  //   }

  // }
  // onRemovemail() {
  //   this.SendEmail.send_to_mails.find(email => {
  //     if (email != this.user_email) {
  //       this.user_email = null
  //     }
  //   })
  // }
  addvatInput(): void {
    for (const prop in this.expense.items_vats) {
      if (prop == this.customVatAmount) {
        return this.messageService.add({ key: 'formM', severity: 'error', summary: this.translate.instant('ERROR'), detail: "The value you entered is existent" });

      }
    }
    this.expense.items_vats[this.customVatAmount] = 0
    this.expense.base[this.customVatAmount] = 0
    if (!this.expense?.items?.length) {
      this.expense.items = []
    }
    this.expense.items.push({
      vat_rate: this.customVatAmount,
      base: 0,
      vat: 0,
    })
    this.customVatAmount = null
    this.showDialogvat = false
  }
  deleteVatInput(value, index: number) {
    delete this.expense.items_vats[value]
    delete this.expense.base[value]
    this.expense.items.splice(index, 1)
    this.update_status = true
    this.getexpenseTotalChange()

  }
  deleteIBANInput( index: number) {
    this.expense.iban.splice(index, 1)
  }
  addIBAN(){
    this.expense.iban.push('')
  }
  changeVatInput(value, index: number) {
    this.dialogVat = 'Edit VAT'
    this.customVatAmount = value
    this.defultVatAmount=value
    this.indexVat = index
    this.showDialogvat = true
  setTimeout(()=>{
    this.vatInput.onInputFocus(this.vatInput.input)
  },0)
  }

  editVatInput() {
    if (this.customVatAmount<this.defultVatAmount) {
      return  this.messageService.add({ key: 'formMs', severity: 'error', summary: this.translate.instant('ERROR'), detail: "Cant apply change becuase Due 0 " });

    }
    const base = this.expense.base[this.defultVatAmount]
    delete this.expense.items_vats[this.defultVatAmount]
    delete this.expense.base[this.defultVatAmount]
    this.expense.items.splice(this.indexVat, 1)
    this.expense.items_vats[this.customVatAmount] = base*(Number(this.customVatAmount)/100)
    this.expense.base[this.customVatAmount] =base
    this.expense.items.push({
      vat_rate: this.customVatAmount,
      base: base,
      vat: base*(Number(this.customVatAmount)/100),
    })
    this.update_status = true
    this.showDialogvat = false
    this.getexpenseTotalChange()
    this.getPaymentDue()
  }
  onChangeExType() {

    if (this.expense.ex_type == 'Ticket' && this.expense.subTotal == 0) {
      this.expense.items = []
      this.expense.base = {
        [0]: this.expense.totalwithVat
      }
      this.expense.items_vats = {
        [0]: 0
      }
      this.expense.items.push({
        vat_rate: 0,
        base: this.expense.totalwithVat,
        vat: 0,
      })
    } else {
      this.expense.amount = this.expense.totalwithVat
    }
  }

  onSelectedChangeExType() {
    if (this.selectedexpense.ex_type == 'Ticket' && this.selectedexpense.subTotal == 0) {
      this.selectedexpense.items = []
      this.selectedexpense.base = {
        [0]: this.selectedexpense.amount
      }
      this.selectedexpense.items_vats = {
        [0]: 0
      }
      this.selectedexpense.items.push({
        vat_rate: 0,
        base: this.selectedexpense.amount,
        vat: 0,
      })
      this.selectedexpense.amount = null
    }
    else {
      this.selectedexpense.amount = this.selectedexpense.totalwithVat
    }
  }

  focusThenShow(type){
  switch (type) {
    case 'status':
      setTimeout(()=>{
        this.DropDownStatus.focus()
        this.DropDownStatus.show()
      },0)
      break;
      case 'cat':
      setTimeout(()=>{
        this.dropCategory.focus()
        this.dropCategory.show()
      },0)
      break;
    case 'date':
      setTimeout(()=>{
        this.openCalender.toggle()
      },0)
      break;
    case 'selectedDate':
      setTimeout(()=>{
        this.selectedDate.toggle()
      },0)
      break;
      case 'selectedPaymentType':
      setTimeout(()=>{
        this.selectedPaymentType.focus()
        this.selectedPaymentType.show()
      },0)
      break;
      case 'selectedCat':
      setTimeout(()=>{
        this.selectedCat.focus()
        this.selectedCat.show()
      },0)
      break;
      case 'selectedType':
      setTimeout(()=>{
        this.selectedType.focus()
        this.selectedType.show()
      },0)
      break;
    }
  }
}
