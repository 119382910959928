export interface VatInfo {
  countryCode: string;
  vatNumber: string;
  requestDate: string;
  valid: boolean;
  name: string;
  address: string;
}

export enum VatInfoCountry {
  BE = 'Belgium'
}
