import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { isSet } from 'src/app/modules/core/util';
import { MainComponent } from '../main/main.component';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']

})
export class MenuComponent extends BaseComponent implements OnInit {

  model: any[];

  constructor(public app: MainComponent, private menuService: MenuService,
    public translate: TranslateService) {
      super()
  }

  ngOnInit() {
    this.model = [
      { label: this.translate.instant('CUSTOMER_LIST_DASHBOARD'), icon: 'pi pi-fw pi-home', routerLink: ['/'] },
      {
        label: this.translate.instant('SELL'), icon: 'fa-menu fa fa-users',
        items: [
          { label: this.translate.instant('CUSTOMER'), icon: 'fa-menu fa fa-users', routerLink: ['/sell/customers'] },
          { label: this.translate.instant('QUOTATION'), icon: 'fa-menu fa fa-receipt', routerLink: ['/sell/quotes'] },
          { label: this.translate.instant('ORDERS'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/sell/orders'] },
          { label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/sell/creditnotes'] },
          // {label: 'Invoices', icon: 'fa-menu fa fa-file-invoice', routerLink: ['/components/forms']},
          // {label: 'Payments', icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms']},
        ]
      },
      {
        label: this.translate.instant('BUY'), icon: 'fa-menu fa fa-user-friends',
        items: [
          { label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends', routerLink: ['/buy/suppliers'] },
          { label: this.translate.instant('PUR_ORDERS'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/buy/purchaseorders'] },
          // {label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/buy/creditnotes']},
        ]
      },
      {
        label: this.translate.instant('DASHBORD_EXPENSES'), icon: 'fa-menu fa fa-chart-line',
        items: [
          { label: this.translate.instant('NEW_EXPENSE'), icon: 'fa-menu fa fa-chart-line', routerLink: ['/expense'] },
          { label: this.translate.instant('EXPENSE_NOTE'), icon: 'fa-menu fa fa-chart-line', routerLink: ['/expense/expensenote'] },
        ]
      },
      {
        label: this.translate.instant('ACCOUNTING'), icon: 'fa-menu fa fa-calculator',
        items: [
          { label:  this.translate.instant('SALES_JOURNAL'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/accounting/sales-journals'] },
          { label: this.translate.instant('PUR_JOURNAL'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/accounting/purchases-journals'] }
        ]
      },{
        label: this.translate.instant('USERS'), icon: 'fa-menu fa fa-users',
        items: [
          { label:  this.translate.instant('USERS'), icon: 'fa-menu fa fa-users', routerLink: ['/users']},
          { label:  this.translate.instant('ROLES'), icon: 'fa-menu fa fa-users', routerLink: ['/users/roles']}
      ]
      },
      {
        label: this.translate.instant('CALENDER'), icon: 'far fa-calendar-alt', routerLink: ['erp/calendar']
      },
      {
        label: this.translate.instant('INVOEN'), icon: 'fa-menu fa fa-truck-moving',
        items: [
          { label: this.translate.instant('PRODUCTS'), icon: 'fas fa-dolly', routerLink: ['/products'] }
        ]
      },
      {
        label: this.translate.instant('COMING_SOON'), icon: 'fas fa-hourglass-half',
        items: [
          {
            label: this.translate.instant('CUSTOMER'), icon: 'fa-menu fa fa-users',
            items: [
              { label: this.translate.instant('Invoices'), icon: 'fa-menu fa fa-file-invoice', routerLink: ['/components/forms'] },
              { label: this.translate.instant('ORDERS_FORM_PAYMENTS'), icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms'] },
            ]
          },
          {
            label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends',
            items: [
              { label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/suppliers/creditnotes'] },
            ]
          },
          {
            label: this.translate.instant('ACCOUNTING'), icon: 'fa-menu fa fa-calculator',
            items: [
              { label: this.translate.instant('Expenses journals'), icon: 'fa-menu fa fa-chart-line', routerLink: [''] },
              { label: this.translate.instant('Account balance'), icon: 'fa-menu fa fa-file-invoice', routerLink: [''] },
              { label: this.translate.instant('VAT position'), icon: 'fa-menu fa fa-percent', routerLink: [''] },
              { label: this.translate.instant('Inventory position'), icon: 'fa-menu fa fa-warehouse', routerLink: [''] },
            ]
          },
          {
            label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends',
            items: [
              { label: 'Tax', icon: 'fa-menu fab fa-expeditedssl', routerLink: ['/suppliers/taxes'] },
              { label: this.translate.instant('ORDERS_FORM_PAYMENTS'), icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms'] },
            ]
          },
          {
            label: 'ERP', icon: 'fa-menu fa fa-tasks',
            items: [
              { label: this.translate.instant('Timesheet'), icon: 'fa-menu fa fa-fingerprint', routerLink: [''] },
              { label:  this.translate.instant('Communicator'), icon: 'fa-menu fa fa-comment-dots', routerLink: [''] },
            ]
          }, {
            label: 'CRM', icon: 'fa-menu fa fa-project-diagram',
          },
          {
            label: 'Reports', icon: 'fa-menu fa fa-chart-pie',
            items: [
              { label: 'Report 1', icon: 'fa-menu fa fa-chart-line', routerLink: ['/components/sample'] },
              { label: 'Report 2', icon: 'fa-menu fa fa-chart-bar', routerLink: ['/components/sample'] },
              { label: 'Report 3', icon: 'fa-menu fa fa-chart-area', routerLink: ['/components/sample'] },
            ]
          },
        ]
      },

    ];
    this.refreshShapes();
  }

  onMenuClick() {
    this.app.menuClick = true;
  }
  refreshShapes() {
    const subscription = this.menuService.refreshShapesEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      setTimeout(() => {
        this.model = [
          { label: this.translate.instant('CUSTOMER_LIST_DASHBOARD'), icon: 'pi pi-fw pi-home', routerLink: ['/'] },
          {
            label: this.translate.instant('SELL'), icon: 'fa-menu fa fa-users',
            items: [
              { label: this.translate.instant('CUSTOMER'), icon: 'fa-menu fa fa-users', routerLink: ['/sell/customers'] },
              { label: this.translate.instant('QUOTATION'), icon: 'fa-menu fa fa-receipt', routerLink: ['/sell/quotes'] },
              { label: this.translate.instant('ORDERS'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/sell/orders'] },
              { label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/sell/creditnotes'] },
              // {label: 'Invoices', icon: 'fa-menu fa fa-file-invoice', routerLink: ['/components/forms']},
              // {label: 'Payments', icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms']},
            ]
          },
          {
            label: this.translate.instant('BUY'), icon: 'fa-menu fa fa-user-friends',
            items: [
              { label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends', routerLink: ['/buy/suppliers'] },
              { label: this.translate.instant('PUR_ORDERS'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/buy/purchaseorders'] },
              // {label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/buy/creditnotes']},
            ]
          },
          {
            label: this.translate.instant('DASHBORD_EXPENSES'), icon: 'fa-menu fa fa-chart-line',
            items: [
              { label: this.translate.instant('NEW_EXPENSE'), icon: 'fa-menu fa fa-chart-line', routerLink: ['/expense'] },
              { label: this.translate.instant('EXPENSE_NOTE'), icon: 'fa-menu fa fa-chart-line', routerLink: ['/expense/expensenote'] },
            ]
          },
          {
            label: this.translate.instant('ACCOUNTING'), icon: 'fa-menu fa fa-calculator',
            items: [
              { label:  this.translate.instant('SALES_JOURNAL'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/accounting/sales-journals'] },
              { label: this.translate.instant('PUR_JOURNAL'), icon: 'fa-menu fa fa-shopping-cart', routerLink: ['/accounting/purchases-journals'] }
            ]
          },
          {
            label: this.translate.instant('USERS'), icon: 'fa-menu fa fa-users',
            items: [
              { label:  this.translate.instant('USERS'), icon: 'fa-menu fa fa-users', routerLink: ['/users']},
              { label:  this.translate.instant('ROLES'), icon: 'fa-menu fa fa-users', routerLink: ['/users/roles']}
          ]
          },
          {
            label: this.translate.instant('CALENDER'), icon: 'far fa-calendar-alt', routerLink: ['erp/calendar']
          },
          {
            label: this.translate.instant('INVOEN'), icon: 'fa-menu fa fa-truck-moving',
            items: [
              { label: this.translate.instant('PRODUCTS'), icon: 'fas fa-dolly', routerLink: ['/products'] }
            ]
          },
          {
            label: this.translate.instant('COMING_SOON'), icon: 'fas fa-hourglass-half',
            items: [
              {
                label: this.translate.instant('CUSTOMER'), icon: 'fa-menu fa fa-users',
                items: [
                  { label: this.translate.instant('Invoices'), icon: 'fa-menu fa fa-file-invoice', routerLink: ['/components/forms'] },
                  { label:  this.translate.instant('ORDERS_FORM_PAYMENTS'), icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms'] },
                ]
              },
              {
                label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends',
                items: [
                  { label: this.translate.instant('DASHBORD_CREDIT_NOTES'), icon: 'fa-menu fa  fa-file-invoice-dollar', routerLink: ['/suppliers/creditnotes'] },
                ]
              },
              {
                label: this.translate.instant('ACCOUNTING'), icon: 'fa-menu fa fa-calculator',
                items: [
                  { label: this.translate.instant('Expenses journals'), icon: 'fa-menu fa fa-chart-line', routerLink: [''] },
                  { label:  this.translate.instant('Account balance'), icon: 'fa-menu fa fa-file-invoice', routerLink: [''] },
                  { label: this.translate.instant('VAT position'), icon: 'fa-menu fa fa-percent', routerLink: [''] },
                  { label: this.translate.instant('Inventory position'), icon: 'fa-menu fa fa-warehouse', routerLink: [''] },
                ]
              },
              {
                label: this.translate.instant('SUPPLIERS_LIST_SUPPLIERS'), icon: 'fa-menu fa fa-user-friends',
                items: [
                  { label: 'Tax', icon: 'fa-menu fab fa-expeditedssl', routerLink: ['/suppliers/taxes'] },
                  { label: this.translate.instant('ORDERS_FORM_PAYMENTS'), icon: 'fa-menu fa fa-money-bill-alt', routerLink: ['/components/forms'] },
                ]
              },
              {
                label: 'ERP', icon: 'fa-menu fa fa-tasks',
                items: [
                  { label: this.translate.instant('Timesheet'), icon: 'fa-menu fa fa-fingerprint', routerLink: [''] },
                  { label:  this.translate.instant('Communicator'), icon: 'fa-menu fa fa-comment-dots', routerLink: [''] },
                ]
              }, {
                label: 'CRM', icon: 'fa-menu fa fa-project-diagram',
              },
              {
                label: 'Reports', icon: 'fa-menu fa fa-chart-pie',
                items: [
                  { label: 'Report 1', icon: 'fa-menu fa fa-chart-line', routerLink: ['/components/sample'] },
                  { label: 'Report 2', icon: 'fa-menu fa fa-chart-bar', routerLink: ['/components/sample'] },
                  { label: 'Report 3', icon: 'fa-menu fa fa-chart-area', routerLink: ['/components/sample'] },
                ]
              },
            ]
          },
    
        ];
      }, 500);
      this.menuService.refreshShapes.next(null)
    }, error => {
      console.log(error);
    });
    this.subscriptions.push(subscription);
  }
}
