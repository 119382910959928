import { Product } from '../product';
import { Unit } from '../unit';
import { isSet } from '../../modules/core/util';
import { Vat_Product } from '../vat-product';
import { Warehouses } from '../PurchaseOrders/warehouses';

export class OrderItem {
  id: string;
  product: Product;
  product_id: string;
  name: string;
  service: string
  datePipe: Date
  date: any;
  qty?: any;
  unit: Unit;
  price?: any;
  priceType?: PriceType;
  vat?: Vat_Product;
  created_at: Date;
  received_qty?: number;
  tax: number;
  created_by: string;
  discount: number;
  updated_at: Date;
  total: number
  warehouse: Warehouses
  inline_Epand: boolean;
  checkinlne: boolean
  order: string
  itemVaild: boolean
  discont_Percentage: any;
  exchange_rate: number
  get remained() {
    if (isSet(this.price) && isSet(this.qty)) {
      return this.qty - this.received_qty;
    }
    return 0;
  }

  get totalNet() {


    if (this.discont_Percentage > 0) {

      const total = parseFloat(`${this.price}`) * parseFloat(`${this.qty}`) 
      
      return (total - (total * this.discont_Percentage)) *this.exchange_rate
    }
    if (isSet(this.price) && isSet(this.qty)) {

      return (parseFloat(`${this.price}`) * parseFloat(`${this.qty}`)) *this.exchange_rate
    }
    
    return 0;
  }
  get default_currncey_totalNet() {
    
    if (this.discont_Percentage > 0) {

      const total = parseFloat(`${this.price}`) * parseFloat(`${this.qty}`) 
      return (total - (total * this.discont_Percentage))  
    }
    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * parseFloat(`${this.qty}`))  
    }
    return 0;
  }

  get item_total() {
    if (isSet(this.price) && isSet(this.qty)) {

      return this.totalNet + this.vatAmount
    }

    return 0;
  }
  get default_currncey_item_total() {
    
    if (isSet(this.price) && isSet(this.qty)) {
      return this.default_currncey_totalNet + this.default_currncey_vatAmount
    }
    return 0;
  }


  get vatAmount() {
    if (this.discont_Percentage > 0) {
      const t = parseFloat(`${this.price}`) * parseFloat(`${this.qty}`) * this.exchange_rate
      const amount = t * (parseFloat(`${this.vat?.value}`) / 100)
      
      return (amount - (amount * this.discont_Percentage) || 0) 
    }
    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * (parseFloat(`${this.vat?.value}`) / 100) * parseFloat(`${this.qty}`)) *this.exchange_rate
    }
    return 0;
  }
  get default_currncey_vatAmount() {
    if (this.discont_Percentage > 0) {
      const t = parseFloat(`${this.price}`) * parseFloat(`${this.qty}`) 
      const amount = t * (parseFloat(`${this.vat?.value}`) / 100)
      return (amount - (amount * this.discont_Percentage) || 0)
    }
    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * (parseFloat(`${this.vat?.value}`) / 100) * parseFloat(`${this.qty}`))
    }
    return 0;
  }


  get beforediscont_total() {
    if (isSet(this.price) && isSet(this.qty)) {
      return this.beforediscont_totalNet + this.beforediscont_vatAmount
    }
    return 0;
  }
  get default_currncey_beforediscont_total() {
    if (isSet(this.price) && isSet(this.qty)) {
      return this.default_currncey_beforediscont_totalNet + this.default_currncey_beforediscont_vatAmount
    }
    return 0;
  }



  get beforediscont_totalNet() {

    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * parseFloat(`${this.qty}`)) *this.exchange_rate
    }
    return 0;
  }
  get default_currncey_beforediscont_totalNet() {
    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * parseFloat(`${this.qty}`)) 
    }
    return 0;
  }

  get beforediscont_vatAmount() {

    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * (parseFloat(`${this.vat?.value}`) / 100) * parseFloat(`${this.qty}`)) *this.exchange_rate
    }
  }
  get default_currncey_beforediscont_vatAmount() {
    if (isSet(this.price) && isSet(this.qty)) {
      return (parseFloat(`${this.price}`) * (parseFloat(`${this.vat?.value}`) / 100) * parseFloat(`${this.qty}`)) 
    }
  }
 


 

  public constructor(params?: OrderItem) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: OrderItem) {
    if (!isSet(objectToClone)) {
      return new OrderItem();
    }
    const obj = new OrderItem(objectToClone);
    obj.product = Product.cloneObject(objectToClone?.product);
    obj.warehouse = Warehouses.cloneObject(objectToClone?.warehouse);
    obj.unit = Unit.cloneObject(objectToClone?.unit);
    obj.vat = Vat_Product.cloneObject(objectToClone?.vat);

    return obj;
  }

  public static cloneManyObjects(objectsToClone: OrderItem[]) {
    const objects: OrderItem[] = [];
    for (const obj of objectsToClone) {
      objects.push(OrderItem.cloneObject(obj));
    }

    return objects;
  }
}

export enum PriceType {
  Custom = 'custom',
  Normal = 'normal',
  Special = 'special',
  Group = 'group',
}
