import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar/calendar.component';
import { RouterModule, Routes } from '@angular/router';

import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid'; 
import list from '@fullcalendar/list'; 
import interactionPlugin from '@fullcalendar/interaction';

import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {DropdownModule} from 'primeng/dropdown';
import { SharedModule } from '../shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';


export const routes: Routes = [
  {path: '', component: CalendarComponent}
];
FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  list,
  interactionPlugin,

]);
@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CommonModule,
  RouterModule.forChild(routes),FormsModule,
  FullCalendarModule,TranslateModule,
  DialogModule,InputTextModule,CalendarModule,CheckboxModule,DropdownModule,
  ButtonModule,TabViewModule,ToastModule,InputTextareaModule,SharedModule
  
  ]
})
export class _CalendarModule { }
