import {Component, ElementRef, HostListener, NgZone, OnInit, ViewChild,} from "@angular/core";
import {BaseComponent} from "../../core/base/base.component";
import {SidebarSize} from "../../shared/enums/sidebarSize";
import {Quote} from "../../../models/quotes/quote";
import {PaginatedResponse} from "../../../models/paginatedresponse";
import {isSet} from "../../core/util";
import {MenuItem, MessageService} from "primeng/api";
import {PurchaseOrdersService} from "../PurchaseOrders.service";
import {SharedService} from "../../shared/shared.service";
import {Router} from "@angular/router";
import {PurchaseOrders} from "src/app/models/PurchaseOrders/PurchaseOrders";
import {PrintOrder} from "../../../models/printorder";
import {ImageResult, Options} from "ngx-image2dataurl";
import {PDFDocumentProxy, PdfViewerComponent,} from "ng2-pdf-viewer";
import {Customer} from "src/app/models/customer";
import {DatePipe} from "@angular/common";
import {Query} from "src/app/models/query";
import {Supplier} from "src/app/models/supplier";
import {TranslateService} from "@ngx-translate/core";
import {Currency} from "src/app/models/currency";
import {PurchaseService} from "src/app/purchase-journals/purchase.service";
import {OrderStatus} from "src/app/models/orders/orderstatus";
import {MenuService} from "src/app/shared/menu.service";
import { SendEmail } from "src/app/models/SendEmail";
import { User } from "src/app/models/user";

@Component({
  selector: "app-PurchaseOrders-list",
  templateUrl: "./PurchaseOrders-list.component.html",
  styleUrls: ["./PurchaseOrders-list.component.scss"],
  providers: [MessageService],
})
export class PurchaseOrdersListComponent
  extends BaseComponent
  implements OnInit {
  upQoute: boolean = true;
  LangStats = new OrderStatus()
  public purchase: PurchaseOrders[] = [];
  public showSidebar = false;
  qouteCheck: boolean = true;
  public size = SidebarSize.X_LARGE;
  sendEmaildialogList = false;
  sendEmail = new SendEmail()
  public modalTitle = "Add new quote";
  user_email: string
  header_body: string
  public removedialogueeQ = false;
  SeletedPurchaseOrders: PurchaseOrders[] = [];
  public fillterCustomers: Customer[] = [];
  public filteredProducts: PurchaseOrders[] = [];
  orderstatusTitle: string;
  public currentModal;
  langOpctions = [];
  langOrderOpctions = [];
  purchaseSelectedIndex = 0;
  removeInvoiceDisabled: boolean = true

  currentLang: any
  public quoteCheack: PurchaseOrders;
  public orderCheack: PurchaseOrders;
  public selectedPurchaseOrders = new PurchaseOrders();
  public selected: PurchaseOrders[] = [];
  purchaselectedIndex = 0
  itemSearch;
  loadingbtnemail: boolean = false
  inoivceViewer = false;
  keyword = "internal_reference";
  statuses: any[];
  delivered_status: any[];
  paid_status: any[];

  purchaseTypes: any[];
  valuestatus;
  invoice_links: any;
  loadingbtnPrint = false;
  loadingStatus: boolean = true;
  loadingbtnupload = false;
  convertStatus: boolean = false;
  pdfheight = 0;

  orderNextPage: any = 0
  orderPrevPage: any = 0
  ordersCount: any
  orderCurrentnumber: any
  orders_inPage = 0
  total_pages = 0
  pageViwed = []
  showDialogView: string
  currentChangedCurrancy: string;

  selectedCurrancy = new Currency()
  currencies: Currency[] = []
  currancyOptions: MenuItem[] = [];

  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith', label: this.translate.instant('START_WITH')
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL')
    },
    {
      type: 'Contains', label: this.translate.instant('CONTINES')
    },
    {
      type: 'EndsWith', label: this.translate.instant('END_WITH')
    },
  ];
  query_types2 = [
    {
      type: 'Not Equal', label: this.translate.instant('NOT_EQUAL'), value: 'ne'
    },
    {
      type: 'Equal', label: this.translate.instant('EQUAL'), value: 'exact'
    },
    {
      type: 'Less than', label: this.translate.instant('LESS_THAN'), value: 'lt'
    },
    {
      type: 'Grater than', label: this.translate.instant('GREATER_THAN'), value: 'gt'
    },
  ];
  seletedquery_type: string
  selected_Vendorquery = new Supplier()
  filterModeApply_Vendor: boolean = false
  filterModeFormat_query = new Query()
  filterModeDelivered_status = new Query()
  filterModePaid_status = new Query()
  filterModeInvoice_status = new Query()
  filterModeDate_query = new Query()
  filterModeTotal_query = new Query()
  filterModeType_query = new Query()
  refresh_selectedPurchaseOrders: boolean = false
  selectedQoute_Id: string
  firtlerInvoiced = 'Invoiced'
  fitlerOrder: Boolean = true
  fitlerQuote: Boolean = true

  @ViewChild("upload") upload: ElementRef;
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750,
    },
    allowedExtensions: ["JPG", "JPEG ", "PnG", "Pdf"],
  };
  @ViewChild("pdf") pdf_viewer: PdfViewerComponent;

  constructor(
    public purchaseOrdersService: PurchaseOrdersService,
    private router: Router,
    private messageService: MessageService,
    private sharedService: SharedService,
    public zone: NgZone,private menuService: MenuService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private purchaseService: PurchaseService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrencies()
    this.getPurchase();
    this.refreshSelectedPurchase();
    this.subscribeOntPurchaseRefresh();
    this.subscripOnConvertToPurchaseOrder();
    this.showOrder_salesJournal()
    this.subscripOnDuplicate()
    this.emailbtnLoading()
    this.langChanged();
    this.selectedCurrancy = JSON.parse(localStorage.getItem('currentCurrncy'))
    this.LangStats.langStatus=JSON.parse(localStorage.getItem('currentLang'))?.lang

    this.langOpctions = [
      {
        label: "EN",
        command: () => {
          this.purchaseOrdersService.qoutePrintLang = "en";
          this.printQuote_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
      {
        label: "FR",
        command: () => {
          this.purchaseOrdersService.qoutePrintLang = "fr";

          this.printQuote_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
      {
        label: "NL",
        command: () => {
          this.purchaseOrdersService.qoutePrintLang = "nl";

          this.printQuote_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
    ];
    this.langOrderOpctions = [
      {
        label: "EN",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "en";
          this.printOrder_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
      {
        label: "FR",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "fr";

          this.printOrder_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
      {
        label: "NL",
        command: () => {
          this.purchaseOrdersService.orderPrintLang = "nl";

          this.printOrder_list(
            this.selectedPurchaseOrders,
            this.purchaseSelectedIndex
          );
        },
      },
    ];

    this.purchaseTypes = [
      { type: 'Quote' },
      { type: 'Order' }
    ]
    this.statuses = [
      { status: { value: 'draft', label: this.translate.instant('DRAFT'), color: 'Draft' } },
      { status: { value: 'completed', label: this.translate.instant('COMPLETED'), color: 'Completed' } },
    ]
    this.delivered_status = [
      { status: { value: 'Delivered', label: this.translate.instant('DELIVERD'), color: 'delivered' } },
      { status: { value: 'p_delivered', label: this.translate.instant('P_DILVER'), color: 'partially_Delivered' } },
      { status: { value: 'Not Delivered', label: this.translate.instant('NOT_DELI'), color: 'not_delivered' } },

    ]
    this.paid_status = [
      { status: { value: 'paid', label: this.translate.instant('PAID'), color: 'Paid' } },
      { status: { value: 'unPaid', label: this.translate.instant('UNPAID'), color: 'UnPaid' } }

    ]
  }
  private subscribeOntPurchaseRefresh() {
    const subscription = this.purchaseOrdersService.refreshListEmitter.subscribe(
      (status: boolean) => {
        if (isSet(status) && status) {

          if (this.orderNextPage != 0) {

            this.refresh_NextPage()
          }
          if (this.orderNextPage == 0) {
            this.getPurchase();


          }

        }


      }
    );
    this.subscriptions.push(subscription);
  }

  getCurrencies() {
    this.currencies = []
    this.currancyOptions = []
    const subscription = this.sharedService.currnceyChangeEmitter.subscribe((currencies: Currency[]) => {

      if (!isSet(currencies)) {
        this.currencies = JSON.parse(localStorage.getItem('supported_currencies'))
      } else {
        localStorage.setItem('supported_currencies', JSON.stringify(this.currencies))

        this.currencies = Currency.cloneManyObjects(currencies)

      }
      this.currencies = this.currencies?.reverse();
      this.currencies?.map(c => {
        this.currancyOptions.push(
          {
            label: c.name, command: () => {
              if (this.selectedCurrancy?.id == c?.id) {
                return
              }
              this.selectedCurrancy = c

              this.purchaseOrdersService.currencyChanged.next(c)
            }
          }
        )
      })


    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
    });
    this.subscriptions.push(subscription);
  }
  refresh_NextPage() {
    this.loading = true
    const subscription = this.purchaseOrdersService.purchaseOrderNextPage(this.orderNextPage).subscribe((purchaseOrders: PaginatedResponse<PurchaseOrders[]>) => {
      if (!isSet(purchaseOrders)) {
        return;
      }



      const clone = PurchaseOrders.cloneManyObjects(purchaseOrders.results);
      clone.map(e => {
        e.customer = new Customer()
        e.customer.company_name = e.company_name
        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
        this.fillterCustomers.push(e.customer)

        this.filteredProducts.push(e)
      })
      const shown = this.orders_inPage

      this.orders_inPage = (this.orderNextPage - 1) * 30

      if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
        this.orders_inPage = this.orders_inPage
      }


      for (let index = 0; index < clone.length; index++) {

        this.purchase[this.orders_inPage + index] = clone[index];

      }

      this.pageViwed.push(this.orderNextPage)
      if (this.refresh_selectedPurchaseOrders == true) {
        this.selectedPurchaseOrders = PurchaseOrders.cloneObject(this.purchase[this.purchaselectedIndex])

        this.modalTitle = this.selectedPurchaseOrders.type == 'Quote' ? this.translate.instant('QUOTATION') + ` : ` : this.translate.instant('PUR_ORDER') + ` : `
        this.header_body = this.selectedPurchaseOrders.internal_reference
        if (this.selectedPurchaseOrders.type == 'Order') {

          this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

        }
      }
      this.loading = false;

      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });
  }
  serach(e) {
    if (!isSet(e)) {
      return;
    }
    this.purchase = e;
  }
  checkQoute() {
    this.modalTitle = this.translate.instant('RQ_FOR_QUOTION');
    this.orderstatusTitle = null
    this.header_body = null
    this.qouteCheck = false;
    this.showAddPurchaseOrdersQuote();
    this.purchaseOrdersService.checkQoute.next(true);
  }
  Update() {
    this.purchaseOrdersService.upadteFormClicked.next(true);

  }
  UpdateQuote() {
    this.purchaseOrdersService.upadteQuoteFormClicked.next(true);

  }

  showAddPurchaseOrders() {
    this.sharedService.Create_form(true);
    this.purchaseOrdersService.upadteFormClicked.next(null)
    this.purchaseOrdersService.upadteQuoteFormClicked.next(null)
    this.purchaseOrdersService.currentPurchaseOrders = null;
    this.modalTitle = this.translate.instant('NEW_PUR_ORDER');
    this.purchaseOrdersService.PurchasesSelected.next({ type: "Order" });
    this.selectedPurchaseOrders = null;
    this.selectedCurrancy = JSON.parse(localStorage.getItem('companySettings'))?.currency

    this.orderstatusTitle = null;
    this.header_body = null
    this.qouteCheck = true;
    this.showSidebar = true;

  }
  showAddPurchaseOrdersQuote() {
    this.purchaseOrdersService.checkQoute.next(null);
    this.purchaseOrdersService.upadteFormClicked.next(null)
    this.purchaseOrdersService.upadteQuoteFormClicked.next(null)
    this.sharedService.Create_form(true);
    this.purchaseOrdersService.currentPurchaseOrders = null;
    this.purchaseOrdersService.PurchasesSelected.next({
      type: "Quote",
    });
    this.orderstatusTitle = null;

    this.selectedPurchaseOrders = null;
    this.qouteCheck = null;
    this.upQoute = false;
    this.showSidebar = true;

  }
  viewInvoice(selectPurchase: PurchaseOrders, index: number) {
    this.selectedPurchaseOrders = selectPurchase;
    this.purchaseSelectedIndex = index;
    this.showDialogView = (this.selectedPurchaseOrders.invoice_attachment?.type)  === 'application/pdf' ? 'pdf' : 'invoice'
    this.inoivceViewer = true;
    this.removeInvoiceDisabled=true
    this.generate_download();
  }
  generate_download() {
    try {
      this.loading = true;

      this.purchaseOrdersService
        .generate_download(
          this.selectedPurchaseOrders?.invoice_attachment?.path
        )
        .subscribe(
          (link) => {

            (this.selectedPurchaseOrders.download_url = link?.download_url),
              (this.selectedPurchaseOrders.viewImg = link?.view_url),
              (this.loading = false);
          },
          (error) => {
            this.loading = false;
            this.handleError(error);
          }
        );
    } catch (error) {
      this.log(error);
    }
  }

  seletedPurchaseOrders(PurchaseOrders) {
    if (this.SeletedPurchaseOrders.includes(PurchaseOrders) === false) {
      this.SeletedPurchaseOrders.push(PurchaseOrders);
    } else {
      this.SeletedPurchaseOrders = this.SeletedPurchaseOrders.filter(
        (item) => item !== PurchaseOrders
      );
    }
  }

  removePurchaseOrders() {
    try {
      this.loading = true;
      let index = 0
      let length = this.selected.length

      this.selected.map(item => {
        const subscription = this.purchaseOrdersService.deletePurchaseOrders(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: item.internal_reference + ' Deleted ' });

          } else if (index == length) {
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant(' SEL_PUR_DEL ') });
          }
          this.purchaseOrdersService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

          subscription.unsubscribe();

        }, error => {

          if (isSet(item)) {
            this.messageService.add({ key: 'list', severity: 'error', life: 3500, summary: this.translate.instant('CANT_DELTE') + "(" + item?.internal_reference + ")", detail: error.error.detail });
          }
          this.loading = false;
          subscription.unsubscribe();
          this.purchaseOrdersService.refreshDataTable();
          this.sharedService.dialog_status.next(false)

        })

      })


      this.loading = false;
      this.selected = []




    }
    catch (error) {
      this.log(error);
    }
    this.removedialogueeQ = false;
    this.showSidebar = false
  }

  Reload() {
    this.purchaseOrdersService.queris = []
    this.purchaseOrdersService.sort = null
    this.purchaseOrdersService.PurchasesSelected.next(null)
    let currentRoute = this.router.url.split('?')[0];
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }
  saveFormQuote() {
    this.purchaseOrdersService.saveFormQuoteClicked.next(true);
    const subscription = this.purchaseOrdersService.retrieve_lastQuoteEmitter.subscribe(
      (purchaseOrders: PurchaseOrders) => {
        if (!isSet(purchaseOrders)) {
          return;
        }
        this.selectedPurchaseOrders = PurchaseOrders.cloneObject(purchaseOrders);
        this.selectedQoute_Id = this.selectedPurchaseOrders?.id

        this.header_body = `${this.selectedPurchaseOrders.internal_reference}`

        this.modalTitle = this.translate.instant('QUOTATION') + ' : '
        this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> `;

        this.selectedPurchaseOrders.type = "Quote";
        this.purchaseOrdersService.retrieve_lastOrder.next(null);

      },
      (error) => {
        console.log(error);

        this.handleError(error);
      }
    );

    this.subscriptions.push(subscription);
  }
  saveForm() {
    this.purchaseOrdersService.saveForm();
    const subscription = this.purchaseOrdersService.retrieve_lastOrderEmitter.subscribe(
      (purchaseOrders: PurchaseOrders) => {
        if (!isSet(purchaseOrders)) {
          return;
        }

        if (this.convertStatus == true) {
          setTimeout(() => {
            this.completeQuote(this.selectedQoute_Id);

          }, 300);

          this.selectedPurchaseOrders = PurchaseOrders.cloneObject(purchaseOrders);
          this.selectedPurchaseOrders.type = 'Order'
          this.header_body = `${this.selectedPurchaseOrders.internal_reference}`
          this.modalTitle = this.translate.instant('PUR_ORDER') + ' : '
          this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

          this.purchaseOrdersService.retrieve_lastOrder.next(null);



        } else if (this.convertStatus == false) {
          this.selectedPurchaseOrders = PurchaseOrders.cloneObject(purchaseOrders);

          this.header_body = `${this.selectedPurchaseOrders.internal_reference}`
          this.selectedPurchaseOrders.type = "Order";
          this.modalTitle = this.translate.instant('PUR_ORDER') + ' : '
          this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;


          this.purchaseOrdersService.retrieve_lastOrder.next(null);
        } else {
          this.convertStatus = false
        }
      },
      (error) => {
        this.handleError(error);
      }
    );

    this.subscriptions.push(subscription);
  }

  showPurchaseOrders(purchase: PurchaseOrders, index: number, type: string) {

    this.purchaseOrdersService.checkQoute.next(null);
    this.purchaseOrdersService.upadteFormClicked.next(null)
    if (type == "Quote") {
      this.qouteCheck = false
      this.purchaseOrdersService.checkQoute.next(true);
      this.selectedPurchaseOrders = PurchaseOrders.cloneObject(purchase)
      this.selectedQoute_Id = this.selectedPurchaseOrders?.id

      this.modalTitle = this.translate.instant('QUOTATION') + ' : '
      this.purchaseOrdersService.PurchasesSelected.next(this.selectedPurchaseOrders);
      this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> `;

    } else if (type == "Order") {
      this.qouteCheck = true
      this.selectedPurchaseOrders = PurchaseOrders.cloneObject(purchase);
      this.purchaseOrdersService.PurchasesSelected.next(this.selectedPurchaseOrders);
      this.modalTitle = this.translate.instant('PUR_ORDER') + ' : '

      this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

      this.removeInvoiceDisabled = false
    }
    this.selectedCurrancy = purchase.currency

    this.purchaselectedIndex = index;
    this.header_body = `${this.selectedPurchaseOrders.internal_reference}`
    this.showSidebar = true;

  }

  private getPurchase() {
    this.purchase = []
    this.loading = true
    const subscription = this.purchaseOrdersService
      .getPurchaseOrders()
      .subscribe(
        (purchaseOrders: PaginatedResponse<PurchaseOrders[]>) => {
          if (!isSet(purchaseOrders)) {
            return;
          }

          this.orderNextPage = 0
          this.pageViwed = []
          this.pageViwed.push(1)
          this.ordersCount = purchaseOrders.count
          this.total_pages = purchaseOrders.total_pages
          this.purchase = Array(this.ordersCount).fill(0)

          const clone = PurchaseOrders.cloneManyObjects(purchaseOrders.results);


          clone.map(e => {

            if (e.status.delivered == true) {
              e.delivered_status = { status: { value: 'Delivered', label: this.translate.instant('DELIVERD'), color: 'delivered' } }
            } else if (e.status.p_delivered == true && e.status.delivered == false) {
              e.delivered_status = { status: { value: 'p_delivered', label: this.translate.instant('P_DILVER'), color: 'partially_Delivered' } }

            } else if (e.status.delivered == false && e.status.delivered == false) {
              e.delivered_status = { status: { value: 'Not Delivered', label: this.translate.instant('NOT_DELI'), color: 'not_delivered' } }

            }

            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

            this.filteredProducts.push(e)
          })

          this.orderCurrentnumber = clone.length
          for (let index = 0; index < clone.length; index++) {
            this.purchase[index] = clone[index];

          }


          if (this.refresh_selectedPurchaseOrders == true) {
            this.selectedPurchaseOrders = PurchaseOrders.cloneObject(this.purchase[this.purchaselectedIndex])

            this.modalTitle = this.selectedPurchaseOrders?.type == 'Quote' ? this.translate.instant('QUOTATION') + ` : ` : this.translate.instant('PUR_ORDER') + ` : `
            this.header_body = this.selectedPurchaseOrders.internal_reference
            if (this.selectedPurchaseOrders?.type == 'Order') {


              this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

            }
          }
          this.loading = false

          subscription.unsubscribe();
        },
        (error) => {
          subscription.unsubscribe();
        }
      );
  }
  printQuote_list(p: PurchaseOrders, i: number) {
     this.purchaseSelectedIndex = i;
     this.selectedPurchaseOrders = new PurchaseOrders();
     this.selectedPurchaseOrders = p;
     this.printQuote_form(p.id);
  }
  printQuote_form(id:string) {
    this.loadingbtnPrint = true;
    this.purchase[this.purchaseSelectedIndex].loadprint = true;
    console.log(id);
    
    try {
      this.purchaseOrdersService.PrintQuote(id).subscribe(
        (Quote: any) => {
          if (!isSet(Quote)) {
            return;
          }
          this.showDialogView = 'pdf'
          this.selectedPurchaseOrders.download_url = Quote?.download_url;
          this.selectedPurchaseOrders.view_url = Quote?.view_url;
          this.getShortUrl(Quote?.download_url,Quote?.file_name)
          this.loadingbtnPrint = false;
          this.loadingStatus = true;
          this.inoivceViewer = true;


          this.purchaseOrdersService.qoutePrintLang = null;
          this.purchase[this.purchaseSelectedIndex].loadprint = false;
        },
        (error) => {
          this.messageService.add({
            key: "form",
            severity: "error",
            summary: "Error",
            detail: error.error.detail,
          });
          console.log(error);
          this.loadingbtnPrint = false;
          this.loadingStatus = false;
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  printOrder_list(p: PurchaseOrders, i: number) {
    this.purchaseSelectedIndex = i;
    this.selectedPurchaseOrders = new PurchaseOrders();
    this.selectedPurchaseOrders = p;

    this.printOrder_form(p.id);
  }
  printOrder_form(id: any) {
    this.inoivceViewer = true;
    this.loadingbtnPrint = true;
    this.purchase[this.purchaseSelectedIndex].loadprint = true;
    // this.loadprint=true
    try {
      const sub = this.purchaseOrdersService.PrintOrder(id).subscribe(
        (Order: any) => {
          if (!isSet(Order)) {
            return;
          }

          //  this.pdf_viewer.src = this.selectedPurchaseOrders.view_url
          this.showDialogView = 'pdf'
          this.selectedPurchaseOrders.download_url = Order?.download_url;
          this.selectedPurchaseOrders.view_url = Order?.view_url;
          this.getShortUrl(Order?.download_url,Order?.file_name)
          this.purchase[this.purchaseSelectedIndex].loadprint = false;

          this.inoivceViewer = true;
          this.loadingbtnPrint = false;
          this.loadingStatus = true;

          // this.purchaseOrdersService.orderPrintLang = null;

          sub.unsubscribe();
        },
        (error) => {
          this.messageService.add({
            key: "form",
            severity: "error",
            summary: "Error",
            detail: error.error.detail,
          });
          this.loadingbtnPrint = false;
          this.loadingStatus = false;
        }
      );
    } catch (error) {
      console.log(error);
      this.loadingbtnPrint = false;
    }
  }
  downloadPdf() {
    window.location.href = this.selectedPurchaseOrders.download_url;
  }
  removeInvoice_attachmet() {
    this.purchaseOrdersService.uploadInovice.next('delete');
    this.purchase[this.purchaseSelectedIndex].invoice_attachment = null;
    this.inoivceViewer = false
    this.loadingbtnupload = false
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status);
  }

  refreshSelectedPurchase() {
    const subscription = this.purchaseOrdersService.refreshselectedEmitter.subscribe(
      (status: any) => {
        if (!isSet(status) || !status) {
          return;
        }

        this.refresh_selectedPurchaseOrders = true


        this.purchaseOrdersService.refreshselected.next(null);
      },
      (error) => {
        this.handleError(error);
      }
    );
    this.subscriptions.push(subscription);
  }

  // uploadinvoice() {
  //   this.purchaseOrdersService.uploadInovice.next(true);
  // }

  print(type: string, id: string) {
    const printOrder = new PrintOrder();
    printOrder.type = type;
    this.purchaseOrdersService.onPrintOrder.next(printOrder);
  }
  convertPurQuote() {
    this.purchaseOrdersService.convertPurchaceClicked.next(true);
  }
  onDropdownClick_language(rowData: PurchaseOrders, i: number) {
    this.selectedPurchaseOrders = rowData;
    this.purchaseSelectedIndex = i;
  }

  showUploadInovice() {
    this.upload.nativeElement.click();
  }
  myUploader(imageResult: ImageResult) {
    this.loadingbtnupload = true;
    let _file =
      (imageResult.resized && imageResult.resized.dataURL) ||
      imageResult.dataURL;
    this.srcToFile(_file, imageResult.file.name, imageResult.file.type).then(
      (file) => {
        const generatUpload = {
          file_type: "user_report",
          file_path: file.name,
        };
        console.log(imageResult);
        
        this.purchaseOrdersService.generate_Upload(generatUpload);

        this.purchaseOrdersService.upload(file);

        try {
          this.purchaseOrdersService.uploadstuatsEmitter.subscribe(
            (status) => {
              if (!isSet(status) || !status) {
                return;
              }
              if (status === true) {
                this.selectedPurchaseOrders.invoice_attachment = {
                  name: file.name,
                  path: this.purchaseOrdersService.attachmentlink,
                  type: file.type,
                };
                this.loadingbtnupload = false;

                this.purchaseOrdersService.uploadInovice.next(
                  this.selectedPurchaseOrders?.invoice_attachment
                );

              } else if (status === false) {
                this.messageService.add({
                  key: "form",
                  severity: "error",
                  summary: this.translate.instant('ERROR'),
                  detail: this.translate.instant('NETWORK_ERROR'),
                });
              }
            },
            (error) => {
              this.loading = false;
              this.handleError(error);
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    );
  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020;
  }
  pdfLoader(event: { loaded: number; total: number }) {
    this.loading = event.loaded < event.total;
  }
  showViewInoivce() {
    console.log(this.selectedPurchaseOrders);
    
    this.showDialogView = (this.selectedPurchaseOrders.invoice_attachment?.type)  === 'application/pdf' ? 'pdf' : 'invoice'
    this.inoivceViewer = true;

    this.generate_download();
  }
  srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true;
    } else {
      return false;
    }
  }
  public sendByEmail() {
    this.purchaseOrdersService.sendEmailClicked.next(
      this.selectedPurchaseOrders.type
    );
    this.inoivceViewer = false;
  }
  sendByEmailList(){
    this.sendEmaildialogList = true;
    this.inoivceViewer = false;
  }
  duplicateForm() {
    if (this.selectedPurchaseOrders.type == "Order") {
      this.sharedService.Create_form(true);
      const format = this.selectedPurchaseOrders?.internal_reference;
      this.selectedPurchaseOrders = null;
      this.modalTitle = this.translate.instant('DUP_OUR_ORDER') + ' : ';
      this.orderstatusTitle = null;
      this.header_body = ` ${format}`
      this.showSidebar = true;
      this.qouteCheck = true;
      this.purchaseOrdersService.duplicateClicked.next("Order");
    } else if (this.selectedPurchaseOrders.type == "Quote") {
      this.sharedService.Create_form(true);
      const format = this.selectedPurchaseOrders?.internal_reference;
      this.selectedPurchaseOrders = null;
      this.modalTitle = this.translate.instant('DUP_OUR_ORDER') + ' : ';
      this.orderstatusTitle = null;
      this.header_body = ` ${format}`
      this.showSidebar = true;
      this.qouteCheck = null;
      this.purchaseOrdersService.duplicateClicked.next("Quote");
    }
  }
  subscripOnConvertToPurchaseOrder() {
    const subscription = this.purchaseOrdersService.convertOrdertEmitter.subscribe(
      (status) => {
        if (!isSet(status)) {

          return;
        }
        this.qouteCheck = true;
        this.selectedPurchaseOrders = null;
        this.orderstatusTitle = null;
        this.modalTitle = this.translate.instant('CONVERT_PUR') + ":  ";
        this.convertStatus = true;
        this.purchaseSelectedIndex = 1
        this.purchaseOrdersService.convertOrder.next(null)
      },
      (error) => {
        console.log(error);
      }
    );
    this.subscriptions.push(subscription);
  }
  completeQuote(id) {
    const subscription = this.purchaseOrdersService
      .convertToPurchaseOrder(id)
      .subscribe(
        () => {

          this.purchaseOrdersService.refreshList.next(true);

          subscription.unsubscribe();
        },
        (error) => {
          console.log(error);
          subscription.unsubscribe();
        }
      );
  }
  getNextorderPage(event) {

    let row_status = false
    if (event.rows == 10) {
      this.orders_inPage = event.first
    } else {
      this.orders_inPage = event.rows + event.first
      row_status = true
    }

    if (this.pageViwed == this.ordersCount) {
      return
    }

    const num = (this.orders_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.orderNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.orderNextPage = num + 1
      }
      if (this.orderNextPage > this.total_pages) {
        this.orderNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.orderNextPage = this.orders_inPage / 30

    }


    if (this.orders_inPage >= this.orderCurrentnumber) {

      if (this.orderNextPage == this.orderPrevPage) {
        this.orderNextPage = this.orderNextPage - 1
        this.orderPrevPage = this.orderNextPage
      }
      this.orderPrevPage = this.orderNextPage

      if (this.purchaseOrdersService.queris.length && this.orderNextPage == 1) {
        this.orderNextPage = 2
      }


      if (this.pageViwed.includes(this.orderNextPage)) {
        return
      } else {

        this.loading = true

        const subscription = this.purchaseOrdersService.purchaseOrderNextPage(this.orderNextPage).subscribe((purchaseOrders: PaginatedResponse<PurchaseOrders[]>) => {
          if (!isSet(purchaseOrders)) {
            return;
          }



          const clone = PurchaseOrders.cloneManyObjects(purchaseOrders.results);
          clone.map(e => {
            if (e.status.delivered == true) {
              e.delivered_status = { status: { value: 'Delivered', label: 'Delivered', color: 'delivered' } }
            } else if (e.status.p_delivered == true && e.status.delivered == false) {
              e.delivered_status = { status: { value: 'p_delivered', label: 'Partially Delivered', color: 'partially_Delivered' } }

            } else if (e.status.delivered == false && e.status.delivered == false) {
              e.delivered_status = { status: { value: 'Not Delivered', label: 'Not Delivered', color: 'not_delivered' } }

            }
            e.customer = new Customer()
            e.customer.company_name = e.company_name
            let datefillter = new Date(e.date)

            e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())
            this.fillterCustomers.push(e.customer)

            this.filteredProducts.push(e)
          })
          const shown = this.orders_inPage

          this.orders_inPage = (this.orderNextPage - 1) * 30

          if (this.orders_inPage > shown && this.orderNextPage == this.orderPrevPage) {
            this.orders_inPage = this.orders_inPage
          }


          for (let index = 0; index < clone.length; index++) {

            this.purchase[this.orders_inPage + index] = clone[index];

          }

          this.pageViwed.push(this.orderNextPage)
          if (this.refresh_selectedPurchaseOrders == true) {
            this.selectedPurchaseOrders = PurchaseOrders.cloneObject(this.purchase[this.purchaselectedIndex])

            this.modalTitle = this.selectedPurchaseOrders?.type == 'Quote' ? this.translate.instant('QUOTATION') + ` : ` : this.translate.instant('PUR_ORDER') + ` : `
            this.header_body = this.selectedPurchaseOrders.internal_reference
            this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

          }
          this.loading = false;

          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }

    }
  }
  deleteseleted(selectedPurchaseOrders) {
    this.selected = []
    this.selected.push(selectedPurchaseOrders)
    this.removedialogueeQ = true
  }
  clearOrders_queries() {
    this.purchaseOrdersService.queris = []
    this.seletedquery_type = null
    this.filterModeFormat_query = new Query()
    this.filterModeTotal_query = new Query()
    this.filterModeDate_query = new Query()
    this.filterModeType_query = new Query()
    this.filterModePaid_status = new Query()
    this.filterModeInvoice_status = new Query()
    this.filterModeDelivered_status = new Query()
    this.selected_Vendorquery = new Supplier()
    this.fitlerOrder = true
    this.fitlerQuote = true

    this.getPurchase()
  }
  getOrders_queries(query_name, query_type, query_value) {
    this.loading = true;

    if (query_type == 'draft') {
      query_type = 'completed',
        query_value = false

    }

    if (query_type == 'Not Delivered') {
      query_type = 'p_delivered',
        query_value = false

    }
    if (query_name == 'total') {
      this.purchaseOrdersService.queris.push({
        query_name: 'type',
        query_type: 'exact',
        query_value: 'Order'
      })
    }
    if (query_name == 'date') {
      query_value = this.datePipe.transform(query_value, 'yyyy-MM-dd')

    }

    if (query_type == 'Equal') {
      query_type = 'exact'
    }

    let re = false
    this.purchaseOrdersService.queris.map((query) => {
      if (query.query_name == query_name && query.query_name != 'status') {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.purchaseOrdersService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }
    this.purchaseOrdersService.queris.map((item, index) => {

      if (item.query_type === 'p_delivered') {
        this.purchaseOrdersService.queris.push({
          query_name: 'status',
          query_type: 'delivered',
          query_value: false
        })
      }
      if (item.query_type === 'completed') {
        this.fitlerOrder = false
        this.filterModeType_query.value = 'Quote'
        this.purchaseOrdersService.queris.push(
          {
            query_name: 'type',
            query_type: 'exact',
            query_value: this.filterModeType_query.value
          },
        )
      }
      if (query_value == undefined) {
        this.purchaseOrdersService.queris.splice(index + 1, 1)

      }
    })
    this.filterModeType_query = new Query()


    this.ordersCount = 0
    const subscription = this.purchaseOrdersService.getPurchaseOrders().subscribe((purchaseOrders: PaginatedResponse<PurchaseOrders[]>) => {
      if (!isSet(purchaseOrders)) {
        return;
      }

      this.orderNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.ordersCount = purchaseOrders.count
      this.total_pages = purchaseOrders.total_pages
      this.purchase = Array(this.ordersCount).fill(0)


      const clone = PurchaseOrders.cloneManyObjects(purchaseOrders.results);
      clone.map(e => {
        if (e.status.delivered == true) {
          e.delivered_status = { status: { value: 'Delivered', label: 'Delivered', color: 'delivered' } }
        } else if (e.status.p_delivered == true && e.status.delivered == false) {
          e.delivered_status = { status: { value: 'p_delivered', label: 'Partially Delivered', color: 'partially_Delivered' } }

        } else if (e.status.delivered == false && e.status.delivered == false) {
          e.delivered_status = { status: { value: 'Not Delivered', label: 'Not Delivered', color: 'not_delivered' } }

        }
        let datefillter = new Date(e.date)

        e.date = new Date(datefillter.getFullYear(), datefillter.getMonth(), datefillter.getUTCDate())

        this.filteredProducts.push(e)
      })

      this.orderCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.purchase[index] = clone[index];

      }

      if (this.refresh_selectedPurchaseOrders == true) {
        this.selectedPurchaseOrders = PurchaseOrders.cloneObject(this.purchase[this.purchaselectedIndex])

        this.modalTitle = this.selectedPurchaseOrders.type == 'Quote' ? this.translate.instant('QUOTATION') + ` : ` : this.translate.instant('PUR_ORDER') + ` : `
        this.header_body = this.selectedPurchaseOrders.internal_reference
        this.orderstatusTitle = `<span  style="margin-right: 5px; " Class="${this.selectedPurchaseOrders.status.status?.color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.label)}</span> /
          <span  style="margin-right: 5px;" Class="${this.selectedPurchaseOrders.status.status?.paid_color}">${this.translate.instant(this.selectedPurchaseOrders.status.status?.paid_status)}</span>`;

      }

      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  paid_Status_queries() {
    this.filterModeType_query.value = 'Order'
    this.fitlerQuote = false

    this.purchaseOrdersService.queris.push(
      {
        query_name: 'status',
        query_type: 'paid',
        query_value: this.filterModePaid_status?.value == 'paid'
      },
      {
        query_name: 'type',
        query_type: 'exact',
        query_value: this.filterModeType_query.value
      }

    )
  }
  onChange_Type_queries(type) {
    if (this.fitlerOrder == true && this.fitlerQuote == true) {
      this.filterModeType_query = new Query()
      this.purchaseOrdersService.queris.map((query, index) => {
        if (query.query_name == 'type') {
          this.purchaseOrdersService.queris.splice(index + 1, 1)

        }
      })
      this.clearOrders_queries()
    } else {
      this.filterModeType_query.value = type

      this.purchaseOrdersService.queris.map((query) => {
        if (query.query_name == 'type') {
          query.query_type = this.filterModeType_query.value
        }
      })
      this.purchaseOrdersService.queris.push(
        {
          query_name: 'type',
          query_type: 'exact',
          query_value: this.filterModeType_query.value
        },
      )
    }
  }
  completeMethod(serach_item) {
    this.purchaseOrdersService.queris = []
    this.getOrders_queries('internal_reference', 'startswith', serach_item)
  }

  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.purchaseOrdersService.sort = filed
      return this.getPurchase()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.purchaseOrdersService.sort = '-' + filed
      return this.getPurchase()
    }







  }
  showOrder_salesJournal() {
    const subscription = this.purchaseService.purchaseOrdersEmitter.subscribe((purchase: PurchaseOrders) => {
      if (!isSet(purchase)) {
        return
      }
      purchase.type = 'Order'
      this.showPurchaseOrders(purchase, null, purchase.type)

      this.purchaseService.showpurchaseOrders.next(null)
    }, error => {
      this.loading = false

      this.handleError(error);
    });
    this.subscriptions.push(subscription);



  }

  subscripOnDuplicate() {
    const subscription = this.purchaseOrdersService.duplicateClickedEmitter.subscribe((type) => {
      if (!isSet(type)) {
        return;
      }

      setTimeout(() => {
        this.duplicateForm()

      }, 1000);

    }, error => {
      console.log(error);
    })
    this.subscriptions.push(subscription)
  }

  emailbtnLoading() {
    const subscription = this.purchaseOrdersService.btnLoadingEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (status == false) {
        return this.loadingbtnemail = false

      }
      if (status == true) {
        return this.loadingbtnemail = true

      }
      this.purchaseOrdersService.btnLoading.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  langChanged() {
    const subscription = this.menuService.langStatusEmitter.subscribe(status => {
      if (!isSet(status)) {
        return
      }
      this.LangStats.langStatus=status
      this.getPurchase()
      this.menuService.langStatus.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }

  sendToWhatsapp(id?){
    if (!this.selectedPurchaseOrders.whatsappUrl){
      this.loading=true
     const subscription = this.purchaseOrdersService.PrintOrder(id).subscribe((order: any) => {
        this.loading=false
        if (!isSet(order)) {
          return;
        }
        this.selectedPurchaseOrders.download_url = order.download_url
        this.getShortUrl(order.download_url,order.file_name).then(async () => {
        window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedPurchaseOrders.whatsappUrl}`, "_blank");

        })
        this.outside_Status(false)
        subscription.unsubscribe();
      }, error => {
        subscription.unsubscribe();
        this.loading=false
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
      });
    }else{
      window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.selectedPurchaseOrders.whatsappUrl}`, "_blank")
    }

  }
  getShortUrl(downloadUrl,fileName){
    return new Promise((resolve) => {
      const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
        if (!isSet(url)) {
          return
        }
        resolve(this.selectedPurchaseOrders.whatsappUrl=url?.id)
        subscription.unsubscribe()
      },error=>{
        console.log(error)
        subscription.unsubscribe()
      })
      });
  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    } 
  }
  onRemovemail() {
    this.sendEmail.send_to_mails.find(email => {
      if (email !== this.user_email) {
        this.user_email = null
      }
    })
  }
  Save_Message() {
    localStorage.setItem('defaultMessage', this.sendEmail.message)
  }
  send_copyto_myself() {
    const user: User = JSON.parse(localStorage.getItem('user'))
    const email = this.sendEmail.send_to_mails
    if (!isSet(this.sendEmail.send_to_mails)) {
      this.sendEmail.send_to_mails = []
      this.sendEmail.send_to_mails.push(user.email)
    }
    if (this.sendEmail.send_to_mails?.includes(user.email) === false && this.user_email != null) {
      email.push(user.email)
      this.sendEmail.send_to_mails = []
      this.user_email = user.email

      email.map(em => {
        this.sendEmail.send_to_mails.push(em)
      })
    } else {
      this.sendEmail.send_to_mails = this.sendEmail.send_to_mails?.filter(item => item !== user.email)
      this.user_email = null
    }
  }
  addEmail_onSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  send_Email() {
    this.purchaseOrdersService.btnLoading.next(true)
    this.sendEmaildialogList = false

  
      const subscription = this.purchaseOrdersService.SendEmailOrders(this.sendEmail, this.selectedPurchaseOrders.id).subscribe(
        (sendEmail: SendEmail) => {
          if (!isSet(sendEmail)) {
            return;
          }
          this.purchaseOrdersService.btnLoading.next(false)

          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('SENT_EMAILS')
          });
          subscription.unsubscribe();
          return
        }, error => {
          this.purchaseOrdersService.btnLoading.next(false)
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          subscription.unsubscribe();
        })
     


  }
}
