import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { ProductService } from '../../products/product.service';
import { Quote } from '../../../models/quotes/quote';
import { Unit } from '../../../models/unit';
import { OrderItem } from '../../../models/orders/orderitem';
import { Product } from '../../../models/product';
import { Order } from '../../../models/orders/order';
import { isSet } from '../../core/util';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { QuotesService } from '../quotes.service';
import { SendEmail } from 'src/app/models/SendEmail';
import { User } from '../../../models/user';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Vat_Product } from 'src/app/models/vat-product';
import { TranslateService } from '@ngx-translate/core';
import  {InputNumber } from 'primeng/inputnumber';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import * as printJS from 'print-js';
import { Customer } from 'src/app/models/customer';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { Currency } from 'src/app/models/currency';
import * as _ from 'lodash';
import { OrderStatus } from 'src/app/models/orders/orderstatus';
import {Calendar} from "primeng/calendar";

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
  providers: [MessageService]

})
export class QuoteFormComponent extends BaseComponent implements OnInit, AfterViewInit {

  quote: Quote;
  units: Unit[] = [];
  productTouched: boolean = false
  customerOrder: boolean = false
  products: Product[] = [];
  ConvertButton = false
  sendEmaildialog: boolean = false
  sendEmail = new SendEmail()
  SubjectSize
  SaveMessageState: boolean
  sendEmailButton: boolean
  ToOrder = new Order()
  ConvertialogueeQ = false
  user_email: string
  statuses: any[];
  selected_Quote: boolean
  update_status: boolean = false
  dispalymessageService = true
  pdfViewer = false
  cardHeight = 0
  public vat_Percentage: Vat_Product[] = [];
  inlineProductValue: string
  indexinlineProduct: number = 0
  showaddinlineProduct: boolean
  productSize = 0
  pdfheight = 0
  customer_due = 0
  show_afterdiscont: boolean = true
  selected_discount_type = { type: null }
  defaultcurrency_status: boolean = false

  discount_type = [
    { type: 'Percent' },
    { type: 'Amount' }

  ]
  default_currency = new Currency()
  showAdd_exchange_rate: boolean = false
  add_exchange_rate: number = 1
  confrim_exchange_rate: boolean = false
  @Output() public sidebar = new EventEmitter();

  @ViewChildren('item.qty') qtyS: QueryList<ElementRef>;
  @ViewChildren('item.price') priceS: QueryList<InputNumber>;
  @ViewChild('total_Height') total_Height: ElementRef;
  @ViewChild('productelemt') productelemt: ElementRef;
  @ViewChild('quoteDueDateToggle') quoteDueDateToggle: Calendar;
  @ViewChild('quoteDateToggle') quoteDateToggle: Calendar;


  constructor(private quotesService: QuotesService,
    private router: Router, private sharedService: SharedService, private productService: ProductService,
    private messageService: MessageService, public primengConfig: PrimeNGConfig,
    private datePipe: DatePipe, private cdr: ChangeDetectorRef,
    public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.getVats()
    this.getNextOrderFormat();
    this.subscribeOnFormSaved();
    this.subscribeOnConvert()
    this.subscribeOnSendEmail()
    this.initQuote();
    this.Save_form()
    this.updateEmitter()
    this.printOrderViewer()
    this.getSavedqoute()
    this.subscribeOnOrderSelected()
    this.showQoute_formDashboered()
    this.clearAll()
    this.subscribeOnduplicatOrder()
    this.currencyChanged()


    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
        this.addItem();
      }
    });
    if (!isSet(localStorage.getItem("defaultMessage"))) {
      this.sendEmail.message = ""
    } else {
      this.sendEmail.message = localStorage.getItem("defaultMessage")
    }


  }

  ngAfterViewInit() {
    this.loading = true

    const x = setInterval(() => {

      const qtyS: ElementRef[] = this.qtyS.toArray()


      for (let index = 0; index < this.quote?.items?.length; index++) {
        qtyS[index].nativeElement.value = this.spaceNumber(this.quote?.items[index]?.qty)
        if (index == this.quote?.items?.length - 1) {
          clearInterval(x);

        }
      }
      this.loading = false


    }, 1000);
    this.cardHeight = this.total_Height.nativeElement.offsetHeight

    this.productSize = this.productelemt.nativeElement.offsetWidth - 40

    this.cdr.detectChanges();

  }

  customerChange(customer: Customer) {
    this.quote.customer = Customer.cloneObject(customer)

    this.update_status = true
  }
  addItem() {
    const item = OrderItem.cloneObject(null);
    item.datePipe = new Date();
    item.product = new Product()
    item.product.id = 'new'
    item.qty = 1;
    item.price = 0;
    item.vat = new Vat_Product()
    item.vat.value = 0;
    item.exchange_rate = 1
    item.checkinlne = false

    this.quote.items.push(item);
    this.update_status = true

  }
  serviceItem(event, index) {
    const service = event

    this.productTouched = false
    this.update_status = true
    this.quote.items[index].checkinlne = true

    this.quote.items[index].product = new Product()
    this.quote.items[index].product.id = ''
    this.quote.items[index].service = service
    this.quote.items[index].name = null
    this.quote.items[index].itemVaild = true
    this.exchangeRateChange()

  }
  remomveItem(index: number) {
    this.update_status = true
    this.quote.items.splice(index, 1)
  }


  async productChanged(event, index) {
    await this.selectProduct(event, index)

    await this.discont_Percentage()
    this.exchangeRateChange()
    await this.getVats_before_afterDiscont()


  }

  selectProduct(event, index) {
    return new Promise((resolve) => {
      this.quote.paid_vats = []
      setTimeout(() => {
        const product = event as Product;


        this.quote.items[index].price = product?.sell_price || 0;
        if (isSet(product?.vat?.id)) {
          this.quote.items[index].vat = product?.vat
        }
        this.quote.items[index].product = new Product()
        this.quote.items[index].datePipe = new Date()
        this.quote.items[index].product = product
        this.quote.items[index].name = product?.name
        this.quote.items[index].itemVaild = true
        this.productTouched = false
        this.update_status = true

        this.onChange_Item(index, null)
        resolve(this.quote.items[index])

      }, 200);


    })
  }

  onChange_Item(index: number, filed: string) {
    const qtyS: ElementRef[] = this.qtyS.toArray()
    this.quotesService.updateOrderItem = true
    this.quotesService.itemIndex = index

    if (filed == 'qty') {
      qtyS[index].nativeElement.value = this.spaceNumber(this.quote.items[index].qty)
      this.quote.items[index].qty = this.quote.items[index].qty.replace(/\s/g, '')



    }

  }
  private subscribeOnFormSaved() {
    const subscription = this.quotesService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addQuote();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  private subscribeOnConvert() {
    const subscription = this.quotesService.ConvertClickedClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.ConvertialogueeQ = true
      this.quotesService.Converttoroeder_buuton(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  private subscribeOnSendEmail() {
    const subscription = this.quotesService.sendEmailClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }

      this.sendEmaildialog = true
      this.quotesService.sendEmail_buuton(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  private initQuote() {
    this.quote = new Quote()
    this.quote.date = new Date();
    this.quote.expiry_date = new Date((new Date()).getTime() + (15 * 86400000));
    this.quote.items = [];
    this.quote.discount = 0
    this.quote.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency

    this.quote.exchange_rate = 1

    this.addItem();
  }
  private subscribeOnOrderSelected() {
    const subscription = this.quotesService.QuoteSelectedListEmitter.subscribe((quote: Quote) => {
      if (!isSet(quote)) {
        return;
      }
      this.ConvertButton = true
      this.sendEmailButton = true
      this.selected_Quote = true

      this.getQuote(quote.id);
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
    this.quotesService.selectQuote(null)

  }


  addQuote(): void {
    this.removeSpaceNumber()
    let checkerr: Boolean

    if (isSet(this.quote?.items)) {
      this.quote?.items.map(item => {
        item.price = Number(item.price)
        item.qty = Number(item.qty)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')

        if (isSet(item.vat.value)) {

          item.vat.value = Number(item.vat.value)
        } else {
          item.vat.value = 0
        }
        if ((!isSet(this.quote.customer)) || (!isSet(this.quote.format))
          || (!isSet(this.quote.date)) || !item.itemVaild) {
          this.productTouched = true
          this.customerOrder = true

          return checkerr = false
        } else {
          return checkerr = true

        }
      })
    }

    if (checkerr == true) {

      try {
        this.loading = true;
        const subscription = this.quotesService.addQuote(this.quote).subscribe((quote: Quote) => {

          if (!isSet(quote)) {
            return;
          }
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'New quote added successfully' });
          this.loading = false;
          this.quote.id = quote.id
          this.quote.status = quote.status

          this.quote?.items?.map(item => {
            if (isSet(item.product?.id)) {
              item.checkinlne = false
            }
            if (isSet(item?.name) && !isSet(item.product?.id)) {
              item.checkinlne = true
              item.product = new Product()
              item.product.name = item?.name
            }
            if (isSet(item.service)) {
              item.checkinlne = true
              item.service = item.service
            }
            if (item.vat?.value==0) {
              item.vat.value='0'
            }
          })


          this.selected_Quote = true
          this.update_status = true
          this.quote.customer.contact.forEach(customer => {
            SendEmail.cloneObject(this.sendEmail)
            this.sendEmail.send_to_mails = []
            this.sendEmail.send_to_mails.push(customer.email)

          })
          subscription.unsubscribe();
          this.quotesService.retrieve_lastOrder.next(this.quote)
          this.quotesService.refreshDataTable()
          this.quotesService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)

        }, error => {

          if (error.error.detail == 'the Quote number must be unique, please try changing it.') {
            const subscription = this.quotesService.getNextOrderNumber().subscribe((data: { format: string }) => {
              if (!isSet(data)) {
                return;
              }
              this.quote.format = data.format;
              this.addQuote()


              subscription.unsubscribe();
            }, error => {
              subscription.unsubscribe();
            });

          } else {
            this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          }
          this.loading = false;
          subscription.unsubscribe();

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }



  }

  ConvertToOrder() {
    if (!isSet(this.quote)) {
      return;
    }
    this.quotesService.currentOrder = this.quote
    this.ConvertialogueeQ = false
    this.sidebar.emit(false)

  }


  private getNextOrderFormat() {
    const subscription = this.quotesService.getNextOrderNumber().subscribe((data: { format: string }) => {
      if (!isSet(data)) {
        return;
      }
      if (!isSet(this.quote.format)) {
        this.quote.format = data.format;
      }

      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }

  inlineprodcut(event, index) {
    const inlineProduct = event

    this.productTouched = false
    this.update_status = true

    this.quote.items[index].product = new Product()
    this.quote.items[index].product.id = ''
    this.quote.items[index].product.name = inlineProduct
    this.quote.items[index].name = inlineProduct
    this.quote.items[index].itemVaild = true
    this.exchangeRateChange()

  }
  private getVats() {
    const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(vats)) {
        return;
      }
      this.vat_Percentage = Vat_Product.cloneManyObjects(vats.results);
      let zeroVat_index = 0
      this.vat_Percentage.map((vat, index) => {
        if (vat.value == 0) {
          vat.value = '0'
          zeroVat_index = index
        }
      })
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.vat_Percentage = insert(this.vat_Percentage, 0, this.vat_Percentage[zeroVat_index])

      this.vat_Percentage.splice(zeroVat_index + 1, 1)
      this.vat_Percentage.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      subscription.unsubscribe();


    }, error => {

      console.log(error);
      subscription.unsubscribe();


    })

  }
  private getQuote(id: string) {
    this.loading = true


    const subscription = this.quotesService.getQuote(id).subscribe((quote: Quote) => {

      const cloneObject = Quote.cloneObject(quote);

      this.quote = cloneObject;
      this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency

      if (this.quote.currency?.id != this.default_currency?.id) {
        this.quote.exchange_rateStauts = true

      }
      this.quote.date = new Date(quote?.date)
      this.quote.expiry_date = new Date(quote.expiry_date)
      if (this.quote.discount[this.quote.discount.length - 1] == "%") {
        this.quote.discount = this.quote.discount.substring(0, this.quote.discount.length - 1)

        this.selected_discount_type.type = 'Percent'
        this.quote.discount_type = 'Percent'
      } else {
        this.selected_discount_type.type = 'Amount'
        this.quote.discount_type = 'Amount'

      }

      this.quote.customer.contact.forEach(customer => {
        SendEmail.cloneObject(this.sendEmail)
        this.sendEmail.send_to_mails = []
        this.sendEmail.send_to_mails.push(customer.email)

      })
      const disconnt = this.quote.discont_Percentage
      if (this.quote?.items?.length) {

        this.quote?.items?.map(item => {
          item.discont_Percentage = disconnt
          item.exchange_rate = this.quote?.exchange_rate

          item.itemVaild = true
          item.datePipe = new Date(item?.date);
          if (isSet(item.product?.id)) {
            item.checkinlne = false
          }
          if (isSet(item?.name) && !isSet(item.product?.id)) {
            item.checkinlne = true
            item.product = new Product()
            item.product.name = item?.name
          }
          if (isSet(item.service)) {
            item.checkinlne = true
          }
        })
        if (this.quote.discount_type == 'Amount') {
          const restdiscount = this.quote.discount
          this.cleardiscont()
          this.quote.discount = restdiscount
          this.discont_Percentage()
        }
        this.getVats_before_afterDiscont()

      }
      subscription.unsubscribe();
      this.loading = false

    }, error => {
      subscription.unsubscribe();
    });
  }
  Save_Message() {


    localStorage.setItem("defaultMessage", this.sendEmail.message)


  }
  send_Email(): void {


    try {
      this.quotesService.btnLoading.next(true)
      const subscription = this.quotesService.SendEmail(this.sendEmail, this.quote.id).subscribe((sendEmail: SendEmail) => {

        if (!isSet(sendEmail)) {
          return;
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SENT_EMAILS') });
        this.quotesService.btnLoading.next(false)

        subscription.unsubscribe();

      }, error => {

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error });
        this.quotesService.btnLoading.next(false)

        subscription.unsubscribe();

      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }
    this.sendEmaildialog = false
  }

  send_copyto_myself() {
    const user: User = JSON.parse(localStorage.getItem("user"))
    const email = this.sendEmail.send_to_mails
    if (this.sendEmail.send_to_mails.includes(user.email) == false && this.user_email != null) {
      email.push(user.email)
      this.sendEmail.send_to_mails = []
      this.user_email = user.email

      email.map(em => {
        this.sendEmail.send_to_mails.push(em)
      })
    } else {

      this.sendEmail.send_to_mails = this.sendEmail.send_to_mails.filter(item => item !== user.email)
      this.user_email = null

    }

  }
  onRemovemail() {
    this.sendEmail.send_to_mails.find(email => {
      if (email != this.user_email) {
        this.user_email = null
      }
    })
  }
  addEmail_onSpace(event: KeyboardEvent) {
    if (event.key === " ") {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.sharedService.Save_object(this.quote)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }

  updateEmitter() {
    try {
      const subscription = this.quotesService.upadteFormClickedEmitter.subscribe(status => {

        if (!isSet(status) || !status) {
          return;
        }
        this.updateQoute()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);


    } catch (error) {
      this.log(error);
    }

  }


  updateQoute() {
    let noerror: boolean
    if (isSet(this.quote?.items)) {
      if (isSet(this.quote?.items)) {
        this.quote?.items.map(item => {
          if (this.quote.status?.invoiced === true) {

            if ((!isSet(this.quote.customer)) || (!isSet(this.quote.format))
              || (!isSet(this.quote.date)) || !item.itemVaild
            ) {
              this.productTouched = true
              this.customerOrder = true
              return noerror = false
            } else {
              return noerror = true

            }

          } else {


            if ((!isSet(this.quote.customer)) || (!isSet(this.quote.format))
              || (!isSet(this.quote.date)) || !item.itemVaild) {
              this.productTouched = true
              this.customerOrder = true

              return noerror = false
            } else {
              return noerror = true

            }
          }

        })
      }
    }

    if (noerror == false) {

      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      if (this.update_status == false) {
        this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

      } else {
        try {
          this.loading = true;
          const subscription = this.quotesService.upadeQoute(this.quote).subscribe((qoute: Quote) => {

            if (!isSet(qoute)) {
              return;
            }
            this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
            this.loading = false;


            subscription.unsubscribe();
            this.quotesService.refreshDataTable()
            this.quotesService.refreshselected.next(true)
            this.quotesService.upadteFormClicked.next(null)
            this.update_status = false;

          }, error => {
            this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });

            this.loading = false;
            subscription.unsubscribe();
            this.quotesService.upadteFormClicked.next(null)

          })
        } catch (error) {
          this.loading = false;
          this.log(error);

        }
      }
    }

  }
  getSavedqoute() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((quote: any) => {
      if (!isSet(quote)) {
        this.sharedService.objectstauts.next(null)

        return;
      }
      if (quote.url == this.router.url) {

        this.quote = quote.object
        this.sharedService.objectstauts.next(true)

      } else {
        this.sharedService.objectstauts.next(null)

      }
    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }

      this.initQuote()
      this.sharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }
  async notempty(event) {

    this.update_status = true
    this.sharedService.update_form.next(true)
    await this.getVats_before_afterDiscont()

    setTimeout(() => {
      if (this.quote.discount > 0) {
        const x = this.quote.discount

        this.quote.discount = 0

        let disconnt = this.quote.discont_Percentage
        this.quote.items.map(item => {
          item.discont_Percentage = disconnt

        })
        this.quote.discount = x
        disconnt = this.quote.discont_Percentage
        this.quote.items.map(item => {
          item.discont_Percentage = disconnt

        })
      }


    }, 300);


  }
  getVats_before_afterDiscont() {
    return new Promise((resolve) => {
      this.quote.paid_vats = []
      setTimeout(() => {
        this.vat_Percentage.map(vat => {
          let beforediscont = 0
          let afterdiscont = 0

          beforediscont = _.sumBy(this.quote?.items, (item) => {
            if (item?.vat?.id == vat.id) {
              return item?.beforediscont_vatAmount
            } else {
              return 0
            }

          })
          afterdiscont = _.sumBy(this.quote?.items, (item) => {
            if (item?.vat?.id == vat?.id) {
              return item?.vatAmount
            } else {
              return 0
            }

          })
          if (beforediscont > 0) {

            let v = {
              name: vat?.value,
              beforediscont: beforediscont,
              afterdiscont: afterdiscont

            }

            this.quote.paid_vats.push(v)
            resolve(this.quote.paid_vats.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

          }

        })
      }, 200);


    })
  }
  erace_Default(index) {
    if (this.quote.items[index].price == 0) {
      this.quote.items[index].price = null
    }
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }

  erace_value(index, filedName: string) {
    const qtyS: ElementRef[] = this.qtyS?.toArray()

    const priceS: InputNumber[] = this.priceS?.toArray()


    if (filedName == 'price') {
      this.quote.items[index].price = 0
      setTimeout(() => {
        priceS[index].input.nativeElement.select()
      }, 100)

    } else {
      this.quote.items[index].qty = 1
      setTimeout(() => {
        qtyS[index].nativeElement.select()
      }, 100)


    }




  }
  spaceNumber(number) {
    return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

  removeSpaceNumber() {
    const qtyS: ElementRef[] = this.qtyS?.toArray()
    for (let index = 0; index < this.quote?.items?.length; index++) {
      let rmspaceQty = qtyS[index].nativeElement?.value.replace(/\s/g, '')

      this.quote.items[index].qty = parseInt(rmspaceQty)


    }
  }
  printOrderViewer() {
    const subscription = this.quotesService.printqoute_linkEmitter.subscribe((link: any) => {
      if (!isSet(link)) {
        return
      }
      this.pdfViewer = true

      this.quote.download_url = link.download_url
      this.quote.view_url = link.view_url
      this.getShortUrl(link.download_url,link.file_name)
      this.quotesService.printqoute_link.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  downloadPdf() {
    window.location.href = this.quote.download_url;
  }
  showQoute_formDashboered() {

    let subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }
      if (item.itemName == 'Quotes') {
        this.selected_Quote = true

        this.getQuote(item.item?.id)

      }
      this.sharedService.diplayDash.next(null)

    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  private subscribeOnduplicatOrder() {


    const subscription = this.quotesService.duplicateClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }


      let duplicate = Quote.cloneObject(this.quote)
      this.selected_Quote=false
      duplicate.id = null
      duplicate.status = new OrderStatus()
      duplicate.format = null;
      duplicate.discount = 0;

      this.getNextOrderFormat();
      duplicate.date = new Date((new Date()).getTime() + (15 * 86400000));
      duplicate.expiry_date = new Date((new Date()).getTime() + (30 * 86400000));
      this.initQuote()
      this.quote = Quote.cloneObject(duplicate)
      this.quote.duplicate = true
      this.discont_Percentage()

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  expand_inLineProduct(index: number) {
    this.quote.items[index].inline_Epand = !this.quote.items[index].inline_Epand
  }
  showEidtinlineProduct(index: number) {
    this.inlineProductValue = this.quote.items[index].service
    this.indexinlineProduct = index
    this.showaddinlineProduct = true
  }
  edit_inlineProduct() {
    this.quote.items[this.indexinlineProduct].service = this.inlineProductValue
    this.showaddinlineProduct = false

  }
  pdfLoader(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;

  }
  printPdf() {

    printJS({ printable: this.quote.download_url, type: 'pdf', showModal: true })

  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfheight = pdf.numPages * 1020

  }
  onKeyUp(e) {
    switch (e.keyCode) {
      case 13:
        let focusableElements = document.querySelectorAll('input, button')
        let index = Array.prototype.indexOf.call(focusableElements, document.activeElement)
        if (e.shiftKey)
          this.focus(focusableElements, index - 1)
        else
          this.focus(focusableElements, index + 1)

        e.preventDefault()
        break;
    }

  }
  focus(elements, index) {
    if (elements[index])
      elements[index].focus()

  }
  async total_discountchange() {

    if (this.selected_discount_type.type == 'Percent') {
      this.quote.discount_type = 'Percent'
    } else {
      this.quote.discount_type = 'Amount'

      if (this.quote.discount > this.quote.beforeDiscont_quoteTotalIncVAT) {
        return this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('DIS_BIG_TOTAL')
        });
      }

    }


    await this.discont_Percentage()
    await this.getVats_before_afterDiscont()

    this.update_status = true



  }
  discont_Percentage() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const disconnt = this.quote.discont_Percentage
        this.quote.items.map(item => {
          item.discont_Percentage = disconnt

        })
        resolve(null)

      });


    })
  }
  cleardiscont() {
    this.quote.discount = 0

    let disconnt = this.quote.discont_Percentage
    this.quote.items.map(item => {
      item.discont_Percentage = disconnt
    })
  }

  currencyChanged() {
    const subscription = this.quotesService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }

      this.quote.currency = currancy

      this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      this.confrim_exchange_rate = false

      if (this.quote.currency?.id != this.default_currency?.id) {
        this.showAdd_exchange_rate = true

      } else {
        this.quote.exchange_rateStauts = false
        this.showAdd_exchange_rate = false
        this.quote.exchange_rate = 1
        this.add_exchange_rate = 1
        this.defaultcurrency_status = false
        this.exchangeRateChange()
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.default_currency.name });

      }


      this.quotesService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }
  confrimAdd_exchangeRate() {
    this.confrim_exchange_rate = true
    this.showAdd_exchange_rate = false;
    this.quote.exchange_rate = this.add_exchange_rate
    this.quote.exchange_rateStauts = true
    this.defaultcurrency_status = true
    this.exchangeRateChange()
    this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.quote?.currency?.name });

  }
  cancelAdd_exchangeRate() {
    this.sharedService.dialog_status.next(false)

    if (this.confrim_exchange_rate == false) {
      this.showAdd_exchange_rate = false;
      this.quote.exchange_rate = 1;
      this.quote.exchange_rateStauts = false
      this.defaultcurrency_status = false
      this.quotesService.currencyChanged.next(this.default_currency)

    }


  }
  exchangeRateChange() {
    setTimeout(() => {
      this.quote.items.map(item => {
        item.exchange_rate = this.quote.exchange_rate
      })
    }, 300);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  sendToWhatsapp(){
    window.open(`https://web.whatsapp.com/send?text=https://api-demo.squirx.com/api/download/${this.quote.whatsappUrl}`, "_blank")
  }

  getShortUrl(downloadUrl,fileName){
    const subscription = this.sharedService.urlShortener(downloadUrl,fileName).subscribe(url=>{
      if (!isSet(url)) {
        return
      }
      this.quote.whatsappUrl=url?.id
      subscription.unsubscribe()
    },error=>{
      console.log(error)
      subscription.unsubscribe()
    })
  }
  focusThenShow(type){
    switch (type) {
      case 'date':
        setTimeout(()=>{
          this.quoteDateToggle.toggle()
        },0)
        break;
      case 'dueDate':
        setTimeout(()=>{
          this.quoteDueDateToggle.toggle()
        },0)
        break;
    }
  }
}

