
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from 'src/app/models/orders/order';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class SalesServiceService {

  constructor(private api: ApiService) { }
  public showOrder: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showOrderEmitter: Observable<any> = this.showOrder.asObservable();


  getSales(form_date: any, to_date: any, cash_Cary: boolean) {


    return this.api.get<PaginatedResponse<Order[]>>(`jornal/sales?from_date=${form_date}&to_date=${to_date}&inc_cc=${cash_Cary}`);
  }
 
  download_salesZip(form_date: any, to_date: any, cash_Cary: boolean) {
    
    return this.api.getfile(`/jornal/sales/export_zip_files?from_date=${form_date}&to_date=${to_date}&inc_cc=${cash_Cary}`);
  }

  download_salesxls(form_date: any, to_date: any, cash_Cary: boolean) {
 

    return this.api.getfile(`/jornal/sales/export_xls_file?from_date=${form_date}&to_date=${to_date}&inc_cc=${cash_Cary}`);
  }

  download_salescsv(form_date: any, to_date: any, cash_Cary: boolean) {
    
    return this.api.getfile(`/jornal/sales/export_csv_file?from_date=${form_date}&to_date=${to_date}&inc_cc=${cash_Cary}`);
  }
}
