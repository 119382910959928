<app-loading *ngIf="loading"></app-loading>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (add)="showAddOrder()" (Reload)='Reload()'
        addLabel=' {{"NOTES_CUSTOMERS_LIST_CREATE" | translate}}'>
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredProducts" [field_name]="keyword"
          (filter)="serach($event)"></app-Search-select>
      </app-table-header>
      <p-table styleClass="p-datatable-striped" [paginator]="true" (onPage)="getNextCreditPage($event)" [rows]="10"
        [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [value]="CreditNotes"
        [(selection)]="selected" [globalFilterFields]="['order_format','vendor.name']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>{{"NOTES_CUSTOMERS_LIST_INVOICE_NO" | translate}}
              <p-columnFilter type="text" field="order_format" display="menu"></p-columnFilter>
            </th>
            <th>{{"QUOTES_LIST_CUSTOMER" | translate}}
              <p-columnFilter type="text" field="vendor.name" display="menu"></p-columnFilter>
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox (click)="RemoveOrder(rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <!-- (click)="RemoveOrder(rowData)" -->
            <td><a (click)="showOrder(rowData)" href="javascript:;">{{ rowData.order_format }}</a></td>
            <td>{{ rowData.vendor.name }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [size]="size">
  <div header>
    <button pButton [icon]='selectedCurrancy?.icon' (click)="currecy.toggle($event)" class="p-button-secondary p-mr-2"
      type="button" [label]="selectedCurrancy?.type"></button>

    <p-tieredMenu appendTo="body" #currecy [model]="currancyOptions" [popup]="true"></p-tieredMenu>
    <button (click)="save()" [disabled]="loading" pButton type="button" label=' {{"CUSTOMER_LIST_SAVE" | translate}}'
      class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-creditnotessupplier *ngIf="showSidebar"></app-creditnotessupplier>
  </div>
</app-sidebar>