import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';

import { isSet } from '../../core/util';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';


@Component({
  selector: 'app-Search-select',
  templateUrl: './Search.component.html',
  styleUrls: ['./Search.component.scss']
})
export class SearchComponent extends BaseComponent implements OnInit {

  filteredProducts
  SearchCustomer
  selected= []
  
  sr:string = "HIs"
  @Input() Searchitems
  @Input() selecteditem 
  @Input() field_name
  filled:boolean
  
  @Output() public completeMethod = new EventEmitter();
  @Output() public onClear = new EventEmitter();

  @Output() public filter = new EventEmitter();

  @ViewChild('search_el') search_el: any;

  progress=false
  constructor() {
    super();
  }

  ngOnInit(): void {
}
ee(event){
  if (!isSet(event)) {
    return 
    
  }
  this.selected = []
  this.selected.push(event)
  this.filter.emit(this.selected) 

}
onCClear(){
  this.progress=false
  this.search_el.loading=false
  // this.filter.emit(this.Searchitems)
  this.onClear.emit()



}
onSelectSearch(){

//   this.search_el.loading=false

//  if ( isSet(this.selecteditem) ) {
//   this.progress=true
//   this.search_el.loading=true
//  }



}
search(event) {
this.progress=true
this.search_el.loading=true

if (event.query.length>2) {
  

  this.progress=false
  this.search_el.loading=false

 return  this.completeMethod.emit(event.query)
}
}
}
