import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './shared/main/main.component';
import { CustomersModule } from './modules/customers/customers.module';
import { ProductsModule } from './modules/products/products.module';
import { AuthModule } from './modules/auth/auth.module';
import { AuthGuard } from './modules/auth/auth.guard';
import { GuestGuard } from './modules/auth/guest.guard';
import { OrdersModule } from './modules/orders/orders.module';
import { QuotesModule } from './modules/quotes/quotes.module';
import { VendorsModule } from './modules/suppliers/suppliers.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { PurchaseOrdersModule } from './modules/PurchaseOrders/PurchaseOrders.module';
import { TaxModule } from './modules/Tax/Tax.module';
import { creditnotesCustomerModule } from './modules/CreditNotes-customers/creditnotescustomers.module';
import { creditnotessupplireModule } from './modules/CreditNotes-supplire/creditnotessupplier.module';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { ExpenseNoteModule } from './modules/expense-note/expense-note.module';
import { ExpenseModule } from './modules/expense/expense.module';
import { SalesJournalsModule } from './modules/sales-journals/sales-journals.module';
import { _CalendarModule } from './modules/erp/calendar.module';
import { PurchaseJournalsModule } from './purchase-journals/purchase-journals.module';
import { changeemailComponent } from './modules/auth/change-email/changeemail.component';
import { UsersModule } from './modules/users/users.module';
import { RolesModule } from './modules/roles/roles.module';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', loadChildren: () => DashboardModule},
      {path: 'sell/customers', loadChildren: () => CustomersModule},
      {path: 'products', loadChildren: () => ProductsModule},
      {path: 'sell/orders', loadChildren: () => OrdersModule},
      {path: 'sell/quotes', loadChildren: () => QuotesModule},
      {path: 'buy/suppliers', loadChildren: () => VendorsModule},
      {path: 'buy/purchaseorders', loadChildren: () => PurchaseOrdersModule},
      {path: 'buy/taxes', loadChildren: () => TaxModule},
      {path: 'sell/creditnotes', loadChildren: () => creditnotesCustomerModule},
      {path: 'buy/creditnotes', loadChildren: () => creditnotessupplireModule},
      {path: 'expense/expensenote', loadChildren: () => ExpenseNoteModule},
      {path: 'expense', loadChildren: () => ExpenseModule},
      {path: 'accounting/sales-journals', loadChildren: () => SalesJournalsModule},
      {path: 'accounting/purchases-journals', loadChildren: () => PurchaseJournalsModule},
      {path: 'erp/calendar', loadChildren: () => _CalendarModule},
      {path: 'users', loadChildren: () => UsersModule },
      {path: 'users/roles', loadChildren: ()=>RolesModule}
    ]
  },
  {
    path: 'auth', loadChildren: () => AuthModule, canActivate: [GuestGuard],
  },
  {
    path: 'auth/change_email', component:changeemailComponent},
  { path: '**',   redirectTo: '/404', pathMatch: 'full'
},
  {
        path: '404', component:NotfoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
