import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import * as _ from 'lodash';
import { VatInfo, VatInfoCountry } from '../../auth/models/vatinfo';
import { ApiService } from '../../core/api.service';
import { Dropdown } from 'primeng/dropdown';
import { SharedService } from '../shared.service';
import { Address } from 'src/app/models/address';
import { Contact } from 'src/app/models/contact';
import { MessageService } from 'primeng/api';
import { Payer } from 'src/app/models/expense/payer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payer-select',
  templateUrl: './payer-select.component.html',
  styleUrls: ['./payer-select.component.scss']
})
export class payersSelectComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('dd') dd: Dropdown;
  @ViewChild('payer_name') payer_name: any
  @Input() payer: Payer;
  @Output() payerChange = new EventEmitter<Payer>();
  @Output() onHide = new EventEmitter<any>();
  @Input() append: string;

  filteredPayers: Payer[] = [];
  payerss: Payer[] = []
  payer_nametouched = false
  addPayerLoading: boolean;
  displayAddpayer: boolean;
  miniPayer: Payer;
  DropdownonFocus_Stuats: boolean
  public contact = new Contact()
  public moredetail_contact = false
  public showContactDialog = false;
  serach: string
  @Input() filterMode: boolean = false

  constructor(
    private api: ApiService,
    private sharedService: SharedService,
    public translate: TranslateService,
    public messageService: MessageService,
  ) {
    super();
  }


  ngOnInit(): void {

    // Dropdown.prototype.onInputFocus = function (event) {
    //   setTimeout(() => {
    //     this.focused = true;
    //     this.onFocus.emit(event);
    //   });
    // };
    Dropdown.prototype.onInputBlur = function (event) {
      setTimeout(() => {
        this.focused = false;
        this.onModelTouched();
        this.onBlur.emit(event);
      });
    };
    this.getPayer();
    setTimeout(() => {
      this.initDropdown();
    }, 100);
  }
  ngAfterViewInit() {
    if (this.append !== 'body' && (this.filterMode == false && !isSet(this.payer))) {

      const openDropdown = setInterval(() => {
        this.dd.containerViewChild.nativeElement.click()


        if (this.DropdownonFocus_Stuats === true) {
          this.DropdownonFocus_Stuats = false
          clearInterval(openDropdown);

        }


      })

    }


  }

  payerChanged($event) {

    const payer = $event.value as Payer;
    if (!isSet(payer)) {
      this.onHide.emit($event);
      this.payerChange.emit(payer);

    }

    if (payer.id === '0') {
      this.showAddminiPayer('');
      return;
    }
    if (payer.id === '-1') {
      this.showAddminiPayer(payer.name);
      return;
    }

    this.sharedService.chekcempey.next(true)
    this.payerChange.emit(payer);
  }

  closeminiPayerModal() {
    this.payer = null;
    this.displayAddpayer = false;
    this.outside_Status(false)
  }

  more_detail() {
    if (this.DropdownonFocus_Stuats == false) {
      this.DropdownonFocus_Stuats = true
    } else if (this.DropdownonFocus_Stuats == true) {
      this.DropdownonFocus_Stuats = false

    }

  }
  addminiPayer() {

    if (!isSet(this.miniPayer?.name) || this.miniPayer.name == '') {
      this.payer_name?.control.markAsTouched();
      this.payer_nametouched = true
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('PLZ_FILL_NAME_REQ')
      });

    } else {
      this.addPayerLoading = true;
      const subscription = this.api.post('payers', this.miniPayer.toDB()).subscribe((payer: Payer) => {
        if (!isSet(payer)) {
          return;
        }
        try {



          this.getPayer()


          setTimeout(() => {

            if (this.payerss[0]?.id == payer?.id) {
              this.payer = this.payerss[0]

              this.payerChange.emit(this.payerss[0]);



            }
          }, 800);



          this.dd.optionsToDisplay.push({ label: this.payer.name, value: this.payer });

          this.dd.containerViewChild.nativeElement.click()
          this.dd.containerViewChild.nativeElement.click()


          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('NEW_PAYER_ADDED')
          });
          this.payer_name?.control.markAsUntouched()
          this.payer_name?.control.clearValidators()
          this.payer_nametouched = false

        } catch (e) {
        } finally {
          this.addPayerLoading = false;
          this.displayAddpayer = false;
          subscription.unsubscribe();
        }
      }, (error) => {
        this.addPayerLoading = false;
        subscription.unsubscribe();
      });
    }
    this.outside_Status(false)

  }
  notempty(event) {

    this.payer_nametouched = false
  }


  hide($event) {

    if (this.payer && this.payer?.id !== '-1') {
      this.onHide.emit($event);
    }

  }

  private showAddminiPayer(name: string) {
    this.payer = null
    this.miniPayer = new Payer();
    this.miniPayer.name = name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('ADD_NEW_PAYER') + ')</span>', '')
    this.displayAddpayer = true;
    setTimeout(() => {
      this.payer = new Payer();

    }, 200);
  }

  private getPayer(): void {
    const subscription = this.api.get<PaginatedResponse<Payer[]>>('/payers').subscribe((payers: PaginatedResponse<Payer[]>) => {
      if (!isSet(payers)) {
        return;
      }
      this.payerss = Payer.cloneManyObjects(payers.results);

      this.filteredPayers = this.payerss;
      this.dd.optionsToDisplay = this.filteredPayers
      this.dd.filterValue = null

      if (this.filterMode == false) {
        const addCustomer = new Payer();
        addCustomer.id = '0';
        addCustomer.name = '<span class="add-quick-option">' + this.translate.instant('ADD_NEW_PAYER') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.filteredPayers = insert(this.filteredPayers, 0, addCustomer)



      }


      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  serachPayer(value: any) {
    const subscription = this.api.get<PaginatedResponse<Payer[]>>(`/payers?query=name,startswith,${value}`).subscribe((payers: PaginatedResponse<Payer[]>) => {

      if (!isSet(payers)) {
        return;
      }

      this.payerss = Payer.cloneManyObjects(payers.results);
      this.filteredPayers = this.payerss;


      if (this.filterMode == false) {
        const addCustomer = new Payer();
        addCustomer.id = '-1';
        addCustomer.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('ADD_NEW_PAYER') + ')</span>';

        this.filteredPayers.push(addCustomer)
      }

      this.dd.optionsToDisplay = this.filteredPayers;

      this.serach = this.dd.filterValue
      subscription.unsubscribe();

    }, error => {
      subscription.unsubscribe();
    });
  }
  private initDropdown(): void {
    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;


      if (this.serach != value) {
        return this.serachPayer(value.toLocaleLowerCase())
      }


    };

  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  onFocus() {
    this.DropdownonFocus_Stuats = true

  }

}
