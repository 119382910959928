import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { isSet } from '../../core/util';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public password: string
  public confirmPassword: string
  public token: string;
  resetBtn = false
  swapSeccuess = false

  checkpass: boolean
  passfoucs: boolean
  passwrod_numeric: boolean
  passwrod_lowercase: boolean
  passwrod_capitalcase: boolean
  passwrod_length: boolean
  passinvaild: boolean
  @ViewChild('Password') passwordCheck: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private auth: AuthService,
    public translate: TranslateService,
    private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
      if (this.token == null) {
        return this.router.navigateByUrl('auth/login')
      }
    })



  }
  resetPassword() {
    if (this.passinvaild) {
      return this.messageService.add({
        key: 'reset', life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter vaild Password'
      });
    } 

    if (this.password !== this.confirmPassword) {

      return this.messageService.add({
        key: 'reset', life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: "Please make sure your password match"
      });
    }


    let resetPasswordBody = {
      token: this.token,
      new_password: this.password
    }
    const subscription = this.auth.setPassword(resetPasswordBody).subscribe((resetPass: any) => {
      this.resetBtn = true
      if (!isSet(resetPass)) {
        return;
      }
      this.swapSeccuess = true
    }
      , error => {
        console.log(error);
        this.resetBtn = false
        this.messageService.add({
          key: 'reset',
          severity: 'error',
          summary: this.translate.instant('ERROR'),
          detail: 'Please check your inputs'

        });

        subscription.unsubscribe();
      })
  }

  onblurPassword() {
    this.checkpass = false
    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.password?.length < 8
    ) {
      this.passinvaild = true
      this.messageService.add({
        key: 'register', life: 5000,
        severity: 'error', summary: this.translate.instant('ERROR'), detail: 'Please enter valid password'
      });
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.password?.length >= 8
    ) {
      this.passinvaild = false

    }
  }
  onfoucsPassword() {
    this.passfoucs = true
    this.checkpass = true

  }

  checkPassword() {
    let lowerCaseLetters = /[a-z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(lowerCaseLetters)) {
      this.passwrod_lowercase = true
    } else {
      this.passwrod_lowercase = false
    }

    let numbers = /[0-9]/g;
    if (this.passwordCheck?.nativeElement?.value.match(numbers)) {
      this.passwrod_numeric = true

    } else {
      this.passwrod_numeric = false
    }

    let upperCaseLetters = /[A-Z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(upperCaseLetters)) {
      this.passwrod_capitalcase = true

    } else {
      this.passwrod_capitalcase = false
    }
    if (this.password?.length >= 8) {
      this.passwrod_length = true
    } else {
      this.passwrod_length = false
    }

    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.password?.length < 8
    ) {
      this.passinvaild = true
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.password?.length >= 8
    ) {
      this.passinvaild = false

    }
  }
}
// http:localhost:4200/auth/resetpassword‏?token=99716e3a-aabf-472e-a8b9-fa82031d7778
// http://demo.squirx.com?token=99716e3a-aabf-472e-a8b9-fa82031d7778