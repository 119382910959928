import { Category } from './category';
import { Vat } from './vat';
import { ProductAdvance } from './productadvance';
import { ProductPricing } from './productpricing';
import { isSet } from '../modules/core/util';
import { ProductShelf } from './productshelf';
import { Vat_Product } from './vat-product';
import { Attachment } from './Attachment';
import { Brand } from './brand';

export class Product {
  id: string;
  name: string;
  sell_price: number;
  created_by: number;
  vat: Vat_Product;
  advanced: ProductAdvance;
  company: number;
  remark: string;
  pricing: ProductPricing;
  shelf: ProductShelf;
  attachment: Attachment;
  seletectAttachment: Attachment;
  categoryfillter: string
  vatvalue: Vat_Product
  view_url: string
  download_url:string
  service:string
  barcode:string;
  category: Category;
  brand:Brand
  public constructor(params?: Product) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Product) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }

    

    const obj = new Product(objectToClone);


    obj.advanced = ProductAdvance.cloneObject(objectToClone.advanced);
    obj.pricing = ProductPricing.cloneObject(objectToClone.pricing);
    obj.shelf = ProductShelf.cloneObject(objectToClone.shelf);
    obj.vat = Vat_Product.cloneObject(objectToClone.vat);

      obj.attachment = Attachment.cloneObject(objectToClone.attachment);

    return obj;
  }

  public static cloneManyObjects(objectsToClone: Product[]) {
    const objects: Product[] = [];
    for (const obj of objectsToClone) {
      objects.push(Product.cloneObject(obj));
    }

    return objects;
  }

  public toDB() {
    
    return {
      name: this.name,
      sell_price: this.sell_price,
      // image: this.image,
      vat: this.vat?.id,
      advanced: {
        stock: this.advanced?.stock,
        barcode: this.advanced?.barcode,
        unit: this.advanced?.unit?.id,
        sub_item_barcode:this.advanced?.sub_item_barcode,
      },
      category: this.category?.id,
      brand:this.brand?.id,
      remark: this.remark,
      pricing: this.pricing,
      shelf: {
        shelf: this.shelf?.shelf,
        shelf_life: this.shelf?.shelf_life,
        weight: this.shelf?.weight,
        height: this.shelf?.height,
        width: this.shelf?.width,
        length: this.shelf?.length,
        dimension_unit: this.shelf.dimension_unit?.id,
        package_type: this.shelf?.package_type?.id,
        minimum_stock: this.shelf?.minimum_stock,
      },
      attachment:this.attachment
    };
  }

  public toMiniDB() {
    return {
      id: this.id,
      name: this.name,
      vat: this.vat.id,
      sell_price: this.sell_price,
    };
  }
}
