import { Customer } from '../customer';
import { OrderItem } from '../orders/orderitem';
import { OrderStatus } from '../orders/orderstatus';
import { isSet } from '../../modules/core/util';
import * as _ from 'lodash';
import { Contact } from '../contact';
import { Address } from '../address';
import { Currency } from '../currency';


export class Quote {
  id: string;
  customer: Customer;
  company_name: string
  format: string;
  date: Date;
  items: OrderItem[];
  total: number;
  sub_total: number;
  total_vat: number;
  balance: number;
  discount: any;
  discount_type: string
  status: OrderStatus;
  terms: string;
  expiry_date: Date
  notes: string
  payment_terms: string
  view_url: string
  download_url: string
  currency: Currency
  exchange_rate: number
  exchange_rateStauts: boolean
  paid_vats: Array<{
    name?: number;
    beforediscont?: number;
    afterdiscont: number;
  }>
  whatsappUrl:string
  duplicate: boolean
  get quoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'totalNet') || 0
  }
  get beforeDiscont_quoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_totalNet') || 0
  }

  get quoteTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.quoteTotal + this.vatAmount_6 + this.vatAmount_21 + this.vatAmount_12 || 0
  }
  get beforeDiscont_quoteTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'beforediscont_total') || 0
  }

  get discont_Percentage() {
    if (this.discount_type == 'Percent') {

      return this.discount / 100
    }
    const x = this.discount / _.sumBy(this.items, 'beforediscont_totalNet')

    return x
  }

  get quoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatAmount') || 0
  }
  get beforediscontquoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_vatAmount') || 0
  }
  get vatAmount_6befordiscont() {
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 6) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_12befordiscont() {
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 12) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_21befordiscont() {
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 21) {
        return item.beforediscont_vatAmount

      }
    }) || 0
  }
  get vatAmount_6() {
    // if (this.total_discount > 0) {

    //   const base6=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 6  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0
    // return ( base6-(base6* this.discont_Percentage)) * 0.06
    //   }
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 6) {
        return item.vatAmount

      }
    }) || 0

  }
  get vatAmount_12() {
    //   if (this.total_discount > 0) {

    //   const base12=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 12  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0
    // return  ( base12-(base12* this.discont_Percentage))* 0.12
    //   }


    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 12) {

        return item.vatAmount
      }
    }) || 0

  }
  get vatAmount_21() {
    // if (this.total_discount > 0) {

    //   const base21=  _.sumBy(this.items,(item)=>{
    //       if (item?.vat?.value == 21  ) {
    //             return  item.totalNet
    //         }
    //     })|| 0


    // return  ( base21-(base21* this.discont_Percentage)) * 0.21
    //   }
    return _.sumBy(this.items, (item) => {
      if (item?.vat?.value == 21) {
        return item.vatAmount

      }
    }) || 0

  }

  get default_currncey_quoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_vatAmount') || 0
  }
  get default_currncey_quoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_totalNet') || 0
  }
  get default_currncey_quoteTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.default_currncey_quoteTotal + this.default_currncey_quoteTotalVAT || 0
  }

  get default_currncey_beforeDiscont_TotalInc() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'default_currncey_beforediscont_total') || 0
  }
  get default_currncey_beforeDiscont_quoteTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_totalNet') || 0
  }
  get default_currncey_beforediscontquoteTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_vatAmount') || 0
  }
  public constructor(params?: Quote,) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Quote) {
    if (!isSet(objectToClone)) {
      return new Quote();
    }
    const obj = new Quote(objectToClone);
    obj.customer = Customer.cloneObject(objectToClone.customer);
    if (isSet(objectToClone.items)) {
      obj.items = OrderItem.cloneManyObjects(objectToClone.items);
    }
    obj.status = OrderStatus.cloneObject(objectToClone.status);
    return obj;
  }

  public static cloneManyObjects(objectsToClone: Quote[]) {
    const objects: Quote[] = [];
    for (const obj of objectsToClone) {
      objects.push(Quote.cloneObject(obj));
    }

    return objects;
  }

  public toDB(quoteDate, expiryDate, _discount) {

    return {

      format: this.format,
      customer: this.customer.id,
      terms: this.customer.payment_details?.payment_terms,
      invoiced: false,
      invoice_number: 1,
      date: quoteDate,
      expiry_date: expiryDate,
      notes: this.notes,
      balance: this.balance,
      discount: _discount,
      billing_email: this.contact()?.email,
      payment_terms: this.payment_terms,
      status: {
        completed: false,
        draft: true,
        sent: false,

      },
      items: _.map(this.items, item => {
        return {
          product: item.product?.id,
          company: item.product?.company,
          name: item?.name,
          qty: item?.qty,
          qtyType: item?.qtyType,
          price: item?.price,
          vat: item?.vat?.id,
          date: item?.date,
          price_type: item?.price_type,
          service: item?.service
        }
      }),
      shipping_address: this.duplicate ? null :
        {
          address: this.shipping_address()?.address,
          zip: this.shipping_address()?.zip,
          city: this.shipping_address()?.city,
          country_code: this.shipping_address()?.country_code
        },
      phone: this.duplicate ? null : this.contact()?.phone,
      currency: this.currency?.id,
      exchange_rate: this.exchange_rate
    }

  }
  contact() {
    let contactt: Contact
    if (isSet(this.customer.contact)) {
      contactt = this.customer.contact[0]

    }

    return contactt
  }
  shipping_address() {
    let shipping_address: Address
    if (isSet(this.customer.shipping_address)) {
      shipping_address = this.customer.shipping_address[0]

    }
    return shipping_address

  }


}
