import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../product.service';
import { isSet } from '../../core/util';
import { Product } from '../../../models/product';
import { ProductAdvance } from '../../../models/productadvance';
import { ProductPricing } from '../../../models/productpricing';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { Unit } from '../../../models/unit';
import { Category } from '../../../models/category';
import { PackageType } from '../../../models/packagetype';
import { BaseComponent } from '../../core/base/base.component';
import { ProductShelf } from 'src/app/models/productshelf';
import { MessageService } from 'primeng/api';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { DimensionUnits } from 'src/app/models/dimension-units';
import { Vat_Product } from 'src/app/models/vat-product';
import { Dropdown } from 'primeng/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { Options, ImageResult } from "ngx-image2dataurl";
import { Brand } from 'src/app/models/brand';
import { Attachment } from 'src/app/models/Attachment';

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.scss'],
  providers: [MessageService]

})
export class ProductsFormComponent extends BaseComponent implements OnInit {
  @ViewChild('dd') dd: Dropdown;
  @ViewChild('Brand') Brand: Dropdown;

  public stocked: boolean;
  fileViewer = false
  public attachmentdilioge: boolean
  attachment_name: string
  public product: Product
  public dimensions: any;
  public showdeleteAccountmanager = false
  public vat = new Vat_Product()
  public vat_Percentage: Vat_Product[] = [];
  public index_Toupdate = 0
  alertReq: boolean = false
  public categories: Category[] = [];
  public updateStatus: boolean = false
  //category
  public cats: Category[] = []
  public category = new Category()
  public showAddcategory = false
  public showCAT = false
  public showCAT_flabel = true
  public showDeletedCAT = false
  CAThedaer = 'Add Category'
  //brand
  public brands: Brand[] = []
  public brand = new Brand()
  public showAddBrand = false
  public showBrand = false
  public showBrand_flabel = true
  public showDeleteBrand = false
  BrandHedaer = 'Add Category'
  //Dimenation Unit
  public dimensionUnits: DimensionUnits[] = []
  public dimensionUnit = new DimensionUnits()
  public showAddcdimensionUnit = false
  public showDimensionUnit = false
  public showDimensionUnit_flabel = true
  public showDeletedDimensionUnit = false
  dimensionsUnitHedaer = 'Add Dimension Unit'
  //Package Type
  public packageTypes: PackageType[] = [];
  public packageType = new PackageType()
  public showAddPackageType = false
  public showPackageType = false
  public showPackageType_flabel = true
  public showDeletedPackageType = false
  PackageTypeHeader = 'Add Package Type'
  //Unit
  public units: Unit[] = [];
  public unit = new Unit()
  public showAddUnit = false
  public showUnit = false
  public showUnit_flabel = true
  public showDeletedUnit = false
  unitHeader = 'Add Unit'


  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG']
  };
  fileupload: any = null
  viewAttachment_diloage = false
  @ViewChild('Dim') Dim: Dropdown
  @ViewChild('lol') lol: Dropdown
  @ViewChild('m2') m2: Dropdown
  @ViewChild('attachmentname') attachmentnamevaild: any;
  @ViewChild('upload') upload: ElementRef;

  constructor(private productService: ProductService, public translate: TranslateService, private messageService: MessageService, private sharedService: SharedService,
    private router: Router

  ) {
    super();
  }

  ngOnInit(): void {

    this.getUnits();
    this.getCategories();
    this.getBrands()
    this.getDimensionUnits();
    this.getPackageTypes();
    this.initProduct()
    this.subscribeOnproductSelected()
    this.Save_form()
    this.subscribeOnSaveFormClicked();
    this.updateCustomerEmiiter()
    this.getSavedProdct()
    this.clearAll()
    this.showProductsFromDash()


  }

  fillDimensions() {
    if (!isSet(this.dimensions)) {
      return;
    }
    const dimensions = this.dimensions.split('x');
    if (dimensions.length !== 3) {
      return;
    }
    this.product.shelf.height = dimensions[0];
    this.product.shelf.width = dimensions[1];
    this.product.shelf.length = dimensions[2];
  }

  private initProduct() {
    this.getVats()
    this.product = new Product();
    this.product.advanced = new ProductAdvance();
    this.product.pricing = new ProductPricing();
    this.product.vat = this.vat_Percentage[0]
    this.product.shelf = new ProductShelf()
    this.product.attachment = new Attachment()
    this.dimensions = null
    this.alertReq = false

  }


  private subscribeOnproductSelected() {
    const subscription = this.productService.productSelectedEmitter.subscribe((product: Product) => {
      if (!isSet(product)) {
        return this.initProduct();
      }
      this.getProduct(product?.id)

      if (!isSet(this.product.pricing)) {
        this.product.pricing = new ProductPricing();
      }
      if (!isSet(this.product.shelf)) {
        this.product.shelf = new ProductShelf();
      }

      if (!isSet(this.product.advanced)) {
        this.product.advanced = new ProductAdvance();
      }
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);

  }
  showProductsFromDash() {
    let subscription = this.sharedService.diplayitemEmitter.subscribe((pro: any) => {
      if (!isSet(pro)) {
        return;
      }
      this.getProduct(pro.item.id)
      this.sharedService.diplayDash.next(null)
    }, error => {
      this.handleError(error);
      console.log(error);

    });
    this.subscriptions.push(subscription);
  }
  private getProduct(id: string) {
    this.loading = true

    const subscription = this.productService.getProduct(id).subscribe((product: Product) => {
      if (!isSet(product)) {
        return this.initProduct();
      }
      const cloneProduct = Product.cloneObject(product)

      this.product = cloneProduct;
      if (!isSet(this.product?.shelf)) {
        this.product.advanced = new ProductAdvance();
      }
      if (!isSet(this.product?.advanced)) {
        this.product.advanced = new ProductAdvance();
      }
      if (!isSet(this.product?.pricing)) {
        this.product.pricing = new ProductPricing();
      }
      if (!isSet(this.product?.attachment)) {
        this.product.attachment = new Attachment()
      }
      if (isSet(this.product.shelf?.height) && isSet(this.product.shelf?.width) && isSet(this.product.shelf?.length)) {
        const height = this.product.shelf?.height
        const width = this.product.shelf?.width
        const length = this.product.shelf?.length
        this.dimensions = height.toString() + width.toString() + length.toString()
      } else {
        this.dimensions = null
      }


      this.loading = false
      subscription.unsubscribe();

    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false

    });

  }
  private subscribeOnSaveFormClicked() {
    const subscription = this.productService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return;
      }
      this.saveProduct();

    });
    this.subscriptions.push(subscription);
  }

  private saveProduct() {
    if (!isSet(this.product.name && this.product.sell_price)) {
      this.alertReq = true
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'), detail: 'Please fill out required fields'
      });


    }
    this.loading = true;
    const subscription = this.productService.saveProduct(this.product).subscribe((product: Product) => {
      this.loading = false;
      if (!isSet(product)) {
        return;
      }
      this.product.id = product.id

      this.sharedService.chekcempey.next(null)
      this.productService.saveFormClicked.next(null);
      subscription.unsubscribe();
      this.loading = false;
      this.productService.retrieveLastProduct.next(this.product)
      this.productService.refreshProducts();
      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: 'New product added successfully'
      });
    }, error => {
      console.log(error);

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {

        return;
      }
      this.sharedService.Save_object(this.product)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    })

  }
  getSavedProdct() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((product: any) => {
      if (!isSet(product)) {
        this.sharedService.objectstauts.next(null)
        return;
      }
      if (product.url == this.router.url) {
        this.product = product.object
        this.sharedService.objectstauts.next(true)

      } else {
        this.sharedService.objectstauts.next(null)

      }

    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  clearAll() {
    this.sharedService.clearAll_Emitter.subscribe(status => {

      if (!isSet(status)) {
        return
      }

      this.initProduct()
      this.sharedService.clearAll.next(null)
    }

      , error => {
        this.handleError(error);
      });
  }
  notempty(event) {
    this.sharedService.chekcempey.next(true)
    this.updateStatus = true

  }
  private updateCustomerEmiiter(): void {
    try {
      const subscription = this.productService.upadteFormClickedEmitter.subscribe(status => {

        if (!isSet(status) || !status) {
          return;
        }
        this.updateProduct()

      }, error => {
        this.handleError(error);
      });
      this.subscriptions.push(subscription);


    } catch (error) {
      this.log(error);
    }

  }
  updateProduct() {
    if (this.updateStatus == false) {
      this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

    } else {
      try {
        this.loading = true;
        const subscription = this.productService.updateProduct(this.product).subscribe((customer: Product) => {

          if (!isSet(customer)) {
            return;
          }
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
          this.loading = false;
          subscription.unsubscribe();
          this.productService.refreshProducts()
          this.productService.upadteFormClicked.next(null)
          this.updateStatus = false

        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          this.loading = false;
          subscription.unsubscribe();
          this.productService.upadteFormClicked.next(null)
          this.updateStatus = false

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }
    }
  }
  private getVats() {
    const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(vats)) {
        return;
      }
      this.vat_Percentage = Vat_Product.cloneManyObjects(vats.results);
      let zeroVat_index = 0
      this.vat_Percentage.map((vat, index) => {
        if (vat.value == 0) {
          vat.value = '0'
          zeroVat_index = index
        }
      })
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.vat_Percentage = insert(this.vat_Percentage, 0, this.vat_Percentage[zeroVat_index])

      this.vat_Percentage.splice(zeroVat_index + 1, 1)

      subscription.unsubscribe();


    }, error => {

      console.log(error);
      subscription.unsubscribe();


    })

  }



  private getCategories() {
    const subscription = this.productService.getCategories().subscribe((categories: PaginatedResponse<Category[]>) => {
      if (!isSet(categories)) {
        return;
      }

      this.categories = Category.cloneManyObjects(categories.results);
      const addCategory = new Category();
      addCategory.id = '0';
      addCategory.name = '<span class="add-quick-option">' + this.translate.instant('NEW_CAT') + '</span>'
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.categories = insert(this.categories, 0, addCategory)


      setTimeout(() => {
        this.initDropdowncategories();
      }, 100);
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  private getBrands() {
    const subscription = this.productService.getBrands().subscribe((brands: PaginatedResponse<Brand[]>) => {
      if (!isSet(brands)) {
        return;
      }

      this.brands = Brand.cloneManyObjects(brands.results);
      const addBrands = new Brand();
      addBrands.id = '0';
      addBrands.name = '<span class="add-quick-option">' + this.translate.instant('NEW_BRAND') + '</span>'
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.brands = insert(this.brands, 0, addBrands)
      setTimeout(() => {
        this.initDropdownBrands();
      }, 100);
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  save_NewCategory() {
    this.loading = true;


    const subscription = this.productService.save_Product_categories(this.category).subscribe((category: Category) => {
      this.loading = false;
      if (!isSet(category)) {
        return;
      }
      subscription.unsubscribe();
      this.loading = false;
      this.showAddcategory = false
      const x = setInterval(() => {
        this.getCategories()
        this.product.category = this.categories[1]

        if (this.product.category.name == this.category.name) {
          clearInterval(x);

        }

      }, 300)


      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('NEW_CAT_ADDED')
      });
    }, error => {

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })
  }

  save_unit() {
    this.loading = true
    const subscription = this.productService.save_units(this.unit).subscribe((unit: Unit) => {
      this.loading = false
      if (!isSet(unit)) {
        return;
      }
      subscription.unsubscribe();
      this.loading = false;
      this.showAddUnit = false
      const a7a = setInterval(() => {
        this.getUnits()
        this.product.advanced.unit = this.units[1]
        if (this.product.advanced.unit.name == this.unit.name) {
          clearInterval(a7a);
        }
      }, 300)
      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('NEW_UNIT_ADDED')
      });
    }, error => {

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })
  }

  save_package_type() {
    this.loading = true
    const subscription = this.productService.save_package_type(this.packageType).subscribe((packageType: PackageType) => {
      this.loading = false
      if (!isSet(packageType)) {
        return;
      }
      subscription.unsubscribe();
      this.loading = false;
      this.showAddPackageType = false
      this.getPackageTypes()
      const lol = setInterval(() => {

        this.product.shelf.package_type = this.packageTypes[1]

        if (this.product.shelf.package_type.name == this.packageType.name) {
          clearInterval(lol);
        }
      }, 300)
      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('NEW_PACK_ADDED')
      });
    }, error => {

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })

  }





  save_dimension_units() {
    this.loading = true;
    const subscription = this.productService.save_dimension_units(this.dimensionUnit).subscribe((dimensionUnit: DimensionUnits) => {
      this.loading = false;
      if (!isSet(dimensionUnit)) {
        return;
      }
      subscription.unsubscribe();
      this.loading = false;
      this.showAddcdimensionUnit = false
      this.getDimensionUnits()
      const x = setInterval(() => {
        this.product.shelf.dimension_unit = this.dimensionUnits[1]

        if (this.product.shelf.dimension_unit?.name == this.dimensionUnit?.name) {
          clearInterval(x);

        }

      }, 300)


      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('NEW_DIM_ADDED')
      });
    }, error => {

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })
  }
  saveBrand() {
    this.loading = true;
    const subscription = this.productService.saveBrand(this.brand).subscribe((brand: Brand) => {
      this.loading = false;
      if (!isSet(brand)) {
        return;
      }
      subscription.unsubscribe();
      this.loading = false;
      this.showAddBrand = false
      this.getBrands()
      const x = setInterval(() => {
        this.product.brand = this.brands[1]

        if (this.product.brand?.name == this.brand?.name) {
          clearInterval(x);

        }

      }, 300)


      this.messageService.add({
        key: 'form',
        severity: 'success',
        summary: this.translate.instant('SUCCESSFUL'),
        detail: this.translate.instant('NEW_BRAND_ADDED')
      });
    }, error => {

      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

      subscription.unsubscribe();
      this.loading = false;

    })
  }
  categoryChanged($event) {
    const category = $event.value as Category;

    if (category?.id === '0') {
      this.category = new Category()
      this.product.category = new Category()

      return this.showAddcategory = true

    }
    if (category?.id === '-1') {
      this.category = new Category()
      this.category.name = category.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_CAT') + ')</span>', '')

      this.product.category = new Category()
      return this.showAddcategory = true

    }
    if (category == null) {

      this.updateStatus = true
    }
  }
  brandChanged($event) {
    const brand = $event.value as Brand;

    if (brand?.id === '0') {
      this.brand = new Brand()
      this.product.brand = new Brand()
      this.product.brand.name = null

      return this.showAddBrand = true

    }
    if (brand?.id === '-1') {
      this.brand = new Brand()
      this.brand.name = brand.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_BRAND') + ')</span>', '')

      this.product.brand = new Brand()
      return this.showAddBrand = true

    }
    if (brand == null) {

      this.updateStatus = true
    }
  }

  private initUnit(): void {
    this.lol.activateFilter = () => {
      const value = this.lol.filterValue
      const options = this.units.filter(lol => {
        return lol.name.toLocaleLowerCase().includes(value.toLowerCase())
      })
      const addUnit = new Unit()
      addUnit.id = '-1'
      addUnit.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_UNIT') + ')</span>';
      options.push(addUnit);
      this.lol.optionsToDisplay = options;

    }
  }


  private initPackageType(): void {
    this.m2.activateFilter = () => {
      const value = this.m2.filterValue
      const options = this.packageTypes.filter(lol => {
        return lol.name.toLocaleLowerCase().includes(value.toLowerCase())
      })
      const addPackage = new PackageType()
      addPackage.id = '-1'
      addPackage.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_PACK') + ')</span>';
      options.push(addPackage);
      this.m2.optionsToDisplay = options;
    }
  }


  // private initDimaenation(): void {
  //   this.Dim.activateFilter = () => {
  //     const value = this.Dim?.filterValue;
  //     const options = this.dimensionUnits?.filter(lol => {
  //       return lol.name.toLocaleLowerCase().includes(value.toLowerCase())
  //     })
  //     const AddDimationUnit = new DimensionUnits();
  //     AddDimationUnit.id = '-1';
  //     AddDimationUnit.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_DIM_INIT') + ')</span>';

  //     options.push(AddDimationUnit);

  //     this.Dim.optionsToDisplay = options;


  //   }
  // }

  private initDropdowncategories(): void {
    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;
      const options = this.categories.filter(category => {
        return category.name.toLowerCase().includes(value.toLowerCase());
      });

      const addcategory = new Category();
      addcategory.id = '-1';
      addcategory.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_CAT') + ')</span>';

      options.push(addcategory);

      this.dd.optionsToDisplay = options;
    };
  }
  private initDropdownBrands(): void {
    this.Brand.activateFilter = () => {
      const value = this.Brand.filterValue;
      const options = this.brands.filter(brand => {
        return brand.name.toLowerCase().includes(value.toLowerCase());
      });

      const addcBrand = new Brand();
      addcBrand.id = '-1';
      addcBrand.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_BRAND') + ')</span>';

      options.push(addcBrand);

      this.Brand.optionsToDisplay = options;
    };
  }
  private getUnits() {
    const subscription = this.productService.getUnits().subscribe((units: PaginatedResponse<Unit[]>) => {
      if (!isSet(units)) {
        return;
      }

      this.units = Unit.cloneManyObjects(units.results);
      const unit = new Unit()
      unit.id = '0'
      unit.name = '<span class="add-quick-option">' + this.translate.instant('NEW_UNIT') + '</span>'
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.units = insert(this.units, 0, unit)
      setTimeout(() => {
        this.initUnit()
      }, 100)
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  private getDimensionUnits() {
    const subscription = this.productService.getDimensionUnits().subscribe((dimensionUnits: PaginatedResponse<DimensionUnits[]>) => {
      if (!isSet(dimensionUnits)) {
        return;
      }

      this.dimensionUnits = DimensionUnits.cloneManyObjects(dimensionUnits.results);
      const dimensionUnit = new DimensionUnits()
      dimensionUnit.id = '0';
      dimensionUnit.name = '<span class="add-quick-option">' + this.translate.instant('NEW_DIM_INIT') + '</span>'
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.dimensionUnits = insert(this.dimensionUnits, 0, dimensionUnit)
      // setTimeout(() => {
      //   this.initDimaenation()
      // }, 100)
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  private getPackageTypes() {
    const subscription = this.productService.getPackageTypes().subscribe((packageTypes: PaginatedResponse<PackageType[]>) => {
      if (!isSet(packageTypes)) {
        return;
      }
      this.packageTypes = PackageType.cloneManyObjects(packageTypes.results);
      const packageType = new PackageType()
      packageType.id = '0'
      packageType.name = '<span class="add-quick-option">' + this.translate.instant('NEW_PACK') + '</span>'
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.packageTypes = insert(this.packageTypes, 0, packageType)
      setTimeout(() => {
        this.initPackageType()
      }, 100)
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }
  showDeleteDimentionsUnit(item: DimensionUnits) {
    this.dimensionUnit = item
    this.showDeletedDimensionUnit = true
    this.product.shelf.dimension_unit = null

  }

  showEditCAT(item: Category) {
    this.category = Category.cloneObject(item)
    this.CAThedaer = this.translate.instant('EDIT_CAT')
    this.showCAT = true
    this.index_Toupdate = this.cats.indexOf(item)
  }
  showEdiBrand(item: Brand) {
    this.brand = Brand.cloneObject(item)
    this.BrandHedaer = this.translate.instant('EDIT_BRAND')
    this.showBrand = true
    this.index_Toupdate = this.brands.indexOf(item)
  }
  showEditDimentionsUnit(item: DimensionUnits) {
    this.dimensionUnit = DimensionUnits.cloneObject(item)
    this.dimensionsUnitHedaer = this.translate.instant('EDIT_DIM')
    this.showDimensionUnit = true
    this.index_Toupdate = this.dimensionUnits.indexOf(item)
  }
  deleteUnit() {
    this.loading = true
    const subscription = this.productService.deleteUnit(this.unit.id).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UNIT_DEL') });
      this.getUnits()
      this.showDeletedUnit = false
      this.showUnit_flabel = false
      this.loading = false
      subscription.unsubscribe();
    }
      , error => {
        this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();

      })
  }
  deleteBrand() {
    this.loading = true
    const subscription = this.productService.deleteBrand(this.brand.id).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('BRAND_DEL') });
      this.getUnits()
      this.showDeleteBrand = false
      this.product.brand = null
      this.showBrand_flabel = false
      this.loading = false
      subscription.unsubscribe();
    }
      , error => {
        this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();

      })
  }
  deletePackageType() {
    this.loading = true
    const subscription = this.productService.deletePackageType(this.packageType.id).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('PACK_DEL') });
      this.getPackageTypes()
      this.showDeletedPackageType = false
      this.showPackageType_flabel = false
      this.product.shelf.package_type = null
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
      this.loading = false;
      subscription.unsubscribe();

    })
  }
  deleteCategory() {
    this.loading = true
    const subscription = this.productService.deleteCat(this.category.id).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('CAT_DEL') });
      this.getCategories()
      this.showDeletedUnit = false
      this.showCAT_flabel = false
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
      this.loading = false;
      subscription.unsubscribe();
    })
  }
  deleteDimentionUnit() {
    this.loading = true;
    const subscription = this.productService.deleteDimentionUnite(this.dimensionUnit.id).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('DEIM_DEL') });
      this.getDimensionUnits()
      this.showDeletedDimensionUnit = false
      this.showDimensionUnit_flabel = true
      this.product.shelf.dimension_unit = null
      this.loading = false;
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
      this.loading = false;
      subscription.unsubscribe();

    }
    )
  }
  canceladddDimentionUnit() {
    this.showDimensionUnit_flabel = false
    this.showAddcdimensionUnit = false
    this.product.shelf.dimension_unit = null
    this.sharedService.dialog_status.next(false)
  }
  canceladddBrand() {
    this.showBrand_flabel = false
    this.showAddBrand = false
    this.product.brand = null
    this.sharedService.dialog_status.next(false)
  }

  cancelCat() {
    this.showCAT_flabel = false
    this.showCAT = false
    this.product.category = null
    this.sharedService.dialog_status.next(false)
  }
  canceldeleteCAt() {
    this.showCAT_flabel = false
    this.showCAT = false
    this.product.category = null
    this.sharedService.dialog_status.next(false)
  }


  canceldeleteDimentionUnit() {
    this.showDimensionUnit_flabel = false
    this.showDeletedDimensionUnit = false
    this.product.shelf.dimension_unit = null
    this.sharedService.dialog_status.next(false)
  }

  cancelDeletePackageType() {
    this.showDeletedPackageType = false
    this.showPackageType_flabel = false
    this.product.shelf.package_type = null
    this.sharedService.dialog_status.next(false)
  }
  canceldeleteUnit() {
    this.showUnit_flabel = false
    this.showDeletedUnit = false
    this.product.advanced.unit = null
    this.sharedService.dialog_status.next(false)
  }
  cancelDeleteBrand() {
    this.showBrand_flabel = false
    this.showDeleteBrand = false
    this.product.brand = null
    this.sharedService.dialog_status.next(false)
  }
  cancelPackageType() {
    this.showPackageType = false
    this.showPackageType_flabel = false
    this.product.shelf.package_type = null
    this.sharedService.dialog_status.next(false)
  }
  cancelUnit() {
    this.showAddUnit = false
    this.showUnit = false
    this.showUnit_flabel = true
    this.product.advanced.unit = null
    this.sharedService.dialog_status.next(false)
  }
  cancelDimentionUnit() {
    this.showDimensionUnit = false
    this.showDimensionUnit_flabel = false
    this.product.shelf.dimension_unit = null
    this.sharedService.dialog_status.next(false)
  }

  updateCat() {
    try {
      this.loading = true
      const subscription = this.productService.updateUnit(this.category).subscribe((cat: Category) => {
        if (!isSet(cat)) {
          return
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showCAT = false
        this.getCategories()
        const lol = setInterval(() => {

          this.product.category = this.cats[this.index_Toupdate]
          if (this.product.category.name == this.cats[this.index_Toupdate]?.name) {
            clearInterval(lol)
          }
        }, 300)
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = true
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = false
      })
    } catch (error) {
      this.loading = false;
    }
  }
  updateBrand() {
    try {
      this.loading = true
      const subscription = this.productService.updateBrand(this.brand).subscribe((brand: Brand) => {
        if (!isSet(brand)) {
          return
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showBrand = false
        this.getBrands()
        const lol = setInterval(() => {

          this.product.brand = this.brands[this.index_Toupdate]
          if (this.product.brand.name == this.brands[this.index_Toupdate]?.name) {
            clearInterval(lol)
          }
        }, 300)
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = true
      }, error => {
        console.log(error);

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = false
      })
    } catch (error) {
      this.loading = false;
    }
  }
  updateUnit() {
    try {
      this.loading = true
      const subscription = this.productService.updateUnit(this.unit).subscribe((unit: Unit) => {
        if (!isSet(unit)) {
          return
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showUnit = false;
        this.getUnits()
        const lol = setInterval(() => {

          this.product.advanced.unit = this.units[this.index_Toupdate]
          if (this.product.advanced.unit?.name == this.units[this.index_Toupdate]?.name) {
            clearInterval(lol)
          }
        }, 300)
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = true
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = false
      })
    } catch (error) {
      this.loading = false;

    }
  }
  updatePackageType() {
    try {
      this.loading = true
      const subscription = this.productService.updatePackageType(this.packageType).subscribe((packageType: PackageType) => {
        if (!isSet(packageType)) {
          return
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showPackageType = false
        this.getPackageTypes()
        const lol = setInterval(() => {

          this.product.shelf.package_type = this.packageTypes[this.index_Toupdate]
          if (this.product.shelf.package_type?.name == this.packageTypes[this.index_Toupdate]?.name) {
            clearInterval(lol)
          }
        }, 100)
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = true


      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = false
      })
    } catch (error) {
      this.loading = false;
      console.log(error);


    }
  }

  updateDimentionUnit() {
    try {
      this.loading = true;
      const subscription = this.productService.updateDimentionUnit(this.dimensionUnit).subscribe((dimentionUnit: DimensionUnits) => {
        if (!isSet(dimentionUnit)) {
          return
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showDimensionUnit = false

        this.getDimensionUnits()
        const y = setInterval(() => {
          this.product.shelf.dimension_unit = this.dimensionUnits[this.index_Toupdate]

          if (this.product.shelf.dimension_unit === this.dimensionUnits[this.index_Toupdate]) {
            clearInterval(y)
          }
        }, 300)
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = true
      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.loading = false;
        subscription.unsubscribe();
        this.productService.upadteFormClicked.next(null)
        this.updateStatus = false
      })



    } catch (error) {
      this.loading = false;
      this.log(error);

    }
  }




  dimensionUnitChanged($event) {
    const dimensionUnit = $event.value as DimensionUnits;

    if (dimensionUnit?.id === '0') {
      this.dimensionUnit = new DimensionUnits()
      this.product.shelf.dimension_unit = new DimensionUnits()

      return this.showAddcdimensionUnit = true

    }
    if (dimensionUnit?.id === '-1') {
      this.dimensionUnit.name = dimensionUnit.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_DIM_INIT') + ')</span>', '')

      this.product.shelf.dimension_unit = new DimensionUnits()

      return this.showAddcdimensionUnit = true

    }
    if (dimensionUnit == null) {

      this.updateStatus = true
    }
  }

  unitChanged($event) {
    const unit = $event.value as Unit
    this.showUnit_flabel = false
    if (unit?.id === '0') {
      this.unit = new Unit()
      this.showAddUnit = true
      this.product.advanced.unit = new Unit()
      this.showUnit_flabel = true

    }
    if (unit?.id === '-1') {
      this.unit.name = unit.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_UNIT') + ')</span>', '')

      this.product.advanced.unit = new Unit()

      return this.showAddUnit = true

    }
    if (unit == null) {

      this.updateStatus = true
    }
  }

  packageTypeChanged($event) {
    const packageType = $event.value as PackageType
    this.showPackageType_flabel = false
    if (packageType?.id === '0') {
      this.packageType = new PackageType()
      this.product.shelf.package_type = new PackageType()
      this.showPackageType_flabel = true
      this.showAddPackageType = true
    }
    if (packageType?.id === '-1') {
      this.packageType.name = packageType.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_PACK') + ')</span>', '')

      this.product.shelf.dimension_unit = new PackageType()

      return this.showAddPackageType = true

    }
    if (packageType == null) {

      this.updateStatus = true
    }
  }
  showDeletePackageType(item: PackageType) {
    this.packageType = item
    this.showDeletedPackageType = true
    this.product.shelf.package_type = null
  }
  showDeleteUnit(item: Unit) {
    this.unit = item
    this.showDeletedUnit = true
    this.product.advanced.unit = null
  }
  showDeleteCAT(item: Category) {
    this.category = Category.cloneObject(item)
    this.showDeletedCAT = true
    this.product.category = null
  }
  showDeleteBrands(item: Brand) {
    this.brand = Brand.cloneObject(item)
    this.showDeleteBrand = true
    this.product.brand = null
  }
  showEditUnit(item: Unit) {
    this.unit = Unit.cloneObject(item)
    this.showUnit = true
    this.unitHeader = this.translate.instant('EDIT_UNIT')
    this.index_Toupdate = this.units.indexOf(item)
  }
  showEditPackageType(item: PackageType) {
    this.packageType = PackageType.cloneObject(item)
    this.PackageTypeHeader = this.translate.instant('EDIT_PACK')
    this.showPackageType = true
    this.index_Toupdate = this.packageTypes.indexOf(item)
  }





  deletefile() {
    this.viewAttachment_diloage = false

    try {

      this.loading = true;
      const subscription = this.productService.delete_File(this.product.attachment).subscribe(() => {
        this.loading = false;
        this.product.seletectAttachment = null
        this.product.attachment = new Attachment()
        this.product.attachment = new Attachment()
        this.sharedService.chekcempey.next(true)
        this.updateStatus = true
        this.updateProduct()
        this.messageService.add({ severity: 'success', summary: this.translate.instant('SECC_PRO_IMG') });
        subscription.unsubscribe();


      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.detail });

        this.loading = false;
        subscription.unsubscribe();
      })
    }
    catch (error) {
      console.log(error)
      this.log(error);
    }
  }
  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }


  myUploader(imageResult: ImageResult) {

    this.fileupload = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;

    const filee = new FormData();
    this.srcToFile(this.fileupload, imageResult.file.name, imageResult.file.type).then((file) => {
      filee.append('file', file)
      if (file.type == 'application/pdf') {
        this.messageService.add({ key: 'formM', severity: 'info', life: 2000, summary: this.translate.instant('INFO'), detail: this.translate.instant('FILE_UPLOAD') });

      }

      const generatUpload = {
        file_type: 'user_report',
        file_path: file.name
      }

      this.productService.generate_Upload(generatUpload)

      this.productService.upload(file)
      try {
        this.loading = true
        const subscription = this.productService.uploadstuatsEmitter.subscribe(status => {
          if (!isSet(status) || !status) {
            return;
          }
          if (status === true) {

            const at = {
              path: this.productService.attachmentlink,
              type: file.type,
              name: this.attachment_name
            }
            this.updateStatus = true
            this.product.attachment = at
            this.messageService.add({
              key: 'form',
              severity: 'success',
              summary: this.translate.instant('SUCCESSFUL'),
              detail: this.translate.instant('UPLOADED_SUCCESS')
            });
            this.attachment_name = null
            this.attachmentnamevaild.control.markAsUntouched()
            this.attachmentnamevaild.control.clearValidators()
            this.loading = false
            this.attachmentdilioge = false

          } else if (status === false) {
            this.messageService.add({
              key: 'form',
              severity: 'error',
              summary: this.translate.instant('SUCCESSFUL'),
              detail: this.translate.instant('NETWORK_ERROR')
            });
            this.loading = false

          }
          this.productService.uploadstuats.next(null)
          subscription.unsubscribe()
        }, error => {
          this.loading = false
          this.handleError(error);
          subscription.unsubscribe()

        });
      } catch (error) {
        console.log(error);
      }
    })
  }

  _upload(imageResult: ImageResult) {
    if (imageResult.error) alert(imageResult.error);
    const image = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;



    this.loading = true;
    const filee = new FormData();
    this.srcToFile(image, imageResult.file.name, imageResult.file.type).then((file) => {

      filee.append('file', file)
      // this.myUploader(file)


    })
  }
  // showViewReceipt() {
  //   this.generate_download()

  // }
  generate_download(seletectedAttachment) {
    this.product.seletectAttachment = seletectedAttachment
    try {
      this.loading = true

      const subscription = this.productService.generate_download(this.product.seletectAttachment.path).subscribe(link => {
        this.product.download_url = link?.ldownload_url
        this.product.view_url = link?.view_url
        this.fileViewer = true
        this.viewAttachment_diloage = true
        setTimeout(() => {
          this.loading = false

        }, 200);

        subscription.unsubscribe()
      }, error => {
        this.loading = false
        this.handleError(error);
        subscription.unsubscribe()

      })
    } catch (error) {
      this.log(error);
    }

  }
  downloadlink() {
    window.location.href = this.product.view_url;
  }
  showUploader() {
    if (!isSet(this.attachment_name)) {
      this.attachmentnamevaild.control.markAsTouched();
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERROR'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    }
    this.upload.nativeElement.click()

  }
}

