<app-loading *ngIf="loading"></app-loading>
<p-toast></p-toast>
<div class="login-body layout-light" >
  <div class="login-content">
    <div class="login-panel">
      <div style="padding-bottom:18%" class="login-panel-content">
        <div class="logo">
          <img src="assets/img/logo7.png" alt="mirage-layout" />
        </div>
        <!-- loginPage -->
        <div *ngIf="showEmailDialog == false">
          <h1>{{"LOGIN_TITLE" | translate}}</h1>
          <p>{{"NO_ACCOUNT" | translate}} ? <a id="test" style="font-weight: 600;" [routerLink]="['/auth/register']"
              routerLinkActive="router-link-active">{{"SING_UP" | translate}} </a></p>

          <div class="forms">
            <div class="login-input-wrapper">
              <label for=" login-email">{{"LOGIN_EMAIL" | translate}}</label>
              <input #emailInput (keyup.enter)="onKeyUp($event)" id=" login-email" [(ngModel)]="email" pInputText placeholder="{{'LOGIN_EMAIL' | translate}}" />
              <i class="pi pi-user"></i>
            </div>

            <div class="login-input-wrapper">
              <label for=" login-password">{{"LOGIN_PASSWORD" | translate}}</label>
              <input (keyup.enter)="onKeyUp($event)" type="password" [(ngModel)]="password" id="login-password" placeholder="{{'LOGIN_PASSWORD' | translate}}"
                pPassword [feedback]="false" #Password />
                <i *ngIf="!showPassword" (click)="showpassword()" class="fas fa-eye-slash p-mt-2"></i>
                <i *ngIf="showPassword" (click)="hidepassword()" class="fas fa-eye  p-mt-2"></i>
            </div>

            <div class="p-col-12">
              <p-checkbox (keyup.enter)="onKeyUp($event)" [(ngModel)]="rememberMe" label="{{'LOGIN_REMEBER_ME' | translate}}" binary="true">
              </p-checkbox>
            </div>

            <div class="buttons ">
              <app-sqx-button (click)='login()'   label="{{'LOGIN_BUTTON' | translate}}"></app-sqx-button>
              <a (click)='sendEmailDailog()' style="cursor:pointer" class="secondary-button p-mt-5">{{"LOGIN_FORGET" |
                translate}}</a>
            </div>

          </div>
        </div>
        <!-- Send Email-->
        <div class="sendEmail" *ngIf="showEmailDialog == true">
          <h1 for="resetEmail">Rest Password</h1>
          <p>Please enter your Registered email address</p>
          <div class="login-input-wrapper p-pt-3">
            <span class="p-float-label p-mt-4 p-mb-4">

              <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" type="email" #Email="ngModel"
                id="resetEmail" [(ngModel)]="resetEmail" pInputText />
              <label for="resetEmail">{{'LOGIN_EMAIL' | translate}}</label>

            </span>

            <div class="buttons">
              <p-button class="p-button-secondary" label="Rest Password" (click)='sendEmail()'></p-button>

              <a (click)='showEmailDialog = false' style="cursor:pointer" class="secondary-button p-mt-5">Back to Sign
                in</a>

            </div>

          </div>
        </div>
        <!-- Reset Password -->
        <!-- <div>
      <div class="login-input-wrapper" style="padding-top: 50px;">
        <h3  >Reset Password</h3>
        <input type="password" [(ngModel)]="password" id="password" placeholder="New Password" pPassword [feedback]="false"/>
        

      </div>
      <div class="login-input-wrapper" style="padding-top: 20px;">
        
        <input type="password" [(ngModel)]="password" id="password" placeholder="Confirm Password" pPassword [feedback]="false"/>
       
      </div>
      <div class="buttons">
        <p-button  label="Rest" ></p-button> 
      </div>
    </div> -->
      </div>
    </div>

    <div class="desert"></div>
  </div>
</div>


<!-- <p-dialog modal="true" appendTo="body" [resizable]="false" [draggable]="false" class="p-shadow-1"
  header='For an optimal experience' [(visible)]="mobileView">

  <div class="p-grid">

    <div class="p-col-12">
      <div style="line-height:1.4rem;" class=" h6">For an optimal experience on mobile devices, we recommend to use the mobile application available for IOS and Android .</div>
    </div>
    <div class="p-col-12">
      <div class="row">
        <div style="text-align: right;" class="p-col">
          <img height="37px" src="assets/layout/images/Download_on_the_App_Store.svg" alt="">
        </div>
        <div class="p-col">
          <img width="120px;" src="assets/layout/images/Google_Play_Store_badge_EN.svg" alt="">

        </div>

      </div>

    </div>
  </div>

</p-dialog> -->