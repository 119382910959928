<div  style="position: absolute;" class="layout-breadcrumb">
  <div class="breadcrumb">
    <div class="route-bar-breadcrumb">
      <ng-template ngFor let-item let-last="last" [ngForOf]="items">
        <li>
          <a>{{item.label | uppercase|translate}}</a>
        </li>
        <li *ngIf="!last"><i class="pi pi-angle-right"></i></li>
      </ng-template>
    </div>
  </div>

  <div class="notification"></div>
</div>
