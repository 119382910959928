import { Contact } from './contact';
import { CustomerDetails } from './customerdetails';
import { ExtraFields } from './extrafields';
import { Address } from './address';
import { CustomerPricing } from './customerpricing';
import { isSet } from '../modules/core/util';
import { Attachment } from './Attachment';
import { Region } from './region';

export class Customer {
  id: string;
  vat?: string;
  contact?: Contact[];
  payment_details?: CustomerDetails;
  created_at?: Date;
  updated_at?: Date;
  company?: number;
  region?:Region;
  company_name?: string;
  attachments: Attachment[];
  main_info_extra_fields?: ExtraFields[];
  shipping_address?: Address[];
  remark?: string;
  invoicing_address?: Address;
  pricing?: CustomerPricing;
  firstcontact?: Contact
  due: number;
  credit_notes: number;
  linktoview: string
  view_url : string;
  download_url:string
  seletectAttachment: Attachment;
  cn_consumed:number;
  cn_remained:number;
  cn_payments:number;
  order_payments:number;
  orders:number;

  public constructor(params?: Customer) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Customer) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new Customer(objectToClone);
    if (isSet(obj.contact)) {
      obj.contact = Contact.cloneManyObjects(objectToClone.contact);
    }
    if (isSet(obj.payment_details)) {
      obj.payment_details = CustomerDetails.cloneObject(objectToClone.payment_details);
    }
    if (isSet(obj.main_info_extra_fields)) {
      obj.main_info_extra_fields = ExtraFields.cloneManyObjects(objectToClone.main_info_extra_fields);
    }
    if (isSet(obj.shipping_address)) {
      obj.shipping_address = Address.cloneManyObjects(objectToClone.shipping_address);
    }
    if (isSet(obj.invoicing_address)) {
      obj.invoicing_address = Address.cloneObject(objectToClone.invoicing_address);
    }
    obj.firstcontact = Contact.cloneObject(objectToClone.firstcontact);

    if (isSet(obj.pricing)) {
      obj.pricing = CustomerPricing.cloneObject(objectToClone.pricing);
    }
    if (isSet(obj.attachments)) {
      obj.attachments = Attachment.cloneManyObjects(objectToClone.attachments);
    }
    return obj;
  }

  public static cloneManyObjects(customersToClone: Customer[]) {
    const customers: Customer[] = [];
    for (const customer of customersToClone) {
      customers.push(Customer.cloneObject(customer));
    }

    return customers;
  }

  public toDB() {
    this.contact.map(item=>{
      if(!isSet( item.GSM )){
        delete item.GSM;       
      }
      if(!isSet( item.fax )){
        delete item.fax;       
      }
      if(!isSet( item.note )){
        delete item.note;       
      }
    })
    return {
      vat: this.vat,
      contact: this.contact,
      payment_details: {
        credit_limit: this.payment_details?.credit_limit,
        account_manager: this.payment_details?.account_manager?.id,
        account_number: this.payment_details?.account_number,
        BIC: this.payment_details?.BIC,
        Language: this.payment_details?.Language,
        payment_terms: this.payment_details?.payment_terms,
        extra_fields: this.payment_details?.extra_fields,
        IBAN:this.payment_details?.IBAN
      },
      company: this.company,
      company_name: this.company_name,
      main_info_extra_fields: this.main_info_extra_fields,
      region:this.region?.id,
      shipping_address: this.shipping_address,
      remark: this.remark,
      invoicing_address: this.invoicing_address,
      pricing: {
        discount: this.pricing?.discount,
        special_price: this.pricing.special_price?.id,
        category: this.pricing.category?.id,
        invoicing_frequency: this.pricing?.invoicing_frequency,
      },
      attachments: this.attachments
    };
  }

  public toMiniDB() {
    return {
      payment_details: {
        credit_limit: this.payment_details?.credit_limit,
      },
      company_name: this.company_name,
      vat: this.vat,
      invoicing_address: this.invoicing_address,
      contact: this.contact

    };
  }
}
