import { isSet } from '../../modules/core/util';
import { Customer } from '../customer';
import { OrderItem } from './orderitem';
import * as _ from 'lodash';
import { OrderStatus } from './orderstatus';
import { Contact } from '../contact';
import { Address } from '../address';
import { Payment } from './Payment';
import { Currency } from '../currency';

export class Order {
  id: string;
  customer: Customer;
  company_name: string;
  format: string;
  vcs: string;
  date: Date;
  due_date: Date
  items: OrderItem[];
  total: number;
  sub_total: number;
  total_vat: number;
  due: number;
  phone: number;
  discount: any;
  discountType: any;
  status: OrderStatus;
  terms: string;
  notes: string;
  payment_terms: string;
  payments: Payment[];
  newPayments: Payment[];
  invoice_format: string;
  invoice_date: Date;
  invoiced: boolean;
  idQuoteConvert: string
  downloadUrl: string
  viewUrl: string
  loadPrint: boolean
  currency: Currency
  duplicate: boolean
  exchange_rate: number
  exchangeRateStauts: boolean
  paidVats: Array<{
    name?: number;
    beforeDiscount?: number;
    afterDiscount: number;
  }>
  whatsappUrl: string
  paymentDue: any
  get customerDue() {
    if (!isSet(this.customer?.id)) {
      return 0
    }

    return this.customer?.due +this.paymentDue || 0
  }
  get orderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'totalNet') || 0
  }
  get beforeDiscountOrderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_totalNet') || 0
  }

  get orderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.orderTotal + this.orderTotalVAT || 0
  }
  get beforeDiscountOrderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'beforediscont_total') || 0
  }

  get discountPercentage() {
    if (this.discountType == 'Percent') {

      return (this.discount / 100)
    }
    const discount = (this.discount / _.sumBy(this.items, 'beforediscont_totalNet'))
    //*this.exchange_rate
    return discount
  }

  get orderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'vatAmount') || 0
  }

  get beforeDiscountOrderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'beforediscont_vatAmount') || 0
  }

  get defaultCurrencyOrderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_vatAmount') || 0
  }
  get defaultCurrencyOrderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_totalNet') || 0
  }
  get defaultCurrencyOrderTotalIncVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return this.defaultCurrencyOrderTotal + this.defaultCurrencyOrderTotalVAT || 0
  }

  get defaultCurrencyBeforeDiscontTotalInc() {
    if (!this.items || !this.items.length) {
      return 0;
    }
    return _.sumBy(this.items, 'default_currncey_beforediscont_total') || 0
  }
  get defaultCurrencyBeforeDiscountOrderTotal() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_totalNet') || 0
  }
  get defaultCurrencyBeforeDiscountOrderTotalVAT() {
    if (!this.items || !this.items.length) {
      return 0;
    }

    return _.sumBy(this.items, 'default_currncey_beforediscont_vatAmount') || 0
  }
  public constructor(params?: Order) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Order) {
    if (!isSet(objectToClone)) {
      return new Order();
    }
    const obj = new Order(objectToClone);
    obj.customer = Customer.cloneObject(objectToClone.customer);
    if (objectToClone.items) {
      obj.items = OrderItem.cloneManyObjects(objectToClone.items);
    }
    if (isSet(objectToClone.payments)) {
      obj.payments = Payment.cloneManyObjects(objectToClone.payments)
    }
    if (isSet(objectToClone.newPayments)) {
      obj.newPayments = Payment.cloneManyObjects(objectToClone.newPayments)
    }

    obj.status = OrderStatus.cloneObject(objectToClone.status);
    return obj;
  }

  public static cloneManyObjects(objectsToClone: Order[]) {
    const objects: Order[] = [];
    for (const obj of objectsToClone) {
      objects.push(Order.cloneObject(obj));
    }

    return objects;
  }

  public toDB(orderDate, dueDate, discont) {

    return {
      format: this.format,
      date: orderDate,
      due_date: dueDate,
      customer: this.customer?.id,
      terms: this.terms,
      invoice_number: 1,
      notes: this.notes,
      currency: this.currency?.id,
      exchange_rate: this.exchange_rate,
      total: this.orderTotal,
      total_vat: this.orderTotalVAT,
      due: this.due,
      discount: discont ?? 0,
      billing_email: this.contact()?.email,
      payment_terms: this.payment_terms,
      payments: this.payments,
      items: _.map(this.items, item => {
        return {
          product: item.product?.id,
          company: item.product?.company,
          name: item?.name,
          qty: item?.qty,
          qtyType: item?.qtyType,
          price: item?.price,
          vat: item?.vat?.id,
          date: item?.date,
          price_type: item?.price_type,
          service: item?.service
        }
      }),
      shipping_address: this.duplicate ? null :
        {
          address: this.shipping_address()?.address,
          zip: this.shipping_address()?.zip,
          city: this.shipping_address()?.city,
          country_code: this.shipping_address()?.country_code
        }
      ,
      phone: this.duplicate ? null : this.contact()?.phone,
      status: this.status
    }
  }

  contact() {
    let contact = new Contact()
    if (isSet(this.customer?.contact)) {
      contact = this.customer?.contact[0]
    }
    return contact
  }
  shipping_address() {
    let shippingAddress = new Address()
    if (isSet(this.customer?.shipping_address)) {
      shippingAddress = this.customer?.shipping_address[0]
    }
    return shippingAddress
  }

}
