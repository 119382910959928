import { SpecialPrice } from './specialprice';
import { PricingCategory } from './pricingcategory';
import { isSet } from '../modules/core/util';

export class CustomerPricing {
  discount: number;
  special_price: SpecialPrice;
  category: PricingCategory;
  invoicing_frequency: number;

  public constructor(params?: CustomerPricing) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: CustomerPricing) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new CustomerPricing(objectToClone);
    obj.special_price = SpecialPrice.cloneObject(objectToClone.special_price);
    obj.category = PricingCategory.cloneObject(objectToClone.category);

    return obj;
  }

  public static cloneManyObjects(objectsToClone: CustomerPricing[]) {
    const objects: CustomerPricing[] = [];
    for (const obj of objectsToClone) {
      objects.push(CustomerPricing.cloneObject(obj));
    }

    return objects;
  }
}
