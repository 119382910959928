import { isSet } from '../modules/core/util';

export class Query {
  type: string;
  value: string;

  public constructor(params?: Query) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: Query) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    return new Query(objectToClone);
  }

  public static cloneManyObjects(objectsToClone: Query[]) {
    const objects: Query[] = [];
    for (const obj of objectsToClone) {
      objects.push(Query.cloneObject(obj));
    }

    return objects;
  }
}
