<p-toast appendTo="body" position="top-right"></p-toast>
<div id="selectSupplier">
  <p-dropdown #dd [(ngModel)]="payer" name="payer" [options]="filteredPayers" [showClear]="true"
    placeholder='{{"SEL_PAYER" | translate}}' optionLabel="name" (onFocus)="onFocus()" 
    #nameS="ngModel" (onChange)="payerChanged($event)" (onHide)="hide($event)"  filter="true">
    <ng-template let-item pTemplate="item">
      <span [innerHTML]="item.name"></span>
    </ng-template>
  </p-dropdown>

  <p-dialog (onShow)="outside_Status(true)" [resizable]="false" [draggable]="false" *ngIf="displayAddpayer"
    header="Add Payer" [(visible)]="displayAddpayer" [style]="{width: '25vw'}" closeIcon="false"
    (onHide)="closeminiPayerModal()">

    <app-loading *ngIf="addPayerLoading"></app-loading>

    <div class="p-grid form-group p-mt-2">
      <div class="p-col-12 mt-4">
        <span class="p-float-label">
          <input required #payer_name="ngModel" (ngModelChange)="notempty($event)" [(ngModel)]="miniPayer.name"
            id="company-name-input" type="text" pInputText>
          <label for="company-name-input">{{"CUSTOMER_FORM_NAME" | translate}}&nbsp;

            <label
              *ngIf="((miniPayer.name == '' || miniPayer.name ==null) && payer_name.touched ) || payer_nametouched==true  "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>
          </label>
        </span>
      </div>

    </div>

    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="addminiPayer()"
      label='{{"CUSTOMER_LIST_SAVE" | translate}}'></button>

      <button type="button" pButton icon="pi pi-times" (click)="closeminiPayerModal()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class="p-button-outlined"></button>

    </p-footer>
  </p-dialog>