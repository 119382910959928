import { Component, OnInit } from '@angular/core';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Contact } from '../../../models/contact';
import { SharedService } from '../../shared/shared.service';
import { Tax } from 'src/app/models/Tax';
import { TaxService } from '../Tax.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-Tax-list',
  templateUrl:'./Tax-list.component.html',
  styleUrls: ['./Tax-list.component.scss'],
  providers: [MessageService]

})
export class TaxListComponent extends BaseComponent implements OnInit {

  public selected: Tax[] = [];
  public Tax: Tax[] = [];
  public filteredProducts: Tax[] = [];
  itemSearch:string
  keyword="name"
  public showSidebar = false;
  public size = SidebarSize.X_X_LARGE;
  public currentModal
  public modalTitle = 'Add new Tax';
  public selectedTax;
  SeletedTax : Tax[] = [];
  public RemovedialogueS = false;
  constructor( private router:Router,private TaxService: TaxService , public translate:TranslateService, private SharedService : SharedService, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.getTaxes();
this.subscribeOnTaxRefresh()



  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.modalTitle = this.selectedTax ? `Edit ${this.selectedTax.name}` : 'Add new Tax';
        break;
     }
  }

  public showtax(Tax) {
    
    this.showSidebar = true;

    this.selectedTax = Tax;
    this.TaxService.selectTax(Tax);
    this.switchModal('Form');

  }

  public showAddTax() {
    this.showSidebar = true;

    this.TaxService.selectTax(null);
    this.selectedTax = null;
    this.TaxService.currentTax = null;
    this.switchModal('Form');


  }

  public saveForm() {
    this.TaxService.saveForm();
  }

  serach(e){
    if(!isSet(e)){
      return
    }
    this.Tax = e
  
  
  }

  private subscribeOnTaxRefresh() {
    const subscription = this.TaxService.refreshTaxListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getTaxes();
      }
    });
    this.subscriptions.push(subscription);
  }

  private getTaxes() {

    this.TaxService.getTaxes().subscribe((data: PaginatedResponse<Tax[]>) => {
      if (!data) {
        return;
      }
      this.Tax = data.results;
      this.Tax.map(e=>{
        this.filteredProducts.push(e)
        this.loading =false

      })

    }, error => {
      this.loading =false
      this.handleError(error);
      this.messageService.add({key: 'list',severity: 'error', summary: 'Network Error', detail: error.error.detail});
      this.loading =false

    });
  }
  seletedTax(Tax){
    if(this.SeletedTax.includes(Tax) == false){
      this.SeletedTax.push(Tax)
    }else{
      this.SeletedTax= this.SeletedTax.filter(item => item !== Tax)

    }
  }
  RemoveTax(){

    try{
      this.SeletedTax.map(item=>{
        this.loading = true;
        const subscription = this.TaxService.deleteTax(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          this.loading = false;
          this.messageService.add({key: 'list',severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: item.name +' Deleteded'});
          this.TaxService.refreshTaxes();
          ;
          
          subscription.unsubscribe();


        }, error => {
          if (isSet(item)) {
            this.messageService.add({key: 'list',severity: 'error', summary: this.translate.instant('ERROR'), detail: 'An error occurred while Delete '+item.name});
          }
          this.loading = false;
          subscription.unsubscribe();
        })

        this.SeletedTax = null
      })
      
      

    
    }       
    catch (error) {
      console.log(error)
      this.log(error);
    }
 
    this.RemovedialogueS=false

  }
  Reload(){
    const currentRoute = this.router.url;


      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); 
    
    });
      

  }
 
  outside_Status(status:boolean){
    this.SharedService.dialog_status.next(status)
  } 
  
}
