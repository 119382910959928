import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import * as _ from 'lodash';
import { ApiService } from '../../core/api.service';
import { Dropdown } from 'primeng/dropdown';
import { SharedService } from '../shared.service';
import { CreditNote } from 'src/app/models/CreditNotesSupplier';
import { PurchaseOrdersService } from '../../PurchaseOrders/PurchaseOrders.service';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';

@Component({
  selector: 'app-purchase-select',
  templateUrl: './purchase-select.component.html',
  styleUrls: ['./purchase-select.component.scss']
})
export class PurchaseSelectComponent implements OnInit {

  
  @ViewChild('dd') dd: Dropdown;
  
  @Input() order: PurchaseOrders;
  @Output() purchaseOrderChange = new EventEmitter<CreditNote>();
  @Output() onHide = new EventEmitter<any>();

  addSupplierLoading: boolean;
  displayAddSupplier: boolean;
  vatBtnDisabled: boolean;s
  CreditNote :CreditNote 
  PurchaseOrders: PurchaseOrders[] = [];
  filteredPurchaseOrders: PurchaseOrders[] = [];
  public loading : boolean
  
  constructor(private PurchaseOrdersService: PurchaseOrdersService, private api: ApiService,private SharedService : SharedService) {
  }


  
  ngOnInit(): void {
    
    Dropdown.prototype.onInputFocus = function (event) {
      setTimeout(() => {
          this.focused = true;
          this.onFocus.emit(event);
      });
      };
      Dropdown.prototype.onInputBlur = function (event) {
          setTimeout(() => {
          this.focused = false;
          this.onModelTouched();
          this.onBlur.emit(event);
       });
      };

    this.getVendors();

  }

  OrdersChanged($event) {
    const PurchaseOrder = $event.value as PurchaseOrders;
    if (!isSet(PurchaseOrder)) {
      return;
    }

    if (PurchaseOrder.id === '-1') {
      return;
    }

this.SharedService.chekcempey.next(true)
this.loading= true
this.PurchaseOrdersService.getorderbyname(PurchaseOrder.internal_reference).subscribe((purchaseOrder:PurchaseOrders) => {
  if (!isSet(purchaseOrder)) {
    return;
  }
  this.CreditNote=new CreditNote()
  this.CreditNote.vendor = purchaseOrder.vendor
  this.CreditNote.order_format = purchaseOrder.internal_reference
  this.CreditNote.items = purchaseOrder.items
    this.purchaseOrderChange.emit( this.CreditNote );
    this.loading=false
})

  }
  closeMiniSupplierModal() {
    this.order = null;
    this.displayAddSupplier = false;
  }

 



  hide($event) {
    if (this.order.id !== '-1') {
      this.onHide.emit($event);
    }
  }

 

  private getVendors(): void {
    const subscription = this.PurchaseOrdersService.getPurchaseOrders().subscribe((purchaseOrders: PaginatedResponse<PurchaseOrders[]>) => {
      if (!isSet(purchaseOrders)) {
        return;
      }

      this.PurchaseOrders = PurchaseOrders.cloneManyObjects(purchaseOrders.results);

      this.filteredPurchaseOrders = this.PurchaseOrders;
      subscription.unsubscribe();
    }, error => {
      subscription.unsubscribe();
    });
  }


}
