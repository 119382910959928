import { Component, HostListener, OnInit } from '@angular/core';
import { Customer } from '../../../models/customer';
import { CustomerService } from '../customer.service';
import { SidebarSize } from '../../shared/enums/sidebarSize';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Query } from 'src/app/models/query';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss'],
  providers: [MessageService]

})
export class CustomersListComponent extends BaseComponent implements OnInit {

  public selected: Customer[] = [];
  public customers: Customer[] = [];
  public filteredCustomers: Customer[] = [];
  public showSidebar = false;
  public size: SidebarSize;
  public currentModal ;
  public modalTitle = 'Add new customer';
  public selectedCustomer: Customer;
  seletedCustomers: Customer[] = [];
  public Removedialoguee = false;
  itemSearch: string
  keyword: string = 'company_name'
  header_body: string

  customers_inPage: number = 0
  customersCount: any
  customerNextPage: any = 0
  pageViwed = []
  total_pages = 0
  customerCurrentnumber: any
  customerPrevPage: any
  currency = JSON.parse(localStorage.getItem('companySettings'))?.currency

  selected_Customerrquery = new Query()
  filterModeVat_query = new Query()
  filterModeContactName_query = new Query()
  filterModeContactEmail_query = new Query()
  filterModeContactPhone_query = new Query()
  filterModeDue_query = new Query()
  filterModeCredit_notes_query = new Query()
  filterModeRegion_query = new Query()
  seletedquery_type: string

  sortMode: number = 1
  query_types = [
    {
      type: 'StartsWith'
    },
    {
      type: 'Equal'
    },
    {
      type: 'Contains'
    },
    {
      type: 'EndsWith'
    },


  ]
  query_types2 = [
    {
      type: 'Not Equal',
      value: 'ne'
    },
    {
      type: 'Equal',
      value: 'exact'
    },
    {
      type: 'Less than', value: 'lt'

    },
    {
      type: 'Grater than', value: 'gt'

    },


  ]
  constructor(private customersService: CustomerService,
    private sharedService: SharedService, private messageService: MessageService,
    private router: Router,
    public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.getCustomers()
    this.subscribeOnCustomersRefresh();
    this.showSaveCustomer()
  }

  public switchModal(modal: string): void {
    switch (modal) {
      case 'Form':
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Form';
        this.modalTitle = this.selectedCustomer ? this.translate.instant('CUSTOMER_PROF') : this.translate.instant('CUSTOMER_FORM_ADD_CUSTOMER');
        this.header_body = this.selectedCustomer ? ` ${this.selectedCustomer.company_name}` : null;
        break;
      case 'Dashboard':
        setTimeout(() => {
          this.customersService.selectCustomer(this.selectedCustomer);
         
        }, 300);
        this.size = SidebarSize.X_LARGE;
        this.currentModal = 'Dashboard';
        this.modalTitle = this.translate.instant('CUSTOMER_DASH');
        this.header_body = this.selectedCustomer.company_name;
        break;
    }
  }

  public showCustomer(customer) {
    this.sharedService.Create_form(null)
    this.selectedCustomer = customer;
    this.customersService.selectCustomer(customer);
    this.sharedService.update_form.next(false)

    this.switchModal('Form');
    this.showSidebar = true;
  }
  showSaveCustomer() {
    const subscription = this.customersService.showaftersaveFormdEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return
      }
      if (status == true) {
        setTimeout(() => {
          this.sharedService.Create_form(null)
          this.selectedCustomer = this.customers[0];
          this.switchModal('Form');
        }, 2000);
      }
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);

  }
  public showAddCustomer() {
      this.customersService.selectCustomer(null);
    this.sharedService.Create_form(true)
    this.selectedCustomer = null;
    this.customersService.createClicke.next(true)
    this.switchModal('Form');
    this.showSidebar = true;
  }

  public saveForm() { this.customersService.saveForm(); }

  serach(e) {
    if (!isSet(e)) {
      return
    }
    this.customers = e
  }

  completeMethod(serach_item) {
    this.customersService.queris = []
    this.getCustomer_queries('company_name', 'startswith', serach_item)
  }
  private subscribeOnCustomersRefresh() {
    const subscription = this.customersService.refreshCustomersListEmitter.subscribe((status: boolean) => {
      if (isSet(status) && status) {
        this.getCustomers();
      }
    });

    this.subscriptions.push(subscription);
  }

  private getCustomers() {
    this.loading = true

    const subscription = this.customersService.getCustomers().subscribe((customers: PaginatedResponse<Customer[]>) => {
      if (!customers) {
        return;
      }
      this.pageViwed.push(1)
      this.customersCount = customers.count
      this.total_pages = customers.total_pages
      this.customers = Array(this.customersCount).fill(0)
      if (customers.links.next != null) {
        let url_string = customers.links.next
        let url = new URL(url_string);
        this.customerNextPage = url.searchParams.get("page")
      } else {
        this.customerNextPage = null
      }
      const clone = Customer.cloneManyObjects(customers.results);
 
      
      clone.map(cust => {
        if (cust?.contact) {
          if (isSet(cust?.contact[0])) {
            cust.firstcontact = cust?.contact[0]
          }
        }
        cust.cn_remained = cust.credit_notes - (cust.cn_consumed + cust.cn_payments)
      })
      this.customerCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.customers[index] = clone[index];
      }
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false

    });

  }
  getNextCustomerPage(event) {
    let row_status = false
    if (event.rows == 10) {
      this.customers_inPage = event.first
    } else {
      this.customers_inPage = event.rows + event.first
      row_status = true
    }
    if (this.pageViwed == this.customersCount) {
      return
    }
    const num = (this.customers_inPage / 30)
    if (event.rows == 10) {
      if (num % 1 != 0) {
        this.customerNextPage = Math.round(num) + 1
      } else if (num % 1 == 0) {
        this.customerNextPage = num + 1
      }
      if (this.customerNextPage > this.total_pages) {
        this.customerNextPage = this.total_pages
      }
    }
    if (event.rows == 30) {
      this.customerNextPage = this.customers_inPage / 30
    }
    if (this.customers_inPage >= this.customerCurrentnumber) {
      if (this.customerNextPage == this.customerPrevPage) {
        this.customerNextPage = this.customerNextPage - 1
        this.customerPrevPage = this.customerNextPage
      }
      this.customerPrevPage = this.customerNextPage
      if (this.pageViwed.includes(this.customerNextPage)) {
        return
      } else {
        this.loading = true
        const subscription = this.customersService.getNextpageCustomers(this.customerNextPage).subscribe((customers: PaginatedResponse<Customer[]>) => {
          if (!isSet(customers)) {
            return;
          }
          const clone = Customer.cloneManyObjects(customers.results);
          clone.map(cust => {
            if (cust?.contact) {
              if (isSet(cust?.contact[0])) {
                cust.firstcontact = cust?.contact[0]
              }
            }
          })
          const shown = this.customers_inPage
          this.customers_inPage = (this.customerNextPage - 1) * 30
          if (this.customers_inPage > shown && this.customerNextPage == this.customerPrevPage) {
            this.customers_inPage = this.customers_inPage
          }
          for (let index = 0; index < clone.length; index++) {
            this.customers[this.customers_inPage + index] = clone[index];
          }
          this.pageViwed.push(this.customerNextPage)
          this.loading = false;
          subscription.unsubscribe();
        }, error => {
          this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
          subscription.unsubscribe();
          this.loading = false;

        });
      }
    }
  }
  private getCustomer(id: string) {
    this.loading = true
    const subscription = this.customersService.getCustomer(id).subscribe((customer: Customer) => {
      if (!isSet(customer)) {
        return;
      }
     
      
      this.showCustomer(customer)
      this.sharedService.diplayDash.next(null)
      this.loading = false
      subscription.unsubscribe();
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false
    });
  }
  seletedCustomer(Customer) {
    if (this.seletedCustomers.includes(Customer.id) == false) {
      this.seletedCustomers.push(Customer)
    } else {
      this.seletedCustomers = this.seletedCustomers.filter(item => item.id !== Customer)
    }
  }
  upadteFormClicked() { this.customersService.upadteFormClicked.next(true) }
  RemoveCustomer() {
    try {
      this.loading = true;
      let index = 0
      let length = this.seletedCustomers.length

      this.seletedCustomers.map(item => {
        const subscription = this.customersService.DeleteCustomer(item.id).subscribe(() => {
          if (!isSet(item)) {
            return;
          }
          index += 1

          if (length < 3) {
            this.customersService.refreshCustomers();
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: item.company_name + this.translate.instant('DELETED') });

          } else if (index == length) {
            this.customersService.refreshCustomers();
            this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant(this.translate.instant('SUCCESSFUL')), detail: 'Selected customers deleted' });
          }
          subscription.unsubscribe();

        }, error => {
          if (isSet(item)) {
            if (error.error.detail == 'Could not delete document (Order.customer refers to it)' || error.error.detail == 'Could not delete document (Quote.customer refers to it)') {
              return this.messageService.add({ key: 'list', severity: 'error', life: 4000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('CANT_DELETE_CUSTOMER') });
            }
            this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
          }
          this.loading = false;
          subscription.unsubscribe();
          this.customersService.refreshCustomers();
        })
      })
      this.loading = false;
      this.seletedCustomers = []
      this.selected = []
    }
    catch (error) {
      this.log(error);
    }
    this.Removedialoguee = false
  }

  remoe() {
    if (this.seletedCustomers.length !== 0) {
      return this.Removedialoguee = true
    }
  }
  Reload() {
    this.customersService.queris = []
    this.customersService.sort = null
    this.loading = true
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }

  deleteRow(selected) { this.seletedCustomers = selected }
  showCustomerFromDash() {
    let subscription = this.sharedService.diplayitemEmitter.subscribe((customer: any) => {
      if (!isSet(customer)) {
        return;
      }
      this.getCustomer(customer?.id)
      this.subscriptions.push(subscription);
    }, error => {
      this.handleError(error);
      console.log(error);
    });
  }

  getCustomer_queries(query_name, query_type, query_value) {
    this.loading = true;

    if (query_type == 'Equal') {
      query_type = 'exact'
    }
    let re = false
    this.customersService.queris.map((query, index) => {
      if (query.query_name == query_name) {
        query.query_value = query_value
        query.query_type = query_type?.toLocaleLowerCase()
        re = true
      }
    })
    if (re == false) {


      this.customersService.queris.push({
        query_name: query_name,
        query_type: query_type?.toLocaleLowerCase(),
        query_value: query_value
      })
    }



    this.customersCount = 0
    const subscription = this.customersService.getCustomers().subscribe((customers: PaginatedResponse<Customer[]>) => {
      if (!isSet(customers)) {
        return;
      }
      this.customerNextPage = 0
      this.pageViwed = []
      this.pageViwed.push(1)
      this.customersCount = customers.count
      this.total_pages = customers.total_pages
      this.customers = Array(this.customersCount).fill(0)


      const clone = Customer.cloneManyObjects(customers.results);
      clone.map(e => {
        if (e?.contact) {
          if (isSet(e?.contact[0])) {
            e.firstcontact = e?.contact[0]
          }
        }
        e.cn_remained = e.credit_notes - (e.cn_consumed + e.cn_payments)
         this.filteredCustomers.push(e)
     })

      this.customerCurrentnumber = clone.length
      for (let index = 0; index < clone.length; index++) {
        this.customers[index] = clone[index];

      }



      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();
      this.loading = false;

    });

  }
  clearCustomers_queries() {
    this.customersService.queris = []
    this.seletedquery_type = null
    this.selected_Customerrquery = new Query()
    this.filterModeVat_query = new Query()
    this.filterModeContactName_query = new Query()
    this.filterModeContactEmail_query = new Query()
    this.filterModeContactPhone_query = new Query()
    this.filterModeDue_query = new Query()
    this.filterModeCredit_notes_query = new Query()
    this.filterModeRegion_query= new Query()
    this.getCustomers()
  }
  customSort(filed: string) {

    if (this.sortMode == 1) {
      this.sortMode = -1
      this.customersService.sort = filed
      return this.getCustomers()
    } else if (this.sortMode == -1) {
      this.sortMode = 1
      this.customersService.sort = '-' + filed
      return this.getCustomers()
    }


  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event){
    if(event.key=="Escape"){
      this.showSidebar=false
    }
  }
}
