import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpenseListComponent } from './expense-list/expense-list.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InplaceModule } from 'primeng/inplace';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import {ChipsModule} from 'primeng/chips';
import {CheckboxModule} from 'primeng/checkbox';
import {FileUploadModule} from 'primeng/fileupload';
import {EditorModule} from 'primeng/editor';
import {TooltipModule} from 'primeng/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import {InputNumberModule} from 'primeng/inputnumber';
import {TabViewModule} from 'primeng/tabview';


export const routes: Routes = [
  {path: '', component: ExpenseListComponent}
];
@NgModule({
  declarations: [ExpenseListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CommonModule,
    PdfViewerModule,
    SharedModule,
    TableModule,
    CardModule,
    InplaceModule,
    CalendarModule,
    FormsModule,
    ToastModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    InputTextareaModule,SplitButtonModule,
    DialogModule,ChipsModule,CheckboxModule,
    FileUploadModule,EditorModule,TooltipModule,
    TranslateModule,ImageToDataUrlModule,TabViewModule
  ]
})
export class ExpenseModule { }
