import { isSet } from '../../modules/core/util';

export class OrderStatus {
  completed: boolean;
  draft: boolean;
  cancelled: boolean;
  paid: boolean;
  invoiced:boolean;
  cc:boolean;
  delivered:boolean
  p_delivered:boolean;
  issued:boolean
  langStatus:string


  get status() {
  if (this.completed) {
    return {label: 'Completed', color: 'Completed'};
  }
  if (this.cancelled) {
    return {label: 'Canceled', color: 'Canceled'};
  }
  if (this.invoiced) {
    return {value:!this.paid ? 'Invoiced/UnPaid' : 'Invoiced/Paid' ,label: 'INVOICED', color: 'Invoiced',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  }

  if (this.draft ) {
    return {value:!this.paid ? 'Draft/UnPaid' : 'Draft/Paid',label: 'DRAFT', color: 'Draft',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  }
  if (this.cc ) {
    return {value:!this.paid ? 'C&C/UnPaid' : 'C&C/Paid',label: 'C&C', color: 'CC',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  };
  if (this.delivered == true) {
    return {value:!this.paid ? 'Delivered/UnPaid' : 'Delivered/Paid',label: 'DELIVERD', color: 'delivered',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  };
  if (this.delivered == false && this.p_delivered  == false) {
    return {value:!this.paid ? 'Not Delivered/UnPaid' : 'Not Delivered/Paid',label: 'NOT_DELI', color: 'not_delivered',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  };
  if (this.p_delivered  == true) {
    return {value:!this.paid ? 'Partially Delivered/UnPaid' : 'Partially Delivered/Paid',label: 'P_DILVER', color: 'partially_Delivered',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  };
  if (this.issued  == true) {
    return {value:!this.paid ? 'Issued/UnPaid' : 'Issued/Paid',label: 'ISSUED', color: 'Issued',paid_status: !this.paid ? 'UNPAID' : 'PAID', paid_color: !this.paid ? 'UnPaid' : 'Paid'};
  };
}
  public constructor(params?: OrderStatus) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: OrderStatus) {
    if (!isSet(objectToClone)) {
      return new OrderStatus();
    }
    return new OrderStatus(objectToClone);
  }
}
