import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/modules/core/api.service';
import { isSet } from 'src/app/modules/core/util';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { BaseComponent } from 'src/app/modules/core/base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent extends BaseComponent implements OnInit {

  @Input() visable: boolean = false;
  @Output() onHide = new EventEmitter<any>();
  @ViewChild('Email') emailcheck: any;

  @ViewChild('upload') upload: ElementRef;
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  };

  user: User = JSON.parse(localStorage.getItem("user"))
  disablebtnupload: boolean = false
  update_status: boolean = false

  password_dialog: boolean = false;
  currentPassword: string
  newPassword: string
  passinvaild: boolean
  passfoucs: boolean
  showPassword: boolean
  checkpass: boolean

  passwrod_numeric: boolean
  passwrod_lowercase: boolean
  passwrod_capitalcase: boolean
  passwrod_length: boolean

  email_dialog: boolean = false;
  new_email: string
  checkused_email: boolean
  emailfoucs: boolean

  @ViewChild('Password') passwordCheck: ElementRef;

  constructor(private api: ApiService,
     private sharedService: SharedService,public translate: TranslateService,
     private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    if (this.user.showSettings == true) {
      this.visable = true
      this.user.showSettings = false

      localStorage.setItem('user', JSON.stringify(this.user));
    }
    this.user.viewimage = this.user?.image

  }

  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
    if (status == false) {
      this.onHide.emit(false);

    }
  }

  deleteImage() {

  }
  notempty(event) {
    this.update_status = true
  }


  updateProfile() {

    if (this.update_status == false) {
      return this.messageService.add({ key: 'form', severity: 'info', summary: 'Info', detail: "No Change to update" });

    }
    this.loading = true
    const subscription = this.sharedService.update_profile(this.user).subscribe((user: any) => {

      if (!isSet(user)) {
        return;
      }
      this.loading = false

      this.messageService.add({
        key: 'form', severity: 'success',
        summary: 'Successful',
        detail: ' Profile info updated successfully'
      });
      this.user.first_name = user?.first_name
      this.user.last_name = user?.last_name
      this.user.mobile = user?.mobile
      this.user.image = user?.image
      this.user.showSettings = true

      localStorage.setItem('user', JSON.stringify(this.user));
      setTimeout(() => {
        this.update_status = false
        window.location.reload()
      }, 1500);


      subscription.unsubscribe();

    }, error => {
      this.loading = false

      subscription.unsubscribe();
    })
  }
  showUpload() {
    this.upload.nativeElement.click()

  }
  uploadlogo(imageResult: ImageResult) {
    const filee = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;

    this.user.viewimage = filee
    this.update_status = true
    this.user.deletePic = false

    this.srcToFile(filee, imageResult.file.name, imageResult.file.type).then((file) => {
      this.user.image = file
    })

  }


  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
  removeImage() {
    this.user.image = null
    this.user.viewimage = null
    this.user.deletePic = true
    this.update_status = true

  }

  showpassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'text');
    this.showPassword = true
  }
  hidepassword() {
    this.passwordCheck.nativeElement.setAttribute('type', 'password');
    this.showPassword = false
  }

  onfoucsPassword() {
    this.passfoucs = true
    this.checkpass = true

  }
  onblurPassword() {
    this.checkpass = false
    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.newPassword?.length < 8
    ) {
      this.passinvaild = true
      this.messageService.add({
        key: 'register', life: 5000,
        severity: 'error', summary: 'Error', detail: 'Please enter valid password'
      });
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.newPassword?.length >= 8
    ) {
      this.passinvaild = false

    }
  }
  checkPassword() {
    let lowerCaseLetters = /[a-z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(lowerCaseLetters)) {
      this.passwrod_lowercase = true
    } else {
      this.passwrod_lowercase = false
    }

    let numbers = /[0-9]/g;
    if (this.passwordCheck?.nativeElement?.value.match(numbers)) {
      this.passwrod_numeric = true

    } else {
      this.passwrod_numeric = false
    }

    let upperCaseLetters = /[A-Z]/g;
    if (this.passwordCheck?.nativeElement?.value.match(upperCaseLetters)) {
      this.passwrod_capitalcase = true

    } else {
      this.passwrod_capitalcase = false
    }
    if (this.newPassword?.length >= 8) {
      this.passwrod_length = true
    } else {
      this.passwrod_length = false
    }

    if (
      !this.passwrod_lowercase ||
      !this.passwrod_numeric ||
      !this.passwrod_capitalcase ||
      this.newPassword?.length < 8
    ) {
      this.passinvaild = true
    } else if (
      this.passwrod_lowercase ||
      this.passwrod_numeric ||
      this.passwrod_capitalcase ||
      this.newPassword?.length >= 8
    ) {
      this.passinvaild = false

    }
  }

  change_password() {

    if (!isSet(this.currentPassword) || !isSet(this.newPassword)) {
      return this.messageService.add({ key: 'form', severity: 'error', summary: 'Error', detail: "Please fill out the fields" });

    }
    this.loading = true
    const data = {
      password: this.currentPassword,
      new_password: this.newPassword
    }
    const subscription = this.sharedService.change_password(data).subscribe((data: any) => {


      this.loading = false

      this.messageService.add({
        key: 'form', severity: 'success',
        summary: 'Successful',
        detail: ' Password changed successfully'
      });



      subscription.unsubscribe();

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'form', severity: 'info', summary: 'Info', detail: "The current password is not correct" });

      subscription.unsubscribe();
    })
  }

  reset_email() {

    if (!isSet(this.currentPassword) || !isSet(this.new_email)) {
      return this.messageService.add({ key: 'form', severity: 'error', summary: 'Error', detail: "Please fill out the fields" });

    }

    if (this.emailcheck.control.invalid) {
      return this.messageService.add({
        key: 'from',
        severity: 'error', summary: 'Error', detail: 'Please enter vaild Email'
      });
    }
    if (this.checkused_email == true) {
      return this.messageService.add({ key: 'form', severity: 'error', summary: 'Error', detail: 'Email already exists.' });

    }

    this.loading = true
    const data = {
      password: this.currentPassword,
      new_email: this.new_email
    }
    const subscription = this.sharedService.reset_email(data).subscribe((data: any) => {


      this.loading = false

      this.messageService.add({
        key: 'form', severity: 'success',
        summary: 'Successful',
        detail: ' Check your email to verify '
      });



      subscription.unsubscribe();

    }, error => {
      this.loading = false
      this.messageService.add({ key: 'form', severity: 'error', summary: 'Error', detail: "The current password is not correct" });

      subscription.unsubscribe();
    })
  }

  onBlurEmail() {
    if (this.emailcheck.control.invalid) {
      return this.messageService.add({
        key: 'from', life: 5000,
        severity: 'error', summary: 'Error', detail: 'Please enter vaild Email'
      });
    }
  }
  checkEmail() {


    if (!this.emailcheck.control.invalid) {


      const subscription = this.api.postGuest('/check_email_available', {
        email: this.new_email
      }).subscribe((data: { status: boolean, message: string }) => {

        if (!isSet(data)) {
          return
        }
        if (data.status) {
          return this.checkused_email = false
        }
        if (!data.status) {
          this.checkused_email = true
          this.messageService.add({ key: 'form', severity: 'error', summary: 'Error', detail: data.message });
        }

      }, error => {
        subscription.unsubscribe();
      }, () => {
        subscription.unsubscribe();
      });
    }

  }
}
