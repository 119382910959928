import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from './calendar.service';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import { CalendarOptions, EventClickArg, FullCalendarComponent } from '@fullcalendar/angular';
import { SharedService } from '../../shared/shared.service';
import { Calendar_event } from 'src/app/models/calendar/event';
import { BaseComponent } from '../../core/base/base.component';
import { isSet } from '../../core/util';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MenuService } from 'src/app/shared/menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendar: FullCalendarComponent ;
  view = 'dayGridMonth'
  eventDialog: boolean
  eventDialogHeader: string
  update_status = false
  disableclick_date: boolean
  event = new Calendar_event()
  events: Calendar_event[] = []

   
  calendarOptions: CalendarOptions = {
    locales: [ esLocale, frLocale,nlLocale ],
    locale: 'en',
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.eventClick.bind(this),
    eventDrop: this.dragEvent.bind(this),
    headerToolbar: {
      center: 'title',
      left: '',
      right: 'prev,next today',

    },
    

    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
  };

  constructor(private calendarService: CalendarService,
    public messageService: MessageService,
    private sharedService: SharedService,public translate: TranslateService,
    private datePipe: DatePipe, private menuService: MenuService
  ) {
    super();
  }

  @HostListener('mousewheel', ['$event']) onMousewheel(event) {
    if (event.wheelDelta > 0) {
      this.calendar.getApi().next()
    }
    if (event.wheelDelta < 0) {
      this.calendar.getApi().prev()

    }
  }
  ngOnInit(): void {
    this.getevents()
  }
  ngAfterViewInit() {
   let langCode= JSON.parse(localStorage.getItem("currentLang"))
    this.calendar.options.locale=langCode.lang
  }
  getevents() {
    this.loading = true;

    const subscription = this.calendarService.getEvents().subscribe((calendar_events: Calendar_event[]) => {
      if (!isSet(calendar_events)) {
        return;
      }
      this.events = Calendar_event.cloneManyObjects(calendar_events)
      this.events.map(ev => {
        ev.start = new Date(ev.start)
        ev.end = new Date(ev.end)

        let differnce = ev.start.getHours() - ev.start.getUTCHours()

        ev.start.setHours(ev.start.getHours() + differnce)
        ev.end.setHours(ev.end.getHours() + differnce)

      })

      this.calendarOptions.events = this.events
      subscription.unsubscribe();
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({ key: 'list', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: this.translate.instant('NETWORK_ERROR') });
      subscription.unsubscribe();

    });
  }
  eventClick(ev) {
    this.event = new Calendar_event()
    this.event = ev.event.toJSON()
    this.event.description = ev.event.toJSON().extendedProps.description
    this.event.start = new Date(ev.event.toJSON().start)
    this.event.startTime = new Date()
    this.event.startTime.setHours(this.event.start.getHours())
    this.event.startTime.setMinutes(this.event.start.getMinutes())

    this.event.startP = this.datePipe.transform(this.event.start, 'yyyy-MM-dd')



    this.event.end = new Date(ev.event.toJSON().end)
    this.event.endTime = new Date()
    this.event.endTime.setHours(this.event.end.getHours())
    this.event.endTime.setMinutes(this.event.end.getMinutes())

    this.event.endP = this.datePipe.transform(this.event?.end, 'yyyy-MM-dd')



    this.eventDialog = true
    this.eventDialogHeader = this.translate.instant('VIEW_EVENT')

  }
  handleDateClick(arg) {
    if (!this.disableclick_date) {

      this.event = new Calendar_event()
      const d = new Date()
      this.event.start = new Date(arg.dateStr)

      this.event.startTime = new Date()
      this.event.startTime.setHours(d.getHours())
      this.event.startTime.setMinutes(d.getMinutes())

      this.event.end = new Date(arg.dateStr)
      this.event.endTime = new Date()
      this.event.endTime.setHours(d.getHours() + 1)
      this.event.endTime.setMinutes(d.getMinutes())

      this.eventDialogHeader = this.translate.instant('NEW_EVENT')
      this.eventDialog = true
    }
  }
  dragEvent(ev) {

    this.event = ev.event.toJSON()
    this.event.description = ev.event.toJSON().extendedProps.description
    this.event.start = new Date(ev.event.toJSON().start)
    this.event.startTime = new Date(ev.event.toJSON().start)

    this.event.end = new Date(ev.event.toJSON().end)
    this.event.endTime = new Date(ev.event.toJSON().end)



    this.update_status = true
    setTimeout(() => {
      this.updateEvent()

    }, 1000);
  }
  gridView() {
    this.calendar.getApi().changeView('dayGridMonth')
    this.view = 'dayGridMonth'
  }
  listView() {
    this.calendar.getApi().changeView('listWeek')
    this.view = 'listWeek'
  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
    this.disableclick_date = status
  }
  showaddevent() {
    this.event = new Calendar_event()
    this.event.start = new Date()
    this.event.end = new Date()
    this.event.startTime = new Date()
    this.event.endTime = new Date()
    this.event.endTime.setHours(this.event.endTime.getHours() + 1)
    this.eventDialog = true
    this.eventDialogHeader = this.translate.instant('NEW_EVENT')
  }
  addEvent() {
    this.event.end.setHours(this.event.endTime.getHours())
    this.event.end.setMinutes(this.event.endTime.getMinutes())
    this.event.start.setHours(this.event.startTime.getHours())
    this.event.start.setMinutes(this.event.startTime.getMinutes())


    if (!isSet(this.event.title)) {
      return this.messageService.add({
        key: 'list', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('PLZ_FILL_TITLE')
      });
    }


    if (this.event.start.getDate() == this.event.end.getDate()) {
      if ((this.event.start.getHours() >= this.event.end.getHours())) {
        return this.messageService.add({
          key: 'list', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('START_GRET_END')
        });
      }
    } else if (this.event.start.getDate() > this.event.end.getDate()) {
      return this.messageService.add({
        key: 'list', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('STATT_GRET_END_TIME')
      });
    } {

    }

    try {
      this.loading = true;
      const subscription = this.calendarService.addEvent(this.event).subscribe((event: Calendar_event) => {

        if (!isSet(event)) {
          return;
        }
        this.messageService.add({
          key: 'list', severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('NEW_EVENT_ADDED')
        });
        this.menuService.refreshcalendar.next(true)
        this.ngOnInit()
        setTimeout(() => {
          this.calendar.getApi().refetchEvents()
        }, 600);


        this.loading = false;
        this.eventDialog = false


        subscription.unsubscribe();

      }, error => {

        this.loading = false;
        subscription.unsubscribe();
      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }



  }
  updateEvent() {

    this.event.end.setHours(this.event.endTime.getHours())
    this.event.end.setMinutes(this.event.endTime.getMinutes())
    this.event.start.setHours(this.event.startTime.getHours())
    this.event.start.setMinutes(this.event.startTime.getMinutes())


    if (this.update_status === false) {
      return this.messageService.add({ key: 'list', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

    }
    if (!isSet(this.event.title)) {
      return this.messageService.add({
        key: 'list', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('PLZ_FILL_TITLE')
      });
    }
    if (this.event.start.getDate() == this.event.end.getDate()) {
      if ((this.event.start.getHours() >= this.event.end.getHours())) {
        return this.messageService.add({
          key: 'list', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('START_GRET_END')
        });
      }
    } else if (this.event.start.getDate() > this.event.end.getDate()) {
      return this.messageService.add({
        key: 'list', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('STATT_GRET_END_TIME')
      });
    } {

    }



    try {
      this.loading = true;
      const subscription = this.calendarService.updateEvent(this.event).subscribe((event: Calendar_event) => {

        if (!isSet(event)) {
          return;
        }
        this.messageService.add({
          key: 'list', severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('EVENT_UPDATED')
        });
        this.menuService.refreshcalendar.next(true)

        this.ngOnInit()
        setTimeout(() => {
          this.calendar.getApi().refetchEvents()
        }, 600);
        this.loading = false;
        this.eventDialog = false
        this.update_status = false

        subscription.unsubscribe();

      }, error => {

        this.loading = false;
        subscription.unsubscribe();
      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }
  }
  deleteEvent() {
    this.loading = true;
    const subscription = this.calendarService.deleteEvent(this.event).subscribe(() => {
      this.messageService.add({ key: 'list', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SELECT_EVENT_DELETE') });
      this.loading = false;
      this.eventDialog = false
      this.menuService.refreshcalendar.next(true)

      this.ngOnInit()
      setTimeout(() => {
        this.calendar.getApi().refetchEvents()

      }, 600);

      subscription.unsubscribe();

    }, error => {

      this.loading = false;
      subscription.unsubscribe();


    })
  }
  changeEventType(ev) {

    if (ev.value == 'One day') {
      this.event.end = new Date()
      this.event.end.setHours(this.event.end.getHours() + 1)

    } else {
      this.event.end = new Date()
      this.event.end.setDate(this.event.start.getDate() + 1)

    }
  }
  notempty(event) {
    this.update_status = true
  }




}
