import { AccountManager } from './accountmanager';
import { ExtraFields } from './extrafields';
import { isSet } from '../modules/core/util';

export class CustomerDetails {
  credit_limit?: number;
  account_manager?: AccountManager;
  account_number?: number;
  IBAN?: any;
  bank_name?: any;
  BIC?: string;
  Language?: string;
  payment_terms: string;
  extra_fields?: ExtraFields[];

  public constructor(params?: CustomerDetails) {
    Object.assign(this, params);
  }

  public static cloneObject(objectToClone: CustomerDetails) {
    if (!isSet(objectToClone)) {
      return objectToClone;
    }
    const obj = new CustomerDetails(objectToClone);
    if (isSet(obj.account_manager)) {
      obj.account_manager = AccountManager.cloneObject(objectToClone.account_manager);
    }
    if (isSet(obj.extra_fields)) {
      obj.extra_fields = ExtraFields.cloneManyObjects(objectToClone.extra_fields);
    }
    return obj;
  }

  public static cloneManyObjects(objectsToClone: CustomerDetails[]) {
    const objects: CustomerDetails[] = [];
    for (const obj of objectsToClone) {
      objects.push(CustomerDetails.cloneObject(obj));
    }

    return objects;
  }
}
