import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { isSet } from 'src/app/modules/core/util';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit , OnDestroy {

  subscription: Subscription;

  items: MenuItem[];
  currentUrl:string
  constructor(public breadcrumbService: BreadcrumbService,private router : Router) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }
  ngOnInit() {
    this.breadcrumb()
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  breadcrumb() {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        

        if (event.url == '/' && !isSet(this.currentUrl)) {
          return this.items = []
        }
        if (this.currentUrl === event.url) {
          return
        } else {
          this.currentUrl = event.url
          this.items = []
          let url: any[] = this.router.parseUrl(event.url).root?.children?.primary?.segments
          url?.map((m) => {
            let upperCase = m.path[0]?.toLocaleUpperCase() + m.path.substring(1)
            if (upperCase == 'Expensenote') {
              upperCase ='Expense-Note'          
            }
            if (upperCase == 'Purchaseorders') {
              upperCase ='Purchase-Orders'          
            }
            if (upperCase == 'Creditnotes') {
              upperCase ='Credit-Notes'          
            }
            this.items.push({ label: upperCase })

          })
        }
      }
    });

  if (!isSet(this.currentUrl) && this.router.url != '/') {
      this.currentUrl = this.router.url
      this.items = []

      let url: any[] = this.router.parseUrl(this.currentUrl).root.children.primary.segments

      url.map((m) => {

      
        let upperCase = m.path[0]?.toLocaleUpperCase() + m.path.substring(1)
        if (upperCase == 'Expensenote') {
          upperCase ='Expense-Note'          
        }
        if (upperCase == 'Purchaseorders') {
          upperCase ='Purchase-Orders'          
        }
        if (upperCase == 'Creditnotes') {
          upperCase ='Credit-Notes'          
        }
        

        this.items.push({ label: upperCase })

      })
    }





  }
}
