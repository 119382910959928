import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { BaseComponent } from '../../core/base/base.component';
import { Warehouses } from 'src/app/models/PurchaseOrders/warehouses';
import { Unit } from '../../../models/unit';
import { OrderItem } from '../../../models/orders/orderitem';
import { Product } from '../../../models/product';
import { PaginatedResponse } from '../../../models/paginatedresponse';
import { isSet } from '../../core/util';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { PurchaseOrdersService } from '../PurchaseOrders.service';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Supplier } from 'src/app/models/supplier';
import { Router } from '@angular/router';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { ShipmentMethods } from 'src/app/models/PurchaseOrders/shipment-methods';
import { Vat_Product } from 'src/app/models/vat-product';
import { Payment } from 'src/app/models/orders/Payment';
import { DatePipe } from '@angular/common';
import { Dropdown } from 'primeng/dropdown';
import * as printJS from 'print-js';
import { Options } from 'ngx-image2dataurl';
import { SendEmail } from 'src/app/models/SendEmail';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Currency } from 'src/app/models/currency';
import { MenuService } from 'src/app/shared/menu.service';
import {Calendar} from "primeng/calendar";

@Component({
  selector: 'app-PurchaseOrders-form',
  templateUrl: './PurchaseOrders-form.component.html',
  styleUrls: ['./PurchaseOrders-form.component.scss'],
  providers: [MessageService]

})
export class PurchaseOrdersFormComponent extends BaseComponent implements OnInit, AfterViewInit {
  purchaceQuote_inte: PurchaseOrders
  purchaseOrder: PurchaseOrders;
  dupplicatePurhase = new PurchaseOrders()


  units: Unit[] = [];
  pdfViewer = false
  productTouched: boolean = false
  vendorOrder: boolean = false
  products: Product[] = [];
  selected_PurchaseOrders: boolean
  sendEmaildialog = false
  sendEmail = new SendEmail()
  convertPurchasedialogueeQ = false
  update_status = false
  public vat_Percentage: Vat_Product[] = [];
  qouteChecks: boolean
  quote_Format: string
  itemsSave = []
  paymenttypes = [];
  payment = new Payment()
  newPayments: Payment[] = []
  user_email: string
  showPaymentDialog = false
  paymentDue = 0
  paymentDuestatus = true
  oldpaymentDue = 0
  totalpayment = 0
  Paymentlabel: string
  typeUpdateMassage
  paymentUpdateindex = 0
  orderTotal = 0
  orderTotalChange = 0
  fixed_amount: boolean
  index_Toupdate = 0
  disable_payments: boolean
  show_afterdiscont: boolean
  payment_cardHeight = 0
  indexwarehouse = 0
  publicScopeUpdate
  paymentDuefromBalance = false
  selected_discount_type = { type: null }
  discount_type = [
    { type: 'Percent', label: this.translate.instant('PERCENT') },
    { type: 'Amount', label: this.translate.instant('ORDERS_FORM_AMOUNT') }
  ]
  customerBalance = 0
  totalpayment_refund = 0
  formType
  supplier_select = false
  editvendoricon = 'far fa-edit'
  warehousess: Warehouses[] = []
  warehouse = new Warehouses()
  showdeleteWarehouses = false
  showAdd_exchange_rate: boolean = false
  add_exchange_rate = 1
  default_currency = new Currency()
  defaultcurrency_status: boolean = false
  confrim_exchange_rate: boolean = false

  prdouctWidth = 0
  notSavedupdate_Convert: boolean = false
  notSavedupdate_Dupplict: boolean = false

  addAttachmentheader = 'Add Invoice File'
  public addWarehousesheader = 'Add Account Manager'
  showWarehouse = false
  options: Options = {
    resize: {
      maxHeight: 1000,
      maxWidth: 750
    },
    allowedExtensions: ['JPG', 'JPEG ', 'PnG', 'Pdf']
  };
  inlineProductValue: string
  indexinlineProduct: number
  showaddinlineProduct: boolean
  disable_VatsRatesChecks: boolean = false

  convertToPurchace: boolean = false
  internalrReference_ToPurchace: string

  // check if there payment with discount
  checkdiscountPy: boolean = false
  @ViewChild('warehouses') warehouses_validation: any;
  @ViewChild('dd') dd: Dropdown;
  @ViewChild('internal_reference') internal_reference: any;
  @ViewChild('amountt') amount: any;
  @ViewChildren('item.qty') qtyS: QueryList<ElementRef>;
  @ViewChildren('item.price') priceS: QueryList<ElementRef>;
  @ViewChild('paymentcard') paymentcard: ElementRef;
  @ViewChild('productelemt') itemWidth: ElementRef;
  @ViewChild('rqInvoiceDateToggle') rqInvoiceDateToggle: Calendar;
  @ViewChild('rqDateToggle') rqDateToggle: Calendar;

  constructor(private purchaseOrdersService: PurchaseOrdersService,
    private sharedService: SharedService,
    private messageService: MessageService,
    public primengConfig: PrimeNGConfig,
    private datePipe: DatePipe,
    public zone: NgZone, public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router) {
    super();
  }

  ngOnInit(): void {

    this.paymenttypes = [
      { type: 'Cash', label: this.translate.instant('Cash') },
      { type: 'Bank Cards', label: this.translate.instant('Bank Cards') },
      { type: 'Wire Transfer', label: this.translate.instant('Wire Transfer') },
      { type: 'Check', label: this.translate.instant('Check') },
      { type: 'Voucher', label: this.translate.instant('Voucher') },
      { type: 'Other', label: this.translate.instant('other') }];

    this.getVats()
    this.checkQoute()

    this.subscribeOnFormSavedQuote()
    this.getwarehouse()
    this.getorderTotalChange()
    this.subscribeOnFormSaved();
    this.initPurchaseOrders();
    this.subscripOnDuplicate()
    this.subscribeOnOrderSelected()
    this.convertToPurchase()
    this.getSavedPurchaseOrders()
    this.Save_form()
    this.subscribeOnSendEmail()
    this.updateEmitter()
    this.currencyChanged()
    this.updateQuoteEmitter()
    this.addInvoiceAttachmentEmmiter()
    this.showOrderFromDash()


    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
        this.addItem();
      }
    });
    this.itemsSave = [

      {

        label: this.translate.instant('FULL_CASH_PAID'), command: () => {
          if (this.paymentDue === 0) {
            this.paymentDuestatus = true
            return
          }
          this.update_status = true
          this.payment = new Payment()
          this.payment.amount = this.paymentDue
          this.totalpayment = this.totalpayment + this.payment.amount

          this.payment.type = 'Cash'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.purchaseOrder.customer?.due)) {
            this.purchaseOrder.customer.due = this.purchaseOrder.customer.due + this.paymentDue
          }
          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.purchaseOrder.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()
          if (this.purchaseOrder.discount > 0) {
            this.checkdiscountPy = true
          }

        },

      },
      {

        label: this.translate.instant('FULL_BANK_PAID'), command: () => {
          if (this.paymentDue === 0) {
            this.paymentDuestatus = true

            return
          }
          this.update_status = true
          this.payment = new Payment()

          this.payment.amount = this.paymentDue
          this.totalpayment = this.totalpayment + this.payment.amount

          this.paymentDue = 0
          this.paymentDuestatus = true
          this.payment.type = 'Bank Cards'
          this.payment.status = 'add'
          this.payment.status2 = 'new'

          if (isSet(this.purchaseOrder.customer?.due)) {
            this.purchaseOrder.customer.due = this.purchaseOrder.customer.due + this.paymentDue
          }
          this.payment.dateP = new Date()
          this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd')
          this.purchaseOrder.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.payment = new Payment()

          if (this.purchaseOrder.discount > 0) {
            this.checkdiscountPy = true
          }
        }
      }


    ]
  }
  ngAfterViewInit() {
    this.loading = true

    const x = setInterval(() => {

      const qtySs: ElementRef[] = this.qtyS.toArray()

      for (let index = 0; index < this.purchaseOrder.items?.length; index++) {
        qtySs[index].nativeElement.value = this.spaceNumber(this.purchaseOrder?.items[index].qty)
        if (index == this.purchaseOrder.items?.length - 1) {
          clearInterval(x);

        }
      }
      this.loading = false

    }, 300);
    this.payment_cardHeight = this.paymentcard?.nativeElement?.offsetHeight

    this.cdr.detectChanges();

  }
  showAddPaymnet() {
    if (this.paymentDue === 0) {
      return
    }
    this.Paymentlabel = this.translate.instant('ADD_PAYMENT')
    this.payment = new Payment()
    this.payment.dateP = new Date()
    this.payment.status = 'add'
    this.payment.status2 = 'new'

    this.amount.control.markAsUntouched()
    this.amount.control.clearValidators()
    this.showPaymentDialog = true

  }
  addPayment() {
    if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) === true)) {
      this.messageService.add({
        key: 'form',
        severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });

      this.amount.control.markAsTouched();

    } else {
      if (this.mathRound(this.payment.amount) > this.mathRound(this.paymentDue)) {

        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_DUE')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_NO_ZERO')
        });
      } else {

        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }

        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        if (this.purchaseOrder.payments.length) {
          this.purchaseOrder.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.paymentDue = this.paymentDue - this.payment.amount
          this.totalpayment = this.totalpayment + this.payment.amount
          if (this.paymentDue === 0) {
            this.paymentDuestatus = true
          }
        } else {
          this.purchaseOrder.payments.push(this.payment)
          this.newPayments.push(this.payment)
          this.getPaymentDue()
        }

        this.update_status = true
        if (this.purchaseOrder.discount > 0) {

          this.checkdiscountPy = true
        }
        if (isSet(this.purchaseOrder.customer?.due)) {
          this.purchaseOrder.customer.due = this.purchaseOrder.customer.due + this.paymentDue
        }


        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()
      }
    }
    
  }


  showrefundPayment() {
    this.Paymentlabel = this.translate.instant('REFUND_PAYMENT')
    this.payment = new Payment()
    this.payment.status = 'refund'
    this.payment.status2 = 'new'
    this.payment.dateP = new Date()
    this.amount.control.markAsUntouched()
    this.amount.control.clearValidators()
    if (this.disable_payments == true) {
      this.fixed_amount = true

      this.payment.amount = this.totalpayment - this.totalpayment_refund
      this.payment.notes = this.translate.instant('TOTAL_CHANGED')
    }
    this.showPaymentDialog = true

  }
  refundPayment() {
    if (this.disable_payments == true) {

      if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
        this.amount.control.markAsTouched();

        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });


      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      } else {
        let paymentDue = Number(this.paymentDue)
        this.payment.amount = Number(this.payment.amount)
        paymentDue = this.paymentDue + this.payment.amount
        if (this.purchaseOrder.orderTotalIncVAT < paymentDue) {
          return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });

        }
        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }
        this.totalpayment_refund = Number(this.totalpayment_refund) + this.payment.amount


        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        this.purchaseOrder.payments.push(this.payment)
        this.newPayments.push(this.payment)
        this.paymentDue = Number(paymentDue)


        this.paymentDuestatus = false
        this.update_status = true
        this.disable_payments = false
        if (this.purchaseOrder.discount > 0 && this.paymentDue == this.purchaseOrder.default_currncey_orderTotalIncVAT) {
          this.checkdiscountPy = true
        }

        if (isSet(this.purchaseOrder.customer?.due)) {
          this.purchaseOrder.customer.due = this.purchaseOrder.customer.due + this.paymentDue
        }
        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()


      }
    } else {


      if (this.amount.control.invalid || !isSet(this.payment.amount) || (isNaN(this.payment.amount) == true)) {
        this.amount.control.markAsTouched();

        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });


      } else if (this.payment.amount > this.purchaseOrder.orderTotalIncVAT) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });
      } else if (this.payment.amount == 0) {
        return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_NO_ZERO') });
      } else {
        let paymentDue = Number(this.paymentDue)
        this.payment.amount = Number(this.payment.amount)
        paymentDue = this.paymentDue + this.payment.amount
        if (this.purchaseOrder.orderTotalIncVAT < paymentDue) {
          return this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: this.translate.instant('AMOUNT_MORE_TOTAL') });

        }
        if (!isSet(this.payment.type)) {
          this.payment.type = 'Cash'
        }
        this.payment.date = this.datePipe.transform(this.payment.dateP, 'yyyy-MM-dd');
        this.purchaseOrder.payments.push(this.payment)
        this.newPayments.push(this.payment)
        this.paymentDue = Number(paymentDue)
        this.totalpayment_refund = Number(this.totalpayment_refund) + this.payment.amount


        this.paymentDuestatus = false
        this.update_status = true

        if (isSet(this.purchaseOrder.customer?.due)) {
          this.purchaseOrder.customer.due = this.purchaseOrder.customer.due + this.paymentDue
        }
        this.showPaymentDialog = false
        this.amount.control.markAsUntouched()
        this.amount.control.clearValidators()
      }
    }
  }
  addItem() {

    const item = OrderItem.cloneObject(null);
    item.datePipe = new Date();
    item.product = new Product()
    item.product.id = 'new'
    item.qty = 1;
    item.exchange_rate = this.purchaseOrder?.exchange_rate
    item.price = 0;
    item.vat = new Vat_Product()
    item.vat.value = 0;
    if (this.qouteChecks == true) {
      item.qty = 1;
      item.price = 0;


    }
    item.received_qty = 0
    item.checkinlne = false
    this.purchaseOrder.items.push(item);

  }

  inlineprodcut(event, index) {
    const inlineProduct = event

    this.productTouched = false
    this.update_status = true

    this.purchaseOrder.items[index].product = new Product()
    this.purchaseOrder.items[index].product.id = ''
    this.purchaseOrder.items[index].product.name = inlineProduct
    this.purchaseOrder.items[index].name = inlineProduct
    this.purchaseOrder.items[index].itemVaild = true





  }

  private getwarehouse() {

    try {
      const subscription = this.purchaseOrdersService.getwarehouses().subscribe((data: PaginatedResponse<Warehouses[]>) => {
        if (!isSet(data)) {
          return;
        }

        this.warehousess = Warehouses.cloneManyObjects(data.results);
        const addwarehousess = new Warehouses();
        addwarehousess.id = '0';
        addwarehousess.name = '<span class="add-quick-option">' + this.translate.instant('NEW_WARHAOUSE') + '</span>'
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        this.warehousess = insert(this.warehousess, 0, addwarehousess)
        setTimeout(() => {
          this.initwarehouses();
        }, 100);
        subscription.unsubscribe();
      }, error => {
        this.log(error);
        subscription.unsubscribe();
      });
    } catch (error) {
      this.log(error);
    }
  }
  private initwarehouses(): void {
    if (this.qouteChecks == true) {
      return
    }

    this.dd.activateFilter = () => {
      const value = this.dd.filterValue;
      const options = this.warehousess.filter(warehouses => {
        return warehouses.name.toLowerCase().includes(value.toLowerCase());
      });

      const addwarehousess = new Warehouses();
      addwarehousess.id = '-1';
      addwarehousess.name = '<span class="add-quick-option">' + value + ' (' + this.translate.instant('NEW_WARHAOUSE') + ')</span>';

      options.push(addwarehousess);

      this.dd.optionsToDisplay = options;
    };
  }
  warehouseChanged($event, i) {
    const warehouse = $event.value as Warehouses;
    this.indexwarehouse = i
    if (warehouse?.id === '0') {
      this.purchaseOrder.items[i].warehouse = new Warehouses()
      this.warehouse = new Warehouses()

      return this.showAddWarehouse()

    }
    if (warehouse?.id === '-1') {
      this.warehouse = new Warehouses()
      this.warehouse.name = warehouse.name.replace('<span class="add-quick-option">', '').replace(' (' + this.translate.instant('NEW_WARHAOUSE') + ')</span>', '')
      this.purchaseOrder.items[i].warehouse = new Warehouses()
      return this.showAddWarehouse()

    }
    if (!isSet(warehouse)) {

      this.update_status = true
    }

  }
  showAddWarehouse() {
    this.warehouses_validation?.control.clearValidators()
    this.warehouses_validation?.control.markAsUntouched()
    this.addWarehousesheader = this.translate.instant('ADD_WARHOUSE')
    this.showWarehouse = true
  }
  showeditWarehouse(item: Warehouses) {

    this.warehouse = Warehouses.cloneObject(item)

    this.addWarehousesheader = this.translate.instant('EDIT_WARHOUSE')
    this.showWarehouse = true

    this.index_Toupdate = this.warehousess.indexOf(item)

  }
  showdeleteWarehouse(item: Warehouses) {
    this.warehouse = item
    this.showdeleteWarehouses = true
    this.purchaseOrder.items[this.indexwarehouse].warehouse = null

  }
  cancelshowAddWarehouse() {
    this.showWarehouse = false
    this.purchaseOrder.items[this.indexwarehouse].warehouse = null
    this.sharedService.dialog_status.next(false)
  }
  canceldeleteWarehouse() {
    this.showdeleteWarehouses = false
    this.purchaseOrder.items[this.indexwarehouse].warehouse = null
    this.sharedService.dialog_status.next(false)

  }
  save_Warehouse() {
    if (!isSet(this.warehouse.name)) {
      this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('FILL_REQUIR_FIELDS') });
      this.warehouses_validation.control.markAsTouched();

    } else {
      this.loading = true;

      const subscription = this.purchaseOrdersService.addWarehouses(this.warehouse).subscribe((warehouse: Warehouses) => {
        this.loading = false;
        if (!isSet(warehouse)) {
          return;
        }
        subscription.unsubscribe();
        this.loading = false;
        this.showWarehouse = false
        this.getwarehouse()
        const x = setInterval(() => {
          this.purchaseOrder.items[this.indexwarehouse].warehouse = this.warehousess[1]

          if (this.purchaseOrder.items[this.indexwarehouse]?.warehouse.id == this.warehouse?.id) {
            clearInterval(x);

          }

        }, 300)

        this.messageService.add({
          key: 'form',
          severity: 'success',
          summary: this.translate.instant('SUCCESSFUL'),
          detail: this.translate.instant('NEW_WARE_ADDED')
        });
      }, error => {

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
        this.showWarehouse = false

        subscription.unsubscribe();
        this.loading = false;

      })
    }
  }


  update_Warehouse() {
    try {
      this.loading = true;
      const subscription = this.purchaseOrdersService.updatewarehouse(this.warehouse).subscribe((warehouse: Warehouses) => {

        if (!isSet(warehouse)) {
          return;
        }
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.showWarehouse = false
        this.getwarehouse()

        const x = setInterval(() => {
          this.purchaseOrder.items[this.indexwarehouse].warehouse = this.warehousess[this.index_Toupdate]

          if (this.purchaseOrder.items[this.indexwarehouse].warehouse?.id == this.warehousess[this.index_Toupdate].id) {
            clearInterval(x);

          }

        }, 300)
        subscription.unsubscribe();
        this.update_status = false

      }, error => {
        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

        this.loading = false;
        subscription.unsubscribe();
        this.update_status = false

      })
    } catch (error) {
      this.loading = false;
      this.log(error);

    }

  }


  delete_Warehouse() {
    this.loading = true;

    const subscription = this.purchaseOrdersService.deletewarehouses(this.warehouse.id).subscribe(() => {

      this.messageService.add({ key: 'form', severity: 'success', life: 2000, summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('SEK_WARE_DEL') });
      this.warehouse = new Warehouses()
      this.getwarehouse()
      this.showdeleteWarehouses = false
      this.loading = false;
      subscription.unsubscribe();

    }, error => {
      this.messageService.add({ key: 'form', severity: 'error', life: 3000, summary: this.translate.instant('ERROR'), detail: error.error.detail });
      this.showdeleteWarehouses = false
      this.loading = false;
      subscription.unsubscribe();
      this.getwarehouse()
    })

  }
  shipping_methodChanged(event) {
    try {
      setTimeout(() => {
        const shipmentMethods = event as ShipmentMethods;
        this.purchaseOrder.shipping_method = shipmentMethods

      }, 400)
    } catch (error) {
      this.log(error);
    }
  }
  private subscribeOnOrderSelected() {
    const subscription = this.purchaseOrdersService.PurchaseSelectedListEmitter.subscribe((purchase: any) => {

      if (!isSet(purchase)) {
        return this.initPurchaseOrders

      }

      if (purchase?.type == 'Order') {
        if (isSet(purchase?.id)) {

          this.getPurchaseOrder(purchase?.id);
        } else {
          this.getNextOrderinternal_reference()

        }

      }

      if (purchase?.type == 'Quote') {
        if (isSet(purchase?.id)) {

          this.getPurchaseQoute(purchase?.id);

        } else {
          this.getNextَQuoteinternal_reference()

        }
      }
      this.purchaseOrdersService.PurchasesSelected.next(null)
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  async productChanged(event, index) {
    await this.selectProduct(event, index)
    await this.discont_Percentage()
    this.getorderTotalChange()

    this.exchangeRateChange()
    await this.getVats_before_afterDiscont()


  }
  exchangeRateChange() {
    setTimeout(() => {
      this.purchaseOrder.items.map(item => {
        item.exchange_rate = this.purchaseOrder.exchange_rate
      })
    }, 300);

    this.getPaymentDue()
  }
  selectProduct(event, index) {
    return new Promise((resolve) => {
      this.purchaseOrder.paid_vats = []
      setTimeout(() => {
        const product = event as Product;


        this.purchaseOrder.items[index].price = product?.sell_price || 0;
        if (isSet(product?.vat?.id)) {
          this.purchaseOrder.items[index].vat = product?.vat
        }
        this.purchaseOrder.items[index].product = new Product()
        this.purchaseOrder.items[index].datePipe = new Date()
        this.purchaseOrder.items[index].product = product
        this.purchaseOrder.items[index].name = product?.name
        this.purchaseOrder.items[index].itemVaild = true
        this.productTouched = false
        this.update_status = true
        this.paymentDuestatus = false
        this.onChange_Item(index, null)
        resolve(this.purchaseOrder.items[index])

      }, 200);


    })
  }
  deletePayment(index) {
    this.paymentDuestatus = false
    if (this.purchaseOrder.payments[index].status == 'add') {
      this.totalpayment = this.totalpayment - this.purchaseOrder.payments[index].amount
    } else if (this.purchaseOrder.payments[index].status == 'refund') {
      this.totalpayment_refund = this.totalpayment_refund - this.purchaseOrder.payments[index].amount
    }

    this.purchaseOrder.payments[index].amount = 0
    this.getPaymentDue()
    this.purchaseOrder.payments.splice(index, 1)
    this.newPayments.splice(this.newPayments.length - 1, 1)

    setTimeout(() => {

      if (this.purchaseOrder.default_currncey_orderTotalIncVAT > (this.totalpayment - this.totalpayment_refund)) {

        this.disable_payments = false

      }
    }, 300);

    if (this.paymentDue > this.purchaseOrder.default_currncey_orderTotalIncVAT) {
      this.paymentDue = this.purchaseOrder.default_currncey_orderTotalIncVAT
    }
    if (this.paymentDue < 0) {
      this.paymentDue = this.purchaseOrder.default_currncey_orderTotalIncVAT
    }
  }
  getPaymentDue() {
    const payments_amountsadd = []
    const refundamounts = []
    if (this.purchaseOrder.payments.length) {
      this.purchaseOrder.payments.map(item => {
        if (item.status === 'add') {
          payments_amountsadd.push(item.amount)
        }
        if (item.status === 'refund') {
          refundamounts.push(item.amount)
        }
      })
      if (payments_amountsadd.length > 0) {
        this.totalpayment = payments_amountsadd.reduce((prev, next) => prev + next);
      }
      if (refundamounts.length > 0) {
        this.totalpayment_refund = refundamounts.reduce((prev, next) => prev + next);
      }
    }
    this.orderTotal = this.purchaseOrder.default_currncey_orderTotalIncVAT

    this.paymentDue = (this.orderTotal - this.totalpayment) || 0
    this.paymentDue = this.totalpayment_refund + this.paymentDue
    if (this.paymentDuefromBalance === false && isSet(this.purchaseOrder.customer?.due)) {
      this.customerBalance = this.purchaseOrder.customer?.due + this.paymentDue
      this.paymentDuefromBalance = true

    }


    if (this.purchaseOrder.discount > 0 && this.paymentDue == this.purchaseOrder.default_currncey_orderTotalIncVAT) {

      this.checkdiscountPy = false
    } else if (this.purchaseOrder.discount > 0) {
      this.checkdiscountPy = true
    }

    if (this.paymentDue === 0) {
      this.paymentDuestatus = true

    } else if (this.paymentDue > 0) {
      this.paymentDuestatus = false
    }

  }
  paymentReview(payment: Payment, index: number) {

    if (payment.status2 === 'old') {
      return
    }
    if (payment.notes == 'Total Changed') {
      return
    }
    if (index !== this.purchaseOrder.payments.length - 1) {
      return
    }
    if (this.paymentDue === 0) {
      this.paymentDuestatus = true
    }
    this.payment = new Payment()
    this.payment.amount = payment.amount
    this.payment.notes = payment.notes
    this.payment.type = payment.type
    this.payment.status = payment.status
    this.payment.status2 = payment.status2
    this.payment.dateP = new Date(payment.dateP)
    this.paymentUpdateindex = index
    this.showPaymentDialog = true
    this.Paymentlabel = this.translate.instant('UPDATED_PAYMENT')
  }

  UpdatePayment() {
    const prv_paymnet = new Payment()
    prv_paymnet.amount = Number(this.purchaseOrder.payments[this.paymentUpdateindex].amount)

    prv_paymnet.dateP = new Date(this.purchaseOrder.payments[this.paymentUpdateindex].dateP)
    prv_paymnet.type = this.purchaseOrder.payments[this.paymentUpdateindex].type
    prv_paymnet.notes = this.purchaseOrder.payments[this.paymentUpdateindex].notes
    let paymentDue = Number(this.paymentDue)
    paymentDue = paymentDue - Number(prv_paymnet.amount)
    paymentDue = paymentDue + Number(this.payment.amount)
    if (this.payment.status === 'add') {

      if (((this.mathRound(this.payment.amount) > this.mathRound(paymentDue)) &&
       (this.mathRound(paymentDue) > this.mathRound(this.purchaseOrder.orderTotalIncVAT)))) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_DUE')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_NO_ZERO')
        });
      }
    } else if (this.payment.status === 'refund') {

      if (this.purchaseOrder.orderTotalIncVAT < paymentDue) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_MORE_TOTAL')
        });
      } else if (this.payment.amount === 0) {
        return this.messageService.add({
          key: 'form',
          severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          detail: this.translate.instant('AMOUNT_NO_ZERO')
        });
      }
    }
    console.log(this.paymentDue);
    
    this.paymentDue = paymentDue
    this.purchaseOrder.payments[this.paymentUpdateindex] = this.payment
    this.purchaseOrder.payments[this.paymentUpdateindex].amount = Number(this.purchaseOrder.payments[this.paymentUpdateindex].amount)

    this.showPaymentDialog = false
    this.update_status = true
    this.payment = new Payment()
    this.getPaymentDue()
  }


  onChange_Item(index: number, filed) {

    const qtyS: ElementRef[] = this.qtyS.toArray()
    if (filed == 'qty') {

      qtyS[index].nativeElement.value = this.spaceNumber(this.purchaseOrder.items[index].qty)
      this.purchaseOrder.items[index].qty = this.purchaseOrder.items[index].qty.replace(/\s/g, '')
    }

  }
  spaceNumber(number) {
    return String(number).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }
  private subscribeOnFormSaved() {
    const subscription = this.purchaseOrdersService.saveFormClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addPurchaseOrders();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }

  private subscribeOnFormSavedQuote() {
    const subscription = this.purchaseOrdersService.saveFormQuoteClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status) || !status) {
        return;
      }

      this.addPurchaseQuote();
    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  private initPurchaseOrders() {

    this.purchaseOrder = PurchaseOrders.cloneObject(null);
    this.purchaseOrder.date = new Date()
    this.purchaseOrder.items = [];
    this.purchaseOrder.payments = [];
    this.purchaseOrder.exchange_rate = 1
    this.purchaseOrder.discount = 0
    this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.purchaseOrder.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.addItem();

  }
  supplierChange($event) {
    console.log($event);
    
    const vndor = $event as Supplier
    this.purchaseOrder.vendor = vndor
    this.update_status = true


  }

  checkQoute() {
    const subscription = this.purchaseOrdersService.checkQouteEmitter.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }
      this.qouteChecks = true
      this.purchaseOrdersService.checkQoute.next(null)

    }, error => {
      this.handleError(error);
    })
    this.subscriptions.push(subscription);
  }
  addPurchaseQuote() {
    this.removeSpaceNumber()
    let noerror: boolean

    if (isSet(this.purchaseOrder?.items)) {
      this.purchaseOrder?.items.map(item => {
        item.qty = Number(item.qty)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')




        if ((!isSet(this.purchaseOrder.vendor)) || (!isSet(this.purchaseOrder.internal_reference))
          || (!isSet(this.purchaseOrder.date)) || !item.itemVaild) {
          this.productTouched = true
          this.vendorOrder = true

          return noerror = false
        } else {
          return noerror = true

        }
      })
    }


    if (noerror == false) {
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      try {
        this.loading = true;
        const subscription = this.purchaseOrdersService.addPurchaseQuote(this.purchaseOrder).subscribe((purchaseOrders: PurchaseOrders) => {

          if (!isSet(purchaseOrders)) {
            return;
          }
          this.purchaseOrder.id = purchaseOrders.id;
          this.purchaseOrder.status = purchaseOrders.status
          this.purchaseOrder.items = purchaseOrders.items
          this.purchaseOrder.items.map(item => {

            item.itemVaild = true
            item.datePipe = new Date(item?.date);
            if (isSet(item.product?.id)) {
              item.checkinlne = false
            }
            if (isSet(item?.name) && !isSet(item.product?.id)) {
              item.checkinlne = true
              item.product = new Product()
              item.product.name = item?.name
            }
            if (isSet(item.service)) {
              item.checkinlne = true
            }




          })
          this.purchaseOrder.items.map(item => {
            if (isSet(item.product?.id)) {
              item.checkinlne = false
            }
            if (isSet(item?.name) && !isSet(item.product?.id)) {
              item.checkinlne = true
              item.product = new Product()
              item.product.name = item?.name
            }
            if (isSet(item.service)) {
              item.checkinlne = true
              item.service = item.service
            }
          })
          this.selected_PurchaseOrders = true
          this.loading = false;
          this.update_status = false
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('PUR_QUOT_ADDED') });
          this.purchaseOrdersService.refreshDataTable()
          this.purchaseOrdersService.retrieve_lastQuote.next(this.purchaseOrder)
          this.purchaseOrdersService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)
          subscription.unsubscribe();

        }, error => {
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
          console.log(error);
          this.loading = false;
          subscription.unsubscribe();

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }

    }
  }
  addPurchaseOrders(): void {
    if (isSet(this.purchaseOrder?.payments)) {
      this.purchaseOrder.payments.map(payment => {
        if (payment.type == 'Wire Transfer') {
          payment.type = 'wt'
        }
        payment.amount = Number(payment.amount)
        payment.date = this.datePipe.transform(payment.dateP, 'yyyy-MM-dd')

      })
    }
    this.removeSpaceNumber()
    let noerror: boolean



    if (isSet(this.purchaseOrder?.items)) {
      this.purchaseOrder?.items.map(item => {
        item.price = Number(item.price)
        item.qty = Number(item.qty)
        item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')



        if (isSet(item.vat.value)) {

          item.vat.value = Number(item.vat.value)
        } else {
          item.vat.value = 0
        }
        if ((!isSet(this.purchaseOrder.vendor)) || (!isSet(this.purchaseOrder.internal_reference))
          || (!isSet(this.purchaseOrder.date)) || !item.itemVaild) {
          this.productTouched = true
          this.vendorOrder = true

          return noerror = false
        } else {
          return noerror = true

        }
      })
    }
    if (noerror == false) {
      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      try {
        this.loading = true;
        const subscription = this.purchaseOrdersService.addPurchaseOrder(this.purchaseOrder).subscribe((purchaseOrders: PurchaseOrders) => {





          this.purchaseOrder.id = purchaseOrders?.id;
          this.purchaseOrder.status = purchaseOrders.status

          this.selected_PurchaseOrders = true
          this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('PUR_ORDER_ADDED') });

          const disconnt = this.purchaseOrder.discont_Percentage
          for (let index = 0; index < this.purchaseOrder.items.length; index++) {
            this.purchaseOrder.items[index].id = purchaseOrders.items[index].id;

          }
          this.purchaseOrder.items


          this.purchaseOrder.items.map(item => {
            item.discont_Percentage = disconnt

            item.itemVaild = true
            item.datePipe = new Date(item?.date);
            if (isSet(item.product?.id)) {
              item.checkinlne = false
            }
            if (isSet(item?.name) && !isSet(item.product?.id)) {
              item.checkinlne = true
              item.product = new Product()
              item.product.name = item?.name
            }
            if (isSet(item.service)) {
              item.checkinlne = true
            }



          })
          this.newPayments = []

          this.purchaseOrder.payments.map(payment => {
            payment.status2 = 'old'
          })

          this.purchaseOrdersService.refreshDataTable()
          this.purchaseOrdersService.retrieve_lastOrder.next(this.purchaseOrder)
          this.purchaseOrdersService.saveFormClicked.next(null)
          this.sharedService.chekcempey.next(null)
          this.notSavedupdate_Dupplict == false
          this.loading = false;

          subscription.unsubscribe();

        }, error => {
          console.log(error);

          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

          this.loading = false;
          subscription.unsubscribe();

        })
      } catch (error) {
        this.loading = false;
        this.log(error);

      }

    }



  }

  downloadPdf() {

    window.location.href = this.purchaseOrder.download_url;

  }
  printPdf() {

    printJS({ printable: this.purchaseOrder.download_url, type: 'pdf', showModal: true })

  }


  private getNextOrderinternal_reference() {
    const subscption = this.purchaseOrdersService.getNextOrderNumber().subscribe((data: { internal_reference: string }) => {
      if (this.convertToPurchace == false) {
        this.purchaseOrder.internal_reference = data.internal_reference;
        this.purchaseOrder.internal_invoice = data.internal_reference;
      }

      this.internalrReference_ToPurchace = data.internal_reference


    }, error => {
      subscption.unsubscribe();
    });
  }

  private getNextَQuoteinternal_reference() {
    const subscption = this.purchaseOrdersService.getNextQuoteNumber().subscribe((data: { internal_reference: string }) => {
      if (!isSet(data)) {
        return;
      }

      this.purchaseOrder.internal_reference = data.internal_reference

    }, error => {
      subscption.unsubscribe();
    });
  }


  private getPurchaseOrder(id: string) {
    this.loading = true
    const subscription = this.purchaseOrdersService.getPurchaseOrder(id).subscribe((purchaseOrders: PurchaseOrders) => {
      if (!isSet(purchaseOrders)) {
        return
      }

      const cloneObject = PurchaseOrders.cloneObject(purchaseOrders);
      this.selected_PurchaseOrders = true
      this.purchaseOrder = cloneObject;
      const default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
      if (this.purchaseOrder.currency?.id != default_currency?.id) {
        this.purchaseOrder.exchange_rateStauts = true

      }

      this.purchaseOrder.date = new Date(purchaseOrders.date)
      if (isSet(purchaseOrders?.invoice_date)) {
        this.purchaseOrder.invoice_date = new Date(purchaseOrders?.invoice_date)

      }

      if (this.purchaseOrder.discount[this.purchaseOrder.discount.length - 1] == "%") {
        this.purchaseOrder.discount = this.purchaseOrder.discount.substring(0, this.purchaseOrder.discount.length - 1)

        this.selected_discount_type.type = 'Percent'
        this.purchaseOrder.discount_type = 'Percent'
      } else {
        this.selected_discount_type.type = 'Amount'
        this.purchaseOrder.discount_type = 'Amount'

      }
      const disconnt = this.purchaseOrder.discont_Percentage
      this.purchaseOrder.items.map(item => {
        item.discont_Percentage = disconnt
        item.exchange_rate = this.purchaseOrder.exchange_rate

        item.itemVaild = true
        item.datePipe = new Date(item?.date);
        if (isSet(item.product?.id)) {
          item.checkinlne = false
        }
        if (isSet(item?.name) && !isSet(item.product?.id)) {
          item.checkinlne = true
          item.product = new Product()
          item.product.name = item?.name
        }
        if (isSet(item.service)) {
          item.checkinlne = true
        }



      })


      if (this.purchaseOrder.payments.length) {
        this.purchaseOrder.payments.map(payment => {
          if (payment.type === 'Wire Transfer') {
            payment.type = 'wt'
          }
          payment.dateP = new Date(payment.date)
          payment.status2 = 'old'

        })

      }
      if (isSet(this.purchaseOrder.vendor?.contacts)) {
        this.purchaseOrder.vendor.contacts.forEach(customer => {
          SendEmail.cloneObject(this.sendEmail)
          this.sendEmail.send_to_mails = []
          this.sendEmail.send_to_mails.push(customer.email)

        })
      }
      this.getPaymentDue()
      if (this.purchaseOrder.discount_type == 'Amount') {
        const restdiscount = this.purchaseOrder.discount
        this.cleardiscont()
        this.purchaseOrder.discount = restdiscount
        this.discont_Percentage()
      }
      this.dupplicatePurhase = PurchaseOrders.cloneObject(this.purchaseOrder);
      subscription.unsubscribe()
      this.loading = false
        ;
    }, error => {


      subscription.unsubscribe();
      this.loading = false

    })
  }
  private getPurchaseQoute(id: string) {
    this.loading = true


    const subscription = this.purchaseOrdersService.getPurchaseQuote(id).subscribe((purchaseOrders: PurchaseOrders) => {
      if (!isSet(purchaseOrders)) {
        return
      }
      const cloneObject = PurchaseOrders.cloneObject(purchaseOrders);
      this.selected_PurchaseOrders = true


      this.purchaseOrder = cloneObject;

      this.purchaseOrder.date = new Date(purchaseOrders.date)
      if (isSet(purchaseOrders?.invoice_date)) {
        this.purchaseOrder.invoice_date = new Date(purchaseOrders?.invoice_date)

      }
      this.purchaseOrder.items.map(item => {

        item.itemVaild = true
        item.datePipe = new Date(item?.date);
        if (isSet(item.product?.id)) {
          item.checkinlne = false
        }
        if (isSet(item?.name) && !isSet(item.product?.id)) {
          item.checkinlne = true
          item.product = new Product()
          item.product.name = item?.name
        }
        if (isSet(item.service)) {
          item.checkinlne = true
        }




      })
      this.dupplicatePurhase = PurchaseOrders.cloneObject(this.purchaseOrder);

      if (isSet(this.purchaseOrder.vendor?.contacts)) {
        this.purchaseOrder.vendor.contacts.forEach(customer => {
          SendEmail.cloneObject(this.sendEmail)
          this.sendEmail.send_to_mails = []
          this.sendEmail.send_to_mails.push(customer.email)

        })
      }
      subscription.unsubscribe()
      this.loading = false
        ;
    }, error => {
      this.handleError(error)
      subscription.unsubscribe();
      this.loading = false

    })
  }
  updateQuoteEmitter() {
    const subscription = this.purchaseOrdersService.upadteQuoteFormClickedEmitter.subscribe(status => {
      if (!isSet(status) || !status) {
        return;
      }


      this.updatePurchaseQuote()
    }, error => {

      this.handleError(error);
    })
    this.subscriptions.push(subscription);
  }
  updateEmitter() {
    const subscription = this.purchaseOrdersService.upadteFormClickedEmitter.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }

      this.updatePurchase()
    }, error => {
      this.handleError(error);


    })
    this.subscriptions.push(subscription);
  }
  updatePurchaseQuote() {

    let noerror: boolean
    if (isSet(this.purchaseOrder?.items)) {
      this.purchaseOrder?.items.map(item => {

        if ((!isSet(this.purchaseOrder.vendor)) || (!isSet(this.purchaseOrder.internal_reference))
          || (!isSet(this.purchaseOrder.date)) || !item.itemVaild) {
          this.productTouched = true
          this.vendorOrder = true
          noerror = false
        } else {
          noerror = true

        }



      })
    }

    if (noerror === false) {

      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      })
    } else if (noerror === true) {

      if (this.update_status === false) {

        this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });

      }
    }

    try {
      this.loading = true;
      const subscription = this.purchaseOrdersService.upadePurchaseQuote(this.purchaseOrder).subscribe((purchaseOrders: PurchaseOrders) => {

        if (!isSet(purchaseOrders)) {
          return
        }

        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });
        this.loading = false;
        this.update_status = false
        this.sharedService.update_form.next(false)
        this.purchaseOrdersService.refreshselected.next(true)
        this.purchaseOrdersService.upadteQuoteFormClicked.next(null)
        this.purchaseOrdersService.refreshDataTable()
        if (this.notSavedupdate_Convert == true) {
          setTimeout(() => {
            this.convertToPurchaseOrder()
          }, 1000);
        }

        if (this.notSavedupdate_Dupplict == true) {

          this.purchaseOrdersService.duplicateClicked.next("Quote");

        }
        subscription.unsubscribe();


      }, error => {
        console.log(error);

        this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });

        this.loading = false;
        subscription.unsubscribe();
        this.purchaseOrdersService.upadteQuoteFormClicked.next(null)

      })
    } catch (error) {
      this.loading = false;
      this.log(error);


    }


  }
  updatePurchase() {
console.log(this.newPayments);

    if (this.purchaseOrder.payments.length) {
      if (this.mathRound(this.purchaseOrder.orderTotalIncVAT) < this.mathRound(this.totalpayment - this.totalpayment_refund)) {

        return this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
        });
      }
    }
    let noerror: boolean
    this.publicScopeUpdate = noerror
    this.purchaseOrdersService.newPayments = this.newPayments
    if (isSet(this.purchaseOrder?.items)) {
      this.purchaseOrder?.items.map(item => {
        if ((!isSet(this.purchaseOrder?.vendor)) || (!isSet(this.purchaseOrder?.internal_reference))
          || !item?.itemVaild || (!isSet(this.purchaseOrder?.date))
        ) {
          this.productTouched = true
          this.vendorOrder = true

          return noerror = false
        } else {
          return noerror = true


        }

      })
    }
    if (noerror == false) {
      this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        detail: this.translate.instant('FILL_REQUIR_FIELDS')
      });
    } else if (noerror == true) {
      if (this.update_status == false) {
        this.messageService.add({ key: 'form', severity: 'info', summary: this.translate.instant('INFO'), detail: this.translate.instant('NO_CHANGE_UPDATE') });
      } else {
        try {
          this.loading = true;
          const subscription = this.purchaseOrdersService.upadePurchaseOrder(this.purchaseOrder).subscribe((purchaseOrders: PurchaseOrders) => {

            if (!isSet(purchaseOrders)) {
              return;
            }


            if (this.typeUpdateMassage == 'invoice Delete') {
              this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('INVO_DEL') });


            } else if (this.typeUpdateMassage == 'invoice Uploaded') {
              this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPLOD_INVO') });

            } else {
              this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: this.translate.instant('UPDATED_SUCC') });

            }
            this.newPayments = []

            this.purchaseOrder.payments.map(payment => {
              payment.status2 = 'old'
            })
            // this.typeUpdateMassage=null
            this.loading = false;
            this.update_status = false
            this.purchaseOrdersService.refreshDataTable()
            this.sharedService.update_form.next(false)
            this.purchaseOrdersService.refreshselected.next(true)
            this.purchaseOrdersService.upadteFormClicked.next(null)
            this.purchaseOrdersService.upadteQuoteFormClicked.next(null)

            if (this.notSavedupdate_Dupplict == true) {

              this.purchaseOrdersService.duplicateClicked.next("Order");

            }

            subscription.unsubscribe();
          }, error => {
            this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERROR'), detail: error.error.detail });
            this.loading = false;
            subscription.unsubscribe();
            this.purchaseOrdersService.upadteFormClicked.next(null)

          })
        } catch (error) {
          this.loading = false;
          this.log(error);


        }
      }

    }
  }
  Save_form() {

    this.sharedService.Saveform_Observable_state.subscribe(status => {

      if (!isSet(status) || !status) {
        return;
      }
      this.sharedService.Save_object(this.purchaseOrder)
      this.sharedService.Set_Saveform__state(null)
    }, error => {
      this.handleError(error);
    });
  }


  getSavedPurchaseOrders() {

    let subscription: Subscription = new Subscription();

    subscription = this.sharedService.Saved_object_Emitter.subscribe((purchaseOrders: any) => {
      if (!isSet(purchaseOrders)) {
        return;
      }
      if (purchaseOrders.url == this.router.url) {
        this.purchaseOrder = purchaseOrders.object
      }
      if (isSet(this.sharedService.Createform.value)) {
        this.sharedService.objectstauts.next(true)
      } else {
        this.sharedService.objectstauts.next(null)

      }

    }, error => {
      this.handleError(error);
    });
    subscription.unsubscribe()

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getorderTotalChange() {
    this.oldpaymentDue = this.paymentDue

    setTimeout(() => {

      if (!this.purchaseOrder.payments.length) {
        this.paymentDue = this.purchaseOrder.orderTotalIncVAT || 0
      } else if (this.purchaseOrder.orderTotalIncVAT == 0) {
        this.paymentDue = 0

      } else if (this.purchaseOrder.orderTotalIncVAT > 0) {

        this.paymentDue = this.purchaseOrder.orderTotalIncVAT - (this.totalpayment - this.totalpayment_refund) || 0
        this.orderTotal = this.purchaseOrder.orderTotalIncVAT || 0
      }
      if (this.paymentDue === 0) {
        this.paymentDuestatus = true
      } else if (this.paymentDue > 0) {
        this.paymentDuestatus = false
      }

      if (isSet(this.purchaseOrder.customer?.due)) {
        this.purchaseOrder.customer.due = this.customerBalance
        this.purchaseOrder.customer.due = this.purchaseOrder.customer.due - this.paymentDue
        this.purchaseOrder.customer.due = -Math.abs(this.purchaseOrder.customer.due)
      }
    }, 300)

  }
  outside_Status(status: boolean) {
    this.sharedService.dialog_status.next(status)
  }
  async notempty(event) {
    this.update_status = true
    this.sharedService.update_form.next(true)

    if (this.disable_VatsRatesChecks == false) {
      const restdiscount = this.purchaseOrder.discount
      this.cleardiscont()
      this.purchaseOrder.discount = restdiscount
      await this.discont_Percentage()
      this.getorderTotalChange()
      await this.getVats_before_afterDiscont()

    }
    if (this.purchaseOrder.discount > 0) {
      const x = this.purchaseOrder.discount

      this.purchaseOrder.discount = 0

      let disconnt = this.purchaseOrder.discont_Percentage
      this.purchaseOrder.items.map(item => {
        item.discont_Percentage = disconnt

      })
      this.purchaseOrder.discount = x
      disconnt = this.purchaseOrder.discont_Percentage
      this.purchaseOrder.items.map(item => {
        item.discont_Percentage = disconnt

      })

    }

    setTimeout(() => {

      if (this.mathRound(this.purchaseOrder.orderTotalIncVAT)< this.mathRound(this.totalpayment - this.totalpayment_refund)) {
        this.disable_payments = true

        this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
        });
      } else {
        this.disable_payments = false

      }
    }, 300);

  }
  erace_Default(index) {
    if (this.purchaseOrder.items[index].price == 0) {
      this.purchaseOrder.items[index].price = ''
    }
  }
  erace_value(index, filedName: string) {
    const qtyS: ElementRef[] = this.qtyS?.toArray()

    const priceS: ElementRef[] = this.priceS?.toArray()


    if (filedName == 'price') {
      this.purchaseOrder.items[index].price = null
      priceS[index].nativeElement.value = ''
      priceS[index].nativeElement.select()

    } else {
      this.purchaseOrder.items[index].qty = null
      qtyS[index].nativeElement.value = ''
      qtyS[index].nativeElement.select()


    }




  }
  removeSpaceNumber() {
    const qtyS: ElementRef[] = this.qtyS?.toArray()
    for (let index = 0; index < this.purchaseOrder?.items?.length; index++) {
      let rmspaceQty = qtyS[index].nativeElement.value.replace(/\s/g, '')

      this.purchaseOrder.items[index].qty = parseInt(rmspaceQty)


    }
  }
  isSet(value: any) {
    if (isSet(value)) {
      return true
    } else {
      return false
    }
  }
  showOrderFromDash() {
    const subscription = this.sharedService.diplayitemEmitter.subscribe((item: any) => {
      if (!isSet(item)) {
        return;
      }

      if (item.itemName == 'Purchases') {
        this.getPurchaseOrder(item.item?.id)

      }
      this.sharedService.diplayDash.next(null)
    }, error => {
      this.handleError(error);
    });
    this.subscriptions.push(subscription);
  }
  addInvoiceAttachmentEmmiter() {
    const subscription = this.purchaseOrdersService.uploadInoviceEmitter.subscribe((inoivceFile: any) => {

      if (!isSet(inoivceFile)) {
        return;
      }

      if (inoivceFile === 'delete') {
        this.typeUpdateMassage = 'invoice Delete'
        this.purchaseOrder.invoice_attachment = null
        this.purchaseOrdersService.uploadInovice.next(null)


      } else if (inoivceFile !== 'delete') {

        this.purchaseOrder.invoice_attachment = inoivceFile
        this.typeUpdateMassage = 'invoice Uploaded'
        this.purchaseOrdersService.uploadInovice.next(null)

      }

      this.update_status = true
      this.purchaseOrdersService.upadteFormClicked.next(true)
      // this.updatePurchase()
    }, error => {

      console.log(error);

    });
    this.subscriptions.push(subscription);



  }


  convertToPurchase() {
    const subscription = this.purchaseOrdersService.convertPurchaceClickedEmitter.subscribe((status: boolean) => {
      if (!isSet(status)) {
        return
      }
      if (this.update_status == true) {
        this.notSavedupdate_Convert = true
      }

      this.convertToPurchace = true
      this.getNextOrderinternal_reference()
      this.purchaseOrder.date = new Date()
      this.convertPurchasedialogueeQ = true
      this.purchaseOrdersService.convertPurchaceClicked.next(null)

    }, error => {
      console.log(error);

      this.handleError(error);
    });
    this.subscriptions.push(subscription);


  }

  async convertToPurchaseOrder() {
    this.qouteChecks = null
    this.purchaseOrder = PurchaseOrders.cloneObject(this.purchaseOrder)
    this.purchaseOrder.convert = true
    this.purchaseOrder.id = null
    this.purchaseOrder.type = 'Order'

    this.purchaseOrder.items.map(item => {
      item.exchange_rate = 1
      item.price = 0;
      item.vat = new Vat_Product()
      item.vat.value = 0;
      if (isSet(item.product?.id)) {
        item.exchange_rate = 1
        item.price = item.product?.sell_price

        if (isSet(item.product?.vat?.value)) {
          item.vat = item.product.vat

        }

      }

    })
    this.purchaseOrder.payments = []
    this.purchaseOrder.currency = JSON.parse(localStorage.getItem('companySettings'))?.currency
    this.purchaseOrder.exchange_rate = 1
    this.purchaseOrder.discount = 0
    this.purchaseOrder.internal_reference = this.internalrReference_ToPurchace
    this.purchaseOrder.internal_invoice = this.internalrReference_ToPurchace
    this.selected_PurchaseOrders = false
    this.purchaseOrdersService.convertOrder.next(true)
    this.convertPurchasedialogueeQ = false
    this.notSavedupdate_Convert = false

    await this.discont_Percentage()
    this.getorderTotalChange()

    await this.getVats_before_afterDiscont()
    this.getorderTotalChange()
  }

  subscribeOnSendEmail() {

    const subscription = this.purchaseOrdersService.sendEmailClickedEmitter.subscribe((status: any) => {
      if (!isSet(status) || !status) {
        return
      }
      this.formType = status
      this.sendEmaildialog = true
      this.purchaseOrdersService.sendEmailClicked.next(null)

    }, error => {
      this.handleError(error);
    });

    this.subscriptions.push(subscription);
  }
  send_Email() {
    this.purchaseOrdersService.btnLoading.next(true)
    this.sendEmaildialog = false

    if (this.formType == 'Order') {
      const subscription = this.purchaseOrdersService.SendEmailOrders(this.sendEmail, this.purchaseOrder.id).subscribe(
        (sendEmail: SendEmail) => {
          if (!isSet(sendEmail)) {
            return;
          }
          this.purchaseOrdersService.btnLoading.next(false)

          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('SENT_EMAILS')
          });
          subscription.unsubscribe();
          return
        }, error => {
          this.purchaseOrdersService.btnLoading.next(false)
          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          subscription.unsubscribe();
        })
    } else if (this.formType == 'Quote') {
      const subscription = this.purchaseOrdersService.SendEmailQuotes(this.sendEmail, this.purchaseOrder.id).subscribe(
        (sendEmail: SendEmail) => {
          if (!isSet(sendEmail)) {
            return;
          }
          this.purchaseOrdersService.btnLoading.next(false)

          this.messageService.add({
            key: 'form',
            severity: 'success',
            summary: this.translate.instant('SUCCESSFUL'),
            detail: this.translate.instant('SENT_EMAILS')
          });
          subscription.unsubscribe();
          return
        }, error => {
          this.purchaseOrdersService.btnLoading.next(false)

          this.messageService.add({ key: 'form', severity: 'error', summary: this.translate.instant('ERR_ESSAGE'), detail: error.error.detail });
          subscription.unsubscribe();
        })
    }


  }

  Save_Message() {
    localStorage.setItem('defaultMessage', this.sendEmail.message)
  }
  send_copyto_myself() {
    const user: User = JSON.parse(localStorage.getItem('user'))
    const email = this.sendEmail.send_to_mails
    if (!isSet(this.sendEmail.send_to_mails)) {
      this.sendEmail.send_to_mails = []
      this.sendEmail.send_to_mails.push(user.email)
    }
    if (this.sendEmail.send_to_mails?.includes(user.email) === false && this.user_email != null) {
      email.push(user.email)
      this.sendEmail.send_to_mails = []
      this.user_email = user.email

      email.map(em => {
        this.sendEmail.send_to_mails.push(em)
      })
    } else {
      this.sendEmail.send_to_mails = this.sendEmail.send_to_mails?.filter(item => item !== user.email)
      this.user_email = null
    }
  }
  addEmail_onSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  onRemovemail() {
    this.sendEmail.send_to_mails.find(email => {
      if (email !== this.user_email) {
        this.user_email = null
      }
    })
  }
  subscripOnDuplicate() {
    const subscription = this.purchaseOrdersService.duplicateClickedEmitter.subscribe((type) => {
      if (!isSet(type)) {
        return;
      }
      if (this.update_status == true) {
        this.notSavedupdate_Dupplict = true
      } else {
        this.notSavedupdate_Dupplict = false

      }

      if (type == 'Order') {
        this.getNextOrderinternal_reference()
        this.purchaseOrder.date = new Date()
        this.purchaseOrder.payments = [];
        this.selected_PurchaseOrders = null
        this.qouteChecks = null
      } else if (type == 'Quote') {
        this.qouteChecks = true
        this.getNextَQuoteinternal_reference()
        this.selected_PurchaseOrders = null
        this.purchaseOrder.date = new Date()
        this.purchaseOrder.payments = [];


      }
      this.purchaseOrdersService.duplicateClicked.next(null)
    }, error => {
      console.log(error);
    })
    this.subscriptions.push(subscription)
  }
  total_discountchange() {

    if (this.selected_discount_type.type == 'Percent') {
      this.purchaseOrder.discount_type = 'Percent'
    } else {
      this.purchaseOrder.discount_type = 'Amount'

      if (this.purchaseOrder.discount > this.purchaseOrder.beforeDiscont_orderTotalIncVAT) {
        return this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('DIS_BIG_TOTAL')
        });
      }
    }

    setTimeout(() => {
      const disconnt = this.purchaseOrder.discont_Percentage
      this.purchaseOrder.items.map(item => {
        item.discont_Percentage = disconnt

      })
    }, 100);

    setTimeout(() => {

      if (this.purchaseOrder.orderTotalIncVAT < this.totalpayment - this.totalpayment_refund) {

        this.disable_payments = true

        this.messageService.add({
          key: 'form', severity: 'error',
          summary: this.translate.instant('ERR_ESSAGE'),
          life: 4000,
          detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
        });
      } else {
        this.disable_payments = false

      }
    }, 300);
    this.update_status = true
    this.getorderTotalChange()



  }
  cleardiscont() {
    this.purchaseOrder.discount = 0

    let disconnt = this.purchaseOrder.discont_Percentage
    this.purchaseOrder.items.map(item => {
      item.discont_Percentage = disconnt
    })
    this.getorderTotalChange()
  }
  remomveItem(index: number) {

    if (this.purchaseOrder.discount > 0) {
      const x = this.purchaseOrder.discount
      setTimeout(() => {
        this.purchaseOrder.discount = 0

        let disconnt = this.purchaseOrder.discont_Percentage
        this.purchaseOrder.items.map(item => {
          item.discont_Percentage = disconnt

        })
        this.purchaseOrder.discount = x
        disconnt = this.purchaseOrder.discont_Percentage
        this.purchaseOrder.items.map(item => {
          item.discont_Percentage = disconnt

        })
      }, 100);
    }
    this.update_status = true
    this.purchaseOrder.items.splice(index, 1)
    this.getorderTotalChange()

    if (this.mathRound(this.purchaseOrder?.orderTotalIncVAT) < this.mathRound(this.totalpayment - this.totalpayment_refund)) {

      this.disable_payments = true

      return this.messageService.add({
        key: 'form', severity: 'error',
        summary: this.translate.instant('ERR_ESSAGE'),
        life: 4000,
        detail: this.translate.instant('TOTAL_BIGGER_CURRENT')
      });
    } else {
      this.disable_payments = false

    }
  }
  onKeyUp(e) {
    switch (e.keyCode) {
      case 13:
        let focusableElements = document.querySelectorAll('input, button')
        let index = Array.prototype.indexOf.call(focusableElements, document.activeElement)
        if (e.shiftKey)
          this.focus(focusableElements, index - 1)
        else
          this.focus(focusableElements, index + 1)

        e.preventDefault()
        break;
    }

  }
  focus(elements, index) {
    if (elements[index])
      elements[index].focus()

  }

  currencyChanged() {
    const subscription = this.purchaseOrdersService.currencyChangedEmitter.subscribe(currancy => {
      if (!isSet(currancy)) {
        return
      }
      this.confrim_exchange_rate = false
      this.purchaseOrder.currency = currancy
      this.default_currency = JSON.parse(localStorage.getItem('companySettings'))?.currency

      if (this.purchaseOrder.currency?.id != this.default_currency?.id) {

        this.showAdd_exchange_rate = true

      } else {
        this.purchaseOrder.exchange_rateStauts = false
        this.showAdd_exchange_rate = false
        this.purchaseOrder.exchange_rate = 1
        this.add_exchange_rate = 1
        this.defaultcurrency_status = false
        this.purchaseOrder.items.map(item => {
          item.exchange_rate = 1
          if (item.product.id?.length > 4) {
            item.price = item.product?.sell_price / item.exchange_rate
          }
        })
        this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.default_currency?.name });

      }
      this.purchaseOrdersService.currencyChanged.next(null)
    }, error => { console.log(error) })
    this.subscriptions.push(subscription)
  }

  showEidtinlineProduct(index: number) {

    this.inlineProductValue = this.purchaseOrder.items[index].service
    this.indexinlineProduct = index
    this.showaddinlineProduct = true
  }
  serviceItem(event, index) {
    const service = event

    this.productTouched = false
    this.update_status = true
    this.purchaseOrder.items[index].checkinlne = true

    this.purchaseOrder.items[index].product = new Product()
    this.purchaseOrder.items[index].product.id = ''
    this.purchaseOrder.items[index].service = service
    this.purchaseOrder.items[index].name = null
    this.purchaseOrder.items[index].itemVaild = true

  }

  expand_inLineProduct(index: number) {
    this.purchaseOrder.items[index].inline_Epand = !this.purchaseOrder.items[index].inline_Epand
  }
  edit_inlineProduct() {
    this.purchaseOrder.items[this.indexinlineProduct].service = this.inlineProductValue
    this.showaddinlineProduct = false

  }

  private getVats() {
    const subscription = this.sharedService.get_Vats().subscribe((vats: PaginatedResponse<Vat_Product[]>) => {
      if (!isSet(vats)) {
        return;
      }
      this.vat_Percentage = Vat_Product.cloneManyObjects(vats.results);
      let zeroVat_index = 0
      this.vat_Percentage.map((vat, index) => {
        if (vat.value == 0) {
          vat.value = '0'
          zeroVat_index = index
        }
      })
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ]
      this.vat_Percentage = insert(this.vat_Percentage, 0, this.vat_Percentage[zeroVat_index])

      this.vat_Percentage.splice(zeroVat_index + 1, 1)

      subscription.unsubscribe();


    }, error => {

      console.log(error);
      subscription.unsubscribe();


    })

  }
  discont_Percentage() {
    return new Promise((resolve) => {
      this.purchaseOrder.paid_vats = []
      setTimeout(() => {
        const disconnt = this.purchaseOrder.discont_Percentage
        this.purchaseOrder.items.map(item => {
          item.discont_Percentage = disconnt

        })
        resolve(null)

      });


    })
  }
  getVats_before_afterDiscont() {
    return new Promise((resolve) => {
      this.purchaseOrder.paid_vats = []
      setTimeout(() => {
        this.vat_Percentage.map(vat => {
          let beforediscont = 0
          let afterdiscont = 0

          beforediscont = _.sumBy(this.purchaseOrder?.items, (item) => {
            if (item?.vat?.id == vat.id) {
              return item?.beforediscont_vatAmount
            } else {
              return 0
            }

          })
          afterdiscont = _.sumBy(this.purchaseOrder?.items, (item) => {
            if (item?.vat?.id == vat?.id) {
              return item?.vatAmount
            } else {
              return 0
            }

          })
          if (beforediscont > 0) {

            let v = {
              name: vat?.value,
              beforediscont: beforediscont,
              afterdiscont: afterdiscont

            }

            this.purchaseOrder.paid_vats.push(v)
            resolve(this.purchaseOrder.paid_vats.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

          }

        })
      }, 200);


    })
  }

  confrimAdd_exchangeRate() {
    this.confrim_exchange_rate = true
    this.showAdd_exchange_rate = false;
    this.purchaseOrder.exchange_rate = this.add_exchange_rate
    this.purchaseOrder.exchange_rateStauts = true
    this.defaultcurrency_status = true
    this.purchaseOrder.items.map(item => {
      item.exchange_rate = this.purchaseOrder.exchange_rate
    })

    this.messageService.add({ key: 'form', severity: 'success', summary: this.translate.instant('SUCCESSFUL'), detail: 'Currency Changed to ' + this.purchaseOrder?.currency?.name });

  }
  cancelAdd_exchangeRate() {
    this.sharedService.dialog_status.next(false)

    if (this.confrim_exchange_rate == false) {
      this.purchaseOrdersService.currencyChanged.next(this.default_currency)
      this.showAdd_exchange_rate = false;
      this.purchaseOrder.exchange_rate = 1;
      this.add_exchange_rate = 1
      this.purchaseOrder.exchange_rateStauts = false
      this.defaultcurrency_status = false
      this.purchaseOrder.items.map(item => {
        item.exchange_rate = 1

      })

    }
  }
  discard_ChangeOnDuplicate() {

    this.purchaseOrdersService.PurchasesSelected.next(this.purchaseOrder)
    if (this.purchaseOrder.type == 'Quote') {
      setTimeout(() => {
        this.getNextَQuoteinternal_reference()

      }, 1000);

    } else {
      setTimeout(() => {
        this.getNextOrderinternal_reference()

      }, 1000);
      this.purchaseOrder.payments = [];
    }

    this.purchaseOrder.date = new Date()

    this.notSavedupdate_Dupplict = false
  }
  sendToWhatsapp(){
    window.open(`https://web.whatsapp.com/send?text=${this.purchaseOrder.whatsappUrl}`, "_blank")
  }

  focusThenShow(type){
    switch (type) {
      case 'date':
        setTimeout(()=>{
          this.rqDateToggle.toggle()
        },0)
        break;
      case 'invoice':
        setTimeout(()=>{
          this.rqInvoiceDateToggle.toggle()
        },0)
        break;
    }
  }

  openDropDown(){
    this.sharedService.openDropdown.next(true)
  }

  mathRound(value){
		return	Math.round(value*100)/100;
	}

}


