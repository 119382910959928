import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sqx-button',
  templateUrl: './sqx-button.component.html',
  styleUrls: ['./sqx-button.component.scss']
})
export class SqxButtonComponent implements OnInit {

  @Input() loading = false;
  @Input() label: string;
  @Input() icon: string;

  @Input() class: string;
  @Output() clicked: EventEmitter<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
