<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-12">
    <div class="card p-0 pt-1">
      <app-table-header (add)="showAddOrder()" (Reload)='Reload()' addLabel=' {{"NEW_CREDIT_NOTE" | translate}} '
        (remove)="removedialoguee = true" [disableRemove]="!selected.length">
        <app-Search-select [selecteditem]="itemSearch" [Searchitems]="filteredProducts" [field_name]="keyword"
          (filter)="serach($event)" (onClear)="clearOrders_queries()"  (completeMethod)="completeMethod($event)"></app-Search-select>
      </app-table-header>
      <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped  p-datatable-responsive-demo"
        [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
        (onPage)="getNextCreditPage($event)"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [value]="creditNotes"
        [(selection)]="selected"
        [globalFilterFields]="['reference','.customer?.company_name','date','total','status?.status.value']">


        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="reference">
              <span
                [style.color]="filterModeFormat_query?.type || filterModeFormat_query?.value?'var(--primary-color)':null">{{"DASHBORD_CREDIT_NOTES" | translate}}</span>
              <p-sortIcon (click)="customSort('reference')" field="reference"></p-sortIcon>

              <p-columnFilter #creditNoteFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="reference" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeFormat_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeFormat_query.value" class="p-mt-3" type="text" pInputText>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeFormat_query?.type || !filterModeFormat_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('reference',filterModeFormat_query?.type,filterModeFormat_query?.value);creditNoteFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th>
              <span [style.color]="filterModeApply_Cutstomer?'var(--primary-color)':null">
                {{"DASHBORD_CUSTOMER" | translate}}

              </span>
              <p-columnFilter #customersFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name">
                <ng-template pTemplate="filter">
                  <app-customers-select *ngIf="filterModeApply_Cutstomer==false"  [(customer)]="selected_Cutstomerquery"
                    [filterMode]="true"></app-customers-select>
                  <input *ngIf="filterModeApply_Cutstomer==true" [disabled]="true"  [(ngModel)]="selected_Cutstomerquery.company_name"
                    class="p-mt-3" type="text" pInputText>

                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries();filterModeApply_Cutstomer=false"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!selected_Cutstomerquery?.id" label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('customer',null,selected_Cutstomerquery?.id);filterModeApply_Cutstomer=true;customersFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>
            <th pSortableColumn="date">

              <span [style.color]="filterModeDate_query?.type||filterModeDate_query?.value?'var(--primary-color)':null">
                {{"QUOTES_LIST_QUOTE_DATE" | translate}}</span>
              <p-sortIcon (click)="customSort('date')" field="date"></p-sortIcon>

              <p-columnFilter #qouteDateFilter class="p-ml-2" type="date" field="date" matchMode="equals" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">

                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeDate_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-calendar dateFormat="dd-mm-yy" [showIcon]="true" yearNavigator=true yearRange='2018:2024'
                      [(ngModel)]="filterModeDate_query.value"></p-calendar>
                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeDate_query?.type||!filterModeDate_query?.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('date',filterModeDate_query.type,filterModeDate_query.value);qouteDateFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th pSortableColumn="total">
              <span
                [style.color]="filterModeTotal_query?.type||filterModeTotal_query?.value?'var(--primary-color)':null">{{"QUOTES_LIST_TOTAL"
                | translate}} </span>
              <p-sortIcon (click)="customSort('total')" field="total"></p-sortIcon>
              <p-columnFilter #totalFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModeTotal_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0"[(ngModel)]="filterModeTotal_query.value" [minFractionDigits]="2" mode="decimal"></p-inputNumber>

                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeTotal_query?.value || !filterModeTotal_query?.type"
                        label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('total',filterModeTotal_query?.type,filterModeTotal_query?.value);totalFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>

                </ng-template>



              </p-columnFilter>
            </th>
            <th>

              <span
                [style.color]="filterModeStatus_query?.value&&filterModePaid_status?.value?'var(--primary-color)':null">{{"QUOTES_LIST_STATUS" | translate}}</span>
                <p-columnFilter #statusFilter class="p-ml-2" type="text" matchMode="equals" field="status.status.value" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="filter">


                  <p-dropdown [options]="statuses" [showClear]="true" [(ngModel)]="filterModeStatus_query.value"
                    placeholder='{{"SELECT_STATUS" | translate}}'optionLabel="status.value" optionValue="status.value">
                    <ng-template let-statuses pTemplate="item">
                      <span [class]="statuses.status.color">{{statuses.status.label}}</span>


                    </ng-template>

                  </p-dropdown>
                  <p-dropdown styleClass="p-mt-3" [options]="paid_status" [showClear]="true"
                  [(ngModel)]="filterModePaid_status.value" placeholder='{{"PAID_STATUS" | translate}}'
                  (onChange)="paid_Status_queries()" optionLabel="status.label" optionValue="status.value">
                  <ng-template let-statuses pTemplate="item">
                    <span [class]="statuses.status.color">{{statuses.status.label}}</span>


                  </ng-template>

                </p-dropdown>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearOrders_queries()" class="p-button-outlined"
                        type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeStatus_query?.value " label='{{"APPLY" | translate}}'
                        (click)="getOrders_queries('status',filterModeStatus_query?.value ,true);statusFilter.overlayVisible=false" type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-columnFilter>
            </th>

            <th>
              {{"QUOTES_LIST_PRINT_ORDER" | translate}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <!-- (click)="RemoveOrder(rowData)" -->
            <td><a (click)="showCreditNote(rowData,i)" href="javascript:;">{{ rowData?.reference }}</a></td>
            <td>{{ rowData?.company_name }}</td>
            <td>{{ rowData.date | date:'dd-MM-yyyy' }}</td>
            <td>{{ rowData.total | number:'1.2-2' }} <span style="color: var(--primary-color);font-size: small;">{{ rowData.currency?.name }}</span></td>
            <td>

              <span [class]="rowData.status?.status?.color">{{ rowData.status?.status?.label |translate}}</span>
              /
              <span  [class]="rowData.status?.status?.paid_color ">{{ rowData.status?.status?.paid_status|translate }}</span>


            </td>
            <td>


              <p-splitButton label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' [style]="{ height: '36px'}"
                [icon]="rowData.loadprint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'" [disabled]="loadingbtnPrint"
                (onClick)="printCreidtt(rowData.id,i)" (onDropdownClick)="onDropdownClick_language(rowData,i)"
                [model]="langOpctions" styleClass="p-button-secondary"></p-splitButton>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [title2]="credittatusTitle"
  [size]="size">
  <div header>
    <button style="margin-right: 5px;" *ngIf="selectedCreditNote?.status?.draft && selectedCreditNote?.id"
      (click)="confirm_Credit()" pButton type="button" icon="pi pi-check-square    " label='{{"CONFIRM_CRED_BOTE" | translate}}'
      class="p-button-secondary"></button>




    <p-splitButton *ngIf="selectedCreditNote?.id" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}' [style]="{ height: '36px'}"
      [icon]="selectedCreditNote.loadprint==true ? 'pi pi-spin pi-spinner': ' fas fa-print'"
      [disabled]="loadingbtnPrint" (onClick)="printCreidtt(selectedCreditNote?.id,creditSelectedIndex)"
      (onDropdownClick)="onDropdownClick_language(selectedCreditNote,creditSelectedIndex)" [model]="langOpctions"
      styleClass="p-button-secondary"></p-splitButton>

      <button *ngIf="selectedCreditNote?.id" pTooltip='{{"QUOTES_LIST_SEND_TO_EMAIL" | translate}}' tooltipPosition="bottom" style="margin-left: 5px;"
      pButton type="button" [disabled]="loadingbtnemail" (click)="sendByEmail()"
      [icon]="loadingbtnemail ? 'pi pi-spin pi-spinner': 'far fa-envelope'" class="p-button-secondary"></button>

      <button (click)="deleteseletectd_creditNote(selectedCreditNote)"
      *ngIf="selectedCreditNote?.id &&  !selectedCreditNote?.status?.issued" pButton pRipple type="button"
      icon="fas fa-trash" [pTooltip]="'Delete ' + selectedCreditNote?.reference" tooltipPosition="bottom"
      class="p-button-secondary" style="margin-left: 5px;"></button>




    <!-- <button pButton style="margin-left: 5px;" (click)="currecy.toggle($event)" [disabled]="selectedCreditNote?.id"
      class="p-button-secondary p-mr-2" type="button" [label]="selectedCurrancy?.name"> </button>
    <p-tieredMenu appendTo="body" #currecy [model]="currancyOptions" [popup]="true"></p-tieredMenu> -->

    <button *ngIf="!selectedCreditNote?.id" (click)="save()" pButton type="button"
      label=' {{"CUSTOMER_LIST_SAVE" | translate}}' class="ui-button-raised ui-button-info"></button>

    <button *ngIf="selectedCreditNote?.id" style="margin-left: 22px;" (click)="upadteFormClicked()" pButton
      type="button" label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>


  </div>
  <div body>
    <app-creditnotesCustomer *ngIf="showSidebar"></app-creditnotesCustomer>
  </div>
</app-sidebar>

<p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" class="p-shadow-1" appendTo="body"
  [resizable]="false" [draggable]="false"
  [header]="this.selected.length == 1 ? 'Delete '+ selected[0]?.reference : 'Delete Orders'"
  [(visible)]="removedialoguee" [style]="{width: '30vw'}">
  <div *ngIf="this.selected.length > 1 " class="p-text-justify "> {{"CON_DELETE_ORDERS" | translate}} ?
  </div>

  <div *ngIf="this.selected.length == 1 " class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}}
    <span style="color: var(--primary-color)">{{selected[0]?.reference}} ?</span>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="delete_Creditnote()" label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialoguee=false" label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}'
      class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body"
  [resizable]="false" [draggable]="false" class="p-shadow-1" [header]="selectedCreditNote?.reference"
  [(visible)]="pdfViewer" [style]="{width: '60vw'}">
  <div style=" position: relative;" [style.height.px]="pdfheight">
    <pdf-viewer (after-load-complete)="afterLoadComplete($event)" (on-progress)="pdfLoader($event)"
      [src]="selectedCreditNote?.view_url" [render-text]="true" [show-all]="true" [stick-to-page]=" true"
      [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"></pdf-viewer>
  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="fas fa-print" (click)="printPdf()" label='{{"QUOTES_LIST_PRINT_ORDER" | translate}}'></button>
    <button type="button" pButton icon="pi pi-download" (click)="downloadPdf()" label='{{"DOWNLOAD" | translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="pdfViewer=false" label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}'
      class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
