<app-loading *ngIf="loading"></app-loading>
<p-toast key="form" position="top-right"></p-toast>

<div class="p-grid">
  <div class="p-col-6">
    <p-card>
      <h3>
        <i class="fas fa-id-badge tags"></i>
        {{"CUSTOMER_FORM_MAIN_INFO"| translate}}
      </h3>
      <div class="p-grid">
        <div class=" p-col-12">
          <div class="p-inputgroup">
            <span class="p-float-label p-inputgroup">
              <input [(ngModel)]="customer.vat" (ngModelChange)="normalize_VAT($event)" (input)="getDataFromVat()"
                id="vat-input" type="text" pInputText>
              <label for="vat-input">{{"CUSTOMER_FORM_VAT_NO"| translate}}</label>
            </span>
            <button pButton (click)="getDataFromVat()" type="button" [disabled]="vatBtnDisabled"
              [icon]="vatBtnDisabled ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'"
              class="ui-button-warn"></button>
          </div>
        </div>
        <div class=" p-col-12">
          <span class="p-float-label">
            <input required minlength="3" (keydown.space)="preventDefault($event,company_name)" #company_name="ngModel"
              (ngModelChange)="notempty($event)" [(ngModel)]="customer.company_name" id="company-input" type="text"
              pInputText>
            <label for="company-input">{{"CUSTOMER_FORM_COMPANT_NAME"| translate}}&nbsp;

              <label
                *ngIf="customer.company_name == '' || customer.company_name == null &&  company_name.touched ; else company_name_err"
                class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

              <ng-template #company_name_err>
                <label *ngIf="company_name.invalid && company_name.dirty "
                  class="alert-invalid">{{"CUSTOMER_FORM_NOT_VALID"| translate}}</label>


              </ng-template>

            </label>

          </span>
        </div>


        <div *ngFor="let field of customer.main_info_extra_fields, let i = index" class=" p-col-6">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <input [(ngModel)]="field.value" (ngModelChange)="notempty($event)" id="maininfo-{{i}}-input" type="text"
                pInputText>
              <label for="maininfo-{{i}}-input"> {{ field.name }}</label>
            </span>
            <button (click)="customer.main_info_extra_fields.splice(i, 1)" pButton type="button" icon="pi pi-times"
              class="ui-button-danger"></button>
          </div>
        </div>
        <div class="p-col-6">
          <button [disabled]="customer.main_info_extra_fields?.length==4" style="font-size:medium; " icon="pi pi-plus"
            (click)="addCustomFieldSection = 'mainInfo';showAddCustomFieldDialog = true" pButton
            label='{{"CUSTOMER_FORM_ADD_CUSTOM_FIELD"| translate}}' class=" p-button-text p-button-plain  customerinplace" type="button"
           ></button>
        </div>

      </div>
    </p-card>

    <p-card>
      <div class="p-grid">
        <div class="p-col-7">
          <h3 class="p-m-0">
            <i class="fas fa-id-card tags"></i>

            {{"CUSTOMER_FORM_CONTACT"| translate}}
          </h3>
        </div>
        <div class="p-col-5" style="text-align: right">
          <p-button (onClick)="showAddContact()" icon="pi pi-plus" label='{{"CUSTOMER_FORM_ADD_CONTACT"| translate}}'>
          </p-button>
        </div>
      </div>
      <div *ngFor="let contact of customer.contact; let i = index" class="p-grid p-mt-2 border-bottom">
        <div class="p-col-6">
          <strong>{{ contact.name }}</strong>
        </div>
        <div class="p-col-6">
          <ul>
            <li *ngIf="contact.position"><i class="tags">{{"CUSTOMER_FORM_POSITION"| translate}} : </i> {{
              contact.position }}</li>
            <li *ngIf="contact.email"><i class="tags">{{"LOGIN_EMAIL"| translate}} : </i> {{ contact.email }}</li>
            <li *ngIf="contact.phone"><i class="tags"> {{"CUSTOMER_FORM_PHONE"| translate}} : </i> {{ contact.phone }}
            </li>
            <li *ngIf="contact.mobile_no"> <i class="tags">Mobile: </i> {{ contact.mobile_no }}</li>
            <li *ngIf="contact.note"><i class="tags">{{"CUSTOMER_FORM_NOTES"| translate}} : </i> {{ contact.note }}</li>

          </ul>


        </div>
        <div class="p-col-6 ">
        </div>

        <div class="p-col-12 text-align-right">
          <button type="button" pButton class="p-button-text p-ml-3" (click)="showEidtContact(i)"
            label='{{"CUSTOMER_LIST_EDIT"| translate}}' icon="fas fa-edit"></button>
          <button type="button" pButton (click)="rmContact(i)" class="p-button-text  p-button-danger "
            label='{{"CUSTOMER_FORM_REMOVE"| translate}}' icon="fas fa-trash"></button>

        </div>
      </div>
    </p-card>

    <p-card>
      <h3>
        <i class="fas fa-money-check-alt tags"></i>
        {{"DASHBORD_PAYMENT_DETAILS"| translate}}

      </h3>
      <div class=" p-grid">
        <div class="p-col-6 pb-4">
          <span class="p-float-label">
            <input [(ngModel)]="customer.payment_details.IBAN" (ngModelChange)="notempty($event)" id="iban-input"
              type="text" pInputText>
            <label for="iban-input">IBAN</label>
          </span>
        </div>



        <div class="p-col-6 pb-4">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="customer.payment_details.BIC" id="bic-input"
              type="text" pInputText>
            <label for="bic-input">{{"CUSTOMER_FORM_BIC"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 pb-4 ">
          <span class="p-float-label">

              <input (ngModelChange)="notempty($event)" [(ngModel)]="customer.payment_details.account_number"
              id="account-number-input" pInputText>

            <label for="account-number-input">{{"CUSTOMER_FORM_ACCOUNT_NO"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 pb-4">
          <span class="p-float-label">
            <p-dropdown id="showAccountmanager_flabel" #dd filter="true"
              [(ngModel)]="customer.payment_details.account_manager" (onChange)="accountmanagerChanged($event)"
              [options]='accountManagers' appendTo="body" (ngModelChange)="notempty($event)"
              placeholder='{{"CUSTOMER_FORM_ACCOUNT_MANG"| translate}}' optionLabel="name" [showClear]="true">
              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': customer.payment_details.account_manager == item}"
                      style="z-index: 10000;" pButton (click)="showeditAccontmanger(item)"
                      pTooltip="{{'CUSTOMER_LIST_EDIT'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-ml-sm-1" type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showdeleteAccontmanger(item)"
                      pTooltip="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="customer.payment_details?.account_manager!=null && showAccountmanager_flabel==false "
              for="showAccountmanager_flabel">{{"CUSTOMER_FORM_ACCOUNT_MANG"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 pb-4">
          <span class="p-float-label">
            <input #credits (ngModelChange)="notempty($event)" [(ngModel)]="customer.payment_details.bank_name"
              id="language-input" type="text" pInputText>
            <label for="language-input">{{"SUPPLIERS_FORM_BANK_NAME"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 pb-4 ">
          <span class="p-float-label " >
            <p-inputNumber [min]="0"[inputStyle]="{'width':credits.offsetWidth+'px'}" (ngModelChange)="notempty($event)"
              [minFractionDigits]="2" [(ngModel)]="customer.payment_details.credit_limit" id="credit-input"
              mode="decimal"></p-inputNumber>
            <label for="credit-input">{{"DASHBORD_CREDIT_LIMITS"| translate}}</label>
          </span>
        </div>


        <div class="p-col-12 pb-4">
          <span class="p-float-label">
            <textarea (ngModelChange)="notempty($event)" [(ngModel)]="customer.payment_details.payment_terms"
              id="payment-terms-input" pInputTextarea [autoResize]="true"></textarea>
            <label for="payment-terms-input">{{"CUSTOMER_FORM_PAY_TERMS"| translate}}</label>
          </span>
        </div>

      <div *ngFor="let field of customer.payment_details.extra_fields, let i = index" class="p-col-6 pt-4">
        <div class="p-inputgroup ">
          <span class="p-float-label ">
            <input [(ngModel)]="field.value" (ngModelChange)="notempty($event)" id="otherdetails-{{i}}-input"
              type="text" pInputText>
            <label for="otherdetails-{{i}}-input">{{ field.name }}</label>
          </span>
          <button (click)="customer.payment_details.extra_fields.splice(i, 1)" pButton type="button" icon="pi pi-times"
            class="ui-button-danger"></button>
        </div>
      </div>
      <div class="p-col-6 pt-4">
        <button style="font-size:medium; "  [disabled]="customer.payment_details?.extra_fields?.length==4" icon="pi pi-plus"
        (click)="addCustomFieldSection = 'otherDetails';showAddCustomFieldDialog = true" pButton
        label='{{"CUSTOMER_FORM_ADD_CUSTOM_FIELD"| translate}}' type="button"
        class=" p-button-text p-button-plain  customerinplace"></button>
      </div>
    </div>
    </p-card>
  </div>
  <div class="p-col-6">
    <p-card>
      <h3>
        <i class="fas fa-map-marker-alt tags"></i>
        {{"CUSTOMER_FORM_INV_ADDRESS"| translate}}
      </h3>

      <div class="p-grid">

          <div (ngModelChange)="notempty($event)" class="p-col-12 ">
            <span class="p-float-label">

              <input (ngModelChange)="notempty($event)" #shipInput [(ngModel)]="customer.invoicing_address.address"
                #address="ngModel" (keydown.space)="preventDefault($event,address)"
                placeholder='{{"CUSTOMER_FORM_ADDRESS"| translate}}' id="address-input" type="text" pInputText>

                <label  *ngIf=" customer.invoicing_address?.address?.length > 2  " style="top:-27% !important;" for="address-input">{{"CUSTOMER_FORM_ADDRESS"| translate}}</label>

            </span>
          </div>


          <div class="p-col-6 ">
            <span class="p-float-label">

              <p-dropdown #regionD filter="true" id="showRegion_flabel" (onChange)="regionChanged($event)"
                (ngModelChange)="notempty($event)" [(ngModel)]="customer.region" [options]="regions" optionLabel="name"
                placeholder="{{'DASHBORD_REGION'| translate}}" [showClear]="true">
                <ng-template let-item pTemplate="item">
                  <div class="p-grid">
                    <div class="p-col-8">
                      <span [innerHTML]="item.name"></span>
                    </div>
                    <div class="p-col-4">
                      <button *ngIf="(item.id != 0) && item.id != -1"
                        [ngClass]="{'lighteidtbtn': customer.region == item}" style="z-index: 10000;" pButton
                        (click)="showeditRegion(item)" pTooltip="{{'CUSTOMER_LIST_EDIT'| translate}} {{item.name}}"
                        tooltipPosition="bottom" class="p-button-text  p-ml-sm-1" type="button"
                        icon="fas fa-edit"></button>
                      <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                        (click)="showdeleteRegion(item)"
                        pTooltip="{{'CUSTOMER_LIST_DELETE_BUTTON' | translate}}  {{item.name}}" tooltipPosition="bottom"
                        class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                    </div>

                  </div>

                </ng-template>
              </p-dropdown>
              <label *ngIf="customer.region!=null && showRegion_flabel==false "
                for="showRegion_flabel">{{'DASHBORD_REGION'| translate}}</label>
            </span>
          </div>

          <div (ngModelChange)="notempty($event)" class="p-col-6">
            <span class="p-float-label">
              <input (ngModelChange)="notempty($event)" [(ngModel)]="customer.invoicing_address.zip"
                id="zip-input" type="number" pInputText/>
              <label for="zip-input">{{"CUSTOMER_FORM_ZIP"| translate}}
              </label>
            </span>
          </div>

          <div (ngModelChange)="notempty($event)" class="p-col-6">
            <span class="p-float-label">
              <input (keydown.space)="preventDefault($event,city)" [(ngModel)]="customer.invoicing_address.city"
                #city="ngModel" id="city-input" type="text" pInputText>
              <label for="city-input">{{"CUSTOMER_FORM_CITY" | translate}}
              </label>
            </span>
          </div>
          <div (ngModelChange)="notempty($event)" class="p-col-6 ">
            <span class="p-float-label ">
              <p-dropdown id="country-input" [options]="countries" [filter]="true" optionValue='name'
                [(ngModel)]="customer.invoicing_address.country_code" placeholder='{{"CUSTOMER_FORM_COUNTRY"| translate}}' optionLabel="name">
              </p-dropdown>
              <label  *ngIf="customer.invoicing_address.country_code!=null  " for="country-input">{{"CUSTOMER_FORM_COUNTRY"| translate}}</label>

            </span>
          </div>
      </div>
    </p-card>
    <p-card>
      <div class="p-grid">
        <div class="p-col-7">
          <h3 class="p-m-0">
            <i class="fas fa-map-marker-alt tags"></i>
            {{"CUSTOMER_FORM_SHIPP_ADDRESS"| translate}}
          </h3>
        </div>
        <div class="p-col-5" style="text-align: right">
          <p-button (onClick)="showAddAdrees()" icon="pi pi-plus" label='{{"CUSTOMER_FORM_ADD_ADDRESS"| translate}}'>
          </p-button>
        </div>
      </div>
      <div *ngFor="let address of customer.shipping_address; let i = index" class="p-grid p-mt-2 border-bottom">
        <div class="p-col-12">
          <h4>
            - <span class="tags">{{ address.address }},{{ address.zip }}, {{ address.city }}, {{
              address.country_code}}</span>
          </h4>
        </div>
        <div class="p-col-12 text-align-right">
          <button type="button" pButton class="p-button-text p-ml-3" (click)="showEidtAdrees(i)"
            label='{{"CUSTOMER_LIST_EDIT"| translate}}' icon="fas fa-edit"></button>
          <button type="button" pButton (click)="rmAdrees(i)" icon="fas fa-trash"
            class="p-button-text  p-button-danger " label='{{"CUSTOMER_FORM_REMOVE"| translate}}'></button>
        </div>
      </div>
    </p-card>
    <p-card>
      <h3>
        <i class="fas fa-dollar-sign tags"></i>
        {{"CUSTOMER_FORM_PRICING"| translate}}
      </h3>
      <div class="form-group p-grid">
        <div class="p-col-6 p-pb-4 p-pt-4">
          <span class="p-float-label">


              <p-inputNumber [min]="0"id="discount-input" [inputStyle]="{'width':credits.offsetWidth+'px'}" (ngModelChange)="notempty($event)" [(ngModel)]="customer.pricing.discount" mode="decimal" [minFractionDigits]="2"></p-inputNumber>

            <label for="discount-input">{{"CUSTOMER_FORM_DISCOUNT"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-pb-4 p-pt-4">
          <span class="p-float-label">
            <p-dropdown appendTo="body" id="showspecialPrices_flabel" #pp filter="true"
              (onChange)="specialPricesChanged($event)" (ngModelChange)="notempty($event)"
              [(ngModel)]="customer.pricing.special_price" [options]="specialPrices" optionLabel="name"
              placeholder='{{"CUSTOMER_FORM_SPIC_PRICE"| translate}}' [showClear]="true">
              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': customer.pricing.special_price == item}" style="z-index: 10000;"
                      pButton (click)="showeditSpecialPrice(item)"
                      pTooltip="{{'CUSTOMER_LIST_EDIT'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-ml-sm-1" type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showdeletespecialPrices(item)"
                      pTooltip="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="isSet(customer.pricing?.special_price) && showspecialPrices_flabel==false "
              for="showAccountmanager_flabel">{{"CUSTOMER_FORM_SPIC_PRICE"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label">
            <p-dropdown #cc filter="true" id="showpricingcategory_flabel" (onChange)="pricingCategoryChanged($event)"
              (ngModelChange)="notempty($event)" [(ngModel)]="customer.pricing.category" [options]="pricingCategories"
              optionLabel="name" placeholder='{{"CUSTOMER_FORM_CAT"| translate}}' [showClear]="true">
              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': customer.pricing.category == item}" style="z-index: 10000;" pButton
                      (click)="showeditpricingCategory(item)"
                      pTooltip="{{'CUSTOMER_LIST_EDIT'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-ml-sm-1" type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showdeletepricingCategory(item)"
                      pTooltip="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}} {{item.name}}" tooltipPosition="bottom"
                      class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="customer.pricing.category!=null && showpricingcategory_flabel==false "
              for="showpricingcategory_flabel">{{"CUSTOMER_FORM_CAT"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6">
          <span class="p-float-label">
            <p-inputNumber [min]="0"id="invoicing-frequency-input" [inputStyle]="{'width':credits.offsetWidth+'px'}" (ngModelChange)="notempty($event)" [(ngModel)]="customer.pricing.invoicing_frequency" mode="decimal"></p-inputNumber>


            <label for="invoicing-frequency-input">{{"CUSTOMER_FORM_INV_FREQ"| translate}}</label>



          </span>
        </div>
      </div>
    </p-card>
    <p-card>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-grid">
            <div class="form-group p-col-8">
              <h3>
                <i class="fa fa-paperclip tags"></i>

                {{"CUSTOMER_FORM_ATTACHMENT"| translate}}
              </h3>
            </div>
            <div style="text-align: right;" class="p-col-4">
              <p-button (onClick)="attachmentdilioge=true" icon="pi pi-plus"
                label='{{"CUSTOMER_FORM_ADD_ATT"| translate}}'></p-button>
            </div>
          </div>
          <div *ngIf="customer?.attachments?.length" class=" p-col-12">
            <div *ngFor="let item of customer.attachments;let i =index" class="p-grid attachmentFile p-mb-1">
              <div class="p-col-8 p-mb-3">
                <h4><a>
                    <i *ngIf="item.type=='application/pdf'" class="far fa-file-pdf"></i>
                    <i *ngIf="item.type!='application/pdf'" class="far fa-file-image"></i>

                    {{item.name}}</a></h4>
              </div>
              <div class="p-col-4 p-mb-3">
                <button type="button" pButton class="p-button-text p-ml-3 uploadtage" (click)="generate_download(item)"
                  label='{{"VIEW"| translate}}' icon="fas fa-eye"></button>
                <button type="button" pButton (click)="removerlink(item)" class="p-button-text  p-button-danger "
                  label='{{"CUSTOMER_FORM_REMOVE"| translate}}' icon="fas fa-trash"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="form-group">
            <span class="p-float-label">
              <textarea (ngModelChange)="notempty($event)" [(ngModel)]="customer.remark" id="reamrk-input"
                pInputTextarea [autoResize]="true"></textarea>
              <label for="reamrk-input">{{"CUSTOMER_FORM_REMARK"| translate}}</label>
            </span>
          </div>
        </div>

      </div>
    </p-card>
  </div>
</div>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  appendTo="body" [header]="ContactDialogHeader" [(visible)]="showContactDialog" [style]="{width: '30vw'}">
  <div class="p-grid ">
    <div class="p-col-12">
      <span [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched) }" class="p-float-label">
        <input required email (ngModelChange)="notempty($event)" [(ngModel)]="contact.email" #email="ngModel"
          name="email" id="email-input" type="text" pInputText>
        <label for="email-input">{{"LOGIN_EMAIL"| translate}}&nbsp;
          <label *ngIf="contact.email == '' || contact.email == null &&  email.touched ; else anotheral"
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>

          <ng-template #anotheral>
            <label *ngIf="email.invalid && email.dirty " class="alert-invalid">{{"CUSTOMER_FORM_NOT_VALID"|
              translate}}</label>
          </ng-template>
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input required minlength="2" (keydown.space)="preventDefault($event,contactname)"
          (ngModelChange)="notempty($event)" [(ngModel)]="contact.name" #contactname="ngModel" name="name"
          id="contact-name-input" type="text" pInputText>
        <label for="contact-name-input">{{"CUSTOMER_LIST_CONTACT_NAME"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (keydown.space)="preventDefault($event,position)" (ngModelChange)="notempty($event)"
          [(ngModel)]="contact.position" #position="ngModel" name="position" id="position-input" type="text" pInputText>
        <label for="position-input">{{"CUSTOMER_FORM_POSITION"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.phone" name="phone" id="phone-input" type="text"
          pInputText>
        <label for="phone-input">{{"CUSTOMER_FORM_PHONE"| translate}}
        </label>
      </span>
    </div>
    <div class="p-col-6">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" [(ngModel)]="contact.mobile_no" name="phone" id="phone-input"
          type="text" pInputText>
        <label for="mobile-input">Mobile Number</label>
      </span>
    </div>
    <div class="p-col-12">
      <span class="p-float-label">
        <textarea (ngModelChange)="notempty($event)" [(ngModel)]="contact.note" id="notes-input" pInputTextarea
          rows="4"></textarea>
        <label for="notes-input">{{"CUSTOMER_FORM_NOTES"| translate}}</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button *ngIf="ContactDialogHeader ==translate.instant('CUSTOMER_FORM_ADD_CONTACT') " type="button" pButton icon="pi pi-check" (click)="addContact()"
      label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button *ngIf="ContactDialogHeader ==translate.instant('EDIT_CONTACT') " type="button" pButton icon="pi pi-check"
      (click)="eidtContact()" label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="showContactDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  appendTo="body" [header]="shipingaddressDialogHeader" [(visible)]="showAddressDialog" [style]="{width: '40vw'}">



  <div class="p-grid">
    <div class="p-col-6">
      <span class="p-float-label">

        <input (ngModelChange)="notempty($event)" #addressInput [(ngModel)]="shipping_address.address"
          #shippingaddress="ngModel" (keydown.space)="preventDefault($event,shippingaddress)"
          id="address-input" type="text" pInputText>

          <label  [style.top]="shipping_address.address?.length > 2  ?'-27%':null" for="address-input"> {{"CUSTOMER_FORM_ADDRESS"| translate}} &nbsp;
            <label *ngIf="shipping_address.address == '' || shipping_address.address == null &&  shippingaddress.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
          </label>


      </span>
    </div>
    <div class="p-col-6">

        <span class="p-float-label">

          <p-inputMask (ngModelChange)="notempty($event)" [(ngModel)]="shipping_address.zip"
            #shipping_addresszip="ngModel" id="zip-input" mask="99999"></p-inputMask>
          <label for="zip-input">{{"CUSTOMER_FORM_ZIP"| translate}}&nbsp;
            <label *ngIf="shipping_address.zip == '' || shipping_address.zip == null &&  shipping_addresszip.touched  "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
          </label>
        </span>

    </div>
    <div class="p-col-6">
        <span class="p-float-label">
          <input required (keydown.space)="preventDefault($event,shipping_addresscity)"
            (ngModelChange)="notempty($event)" [(ngModel)]="shipping_address.city" #shipping_addresscity="ngModel"
            id="city-input" type="text" pInputText>
          <label for="city-input">{{"CUSTOMER_FORM_CITY"| translate}}&nbsp;
            <label
              *ngIf="shipping_address.city == '' || shipping_address.city == null &&  shipping_addresscity.touched  "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>


          </label>


        </span>

    </div>
    <div class="p-col-6">
        <span class="p-float-label">
          <label for="country-input">{{"CUSTOMER_FORM_COUNTRY"| translate}}&nbsp;
            <label
              *ngIf="shipping_address.country_code == '' || shipping_address.country_code == null &&  shipping_addresscountry_code.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
          </label>
          <p-dropdown appendTo="body" id="country-input" [options]="countries" [filter]="true" #shipping_addresscountry_code="ngModel"
            (keydown.space)="preventDefault($event,shipping_addresscountry_code)" optionValue='name'
            [(ngModel)]="shipping_address.country_code" placeholder='{{"CUSTOMER_FORM_COUNTRY"| translate}}' optionLabel="name">
          </p-dropdown>
        </span>

    </div>
  </div>


  <p-footer>
    <button [disabled]="!isSet(customer?.invoicing_address?.country_code)&&!isSet(customer?.invoicing_address?.zip)&& !isSet(customer?.invoicing_address?.city)  && !isSet(customer?.invoicing_address?.address) " *ngIf="shipingaddressDialogHeader == translate.instant('SUPPLIERS_FORM_ADD_SHIPP_NAME')"
      (click)="fillShippingAdrress()" type="button" pButton label='{{"FILL_INFO_ADD"| translate}}'></button>

    <button *ngIf="shipingaddressDialogHeader == translate.instant('SUPPLIERS_FORM_ADD_SHIPP_NAME')" type="button"
      pButton icon="pi pi-check" (click)="adShipping_address()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>

    <button *ngIf="shipingaddressDialogHeader == translate.instant('EDIT_SHIP_ADDRES')" type="button" pButton
      icon="pi pi-check" (click)="eidtAdrees()" label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>
    <button type="button" pButton icon="pi pi-times" label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}'
      class="p-button-outlined" (click)="showAddressDialog=false"></button>

  </p-footer>
</p-dialog>




<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  appendTo="body" header="{{'CUSTOMER_FORM_CUSTOM_FIELD'| translate}}" [(visible)]="showAddCustomFieldDialog"
  [style]="{width: '20vw'}">

  <div class="p-grid form-group">
    <div class="p-col-12">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" maxlength="30" [(ngModel)]="customFieldName" id="customfield-input"
          type="text" pInputText>
        <label for="customfield-input">{{"CUSTOMER_FORM_CUSTOM_FIELD_NAME"| translate}}</label>
      </span>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="addCustomField()"
      label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="showAddCustomFieldDialog=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="cancelspecialPrices()" [resizable]="false" [draggable]="false"
  class="p-shadow-1" [header]="specialPricesheader" [(visible)]="showSpecialPrice" [style]="{width: '21vw'}">
  <div class="p-grid p-mt-2 form-group">

    <div class="p-col-12 ">
      <span class="p-float-label">
        <input required #specialPrice="ngModel" [(ngModel)]="special_Price.name" id="specialPrice-input" type="text"
          pInputText>
        <label for="specialPrice-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
          <label *ngIf="special_Price.name == null &&  specialPrice.touched "
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label></label>
      </span>
    </div>

  </div>


  <p-footer>
    <button *ngIf="specialPricesheader==translate.instant('ADD_SPECAL_PRICE')" type="button" pButton icon="pi pi-check"
      (click)="save_specialPrices()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button *ngIf="specialPricesheader==translate.instant('EDIT_SPECAL_PRICE')" type="button" pButton icon="pi pi-check"
      (click)="updateSpecialPrice()" label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelspecialPrices()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="cancelshowAddAccontmanger()" [resizable]="false" [draggable]="false"
  class="p-shadow-1" [header]="addAccountManagerheader" [(visible)]="showAccountmanager" [style]="{width: '21vw'}">
  <div class="p-grid p-mt-2 form-group">

    <div class="p-col-12 ">
      <span class="p-float-label">
        <input required #accountManager="ngModel" [(ngModel)]="account_Manager.name" id="accountmanager-input"
          type="text" pInputText>
        <label for="accountmanager-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
          <label *ngIf="account_Manager.name == null &&  accountManager.touched "
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label></label>
      </span>
    </div>

  </div>


  <p-footer>
    <button *ngIf="addAccountManagerheader==translate.instant('NEW_ACCO_MAG')" type="button" pButton icon="pi pi-check"
      (click)="save_AccountManagers()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button *ngIf="addAccountManagerheader==translate.instant('CUSTOMER_FORM_EDIT_ACC_MANG')" type="button" pButton
      icon="pi pi-check" (click)="updateAccountManger()" label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelshowAddAccontmanger()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="cancelshowAddpricingCategory()" [resizable]="false"
  [draggable]="false" class="p-shadow-1" [header]="pricingCategoryheader" [(visible)]="showpricingCategoryDialog"
  [style]="{width: '21vw'}">
  <div class="p-grid p-mt-2 form-group">

    <div class="p-col-12 ">
      <span class="p-float-label">
        <input required #pricingCategory="ngModel" [(ngModel)]="pricing_Category.name" id="pricingCategory-input"
          type="text" pInputText>
        <label for="pricingCategory-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
          <label *ngIf="pricing_Category.name == null &&  pricingCategory.touched "
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label></label>
      </span>
    </div>

  </div>


  <p-footer>
    <button *ngIf="pricingCategoryheader==translate.instant('ADD_PRIC_CAT')" type="button" pButton icon="pi pi-check"
      (click)="save_pricingCategory()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button *ngIf="pricingCategoryheader==translate.instant('EDIT_PRICE_CAT')" type="button" pButton icon="pi pi-check"
      (click)="updatepricingCategory()" label='{{"EDIT_PRICE_CAT"| translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelshowAddpricingCategory()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
<!-- region -->
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="cancelshowAddREgion()" [resizable]="false" [draggable]="false"
  class="p-shadow-1" [header]="regionHeader" [(visible)]="showAddcRegion" [style]="{width: '21vw'}">
  <div class="p-grid p-mt-2 form-group">

    <div class="p-col-12 ">
      <span class="p-float-label">
        <input required [(ngModel)]="region.name" id="region-input" type="text" pInputText>
        <label for="region-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
        </label>
      </span>
    </div>

  </div>


  <p-footer>
    <button *ngIf="regionHeader==translate.instant('ADD_REGION')" type="button" pButton icon="pi pi-check" (click)="saveRegion()"
      label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
    <button *ngIf="regionHeader==translate.instant('EDIT_REGION')" type="button" pButton icon="pi pi-check" (click)="updateRegion()"
      label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelshowAddREgion()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>
<p-dialog  modal="true" (onShow)="outside_Status(true)" (onHide)="canceldeleteAccontmanger()" class="p-shadow-1"
  header='{{"DASHBORD_DELETE_ACCOUNT_MANGER"| translate}}' [(visible)]="showdeleteAccountmanager"
  [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
      style="color: var(--primary-color)">{{account_Manager.name}}</span> ?</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deleteAccontmanger()"
      label="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}}"></button>
    <button type="button" pButton icon="pi pi-times" (click)="canceldeleteAccontmanger()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true"(onShow)="outside_Status(true)" (onHide)="canceldeletespecialPrices()" class="p-shadow-1"
  header='{{"DASHBORD_DELETE_SPEICAL_PRICE"| translate}}' [(visible)]="showdeleteSpecialPrice"
  [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
      style="color: var(--primary-color)">{{special_Price.name}}</span> ?</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deletespecialPrice()"
      label="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}}"></button>
    <button type="button" pButton icon="pi pi-times" (click)="canceldeletespecialPrices()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="canceldeletepricingCategory()" class="p-shadow-1"
  header='{{"DASHBORD_DELETE_PRICE_CATAGORY"| translate}}' [(visible)]="showdeletePricingCategory"
  [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
      style="color: var(--primary-color)">{{pricing_Category.name}}</span> ?</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deletepricingCategory()"
      label="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}}"></button>
    <button type="button" pButton icon="pi pi-times" (click)="canceldeletepricingCategory()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="canceldeleteRegion()" class="p-shadow-1" header="Delete Region"
  [(visible)]="showDeletedRegion" [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
      style="color: var(--primary-color)">{{region.name}}</span> ?</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deleteRegion()"
      label="{{'CUSTOMER_LIST_DELETE_BUTTON'| translate}}"></button>
    <button type="button" pButton icon="pi pi-times" (click)="canceldeleteRegion()"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>
<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body" [resizable]="false"
  [draggable]="false" class="p-shadow-1" [header]="'View '+customer?.seletectAttachment?.name" [(visible)]="fileViewer"
  [style]="{width: '60vw'}">

  <div style="height: 1000px; position: relative;">
    <div
      *ngIf="(customer?.seletectAttachment?.type=='application/pdf') ||(customer?.seletectAttachment?.type=='application/pdf') ;else img_viewer"
      style=" position: relative;" [style.height.px]="pdfheight">
      <pdf-viewer (after-load-complete)="afterLoadComplete($event)" [src]="customer?.view_url" [render-text]="true"
        [show-all]="true" [stick-to-page]=" true" [zoom-scale]="'page-width'" [zoom]="0.9" style="display: block"
        (on-progress)="pdfLoader($event)"></pdf-viewer>
    </div>
    <ng-template #img_viewer>
      <div class="p-grid text-align-center">
        <div class="p-col-12"><img [src]="customer?.view_url "></div>
      </div>


    </ng-template>



  </div>

  <p-footer class="p-pt-4">
    <button type="button" pButton icon="pi pi-download" (click)="downloadlink()"
      label='{{"DOWNLOAD" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="fileViewer=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
  </p-footer>
</p-dialog>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  class="p-shadow-1" header='{{"CUSTOMER_FORM_ADD_ATT"| translate}}' [(visible)]="attachmentdilioge"
  [style]="{width: '30vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" #attachmentname="ngModel" [(ngModel)]="attachment_name"
          id="Name-attachments-input" type="text" pInputText>
        <label for="Name-attachments-input">{{"CUSTOMER_FORM_NAME_ATT"| translate}} &nbsp;

          <label *ngIf="attachment_name == '' || attachment_name == null &&  attachmentname.touched "
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
        </label>
      </span>
    </div>

  </div>


  <p-footer class="p-pt-4">
    <button pButton type="button" style="margin-right: 5px;" [icon]="loading ? 'pi pi-spin pi-spinner': ' pi pi-upload'"
      (click)=" showUploader()" label='{{"UPLOAD"| translate}}' class=" ui-button-info" for="upload-photo"></button>
    <div style="display: inline-block;">
      <input #upload type="file" id="upload-photo" [imageToDataUrl]="options" (imageSelected)="myUploader($event)">
    </div>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="attachmentdilioge=false"
      label="{{'CUSTOMER_LIST_CANACEL_BUTTON'| translate}}" class="p-button-outlined ui-button-secondary"></button>

  </p-footer>
</p-dialog>
