import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { PaginatedResponse } from '../../models/paginatedresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PurchaseOrders } from 'src/app/models/PurchaseOrders/PurchaseOrders';
import { Warehouses } from 'src/app/models/PurchaseOrders/warehouses';
import { isSet } from '../core/util';
import { Payment } from 'src/app/models/orders/Payment';
import { Lang, PrintOrder } from '../../models/printorder';
import { SendEmail } from 'src/app/models/SendEmail';
import { Product } from 'src/app/models/product';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {
  public orderPrintLang: string;
  public orderCurrany: string;
  public qoutePrintLang: string;
  public currentPurchaseOrders: PurchaseOrders;
  public attachmentlink: string;
  public queris = []
  sort: any

  public newPayments: Payment[] = []

  public convertPurchaceClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public convertPurchaceClickedEmitter: Observable<boolean> = this.convertPurchaceClicked.asObservable();

  public saveFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormClickedEmitter: Observable<boolean> = this.saveFormClicked.asObservable();

  public saveFormQuoteClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveFormQuoteClickedEmitter: Observable<boolean> = this.saveFormQuoteClicked.asObservable();

  
  public btnLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public btnLoadingEmitter: Observable<boolean> = this.btnLoading.asObservable();
  
  public refreshList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public refreshListEmitter: Observable<boolean> = this.refreshList.asObservable();


  public convertOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public convertOrdertEmitter: Observable<boolean> = this.convertOrder.asObservable();

  public PurchasesSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public PurchaseSelectedListEmitter: Observable<any> = this.PurchasesSelected.asObservable();

  public printQuote_link: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public printQuote_linkEmitter: Observable<any> = this.printQuote_link.asObservable();

  public retrieve_lastOrder: BehaviorSubject<PurchaseOrders> = new BehaviorSubject<PurchaseOrders>(null);
  public retrieve_lastOrderEmitter: Observable<PurchaseOrders> = this.retrieve_lastOrder.asObservable();

  public retrieve_lastQuote: BehaviorSubject<PurchaseOrders> = new BehaviorSubject<PurchaseOrders>(null);
  public retrieve_lastQuoteEmitter: Observable<PurchaseOrders> = this.retrieve_lastQuote.asObservable();

  public uploadkey: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadkeyEmitter: Observable<any> = this.uploadkey.asObservable();

  public uploadstuats: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadstuatsEmitter: Observable<any> = this.uploadstuats.asObservable();

  public uploadDetils: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadDetilsEmitter: Observable<any> = this.uploadDetils.asObservable();

  public sendEmailClicked: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sendEmailClickedEmitter: Observable<any> = this.sendEmailClicked.asObservable();

  public uploadInovice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public uploadInoviceEmitter: Observable<any> = this.uploadInovice.asObservable();

  public duplicateClicked: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public duplicateClickedEmitter: Observable<any> = this.duplicateClicked.asObservable();

  public checkQoute: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public checkQouteEmitter: Observable<any> = this.checkQoute.asObservable();

  public upadteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteFormClickedEmitter: Observable<boolean> = this.upadteFormClicked.asObservable();

  public upadteQuoteFormClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public upadteQuoteFormClickedEmitter: Observable<boolean> = this.upadteQuoteFormClicked.asObservable();

  public refreshselected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public refreshselectedEmitter: Observable<any> = this.refreshselected.asObservable();

  public onPrintOrder: BehaviorSubject<PrintOrder> = new BehaviorSubject<PrintOrder>(null);
  public onPrintOrderEmitter: Observable<PrintOrder> = this.onPrintOrder.asObservable();

  public currencyChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currencyChangedEmitter: Observable<any> = this.currencyChanged.asObservable();

  constructor(private api: ApiService, private datePipe: DatePipe,) {
  }

  getPurchaseOrders() {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {
        if (isSet(querirs_[index].query_type)) {

          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        } else {
          querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_value}`
          querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
        }
      }
      let sum_querirs = ''
      for (let index = 0; index < querirs_.length; index++) {
        sum_querirs = sum_querirs + querirs_[index]
      }

      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`/purchases?${sum_querirs}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`/purchases?order=${this.sort}`)
    }
  }
  purchaseOrderNextPage(num): Observable<PaginatedResponse<PurchaseOrders[]>> {
    if (this.queris.length) {
      let querirs_ = [...this.queris]
      const length: number = querirs_.length - 1
      for (let index = 0; index < querirs_.length; index++) {

        querirs_[index] = `query=${querirs_[index].query_name},${querirs_[index].query_type},${querirs_[index].query_value}`
        querirs_[index] = index != length ? querirs_[index] + '&' : querirs_[index]
      }
      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`/purchases?${querirs_}&page=${num}&order=${this.sort}`);

    }
    if (!this.queris.length) {
      return this.api.get<PaginatedResponse<PurchaseOrders[]>>(`/purchases?page=${num}&order=${this.sort}`);

    }
  }
  getPurchaseQuotes() {
    return this.api.get<PaginatedResponse<PurchaseOrders[]>>('/purchases_quotes');
  }

  getPurchaseOrder(id: string): Observable<PurchaseOrders> {
    return this.api.get<PurchaseOrders>(`/purchases/rud/${id}`);
  }

  getPurchaseQuote(id: string): Observable<PurchaseOrders> {
    return this.api.get<PurchaseOrders>(`/purchases_quotes/rud/${id}`);
  }
  getNextOrderNumber(): Observable<{ internal_reference: string }> {
    return this.api.get<{ internal_reference: string }>('/purchases/next_internal_reference');
  }

  getNextQuoteNumber(): Observable<{ internal_reference: string }> {
    return this.api.get<{ internal_reference: string }>('/purchases_quotes/next_internal_reference');
  }

  addPurchaseOrder(purchaseOrders: PurchaseOrders): Observable<PurchaseOrders> {
    let discont = purchaseOrders.discount

   let invoice_date=this.datePipe.transform(purchaseOrders?.invoice_date, 'yyyy-MM-dd')
    if (purchaseOrders.discount_type == 'Percent') {
      discont = purchaseOrders.discount + '%'
    }

    const date = this.datePipe.transform(purchaseOrders.date, 'yyyy-MM-dd')
    for (let x = 0; x < purchaseOrders.items.length; x++) {
      if (isSet(purchaseOrders.items[x].service)) {
        purchaseOrders.items[x].name = null
        purchaseOrders.items[x].product = new Product()

      }

    }
    console.log(purchaseOrders.toDB(date, discont,invoice_date));
    
    return this.api.post('/purchases', purchaseOrders.toDB(date, discont,invoice_date));
  }

  addPurchaseQuote(purchase_quote: PurchaseOrders): Observable<PurchaseOrders> {
    const date = this.datePipe.transform(purchase_quote.date, 'yyyy-MM-dd')

    for (let x = 0; x < purchase_quote.items.length; x++) {
      if (isSet(purchase_quote.items[x].service)) {
        purchase_quote.items[x].name = null
        purchase_quote.items[x].product = new Product()

      }

    }

    return this.api.post('/purchases_quotes', purchase_quote.toDBQuote(date));
  }
  saveForm() {
    this.saveFormClicked.next(true);
  }


  deletePurchaseOrders(id: string): Observable<PurchaseOrders> {
    return this.api.delete<PurchaseOrders>(`/purchases/rud/${id}`);
  }

  getorderbyname(oderformat: string): Observable<PurchaseOrders> {
    return this.api.get<PurchaseOrders>(`/purchases/${oderformat}/using_format`);

  }
  getwarehouses(): Observable<PaginatedResponse<Warehouses[]>> {
    return this.api.get<PaginatedResponse<Warehouses[]>>('/warehouses');
  }
  updatewarehouse(warehouses: Warehouses): Observable<Warehouses> {
    let update = {
      name: warehouses.name
    }
    return this.api.patch<Warehouses>(`/warehouses/${warehouses.id}`, update)

  }
  addWarehouses(warehouses: Warehouses): Observable<Warehouses> {
    return this.api.post<Warehouses>(`/warehouses`, warehouses.toDB())
  }
  deletewarehouses(id: string): Observable<Warehouses> {
    return this.api.delete<Warehouses>(`/warehouses/${id}`);

  }
  upadePurchaseQuote(purchaseOrders: PurchaseOrders) {
    let date = this.datePipe.transform(purchaseOrders.date, 'yyyy-MM-dd')
    let itemstoUpdate = []
    purchaseOrders.items.map(item => {
      item.price = Number(item.price)
      item.qty = Number(item.qty)
      item.vat.value = Number(item.vat.value)
      item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')

    })
    for (let x = 0; x < purchaseOrders.items.length; x++) {


      itemstoUpdate[x] = {
        id: purchaseOrders.items[x]?.id,
        product: purchaseOrders.items[x].product?.id,
        date: purchaseOrders.items[x]?.date,
        name: purchaseOrders.items[x].product?.name,
        qty: purchaseOrders.items[x]?.qty,
        service: purchaseOrders.items[x]?.service,

      }

    }

    let update = {
      date: date,
      vendor: purchaseOrders.vendor?.id,
      notes: purchaseOrders?.notes,
      items: itemstoUpdate,
      payment_terms: purchaseOrders?.payment_terms,
    }


    return this.api.patch<any>(`/purchases_quotes/rud/${purchaseOrders.id}`, update)
  }
  upadePurchaseOrder(purchaseOrders: PurchaseOrders) {
    let date = this.datePipe.transform(purchaseOrders.date, 'yyyy-MM-dd')
    let itemstoUpdate = []
    let discount = purchaseOrders.discount
    if (purchaseOrders.discount_type == 'Percent') {
      discount = purchaseOrders.discount + '%'
    }
    if (purchaseOrders.discount == 0) {
      discount = '0'
    }
    purchaseOrders.items.map(item => {
      item.price = Number(item.price)
      item.qty = Number(item.qty)
      item.vat.value = Number(item.vat.value)
      item.date = this.datePipe.transform(item.datePipe, 'yyyy-MM-dd')

    })
    for (let x = 0; x < purchaseOrders.items.length; x++) {


      itemstoUpdate[x] = {
        id: purchaseOrders.items[x].id,
        product: purchaseOrders.items[x].product?.id,
        date: purchaseOrders.items[x].date,
        created_at: purchaseOrders.items[x].created_at,
        name: purchaseOrders.items[x].product?.name,
        qty: purchaseOrders.items[x].qty,
        price: purchaseOrders.items[x].price,
        vat: purchaseOrders.items[x].vat?.id,
        warehouse: purchaseOrders.items[x].warehouse?.id,
        service: purchaseOrders.items[x]?.service,
        received_qty: Number(purchaseOrders.items[x].received_qty),

      }

    }

    let update = {
      date: date,
      vendor: purchaseOrders.vendor?.id,
      notes: purchaseOrders?.notes,
      payment_terms: purchaseOrders?.payment_terms,
      items: itemstoUpdate,
      payments: this.newPayments,
      invoice_attachment: purchaseOrders?.invoice_attachment,
      discount: discount,
      exchange_rate : purchaseOrders?.exchange_rate,
      invoice_date : this.datePipe.transform(purchaseOrders?.invoice_date, 'yyyy-MM-dd')
    }

    for (let index = 0; index < update.payments.length; index++) {
      update.payments[index].date = this.datePipe.transform(update.payments[index].dateP, 'yyyy-MM-dd')

    }
    update.payments.map(payment => {
      if (!isSet(payment.notes)) {
        delete payment.notes;
      }
    })

console.log(update);

    return this.api.patch<any>(`/purchases/rud/${purchaseOrders.id}`, update)
  }

  refreshDataTable() {
    this.refreshList.next(true);
  }
  convertToOrder(Quote: PurchaseOrders) {

    let puracheseQuote = {
      invoice_format: Quote.internal_reference,
      invoice_date: Quote.date
    }


    return this.api.post<any>(`/purchases_quotes/to_purchase_order/${Quote.id}`, puracheseQuote)
  }
  upload(filee: any) {
    const subscription = this.uploadDetilsEmitter.subscribe(uploadDetils => {
      if (!isSet(uploadDetils)) {
        return;
      }

      const credential = uploadDetils.upload_data.fields as credentialfilee


      const body = new FormData()
      this.uploadkey.next(credential.key)
      body.append("key", credential.key)
      body.append("x-amz-algorithm", credential['x-amz-algorithm'])
      body.append("x-amz-credential", credential['x-amz-credential'])
      body.append("x-amz-date", credential['x-amz-date'])
      body.append("policy", credential.policy)
      body.append("x-amz-signature", credential['x-amz-signature'])
      body.append("file", filee, filee.name)

      const sub = this.api.Uploadfile<any>(uploadDetils.upload_data.url, body).subscribe(() => {
        this.attachmentlink = credential.key

        this.uploadstuats.next(true)
        sub.unsubscribe()
      }, error => {
        this.uploadstuats.next(false)
        sub.unsubscribe()
      }
      )
      this.uploadDetils.next(null)
      subscription.unsubscribe()

    }, error => {
      subscription.unsubscribe()
    })
  }
  SendEmailOrders(SendEmail: SendEmail, id: string): Observable<SendEmail> {
    return this.api.post(`/purchases/send_purchase_order_mail/${id}`, SendEmail.toDB());
  }
  SendEmailQuotes(SendEmail: SendEmail, id: string): Observable<SendEmail> {
    return this.api.post(`/purchases_quotes/send_purchase_quote_mail/${id}`, SendEmail.toDB());
  }
  PrintQuote(id: string): Observable<any> {
    let printLang

    if (!isSet(this.qoutePrintLang)) {
      printLang = JSON.parse(localStorage.getItem("currentLang"))?.lang.toLocaleLowerCase() || 'en'

    } else {
      printLang = this.qoutePrintLang?.toLocaleLowerCase()
    }
    let body = {
      lang: printLang
    }

    return this.api.post<any>(`/purchases_quotes/print_purchase_quote/${id}`, body);
  }
  PrintOrder(id: string): Observable<any> {
    let printLang

    if (!isSet(this.orderPrintLang)) {
      printLang = JSON.parse(localStorage.getItem("currentLang"))?.lang.toLocaleLowerCase() || 'en'

    } else {
      printLang = this.orderPrintLang?.toLocaleLowerCase()
    }
    let body = {
      lang: printLang
    }

    return this.api.post<any>(`/purchases/print_purchase_order/${id}`, body);
  }
  generate_Upload(file: any) {
    const subscription = this.api.generate_upload_url<any>(`/generate_s3_upload_url`, file).subscribe(uploadDetils => {

      if (!isSet(uploadDetils)) {
        return
      }
      subscription.unsubscribe()
      return this.uploadDetils.next(uploadDetils)


    })
  }
  generate_download(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.generate_upload_url<any>(`/generate_s3_download_url`, d)
  }
  delete_File(filename: any) {
    let d = {
      file_type: 'user_report',
      file_name: filename
    }
    return this.api.deleteFile(`/delete_s3_file`, d)
  }
  convertToPurchaseOrder(id: string): Observable<PurchaseOrders> {
    
    return this.api.post<PurchaseOrders>(`/purchases_quotes/complete_purchase_quote/${id}`, null);

  }

}
export class credentialfilee {
  "key": string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "policy": string
  "x-amz-signature": string
}