<app-loading *ngIf="loading"></app-loading>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
    <div class="p-col-12">
      <div class="card p-0 pt-1">
        <app-table-header (Reload)='Reload()' (remove)="remove()" (add)="showAddNewRole()"
        addLabel='Create Roles' [disableRemove]="!selected.length">
          <app-Search-select [selecteditem]="itemSearch" (completeMethod)="completeMethod($event)"
          (onClear)="clearRoles_queries()" [Searchitems]="filteredRoles" [field_name]="keyword"
          (filter)="serach($event)"></app-Search-select>
      </app-table-header>

      <p-table appendTo="body" [customSort]="true" styleClass="p-datatable-striped"
      (onPage)="getNextRolePage($event)" [value]="roles" [paginator]="true" [rows]="10"
      [(selection)]="selected" [scrollable]="true" [rowsPerPageOptions]="[10,30]" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {{rolesCount}} entries"
      [globalFilterFields]="['name']">
            <ng-template pTemplate="header" let-columns>
                <th style="width: 3em">
                    <!-- <p-tableHeaderCheckbox (click)="deleteRow(selected)"></p-tableHeaderCheckbox> -->
                  </th>
                  <th pSortableColumn="name">
                    <span
                      [style.color]="selected_Rolesnamequery?.type && selected_Rolesnamequery?.value?'var(--primary-color)':null">
                      {{"ROLES_LIST_NAME"| translate}} </span>
                    <p-sortIcon (click)="customSort('name')" field="name"></p-sortIcon>
      
                    <p-columnFilter #companyNameFilter matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false"
                      [showAddButton]="false" type="text" field="reference" display="menu">
                      <ng-template id="statusFilter" pTemplate="filter">
      
                        <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="selected_Rolesnamequery.type"
                          placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="type" optionLabel="type">
                          <ng-template let-item pTemplate="item">
                            <span>{{item.type}}</span>
                          </ng-template>
                        </p-dropdown>
      
                        <input [(ngModel)]="selected_Rolesnamequery.value" class="p-mt-3" type="text" pInputText>
                        <div class="p-grid p-mt-2">
                          <div class="p-col-6">
                            <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearRoles_queries()"
                              class="p-button-outlined" type="button">
                            </button>
                          </div>
                          <div class="p-col-6">
                            <button pButton [disabled]="!selected_Rolesnamequery?.type || !selected_Rolesnamequery?.value"
                              label='{{"APPLY" | translate}}'
                              (click)="getRoles_queries('name',selected_Rolesnamequery?.type,selected_Rolesnamequery?.value);companyNameFilter.overlayVisible=false"
                              type="button">
                            </button>
                          </div>
                        </div>
                      </ng-template>
      
                    </p-columnFilter>
      
      
                  </th>

            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td style="width: 3em">
                    <p-tableCheckbox (click)="selectedRole(rowData)" [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td><a (click)="showRole(rowData)" href="javascript:;">{{ rowData.name }}</a></td>
                </tr>
            </ng-template>

    </p-table>
      </div>
    </div>
</div>

<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [size]="size">
  <div header>
    <button pButton (click)="saveForm()" *ngIf="!selectedSingleRole" type="button"
      label='{{"ROLE_LIST_SAVE"| translate}}' class="ui-button-raised ui-button-info"></button>
    <button *ngIf=" selectedSingleRole  && currentModal == 'Form'" (click)="upadteFormClicked()" pButton type="button"
      label='{{"ROLE_LIST_UPDATE"| translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-role-form *ngIf="currentModal == 'Form' && showSidebar"></app-role-form>
  </div>
</app-sidebar>