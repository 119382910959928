<app-loading *ngIf="loading"></app-loading>


<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-12">
    <div class="card">
      <app-table-header (Reload)='Reload()' (remove)="remoe()" addLabel=' {{"PRODUCT_LIST_CREATE_PRO" | translate}}'
        [disableRemove]="!selected.length" (add)="showAddProduct()">
        <app-Search-select [selecteditem]="itemSearch" (onClear)="clearProdutct_queries()" [Searchitems]="filteredProducts" [field_name]="keyword"
        (completeMethod)="completeMethod($event)"  (filter)="serach($event)"></app-Search-select>

      </app-table-header>
      <p-table styleClass="p-datatable-striped" [paginator]="true" [rows]="10" [value]="products" [customSort]="true"
        (onPage)="getNextproductPage($event)" [(selection)]="selected" [rowsPerPageOptions]="[10,30]"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['vatvalue','name','sell_price','category','created_at']">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em">
              <!-- <p-tableHeaderCheckbox (click)="deleteRow(selected)"></p-tableHeaderCheckbox> -->
            </th>
            <th pSortableColumn="name">
              <span
                [style.color]="filterModeName_query?.type && filterModeName_query?.value?'var(--primary-color)':null">
                {{"DASHBORD_PRODUCT" | translate}}</span>

              <p-sortIcon (click)="customSort('name')" field="name"></p-sortIcon>

              <p-columnFilter #productNameFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="company_name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <p-dropdown [options]="query_types" [showClear]="true" [(ngModel)]="filterModeName_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE" | translate}} ' optionValue="type" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>

                  <input [(ngModel)]="filterModeName_query.value" placeholder="Enter Reference" class="p-mt-3"
                    type="text" pInputText>

                  <div class="p-grid p-mt-2">

                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearProdutct_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton
                        [disabled]="(!filterModeName_query?.type || !filterModeName_query?.value) && !filterModeType_query.value"
                        label='{{"APPLY" | translate}}'
                        (click)="getProduts_queries('name',filterModeName_query?.type,filterModeName_query?.value);productNameFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>
            <th>

              <span
                [style.color]="filterModeApply_BarCode?.type && filterModeApply_BarCode?.value?'var(--primary-color)':null">
                {{"PRODUCT_LIST_BARCODE" | translate}}</span>


              <p-columnFilter #productBarcodeFilter class="p-ml-2" matchMode="equals" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false" type="text" field="name" display="menu">
                <ng-template id="statusFilter" pTemplate="filter">

                  <input [(ngModel)]="filterModeApply_BarCode.value" placeholder="Enter Barode" class="p-mt-3"
                    type="text" pInputText>

                  <div class="p-grid p-mt-2">

                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearProdutct_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModeApply_BarCode.value" label='{{"APPLY" | translate}}'
                        (click)="searchProductBarCode(filterModeApply_BarCode.value);productBarcodeFilter.overlayVisible=false" type="button">
                      </button>
                    </div>
                  </div>
                </ng-template>

              </p-columnFilter>
            </th>

            <th pSortableColumn="sell_price">
              <span
                [style.color]="filterModePrice_query?.type||filterModePrice_query?.value?'var(--primary-color)':null">{{"QUOTES_Form_PRICE"
                | translate}}</span>
              <p-sortIcon (click)="customSort('sell_price')" field="sell_price"></p-sortIcon>
              <p-columnFilter #priceFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

                <ng-template pTemplate="filter">
                  <p-dropdown [options]="query_types2" [showClear]="true" [(ngModel)]="filterModePrice_query.type"
                    placeholder='{{"SELECT_FLTER_TYPE"| translate}}' optionValue="value" optionLabel="type">
                    <ng-template let-item pTemplate="item">
                      <span>{{item.type}}</span>
                    </ng-template>
                  </p-dropdown>
                  <div class="p-mt-3">
                    <p-inputNumber [min]="0" [(ngModel)]="filterModePrice_query.value" [minFractionDigits]="2"
                      mode="decimal"></p-inputNumber>

                  </div>
                  <div class="p-grid p-mt-2">
                    <div class="p-col-6">
                      <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearProdutct_queries()"
                        class="p-button-outlined" type="button">
                      </button>
                    </div>
                    <div class="p-col-6">
                      <button pButton [disabled]="!filterModePrice_query?.value || !filterModePrice_query?.type"
                        label='{{"APPLY" | translate}}'
                        (click)="getProduts_queries('sell_price',filterModePrice_query?.type,filterModePrice_query?.value);priceFilter.overlayVisible=false"
                        type="button">
                      </button>
                    </div>
                  </div>

                </ng-template>



              </p-columnFilter>
            </th>
            <th>{{"CUSTOMER_LIST_VAT" | translate}} <i class="fas fa-percent"></i>


            </th>


            <th>
          
              <span
              [style.color]="filterModeCat_query?.id?'var(--primary-color)':null">{{"CUSTOMER_FORM_CAT"
              | translate}}</span>
              <p-columnFilter #categoryFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
              [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

              <ng-template pTemplate="filter">
                <p-dropdown filter="true"  [options]="categories" [showClear]="true" [(ngModel)]="filterModeCat_query"
                  placeholder="Select Category " optionLabel="name">
                  <ng-template let-item pTemplate="item">
                    <span>{{item.name}}</span>
                  </ng-template>
                </p-dropdown>
             
                <div class="p-grid p-mt-2">
                  <div class="p-col-6">
                    <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearProdutct_queries()"
                      class="p-button-outlined" type="button">
                    </button>
                  </div>
                  <div class="p-col-6">
                    <button pButton [disabled]="!filterModeCat_query?.id"
                      label='{{"APPLY" | translate}}'
                      (click)="getProduts_queries('category',null,filterModeCat_query?.id);categoryFilter.overlayVisible=false"
                      type="button">
                    </button>
                  </div>
                </div>

              </ng-template>



            </p-columnFilter>

            </th>
            <th>
              <span
              [style.color]="filterModeBrand_query?.id?'var(--primary-color)':null">Brand</span>

              <p-columnFilter #brandFilter class="p-ml-2" type="text" matchMode="equals" field="total" display="menu"
              [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

              <ng-template pTemplate="filter">
                <p-dropdown filter="true"  [options]="brands" [showClear]="true" [(ngModel)]="filterModeBrand_query"
                  placeholder="Select Brand " optionLabel="name">
                  <ng-template let-item pTemplate="item">
                    <span>{{item.name}}</span>
                  </ng-template>
                </p-dropdown>
             
                <div class="p-grid p-mt-2">
                  <div class="p-col-6">
                    <button pButton label='{{"SIDE_BAR_CLEAR" | translate}}' (click)="clearProdutct_queries()"
                      class="p-button-outlined" type="button">
                    </button>
                  </div>
                  <div class="p-col-6">
                    <button pButton [disabled]="!filterModeBrand_query?.id"
                      label='{{"APPLY" | translate}}'
                      (click)="getProduts_queries('brand',null,filterModeBrand_query?.id);brandFilter.overlayVisible=false"
                      type="button">
                    </button>
                  </div>
                </div>

              </ng-template>



            </p-columnFilter>
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 3em">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td><a (click)="showEditProduct(rowData)" href="javascript:">{{ rowData.name }}</a></td>
            <td>

              <span *ngIf="rowData?.barcode">
                {{ rowData?.barcode }}
              </span>
              <span *ngIf="!rowData?.barcode">ــ</span>
            </td>

            <td>{{ rowData.sell_price | number:'1.2-2'}} <span style="color: var(--primary-color);font-size: small;">{{
                selectedCurrancy?.name }}</span></td>
            <td>

              <span *ngIf="rowData?.vat?.value">
                {{ rowData?.vat?.value | number:'1.2-2'}} %
              </span>
              <span *ngIf="!rowData?.vat?.value">0.00 %</span>

            </td>
            <td>
              <span *ngIf="rowData?.category?.name">
                {{ rowData?.category?.name }}
              </span>
              <span *ngIf="!rowData?.category?.name">ــ</span>


            </td>
            <td>
              <span *ngIf="rowData?.brand?.name">
                {{ rowData?.brand?.name }}
              </span>
              <span *ngIf="!rowData?.brand?.name">ــ</span>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<app-sidebar (keydown)="onKeydown($event)" [(visible)]="showSidebar" [title]="modalTitle" [header_body]="header_body" [size]="size">
  <div header>
    <!-- <button pButton *ngIf="currentModal == 'Form' && currentProduct" (click)="switchModal('Dashboard')" type="button"
      label='{{"CUSTOMER_LIST_DASHBOARD" | translate}}' icon="pi pi-chart-bar"
      class="ui-button-raised ui-button-secondary p-mr-2"></button> -->
    <button pButton *ngIf="currentModal == 'Dashboard'" (click)="switchModal('Form')" type="button"
      label='{{"CUSTOMER_LIST_EDIT" | translate}}' class="ui-button-raised ui-button-info p-mr-2"></button>
    <!-- <button pButton type="button" label="Stock" class="ui-button-raised ui-button-info p-mr-2"></button> -->
    <button pButton *ngIf="currentModal == 'Form' && !currentProduct" (click)="saveForm()" type="button"
      label='{{"CUSTOMER_LIST_SAVE" | translate}}' class="ui-button-raised ui-button-info"></button>
    <button *ngIf="currentModal == 'Form' && currentProduct" (click)="upadteFormClicked()" pButton type="button"
      label='{{"CUSTOMER_LIST_UPDATE" | translate}}' class="ui-button-raised ui-button-info"></button>

  </div>
  <div body>
    <app-products-form *ngIf="currentModal == 'Form'"></app-products-form>
    <app-product-dashboard *ngIf="currentModal == 'Dashboard'"></app-product-dashboard>
  </div>
</app-sidebar>

<p-dialog class="p-shadow-1" header="Delete Customers" [(visible)]="removedialoguee" [style]="{width: '30vw'}">
  <div class="p-text-justify "> {{"CUSTOMER_LIST_DELETE_CONFIRM" | translate}}</div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="deleteProducts()"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
    <button type="button" pButton icon="pi pi-times" (click)="removedialoguee=false"
      label='{{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}}' class=" p-button-outlined"></button>
  </p-footer>
</p-dialog>