import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Calendar_event } from 'src/app/models/calendar/event';
import { Customer } from 'src/app/models/customer';
import { Order } from 'src/app/models/orders/order';
import { PaginatedResponse } from 'src/app/models/paginatedresponse';
import { Product } from 'src/app/models/product';
import { Quote } from 'src/app/models/quotes/quote';
import { ApiService } from '../core/api.service';
import { isSet } from '../core/util';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  filterSelectedYear:number
  constructor(private api: ApiService) { }

  public openSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public openSettingsEmitter: Observable<boolean> = this.openSettings.asObservable();

  public toturial: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public toturialEmitter: Observable<boolean> = this.toturial.asObservable();

  public displayFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public displayFilterEmitter: Observable<any> = this.displayFilter.asObservable();

  getOuststandingInvoice(): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>('/dashboard/orders/outstanding_invoiced')
  }
  getOuststandingQoute(): Observable<PaginatedResponse<Quote[]>> {
    return this.api.get<PaginatedResponse<Quote[]>>('/dashboard/quotes/outstanding')
  }

  getOuststandingCustomerPayments(): Observable<PaginatedResponse<any[]>> {
    return this.api.get<PaginatedResponse<any[]>>('/dashboard/outstanding_customers_payments')
  }
  getOuststandingVendorsPayments(): Observable<PaginatedResponse<any[]>> {
    return this.api.get<PaginatedResponse<any[]>>('/dashboard/outstanding_vendors_payments')
  }

  getOrders(): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>('/dashboard/orders/outstanding');
  }
  getPurchases(): Observable<PaginatedResponse<Order[]>> {
    return this.api.get<PaginatedResponse<Order[]>>('/dashboard/purchases/outstanding');
  }
  getTopCustomers(): Observable<PaginatedResponse<Customer[]>> {
    return this.api.get<PaginatedResponse<Customer[]>>('/dashboard/customers/top_paid_customers');
  }
  getTopPro(): Observable<PaginatedResponse<Product[]>> {
    return this.api.get<PaginatedResponse<Product[]>>('/dashboard/products/top_sold_product?limit=10');
  }
  skipTutorial(id): Observable<any> {
 
    return this.api.post(`/users/skip_tutorial`, '')
  }
  dueCustomerPayments(): Observable<any> {  
    return this.api.get(`/dashboard/due_customers_payments`)
  }
  cashPostion(): Observable<any> {  
    return this.api.get(`/dashboard/cash_position`)
  }
  dueVendorsPayments(): Observable<any> {  
    return this.api.get(`/dashboard/due_vendors_payments`)
  }
  getEventsCount(): Observable<PaginatedResponse<Calendar_event[]>> {
    return this.api.get<PaginatedResponse<Calendar_event[]>>('/events/list');
  }
  getSalesByRegion(): Observable<any> {
    return this.api.get<any>('/dashboard/sales_by_regions');
  }
  getSalesByBrand(): Observable<any> {
    return this.api.get<any>('/dashboard/sales_by_brands');
  }
  CashIn(): Observable<any> {
    return this.api.get<any>(`/dashboard/cash_in?year=${this.filterSelectedYear}`);  
  }
  CashOut(): Observable<any> {
    return this.api.get<any>(`/dashboard/cash_out?year=${this.filterSelectedYear}`);
  }
}

