<app-loading *ngIf="loading"></app-loading>
<p-toast key="form" position="top-right"></p-toast>
<p-toast key="list" position="top-right"></p-toast>
<div class="p-grid">
  <div class="p-col-6">
    <p-card>
      <h3> <i class="fas fa-clipboard-list tags p-ml-2"></i>
        {{"CUSTOMER_FORM_MAIN_INFO"| translate}}</h3>
      <div class=" p-grid pt-3">
        <div class="p-col-12 p-mt-2">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)"  maxlength="40" [(ngModel)]="product.name" id="product-name-input" type="text"
              pInputText>
            <label for="product-name-input">{{"PRODUCT_FORM_PRO_NAME" | translate}}
              &nbsp;
              <label *ngIf="!product.name &&  alertReq "
                class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

            </label>
          </span>
        </div>
  
        <div class="p-col-6 p-mt-4" #price>
          <span class="p-float-label">
              <p-inputNumber [min]="0"(keypress)="numberOnly($event)" [inputStyle]="{'width':price.offsetWidth-10+'px'}"  (ngModelChange)="notempty($event)" [minFractionDigits]="2"
              [(ngModel)]="product.sell_price" id="cost-price-input" mode="decimal"></p-inputNumber>
            <label for="price-input">{{"PRODUCT_FORM_SELL_PRICE" | translate}}

              &nbsp;
              <label *ngIf="!product.sell_price &&  alertReq "
                class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED" | translate}}</label>

            </label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <p-dropdown inputId="vat" (ngModelChange)="notempty($event)" appendTo="body" [options]="vat_Percentage"
              [(ngModel)]="product.vat" optionLabel="value">
            </p-dropdown>
            <label for="vat">{{"CUSTOMER_LIST_VAT" | translate}} <i class="fas fa-percent"></i>

            </label>

          </span>

        </div>
      </div>
    </p-card>
    <p-card>
      <h3> <i class="fas fa-dollar-sign tags p-mr-2"></i> <label> {{"CUSTOMER_FORM_PRICING"| translate}}</label></h3>
      <div class="p-grid ">
        <div class="p-col-12 p-mt-4" #buy>
          <span class="p-float-label">
            <!-- <input (ngModelChange)="notempty($event)" [(ngModel)]="product?.pricing.buy_price" id="buy-price-input"
              type="text" pInputText> -->
              <p-inputNumber [min]="0"[inputStyle]="{'width':buy.offsetWidth-10+'px'}"  (ngModelChange)="notempty($event)" [minFractionDigits]="2"
              [(ngModel)]="product?.pricing.buy_price" id="buy-price-inpu" mode="decimal"></p-inputNumber>
            <label for="buy-price-input">{{"PRODUCT_FORM_BUY_PRICE" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4" #cost>
          <span class="p-float-label">

              <p-inputNumber [min]="0"[inputStyle]="{'width':cost.offsetWidth-10+'px'}"  (ngModelChange)="notempty($event)" [minFractionDigits]="2"
              [(ngModel)]="product?.pricing.cost" id="cost-price-input" mode="decimal"></p-inputNumber>
            <label for="cost-price-input">{{"PRODUCT_FORM_COST" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">

              <p-inputNumber [inputStyle]="{'width':cost.offsetWidth-10+'px'}"  (ngModelChange)="notempty($event)"  [min]="0"[(ngModel)]="product?.pricing.delivery_cost" [minFractionDigits]="2"  mode="decimal" ></p-inputNumber>

            <label for="delivery-cost-input">{{"PRODUCT_FORM_DELIVERY_COST" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="product?.pricing.sub_items_count"
              id="number-subitems-input" type="text" pInputText>
            <label for="number-subitems-input"># {{"PRODUCT_FORM_OF_SUB_ITEMS" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="product?.pricing.sub_items_sell_price"
              id="subitem-sell-price-input" type="text" pInputText>
            <label for="subitem-sell-price-input">{{"PRODUCT_FORM_SUB_SELL_PRICE" | translate}}</label>
          </span>
        </div>


      </div>
    </p-card>
    <!-- <p-card>

      <span class="p-float-label">
        <textarea (ngModelChange)="notempty($event)" [(ngModel)]="product.remark" id="remark-input"
          pInputTextarea></textarea>
        <label for="remark-input">{{"CUSTOMER_FORM_REMARK"| translate}}</label>
      </span>
      <div class="p-col-12 p-mt-2">
        <div class="p-grid">
          <div class="form-group p-col-6">
            <h3>
              <i class="fas fa-camera tags"></i>
            
              {{"PRODUCT_FORM_IMG"| translate}}
            </h3>

          </div>
          <div style="text-align: right;" class="p-col-6">

            <div *ngIf="!product.image; else viewRec">
              <button pButton type="button" (click)="upload.click()" icon="pi pi-plus" label='Add '
                class="ui-button-raised ui-button-info" for="upload-photo"></button>
              <input #upload type="file" id="upload-photo" [imageToDataUrl]="options" (imageSelected)="_upload($event)">
            </div>
            <ng-template #viewRec>

              <button pButton type="button" (click)="showViewReceipt()" icon="fas fa-eye " label='View '
                class="ui-button-raised ui-button-info  p-ml-1" for="upload-photo"></button>


            </ng-template>
          </div>
        </div>


      </div>
    </p-card> -->
    <p-card>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-grid">
            <div class="form-group p-col-8">
              <h3>
                <i class="fa fa-paperclip tags"></i>

                {{"CUSTOMER_FORM_ATTACHMENT"| translate}}
              </h3>
            </div>
            <div *ngIf="!product?.attachment?.path" style="text-align: right;" class="p-col-4">
              <p-button (click)="attachmentdilioge=true"  icon="pi pi-plus"
                label='{{"CUSTOMER_FORM_ADD_ATT"| translate}}'></p-button>
            </div>
          </div>
          <div *ngIf="product?.attachment?.path" class=" p-col-12">
            <div class="p-grid attachmentFile p-mb-1">
              <div class="p-col-6 p-mb-3">
                <h4><a>
                    <i *ngIf="product.attachment?.type=='application/pdf'" class="far fa-file-pdf"></i>
                    <i *ngIf="product.attachment?.type!='application/pdf'" class="far fa-file-image"></i>

                    {{product.attachment?.name}}</a></h4>
              </div>
              <div class="p-col-6 p-mb-3 text-align-right">
                <button type="button" pButton (click)="generate_download(product.attachment)" class="p-button-text p-ml-3 uploadtage" 
                  label='{{"VIEW"| translate}}' icon="fas fa-eye"></button>
                <button type="button" (click)="deletefile()" pButton  class="p-button-text  p-button-danger "
                  label='{{"CUSTOMER_FORM_REMOVE"| translate}}' icon="fas fa-trash"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="form-group">
            <span class="p-float-label">
              <textarea (ngModelChange)="notempty($event)" [(ngModel)]="product.remark" id="reamrk-input"
                pInputTextarea [autoResize]="true"></textarea>
              <label for="reamrk-input">{{"CUSTOMER_FORM_REMARK"| translate}}</label>
            </span>
          </div>
        </div>
      
      </div>
    </p-card>
  </div>

  <div class="p-col-6">
    <p-card>
      <h3> <i class="fas fa-box-open tags p-mr-1"></i>

        {{"PRODUCT_FORM_ADVANCE" | translate}}</h3>
      <div class="form-group p-grid pt-3">
        <!-- <div class="p-col-12 ">
          <label class="first p-mb-1">Stocked ({{product.advanced.stock ? 'Yes' : 'No'}})</label><br>
          <p-inputSwitch (ngModelChange)="notempty($event)" [(ngModel)]="product.advanced.stock"></p-inputSwitch>
        </div> -->

        <div class="p-col-12 p-mt-2">
          <span class="p-float-label">

            <p-dropdown #dd filter="true" (onChange)="categoryChanged($event)" [showClear]="true" inputId="Category"
              placeholder='{{"CUSTOMER_FORM_CAT" | translate}}' appendTo="body" (ngModelChange)="notempty($event)"
              [(ngModel)]="product.category" [options]="categories" optionLabel="name">

              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': product.category == item}" style="z-index: 10000;" pButton
                      (click)="showEditCAT(item)" tooltipPosition="bottom" class="p-button-text  p-ml-sm-1"
                      type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showDeleteCAT(item)" tooltipPosition="bottom" class="p-button-text  p-button-danger"
                      type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="product.category?.name!=null && showAddcategory==false "
              for="Category">{{"CUSTOMER_FORM_CAT"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6  p-mt-4 ">
          <span class="p-float-label">
            <p-dropdown #Brand filter="true" (onChange)="brandChanged($event)" [showClear]="true" inputId="Brand"
              placeholder='{{"BRAND" | translate}}' appendTo="body" (ngModelChange)="notempty($event)"
              [(ngModel)]="product.brand" [options]="brands" optionLabel="name">

              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': product.brand == item}" style="z-index: 10000;" pButton
                      (click)="showEdiBrand(item)" tooltipPosition="bottom" class="p-button-text  p-ml-sm-1"
                      type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showDeleteBrands(item)" tooltipPosition="bottom" class="p-button-text  p-button-danger"
                      type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="product.brand?.name!=null && showAddBrand==false " for="Brand">{{"BRAND" | translate}}</label>
          </span>

        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">

            <p-dropdown #lol inputId="Unit" appendTo="body" placeholder='{{"PRODUCT_FORM_UNIT" | translate}}'
              (ngModelChange)="notempty($event)" (onChange)="unitChanged($event)" filter="true"
              [(ngModel)]="product.advanced.unit" [options]="units" optionLabel="name" [showClear]="true">

              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': product.advanced.unit == item}" style="z-index: 10000;" pButton
                      (click)="showEditUnit(item)" tooltipPosition="bottom" class="p-button-text  p-ml-sm-1"
                      type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showDeleteUnit(item)" tooltipPosition="bottom" class="p-button-text  p-button-danger"
                      type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="product.advanced.unit!=null && showAddUnit==false" for="Unit">{{"PRODUCT_FORM_UNIT" |
              translate}}</label>
          </span>

        </div>

     
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="product.advanced.barcode" id="barcode-input"
              type="text" pInputText>
             
            <label for="barcode-input">{{"PRODUCT_LIST_BARCODE" | translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input (ngModelChange)="notempty($event)" [(ngModel)]="product.advanced.sub_item_barcode"
              id="subitem-barcode-input" type="text" pInputText>
            <label for="subitem-barcode-input">{{"PRODUCT_FORM_SUB_ITEM_BARCODE" | translate}}</label>
          </span>
        </div>
  

      </div>
    </p-card>


    <p-card>
      <h3> <i class="ng-tns-c164-142 tags fas fa-dolly p-mr-1"></i>
        {{"WAREHOUSE"| translate}}</h3>
      <div class="p-grid">
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input #shhh (ngModelChange)="notempty($event)" [(ngModel)]="product?.shelf.shelf" id="shelf-input" type="text"
              pInputText>
            <label for="shelf-input">{{"WAREHOUSE"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">

              <p-inputNumber [min]="0"[inputStyle]="{'width':shhh.offsetWidth-10+'px'}"  (ngModelChange)="notempty($event)" [minFractionDigits]="2"
              [(ngModel)]="product?.shelf.weight" id="cost-price-input" mode="decimal"></p-inputNumber>

            <label for="weight-input">{{"PRODUCT_FORM_WEIGHT"| translate}}</label>
          </span>
        </div>
        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <p-inputMask (ngModelChange)="notempty($event)" [(ngModel)]="dimensions" (onComplete)="fillDimensions()"
              id="dimensions-input" mask="999x999x999"></p-inputMask>
            <label for="dimensions-input">{{"PRODUCT_FORM_DIMENSIONS"| translate}}</label>
          </span>
        </div>

        <!-- <div class="p-col-6 p-mt-4 ">
          <span class="p-float-label">

            <p-dropdown appendTo="body" inputId="Dimension_Unit" #Dim id='showDimensionUnit_flabel' filter="true"
              (onChange)="dimensionUnitChanged($event)" [showClear]="true"
              placeholder='{{"PRODUCT_FORM_DIM_UNIT"| translate}}' (ngModelChange)="notempty($event)"
              [(ngModel)]="product?.shelf.dimension_unit" [options]="dimensionUnits" optionLabel="name">
              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': product?.shelf.dimension_unit == item}" style="z-index: 10000;"
                      pButton (click)="showEditDimentionsUnit(item)" tooltipPosition="bottom"
                      class="p-button-text  p-ml-sm-1" type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showDeleteDimentionsUnit(item)" tooltipPosition="bottom"
                      class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="product?.shelf.dimension_unit?.name !=null && showDimensionUnit_flabel==false"
              for="showDimensionUnit_flabel">{{"PRODUCT_FORM_DIM_UNIT"| translate}}</label>
          </span>
        </div> -->



        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">

            <p-dropdown inputId="Package_type" #m2 appendTo="body" (ngModelChange)="notempty($event) "
              id='showPackageType_flabel' filter="true" (onChange)='packageTypeChanged($event)'
              [(ngModel)]="product?.shelf.package_type" [options]="packageTypes"
              placeholder='{{"PRODUCT_FORM_PACKAGE_TYPE"| translate}}' optionLabel="name" [showClear]="true">
              <ng-template let-item pTemplate="item">
                <div class="p-grid">
                  <div class="p-col-8">
                    <span [innerHTML]="item.name"></span>
                  </div>
                  <div class="p-col-4">
                    <button *ngIf="(item.id != 0) && item.id != -1"
                      [ngClass]="{'lighteidtbtn': product?.shelf.package_type == item}" style="z-index: 10000;" pButton
                      (click)="showEditPackageType(item)" tooltipPosition="bottom" class="p-button-text  p-ml-sm-1"
                      type="button" icon="fas fa-edit"></button>
                    <button *ngIf="(item.id != 0) && item.id != -1" style="z-index: 10000;" pButton
                      (click)="showDeletePackageType(item)" tooltipPosition="bottom"
                      class="p-button-text  p-button-danger" type="button" icon="fas fa-trash"></button>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label *ngIf="product?.shelf.package_type?.name !=null && showPackageType_flabel==false"
              for="showPackageType_flabel">{{"PRODUCT_FORM_PACKAGE_TYPE"| translate}}</label>
          </span>
        </div>




        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
            <input #shef (ngModelChange)="notempty($event)" [(ngModel)]="product?.shelf.shelf_life" id="shelf-life-input"
              type="text" pInputText>
            <label for="shelf-life-input">{{"PRODUCT_FORM_SHELF_LIFE"| translate}}</label>
          </span>
        </div>

        <div class="p-col-6 p-mt-4">
          <span class="p-float-label">
              <p-inputNumber [min]="0"[inputStyle]="{'width':shef.offsetWidth+'px'}"  (ngModelChange)="notempty($event)" [minFractionDigits]="2"
              [(ngModel)]="product?.shelf.minimum_stock" id="cost-price-input" mode="decimal"></p-inputNumber>
            <label for="minimum-stock-input">{{"PRODUCT_FORM_MIN_STOCK"| translate}}</label>
          </span>
        </div>


      </div>
    </p-card>

  </div>


  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"PRODUCT_FORM_ADD_CAT"| translate}}' [(visible)]="showAddcategory"
    [style]="{width: '17vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input [(ngModel)]="category.name" id="category-name-input" type="text" pInputText>
          <label for="category-name-input"> {{"CUSTOMER_FORM_NAME"| translate}}</label>
        </span>
      </div>

    </div>


    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="save_NewCategory()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="showAddcategory=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>




  <!-- Add Dimantion -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="canceladddDimentionUnit()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"PRODUCT_FORM_ADD_DIM_UNIT"| translate}}' [(visible)]="showAddcdimensionUnit"
    [style]="{width: '17vw'}">
    <div class="p-grid p-mt-2 form-group">
      <div class="p-col-12 ">
        <span class="p-float-label">
          <input [(ngModel)]="dimensionUnit.name" id="dimension-name-input" type="text" pInputText>
          <label for="dimension-name-input"> {{"CUSTOMER_FORM_NAME"| translate}}</label>
        </span>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="save_dimension_units()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="canceladddDimentionUnit()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Add Brand -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="canceladddBrand()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='Add Brand' [(visible)]="showAddBrand" [style]="{width: '17vw'}">
    <div class="p-grid p-mt-2 form-group">
      <div class="p-col-12 ">
        <span class="p-float-label">
          <input [(ngModel)]="brand.name" id="brand-name-input" type="text" pInputText>
          <label for="brand-name-input"> {{"CUSTOMER_FORM_NAME"| translate}}</label>
        </span>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="saveBrand()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="canceladddBrand()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>


  <!-- Update Dimantion -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="cancelDimentionUnit()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="dimensionsUnitHedaer" [(visible)]="showDimensionUnit" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input required #Dimention_Unit="ngModel" [(ngModel)]="dimensionUnit.name" id="demintionUnit-input"
            type="text" pInputText>
          <label for="demintionUnit-inputt">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
            <label *ngIf="dimensionUnit.name == null &&  Dimention_Unit.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label></label>
        </span>
      </div>
    </div>
    <p-footer>
      <button *ngIf="dimensionsUnitHedaer=='Add Demintion Unit'" type="button" pButton icon="pi pi-check"
        (click)="save_dimension_units()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-check" (click)="updateDimentionUnit()"
        label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelDimentionUnit()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

  <!-- Update category -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="cancelCat()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="CAThedaer" [(visible)]="showCAT" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input required #Cat="ngModel" [(ngModel)]="Cat.name" id="Cat-input" type="text" pInputText>
          <label for="Cat-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
            <label *ngIf="dimensionUnit.name == null &&  Cat.touched " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"|
              translate}}</label></label>
        </span>
      </div>
    </div>
    <p-footer>
      <button *ngIf="dimensionsUnitHedaer=='Add Category'" type="button" pButton icon="pi pi-check"
        (click)="save_NewCategory()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-check" (click)="updateCat()"
        label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelCat()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Update Brand -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="cancelCat()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="BrandHedaer" [(visible)]="showBrand" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input required #Cat="ngModel" [(ngModel)]="brand.name" id="brand-input" type="text" pInputText>
          <label for="brand-input">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
            <label *ngIf="dimensionUnit.name == null &&  Cat.touched " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"|
              translate}}</label></label>
        </span>
      </div>
    </div>
    <p-footer>

      <button type="button" pButton icon="pi pi-check" (click)="updateBrand()"
        label='{{"CUSTOMER_LIST_EDIT"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelCat()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Delete Dimation -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="canceldeleteDimentionUnit()" [resizable]="false"
    [draggable]="false" class="p-shadow-1" header='{{"CUSTOMER_FORM_DELETE_DIM_UNIT"| translate}}'
    [(visible)]="showDeletedDimensionUnit" [style]="{width: '20vw'}">

    <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
        style="color: var(--primary-color)">{{dimensionUnit.name}}</span> ?</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="deleteDimentionUnit()"
        label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="canceldeleteDimentionUnit()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <!-- Delete Category -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="canceldeleteCAt()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"CUSTOMER_FORM_DELETE_CAT"| translate}}' [(visible)]="showDeletedCAT"
    [style]="{width: '20vw'}">

    <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON"| translate}} <span
        style="color: var(--primary-color)">{{dimensionUnit.name}}</span> ?</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="deleteCategory()"
        label='{{"CUSTOMER_LIST_DELETE_BUTTON" | translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="canceldeleteCAt()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>

  <!-- Add Unit -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='Add Unit' [(visible)]="showAddUnit" [style]="{width: '17vw'}">
    <div class="p-grid p-mt-2 form-group">
      <div class="p-col-12 ">
        <span class="p-float-label">
          <input [(ngModel)]="unit.name" id="unit-input" type="text" pInputText>
          <label for="unit-inpu"> {{"CUSTOMER_FORM_NAME"| translate}}</label>
        </span>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="save_unit()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelUnit()"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

  <!-- Add Package Type -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"PRODUCT_FORM_ADD_PACKAGE"| translate}}' [(visible)]="showAddPackageType"
    [style]="{width: '17vw'}">
    <div class="p-grid p-mt-2 form-group">
      <div class="p-col-12 ">
        <span class="p-float-label">
          <input [(ngModel)]="packageType.name" id="package_type-input" type="text" pInputText>
          <label for="package_type-input"> {{"CUSTOMER_FORM_NAME"| translate}}</label>
        </span>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="save_package_type()"
        label=' {{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="showAddPackageType=false"
        label='{{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Update Unit -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="cancelUnit()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="unitHeader" [(visible)]="showUnit" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input required #unitU="ngModel" [(ngModel)]="unit.name" id="unit-input" type="text" pInputText>
          <label for="unit-inputt">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
            <label *ngIf="unit.name == null &&  unitU.touched " class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"|
              translate}}</label></label>
        </span>
      </div>
    </div>
    <p-footer>
      <button *ngIf="unitHeader=='Add Unit'" type="button" pButton icon="pi pi-check" (click)="save_unit()"
        label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-check" (click)="updateUnit()"
        label=' {{"CUSTOMER_LIST_EDIT"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelUnit()"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Update Package Type -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="cancelPackageType()" [resizable]="false" [draggable]="false"
    class="p-shadow-1" [header]="PackageTypeHeader" [(visible)]="showPackageType" [style]="{width: '21vw'}">
    <div class="p-grid p-mt-2 form-group">

      <div class="p-col-12 ">
        <span class="p-float-label">
          <input required #PackageTypeD="ngModel" [(ngModel)]="packageType.name" id="package_type-input" type="text"
            pInputText>
          <label for="demintionUnit-inputt">{{"CUSTOMER_FORM_NAME"| translate}}&nbsp;
            <label *ngIf="packageType.name == null &&  PackageTypeD.touched "
              class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label></label>
        </span>
      </div>
    </div>
    <p-footer>
      <button *ngIf="PackageTypeHeader=='Add Package Type'" type="button" pButton icon="pi pi-check"
        (click)="save_package_type()" label='{{"CUSTOMER_FORM_ADD"| translate}}'></button>
      <button type="button" pButton icon="pi pi-check" (click)="updatePackageType()"
        label=' {{"CUSTOMER_LIST_EDIT"| translate}}'></button>

      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="cancelPackageType()"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <!-- Delete package type -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"CUSTOMER_FORM_DELETE_PACKAGE"| translate}}' [(visible)]="showDeletedPackageType"
    [style]="{width: '20vw'}">

    <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}} <span
        style="color: var(--primary-color)">{{dimensionUnit.name}}</span> ?</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="deletePackageType()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="cancelDeletePackageType()"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <!-- Delete Unit -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"CUSTOMER_FORM_DELETE_Unit"| translate}}' [(visible)]="showDeletedUnit"
    [style]="{width: '20vw'}">

    <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}} <span
        style="color: var(--primary-color)">{{dimensionUnit.name}}</span> ?</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="deleteUnit()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="canceldeleteUnit()"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <!-- Delete Brand -->
  <p-dialog (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
    class="p-shadow-1" header='{{"DELETE_BRAND"| translate}}' [(visible)]="showDeleteBrand"
    [style]="{width: '20vw'}">

    <div class="p-text-justify "> {{"CUSTOMER_FORM_DELETE_CON" | translate}} <span
        style="color: var(--primary-color)">{{brand.name}}</span> ?</div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="deleteBrand()"
        label=' {{"CUSTOMER_LIST_DELETE_BUTTON"| translate}}'></button>
      <button type="button" pButton icon="pi pi-times" (click)="cancelDeleteBrand()"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}}' class=" p-button-outlined"></button>
    </p-footer>
  </p-dialog>
  <!-- images -->

  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false" [draggable]="false"
  class="p-shadow-1" header='{{"CUSTOMER_FORM_ADD_ATT"| translate}}' [(visible)]="attachmentdilioge"
  [style]="{width: '30vw'}">
  <div class="p-grid p-mt-2">
    <div class="p-col-12">
      <span class="p-float-label">
        <input (ngModelChange)="notempty($event)" #attachmentname="ngModel" [(ngModel)]="attachment_name"
          id="Name-attachment-input" type="text" pInputText>
        <label for="Name-attachment-input">{{"CUSTOMER_FORM_NAME_ATT"| translate}} &nbsp;

          <label *ngIf="attachment_name == '' || attachment_name == null &&  attachmentname.touched "
            class="alert-invalid">{{"CUSTOMER_FORM_REQUIRED"| translate}}</label>
        </label>
      </span>
    </div>

  </div>


  <p-footer class="p-pt-4">
    <button pButton type="button" style="margin-right: 5px;" icon=" pi pi-upload"
      (click)=" showUploader()" label='Upload ' class=" ui-button-info" for="upload-photo"></button>
    <div style="display: inline-block;">
      <input #upload type="file" accept="image/png, image/jpeg" id="upload-photo" [imageToDataUrl]="options" (imageSelected)="myUploader($event)">
    </div>
    <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="attachmentdilioge=false"
      label="{{'CUSTOMER_LIST_CANACEL_BUTTON'| translate}}" class="p-button-outlined ui-button-secondary"></button>

  </p-footer>
</p-dialog>


  <!-- Image View -->
  <p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" appendTo="body" [resizable]="false"
    [draggable]="false" class="p-shadow-1" [header]="'View Image'" [(visible)]="viewAttachment_diloage"
    [style]="{width: '60vw'}">
    <div class="p-grid text-align-center">
      <div class="p-col-12"><img [src]="product.view_url"></div>
    </div>

    <p-footer class="p-p-mt-4">

      <button type="button" pButton icon="pi pi-download" (click)="downloadlink()" label=' {{"DOWNLOAD" | translate}} '></button>
      <!-- <button pButton type="button" (click)="deletefile()" icon="fas fa-trash " label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}} ' class=" p-button-danger"
        for="upload-photo"></button> -->
      <button type="button" pButton icon="pi pi-times" icon="pi pi-times" (click)="viewAttachment_diloage=false"
        label=' {{"CUSTOMER_LIST_CANACEL_BUTTON"| translate}} ' class="p-button-outlined ui-button-secondary"></button>
    </p-footer>
  </p-dialog>